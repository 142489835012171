import React from 'react';
import PropTypes from 'prop-types';
import styles from './ErrorMsg.module.scss';

const ErrorMsg = ({ errorMessage, display = false }) => {
  if (!display) {
    return null;
  }
  return <span className={styles.errorMsg}>{errorMessage}</span>;
};

ErrorMsg.propTypes = {
  display: PropTypes.bool,
  errorMessage: PropTypes.string.isRequired,
};

export default ErrorMsg;
