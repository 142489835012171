import React, {useMemo} from 'react';
import classNames from 'classnames';
import Tooltip from 'shared/components/andtComponents/Tooltip';
import { useRecommendationsNewContext } from 'recommendationsNew/contexts/recommendationsNewContext.jsx';
import { COST_CONFIGURATION, FILTERS } from 'recommendationsNew/consts.js';
import styles from './costModeToggle.module.scss';
import {GenerateIcon, ICONS} from "@pileus-cloud/anodot-frontend-common";
const CostModeToggle = () => {
    const { recommendationFilters: filtersContext, setRecommendationFilters: setFiltersContext } = useRecommendationsNewContext();
    const isUnBlended = filtersContext[FILTERS.COST_MODE.id] === COST_CONFIGURATION.UNBLENDED;
    const toggleTitle = useMemo(() => (isUnBlended ? 'On-Demand' : 'Net Amortized'), [isUnBlended]);
    const toggleTooltip = useMemo(() => (isUnBlended ? 'Click to view Net Amortized cost values' : 'Click to view On demand cost values'), [isUnBlended]);
    const onToggle = () => {
        setFiltersContext({
            ...filtersContext,
            [FILTERS.COST_MODE.id]: isUnBlended ? COST_CONFIGURATION.NET_AMORTIZED : COST_CONFIGURATION.UNBLENDED
        });
    }
    return (
            <Tooltip title={toggleTooltip}>
                <div className={classNames(styles.toggleContainer, !isUnBlended && styles.amortizeToggle)}
                     automation-id="heatmap-toggle-net-amortized"
                     onClick={onToggle}>
                    <div key={toggleTitle}
                         className={classNames(styles.toggleTitle, !isUnBlended ? styles.slideDown : styles.slideUp)}>
                        {toggleTitle}
                    </div>
                    <div className={styles.toggleSwitch}>
                        <GenerateIcon
                            iconName={ICONS.caretUp.name}
                            className={`${styles.upperCaret} ${isUnBlended ? '' : styles.selected}`}
                        />
                        <GenerateIcon
                            iconName={ICONS.caretDown.name}
                            className={`${styles.lowerCaret} ${isUnBlended ? styles.selected : ''}`}
                        />
                    </div>
                </div>
            </Tooltip>
    );
};

export default CostModeToggle;

