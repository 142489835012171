import React from 'react';
import Tooltip from '../../andtComponents/Tooltip';
import HOLIDAYS from '../constants';
import { ReactComponent as BatLogo } from '../assets/halloween/bat-logo.svg';
import { ReactComponent as BooIcon } from '../assets/halloween/boo.svg';
import { ReactComponent as IlIcon } from '../assets/warIsrael/Anodot-stand-with-IL.svg';
import HappyHolidaysLogo from '../assets/happyHolidays/happy-holidays-logo.gif';
import styles from '../HolidayThemedIcons.module.scss';

const HolidayLogo = ({ holiday }) => {
  switch (holiday) {
    case HOLIDAYS.HALLOWEEN:
      return (
        <span className={styles.logo}>
          <Tooltip title={<BooIcon />} blank arrow={false}>
            <span>
              <BatLogo />
            </span>
          </Tooltip>
        </span>
      );
    case HOLIDAYS.STAND_WITH_ISRAEL:
      return (
        <span className={styles.leftAlignedlogo}>
          <IlIcon />
        </span>
      );
    case HOLIDAYS.HAPPY_HOLIDAYS:
      return (
        <span className={styles.leftAlignedlogo}>
          <img src={HappyHolidaysLogo} alt="Happy Holidays" />
        </span>
      );
    default:
      return null;
  }
};

export default HolidayLogo;
