import React from 'react';
import { DndContext, useSensor, useSensors, closestCenter, MouseSensor, DragEndEvent } from '@dnd-kit/core';
import { useSortable, SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
// @ts-ignore
import styles from './DraggableList.module.scss';

interface Item {
  uuid: string;
  [key: string]: any;
}

interface DraggableListProps {
  items: Item[];
  renderItem: (item: Item, dragHandleProps: any, isDragging: boolean, index: number) => React.ReactNode;
  onChangeOrder: (oldIndex: number, newIndex: number) => void;
  noWidth?: boolean;
  columns?: string[];
}

const SortableItem: React.FC<{
  id: string;
  item: Item;
  index: number;
  renderItem: (item: Item, dragHandleProps: any, isDragging: boolean, index: number) => React.ReactNode;
}> = ({ id, item, index, renderItem }) => {
  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({ id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <div ref={setNodeRef} style={style} {...attributes} {...listeners}>
      {renderItem(item, listeners, isDragging, index)}
    </div>
  );
};

const DraggableList: React.FC<DraggableListProps> = ({
  items,
  renderItem,
  onChangeOrder,
  noWidth = false,
  columns = null,
}) => {
  const sensors = useSensors(
    useSensor(MouseSensor, {
      activationConstraint: {
        distance: 8,
      },
    }),
  );

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;

    if (active?.id && over?.id && active.id !== over.id) {
      const oldIndex = items.findIndex((item) => item.uuid === active.id);
      const newIndex = items.findIndex((item) => item.uuid === over.id);
      onChangeOrder(oldIndex, newIndex);
    }
  };

  return (
    <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
      <div style={{ width: noWidth ? undefined : 573 }}>
        {columns ? (
          <div className="d-flex justify-content-between mb-2 ps-4 pe-2">
            {columns.map((col) => (
              <div key={col} className={styles.colTitle}>
                {col}
              </div>
            ))}
          </div>
        ) : null}
        <SortableContext items={items.map((item) => item.uuid)} strategy={verticalListSortingStrategy}>
          {items.map((item, index) => (
            <SortableItem key={item.uuid} id={item.uuid} item={item} index={index} renderItem={renderItem} />
          ))}
        </SortableContext>
      </div>
    </DndContext>
  );
};

export default DraggableList;
