 
import { RecommendationTypes, S3AttentionComment } from 'recommendations/constants/recommendationsConstants';
import { S3IdleRecommendation } from 'recommendations/models/recommTypes';
import CostRecommendationBuilder from 'recommendations/models/builders/costRecommBuilder';

export default class S3IdleRecommendationBuilder extends CostRecommendationBuilder {
  constructor(rawRecommendation) {
    super(rawRecommendation);
  }

  createRecommendation() {
    this._recommendation = new S3IdleRecommendation();
  }

  getRecommType() {
    return RecommendationTypes.S3_IDLE;
  }

  buildRecommTitle() {
    const { action } = this._rawRecommendation;
    this._recommendation.title = `${action || ''} S3 Inactive`;
  }

  build() {
    super.build();
    const {
      environment,
      project,
      num_of_days: numOfDays,
      starting_time: startingTime,
      resource_id: bucketName,
      // resource_name: resourceName,
    } = this._rawRecommendation;
    this._recommendation.bucketName = bucketName;
    // this._recommendation.resourceName = resourceName;
    this._recommendation.environment = environment;
    this._recommendation.project = project;
    this._recommendation.numOfDays = numOfDays;
    this._recommendation.startingTime = startingTime;
    this._recommendation.attentionComment = S3AttentionComment;

    // BETA
    this._recommendation.isBeta = true;
  }
}
