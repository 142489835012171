import React from 'react';
import PropTypes from 'prop-types';
import { Col, UncontrolledTooltip } from 'reactstrap';
import LinkIconButton from 'shared/components/buttons/LinkIconButton';
import InformationOutline from 'mdi-react/InformationOutlineIcon';
import ExportChartTableToolbarButton from './ExportChartTableToolbarButton';

const buttonContainerStyle = {
  display: 'flex',
  flexFlow: 'row',
  alignSelf: 'center',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  height: '100%',
};

const linkStyle = {
  display: 'inline-block',
  position: 'relative',
  zIndex: 1,
  padding: '1em',
  margin: '-1em',
};

const ChartPanel = ({
  children,
  tableModifiedDailyBalance,
  title = '',
  infoText = 'Spot data not included',
  isShowToolTip = false,
  id = 'SpotNotIncluded',
  lg = 6,
  xl = 6,
  csvTitle,
}) => (
  <Col sm={12} md={12} lg={lg} xl={xl}>
    <div style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
      <h5 className="bold-text" style={{ 'text-align': 'left', marginRight: '10px' }}>
        {title}
      </h5>
      {isShowToolTip && (
        <div>
          <LinkIconButton containerStyle={buttonContainerStyle}>
            <a
              href="#"
              linkStyle={linkStyle}
              onClick={(e) => {
                e.preventDefault();
              }}
              id={id}
            />
            <InformationOutline size={18} />
          </LinkIconButton>
          <UncontrolledTooltip placement="right" target={id}>
            {infoText}
          </UncontrolledTooltip>
        </div>
      )}
      <div style={{ flexGrow: 1, display: 'flex', justifyContent: 'flex-end', padding: '0px 17px' }}>
        <ExportChartTableToolbarButton tableModifiedDailyBalance={tableModifiedDailyBalance} csvTitle={csvTitle} />
      </div>
    </div>
    {children}
  </Col>
);

ChartPanel.propTypes = {
  children: PropTypes.object.isRequired,
  tableModifiedDailyBalance: PropTypes.object.isRequired,
  title: PropTypes.string,
  infoText: PropTypes.string,
  isShowToolTip: PropTypes.bool,
  id: PropTypes.string,
  lg: PropTypes.number,
  xl: PropTypes.number,
  csvTitle: PropTypes.string.isRequired,
};

export default ChartPanel;
