import React from 'react';
import PropTypes from 'prop-types';
import { startCase } from 'lodash';
import Tooltip from 'shared/components/andtComponents/Tooltip';
import styles from './alertStatus.module.scss';

const propTypes = {
  value: PropTypes.string,
  showAlerts: PropTypes.bool,
};

const AlertStatus = ({ value = '', showAlerts = true }) => (
  <Tooltip placement="top" title={`${startCase(value)} ${showAlerts ? 'Alert' : 'Anomaly'}`} arrow>
    <div className={`${styles[value]}`}>{startCase(value)}</div>
  </Tooltip>
);

AlertStatus.propTypes = propTypes;
export default AlertStatus;
