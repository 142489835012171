import React from 'react';
import PropTypes from 'prop-types';
import styles from './customizeLegend.module.scss';
import SvgPattern from './svgPattern.js';

export const CustomizedLegend = ({
                                   payload,
                                   hideKeys,
                                   specialLegends,
                                 }) => (
  <div className={styles.customizedLegendWrapper}>
    <div className={styles.customizedLegend}>
      {payload?.map((entry) => {
        const entryId = `${entry.payload.id}Legend`;
        return !hideKeys?.includes(entry.dataKey) ? (
          <div className={styles.legendPair} id={entryId}>
            {!specialLegends?.includes(entry.dataKey) ? (
              <svg width="20" height="10" className={styles.legendSymbol}>
                <rect width="20" height="10" fill={entry.color}/>
              </svg>) : (
              <div
                className={styles.legendSymbolPattern}
                style={{
                  background: SvgPattern({color: entry.color}),
                }}
              />
            )}
            <div className={styles.legendLabel}>{entry.value}</div>
          </div>
        ) : null;
      })}
    </div>
  </div>
);

CustomizedLegend.propTypes = {
  payload: PropTypes.arrayOf(
    PropTypes.shape({
      payload: PropTypes.shape({
        id: PropTypes.string.isRequired,
      }).isRequired,
      dataKey: PropTypes.string.isRequired,
      color: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })
  ),
  hideKeys: PropTypes.arrayOf(PropTypes.string),
  specialLegends: PropTypes.arrayOf(PropTypes.string),
};
