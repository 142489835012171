import moment from 'moment';
import { GranularityLevelsTypes } from 'shared/constants/appConstants';

const getLineDataFirstDateGroupingBy = (events, groupBy) => {
  const withInfo = events.map((e) => ({
    ...e,
    info: groupBy(e.date),
  }));
  const groupingBy = [...new Set(withInfo.map((event) => event.info))];
  return groupingBy.map((elem) => {
    const event = withInfo.find((ev) => ev.info === elem);
    return {
      x: elem,
      label: event.title,
      id: event.uuid,
      date: event.date,
    };
  });
};

export const getCUEEventsLineData = (events, granLevel) => {
  if (granLevel === GranularityLevelsTypes.GRAN_LEVEL_DAILY) {
    return getLineDataFirstDateGroupingBy(events, (date) => moment(date).format('YYYY-MM-DD'));
  }
  if (granLevel === GranularityLevelsTypes.GRAN_LEVEL_QUARTERLY) {
    return getLineDataFirstDateGroupingBy(events, (date) => moment(date).format('YYYY-Q-[q]'));
  }
  if (granLevel === GranularityLevelsTypes.GRAN_LEVEL_YEARLY) {
    return getLineDataFirstDateGroupingBy(events, (date) => moment(date).format('YYYY'));
  }
  if (granLevel === GranularityLevelsTypes.GRAN_LEVEL_WEEKLY) {
    return getLineDataFirstDateGroupingBy(events, (date) => {
      const weekNum = +moment(date).format('w');
      const format = moment(date).format('[W]-[WEEK_NUM]-YYYY');
      return format.replace('WEEK_NUM', String(weekNum));
    });
  }
  if (granLevel === GranularityLevelsTypes.GRAN_LEVEL_MONTHLY) {
    return getLineDataFirstDateGroupingBy(events, (date) => moment(date).format('YYYY-MM'));
  }
  return [];
};
