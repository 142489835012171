import React, { Component } from 'react';
import { Container, Row } from 'reactstrap';
import PropTypes from 'prop-types';
import ProcessingDataPanel from 'shared/components/ProcessingDataPanel';
import { PageNames } from 'shared/constants/appConstants';
import PageHeader from 'shared/components/PageHeader';

class WelcomePage extends Component {
  static propTypes = {
    usersStore: PropTypes.object.isRequired,
  };

  render() {
    return (
      <Container>
        <Row>
          <PageHeader title={PageNames.WELCOME} showDate />
        </Row>
        <Row>
          <ProcessingDataPanel type={this.props.usersStore.isCurrentUserNew ? 'error' : 'success'} />
        </Row>
      </Container>
    );
  }
}

export default WelcomePage;
