import React from 'react';
import PropTypes from 'prop-types';
import BaseRecommendation
    from 'recommendationsNew/components/detailedRecommendation/recommendations/baseRecommendation.jsx';


const UNUSED_SECRETS_PROPERTIES = {
    SECRET_NAME: {
        label: 'Secret Name',
        getterFunction: (recommendation) => recommendation?.recData?.secret_name,
    },
    DESCRIPTION: {
        label: 'Description',
        getterFunction: (recommendation) => recommendation?.recData?.description,
    },
}

const UnusedSecrets = ({ recommendation }) => {
    const description =
        `This secret has not been accessed or used based on the absence of any operations or access events within a specified period.
         This helps reduce security risks and unnecessary storage costs by cleaning up unused secrets.`;


    return (
        <BaseRecommendation
            recommendation={recommendation}
            description={description}
            dynamicProps={{ ...UNUSED_SECRETS_PROPERTIES}}
        />
    );
};

UnusedSecrets.propTypes = {
    recommendation: PropTypes.object.isRequired,
};

export default UnusedSecrets;
