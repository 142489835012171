 
import { RecommendationTypes } from 'recommendations/constants/recommendationsConstants';
import { AzureAppRightSizingRecommendation } from 'recommendations/models/recommTypes';
import AzureCostRecommendationBuilder from 'recommendations/models/builders/azureCostRecommBuilder';

export default class AzureAppRightSiznigRecommendationBuilder extends AzureCostRecommendationBuilder {
  constructor(rawRecommendation) {
    super(rawRecommendation);
  }

  createRecommendation() {
    this._recommendation = new AzureAppRightSizingRecommendation();
  }

  getRecommType() {
    return RecommendationTypes.AZURE_APP_RIGHT_SIZING;
  }

  buildRecommTitle() {
    const { recommended_action: action } = this._rawRecommendation;
    this._recommendation.title = `${action || ''} APP Idle`;
  }

  build() {
    super.build();
    const {
      zone_tag: tagZone,
      resource_name: resourceName,
      num_of_days: numOfDays,
      starting_time: startingTime,
      max_cpu_util: maxCpuUtil,
      cpu_util_statistics_usage: cpuUtilStatisticsUsage,
      max_network: maxNetwork,
      network_in_statistics_usage: networkInStatisticsUsage,
      network_out_statistics_usage: networkOutStatisticsUsage,
      // potential_savings: potentialSavings,
    } = this._rawRecommendation;
    this._recommendation.maxCpuUtil = maxCpuUtil;
    this._recommendation.cpuUtilStatisticsUsage = cpuUtilStatisticsUsage;
    this._recommendation.maxNetwork = maxNetwork;
    this._recommendation.networkInStatisticsUsage = networkInStatisticsUsage;
    this._recommendation.networkOutStatisticsUsage = networkOutStatisticsUsage;
    this._recommendation.region = tagZone;
    this._recommendation.resourceName = resourceName;
    this._recommendation.numOfDays = numOfDays;
    this._recommendation.startingTime = startingTime;
    // this._recommendation.potentialSavings = potentialSavings;
  }
}
