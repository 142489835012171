import { CostUsageStates } from 'usage/constants/costAndUsageConstants';
import BaseCostUsageDataState from './baseCostUsageDataState';

export default class AccountTagsDataStates extends BaseCostUsageDataState {
  getNewDataStateType() {
    return CostUsageStates.ACCOUNT_TAGS;
  }

  getDataStateType() {
    return CostUsageStates.ACCOUNT_TAGS;
  }
}
