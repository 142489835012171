import React from 'react';
import { ReactComponent as AccountNameSVG } from 'shared/img/images/account-name.svg';
import ApplicationIDImage from './assets/ApplicationID.jpg';
import DirectoryIDImage from './assets/DirectoryID.jpg';
import ClientSecretImage from './assets/ClientSecretValue.jpg';
import AccountNameImage from './assets/DisplayName.jpg';
import commonStyles from '../Pages.module.scss';
import styles from './AppRegPage.module.scss';

const AppRegPageHelp = {};

AppRegPageHelp.appDisplayName = {
  component: () => (
    <p className={commonStyles.helpText}>
      Enter the <span>Display name</span> of the dedicated app registration you created.
    </p>
  ),
  image: AccountNameImage,
  imageHeight: 267,
};

AppRegPageHelp.appID = {
  component: () => (
    <p className={commonStyles.helpText}>
      Enter the <span>Application (client) ID</span> from the dedicated application you created.
    </p>
  ),
  image: ApplicationIDImage,
  imageHeight: 246,
};

AppRegPageHelp.directoryID = {
  component: () => (
    <p className={commonStyles.helpText}>
      Enter the <span>Directory (tenant) ID</span> from the dedicated application you created.
    </p>
  ),
  image: DirectoryIDImage,
  imageHeight: 246,
};

AppRegPageHelp.clientSecret = {
  component: () => (
    <p className={commonStyles.helpText}>
      Enter the Client&apos;s Secret <span>Value</span>.
    </p>
  ),
  image: ClientSecretImage,
  imageHeight: 267,
};

AppRegPageHelp.accountName = {
  component: () => (
    <div className={styles.accountNameHelp}>
      <p className={commonStyles.helpText}>Choose a display name for the account in Anodot.</p>
      <AccountNameSVG />
    </div>
  ),
};

export default AppRegPageHelp;
