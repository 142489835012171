import { action, makeObservable, observable } from 'mobx';
import { groupBy as lodashGroupBy } from 'lodash';
import { toast } from 'react-toastify';
import { CostTrackingConstants, CostUsageStates } from 'usage/constants/costAndUsageConstants';
import { K8S_QUANTITY_TYPE_SELECT, K8sCostTypes, k8sGranLevel } from 'shared/constants/k8sConstants';
import { convertObjToMap } from 'shared/utils/apiUtil';
import { buildStartAndEndDate } from 'shared/utils/dateUtil';
import { AwsCommonFields } from 'shared/constants/awsConstants';

class KubernetesStore {
  isLoading = true;
  finOpsSevenDaysServiceCost = [];
  currVsPrevMonthCost = [];
  dataStates = [{}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}];
  k8SGranularity = k8sGranLevel.NODES;

  constructor(rootStore, apiGateway, kubernetesModel) {
    this.rootStore = rootStore;
    this.apiGateway = apiGateway;
    this.kubernetesModel = kubernetesModel;
    makeObservable(this, {
      dataStates: observable,
      setIsLoading: action,
      isLoading: observable,
      // currGroupByBalance: observable,
      currVsPrevMonthCost: observable,
      finOpsSevenDaysServiceCost: observable,
      // fetchData: action,
      // fetchDataByState: action,
      setK8sGranularity: action,
      k8SGranularity: observable,
    });
  }

  setK8sGranularity = (field) => {
    if (field === k8sGranLevel.NODES) {
      this.k8SGranularity = k8sGranLevel.NODES;
    } else if (field === k8sGranLevel.PODS) {
      this.k8SGranularity = k8sGranLevel.PODS;
    }
  };

  getK8sPodDataParams = (whereParams) => {
    const whereParamsMap = convertObjToMap(whereParams);
    const clusterName = whereParamsMap.get('cluster');
    const nodeName = whereParamsMap.get('node');
    const podName = whereParamsMap.get('pod');
    return { clusterName, nodeName, podName };
  };
  fetchK8sDataByState = async (
    currDataState,
    secondaryGroupByLevel = 'usagedate',
    groupByValue,
    whereParamsMap,
    start,
    end,
    periodGranLevel,
    filters,
    currCostType,
    selectedUsageType = K8S_QUANTITY_TYPE_SELECT,
    selectedUsageTypeMetric = null,
    isNetUnblended,
    isShowAmortizeCost,
    isNetAmortize,
    isListUnitPrice,
    isNegotiatedSavings,
    lastProcessTime = '',
    panelId,
    dashboardId,
    wasteCostAllocationChecked,
    diveDate,
    k8SGranularity,
  ) => {
    let usageStoreK8sData = [];
    try {
      if (currDataState === CostUsageStates.POD_DETAILS) {
        const { clusterName, nodeName, podName } = this.getK8sPodDataParams(whereParamsMap);
        usageStoreK8sData = await this.kubernetesModel.getK8sPodData(
          diveDate,
          diveDate,
          clusterName,
          nodeName,
          podName,
          isNetUnblended,
          isShowAmortizeCost,
          isNetAmortize,
          isListUnitPrice,
          isNegotiatedSavings,
        );
        return usageStoreK8sData || [];
      }
      usageStoreK8sData = await this.kubernetesModel.fetchK8ScostAndUsage(
        secondaryGroupByLevel,
        groupByValue,
        whereParamsMap,
        start,
        end,
        periodGranLevel,
        filters,
        currCostType,
        currDataState,
        selectedUsageType,
        selectedUsageTypeMetric,
        isNetUnblended,
        isShowAmortizeCost,
        isNetAmortize,
        isListUnitPrice,
        isNegotiatedSavings,
        lastProcessTime,
        panelId,
        dashboardId,
        wasteCostAllocationChecked,
        k8SGranularity,
      );
      if (usageStoreK8sData?.length > 0) {
        if (secondaryGroupByLevel === AwsCommonFields.POD || groupByValue === AwsCommonFields.POD) {
          toast.warning(`Top 1000 pods are shown. Everything else is grouped under "Rest".`);
        }
        if (secondaryGroupByLevel === AwsCommonFields.NODE || groupByValue === AwsCommonFields.NODE) {
          toast.warning(`Top 1000 nodes are shown. Everything else is grouped under "Rest".`);
        }
      }
    } catch {
      usageStoreK8sData = [];
    }
    return usageStoreK8sData;
  };
  fetchK8sData = (startDate, endDate) => {
    this.kubernetesModel.fetchCostBreakDownData(startDate, endDate);
  };

  fetchDefaultDataByBusinessMapping = (viewpointId) => async () => {
    const { startDate, endDate } = buildStartAndEndDate(null, null, false);
    const data = await this.kubernetesModel.apiGateway.getK8sCostAndUsage(
      `${AwsCommonFields.BUSINESS_MAPPING_VIEWPOINTS}: ${viewpointId}`,
      '',
      '',
      startDate,
      endDate,
      CostTrackingConstants.GRAN_LEVEL_QUARTERLY,
      '',
      [K8sCostTypes.COMPUTE, K8sCostTypes.NETWORK, K8sCostTypes.DATA_TRANSFER, K8sCostTypes.STORAGE],
    );
    const groupedData = lodashGroupBy(data, (a) => a.groupBy);
    return Object.values(groupedData).map((row) => ({
      ...row[0],
      group_by: row[0].groupBy,
      total_cost: row.reduce((acc, item) => acc + (item.totalCost || 0), 0),
    }));
  };

  invalidateStore = () => {
    this.finOpsSevenDaysServiceCost.splice(0);
    this.currVsPrevMonthCost.splice(0);
    this.dataStates = [{}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}];
    this.isLoading = true;
  };

  setIsLoading = (isLoading) => {
    this.isLoading = isLoading;
  };
}

export default KubernetesStore;
