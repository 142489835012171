 
import { RecommendationTypes } from 'recommendations/constants/recommendationsConstants';
import { ElasticsearchLowUtilizationRecommendation } from 'recommendations/models/recommTypes';
import CostRecommendationBuilder from 'recommendations/models/builders/costRecommBuilder';

export default class ElasticsearchLowUtilizationRecommendationBuilder extends CostRecommendationBuilder {
  constructor(rawRecommendation) {
    super(rawRecommendation);
  }

  createRecommendation() {
    this._recommendation = new ElasticsearchLowUtilizationRecommendation();
  }

  getRecommType() {
    return RecommendationTypes.ELASTICSEARCH_LOW_UTILIZATION;
  }

  buildRecommTitle() {
    const { action } = this._rawRecommendation;
    this._recommendation.title = `${action || ''} Elasticsearch`;
  }

  build() {
    super.build();
    const {
      domain_id: domainId,
      domain_name: domainName,
      instance_type: instanceType,
      instance_count: instanceCount,
      max_indexing_rate: maxIndexingRate,
      indexing_rate: indexingRate,
      max_search_rate: maxSearchRate,
      search_rate: searchRate,
      zone_tag: region,
      days_to_check: daysToCheck,
    } = this._rawRecommendation;
    this._recommendation.domainId = domainId;
    this._recommendation.domainName = domainName;
    this._recommendation.instanceType = instanceType;
    this._recommendation.instanceCount = instanceCount;
    this._recommendation.maxIndexingRate = maxIndexingRate;
    this._recommendation.indexingRate = indexingRate;
    this._recommendation.maxSearchRate = maxSearchRate;
    this._recommendation.searchRate = searchRate;
    this._recommendation.region = region;
    this._recommendation.daysToCheck = daysToCheck;

    // BETA
    // this._recommendation.isBeta = true;
  }
}
