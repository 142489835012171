import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  GenerateIcon,
  ICONS,
  Popover,
  PopoverContent,
  PopoverPortal,
  PopoverTrigger,
} from '@pileus-cloud/anodot-frontend-common';
import { Action, OrganizationEntityCategory } from '@anodot-cost/rbac-client';
import ReadOnlyDisplayWrapper from 'shared/components/ReadOnlyDisplayWrapper.jsx';
import Button from 'shared/components/andtComponents/Button';
import { USER_ACTIONS } from '../consts';
import AssignRolesModal from './userModals/AssignRolesModal/AssignRolesModal';
import EditUserModal from './userModals/EditUserModal';
import ResetPasswordModal from './userModals/ResetPasswordModal';
import DeleteUsersModal from './userModals/DeleteUsersModal';

import styles from './UserActions.module.scss';

const UserActions = ({ row }) => {
  const [isOpen, setIsOpen] = useState(false);

  const [isAssignRolesModalOpen, setIsAssignRolesModalOpen] = useState(false);
  const [isEditUserModalOpen, setIsEditUserModalOpen] = useState(false);
  const [isResetPasswordModalOpen, setIsResetPasswordModalOpen] = useState(false);
  const [isDeleteUserModalOpen, setIsDeleteUserModalOpen] = useState(false);

  const toggleOpen = (e) => {
    e.stopPropagation();
  };

  const onUserActionSelect = (action) => {
    switch (action) {
      case USER_ACTIONS.ASSIGN_ROLES.id:
        setIsAssignRolesModalOpen(true);
        break;
      case USER_ACTIONS.EDIT.id:
        setIsEditUserModalOpen(true);
        break;
      case USER_ACTIONS.RESET_PASSWORD.id:
        setIsResetPasswordModalOpen(true);
        break;
      case USER_ACTIONS.DELETE.id:
        setIsDeleteUserModalOpen(true);
        break;
      default:
        break;
    }
    setIsOpen(false);
  };

  return (
    <div className={styles.userActionsContainer} onClick={(e) => toggleOpen(e)}>
      <Popover open={isOpen} onOpenChange={() => setIsOpen(!isOpen)}>
        <PopoverTrigger as="button" type="button">
          <Button
            isTextButton
            text=""
            icon={<GenerateIcon iconName={ICONS.verticalDots.name} className={styles.actionsIcon} />}
            onClick={() => setIsOpen(!isOpen)}
            className={{ icon: styles.actionsIcon }}
          />
        </PopoverTrigger>

        <PopoverPortal>
          <PopoverContent sideOffset={-10} side="left" align="start" alignOffset={20} className={styles.popoverContent}>
            <ReadOnlyDisplayWrapper category={OrganizationEntityCategory.Roles} action={Action.Update}>
              <Button
                isTextButton
                text={USER_ACTIONS.ASSIGN_ROLES.label}
                automationId={USER_ACTIONS.ASSIGN_ROLES.automationId}
                className={{ button: styles.menuItem }}
                icon={() => <GenerateIcon iconName={USER_ACTIONS.ASSIGN_ROLES.icon} className={styles.itemIcon} />}
                onClick={() => onUserActionSelect(USER_ACTIONS.ASSIGN_ROLES.id)}
              />
            </ReadOnlyDisplayWrapper>
            <ReadOnlyDisplayWrapper category={OrganizationEntityCategory.Roles} action={Action.Update}>
              <Button
                isTextButton
                text={USER_ACTIONS.EDIT.label}
                automationId={USER_ACTIONS.EDIT.automationId}
                className={{ button: styles.menuItem }}
                icon={() => <GenerateIcon iconName={USER_ACTIONS.EDIT.icon} className={styles.itemIcon} />}
                onClick={() => onUserActionSelect(USER_ACTIONS.EDIT.id)}
              />
            </ReadOnlyDisplayWrapper>
            <ReadOnlyDisplayWrapper category={OrganizationEntityCategory.Roles} action={Action.Update}>
              <Button
                isTextButton
                text={USER_ACTIONS.RESET_PASSWORD.label}
                automationId={USER_ACTIONS.RESET_PASSWORD.automationId}
                className={{ button: styles.menuItem }}
                icon={() => <GenerateIcon iconName={USER_ACTIONS.RESET_PASSWORD.icon} className={styles.itemIcon} />}
                onClick={() => onUserActionSelect(USER_ACTIONS.RESET_PASSWORD.id)}
              />
            </ReadOnlyDisplayWrapper>
            <ReadOnlyDisplayWrapper category={OrganizationEntityCategory.Roles} action={Action.Update}>
              <Button
                isTextButton
                text={USER_ACTIONS.DELETE.label}
                automationId={USER_ACTIONS.DELETE.automationId}
                className={{ button: styles.menuItem }}
                icon={() => <GenerateIcon iconName={USER_ACTIONS.DELETE.icon} className={styles.itemIcon} />}
                onClick={() => onUserActionSelect(USER_ACTIONS.DELETE.id)}
              />
            </ReadOnlyDisplayWrapper>
          </PopoverContent>
        </PopoverPortal>
      </Popover>

      {setIsAssignRolesModalOpen && (
        <AssignRolesModal setIsOpen={setIsAssignRolesModalOpen} isOpen={isAssignRolesModalOpen} users={[row]} />
      )}
      {isEditUserModalOpen && (
        <EditUserModal setIsOpen={setIsEditUserModalOpen} isOpen={isEditUserModalOpen} user={row} />
      )}
      {isResetPasswordModalOpen && (
        <ResetPasswordModal setIsOpen={setIsResetPasswordModalOpen} isOpen={isResetPasswordModalOpen} users={[row]} />
      )}
      {isDeleteUserModalOpen && (
        <DeleteUsersModal setIsOpen={setIsDeleteUserModalOpen} isOpen={isDeleteUserModalOpen} users={[row]} />
      )}
    </div>
  );
};

UserActions.propTypes = {
  row: PropTypes.object.isRequired,
};

export default UserActions;
