import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { PreferencesKeys, PreferencesTypes } from 'users/constants/usersConstants';
import Spinner from 'shared/components/andtComponents/Spinner';
import OneChoiceFieldFilter from 'shared/components/OneChoiceFieldFilter';
import toast from 'shared/components/andtComponents/Toast';
import Button from 'shared/components/andtComponents/Button';
import { usePreferences } from 'users/hooks/react-query/usePreferences';
import { formatDateTime } from 'shared/utils/dateUtil';
import { TableHeaderProvider } from 'shared/components/tableHeader/TableHeaderContext';
import TableHeader from 'shared/components/tableHeader/TableHeader';
import styles from './PreferencesTableDraggable.module.scss';
import InvoiceOpeningSentenceInput from './InvoiceOpeningSentenceInput';

const InvoicesPreferences = ({ usersStore, isAllAccounts, payerAccountOptions }) => {
  const [payerAccount, setPayerAccount] = useState(payerAccountOptions[0].value);
  const [preference, setPreference] = useState(null);

  const actionsDisabled = usersStore.isCurrentUserReCustomer || usersStore.isCurrentUserNew;

  const { fetchPreferencesByTypeAndKeyWithTenant, useSaveMutation } = usePreferences();
  const { data: preferences } = fetchPreferencesByTypeAndKeyWithTenant(
    usersStore.currDispUserAccountKey,
    PreferencesTypes.RESELLER,
    PreferencesKeys.CUSTOMER_INVOICE,
  );
  const savePreference = useSaveMutation();
  useEffect(() => {
    if (preferences) {
      setPreference({ ...preferences[payerAccount] });
    }
  }, [preferences]);

  const [saveLoading, setSaveLoading] = useState(false);
  const getUpdatedDetails = (pref) => {
    const updatedBy = (pref || {}).createdBy || 'Default';
    const updateDate = (pref || {}).updateTime || null;
    return { updatedBy, updateDate };
  };
  const { updatedBy, updateDate } = getUpdatedDetails(preference);
  const handleSavePreferences = async () => {
    setSaveLoading(true);
    await savePreference.mutateAsync([{ ...preference, accountId: payerAccount }]);
    setSaveLoading(false);
    toast.success('Changes Saved');
  };
  return (
    <div className="w-100">
      <div className="mb-4">
        <TableHeaderProvider>
          <TableHeader
            tableName="Invoice"
            hideSearch
            isCreatable={false}
            titleInfo={
              <div className={styles.headerInfo}>
                <div>Created by: {updatedBy}</div>
                {updateDate && <div>Updated: {formatDateTime(updateDate)}</div>}
              </div>
            }
          />
        </TableHeaderProvider>
      </div>
      {isAllAccounts ? (
        <div className="mb-4">
          <h5 className="">Payer Account</h5>
          <OneChoiceFieldFilter
            type="payerAccount"
            value={payerAccountOptions.find((opt) => opt.value === payerAccount)}
            options={payerAccountOptions}
            handleChange={(type, value) => {
              setPayerAccount(value.value);
              setPreference({ ...preferences[value.value] });
            }}
            isClearable
          />
        </div>
      ) : null}
      {preference ? (
        <>
          <InvoiceOpeningSentenceInput
            preference={preference}
            onChange={(value) => {
              setPreference({
                ...preference,
                updated: 1,
                preferences: {
                  ...preference.preferences,
                  openingSentence: value,
                },
              });
            }}
          />
          <div className="d-flex pe-3 pt-3">
            <Button
              className="mb-4"
              disabled={actionsDisabled || saveLoading}
              onClick={handleSavePreferences}
              text="Save"
            />
          </div>
        </>
      ) : (
        <Spinner />
      )}
    </div>
  );
};

InvoicesPreferences.propTypes = {
  usersStore: PropTypes.object.isRequired,
  isAllAccounts: PropTypes.bool.isRequired,
  payerAccountOptions: PropTypes.array.isRequired,
};

export default InvoicesPreferences;
