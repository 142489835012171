import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './Switch.module.scss';

const Switch = ({ options, onChange, isDisabled = false, isLoading = false, className = undefined }) => {
  return (
    options?.length > 0 && (
      <div className={classNames(styles.container, { [styles.inProgress]: isLoading })}>
        <div className={styles.switchOptionsContainer}>
          {options.map((option) => (
            <div
              key={option.label}
              style={option.isSelected && !isDisabled ? { backgroundColor: option.color } : {}}
              className={classNames(styles.option, className, {
                [styles.viewOnly]: option.isSelected && isDisabled,
                [styles.selected]: option.isSelected,
                [styles.notSelected]: !option.isSelected,
                [styles.disabled]: isDisabled,
              })}
              onClick={(e) => onChange(e, option)}
            >
              {option.label}
            </div>
          ))}
        </div>
      </div>
    )
  );
};

Switch.propTypes = {
  className: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      color: PropTypes.string,
      isSelected: PropTypes.bool,
      label: PropTypes.string,
      value: PropTypes.string,
    }),
  ).isRequired,
  onChange: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool,
  isLoading: PropTypes.bool,
};

export default Switch;
