import { useQuery, useQueryClient } from '@tanstack/react-query';
import { API } from 'shared/utils/apiMiddleware';
import apiConstants from 'shared/api/apiConstants';
import { getFullAwsServiceName } from 'shared/utils/awsUtils';
import DateFilter from 'shared/modules/dateFilter';
import { buildFilterParams } from 'shared/utils/apiUtil';
import { mapRecommendationsTypeToServiceName } from 'recommendations/constants/recommendationsConstants';
import { buildStartAndEndDate } from 'shared/utils/dateUtil';
import { prepareFiltersMap } from 'recommendations/utils/recommUtils';
import { AWS_RECOMMENDATIONS_ROOT, BILLINGS_API_NAME } from 'recommendations/hooks/react-query/api';
import moment from 'moment';

const fetchRecommendationOpportunities = async (
  startDate,
  endDate,
  filterParams,
  periodGranLevel,
  recommendationType,
  service,
  dashboardId,
  limit,
) =>
  API.get(
    BILLINGS_API_NAME,
    // eslint-disable-next-line max-len
    `${AWS_RECOMMENDATIONS_ROOT}/dashboard/opportunities?startDate=${startDate}&endDate=${endDate}&periodGranLevel=${periodGranLevel}${filterParams}${
      recommendationType ? `&recType=${recommendationType}` : ''
    }${service ? `&service=${service}` : ''}${dashboardId ? `&dashboardId=${dashboardId}` : ''}${
      limit ? `&limit=${limit}` : ''
    }`,
  );

export const fetchRecommendationOpportunitiesData = async (
  startDate,
  endDate,
  filterParams,
  periodGranLevel,
  recommendationType,
  dashboardId,
  limit,
) => {
  try {
    const service = getFullAwsServiceName(mapRecommendationsTypeToServiceName.get(recommendationType)) || null;
    const lastDate = DateFilter.getPreviousDayDate();
    const builtDates = buildStartAndEndDate(
      startDate || moment(lastDate).subtract(30, 'days').format('YYYY-MM-DD'),
      endDate || lastDate,
    );
    const filterMap = prepareFiltersMap(filterParams);
    const builtFilterParams = buildFilterParams(filterMap);
    return fetchRecommendationOpportunities(
      builtDates.startDate,
      builtDates.endDate,
      builtFilterParams,
      periodGranLevel,
      recommendationType,
      service,
      dashboardId,
      limit,
    );
  } catch (error) {
    throw error;
  }
};

export default function useRecommendationOpportunities({
  startDate,
  endDate,
  filterParams,
  periodGranLevel,
  recommendationType,
  dashboardId = null,
  limit,
}) {
  const queryClient = useQueryClient();

  const queryKey = [
    apiConstants.QUERY_KEYS.RECOMMENDATION_OPPORTUNITIES,
    {
      startDate,
      endDate,
      filterParams,
      periodGranLevel,
      recommendationType,
      dashboardId,
      limit,
    },
  ];

  return {
    invalidate: () => queryClient.invalidateQueries(queryKey),
    reset: () => queryClient.resetQueries(queryKey),
    fetchOpportunities: (currentLimit) =>
      useQuery(
        queryKey,
        () =>
          fetchRecommendationOpportunitiesData(
            startDate,
            endDate,
            filterParams,
            periodGranLevel,
            recommendationType,
            dashboardId,
            currentLimit || limit,
          ),
        {
          retry: false,
        },
      ),
  };
}
