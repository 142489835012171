/* eslint-disable no-unused-vars */
import { CostUsageStates } from 'usage/constants/costAndUsageConstants';
import { isArray } from 'lodash';
import LabelCoordinator from 'shared/modules/labelCoordinator';
import BaseCostUsageDataState from './baseCostUsageDataState';

export default class DivisionDataState extends BaseCostUsageDataState {
  constructor(context) {
    super(context);
  }

  getNewDataStateType(dataKey) {
    const newDataState = CostUsageStates.SERVICE;
    return newDataState;
  }

  getDataStateType() {
    return CostUsageStates.DIVISION;
  }
  getData = () => {
    const stateType = this.getDataStateType();
    this.data = this.context.props.invoiceStore.dataStates[stateType];
    if (!isArray(this.data)) {
      return [];
    }
    return this.data.map(({ groupBy, ...rest }) => ({
      groupBy: LabelCoordinator.getDataKeyDisplayName('cueDisplayCoordinator', groupBy),
      ...rest,
    }));
  };
}
