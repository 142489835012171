import React from 'react';
import { observer } from 'mobx-react';
import Spinner from 'shared/components/andtComponents/Spinner';

export const checkTrialUser = (Component1, Component2, childProps) => {
  const Page = observer(() => {
    if (childProps.usersStore.usersModel.isLoadingUsers) {
      return <Spinner />;
    }
    if (
      childProps.usersStore &&
      childProps.usersStore.currentDisplaydUser &&
      childProps.usersStore.currentDisplaydUser.isPayingUser
    ) {
      return <Component1 {...childProps} />;
    }
    return <Component2 {...childProps} />;
  });

  return Page;
};
