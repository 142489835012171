export class ResellerCommonFields {
  static COST_CENTER = 'Cost Center';
  static DIVISION = 'Division';
  static division = 'division';
  static DIVISION_ID = 'divisionid';
  static COST_CENTER_ID = 'Cost Center ID';
}

export const resellerCommonFieldToDisplayField = new Map([
  [ResellerCommonFields.COST_CENTER, 'Customer'],
  [ResellerCommonFields.COST_CENTER_ID, 'Customer ID'],
  [ResellerCommonFields.DIVISION, 'Customer'],
  [ResellerCommonFields.DIVISION_ID, 'Customer ID'],
  [ResellerCommonFields.division, 'Customer'],
]);
