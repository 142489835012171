import React from 'react';
import PropTypes from 'prop-types';
import { GenerateIcon, ICONS } from '@pileus-cloud/anodot-frontend-common';

import classes from './searchFilters.module.scss';

export function SearchFilters({ searchValue = '', onSearchChange, placeholder = ' Search' }) {
  const inputRef = React.useRef(null);
  const onChange = (searchInput) => {
    onSearchChange(searchInput);
  };

  const onClearSearchClick = () => {
    onChange('');
  };

  return (
    <div className={classes.searchInputContainer} onClick={() => inputRef.current?.focus()}>
      <div className={classes.searchInputWithIcon}>
        <GenerateIcon iconName={ICONS.magnifyingGlass.name} className={classes.searchIcon} />
        <input
          value={searchValue.toString()}
          className={classes.searchInput}
          placeholder={placeholder}
          ref={inputRef}
          onChange={(event) => onChange(event?.target?.value)}
        />
      </div>
      {searchValue ? (
        <GenerateIcon className={classes.clearIcon} iconName={ICONS.circleXmark.name} onClick={onClearSearchClick} />
      ) : null}
    </div>
  );
}

SearchFilters.propTypes = {
  onSearchChange: PropTypes.object.isRequired,
  searchValue: PropTypes.string,
  placeholder: PropTypes.string,
};
