import { PricingPlans } from 'shared/constants/paymentConstants';
import Account from './account';

export default class User {
  constructor(args) {
    // User personal info
    this.jobTitle = args.job_title || '';
    this.firstName = args.first_name || '';
    this.lastName = args.last_name || '';
    this.companyName = args?.isKeycloakManagement ? args.organizationName || '' : args.company_name || '';
    this.tenantName = args.tenant_name || '';

    // Pileus basic system user data
    this.userId = args.id;
    this.userKey = args.user_key;
    this.userHash = args.userHash;
    this.userName = args.user_name;
    this.customTheme = args.theme;
    this.roleId = args.role_id;
    this.isAdmin = args.is_admin;
    this.isResellerMode = args.is_reseller_mode;
    this.isReadOnly = Boolean(args.is_read_only);
    this.isRootUser = args.root_user || false;
    this.isParent = args.is_parent || false;
    this.userDisplayName = args.user_display_name || args.user_name;
    this.userType = this.parseType(args.user_type);
    this.registrationTime = args.registration_time;
    this.registrationDate = new Date(args.registration_time); // createTimeZoneAgnosticDateFromStr(args.db_creation_time);
    this.parentUserKey = args.parent_user_key;
    this.childs = this.createChildren(args.childs);

    // payment details
    this.isPayingUser = args.pricing_plan_id !== PricingPlans.TRIAL;
    this.pricingPlanId = args.pricing_plan_id;
    this.remainingTrialDays = args.remaining_trial_days;
    this.defaultAccountFeatures = args.default_account_features;

    this.selfManagedCustomerAdmin = args.self_managed_customer_admin;

    this.privateSystemData = [
      'userId',
      'userKey',
      'registrationTime',
      'registrationDate',
      'isPayingUser',
      'remainingTrialDays',
    ];
    // Cloud dynamic data specific

    this.accounts = this.setUserAccounts({ accounts: args.accounts, isKeycloakManagement: args.isKeycloakManagement });
    this.customerDivisions = this.setCustomerDivisions(args.customerDivisions);
    const { default_account_id: defaultAccountId } = args.settings || {};
    this.settings = {
      defaultAccountId,
    };
    this.awsAccountId = args.aws_account_id; // will be in account class

    // New User Management attributes
    this.organizationId = args.organizationId;
    this.isSuperAdmin = args.isSuperAdmin;
    this.isCustomer = args.isCustomer;
    this.isReseller = args.isReseller;
    this.endTrial = args.endTrial;
    this.isKeycloakManagement = args.isKeycloakManagement || false;
  }

  // user Methods

  getUserKey = () => this.userKey;

  getCustomTheme = () => this.customTheme;
  getIsResellerMode = () => this.isResellerMode;
  getIsAdmin = () => this.isAdmin;

  set updateUserData(newData) {
    Object.keys(newData).forEach((updatedAttr) => {
      if (updatedAttr in this && !this.isAttributePrivate(updatedAttr)) {
        // checking if the new data object attributes match user attribute and not private
        this[updatedAttr] = newData[updatedAttr];
      }
    });
  }

  hasAccounts = () => this.accounts.length > 0;

  getAllAccounts = () => {
    const first = '281974177803';
    this.accounts.sort(function (x, y) {
      // eslint-disable-next-line no-nested-ternary
      return x.accountId === first ? -1 : y.accountId === first ? 1 : 0;
    });
    return this.accounts;
  };

  getAllCustomerDivisions = () => this.customerDivisions || [];

  getDefaultUserAccount = (accKey = false, settings = this.settings) => {
    try {
      if (this.hasAccounts()) {
        if (accKey && this.getAccountByAccKey(+accKey)) {
          return this.getAccountByAccKey(+accKey);
        }
        if ((settings || {}).defaultAccountId) {
          const acc = (this.accounts || []).find((a) => a.accountId === settings.defaultAccountId);
          if (acc) {
            return acc;
          }
        }
        return this.getAllAccounts()[0];
      }
    } catch {
      return this.getAllAccounts()[0];
    }
    return null;
  };

  setUserAccounts = ({ accounts, isKeycloakManagement }) => {
    if (accounts) {
      const arrOfAccounst = accounts.map((acc) => new Account({ ...acc, isKeycloakManagement }));
      return arrOfAccounst;
    }
    return [];
  };

  setCustomerDivisions = (customerDivisions) => customerDivisions || [];

  getAccountByAccKey = (accKey) => {
    const accIndex = this.accounts.findIndex((acc) => acc.getAccKey() === accKey);
    if (accIndex > -1) {
      return this.accounts[accIndex];
    }
    return null;
  };

  getAccAndAccIndexByAcckey = (key) => {
    let account = {};
    let idx;
    this.accounts.forEach((acc, index) => {
      if (acc.getAccKey() === key) {
        account = { ...acc };
        idx = index;
      }
    });
    if (Object.keys(account).length > 0) {
      return { account, index: idx };
    }
    return undefined;
  };

  getAccTypeIdByAcckey = (key) => {
    const accountAndIdx = this.getAccAndAccIndexByAcckey(key);
    if (accountAndIdx) {
      return accountAndIdx.account.getAccountTypeId();
    }
    return null;
  };

  updateAcc = (newAccObj) => {
    const { index } = this.getAccAndAccIndexByAcckey(newAccObj.getAccKey());
    if (index) {
      this.accounts[index].updateAcc;
      return true;
      // this.accounts.splice(index, 1, newAccObj);
    }
    return false;
  };
  updateAccountName = (accountKey, newAccountName) => {
    const { index } = this.getAccAndAccIndexByAcckey(accountKey);
    if (index) {
      this.accounts[index].updateAccountName(newAccountName);
      return true;
      // this.accounts.splice(index, 1, newAccObj);
    }
    return false;
  };
  deleteAcc = (newAccObj) => {
    const { index } = this.getAccAndAccIndexByAcckey(newAccObj.getAccKey());
    if (index) {
      this.accounts.splice(index, 1);
    }
  };

  // general helper methods

  isAttributePrivate = (attr) => {
    if (this.privateSystemData.includes(attr)) {
      return false;
    }
    return true;
  };

  // old methods kept as reference for now..
  getUserType = () => this.userType;

  getUserDisplayName = () => this.userDisplayName;
  getChildren = () => (this.childs && Array.isArray(this.childs) ? [...this.childs] : []);

  createChildren = (childs) => {
    if (!childs || childs.length === 0) {
      return [];
    }
    return childs.map((child) => new User({ ...child, isKeycloakManagement: this.isKeycloakManagement }));
  };

  deleteChild = (childUserKey) => {
    const children = this.getChildren();
    if (children.length > 0) {
      const childIndex = children.findIndex((usr) => usr.userKey === childUserKey);
      if (childIndex > -1) {
        this.childs.splice(childIndex, 1);
      }
    }
  };

  checkIfMatchFilterCriteria = (textToCheck) => {
    if (!textToCheck) {
      return true;
    }
    const equalToDisplayName =
      this.userDisplayName && this.userDisplayName.toLowerCase().includes(textToCheck.toLowerCase());
    if (equalToDisplayName) {
      return true;
    }
    const equalToUserName = this.userName.toLowerCase().includes(textToCheck.toLowerCase());
    if (equalToUserName) {
      return true;
    }

    const equalToUserKey = this.userKey.toLowerCase() === textToCheck.toLowerCase();
    if (equalToUserKey) {
      return true;
    }

    // if (this.getUserDisplayName()) {
    //   return this.userDisplayName.toLowerCase().includes(textToCheck.toLowerCase());
    // }
    return false;
  };

  parseType = (type) => parseInt(type, 10);

  getAccountByAccountId = (accountId) => this.accounts?.find((a) => accountId === a.accountId);
}
