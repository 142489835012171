 
 
import { RecommendationTypes } from 'recommendations/constants/recommendationsConstants';
import { AzureVmRightSizingRecommendation } from 'recommendations/models/recommTypes';
import AzureCostRecommendationBuilder from 'recommendations/models/builders/azureCostRecommBuilder';
import { calculateAverage } from 'shared/utils/calcUtil';
import React from 'react';

export default class AzureVmRightSizingRecommendationBuilder extends AzureCostRecommendationBuilder {
  constructor(rawRecommendation) {
    super(rawRecommendation);
  }

  createRecommendation() {
    this._recommendation = new AzureVmRightSizingRecommendation();
  }

  getRecommType() {
    return RecommendationTypes.AZURE_VM_RIGHT_SIZING;
  }

  buildRecommTitle() {
    const { recommended_action: action } = this._rawRecommendation;
    this._recommendation.title = `${action || ''} VM Right Sizing`;
  }
  build() {
    super.build();
    const {
      // instance_type_size_recommended: recommSize,
      network_in_statistics_usage: networkInStatisticsUsage,
      network_out_statistics_usage: networkOutStatisticsUsage,
      num_of_days: numOfDays,
      sku_name_recommended: recommVersion,
      sku_name_current: currentVersion,
      // project,
      zone_tag: region,
      os,
      environment,
      resource_id: resourceId,
      resource_group: resourceGroup,
      role_name: resourceName,
      max_cpu: maxCpuUtil,
      max_memory: maxMemory,
      max_total_network: maxNetwork,
      cpu_util_statistics_usage: cpuUtilStatisticsUsage,
      cpu_util: cpuUtil,
      cpu_percentile: cpuPercentile,
      mem_used_avg: memUsedAvg,
      mem_available_avg: memAvailableAvg,
      mem_size_gb: memSizeGb,
      mem_utilization_percentage: memUtilizationPercentage,
      is_current_reserved: isCurrentReserved,
      is_recommended_reserved: isRecommendedReserved,
    } = this._rawRecommendation;

    // const recommEc2Instance = new VmInstanceBasicData(recommVersion, os, region, environment);
    // this._recommendation.recommEc2Instance = recommEc2Instance;
    this._recommendation.currentVersion = currentVersion;
    this._recommendation.recommVersion = recommVersion;
    this._recommendation.os = os;
    this._recommendation.environment = environment;
    this._recommendation.networkInStatisticsUsage = networkInStatisticsUsage;
    this._recommendation.networkOutStatisticsUsage = networkOutStatisticsUsage;
    this._recommendation.numOfDays = parseInt(numOfDays, 10);
    this._recommendation.maxCpuUtil = parseFloat(maxCpuUtil);
    this._recommendation.cpuUtil = parseFloat(cpuUtil);
    this._recommendation.resourceGroup = resourceGroup;
    this._recommendation.resourceId = resourceId;
    this._recommendation.resourceName = resourceName;
    this._recommendation.region = region;
    this._recommendation.maxNetwork = maxNetwork;
    this._recommendation.maxMemory = maxMemory;
    this._recommendation.cpuUtilStatisticsUsage = cpuUtilStatisticsUsage;
    this._recommendation.cpuPercentile = +cpuPercentile;
    this._recommendation.memUsedAvg = memUsedAvg;
    this._recommendation.memUsedAvgNumber = calculateAverage(memUsedAvg);
    this._recommendation.memAvailableAvg = memAvailableAvg;
    this._recommendation.memAvailableAvgNumber = calculateAverage(memAvailableAvg);
    this._recommendation.memSizeGb = parseInt(memSizeGb, 10);
    this._recommendation.isCurrentReserved = isCurrentReserved ? 'Yes' : 'No';
    this._recommendation.isRecommendedReserved = isRecommendedReserved ? 'Yes' : 'No';
    this._recommendation.memUtilizationPercentage = memUtilizationPercentage;
    this._recommendation.memUtilizationPercentageNumber = calculateAverage(memUtilizationPercentage);
    this._recommendation.attentionComment = (
       
      <ul style={{ paddingLeft: '15px' }}>
        <li key={1}>
          In a <strong>MCA/CSP Export Amortize </strong> account type the potential savings and current cost are
          calculated based on the <strong>Effective Cost </strong>(RI price).
        </li>
        <li key={2}>
          In a <strong>MCA/CSP Export Unblended</strong> account type the potential savings and current cost are
          calculated based on the
          <strong> List Price</strong> (on demand).
        </li>
        <li>
          In an <strong>EA API </strong>account type the potential savings and current cost are calculated based on the
          <strong>List Price</strong> (on demand).
        </li>
      </ul>
    );
  }
}
