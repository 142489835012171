import React from 'react';
import PropTypes from 'prop-types';
import { Row } from 'reactstrap';
import DateFilter from 'shared/modules/dateFilter';
import CostBarLineChartToggler from './CostBarLineChartToggler';

function PricingMethodChart({ data, height = 300 }) {
  return (
    <Row style={{ padding: '20px 10px', margin: '5px 0px 0px 2px' }} className="chart-box-style">
      <CostBarLineChartToggler data={data} dateFilter={DateFilter} showHeader showOnlyBarChart height={height} />
    </Row>
  );
}

PricingMethodChart.propTypes = {
  data: PropTypes.object.isRequired,
  height: PropTypes.number,
};

export default PricingMethodChart;
