import React, { useEffect, useState } from 'react';
import {
  DataTypeProvider,
  IntegratedSelection,
  IntegratedSorting,
  RowDetailState,
  SelectionState,
  Sorting,
  SortingState,
} from '@devexpress/dx-react-grid';
import { Grid, TableHeaderRow, TableRowDetail, TableSelection } from '@devexpress/dx-react-grid-material-ui';
import TableWrapper from 'shared/components/tables/TableWrapper';
import useTable from 'shared/hooks/customHooks/useTable';
import { sortMonth } from 'shared/utils/sortUtil';
import styles from '../BillingSummary.module.scss';
import {
  CurrencyNumberWithTooltipFormatter,
  CustomHeaderCell,
  CustomNoDataComponent,
  CustomSelectionCell,
} from '../CustomTableComponents';
import CustomerMargins from '../CustomerMargins';
import { BillingHistoryRow } from '../../types.ts';
import { getHistoryTableColumnsAndWidths } from '../../utils.ts';

const columnsToApplyCurrencyFormatting = [
  'mtdResellerCost',
  'mtdCustomerCost',
  'partnerMargin',
  'riMargin',
  'usageMargin',
  'riFlexibilityMargin',
  'spFlexibilityMargin',
  'publicPriceMargin',
  'billingRuleMargin',
  'creditMargin',
  'resellerCredit',
];

const currentMonth = new Date().getMonth() + 1;
const defaultSorting: Sorting[] = [
  { columnName: 'year', direction: 'desc' },
  { columnName: 'month', direction: 'desc' },
  { columnName: 'mtdResellerCost', direction: 'desc' },
  { columnName: 'mtdCustomerCost', direction: 'desc' },
  { columnName: 'partnerMargin', direction: 'desc' },
];

const sortingExtensions = [
  {
    columnName: 'month',
    compare: sortMonth,
  },
];

type Props = {
  rows: BillingHistoryRow[];
  selectedRowsIndexes: number[];
  setSelectedRowsIndexes(rs: number[]): void;
  isAzure: boolean;
  isAllExpanded: boolean;
  timeRange: [Date, Date];
  showSelectAll?: boolean;
};

const CustomersHistoryTable: React.FC<Props> = ({
  rows,
  selectedRowsIndexes,
  setSelectedRowsIndexes,
  isAzure,
  isAllExpanded,
  timeRange,
  showSelectAll,
}) => {
  const [expandedRowIds, setExpandedRowIds] = useState<(string | number)[]>([]);
  const { NewTableRow, CustomToggleCell } = useTable();
  const { tableColumns } = getHistoryTableColumnsAndWidths(isAzure);
  const [sorting, setSorting] = useState(defaultSorting);

  useEffect(() => {
    if (!isAllExpanded) {
      setExpandedRowIds([]);
    } else {
      setExpandedRowIds(rows.map((row, index) => index));
    }
  }, [isAllExpanded]);
  return (
    <Grid rows={rows} columns={tableColumns}>
      <SortingState
        sorting={sorting}
        onSortingChange={(sortingColumAndDirection) => setSorting(sortingColumAndDirection)}
      />
      <IntegratedSorting columnExtensions={sortingExtensions} />
      <SelectionState selection={selectedRowsIndexes} onSelectionChange={setSelectedRowsIndexes} />
      <IntegratedSelection />
      <RowDetailState expandedRowIds={expandedRowIds} onExpandedRowIdsChange={(n) => setExpandedRowIds(n)} />
      <DataTypeProvider
        for={columnsToApplyCurrencyFormatting}
        formatterComponent={CurrencyNumberWithTooltipFormatter}
      />
      <TableWrapper
        className="history-table"
        virtual
        noDataCellComponent={CustomNoDataComponent}
        rowComponent={(props) => (
          <NewTableRow
            expandedRowIds={expandedRowIds}
            setExpandedRowIds={setExpandedRowIds}
            {...props}
            className={props.row.month < currentMonth ? styles.historyRow : ''}
          />
        )}
        height="auto"
      />
      <TableSelection showSelectAll={showSelectAll} cellComponent={CustomSelectionCell} />
      <TableHeaderRow showSortingControls cellComponent={CustomHeaderCell} />
      <TableRowDetail
        contentComponent={(d) => <CustomerMargins row={d.row} timeRange={timeRange} />}
        toggleCellComponent={CustomToggleCell}
      />
    </Grid>
  );
};

export default CustomersHistoryTable;
