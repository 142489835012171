 
import { RecommendationTypes } from 'recommendations/constants/recommendationsConstants';
import { AzureCosmosRightSizingRecommendation } from 'recommendations/models/recommTypes';
import { Ec2InstanceBasicData } from 'recommendations/models/recommCommonTypes';
import AzureCostRecommendationBuilder from 'recommendations/models/builders/azureCostRecommBuilder';

export default class AzureCosmosRightSizingRecommendationBuilder extends AzureCostRecommendationBuilder {
  constructor(rawRecommendation) {
    super(rawRecommendation);
  }

  createRecommendation() {
    this._recommendation = new AzureCosmosRightSizingRecommendation();
  }

  getRecommType() {
    return RecommendationTypes.AZURE_VM_RIGHT_SIZING;
  }

  buildRecommTitle() {
    const { recommended_action: action } = this._rawRecommendation;
    this._recommendation.title = `${action || ''} Cosmos Right Sizing`;
  }

  build() {
    super.build();
    const {
      instance_type_size_recommended: recommSize,
      network_in: networkIn,
      network_out: networkOut,
      num_of_days: numOfDays,
      instance_type_model: recommVersion,
      project,
      zone_tag: region,
      operation_system: os,
      quantity: resourcesQuantity,
      environment,
      resources,
      additional_recommended_models: additionalRecModels,
      specs_details_current: specsCurr,
      specs_details_recommended: specsRecomm,
      cpu_util: cpuUtil,
      ec2_id: resourceId,
      max_cpu_util: maxCpuUtil,
      cpu_util_statistics_usage: cpuUtilStatisticsUsage,
    } = this._rawRecommendation;

    const recommEc2Instance = new Ec2InstanceBasicData(recommVersion, recommSize, os, region, environment, resourceId);
    this._recommendation.recommEc2Instance = recommEc2Instance;
    this._recommendation.recommSize = recommSize;
    this._recommendation.networkIn = networkIn;
    this._recommendation.networkOut = networkOut;
    this._recommendation.numOfDays = parseInt(numOfDays, 10);
    this._recommendation.cpuUtil = parseFloat(cpuUtil);
    this._recommendation.resourceId = resourceId;
    this._recommendation.project = project;
    this._recommendation.resourcesQuantity = resourcesQuantity;
    this._recommendation.resources = resources;
    this._recommendation.additionalRecModels = additionalRecModels;
    this._recommendation.specsCurr = specsCurr;
    this._recommendation.specsRecomm = specsRecomm;
    this._recommendation.maxCpuUtil = maxCpuUtil;
    this._recommendation.cpuUtilStatisticsUsage = cpuUtilStatisticsUsage;
  }
}
