import { CLOUD_TYPE_IDS } from 'users/constants/usersConstants';
import { AMORTIZE_COST_TYPES_ITEMS } from 'usage/constants/costAndUsageConstants';

export const getCostBasisOptions = ({
  cloudType,
  isCurrentUserSharedReCustomer,
  isCurrentUserDedicatedReCustomer,
  isDisabled: disabled = false,
  isEditMode = false,
  classes = {},
}) => {
  if (cloudType === CLOUD_TYPE_IDS.MULTI) {
    return [
      {
        ...AMORTIZE_COST_TYPES_ITEMS.AMORTIZED,
        primary: true,
        automationId: 'amortized-cost',
        disabled,
      },
      {
        ...AMORTIZE_COST_TYPES_ITEMS.UNBLENDED,
        primary: true,
        disabled,
        optionClassName: classes.marginOption,
        automationId: 'unblended-cost',
      },
    ];
  }
  if (isCurrentUserSharedReCustomer) {
    return [
      {
        ...AMORTIZE_COST_TYPES_ITEMS.UNBLENDED,
        primary: true,
        automationId: `${isEditMode ? 'edit' : 'create'}-unblended-cost`,
      },
    ];
  }

  if (isCurrentUserDedicatedReCustomer || cloudType === CLOUD_TYPE_IDS.AZURE) {
    return [
      {
        ...AMORTIZE_COST_TYPES_ITEMS.AMORTIZED,
        primary: true,
        automationId: `${isEditMode ? 'edit' : 'create'}-amortized-cost`,
        disabled,
      },
      {
        ...AMORTIZE_COST_TYPES_ITEMS.UNBLENDED,
        primary: true,
        automationId: `${isEditMode ? 'edit' : 'create'}-unblended-cost`,
      },
    ];
  }
  return [
    {
      ...AMORTIZE_COST_TYPES_ITEMS.AMORTIZED,
      primary: true,
      automationId: `${isEditMode ? 'edit' : 'create'}-amortized-cost`,
      disabled,
    },
    {
      ...AMORTIZE_COST_TYPES_ITEMS.NET_AMORTIZED,
      value: 'net_amortized',
      primary: true,
      disabled,
      optionClassName: classes.option2,
      automationId: `${isEditMode ? 'edit' : 'create'}-net-amortized-cost`,
    },
    {
      ...AMORTIZE_COST_TYPES_ITEMS.UNBLENDED,
      primary: true,
      disabled,
      automationId: `${isEditMode ? 'edit' : 'create'}-unblended-cost`,
    },
  ];
};
