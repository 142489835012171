import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Checkbox from 'shared/components/andtComponents/Checkbox';
import Spinner from 'shared/components/andtComponents/Spinner';
import SwitchButton from 'shared/components/andtComponents/Switch';
import PermissionsExplanation from './PermissionsExplaination';
import { PERMISSION_ACTIONS, PERMISSION_ENTITIES, PERMISSION_TYPES } from '../../consts';
import Permission from './Permission';
import useRoles from 'users/new-user-management/hooks/reactQuery/useRoles';
import InfoPopover from 'shared/components/andtComponents/InfoPopover/index';

import styles from './EffectivePermissions.module.scss';

const EffectivePermissions = ({
  isViewOnly,
  permissions = [],
  permissionChanged,
  actionPermissionForAllCategoriesChanged,
  roleId,
  userId,
  isCreateEditMode,
}) => {
  const [filterByAvailablePermissions, setFilterByAvailablePermissions] = useState(isCreateEditMode ?? true);
  const [permissionsToDisplay, setPermissionsToDisplay] = useState(null);

  const { fetchAvailableCategoryActions } = useRoles();
  const { data: availableCategoryActions, isLoading } = fetchAvailableCategoryActions();

  useEffect(() => {
    if (availableCategoryActions) {
      let tmpPermissionsToDisplay;
      if (filterByAvailablePermissions) {
        tmpPermissionsToDisplay = permissions.filter((p) =>
          p.actionPermissions.some((ap) => ap.permissions.some((p) => p.permissionType !== PERMISSION_TYPES.NONE.id)),
        );
      } else {
        const availableCategoryActionsPermissions = availableCategoryActions.map((item) => {
          return {
            category: item.category,
            actionPermissions: item.actions.map((action) => ({
              action: action,
              permissions: [],
            })),
          };
        });
        tmpPermissionsToDisplay = availableCategoryActionsPermissions.map((item) => {
          const found = permissions.find((obj) => obj.category === item.category);
          return {
            ...item,
            actionPermissions: found?.actionPermissions ? [...found.actionPermissions] : [...item.actionPermissions],
          };
        });
      }
      setPermissionsToDisplay(tmpPermissionsToDisplay?.sort((a, b) => a.category.localeCompare(b.category)));
    }
  }, [permissions, filterByAvailablePermissions, availableCategoryActions]);

  const getNewCategoryPermissionsHelper = (categoryIndex, actionId, newPermission) => {
    const categoryActionPermission = {
      permissionType: newPermission ? PERMISSION_TYPES.FULL.id : PERMISSION_TYPES.NONE.id,
    };

    return {
      category: permissions[categoryIndex].category,
      actionPermissions: [
        {
          action: actionId,
          permissions: [categoryActionPermission],
        },
      ],
    };
  };

  const getNewCategoryPermissions = (categoryPermissions, actionId, newPermission) => {
    let categoryIndex = permissions.findIndex((c) => c.category === categoryPermissions?.category);
    if (categoryIndex === -1) {
      permissions.push(categoryPermissions);
      categoryIndex = permissions.length - 1;
    }
    return getNewCategoryPermissionsHelper(categoryIndex, actionId, newPermission);
  };

  const onPermissionChange = (categoryPermissions, actionId, newPermission) => {
    let categoryNewPermissions = getNewCategoryPermissions(categoryPermissions, actionId, newPermission);
    permissionChanged(categoryNewPermissions, categoryPermissions);
  };

  const isActionCheckboxChecked = (actionId) => {
    const isChecked = permissionsToDisplay?.every((category) => {
      const viewPermission = category.actionPermissions.find((ap) => ap.action === actionId);
      return (
        viewPermission?.permissions.some((p) => p.permissionType === PERMISSION_TYPES.FULL.id) ||
        // If undefined, this means that the category doesn't have permission for this action,
        // and thus it is ok to ignore it when calculating if all the checkboxes are checked
        typeof viewPermission === 'undefined'
      );
    });
    return isChecked;
  };

  const onActionForAllCategoriesPermissionChange = (isChecked, actionId) => {
    const categoriesNewPermissions = [];
    permissionsToDisplay?.forEach((categoryPermissions) => {
      const isActionAvailableForCategory = availableCategoryActions
        ?.find((item) => item.category === categoryPermissions.category)
        ?.actions.includes(actionId);
      if (isActionAvailableForCategory) {
        const categoryNewPermissions = getNewCategoryPermissions(categoryPermissions, actionId, isChecked);
        categoriesNewPermissions.push(categoryNewPermissions);
      }
    });
    actionPermissionForAllCategoriesChanged(categoriesNewPermissions);
  };

  const getInfoTooltip = (actionId) => {
    if (actionId === PERMISSION_ACTIONS.IMPERSONATE.id) {
      return 'Allow users to impersonate other users for support purposes. Use sparingly.';
    } else if (actionId === PERMISSION_ACTIONS.EXEC_OFFLINE_TASKS_AS.id) {
      return 'Allow users to determine under which role automatic tasks, such as reports and alert are run, thus determining their data access scope.';
    }
  };

  return isLoading ? (
    <Spinner />
  ) : (
    <div className={styles.effectivePermissionsContainer}>
      <div className={styles.titleContainer}>
        <div className={styles.title}>Effective Permissions</div>
        <Checkbox
          isChecked={filterByAvailablePermissions}
          onChange={() => {
            setFilterByAvailablePermissions(!filterByAvailablePermissions);
          }}
          primary
          text="Show granted permissions"
        />
      </div>
      <div className={styles.container}>
        <div className={styles.permissionsGrid}>
          <div className={classNames(styles.gridCell, styles.headerCell, styles.entityHeaderCell)}>Entities</div>
          {Object.values(PERMISSION_ACTIONS).map((action) => (
            <div className={classNames(styles.gridCell, styles.headerCell)} key={action.id}>
              <div className={styles.actionName}>{action.name}</div>
              {action.id !== PERMISSION_ACTIONS.IMPERSONATE.id &&
              action.id !== PERMISSION_ACTIONS.EXEC_OFFLINE_TASKS_AS.id ? (
                <SwitchButton
                  isDisabled={isViewOnly}
                  onChange={() =>
                    onActionForAllCategoriesPermissionChange(!isActionCheckboxChecked(action.id), action.id)
                  }
                  isChecked={isActionCheckboxChecked(action.id)}
                  className={styles.permissionSwitch}
                  isSmall
                />
              ) : (
                <InfoPopover isSimple>{getInfoTooltip(action.id)}</InfoPopover>
              )}
            </div>
          ))}
          {Array.isArray(permissionsToDisplay) &&
            permissionsToDisplay?.map((categoryPermissions) => (
              <>
                <div className={classNames(styles.gridCell, styles.entityCell)}>
                  <div className={styles.entityName}>
                    {PERMISSION_ENTITIES[categoryPermissions.category]?.title || categoryPermissions.category}
                  </div>
                </div>
                {Object.values(PERMISSION_ACTIONS).map((action) => (
                  <div className={classNames(styles.gridCell, styles.valuesCell)} key={action.id}>
                    <Permission
                      actionId={action.id}
                      category={categoryPermissions.category}
                      isViewOnly={isViewOnly}
                      userId={userId}
                      onPermissionChange={(newPermission) =>
                        onPermissionChange(categoryPermissions, action.id, newPermission)
                      }
                      permissions={
                        categoryPermissions?.actionPermissions?.find((p) => p.action === action.id)?.permissions
                      }
                      isAllowed={categoryPermissions?.actionPermissions?.find((p) => p.action === action.id)}
                      roleId={roleId}
                    />
                  </div>
                ))}
              </>
            ))}
        </div>
        <PermissionsExplanation />
      </div>
    </div>
  );
};

EffectivePermissions.propTypes = {
  actionPermissionForAllCategoriesChanged: PropTypes.func,
  isCreateEditMode: PropTypes.bool,
  isViewOnly: PropTypes.bool,
  permissions: PropTypes.array,
  permissionChanged: PropTypes.func,
  roleId: PropTypes.string,
  userId: PropTypes.string,
};

export default EffectivePermissions;
