import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useRootStore } from 'app/contexts/RootStoreContext';
import { CLOUD_TYPE_IDS, mapCloudTypeIdToDescription } from 'users/constants/usersConstants';
import {
  awsServiceNameToIconMapping,
  azureServiceNameToIconMapping,
  gcpServiceNameToIconMapping,
} from '../servicesIcons/serviceNameToIconMapping';
import { useDynamicSvgImport } from './useDynamicSvgImport';
import { useRecommendationsNewContext } from 'recommendationsNew/contexts/recommendationsNewContext.jsx';
import classes from './SelectIconsMultiMenu.module.scss';

export const ServicesImageTag = ({ label = '' }) => {
  const { usersStore } = useRootStore();
  const { panelCloudType } = useRecommendationsNewContext();
  const cloudType = panelCloudType !== undefined ? panelCloudType : usersStore?.currDispUserCloudAccountType;
  const cloudTypeString = mapCloudTypeIdToDescription.get(cloudType).toLowerCase();

  const cloudMapper = useMemo(() => {
    if (cloudType === CLOUD_TYPE_IDS.AWS) {
      return awsServiceNameToIconMapping;
    }
    if (cloudType === CLOUD_TYPE_IDS.AZURE) {
      return azureServiceNameToIconMapping;
    }
    if (cloudType === CLOUD_TYPE_IDS.GCP) {
      return gcpServiceNameToIconMapping;
    }
    return null;
  }, [cloudType]);

  const { SvgIcon } = useDynamicSvgImport(cloudTypeString, cloudMapper, label);

  return <>{SvgIcon && <SvgIcon className={classes.serviceIcon} />}</>;
};

ServicesImageTag.propTypes = {
  label: PropTypes.string,
};
