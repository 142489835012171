import { CostUsageStates } from 'usage/constants/costAndUsageConstants';
import BaseCostUsageDataState from './baseCostUsageDataState';

export default class PodDataState extends BaseCostUsageDataState {
  constructor(context) {
    super(context);
  }

  getNewDataStateType() {
    const newDataState = CostUsageStates.POD_DETAILS;
    return newDataState;
  }

  getDataStateType() {
    return CostUsageStates.POD;
  }
}
