import React from 'react';
import { MainDashboardProvider } from './contexts/mainDashboardContext.jsx';
import MainDashboard from 'app/containers/MainDashboard/MainDashboard.jsx';
const MainDashboardPage = () => {

    return (
        <MainDashboardProvider>
           <MainDashboard />
        </MainDashboardProvider>
    );
};

export default MainDashboardPage;
