import React, { useEffect, useState } from 'react';
import useTable from 'shared/hooks/customHooks/useTable';
import useUsers from 'users/new-user-management/hooks/reactQuery/useUsers';
import UsersHeader from './UsersHeader';
import UsersTable from './UsersTable';
import { UsersContext } from './contexts/usersContext';
import { GET_USERS_QUERY_PARAMS } from '../consts';
import usePagination from 'users/hooks/usePagination';

import styles from './Users.module.scss';

const Users = () => {
  const [totalUsers, setTotalUsers] = useState(0);
  const [isAllExpanded, setIsAllExpanded] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [showFilters, setShowFilters] = useState(false);
  const [tableFilters, setTableFilters] = useState({
    [GET_USERS_QUERY_PARAMS.PAGINATION_TOKEN.name]: null,
    [GET_USERS_QUERY_PARAMS.PAGE_SIZE.name]: GET_USERS_QUERY_PARAMS.PAGE_SIZE.defaultValue,
  });

  const { NewTableWrapper } = useTable();

  const usersHook = useUsers();
  const { data, isLoading, isFetching } = usersHook.fetchUsers({
    ...tableFilters,
  });

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const searchParam = searchParams.get(GET_USERS_QUERY_PARAMS.SEARCH.name);
    if (searchParam) {
      setTableFilters((prev) => ({ ...prev, [GET_USERS_QUERY_PARAMS.SEARCH.name]: searchParam }));
    }
  }, []);

  const handlePageChange = (newPaginationToken) => {
    setTableFilters((prev) => ({
      ...prev,
      [GET_USERS_QUERY_PARAMS.PAGINATION_TOKEN.name]: newPaginationToken,
    }));
  };

  const { currentPage, pageChanged } = usePagination({
    data,
    onPageChange: handlePageChange,
  });

  useEffect(() => {
    if (data?.total) {
      setTotalUsers(data.total);
    }
  }, [data?.total]);

  return (
    <div className={styles.users}>
      <UsersContext.Provider
        value={{
          isAllExpanded,
          setIsAllExpanded,
          showFilters,
          setShowFilters,
          selectedRows,
          setSelectedRows,
          setTableFilters,
          tableFilters,
          totalUsers,
        }}
      >
        <NewTableWrapper>
          <UsersHeader
            pageChanged={pageChanged}
            currentPage={currentPage}
            isNextPageLoading={isLoading || isFetching}
          />
          <UsersTable isLoading={isLoading} users={data?.users || []} />
        </NewTableWrapper>
      </UsersContext.Provider>
    </div>
  );
};

export default Users;
