 
import { RecommendationTypes } from 'recommendations/constants/recommendationsConstants';
import { NeptuneLowUtilizationRecommendation } from 'recommendations/models/recommTypes';
import CostRecommendationBuilder from 'recommendations/models/builders/costRecommBuilder';

export default class NeptuneLowUtilizationRecommendationBuilder extends CostRecommendationBuilder {
  constructor(rawRecommendation) {
    super(rawRecommendation);
  }

  createRecommendation() {
    this._recommendation = new NeptuneLowUtilizationRecommendation();
  }

  getRecommType() {
    return RecommendationTypes.NEPTUNE_LOW_UTILIZATION;
  }

  buildRecommTitle() {
    const { action } = this._rawRecommendation;
    this._recommendation.title = `${action || ''} Neptune`;
  }

  build() {
    super.build();
    const {
      starting_time: startingTime,
      arn,
      engine,
      engine_version: engineVersion,
      zone_tag: region,
      db_cluster_id: dbClusterId,
      db_cluster_parameter_group: dbClusterParameterGroup,
      db_cluster_resource_id: dbClusterResourceId,
      gremlin_requests_per_sec: gremlinRequestsPerSec,
      max_gremlin_requests_per_sec: maxGremlinRequestsPerSec,
      max_sparql_requests_per_sec: maxSparqlRequestsPerSec,
      multi_az: multiAz,
      primary_db_instance_id: primaryDbInstanceId,
      sparql_requests_per_sec: sparqlRequestsPerSec,
      num_members: numMembers,
      days_to_check: daysToCheck,
    } = this._rawRecommendation;

    this._recommendation.startingTime = startingTime;
    this._recommendation.arn = arn;
    this._recommendation.engineVersion = engineVersion;
    this._recommendation.engine = engine;
    this._recommendation.numMembers = numMembers;
    this._recommendation.sparqlRequestsPerSec = sparqlRequestsPerSec;
    this._recommendation.primaryDbInstanceId = primaryDbInstanceId;
    this._recommendation.multiAz = multiAz;
    this._recommendation.max_sparqlRequestsPerSec = maxSparqlRequestsPerSec;
    this._recommendation.maxGremlinRequestsPerSec = maxGremlinRequestsPerSec;
    this._recommendation.gremlinRequestsPerSec = gremlinRequestsPerSec;
    this._recommendation.dbClusterResourceId = dbClusterResourceId;
    this._recommendation.dbClusterParameterGroup = dbClusterParameterGroup;
    this._recommendation.dbClusterId = dbClusterId;
    this._recommendation.region = region;
    this._recommendation.daysToCheck = daysToCheck;
    // BETA
    // this._recommendation.isBeta = true;
  }
}
