/* eslint-disable no-unused-vars */
import { CostUsageStates } from 'usage/constants/costAndUsageConstants';
import BaseCostUsageDataState from './baseCostUsageDataState';

export default class CustomTagsDataStates extends BaseCostUsageDataState {
  constructor(context) {
    super(context);
  }

  getNewDataStateType(dataKey) {
    const newDataState = CostUsageStates.SERVICE;
    return newDataState;
  }

  getDataStateType() {
    return CostUsageStates.CUSTOM_TAGS;
  }
}
