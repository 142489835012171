import moment from 'moment';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { API } from 'shared/utils/apiMiddleware';
import apiConstants from 'shared/api/apiConstants';
import { CLOUD_TYPE_IDS } from 'users/constants/usersConstants';
import config from 'config';

const root = '/api/v1/divisions';

const getCustomersHistoryData = ({ startDate, endDate, cloudTypeId }) => {
  const url = cloudTypeId === CLOUD_TYPE_IDS.AZURE ? 'azure-billing-history' : 'customers/aws/costs';
  const query = `?startDate=${moment(startDate).format('YYYY-MM-DD')}&endDate=${moment(endDate).format('YYYY-MM-DD')}`;
  return API.get('billings', `${root}/${url}/${query}`);
};

const getResellerCustomerInvoiceData = async ({ rows, type }) => {
  const getUrl = `${config.apiGateway.URL}${root}/customers/aws/customer-invoice/${type}`;
  const response = await fetch(getUrl, {
    method: 'POST',
    body: JSON.stringify({ rows }),
    headers: {
      ...config.apiReqHeaders.createHeaders(),
      'Content-Type': 'application/json',
    },
  });
  return response.text();
};

export default function useBillingInfo() {
  const queryClient = useQueryClient();

  const queryKey = apiConstants.QUERY_KEYS.BILLING_INFO;

  return {
    invalidate: () => queryClient.invalidateQueries(queryKey),
    reset: () => queryClient.resetQueries(queryKey),
    fetchBillingHistory: ({ startDate, endDate, cloudTypeId }) =>
      useQuery([queryKey, startDate, endDate, cloudTypeId], () =>
        getCustomersHistoryData({ startDate, endDate, cloudTypeId }),
      ),
    fetchResellerCustomerInvoiceData: ({ rows, type, onSuccess, enabled }) =>
      useQuery(
        [queryKey, 'invoice-data', JSON.stringify(rows), type],
        () => getResellerCustomerInvoiceData({ rows, type }),
        { onSuccess, enabled },
      ),
  };
}
