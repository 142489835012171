import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { Routes } from 'shared/constants/routes';
import { TABS_IDS } from 'users/constants/usersConstants';
import AzureMCAMigrationModal from './AzureMCAMigrationModal/AzureMCAMigrationModal';
import { useNavigate } from 'react-router-dom';

const AzureMCAMigration = () => {
  const [migrationModalOpen, setMigrationModalOpen] = useState(false);
  const navigate = useNavigate();

  return (
    <AzureMCAMigrationModal
      open={migrationModalOpen}
      onContinue={() => {
        navigate(Routes.ACCOUNT, {
          state: { defaultTabId: TABS_IDS.CLOUD_ACCOUNTS },
        });
        setMigrationModalOpen(false);
      }}
      onClose={() => setMigrationModalOpen(false)}
    />
  );
};

export default observer(AzureMCAMigration);
