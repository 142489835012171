import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { observer } from 'mobx-react';
import { Row } from 'reactstrap';
import { cloneDeep } from 'lodash';
import Spinner from 'shared/components/andtComponents/Spinner';
import DateFilter from 'shared/modules/dateFilter';
import toast from 'shared/components/andtComponents/Toast';
import { CLOUD_TYPE_IDS } from 'users/constants/usersConstants';
import { numberRangeArrayOfSize } from 'shared/utils/arrayUtils';
import CommitmentTablePanel from './CommitmentTablePanel';
import RiUtilizationPieChartsPanel from './RiUtilizationPieChartsPanel';
import SavingsPlansStatPanel from './SavingsPlansStatPanel';
import MonthPicker from './MonthPicker';
import RiFlexibilityOrChargeBackModal from './RiFlexibilityOrChargeBackModal';
import { RI_SERVICE_FILTER_OPTIONS, SP_UTILIZATION_SERVICE_FILTER_OPTIONS } from '../constants/commitmentConstants';

class CommitmentTypeUtilizationPage extends Component {
  static propTypes = {
    usageStore: PropTypes.object.isRequired,
    commitmentStore: PropTypes.object.isRequired,
    invoiceStore: PropTypes.object.isRequired,
    usersStore: PropTypes.object.isRequired,
    commitmentType: PropTypes.string.isRequired,
  };

  constructor(props) {
    super(props);
    this.prepareMonthOptions = this.prepareMonthOptions.bind(this);
    this.getSelectedDate = this.getSelectedDate.bind(this);
    this.handleMonthChange = this.handleMonthChange.bind(this);
    this.state = {
      isLoading: false,
      loadingTableData: false,
      selectedDate: this.getSelectedDate(),
      tempSelectedCommitmentService: '',
      selectedCommitmentService: '',
      selectedLinkedAccount: '',
      selectedPayerAccount: '',
      isGetTableDataButtonActive: true,
      cbModalIsLoading: false,
      flexModalIsLoading: false,
      currentLoadingArn: '',
      isRiFlexOrChargeBackDataIsAvailable: false,
      modalType: '',
    };
  }

  async componentDidMount() {
    const { commitmentStore, commitmentType } = this.props;
    const { selectedDate } = this.state;
    commitmentStore.commitmentModel.invalidateTableData();
    const data = commitmentStore.commitmentModel.getCommitmentChartsAndStatDataByType(commitmentType);
    if (data.length === 0) {
      try {
        this.setState({ isLoading: true });
        await commitmentStore.getCommitmentChartsAndStatisticsData(selectedDate.value, commitmentType);
        this.setState({ isLoading: false });
      } catch {
        this.setState({ isLoading: false });
      }
    }
  }

  getSelectedDate = () => {
    const currentMonth = this.props.commitmentStore.commitmentModel.getCurrDataMonthByType(this.props.commitmentType);
    if (currentMonth === -1) {
      return this.prepareMonthOptions()[0];
    }
    const months = this.prepareMonthOptions();
    const dateObj = months.filter((item) => {
      const date = new Date(item.value).getMonth();
      return date === currentMonth;
    });
    return dateObj[0];
  };
  getCommitmentTableData = async () => {
    if (!this.filtersAreValid()) {
      return;
    }
    const { selectedDate, selectedLinkedAccount, selectedPayerAccount, tempSelectedCommitmentService } = this.state;
    const commitmentServiceValue = tempSelectedCommitmentService && tempSelectedCommitmentService.value;
    const selectedLinkedAccountValue = selectedLinkedAccount && selectedLinkedAccount.value;
    let selectedPayerAccountValue = selectedPayerAccount && selectedPayerAccount.value;
    if (selectedPayerAccountValue && selectedPayerAccountValue.length) {
      const idxStart = selectedPayerAccountValue.indexOf('(');
      const idxEnd = selectedPayerAccountValue.indexOf(')');
      selectedPayerAccountValue = selectedPayerAccountValue.slice(idxStart + 1, idxEnd);
    }
    try {
      this.setState({ loadingTableData: true, isGetTableDataButtonActive: false });
      await this.props.commitmentStore.getCommitmentTableData(
        selectedDate.value,
        commitmentServiceValue,
        selectedLinkedAccountValue,
        this.props.commitmentType,
        selectedPayerAccountValue,
      );
      this.setState({
        loadingTableData: false,
        selectedCommitmentService: tempSelectedCommitmentService,
      });
    } catch {
      this.setState({ loadingTableData: false });
      toast.warning('Failed to get data');
    }
  };
  getChargeBackData = async (arn, month, year) => {
    try {
      this.setState({ cbModalIsLoading: true, modalType: 'cb', currentLoadingArn: arn });
      await this.props.commitmentStore.getChargeBackData(arn, month, year, this.props.commitmentType);
      if (arn !== this.state.currentLoadingArn) {
        return;
      }
      this.setState({ cbModalIsLoading: false, isRiFlexOrChargeBackDataIsAvailable: true });
    } catch {
      this.setState({ cbModalIsLoading: false });
    }
  };
  getRiFlexabilityData = async (arn, month, year) => {
    try {
      this.setState({ flexModalIsLoading: true, modalType: 'flexibility', currentLoadingArn: arn });
      await this.props.commitmentStore.getRiFlexabilityData(arn, month, year);
      if (arn !== this.state.currentLoadingArn) {
        return;
      }
      this.setState({ flexModalIsLoading: false, isRiFlexOrChargeBackDataIsAvailable: true });
    } catch {
      this.setState({ flexModalIsLoading: false });
    }
  };

  getUserSpecificColumns = (isCurrentUserSharedReCustomer, commitType) => {
    const riTableColunms = [
      { name: 'chargeBack', title: 'Charge Back' },
      {
        name: 'flexibilityUtilizationPercentage',
        title: 'RI Flexibility ',
        getCellValue: (row) => row.flexibilityUtilizationPercentage,
      },
      { name: 'riFlexibility', title: ' ' },
    ];
    const spTableColunms = [{ name: 'chargeBack', title: 'Charge Back' }];
    const addTableColumnsByType = {
      ri: riTableColunms,
      sp: spTableColunms,
    };
    if (!isCurrentUserSharedReCustomer) {
      return addTableColumnsByType[commitType];
    }
    return [];
  };
  getTableColumnsByType = () => {
    const { commitmentType, usersStore } = this.props;
    const { currDispUserCloudAccountType, isCurrentUserSharedReCustomer } = usersStore;
    const serviceOptionsByType = {
      ri: this.prepareRiUtilizationTableColumns(currDispUserCloudAccountType),
      sp: this.SpUtilizationTableColumns,
    };
    const columns = serviceOptionsByType[commitmentType];
    if (currDispUserCloudAccountType === CLOUD_TYPE_IDS.AWS) {
      const userSpecificColumns = this.getUserSpecificColumns(isCurrentUserSharedReCustomer, commitmentType);
      return [...columns, ...userSpecificColumns];
    }
    return columns;
  };
  getServiceOptions = () => {
    const { usersStore } = this.props;
    const { currDispUserCloudAccountType } = usersStore;
    if (currDispUserCloudAccountType === CLOUD_TYPE_IDS.AWS) {
      const serviceOptionsbyType = {
        ri: RI_SERVICE_FILTER_OPTIONS,
        sp: SP_UTILIZATION_SERVICE_FILTER_OPTIONS,
      };
      return serviceOptionsbyType[this.props.commitmentType];
    }
    return [];
  };
  modalTitle = () => {
    let title = this.state.modalType;
    if (this.state.modalType === 'flexibility') {
      title = 'Ri Flexibility';
    } else if (this.state.modalType === 'cb') {
      title = 'Chargeback';
    }
    return title;
  };
  modalData = () => {
    let data = [];
    data = this.props.commitmentStore.commitmentModel.getCommitDetailedDataByCommitTypeAndModalType(
      this.props.commitmentType,
      this.state.modalType,
    );
    return data;
  };
  toggleRiFlexOrChargeBackModal = () => {
    if (this.state.isRiFlexOrChargeBackDataIsAvailable) {
      this.props.commitmentStore.invalidateCommitmentRiAndChargeBackModel();
    }
    this.setState(() => ({
      isRiFlexOrChargeBackDataIsAvailable: false,
      cbModalIsLoading: false,
      flexModalIsLoading: false,
      modalType: '',
      currentLoadingArn: '',
    }));
  };
  handleFilterChange = (type, value) => {
    this.setState({ [type]: value, isGetTableDataButtonActive: true });
  };
  handleMonthChange = async (type, value) => {
    try {
      this.setState({ [type]: value });
      if (value.value !== this.state.selectedDate.value) {
        this.setState({ isLoading: true, isGetTableDataButtonActive: false });
        await this.props.commitmentStore.getCommitmentTypeData(
          value.value,
          this.props.commitmentType,
          this.state.selectedCommitmentService.value,
          this.state.selectedLinkedAccount.value,
        );
        this.setState({ isLoading: false, isGetTableDataButtonActive: true });
      }
    } catch {
      this.setState({ isLoading: false, isGetTableDataButtonActive: true });
    }
  };
  filtersAreValid = () => {
    const { usersStore } = this.props;
    const { currDispUserCloudAccountType } = usersStore;
    const { selectedCommitmentService, tempSelectedCommitmentService } = this.state;
    const isAws = currDispUserCloudAccountType === CLOUD_TYPE_IDS.AWS;
    if (isAws && !selectedCommitmentService && !tempSelectedCommitmentService) {
      return false;
    }
    return true;
  };
  prepareRiUtilizationTableColumns = (cloudType) => {
    if (cloudType === CLOUD_TYPE_IDS.AWS) {
      const { selectedCommitmentService } = this.state;
      if (selectedCommitmentService && selectedCommitmentService.value === 'ec2') {
        const recIdx = this.awsRiUtilizationTableColumns.findIndex((elem) => elem.name === 'riRecommLink');
        if (recIdx < 0) {
          const item = {
            name: 'riRecommLink',
            title: 'Recommendations',
            getCellValue: (row) => row.UtilizationPercentage,
          };
          const idx = this.awsRiUtilizationTableColumns.findIndex((elem) => elem.name === 'instanceType');
          const newRiUtilizationTableColumns = cloneDeep(this.awsRiUtilizationTableColumns);
          newRiUtilizationTableColumns.splice(idx + 1, 0, item);
          return newRiUtilizationTableColumns;
        }
      }
      return this.awsRiUtilizationTableColumns;
    }
    return this.azureRiUtilizationTableColumns;
  };

  prepareMonthOptions = () => {
    const { month: currMonth, year } = DateFilter.getCurrMonthAndYear();
    const arraySize = 24;
    const startMonth = currMonth - 24;
    const months = numberRangeArrayOfSize(arraySize, startMonth); // [...Array(currMonth).keys()];
    const options = months.map((month) => {
      /* eslint-disable-next-line no-nested-ternary */
      const decisiveYear = month < 0 ? (month < -12 ? year - 2 : year - 1) : year;
      /* eslint-disable-next-line no-nested-ternary */
      const decisiveMonth = month < 0 ? (month < -12 ? month + 24 : month + 12) : month;
      const date = new Date(decisiveYear, decisiveMonth, 1);
      const dateVal = `${decisiveYear}-${decisiveMonth < 10 ? '0' : ''}${decisiveMonth + 1}-01`;
      const label = date.toLocaleDateString('en-US', { year: 'numeric', month: 'long' });
      return { label, value: dateVal };
    });
    return options.reverse();
  };

  awsRiUtilizationTableColumns = [
    { name: 'linkedAccountId', title: 'Linked Account ID', getCellValue: (row) => row.linkedAccountId },
    { name: 'linkedAccountName', title: 'Linked Account', getCellValue: (row) => row.linkedAccountName },
    { name: 'instanceType', title: 'Instance Type', getCellValue: (row) => row.instanceType },
    { name: 'region', title: 'Region', getCellValue: (row) => row.region },
    {
      name: 'status',
      title: 'Status',
      getCellValue: ({ endDateTime }) => {
        const currDate = moment().format('YYYY-MM-DD');
        const formattedEndDate = moment(endDateTime).format('YYYY-MM-DD');
        const isActiveRow = formattedEndDate.localeCompare(currDate) >= 0;
        return isActiveRow ? 'Active' : 'Expired';
      },
    },
    { name: 'UtilizationPercentage', title: 'Utilization', getCellValue: (row) => row.UtilizationPercentage },
    { name: 'startDateTime', title: 'Start Date', getCellValue: (row) => row.startDateTime },
    { name: 'endDateTime', title: 'End Date', getCellValue: (row) => row.endDateTime },
    { name: 'TotalPotentialRISavings', title: 'Potential Savings', getCellValue: (row) => row.TotalPotentialRISavings },
    { name: 'NetRISavings', title: 'Net Savings', getCellValue: (row) => row.NetRISavings },
  ];
  azureRiUtilizationTableColumns = [
    { name: 'reservationId', title: 'Reservation Id', getCellValue: (row) => row.reservationId },
    { name: 'linkedAccount', title: 'Subscription', getCellValue: (row) => row.subscriptionName || row.subscriptionId },
    { name: 'skuName', title: 'SKU Name', getCellValue: (row) => row.skuName },
    { name: 'UtilizationPercentage', title: 'Utilization', getCellValue: (row) => +row.utilizationPercentage },
  ];
  SpUtilizationTableColumns = [
    { name: 'linkedAccountId', title: 'Account Id', getCellValue: (row) => row.linkedAccountId },
    { name: 'linkedAccountName', title: 'Account Name', getCellValue: (row) => row.linkedAccountName },
    { name: 'HourlyCommitment', title: 'Hourly Commitment', getCellValue: (row) => row.HourlyCommitment },
    { name: 'NetSavings', title: 'Net Savings', getCellValue: (row) => row.NetSavings },
    {
      name: 'status',
      title: 'Status',
      getCellValue: ({ endDateTime }) => {
        const currDate = moment().format('YYYY-MM-DD');
        const formattedEndDate = moment(endDateTime).format('YYYY-MM-DD');
        const isActiveRow = formattedEndDate.localeCompare(currDate) >= 0;
        return isActiveRow ? 'Active' : 'Expired';
      },
    },
    { name: 'UtilizationPercentage', title: 'Utilization', getCellValue: (row) => row.UtilizationPercentage },
    { name: 'startDate', title: 'Start Date', getCellValue: (row) => moment(row.StartDateTime).format('YYYY-MM-DD') },
    { name: 'endDate', title: 'End Date', getCellValue: (row) => moment(row.EndDateTime).format('YYYY-MM-DD') },
  ];

  renderRiFlexOrChargeBackModal = () => {
    if (
      this.state.cbModalIsLoading ||
      this.state.flexModalIsLoading ||
      this.state.isRiFlexOrChargeBackDataIsAvailable
    ) {
      return (
        <RiFlexibilityOrChargeBackModal
          modalIsLoading={this.state.cbModalIsLoading || this.state.flexModalIsLoading}
          data={this.modalData()}
          title={this.modalTitle()}
          toggle={this.toggleRiFlexOrChargeBackModal}
          isOpen={
            this.state.cbModalIsLoading ||
            this.state.flexModalIsLoading ||
            this.state.isRiFlexOrChargeBackDataIsAvailable
          }
          type={this.state.modalType}
          commitmentType={this.props.commitmentType}
        />
      );
    }
    return null;
  };
  renderRiOrSpStatData = (data) => {
    if (this.props.commitmentType === 'ri') {
      return <RiUtilizationPieChartsPanel data={data} dontShowLegendForTitle="Unutilized RI By Account" />;
    }
    if (this.props.commitmentType === 'sp') {
      return <SavingsPlansStatPanel data={data} />;
    }
    return null;
  };

  render() {
    const { usersStore, commitmentType, commitmentStore, invoiceStore, usageStore } = this.props;
    const {
      isLoading,
      loadingTableData,
      selectedDate,
      tempSelectedCommitmentService,
      selectedLinkedAccount,
      selectedPayerAccount,
      isGetTableDataButtonActive,
    } = this.state;
    const { currDispUserCloudAccountType: cloudTypeId } = usersStore;
    if (isLoading || invoiceStore.isLoading) {
      return <Spinner />;
    }
    const isShowCommitmentStatData = cloudTypeId === CLOUD_TYPE_IDS.AWS;
    const statData = commitmentStore.commitmentModel.getCommitmentChartsAndStatDataByType(commitmentType);
    const tableColumnsArr = this.getTableColumnsByType();
    return (
      <>
        <Row style={{ marginLeft: '1px' }}>
          <MonthPicker
            selectedDate={selectedDate}
            handleMonthChange={this.handleMonthChange}
            monthOptions={this.prepareMonthOptions()}
          />
        </Row>
        {isShowCommitmentStatData ? (
          <Row style={{ marginLeft: '1px' }}>{this.renderRiOrSpStatData(statData)}</Row>
        ) : null}
        <Row style={{ marginLeft: '1px' }}>
          <CommitmentTablePanel
            getCommitmentTableData={this.getCommitmentTableData}
            handleFilterChange={this.handleFilterChange}
            loadingTableData={loadingTableData}
            invoiceStore={invoiceStore}
            usageStore={usageStore}
            commitmentStore={commitmentStore}
            selectedCommitmentService={tempSelectedCommitmentService}
            selectedLinkedAccount={selectedLinkedAccount}
            selectedPayerAccount={selectedPayerAccount}
            isGetTableDataButtonActive={isGetTableDataButtonActive}
            getRiFlexabilityData={this.getRiFlexabilityData}
            getChargeBackData={this.getChargeBackData}
            commitmentServiceOptions={this.getServiceOptions()}
            commitmentType={commitmentType}
            tableColumns={tableColumnsArr}
          />
        </Row>
        {this.renderRiFlexOrChargeBackModal()}
      </>
    );
  }
}

const ObserverCommitmentTypeUtilizationPage = observer(CommitmentTypeUtilizationPage);
export default ObserverCommitmentTypeUtilizationPage;
