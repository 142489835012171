import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import apiConstants from 'shared/api/apiConstants';
import { API } from 'shared/utils/apiMiddleware';
import { isAccountAllAccounts } from '../../utils/userUtil';
import { useRootStore } from '~/app/contexts/RootStoreContext.jsx';

const root = '/api/v1/users/roles';

const fetchRolesApi = () => API.get('billings', root);
const fetchDefaultRoleApi = async () => {
  const response = await API.get('billings', `${root}/default`);
  return response.defaultRoleId;
};
const fetchAllCloudAccountsLinkedAccountsApi = (accountsIdsAndClouds) => {
  const accountsWithoutTenant = accountsIdsAndClouds?.filter((acc) => !isAccountAllAccounts(acc));
  const accIdsAndClouds = accountsWithoutTenant?.map((acc) => ({
    accountId: acc.accountId,
    cloudTypeId: acc.cloudTypeId,
  }));
  return API.post('billings', `${root}/linked-accounts`, { body: accIdsAndClouds });
};
const createNewRoleApi = (data) => API.post('billings', root, { body: data });
const updateRoleApi = (data) => API.put('billings', root, { body: data });
const updateRolesBatchApi = (data) => API.put('billings', `${root}/batch`, { body: data });
const deleteRoleApi = (uuid) => API.del('billings', `${root}?uuid=${uuid}`);

export default function useRoles() {
  const queryClient = useQueryClient();
  const queryKey = apiConstants.QUERY_KEYS.ROLES;
  const { appStore } = useRootStore();
  return {
    fetchRoles: (options = {}) =>
      useQuery(['fetchRolesIs', apiConstants.QUERY_KEYS.ALL_ROLES], () => fetchRolesApi(), {
        enabled: !appStore.isKeyCloakManagement,
        ...options,
      }),
    fetchDefaultRole: (options = {}) =>
      useQuery(['fetchRolesIs', apiConstants.QUERY_KEYS.ALL_ROLES, 'default'], () => fetchDefaultRoleApi(), {
        ...options,
      }),
    fetchAllCloudAccountsLinkedAccounts: (data, options) =>
      useQuery([queryKey, JSON.stringify(data)], () => fetchAllCloudAccountsLinkedAccountsApi(data), {
        staleTime: 10 * 1000,
        ...options,
      }),
    createNewRole: () =>
      useMutation(createNewRoleApi, {
        onSuccess: () => queryClient.invalidateQueries(queryKey),
      }),
    updateRole: () =>
      useMutation(updateRoleApi, {
        onSuccess: () => queryClient.invalidateQueries(queryKey),
      }),
    updateRolesBatch: () =>
      useMutation(updateRolesBatchApi, {
        onSuccess: () => queryClient.invalidateQueries(queryKey),
      }),
    deleteRole: () =>
      useMutation(deleteRoleApi, {
        onSuccess: () => queryClient.invalidateQueries(queryKey),
      }),
  };
}
