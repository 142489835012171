import React, { useState } from 'react';
import PropTypes from 'prop-types';
import useRoles from 'users/new-user-management/hooks/reactQuery/useRoles';
import DataAccessContent from 'users/containers/Organization/Roles/rolesExpandedRowComponents/DataAccess/DataAccessContent';
// import { useRootStore } from 'app/contexts/RootStoreContext';

const DataAccessTab = ({ row }) => {
  const [isInProgress, setIsInProgress] = useState(false);

  const { updateAccountsAccessibilityForRole, updateCostCentersAccessibilityForRole } = useRoles();

  const handleRemoveLinkedAccounts = async (payload) => {
    setIsInProgress(true);
    await updateAccountsAccessibilityForRole.mutateAsync({ roleId: row?.id, roleDataAccess: payload });
    setIsInProgress(false);
  };

  const handleRemoveCostCenter = async (costCenterToRemove) => {
    setIsInProgress(true);

    const payload = {
      itemsToRemove: [costCenterToRemove.id],
    };
    await updateCostCentersAccessibilityForRole.mutateAsync({
      roleId: row.id,
      roleDataAccess: payload,
    });
    setIsInProgress(false);
  };

  return (
    <DataAccessContent
      roleId={row.id}
      isCreateEditMode={false}
      isInProgress={isInProgress}
      onRemoveLinkedAccounts={handleRemoveLinkedAccounts}
      onRemoveCostCenters={handleRemoveCostCenter}
    />
  );
};

DataAccessTab.propTypes = {
  row: PropTypes.shape({
    id: PropTypes.string,
  }),
};

export default DataAccessTab;
