import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { GranularityLevelsTypes } from 'shared/constants/appConstants';
import ButtonMultiDropdown from 'shared/components/andtComponents/ButtonMultiDropdown';
import DatePickerFilter from 'shared/components/DatePickerFilter';
import { ReactComponent as PlusIcon } from 'shared/img/icons/add.svg';
import { ReactComponent as ListIcon } from 'shared/img/icons/list.svg';
import { ReactComponent as ExportIcon } from 'shared/img/icons/download-solid.svg';
import { segmentEvent } from 'shared/modules/segmentAndAptrinsicHandler';
import { anomalyDetectionProvider } from 'usage/containers/AnomalyDetection/hooks/react-query/useAnomalyDetection';
import CustomCSVDownload from 'shared/components/buttons/CustomCSVDownload';
import Spinner from 'shared/components/andtComponents/Spinner';
import Pagination from 'shared/components/andtComponents/Pagination';
import { getTableColumns } from '../anomalyDetectionHelpers';
import { FILTER_PARAMS_KEYS } from '../anomalyDetectionConstants';
import styles from './actionBar.module.scss';
import SearchInput from './SearchInput';

const ActionsBar = ({
  onSearchChanged,
  anomaliesFilterParams = {},
  onDateChange,
  alertRulesCounter = 0,
  openAlertRulesListModal,
  openAddAlertRuleModal,
  setIsAlerted,
  isAlerted = true,
  isAlertRulesLoading = false,
  mapLinkedAccIdToDivisionName = new Map(),
  isPpApplied = false,
  currPageIdx,
  onPageChange,
  totalPages,
  isNextPageLoading,
}) => {
  const [exportAnomaliesLoading, setExportAnomaliesLoading] = useState(false);
  const [exportAlertsLoading, setExportAlertsLoading] = useState(false);
  const [dates, setDatesFilter] = useState({
    startDate:
      anomaliesFilterParams[FILTER_PARAMS_KEYS.START_DATE] ||
      moment().subtract(1, 'months').startOf('month').format('YYYY-MM-DD'),
    endDate: anomaliesFilterParams[FILTER_PARAMS_KEYS.END_DATE] || moment().format('YYYY-MM-DD'),
  });
  const { fetchAllAnomalies } = anomalyDetectionProvider(
    {
      [FILTER_PARAMS_KEYS.START_DATE]: dates.startDate,
      [FILTER_PARAMS_KEYS.END_DATE]: dates.endDate,
      [FILTER_PARAMS_KEYS.SEARCH]: anomaliesFilterParams[FILTER_PARAMS_KEYS.SEARCH],
    },
    isPpApplied,
  );
  const { fetchAllAnomalies: fetchAllAnomaliesAlerts } = anomalyDetectionProvider(
    {
      [FILTER_PARAMS_KEYS.START_DATE]: dates.startDate,
      [FILTER_PARAMS_KEYS.END_DATE]: dates.endDate,
      [FILTER_PARAMS_KEYS.SEARCH]: anomaliesFilterParams[FILTER_PARAMS_KEYS.SEARCH],
      [FILTER_PARAMS_KEYS.ALERTED]: true,
    },
    isPpApplied,
  );

  useEffect(() => {
    const filterParamsStartDate = anomaliesFilterParams[FILTER_PARAMS_KEYS.START_DATE];
    const filterParamsEndDate = anomaliesFilterParams[FILTER_PARAMS_KEYS.END_DATE];
    if (filterParamsStartDate !== dates.startDate || filterParamsEndDate !== dates.endDate) {
      onDateChange(dates);
    }
  }, [dates]);

  const getCsvData = (isAlerts, data) => {
    const columns = getTableColumns(isAlerts, mapLinkedAccIdToDivisionName).filter(
      ({ name }) => !['alerts', 'explore'].includes(name),
    );
    return data.map((item) => {
      const csvItem = { uuid: item.uuid };
      columns.forEach(
        ({
          name,
          title,
          getCellValue = (row, col) => {
            if (row[col]?.length) {
              switch (col) {
                case 'comments':
                  return row[col]
                    .map(
                      (comment) =>
                        `[${moment.unix(comment.createdAt).format('MMM DD YYYY, HH:mm')}] ${comment.createdBy}: ${
                          comment.comment
                        }`,
                    )
                    .join('\n');
                case 'userStatuses':
                  return row[col][row[col].length - 1] === 'DEFAULT' ? '' : row[col][row[col].length - 1].status;
                case 'feedbacks':
                  return `POSITIVE: ${row[col].reduce(
                    (acc, { feedback }) => (feedback ? acc + 1 : acc),
                    0,
                  )}\nNEGATIVE: ${row[col].reduce((acc, { feedback }) => (feedback ? acc : acc + 1), 0)}`;
                default:
                  return row[col];
              }
            }
            return row[col];
          },
        }) => {
          csvItem[title.trim() || name] = getCellValue(item, name);
        },
      );
      return csvItem;
    });
  };

  const exportAnomalies = async () => {
    try {
      setExportAnomaliesLoading(true);
      const anomalies = await fetchAllAnomalies();
      const data = getCsvData(false, anomalies.anomalies);
      setExportAnomaliesLoading(false);
      return [{ data, filename: `Anomalies_${dates.startDate}-${dates.endDate}.csv` }];
    } catch (error) {
      setExportAnomaliesLoading(false);
      return [];
    }
  };

  const exportAlerts = async () => {
    try {
      setExportAlertsLoading(true);
      const alerts = await fetchAllAnomaliesAlerts();
      const data = getCsvData(true, alerts.anomalies);
      setExportAlertsLoading(false);
      return [{ data, filename: `Alerts_${dates.startDate}-${dates.endDate}.csv` }];
    } catch (error) {
      setExportAlertsLoading(false);
      return [];
    }
  };

  const getExportButton = (text, exportFunc, loading) => (
    <CustomCSVDownload
      fetchData={exportFunc}
      filesNumber={1}
      isLoading={false}
      showDownloadIcon={false}
      hideText
      className={`btn-no-style ${styles['export-button-container']}`}
    >
      <span className="d-flex">
        <ExportIcon className={styles.export} />
        <span className="me-3">{text}</span>
        {loading && (
          <div style={{ position: 'relative' }}>
            <Spinner size={30} />
          </div>
        )}
      </span>
    </CustomCSVDownload>
  );

  const rulesDropdownOpts = [
    {
      text: 'New Alert',
      icon: () => <PlusIcon className={`${styles.plus}`} />,
      disabled: false,
      onClick: openAddAlertRuleModal,
      handler: openAddAlertRuleModal,
      id: 'newAlert',
    },
    {
      text: 'Alert Rules List',
      icon: ListIcon,
      disabled: false,
      onClick: openAlertRulesListModal,
      handler: openAlertRulesListModal,
      id: 'alertRulesList',
    },
  ];
  const exportDropdownOpts = [
    {
      text: 'Export Anomalies',
      icon: ExportIcon,
      disabled: exportAnomaliesLoading,
      handler: exportAnomalies,
      id: 'exportAnomalies',
      simpleOptionContent: <li>{getExportButton('Export Anomalies', exportAnomalies, exportAnomaliesLoading)}</li>,
    },
    {
      text: 'Export Alerts',
      Icon: ExportIcon,
      disabled: exportAlertsLoading,
      handler: exportAlerts,
      id: 'exportAlerts',
      simpleOptionContent: (
        <li className={styles.downloadCsvOptionContainer}>
          {getExportButton('Export Alerts', exportAlerts, exportAlertsLoading)}
        </li>
      ),
    },
  ];

  const changeDate = ({ startDate, endDate }) => {
    setDatesFilter({
      startDate: moment(startDate).format('YYYY-MM-DD'),
      endDate: moment(endDate).format('YYYY-MM-DD'),
    });
  };

  const toggleIsAlerted = (value) => {
    // report to segment
    segmentEvent({
      target: `anomalyDetectionTab${value ? 'Alerts' : 'AllAnomalies'}`,
    });
    setIsAlerted(value);
  };

  return (
    <div className={styles.actionBarContainer}>
      <div className="d-flex">
        <div className={`d-flex align-items-center me-2 ${styles['tabs-container']}`}>
          <div className={`me-2 ${isAlerted ? styles.selected : ''}`} onClick={() => toggleIsAlerted(true)}>
            Alerts
          </div>
          <div className={!isAlerted ? styles.selected : ''} onClick={() => toggleIsAlerted(false)}>
            All anomalies
          </div>
        </div>
        <div className="me-2">
          <DatePickerFilter
            onDateChange={changeDate}
            startDate={dates.startDate}
            endDate={dates.endDate}
            currPeriodGranLevel={GranularityLevelsTypes.GRAN_LEVEL_DAILY}
            isDateRangeError={false}
            andtLook
            forceNoMaxDate
            className={styles.height}
          />
        </div>
        <SearchInput
          onSearchChanged={onSearchChanged}
          searchInputValue={anomaliesFilterParams[FILTER_PARAMS_KEYS.SEARCH]}
        />
      </div>
      <div className="d-flex">
        <ButtonMultiDropdown
          dropdownProps={{
            icon: ExportIcon,
            overrideButtonStyles: {
              marginInlineEnd: '10px',
            },
          }}
          items={exportDropdownOpts
            .filter((opt) => !!opt.handler)
            .map(({ handler, icon, text, content, simpleOptionContent, id, disabled }, index) => ({
              id: id || index,
              simpleOption: true,
              label: content || text,
              simpleOptionContent,
              icon,
              disabled,
              onClick: handler,
            }))}
        />
        <ButtonMultiDropdown
          dropdownProps={{
            disabled: isAlertRulesLoading,
            isLoading: isAlertRulesLoading,
            text: `Alert configuration (${alertRulesCounter})`,
            tooltipText: `Alert configuration (${alertRulesCounter})`,
            isSecondary: false,
            overrideButtonStyles: {
              marginInlineEnd: '10px',
              height: '35px',
              fontFamily: 'Roboto',
              fontSize: '14px',
              backgroundColor: '#e9e9f0',
              color: '#3d4c59',
            },
          }}
          overrideButtonStyles={{
            marginInlineEnd: '10px',
            height: '35px',
            fontFamily: 'Roboto',
            fontSize: '14px',
          }}
          automationId="alertConfiguration"
          items={rulesDropdownOpts
            .filter((opt) => !!opt.handler)
            .map(({ handler, icon, text, content, simpleOptionContent, id, disabled }, index) => ({
              id: id || index,
              simpleOption: true,
              label: content || text,
              simpleOptionContent,
              icon,
              disabled,
              onClick: handler,
            }))}
        />
        <Pagination
          currPageIdx={currPageIdx}
          onPageChange={onPageChange}
          totalPages={totalPages}
          isNextPageLoading={isNextPageLoading}
        />
      </div>
    </div>
  );
};

ActionsBar.propTypes = {
  onSearchChanged: PropTypes.func.isRequired,
  onDateChange: PropTypes.func.isRequired,
  openAlertRulesListModal: PropTypes.func.isRequired,
  openAddAlertRuleModal: PropTypes.func.isRequired,
  setIsAlerted: PropTypes.func.isRequired,
  anomaliesFilterParams: PropTypes.object,
  alertRulesCounter: PropTypes.number,
  isAlerted: PropTypes.bool,
  isAlertRulesLoading: PropTypes.bool,
  isPpApplied: PropTypes.bool,
  mapLinkedAccIdToDivisionName: PropTypes.object,
};

export default ActionsBar;
