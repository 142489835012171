import React from 'react';
import PropTypes from 'prop-types';
import Paper from '@mui/material/Paper';
import {
  FilteringState,
  IntegratedFiltering,
  IntegratedSorting,
  DataTypeProvider,
  SortingState,
} from '@devexpress/dx-react-grid';
import {
  Grid,
  TableHeaderRow,
  Toolbar,
  TableColumnVisibility,
  ColumnChooser,
  TableFilterRow,
  TableColumnResizing,
} from '@devexpress/dx-react-grid-material-ui';
import { Col } from 'reactstrap';
import { CSVLink } from 'react-csv';
import { kFormatter } from 'shared/utils/strUtil';
import { RdsIsActiveValues } from 'usage/constants/usageConstants';
import TableWrapper from 'shared/components/tables/TableWrapper';
import { withUserSettingsConsumer } from 'users/utils/contexts/UserSettingsContext';

class AzureDbCostsTable extends React.PureComponent {
  static propTypes = {
    data: PropTypes.object.isRequired,
    columns: PropTypes.object.isRequired,
    columnWidths: PropTypes.object.isRequired,
    tableColumnExtensions: PropTypes.object.isRequired,
    defaultHiddenColumnNames: PropTypes.object.isRequired,
    defaultSorting: PropTypes.object.isRequired,
    currenciesColumns: PropTypes.object.isRequired,
    csvTitle: PropTypes.object.isRequired,
    getCurrencyNumber: PropTypes.func.isRequired,
  };
  constructor(props) {
    super(props);
    const { columns, columnWidths, tableColumnExtensions, defaultHiddenColumnNames, data, currenciesColumns } =
      this.props;
    this.state = {
      columns,
      columnWidths,
      tableColumnExtensions,
      hiddenColumnNames: defaultHiddenColumnNames,
      rows: data,
      currenciesColumns,
    };
  }

  getColor = (row) => {
    if (row.isActive === RdsIsActiveValues.ACTIVE) {
      return '#449d44';
    }
    return '#d95350';
  };

  isActiveFormatter = (data) => (
    <div
      style={{ 'background-color': this.getColor(data.row), height: '15px', width: '15px', 'border-radius': '50%' }}
    />
  );

  currenciesFormatter = (value) => this.props.getCurrencyNumber(kFormatter(value.value));

  changeColumnWidths = (columnWidths) => {
    this.setState({ columnWidths });
  };

  hiddenColumnNamesChange = (hiddenColumnNames) => {
    this.setState({ hiddenColumnNames });
  };

  render() {
    const { rows, columns, columnWidths, tableColumnExtensions, hiddenColumnNames, currenciesColumns } = this.state;
    const { csvTitle, defaultSorting } = this.props;
    const csvModifiedRows = JSON.parse(JSON.stringify(rows));

    return (
      // <div style={{ width: '100%' }} ref={this.refCallback}>
      // </div>
      (<>
        <Col xs={12} md={12} lg={12} xl={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <CSVLink data={csvModifiedRows} headers={columns.title} filename={`${csvTitle}.csv`}>
            Export as CSV
            <span className="lnr lnr-download" style={{ fontSize: '18px', marginLeft: '8px', marginBottom: '8px' }} />
          </CSVLink>
        </Col>
        <Col xs={12} md={12} lg={12} xl={12}>
          <Paper>
            <Grid rows={rows} columns={columns}>
              <FilteringState defaultFilters={[]} />
              <IntegratedFiltering />
              <SortingState defaultSorting={defaultSorting} />
              <IntegratedSorting />
              <DataTypeProvider for={currenciesColumns} formatterComponent={this.currenciesFormatter} />
              <DataTypeProvider for={['isActive']} formatterComponent={this.isActiveFormatter} />
              <TableWrapper columnExtensions={tableColumnExtensions} />
              <TableColumnResizing columnWidths={columnWidths} onColumnWidthsChange={this.changeColumnWidths} />
              <TableHeaderRow showSortingControls />
              <TableFilterRow />
              <TableColumnVisibility
                hiddenColumnNames={hiddenColumnNames}
                onHiddenColumnNamesChange={this.hiddenColumnNamesChange}
              />
              <Toolbar />
              <ColumnChooser />
            </Grid>
          </Paper>
        </Col>
      </>)
    );
  }
}

const ObserverAzureDbCostsTable = withUserSettingsConsumer(AzureDbCostsTable);
export default ObserverAzureDbCostsTable;
