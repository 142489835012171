import moment from 'moment';
import {CLOUD_TYPE_IDS} from 'users/constants/usersConstants.js';
import DateFilter from 'shared/modules/dateFilter.js';
import { isAccountAllAccounts } from 'users/utils/userUtil.js';

export const getFirstDayOfMonth = (date) => {
    return moment(date).startOf('month').format('YYYY-MM-DD');
};
export const getFirstAndLastDayOfPreviousMonth = () => {
    const firstDay = moment(DateFilter.getDate()).subtract(1, 'months').startOf('month').format('YYYY-MM-DD');
    const lastDay = moment(DateFilter.getDate()).subtract(1, 'months').endOf('month').format('YYYY-MM-DD');

    return {
        start: firstDay,
        end: lastDay,
    };
};

export const sumTotalCost = (data) => {
    return data?.reduce((acc, item) => acc + (item.total_cost || 0), 0);
};

export const compareFormatterNumber = (a, b) => {
    const numA =  Number((a.split(' ')[1] || a.split(' ')[0]).replace(/,/g, ''));
    const numB =  Number((b.split(' ')[1] || b.split(' ')[0]).replace(/,/g, ''));
    return numA - numB ;
};
export const getSearchParamsPanel = (params, isPpApplied) => {
    const filterParams = new URLSearchParams();

    filterParams.set('currCostType', JSON.stringify(params.costType));
    filterParams.set('fieldToFilterdValuesMap', JSON.stringify({}));
    filterParams.set('excludedFiltersStatusMap', JSON.stringify({}));
    filterParams.set('likeFiltersStatus',JSON.stringify({}));
    filterParams.set('startDate',  params.start);
    filterParams.set('endDate',  params.end);
    filterParams.set('currPeriodGranLevel', params.periodGranLevel);
    filterParams.set('selectedGranLevel', params.periodGranLevel);
    filterParams.set('currentGroupBy', params.groupBy);
    filterParams.set('filterBarGroupBy', params.groupBy);
    filterParams.set('isCumulative', false);
    filterParams.set('isPpApplied', isPpApplied);

    return `?${filterParams.toString()}`;

}

export const getIncludeOptions = (usersStore) => {
   const { currDispUserCloudAccountType } = usersStore;
   switch(currDispUserCloudAccountType){
      case CLOUD_TYPE_IDS.AWS:
           return [ {
               value: 'support',
               label: 'Support',
           },{
               value: 'tax',
               label: 'Tax',
           },{
               value: 'marketplace',
               label: 'Marketplace',
           }];
      case CLOUD_TYPE_IDS.AZURE:
           return [{
                   value: 'marketplace',
                   label: 'Marketplace',
           }];
      case CLOUD_TYPE_IDS.GCP:
          return [];
   }
   return null;
}

export const getFilterParams = (filters) => {
    const params = new URLSearchParams();

    Object.keys(filters).forEach(key => {
        filters[key].forEach(value => {
            params.append(`filters[${key}]`, value);
        });
    });
    return `&${params.toString()}`;
}

export const getGroupSettingByAccount = (usersStore) => {
  if (isAccountAllAccounts(usersStore.getCurrDisplayedAccount)) {
    return 'payeraccount';
  }
  if (usersStore.currDispUserCloudAccountType === CLOUD_TYPE_IDS.MULTI) {
    return 'cloudprovider';
  }
  return 'none'

}
