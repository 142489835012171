/* eslint-disable no-unused-vars */
import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'reactstrap';
import Panel from 'shared/components/Panel';
import PieChartWithTotalSummary from 'shared/components/PieChartWithTotalSummary';

const RiUtilizationPieChartsPanel = (props) => {
  const renderPieCharts = (chartsData, dontShowLegendForTitle = '') =>
    chartsData.map((data) => (
      <Col xl={3} lg={3} md={12} sm={12} xs={12}>
        <PieChartWithTotalSummary data={data} dontShowLegendForTitle={dontShowLegendForTitle} />
      </Col>
    ));

  return (
    <Panel divider title="RI Utilization summary">
      <Row>{renderPieCharts(props.data, props.dontShowLegendForTitle)}</Row>
    </Panel>
  );
};

RiUtilizationPieChartsPanel.propTypes = {
  data: PropTypes.object.isRequired,
  dontShowLegendForTitle: PropTypes.string,
};

export default RiUtilizationPieChartsPanel;
