/* eslint-disable no-unused-vars */
import React from 'react';
/* eslint-disable arrow-parens */
import { Area, AreaChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { kIntFormmater } from 'shared/utils/strUtil';
import PropTypes from 'prop-types';
import { useUserSettingsContext } from 'users/utils/contexts/UserSettingsContext';

const AvgHourlyCostChart = ({ data, selectedHourlyCost, height = 284, hasSelectedSavingsPlan = true }) => {
  const { currencySymbol } = useUserSettingsContext();
  return (
    <ResponsiveContainer className="dashboard__chart-pie" width="100%" height={height || 284}>
      <AreaChart data={data.avgHourlyCostData} margin={{ top: 20, right: 20, bottom: 0, left: 20 }}>
        {/* <XAxis tickFormatter={value => `${createDateDisplayStr(value)}`} dataKey="date" tickLine /> */}
        <XAxis
          dataKey="date"
          tickFormatter={(value) => {
            if (value.toLowerCase().indexOf('w') > -1) {
              return value;
            }
            if (value.length > 0 && value.length < 9) {
              return new Date(value).toLocaleString('en-US', { month: 'short', year: 'numeric' });
            }
            return new Date(value).toLocaleString('en-US', { month: 'short', day: 'numeric' });
          }}
        />
        <YAxis
          tickLine
          domain={[0, (dataMax) => (dataMax > 0.5 ? Math.ceil(dataMax) : dataMax)]}
          label={{
            value: 'Average Hourly Cost',
            offset: -5,
            angle: -90,
            position: 'left',
          }}
          tickFormatter={(value) => `${currencySymbol}${kIntFormmater(value, 2, false)}`}
        />
        <Tooltip formatter={(value) => ` ${currencySymbol}${kIntFormmater(value, 2, false)}`} itemSorter={() => 1} />
        <Legend />
        <CartesianGrid strokeDasharray="3 3" />
        <Area
          name="On-Demand"
          type="monotone"
          dataKey="On-Demand"
          fill="#EC7C79"
          stroke="#EC7C79"
          fillOpacity={0.4}
          stackId="21"
        />
        {/* <Area name="On-Demand" type="monotone" dataKey="On-Demand"
       fill="#5EB6F9" stroke="#5EB6F9" fillOpacity={0.4} stackId="21" /> */}
        {data.hasSavingsPlans ? (
          <Area
            name="Purchased Savings Plans Coverage"
            type="monotone"
            dataKey="Purchased Savings Plans Coverage"
            fill="#4ce2b6"
            stroke="#4ce2b6"
            fillOpacity={0.4}
            stackId="1"
          />
        ) : null}
        {hasSelectedSavingsPlan && (
          <Area
            name="Selected Savings Plan Coverage"
            type="monotone"
            dataKey="Savings Plan Coverage"
            fill="#d9db69"
            stroke="#d9db69"
            fillOpacity={0.4}
            stackId="1"
          />
        )}
        {data.hasComputeSavingsPlans ? (
          <Area
            name="Compute Savings Plans"
            type="monotone"
            dataKey="Compute Savings Plans"
            fill="#4ce2b6"
            stroke="#4ce2b6"
            fillOpacity={0.4}
            stackId="1"
          />
        ) : null}
        {data.hasEc2SavingsPlans ? (
          <Area
            name="EC2 Savings Plans"
            type="monotone"
            dataKey="EC2 Savings Plans"
            fill="#6c5c84"
            stroke="#6c5c84"
            fillOpacity={0.8}
            stackId="1"
          />
        ) : null}
      </AreaChart>
    </ResponsiveContainer>
  );
};

AvgHourlyCostChart.propTypes = {
  data: PropTypes.object.isRequired,
  selectedHourlyCost: PropTypes.object.isRequired,
  height: PropTypes.number,
  hasSelectedSavingsPlan: PropTypes.bool,
};

export default AvgHourlyCostChart;
