import moment from 'moment';
import DateFilter from 'shared/modules/dateFilter.js';

const currentMonth = moment(DateFilter.getDate()).format('MMMM');
const previousMonth = moment(DateFilter.getDate()).subtract(1, 'months').format('MMMM');

const BASE_PARAMS = {
        secondaryGroupBy: 'usagedate',
        whereParams: '',
        filterParams: '',
        costType: ['cost', 'discount'],
        isAmortized: false,
        isNetAmortized: false,
        isNetUnblended: false,
        isPublicCost: false,
        isDistributed: false
    }
export const PANELS_TYPES = {
    MTD_COST: 'mtd_cost',
    PREVIOUS_MONTH_COST: 'previous_month_cost',
    ANNUAL_POTENTIAL_SAVINGS: 'annual_potential_savings',
    FORECASTED_MONTHLY_COST: 'forecasted_monthly_cost',
    OPEN_RECOMMENDATIONS: 'open_recommendations',
    MTD_TOP_10: 'mtd_top_10',
    HISTORY_DAILY_WITH_FORECAST: 'history_daily_with_forecast',
    HISTORY_MONTHLY_WITH_FORECAST: 'history_monthly_with_forecast',
    REGION_COST: 'region_cost',
    LINKED_ACCOUNT_TOTAL: 'linked_account_total',
}

export const PANELS = {
    [PANELS_TYPES.MTD_COST]: {
        title: 'MTD costs',
        info: 'Month-to-date',
        params: {
            ...BASE_PARAMS,
            groupBy: 'none',
            periodGranLevel: 'month',
        },
        secondTitle: 'Previous MTD Cost',
    },
    [PANELS_TYPES.PREVIOUS_MONTH_COST]: {
        title: 'Previous Month Total Costs',
        params: {
            ...BASE_PARAMS,
            groupBy: 'none',
            periodGranLevel: 'day',
        }
    },
    [PANELS_TYPES.MTD_TOP_10]: {
        title: 'Month to Date Top 10',
        params: {
            ...BASE_PARAMS,
            groupBy: 'service',
            periodGranLevel: 'month',
        }
    },
    [PANELS_TYPES.ANNUAL_POTENTIAL_SAVINGS]: {
        title: 'Annual Potential Savings',
        info: 'Annual Potential Savings based on open recommendations from Waste Detector page.',
    },
    [PANELS_TYPES.FORECASTED_MONTHLY_COST]: {
        title: 'Forecasted Monthly Cost',
        info: 'Expected Monthly Costs based on the previous month usage.'
    },
    [PANELS_TYPES.OPEN_RECOMMENDATIONS]: {
        title: 'Open Recommendations',
    },
    [PANELS_TYPES.HISTORY_DAILY_WITH_FORECAST]: {
        title: `Daily Costs: ${currentMonth} over ${previousMonth}`,
        params: {
            ...BASE_PARAMS,
            groupBy: 'none',
            periodGranLevel: 'day',
        }
    },
    [PANELS_TYPES.HISTORY_MONTHLY_WITH_FORECAST]: {
        title: 'Monthly Costs',
        params: {
            ...BASE_PARAMS,
            groupBy: 'none',
            periodGranLevel: 'month',
        }
    },
    [PANELS_TYPES.REGION_COST]: {
        title: 'Cost by Region',
        params: {
            ...BASE_PARAMS,
            groupBy: 'region',
            periodGranLevel: 'day',
        }
    },
    [PANELS_TYPES.LINKED_ACCOUNT_TOTAL]: {
        title: 'Linked Accounts',
        params: {
            ...BASE_PARAMS,
            groupBy: 'linkedaccid',
            periodGranLevel: 'month',
        }
    }
}

export const supportServices = ["AWS Support [Developer]", "AWS Support [Business]", "AWS Support [Enterprise]", "AWS Premium Support"];
export const marketPlaceFamilyTypes = ["AWS Marketplace"];
export const marketPlaceService = ["Marketplace"];
