import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Checkbox from 'shared/components/andtComponents/Checkbox';
import SwitchButton from 'shared/components/andtComponents/Switch';
import RadioButtonList from 'shared/components/andtComponents/RadioButtonList';
import NoRebillingImg from './assets/no-rebilling-img.png';
import styles from './BillingProfile.module.scss';

const BillingProfileForm = ({
  setProfile,
  profile,
  hideCustomer = false,
  isSignleCustomerOnly = false,
  isMultiCustomersOnly = false,
}) => {
  const handleUpdateField = (field, val) => {
    setProfile({
      ...profile,
      [field]: val,
    });
  };

  const checkboxes = [
    {
      checked: profile.riApplication || profile.spApplication,
      onChange: (val) => {
        setProfile({
          ...profile,
          riApplication: val,
          spApplication: val,
        });
      },
      label: 'Reallocate RI + SP',
    },
    {
      checked: profile.tiers,
      onChange: (val) => handleUpdateField('tiers', val),
      label: 'Calculate all services with',
      subContent: (
        <RadioButtonList
          direction="row"
          className={styles.radioList}
          options={[
            { value: 'tiers', label: 'Tiers', disabled: !profile.rebilling || !profile.tiers, primary: true },
            {
              value: 'cloudfront-cost',
              label: 'Only CloudFront public cost',
              disabled: !profile.rebilling || !profile.tiers,
              primary: true,
            },
          ]}
          value={profile.tiersCustom ? 'cloudfront-cost' : 'tiers'}
          onChange={(val) => {
            handleUpdateField('tiersCustom', val === 'cloudfront-cost');
          }}
        />
      ),
    },
  ];
  return (
    <div className={styles.billingContainer}>
      <div className={classNames(styles.billingTab, { [styles.active]: profile.rebilling })}>
        <div className={styles.billingTabHeader}>
          <RadioButtonList
            options={[{ value: 'rebilling', label: 'Rebilling', primary: true }]}
            value={!profile.rebilling ? '' : 'rebilling'}
            onChange={() => {
              handleUpdateField('rebilling', true);
            }}
          />
        </div>
        <div className={styles.billingForm}>
          <p className={styles.desc}>Perform Rebilling based on the following options:</p>
          {!hideCustomer && (
            <RadioButtonList
              direction="row"
              options={[
                {
                  value: 'single-customer',
                  label: 'Single customer',
                  disabled: !profile.rebilling || isMultiCustomersOnly,
                  primary: true,
                },
                {
                  value: 'multiple-customers',
                  label: 'Multiple customers',
                  disabled: !profile.rebilling || isSignleCustomerOnly,
                  primary: true,
                },
              ]}
              value={profile.serviceCosts ? 'multiple-customers' : 'single-customer'}
              onChange={(val) => {
                handleUpdateField('serviceCosts', val === 'multiple-customers');
              }}
              disabled
            />
          )}
          {checkboxes.map((c) => (
            <div>
              <Checkbox
                primary
                text={c.label}
                isDisabled={!profile.rebilling}
                key={c.label}
                isChecked={c.checked}
                onChange={c.onChange}
              />
              {c.subContent || null}
            </div>
          ))}
          <SwitchButton
            isDisabled={!profile.rebilling}
            label="Transfer AWS credits to the Customer"
            isChecked={profile.copyAwsCredits}
            onChange={(val) => handleUpdateField('copyAwsCredits', val)}
          />
          <SwitchButton
            isDisabled={!profile.rebilling}
            label="Transfer EDP to the customer"
            isChecked={profile.edp}
            onChange={(val) => handleUpdateField('edp', val)}
          />
          <SwitchButton
            isDisabled={!profile.rebilling}
            label="Transfer Private Pricing to the Customer"
            isChecked={profile.privatePricing}
            onChange={(val) => handleUpdateField('privatePricing', val)}
          />
          <SwitchButton
            isDisabled={!profile.rebilling}
            label="Transfer Distributor Discount to the Customer"
            isChecked={profile.distributorDiscount}
            onChange={(val) => handleUpdateField('distributorDiscount', val)}
          />
        </div>
      </div>
      <div className={classNames(styles.billingTab, { [styles.active]: !profile.rebilling })}>
        <div className={styles.billingTabHeader}>
          <RadioButtonList
            options={[{ value: 'no-rebilling', label: 'No Rebilling', primary: true }]}
            value={profile.rebilling ? '' : 'no-rebilling'}
            onChange={() => {
              handleUpdateField('rebilling', false);
            }}
          />
        </div>
        <div className={styles.billingForm}>
          <p className={styles.desc}>
            Perform no Rebilling to your customers. <br /> Note that EDP, Tax, SPP and Private Pricing <br />
            will still be excluded.
          </p>
          <img src={NoRebillingImg} alt="Illustration" className={styles.imgIllustration} />
        </div>
      </div>
    </div>
  );
};

BillingProfileForm.propTypes = {
  profile: PropTypes.object.isRequired,
  setProfile: PropTypes.func.isRequired,
  hideCustomer: PropTypes.bool,
  isSignleCustomerOnly: PropTypes.bool,
  isMultiCustomersOnly: PropTypes.bool,
};

export default BillingProfileForm;
