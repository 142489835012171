 
import { RecommendationTypes } from 'recommendations/constants/recommendationsConstants';
import { AzureDiskUnattachedRecommendation } from 'recommendations/models/recommTypes';
import AzureCostRecommendationBuilder from 'recommendations/models/builders/azureCostRecommBuilder';

export default class AzureDiskUnattachedRecommendationBuilder extends AzureCostRecommendationBuilder {
  constructor(rawRecommendation) {
    super(rawRecommendation);
  }

  createRecommendation() {
    this._recommendation = new AzureDiskUnattachedRecommendation();
  }

  getRecommType() {
    return RecommendationTypes.AZURE_DISK_UNATTACHED;
  }

  buildRecommTitle() {
    const { recommended_action: action } = this._rawRecommendation;
    this._recommendation.title = `${action || ''} Disk Unattached`;
  }

  build() {
    super.build();
    const {
      unattached_disk_id: unattachedDiskId,
      disk_sku_name: diskSkuName,
      zone_tag: zoneTag,
      disk_size: diskSize,
      disk_name: diskName,
      disk_type: diskType,
      // potential_savings: potentialSavings,
      resource_group: resourceGroup,
      resource_id: resourceId,
    } = this._rawRecommendation;
    this._recommendation.region = zoneTag;
    this._recommendation.unattachedDiskId = unattachedDiskId;
    this._recommendation.diskSkuName = diskSkuName;
    this._recommendation.diskSize = diskSize;
    this._recommendation.diskName = diskName;
    this._recommendation.resourceGroup = resourceGroup;
    this._recommendation.resourceId = resourceId;

    this._recommendation.diskType = diskType;
    // this._recommendation.potentialSavings = potentialSavings;
  }
}
