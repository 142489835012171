 
import { isFamilyTypeHasTypes } from 'shared/utils/cloudUtils';
import { CostUsageStates } from 'usage/constants/costAndUsageConstants';
import BaseCostUsageDataState from './baseCostUsageDataState';

export default class FamilyTypeDataState extends BaseCostUsageDataState {
  constructor(context) {
    super(context);
  }

  getNewDataStateType(dataKey) {
    const { currDataState } = this.context.state;
    const { usersStore } = this.context.props;
    const { currDispUserCloudAccountType } = usersStore;
    let newDataState = currDataState;

    if (isFamilyTypeHasTypes(dataKey, currDispUserCloudAccountType)) {
      newDataState = CostUsageStates.TYPE;
    } else {
      newDataState = CostUsageStates.OPERATION;
    }

    return newDataState;
  }

  getDataStateType() {
    return CostUsageStates.FAMILY_TYPE;
  }
}
