import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Container } from 'reactstrap';
import { toast } from 'react-toastify';
import Spinner from 'shared/components/andtComponents/Spinner';
import { isOperatorInput, isOperatorSelect, OPERATORS_KEYS, PageNames } from 'shared/constants/appConstants';
import LinkIconButton from 'shared/components/buttons/LinkIconButton';
import IconFromPng from 'shared/components/IconFromPng';
import ICONS from 'shared/constants/assetsConstants';
import { UsersAccountTypeId, ACCOUNT_FEATURES } from 'users/constants/usersConstants';
import DeleteWarningModal from 'shared/components/DeleteWarningModal';
import PricingTable from 'shared/components/resellerPrivatePricing/AwsResellerPricingTable';
import AddOrUpdatePrivatePricingModal from 'shared/components/resellerPrivatePricing/AddOrUpdatePrivatePricingModal';
import { percentageMargins } from 'shared/components/resellerPrivatePricing/resellerPrivatePricingConstants';
import { AwsCommonFields } from 'shared/constants/awsConstants';
import { trimNonAlphanumericSymbols } from 'shared/utils/strUtil';
import { AzureCommonFields } from 'shared/constants/azureConstants';
import { useBillingRules } from 'divisions/containers/Customers/hooks/useCustomers';
import { PRIVATE_PRICING_DISCOUNT_TYPES } from 'divisions/constants/customersConstants';
import { useUserSettingsContext } from 'users/utils/contexts/UserSettingsContext';
import { useFiltersUsageTypes, useFiltersDistinctValues } from 'invoices/hooks/react-query/useInvoiceFilters';
import { preparePPobjectForDB } from './billingRuleHelpers';

const ALL_CUSTOMERS = 'Internal_Pileus:Account_id:All_Customers';

export const AWS_RESELLER_RATES_TABLE_COLUMNS = [
  // CLOUDRIDE PATCH
  { name: 'name', title: 'Rule Name' },
  { name: 'description', title: 'Description' },
  {
    name: 'margin',
    title: 'Margin',
  },
  {
    name: 'marginType',
    title: 'Margin Type',
    getCellValue: (row) =>
      row.marginType === 'fix' ? 'Fixed Cost' : `${row.marginType.charAt(0).toUpperCase() + row.marginType.slice(1)}`,
  },
  { name: 'frequency', title: 'Frequency' },
  {
    name: 'date',
    title: 'Start Month',
    getCellValue: (row) => {
      const { frequency, startDate, effectiveMonth } = row;
      if (frequency === 'recurring') {
        if (startDate) {
          return startDate.slice(0, 7);
        }
        return '';
      }
      return effectiveMonth;
    },
  },
  {
    name: 'endDate',
    title: 'End Month',
    getCellValue: (row) => {
      const { endDate } = row;
      return endDate ? endDate.slice(0, 7) : '';
    },
  },
  { name: 'userAction', title: ' ' },
];
export const AWS_RESELLER_RATES_TABLE_CURRENCY_COLUMNS = [];
export const AWS_RESELLER_RATES_TABLE_COLUMNS_WIDTHS = [
  { columnName: 'name', width: 200 },
  { columnName: 'description', width: 300 },
  { columnName: 'margin', width: 150 },
  { columnName: 'marginType', width: 200 },
  { columnName: 'frequency', width: 150 },
  { columnName: 'date', width: 150 },
  { columnName: 'endDate', width: 150 },
  { columnName: 'userAction', width: 150 },
];

export const AWS_RESELLER_RATES_TABLE_COLUMN_EXTENSIONS = [{ columnName: 'margin', align: 'center' }];

export const AddPricingButton = ({ handler }) => (
  <div style={{ display: 'flex', justifyContent: 'flex-start', margin: '0 0 10px 0', alignItems: 'center' }}>
    <div style={{ padding: '5px', marginRight: '10px' }}>
      <LinkIconButton>
        {/* eslint-disable-next-line jsx-a11y/anchor-has-content,jsx-a11y/anchor-is-valid */}
        <a
          href="#"
          onClick={(e) => {
            e.preventDefault();
            handler();
          }}
        />
        <IconFromPng size={30} icon={ICONS.plus} />
      </LinkIconButton>
    </div>
    <div style={{ fontSize: '1.2vw', padding: '5px' }}>
      <span>Add Billing Rule</span>
    </div>
  </div>
);

AddPricingButton.propTypes = {
  handler: PropTypes.func.isRequired,
};

const ResellerPrivatePricing = ({ usersStore, invoiceStore, divisionName }) => {
  const { usageTypesFetcher } = useFiltersUsageTypes();
  const { getCurrencyNumber } = useUserSettingsContext();
  const accountFeatures = usersStore.getCurrentDisplayedAccountFeatures(usersStore.currDispUserAccountKey);
  const { filtersValuesMap, filtersLoading, getPageFilters } = useFiltersDistinctValues({
    accountType: usersStore.currDispUserCloudAccountType,
    accountKey: usersStore.currDispUserAccountKey,
    fetchK8S: (accountFeatures || []).includes(ACCOUNT_FEATURES.K8S),
  });

  const defaultPricingParams = useRef({
    disableMinimumFee: false,
    isLinkedAccountsChecked: false,
    services: '',
    excludeService: false,
    divNames: divisionName ? [{ value: divisionName, label: divisionName }] : '',
    linkedAccounts: '',
    frequency: 'recurring',
    startDate: '',
    endDate: '',
    effectiveMonth: '',
    regions: '',
    cloudFrontRegions: '',
    margin: '',
    marginType: '',
    name: '',
    customService: '',
    isExcludeAwsMarketplace: false,
    payerAccount: '',
    usageTypeOperator: OPERATORS_KEYS.IS,
  });
  const defaultInputsErrorMessage = useRef({
    services: '',
    divNames: '',
    linkedAccounts: '',
    effectiveMonth: '',
    startDate: '',
    margin: '',
    marginType: '',
    name: '',
    customService: '',
    date: '',
  });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalState, setModalState] = useState('');
  const [currEditedId, setCurrEditedId] = useState(null);
  const [deletedPpId, setDeletedPpId] = useState(null);
  const [deletedPpRuleName, setDeletedPpRuleName] = useState(null);
  const [deletedPpPayerAccountId, setDeletedPpPayerAccountId] = useState(null);
  const [pricingParams, setPricingParams] = useState(defaultPricingParams.current);
  const [inputsErrorMessage, setInputsErrorMessage] = useState(defaultInputsErrorMessage.current);

  const { getBillingRules, postBillingRule, putBillingRule, delBillingRule } = useBillingRules();
  const { data: billingRulesData, isLoading: isBillingRulesLoading } = getBillingRules();
  const { isLoading: isAddingBillingRule, mutateAsync: addBillingRule } = postBillingRule();
  const { isLoading: isUpdatingBillingRule, mutateAsync: updateBillingRule } = putBillingRule();
  const { isLoading: isDeletingBillingRule, mutateAsync: deleteBillingRule } = delBillingRule();

  useEffect(() => {
    usageTypesFetcher.onLoad();
  }, []);

  const getIsMultiAccountsAccUser = () => {
    const currAccountTypeId = usersStore.getCurrAccountTypeId();
    return (
      currAccountTypeId === UsersAccountTypeId.RESELLER_MULTI_ACCOUNT ||
      currAccountTypeId === UsersAccountTypeId.RESELLER_MULTI_CLOUDS_ACCOUNT ||
      currAccountTypeId === UsersAccountTypeId.M3C_ACCOUNT
    );
  };
  const getScopeListOfValues = (payerAccListOfValues) => {
    if (payerAccListOfValues && payerAccListOfValues.length && pricingParams.payerAccount) {
      return usersStore.getDivisionValuesByPayerAccount(pricingParams.payerAccount);
    }
    return usersStore.getDivisionValues();
  };
  const toggleModal = () => {
    // eslint-disable-next-line max-len
    setIsModalOpen((prevState) => !prevState);
    setCurrEditedId(null);
    setPricingParams({ ...defaultPricingParams.current });
    setInputsErrorMessage({ ...defaultInputsErrorMessage.current });
  };
  const resetToBaseStateParams = () => {
    // eslint-disable-next-line max-len
    setCurrEditedId(null);
    setDeletedPpId(null);
    setDeletedPpRuleName(null);
    setDeletedPpPayerAccountId(null);
    setPricingParams({ ...defaultPricingParams.current });
    setInputsErrorMessage({ ...defaultInputsErrorMessage.current });
  };
  const handleOpenAddPricingModalClick = () => {
    setIsModalOpen(true);
    setModalState('add');
  };
  const isCustomServiceValid = (marginType, customService, services) => {
    let isValid = false;
    const isMarginOfType =
      marginType === PRIVATE_PRICING_DISCOUNT_TYPES.FIXED_COST ||
      marginType === PRIVATE_PRICING_DISCOUNT_TYPES.CUST_SER_CALC_COST;
    if (!isMarginOfType) {
      isValid = true;
    } else {
      const regexLettersNumbersSpace = /^[a-zA-Z0-9\s]*$/;
      if (customService?.length > 0 && customService?.length <= 30 && regexLettersNumbersSpace.test(customService)) {
        isValid = true;
      } else if (
        marginType === PRIVATE_PRICING_DISCOUNT_TYPES.FIXED_COST &&
        Array.isArray(services) &&
        services.some((ser) => ser.value !== 'Custom Service')
      ) {
        isValid = true;
      }
    }
    return isValid;
  };
  const newPricingDataIsValid = (pricingParams) => {
    const {
      divNames,
      linkedAccounts,
      services,
      regions,
      effectiveMonth,
      startDate,
      margin,
      marginType,
      name,
      customService,
      isLinkedAccountsChecked,
      payerAccount,
    } = pricingParams;
    inputsErrorMessage.services = !services ? 'Please select a service' : '';
    inputsErrorMessage.regions =
      !regions &&
      marginType !== 'remove-support' &&
      services &&
      !services.map((srv) => srv.value).includes('Data Transfer - Amazon CloudFront')
        ? 'Please select a region'
        : '';
    inputsErrorMessage.divNames = !isLinkedAccountsChecked && !divNames ? 'Please select a scope' : '';
    inputsErrorMessage.linkedAccounts = isLinkedAccountsChecked && !linkedAccounts ? 'Please select a scope' : '';
    inputsErrorMessage.date = !effectiveMonth && !startDate ? 'Please enter start date' : '';
    if (!['calc-cost', 'remove-support'].includes(marginType)) {
      inputsErrorMessage.margin = !margin ? 'Please enter margin amount' : '';
    } else {
      inputsErrorMessage.margin = '';
    }
    inputsErrorMessage.marginType = !marginType ? 'Please choose margin type' : '';
    inputsErrorMessage.name =
      !name || name.length > 100 ? "Please provide a name, name length can't exceed 100 characters" : '';
    inputsErrorMessage.customService = isCustomServiceValid(marginType, customService, services)
      ? ''
      : 'Provide the custom service name, include letters and numbers and up to 30 characters';
    if (getIsMultiAccountsAccUser()) {
      inputsErrorMessage.payerAccount =
        !payerAccount || !payerAccount.length ? 'Please select payer account and then customers' : '';
    }
    if (Object.values(inputsErrorMessage).some((val) => !!val)) {
      setInputsErrorMessage({ ...inputsErrorMessage });
      return false;
    }
    return true;
  };
  const handleAddOrEditPrivatePricingData = async (action) => {
    // adjust regions
    if (
      pricingParams.regions &&
      pricingParams.services &&
      pricingParams.services.map((srv) => srv.value).includes('Data Transfer - Amazon CloudFront')
    ) {
      pricingParams.regions = [];
    } else if (
      pricingParams.cloudFrontRegions &&
      pricingParams.services &&
      !pricingParams.services.map((srv) => srv.value).includes('Data Transfer - Amazon CloudFront')
    ) {
      pricingParams.cloudFrontRegions = [];
    }
    if (!newPricingDataIsValid(pricingParams)) {
      return;
    }
    try {
      toggleModal();
      if (action === 'add') {
        const newPricingParams = preparePPobjectForDB(
          pricingParams,
          usersStore.usersModel.mapLinkedAccIdToDivisionName,
        );
        await addBillingRule(newPricingParams);
      } else {
        const newPricingParams = preparePPobjectForDB(
          pricingParams,
          usersStore.usersModel.mapLinkedAccIdToDivisionName,
        );
        const { accountId, accountKey } = billingRulesData.find((rule) => rule.id === currEditedId) || {};
        if (!accountId || !accountKey) {
          toast.error('Something went wrong, please try again later', {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
          return;
        }
        await updateBillingRule({ accountId, accountKey, ...newPricingParams, currEditedId });
      }
    } catch (error) {
      toggleModal();
    }
  };
  const handleDeletePrivatePricingClicked = (e, id, ruleName, payerAccount = null) => {
    e.preventDefault();
    setDeletedPpId(id);
    setDeletedPpRuleName(ruleName);
    setDeletedPpPayerAccountId(payerAccount);
  };
  const handleDeletePrivatePricing = async (action) => {
    if (action === 'cancel') {
      setDeletedPpId(null);
      setDeletedPpRuleName(null);
      setDeletedPpPayerAccountId(null);
    } else if (action === 'delete') {
      try {
        await deleteBillingRule(deletedPpId, deletedPpPayerAccountId);
        resetToBaseStateParams();
      } catch (error) {
        resetToBaseStateParams();
      }
    }
    return null;
  };
  const prepareFilterOptions = (values, customLabel) => {
    let prepValues = [];
    if (Array.isArray(values)) {
      prepValues = values.map((val) => ({ value: val, label: val }));
    } else if (values && values.toLowerCase() === 'all') {
      prepValues = [{ value: 'all', label: customLabel }];
    }
    return prepValues;
  };
  const prepareServiceOptionByValue = (values, marginType, includeOrExcludeServices = []) => {
    let services = [];
    if (marginType === 'custom-service-calc-cost') {
      services =
        includeOrExcludeServices === 'all'
          ? [{ value: 'all', label: 'All' }]
          : prepareFilterOptions(includeOrExcludeServices, 'All');
    } else {
      services = prepareFilterOptions(values, 'All');
      services = values.includes('Custom Service') ? [{ value: 'Custom Service', label: 'Custom Service' }] : services;
    }
    return services;
  };
  const handleEditPrivatePricingClick = (row) => {
    const {
      services,
      id,
      effectiveDate,
      divNames,
      regions,
      cloudFrontRegions,
      includeOrExcludeServices,
      marginType,
      linkedAccounts,
      accountId,
      usageTypes,
      usageTypeOperator,
      ...rest
    } = row;
    const prepServices = prepareServiceOptionByValue(services, marginType, includeOrExcludeServices);
    const prepRegions = prepareFilterOptions(regions, 'All');
    const prepCloudFrontRegions = prepareFilterOptions(cloudFrontRegions, 'All');
    const divisionNames = prepareFilterOptions(divNames, 'All');
    const linkAcc = prepareFilterOptions(linkedAccounts, 'All');
    const prepUsageTypes =
      usageTypeOperator && isOperatorInput(usageTypeOperator) ? usageTypes : prepareFilterOptions(usageTypes, 'All');
    let payerAccount = '';
    if (getIsMultiAccountsAccUser()) {
      payerAccount = accountId;
    }
    const selectedPrivatePricingParams = {
      linkedAccounts: linkAcc,
      services: prepServices,
      divNames: divisionNames,
      regions: prepRegions,
      cloudFrontRegions: prepCloudFrontRegions,
      usageTypes: prepUsageTypes,
      usageTypeOperator: usageTypeOperator || OPERATORS_KEYS.IS,
      marginType,
      payerAccount,
      ...rest,
    };
    const inputsErrorMessage = defaultInputsErrorMessage.current;
    setIsModalOpen(true);
    setModalState('edit');
    setPricingParams(selectedPrivatePricingParams);
    setInputsErrorMessage(inputsErrorMessage);
    setCurrEditedId(id);
  };
  const handleOneChoiceFilterChange = (type, value) => {
    const attr = type;
    const val = value ? value.value : '';
    const newPricingParams = {};
    if (type === 'usageTypeOperator') {
      const changedToInput = isOperatorInput(val) && isOperatorSelect(pricingParams[attr]);
      const changedToSelect = isOperatorSelect(val) && isOperatorInput(pricingParams[attr]);
      if (changedToInput) {
        newPricingParams.usageTypes = '';
      }
      if (changedToSelect) {
        newPricingParams.usageTypes = [];
      }
    }
    newPricingParams[attr] = val;
    if (attr === 'payerAccount') {
      newPricingParams.divNames = '';
      newPricingParams.linkedAccounts = '';
    }
    if (val === 'recurring') {
      newPricingParams.effectiveMonth = '';
    }
    if (val === 'one time') {
      newPricingParams.startDate = '';
      newPricingParams.endDate = '';
    }
    setPricingParams((prevState) => ({ ...prevState, ...newPricingParams }));
  };

  const handleSelectCloudFrontRegions = (_, value) => {
    const newValues = value;
    const attributeName = 'cloudFrontRegions';
    if (!newValues) {
      setPricingParams((prevState) => ({ ...prevState, [attributeName]: '' }));
      setInputsErrorMessage((prevState) => ({ ...prevState, [attributeName]: 'Please select a value' }));
      return;
    }
    const allValue = newValues.find((item) => Array.isArray(item.value));
    setPricingParams((prevState) => ({ ...prevState, [attributeName]: allValue || newValues }));
    setInputsErrorMessage((prevState) => ({ ...prevState, [attributeName]: '' }));
  };

  const handleMultiChoiceFilterChange = (type, value) => {
    const typeToAttr = {
      service: 'services',
      region: 'regions',
      divNames: 'divNames',
      linkedAccounts: 'linkedAccounts',
      usagetype: 'usageTypes',
    };
    const attr = typeToAttr[type];
    const newPricingParams = {};
    const newInputsErrorMessage = {};
    if (!value) {
      newPricingParams[attr] = '';
      if (attr !== 'usageTypes') {
        newInputsErrorMessage[attr] = 'Please select a value';
      }
      setPricingParams((prevState) => ({ ...prevState, ...newPricingParams }));
      setInputsErrorMessage((prevState) => ({ ...prevState, ...newInputsErrorMessage }));
      return;
    }
    const val = Array.isArray(value) ? [...value] : [value];
    const allIndex = val.findIndex((item) => item.value === 'all' || item.value === ALL_CUSTOMERS);
    const customServiceIndex = val.findIndex((item) => item.value === 'Custom Service');
    if (val.length && allIndex === val.length - 1) {
      newPricingParams[attr] = [
        {
          value: val[val.length - 1].value === ALL_CUSTOMERS ? ALL_CUSTOMERS : 'all',
          label: val[val.length - 1].value === ALL_CUSTOMERS ? 'All' : 'All',
        },
      ];
    } else if (val.length && customServiceIndex === val.length - 1) {
      newPricingParams[attr] = [{ value: 'Custom Service', label: 'Custom Service' }];
    } else {
      if (allIndex > -1) {
        val.splice(allIndex, 1);
      }
      if (customServiceIndex > -1) {
        val.splice(customServiceIndex, 1);
      }
      newPricingParams[attr] = val;
    }
    newInputsErrorMessage[attr] = val ? '' : 'You must select at least on value';
    setPricingParams((prevState) => ({ ...prevState, ...newPricingParams }));
    setInputsErrorMessage((prevState) => ({ ...prevState, ...newInputsErrorMessage }));
  };
  const handleServiceFilterChange = (type, value) => {
    const typeToAttr = {
      service: 'services',
    };
    const attr = typeToAttr[type];
    if (!value) {
      setPricingParams((prevState) => ({ ...prevState, [attr]: '' }));
      setInputsErrorMessage((prevState) => ({ ...prevState, [attr]: 'Please select a value' }));
      return;
    }
    const val = Array.isArray(value) ? [...value] : [value];
    const allIndex = val.findIndex((item) => item.value === 'all');
    const customServiceIndex = val.findIndex((item) => item.value === 'Custom Service');
    const newPricingParams = {};
    if (val.length && allIndex === val.length - 1) {
      newPricingParams[attr] = [
        {
          value: 'all',
          label: 'All',
        },
      ];
    } else if (val.length && customServiceIndex === val.length - 1) {
      newPricingParams[attr] = [{ value: 'Custom Service', label: 'Custom Service' }];
    } else {
      if (allIndex > -1) {
        val.splice(allIndex, 1);
      }
      if (customServiceIndex > -1) {
        val.splice(customServiceIndex, 1);
      }
      newPricingParams[attr] = val;
      newPricingParams.customService = '';
    }
    inputsErrorMessage[attr] = val ? '' : 'You must select at least one value';
    setPricingParams((prevState) => ({ ...prevState, ...newPricingParams }));
    setInputsErrorMessage((prevState) => ({ ...prevState, [attr]: val ? '' : 'You must select at least one value' }));
  };
  const handleNumericFilterChange = (e) => {
    const isPercentage = percentageMargins.includes(pricingParams.marginType);
    const { value, id } = e.target;
    if (Number.isNaN(Number(value))) {
      return;
    }
    let valueNumber = value;
    if (value.endsWith('-')) {
      valueNumber = '-';
    } else if (isPercentage) {
      valueNumber = Math.max(Math.min(valueNumber, 100), -100);
    }
    setPricingParams((prevState) => ({ ...prevState, [id]: String(valueNumber) }));
  };
  const handleTextInputChange = (e) => {
    const attr = e.target.name;
    let val = e.target.value;
    let errorMessage = '';
    if (attr === 'name') {
      val = trimNonAlphanumericSymbols(val);
      if (val.length > 100) {
        val = val.slice(0, 99);
        errorMessage = "Name length can't exceed 100 characters";
      }
    }
    if (attr === 'customService') {
      if (val.length > 30) {
        val = val.slice(0, 29);
        errorMessage = "Custom Service name length can't exceed 30 characters";
      }
    }
    setPricingParams((prevState) => ({ ...prevState, [attr]: val }));
    setInputsErrorMessage((prevState) => ({ ...prevState, [attr]: errorMessage }));
  };
  const handleDateChange = (e) => {
    const dateTypeByFrequency = {
      'one time': 'effectiveMonth',
      recurring: e.nativeEvent.target.id,
    };
    const val = e ? e.target.value : '';
    setPricingParams((prevState) => ({ ...prevState, [dateTypeByFrequency[prevState.frequency]]: val }));
    setInputsErrorMessage((prevState) => ({ ...prevState, date: val ? '' : 'Please select a date' }));
  };
  const handleDisableMinimumFee = () => {
    setPricingParams((prevState) => ({ ...prevState, disableMinimumFee: !prevState.disableMinimumFee }));
  };
  const handleDiscountTypeChange = (e) => {
    const newPricingParams = {};
    const newInputsErrorMessages = {};
    const val = e ? e.target.id : '';
    newPricingParams.marginType = val;
    newInputsErrorMessages.marginType = val ? '' : 'Please choose discount type';
    if (val === 'fix') {
      newPricingParams.services = [{ value: 'Custom Service', label: 'Custom Service' }];
      newInputsErrorMessages.services = '';
    } else {
      newPricingParams.services = '';
      newPricingParams.customService = '';
    }
    setPricingParams((prevState) => ({ ...prevState, ...newPricingParams }));
    setInputsErrorMessage((prevState) => ({ ...prevState, ...newInputsErrorMessages }));
  };
  const handleChangeIncludeExcludeService = () => {
    setPricingParams((prevState) => ({ ...prevState, excludeService: !prevState.excludeService }));
  };
  const handleChangeIncludeExcludeMarketPlace = () => {
    setPricingParams((prevState) => ({ ...prevState, isExcludeAwsMarketplace: !prevState.isExcludeAwsMarketplace }));
  };
  const handleToggleScopeType = () => {
    setPricingParams((prevState) => ({ ...prevState, isLinkedAccountsChecked: !prevState.isLinkedAccountsChecked }));
  };
  const prepareLinkedAccountOptions = () => {
    const isMultiAccountsAccUser = getIsMultiAccountsAccUser();
    const allLinkedAccounts = filtersValuesMap?.get(AwsCommonFields.LINKED_ACCOUNT_NAME);
    if (isMultiAccountsAccUser) {
      return allLinkedAccounts.filter((linkedAcc) => linkedAcc.accountId === pricingParams.payerAccount);
    }
    const customerLinkedAccountIds = usersStore.usersModel.divisionAllocatedLinkedAccounts;
    const linkedAccountOptions =
      allLinkedAccounts &&
      allLinkedAccounts.filter((linkA) => customerLinkedAccountIds.includes(linkA.linkedAccountId));
    return linkedAccountOptions;
  };

  const isMultiAccountsAccUser = getIsMultiAccountsAccUser();
  const payerAccListOfValues = filtersValuesMap.get(AwsCommonFields.PAYER_ACCOUNT);
  const inputListOfValues = getPageFilters(PageNames.AWS_RESELLER, usersStore.currDispUserCloudAccountType);
  const resellerColumns = AWS_RESELLER_RATES_TABLE_COLUMNS.map((c) => {
    const col = { ...c };
    if (col.name === 'margin') {
      col.getCellValue = (row) => {
        if (['calc-cost', 'remove-support'].includes(row.marginType)) {
          return 'Support fee';
        }
        return percentageMargins.includes(row.marginType) ? `${row.margin} %` : `${getCurrencyNumber(row.margin)}`;
      };
    }
    return col;
  });

  if (
    isBillingRulesLoading ||
    filtersLoading ||
    isAddingBillingRule ||
    isUpdatingBillingRule ||
    isDeletingBillingRule ||
    (isMultiAccountsAccUser && !payerAccListOfValues)
  ) {
    return <Spinner />;
  }
  return (
    <Container>
      <AddPricingButton handler={handleOpenAddPricingModalClick} />
      {billingRulesData?.length > 0 && (
        <PricingTable
          rows={billingRulesData?.filter((resellerRate) => resellerRate.divisionName === divisionName)}
          columns={resellerColumns}
          columnWidths={AWS_RESELLER_RATES_TABLE_COLUMNS_WIDTHS}
          tableColumnExtensions={AWS_RESELLER_RATES_TABLE_COLUMN_EXTENSIONS}
          defaultHiddenColumnNames={[]}
          defaultSorting={[]}
          currenciesColumns={AWS_RESELLER_RATES_TABLE_CURRENCY_COLUMNS}
          deleteHandler={handleDeletePrivatePricingClicked}
          editHandler={handleEditPrivatePricingClick}
          csvTitle="Reseller Rates"
          divisionGroups={usersStore.usersModel.divisionGroups}
        />
      )}
      <AddOrUpdatePrivatePricingModal
        modalState={modalState} // add or update
        toggle={toggleModal}
        isOpen={isModalOpen}
        inputListOfValues={inputListOfValues}
        inputSelectedOption={pricingParams}
        selectedInputsErrorMessage={inputsErrorMessage}
        handleAddOrEditPrivatePricingData={handleAddOrEditPrivatePricingData}
        handleOneChoiceFilterChange={handleOneChoiceFilterChange}
        handleMultiChoiceFilterChange={handleMultiChoiceFilterChange}
        handleSelectCloudFrontRegions={handleSelectCloudFrontRegions}
        handleServiceFilterChange={handleServiceFilterChange}
        handleTextInputChange={handleTextInputChange}
        invoiceStore={invoiceStore}
        handleNumericFilterChange={handleNumericFilterChange}
        handleDateChange={handleDateChange}
        handleDiscountTypeChange={handleDiscountTypeChange}
        handleDisableMinimumFee={handleDisableMinimumFee}
        handleChangeIncludeExcludeService={handleChangeIncludeExcludeService}
        handleChangeIncludeExcludeMarketPlace={handleChangeIncludeExcludeMarketPlace}
        scopeListOfValues={getScopeListOfValues(payerAccListOfValues)}
        isShowScope={!divisionName}
        cloudType={usersStore.currDispUserCloudAccountType}
        handleToggleScopeType={handleToggleScopeType}
        linkedAccountOptions={prepareLinkedAccountOptions()}
        payerAccListOfValues={payerAccListOfValues}
      />
      <DeleteWarningModal
        deletedItemName={deletedPpRuleName}
        isOpen={deletedPpId}
        handleDelete={handleDeletePrivatePricing}
        warningMessage="Be advise you are about to delete Billing rule: "
        modalTitle="Delete Billing Rule"
      />
    </Container>
  );
};

ResellerPrivatePricing.propTypes = {
  usersStore: PropTypes.object.isRequired,
  invoiceStore: PropTypes.object.isRequired,
  divisionName: PropTypes.string,
};

export default ResellerPrivatePricing;
