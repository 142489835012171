import PropTypes from 'prop-types';
import React from 'react';
import LinearProgress from '@mui/material/LinearProgress';
import { grey } from '@mui/material/colors';

import { useUserSettingsContext } from 'users/utils/contexts/UserSettingsContext';
import styles from './systemCharts.module.scss';

const MAP_SERVICE_TO_NAME = {
  ec2: 'EC2',
  elasticache: 'Elastic Cache',
  rds: 'RDS',
  es: 'ES',
  os: 'OS',
  redshift: 'RedShift',
};

const SavingOnDemand = ({ data, overrideCurrency = null }) => {
  const { getCurrencyNumber } = useUserSettingsContext();
  if (!data || !data.length) {
    return null;
  }
  const servicesSavings = data[0];
  const totalSum = Object.keys(servicesSavings).reduce((acc, key) => acc + +servicesSavings[key], 0);

  const getServingServiceValue = (value) =>
    value.toFixed(1)[value.toFixed(1).length - 1] === '0' ? value.toFixed(0) : value;
  return (
    <div className="d-flex flex-column">
      <h6 className="bold-text mb-2">
        TOTAL: {getCurrencyNumber(totalSum, 0, { roundNumber: true }, overrideCurrency)}
      </h6>
      {Object.keys(servicesSavings)
        .sort((a, b) => +servicesSavings[b] - +servicesSavings[a])
        .map((key) => (
          <div key={key} className="w-100 mb-3 d-flex">
            <div className="d-flex" style={{ width: '33%' }}>
              <span className="mt-auto" style={{ lineHeight: 1, color: grey[700] }}>
                {MAP_SERVICE_TO_NAME[key]}
              </span>
            </div>
            <div style={{ width: '67%' }}>
              <p className="text-right initialism" style={{ color: '#3ea3fc' }}>
                {getCurrencyNumber(getServingServiceValue(+servicesSavings[key]), 2, {}, overrideCurrency)}
              </p>
              <LinearProgress
                title={key}
                variant="determinate"
                value={totalSum === 0 ? totalSum : (+servicesSavings[key] / totalSum) * 100}
                classes={{ root: styles.root, colorPrimary: styles.colorPrimary, bar: styles.bar }}
              />
            </div>
          </div>
        ))}
    </div>
  );
};

SavingOnDemand.propTypes = {
  data: PropTypes.object.isRequired,
  overrideCurrency: PropTypes.string,
};

export default SavingOnDemand;
