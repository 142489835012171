import { useQuery } from '@tanstack/react-query';
import apiConstants from 'shared/api/apiConstants';
import { queryClient } from 'queryClient';
import { API } from 'shared/utils/apiMiddleware';
import { BILLINGS_API_NAME, RECOMMENDATIONS_HEATMAP_ROOT, STALE_TIME } from 'recommendationsNew/consts';
import { transformFiltersContextToApiFormat } from 'recommendationsNew/hooks/react-query/apiUtils';
import { useRootStore } from 'app/contexts/RootStoreContext';

export function fetchWasteReport({filters, externalFilters}) {
  const transformedFilters = transformFiltersContextToApiFormat(filters);
  const transformedExternalFilters = transformFiltersContextToApiFormat(externalFilters);
  return API.post(BILLINGS_API_NAME, `${RECOMMENDATIONS_HEATMAP_ROOT}/wasteReport`, {
    body: {
      filters: transformedFilters || {},
      second_level_filters: transformedExternalFilters || {},
    },
  });
}

export default function useWasteReport(isEnabled = true) {
  const { usersStore } = useRootStore();
  const userAccountKey = usersStore?.currDispUserAccountKey;

  const queryKey = [
    apiConstants.QUERY_KEYS.RECOMMENDATIONS,
    apiConstants.QUERY_KEYS.RECOMMENDATIONS_WASTE_REPORT,
    userAccountKey,
  ];

  return {
    invalidate: ({ filters }) => queryClient.invalidateQueries([...queryKey, filters]),
    reset: ({ filters }) => queryClient.resetQueries([...queryKey, filters]),
    fetchWasteReport: (filters, externalFilters) =>
      useQuery([...queryKey, filters, externalFilters], () => fetchWasteReport({filters, externalFilters}), {
        retry: false,
        staleTime: STALE_TIME,
        enabled: isEnabled,
      }),
  };
}
