/* eslint-disable no-unused-vars */
import { CostUsageStates } from 'usage/constants/costAndUsageConstants';
import { CLOUD_TYPE_IDS } from 'users/constants/usersConstants';
import { k8sGranLevel } from 'shared/constants/k8sConstants';
import BaseCostUsageDataState from './baseCostUsageDataState';

export default class ClusterDataState extends BaseCostUsageDataState {
  constructor(context) {
    super(context);
  }

  getNewDataStateType(dataKey) {
    let newDataState;
    if (this.context.props.usersStore.currDispUserCloudAccountType === CLOUD_TYPE_IDS.AZURE) {
      // Node Groups are currently reliable only for Azure (all nodes are reachable)
      newDataState = CostUsageStates.NODE_GROUP;
    } else {
      newDataState =
        this.context.state.k8SGranularityState === k8sGranLevel.PODS ? CostUsageStates.NAMESPACE : CostUsageStates.NODE;
    }
    return newDataState;
  }

  getDataStateType() {
    return CostUsageStates.CLUSTER;
  }
}
