 
import { RecommendationTypes } from 'recommendations/constants/recommendationsConstants';
import { AzureDiskTypeChangeRecommendation } from 'recommendations/models/recommTypes';
import AzureCostRecommendationBuilder from 'recommendations/models/builders/azureCostRecommBuilder';

export default class AzureDiskTypeChangeRecommendationBuilder extends AzureCostRecommendationBuilder {
  constructor(rawRecommendation) {
    super(rawRecommendation);
  }

  createRecommendation() {
    this._recommendation = new AzureDiskTypeChangeRecommendation();
  }

  getRecommType() {
    return RecommendationTypes.AZURE_DISK_TYPE_CHANGE;
  }

  buildRecommTitle() {
    const { recommended_action: action } = this._rawRecommendation;
    this._recommendation.title = `${action || ''} Disk Type`;
  }

  build() {
    super.build();
    const {
      zone_tag: zoneTag,
      disk_time_created: diskTimeCreated,
      // days_to_check: daysToCheck,
      disk_type: diskType,
      new_disk_type: newDiskType,
      disk_id: diskId,
      resource_group: resourceGroup,
      disk_size: diskSize,
      disk_name: diskName,
      iops,
      max_iops_possible: maxIopsPossible,
      new_max_iops_possible: newMaxIopsPossible,
      max_iops_data: maxIopsData,
      max_daily_read_bytes: maxDailyReadBytes,
      max_daily_write_bytes: maxDailyWriteBytes,
      max_daily_read_iops: maxDailyReadIops,
      max_daily_write_iops: maxDailyWriteIops,
    } = this._rawRecommendation;
    this._recommendation.maxIopsPossible = maxIopsPossible;
    this._recommendation.newMaxIopsPossible = newMaxIopsPossible;
    this._recommendation.maxIopsData = maxIopsData;
    this._recommendation.region = zoneTag;
    this._recommendation.diskTimeCreated = diskTimeCreated;
    // this._recommendation.daysToCheck = daysToCheck;
    this._recommendation.newDiskType = newDiskType;
    this._recommendation.diskId = diskId;
    this._recommendation.resourceGroup = resourceGroup;
    this._recommendation.iops = iops;
    this._recommendation.diskType = diskType;
    this._recommendation.diskSize = diskSize;
    this._recommendation.diskName = diskName;
    this._recommendation.maxDailyReadBytes = maxDailyReadBytes;
    this._recommendation.maxDailyWriteBytes = maxDailyWriteBytes;
    this._recommendation.maxDailyReadIops = maxDailyReadIops;
    this._recommendation.maxDailyWriteIops = maxDailyWriteIops;
  }
}
