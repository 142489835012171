import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import styles from './Chip.module.scss';

export const CHIP_COLORS = {
  DEFAULT: 'default',
  RED: 'red',
  BLUE: 'blue',
};

export const CHIP_SIZES = {
  DEFAULT: 'default',
  BIG: 'big',
};

const Chip = ({ label, color = CHIP_COLORS.DEFAULT, size = CHIP_SIZES.DEFAULT, className = '' }) => (
  <div className={classnames(styles.chip, className)} data-color={color} data-size={size}>
    {label}
  </div>
);

Chip.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string.isRequired,
  color: PropTypes.oneOf(Object.values(CHIP_COLORS)),
  size: PropTypes.oneOf(Object.values(CHIP_SIZES)),
};

export default Chip;
