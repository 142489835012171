import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { GenerateIcon, ICONS, Popover, PopoverContent, PopoverTrigger } from '@pileus-cloud/anodot-frontend-common';
import SwitchButton from 'shared/components/andtComponents/Switch';
import Button from 'shared/components/andtComponents/Button';
import { PERMISSION_TYPES } from '../../consts';
import SharedEntitiesModal from './SharedItemsModal/SharedEntitiesModal';
import PermissionTooltip from './PermissionTooltip';

import styles from './Permission.module.scss';

const Permission = ({ actionId, category, isAllowed, isViewOnly, onPermissionChange, permissions, roleId, userId }) => {
  const [isSharedEntitiesModalOpen, setIsSharedEntitiesModalOpen] = useState(false);
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);

  const handleMouseEnter = () => {
    setIsTooltipOpen(true);
  };

  const handleMouseLeave = () => {
    setIsTooltipOpen(false);
  };

  const permissionTypeAndRoles = useMemo(() => {
    let maxPermissionType = PERMISSION_TYPES.NONE.id;
    const fullPermissionRoles = [];
    permissions?.forEach((permission) => {
      if (permission.permissionType === PERMISSION_TYPES.FULL.id) {
        if (permission.derivedFromRole) {
          fullPermissionRoles.push(permission.derivedFromRole);
        }
        maxPermissionType = PERMISSION_TYPES.FULL.id;
      }
      if (permission.permissionType === PERMISSION_TYPES.PARTIAL.id && maxPermissionType !== PERMISSION_TYPES.FULL.id) {
        maxPermissionType = PERMISSION_TYPES.PARTIAL.id;
      }
    });
    return {
      type: maxPermissionType,
      roles: maxPermissionType === PERMISSION_TYPES.FULL.id ? fullPermissionRoles : null,
    };
  }, [permissions]);

  return !isAllowed ? null : (
    <>
      {permissionTypeAndRoles.type === PERMISSION_TYPES.FULL.id && (
        <>
          {permissionTypeAndRoles.roles?.length === 0 ? (
            <SwitchButton
              isDisabled={isViewOnly}
              onChange={onPermissionChange}
              isChecked
              className={styles.permissionSwitch}
              isSmall
            />
          ) : (
            <Popover open={isTooltipOpen} onOpenChange={setIsTooltipOpen}>
              <PopoverTrigger onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                <GenerateIcon iconName={ICONS.check.name} className={styles.permissionIcon} />
              </PopoverTrigger>
              {permissionTypeAndRoles?.roles?.length > 0 ? (
                <PopoverContent className="z-1">
                  <PermissionTooltip roles={permissionTypeAndRoles.roles} />
                </PopoverContent>
              ) : null}
            </Popover>
          )}
        </>
      )}
      {permissionTypeAndRoles.type === PERMISSION_TYPES.NONE.id && (
        <SwitchButton
          onChange={onPermissionChange}
          isChecked={false}
          isDisabled={isViewOnly}
          className={styles.permissionSwitch}
          isSmall
        />
      )}
      {permissionTypeAndRoles.type === PERMISSION_TYPES.PARTIAL.id && (
        <>
          <Button
            text="Shared Items"
            onClick={() => setIsSharedEntitiesModalOpen(!isSharedEntitiesModalOpen)}
            className={{ buttonText: styles.sharedItemsText }}
            isTextButton
          />
          <SwitchButton
            onChange={onPermissionChange}
            isChecked={false}
            className={styles.permissionSwitch}
            isSmall
            isDisabled={!!userId || isViewOnly}
          />
          {isSharedEntitiesModalOpen && (
            <SharedEntitiesModal
              actionId={actionId}
              category={category}
              isOpen={isSharedEntitiesModalOpen}
              userId={userId}
              roleId={roleId}
              setIsOpen={setIsSharedEntitiesModalOpen}
            />
          )}
        </>
      )}
    </>
  );
};

Permission.propTypes = {
  actionId: PropTypes.string,
  category: PropTypes.string,
  isAllowed: PropTypes.bool,
  isViewOnly: PropTypes.bool,
  onPermissionChange: PropTypes.func,
  permissions: PropTypes.array,
  roleId: PropTypes.string,
  userId: PropTypes.string,
};

export default Permission;
