import React, { useMemo } from 'react';
import classNames from 'classnames';
import { GenerateIcon, ICONS } from '@pileus-cloud/anodot-frontend-common';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import Button from 'shared/components/andtComponents/Button';
import { useOnboarding } from 'app/hooks/react-query/useOnboarding';
import CloudProviderButton from 'app/components/CloudProviderButton';
import { CLOUD_TYPE_IDS, mapCloudDescriptionToTypeId } from 'users/constants/usersConstants';
import TableHeader from 'shared/components/tableHeader/TableHeader';
import { useTableHeaderContext } from 'shared/components/tableHeader/TableHeaderContext';
import Spinner from 'shared/components/andtComponents/Spinner';
import { cloudTypeToIcon } from 'shared/constants/appConstants';
import AccountsTable from './components/AccountsTable';
import styles from './CloudAccounts.module.scss';
import * as AccountTableConstants from './constants/cloudAccountsConstants';

const CloudAccounts = ({ usersStore }) => {
  const { searchText, isCreateMode, setIsCreateMode } = useTableHeaderContext();
  const data = usersStore.getAllCurrDisplayedUserAccounts();
  const { fetchOnboardings } = useOnboarding();
  const { data: onboardingAccounts, isFetched } = fetchOnboardings();
  const csvColumns = (AccountTableConstants.CLOUD_ACCOUNTS_TABLE_COLUMNS || []).filter(
    (c) => !['delete', 'edit', 'isDefaultAcc'].includes(c.name),
  );
  const csvData = data.map((row) => {
    const formattedRow = {};
    csvColumns.forEach((col) => {
      formattedRow[col.title] = col.getCellValue ? col.getCellValue(row) : row[col.name];
    });
    return formattedRow;
  });
  const filteredData = useMemo(
    () =>
      (
        [
          ...(onboardingAccounts || []).map((acc) => ({
            ...acc,
            onboarding: true,
            accountName: acc.accountName,
            cloudTypeId: mapCloudDescriptionToTypeId.get(acc.provider?.toUpperCase()),
            accountId: acc.accountId,
          })),
          ...data.filter((acc) => !onboardingAccounts?.some((onboarding) => acc.accountId === onboarding.accountId)),
        ] || []
      ).filter((item) => {
        if (!searchText) {
          return true;
        }
        const searchLower = searchText.toLowerCase();
        const { accountId, accountName } = item;
        return (
          (accountId?.toLowerCase() || '').includes(searchLower) ||
          (accountName?.toLowerCase() || '').includes(searchLower)
        );
      }),
    [searchText, isFetched, data, onboardingAccounts],
  );

  if (!isFetched) {
    return <Spinner />;
  }
  const renderCloudCounter = (cloudTypeId) => {
    if (cloudTypeId === CLOUD_TYPE_IDS.BYOD && !filteredData.some((a) => a.cloudTypeId === cloudTypeId)) {
      return null;
    }

    const Icon = cloudTypeToIcon[cloudTypeId];
    return (
      <div className={styles.cloudCounter} data-total={cloudTypeId === CLOUD_TYPE_IDS.MULTI}>
        <Icon />
        <span>
          {cloudTypeId === CLOUD_TYPE_IDS.MULTI ? 'Total: ' : ''}
          {filteredData.filter((a) => a.cloudTypeId === cloudTypeId || cloudTypeId === CLOUD_TYPE_IDS.MULTI).length}
        </span>
      </div>
    );
  };
  return (
    <div>
      {isCreateMode ? (
        <div className={classNames(styles.providersHeader, styles.header)}>
          <p className={styles.title}>Choose your cloud provider</p>
          <div className={styles.providers}>
            <CloudProviderButton type={CLOUD_TYPE_IDS.AWS} />
            <CloudProviderButton type={CLOUD_TYPE_IDS.AZURE} />
            <CloudProviderButton type={CLOUD_TYPE_IDS.GCP} />
          </div>
          <div className={styles.closeButton}>
            <Button
              onClick={() => setIsCreateMode(false)}
              text="Close"
              icon={() => <GenerateIcon iconName={ICONS.xMark.name} />}
            />
          </div>
        </div>
      ) : (
        <TableHeader
          tableName="Cloud Account"
          titleComponent={
            <div className={styles.tableTitle}>
              Cloud Accounts
              <div className={styles.counters}>
                {renderCloudCounter(CLOUD_TYPE_IDS.AWS)}
                {renderCloudCounter(CLOUD_TYPE_IDS.AZURE)}
                {renderCloudCounter(CLOUD_TYPE_IDS.GCP)}
                {renderCloudCounter(CLOUD_TYPE_IDS.BYOD)}
                {renderCloudCounter(CLOUD_TYPE_IDS.MULTI)}
              </div>
            </div>
          }
          filteredRows={filteredData.length}
          totalRows={[...(onboardingAccounts || []), ...data].length}
          csvData={{ data: csvData, filename: 'accounts.csv' }}
        />
      )}
      <div className="pt-3 mt-2" />
      <AccountsTable data={filteredData} usersStore={usersStore} />
    </div>
  );
};

CloudAccounts.propTypes = {
  usersStore: PropTypes.object.isRequired,
};

const ObserverClousAccounts = observer(CloudAccounts);
export default ObserverClousAccounts;
