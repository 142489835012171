 
import { RecommendationTypes } from 'recommendations/constants/recommendationsConstants';
import { EcsFargateRecommendation } from 'recommendations/models/recommTypes';
import CostRecommendationBuilder from 'recommendations/models/builders/costRecommBuilder';
import { RecommendationCost } from 'recommendations/models/recommCommonTypes';

export default class EcsFargateRecommendationBuilder extends CostRecommendationBuilder {
  constructor(rawRecommendation) {
    super(rawRecommendation);
  }

  createRecommendation() {
    this._recommendation = new EcsFargateRecommendation();
  }

  getRecommType() {
    return RecommendationTypes.ECS_FARGATE;
  }

  buildRecommTitle() {}

  build() {
    super.build();
    const {
      region,
      recommended_cpu,
      recommended_memory,
      total_cost_recommended: recommTotalCost,
      total_cost_current: currTotalCost,
      current_memory_config,
      current_cpu_config,
    } = this._rawRecommendation;
    const recommCost = new RecommendationCost(currTotalCost, recommTotalCost);
    if (region && !this._recommendation.region) {
      this._recommendation.region = region;
    }
    if (recommended_cpu && !this._recommendation.recommendedCpu) {
      this._recommendation.recommendedCpu = recommended_cpu;
    }
    if (current_cpu_config && !this._recommendation.currentCpu) {
      this._recommendation.currentCpu = current_cpu_config;
    }
    if (recommended_cpu && current_cpu_config && !this._recommendation.cpuChange) {
      this._recommendation.cpuChange = (Math.abs(current_cpu_config - recommended_cpu) / current_cpu_config) * 100;
    }
    if (recommended_memory && !this._recommendation.recommendedMemory) {
      this._recommendation.recommendedMemory = recommended_memory;
    }
    if (current_memory_config && !this._recommendation.currentMemory) {
      this._recommendation.currentMemory = current_memory_config;
    }
    if (recommended_memory && current_memory_config && !this._recommendation.memoryChange) {
      this._recommendation.memoryChange =
        (Math.abs(current_memory_config - recommended_memory) / current_memory_config) * 100;
    }
    this._recommendation.currTotalCost = parseFloat(recommCost.currYearlyCost);
    this._recommendation.recommTotalCost = parseFloat(recommCost.recommYearlyCost);
  }
}
