import { getFullAwsServiceName } from 'shared/utils/awsUtils';
import {
  CostUsageStates,
  mapCloudBaseCostUsageStateToAttributesMap,
  mapCostUsageStateToAttributes,
} from 'usage/constants/costAndUsageConstants';
import { AWS_QUANTITY_TYPE_SELECT, AwsCommonFields, AwsQuantityTypes } from 'shared/constants/awsConstants';
import { CLOUD_TYPE_IDS } from 'users/constants/usersConstants';
import LabelCoordinator from '../../../../../shared/modules/labelCoordinator';

export default class BaseCostUsageDataState {
  constructor(context) {
    this.data = null;
    this.context = context;
  }

  getData = () => {
    const stateType = this.getDataStateType();
    this.data = this.context.props.invoiceStore.dataStates[stateType];
    return this.data;
  };

  getSupportedQuantityTypes = (cloudTypeId = CLOUD_TYPE_IDS.AWS) => {
    if (cloudTypeId === CLOUD_TYPE_IDS.GCP) {
      return [
        AWS_QUANTITY_TYPE_SELECT,
        AwsQuantityTypes.HOURS,
        AwsQuantityTypes.BYTES,
        AwsQuantityTypes.OTHER,
        AwsQuantityTypes.QUANTITY,
      ];
    }
    return [
      AWS_QUANTITY_TYPE_SELECT,
      AwsQuantityTypes.HOURS,
      AwsQuantityTypes.REQUESTS,
      AwsQuantityTypes.BYTES,
      AwsQuantityTypes.OTHER,
      AwsQuantityTypes.QUANTITY,
    ];
  };

  modifyDataKey = (dataKey, currGroupBy) => {
    if (currGroupBy === AwsCommonFields.PAYER_ACCOUNT) {
      return dataKey.split(' (')[1].replace(')', '');
    }
    return dataKey;
  };

  getBreadCrumbLabel = (value) => LabelCoordinator.getDataKeyDisplayName('cueDisplayCoordinator', value);

  handleDive = (date, dataKey, cloudTypeId) => {
    const { currentGroupBy, dataKeyToWhereParamsMap } = this.context.state;
    const modifiedDataKey = this.modifyDataKey(dataKey, currentGroupBy);
    const newDataState = this.getNewDataStateType(modifiedDataKey);
    if (newDataState === CostUsageStates.RESOURCE && cloudTypeId === CLOUD_TYPE_IDS.MULTI) {
      return;
    }
    const newGroupBy = mapCostUsageStateToAttributes.get(newDataState);
    this.context.setState({
      diveDate: date,
      isApplyFiltersButtonDisabled: true,
      currDataState: newDataState,
      currentGroupBy: newGroupBy,
      dataKeyToWhereParamsMap: {
        ...dataKeyToWhereParamsMap,
        [currentGroupBy]: getFullAwsServiceName(modifiedDataKey),
      },
    });
  };

  handleBackTo = () => {
    const dataStateType = this.getDataStateType();
    const { dataKeyToWhereParamsMap } = this.context.state;
    const newGroupBy = this.getCurrentGroupByFromWhereParams(mapCostUsageStateToAttributes.get(dataStateType));
    const keys = [...Object.keys(dataKeyToWhereParamsMap)];
    const newDataKeyToWhereParamsMap = {};

    for (let i = 0; i < keys.length; i++) {
      if (keys[i] === newGroupBy) {
        break;
      }
      newDataKeyToWhereParamsMap[keys[i]] = dataKeyToWhereParamsMap[keys[i]];
    }
    // clear previous data state
    this.context.props.invoiceStore.clearDataState(this.context.state.currDataState);
    this.context.setState({
      currDataState: dataStateType,
      currentGroupBy: newGroupBy,
      dataKeyToWhereParamsMap: newDataKeyToWhereParamsMap,
    });
  };

  getCurrentGroupByFromWhereParams = (dataStateAttr) => {
    if ((dataStateAttr || '').includes('customtags') || (dataStateAttr || '').includes(AwsCommonFields.ACCOUNT_TAGS)) {
      const { dataKeyToWhereParamsMap } = this.context.state;
      const wheresKeys = Object.keys(dataKeyToWhereParamsMap);
      const fulldataStateAttr = wheresKeys.find((whereKey) => whereKey.split(':')[0] === dataStateAttr);
      return fulldataStateAttr || dataStateAttr;
    }
    return dataStateAttr;
  };

  handleFilterChange = async (
    groupByFilter,
    dataKeyToWhereParamsMap,
    startDate,
    endDate,
    currPeriodGranLevel,
    filters,
    currCostType,
    isShowAmortize,
    isApplyMargin,
    isNetAmortize,
    isNetUnblended,
    isPublicCost,
    isDistributed,
    isListUnitPrice,
    isNegotiatedSavings,
  ) => {
    const stateType = this.getDataStateType();
    const { filterBarGroupBySecondary } = this.context.state;
    this.context.updateDataDates();
    await this.context.props.invoiceStore.fetchDataByState(
      stateType,
      groupByFilter,
      filterBarGroupBySecondary,
      groupByFilter,
      dataKeyToWhereParamsMap,
      startDate,
      endDate,
      currPeriodGranLevel,
      filters,
      currCostType,
      isShowAmortize,
      isApplyMargin,
      isNetAmortize,
      isNetUnblended,
      isPublicCost,
      isDistributed,
      isListUnitPrice,
      isNegotiatedSavings,
    );
  };

  getPreparedNewGroupBy = (newGroupBy) => {
    let preparedNewGroupBy = '';
    switch (true) {
      case newGroupBy.split(':')[0] === 'customtags':
        preparedNewGroupBy = 'customtags';
        break;
      case newGroupBy.split(':')[0] === 'accounttags':
        preparedNewGroupBy = 'accounttags';
        break;
      case newGroupBy.split(':')[0] === 'virtualcustomtags':
        preparedNewGroupBy = 'virtualcustomtags';
        break;
      case newGroupBy.split(':')[0] === 'viewscustomtags':
        preparedNewGroupBy = 'viewscustomtags';
        break;
      case newGroupBy.split(':')[0] === AwsCommonFields.BUSINESS_MAPPING_VIEWPOINTS:
        preparedNewGroupBy = AwsCommonFields.BUSINESS_MAPPING_VIEWPOINTS;
        break;
      default:
        preparedNewGroupBy = newGroupBy;
        break;
    }
    return preparedNewGroupBy;
  };

  handleGroupBy = async (newGroupBy, newSecondaryGroupBy, newDataState) => {
    // if GroupBy is the same - No required change
    const { filterBarGroupBySecondary, filterBarGroupBy } = this.context.state;
    if (newGroupBy === filterBarGroupBy && newSecondaryGroupBy === filterBarGroupBySecondary) {
      return;
    }
    await this.fetchDataByState(newDataState, newSecondaryGroupBy, newGroupBy);
  };

  fetchDataByState = async (dataState, newSecondaryGroupBy = '', newGroupBy) => {
    const {
      excludedFiltersStatusMap,
      likeFiltersStatus,
      isShowAmortizeCost,
      isNetAmortize,
      isNetUnblended,
      isPublicCost,
      isDistributed,
      isListUnitPrice,
      isNegotiatedSavings,
      currCostType,
      fieldToFilterdValuesMap,
      endDate,
      selectedGranLevel,
      isApplyMargin,
      startDate,
      isRateUsageBased,
      carbonEmissionsUsage,
    } = this.context.state;
    const { currDispUserCloudAccountType } = this.context.props.usersStore;
    const mapCloudBase = mapCloudBaseCostUsageStateToAttributesMap.get(currDispUserCloudAccountType);
    const stateAttribute = dataState === CostUsageStates.SECOND_GROUP_BY ? newGroupBy : mapCloudBase.get(dataState);
    this.context.updateDataDates();
    await this.context.props.invoiceStore.fetchDataByState(
      dataState,
      stateAttribute, // groupByLevel
      newSecondaryGroupBy,
      newGroupBy || stateAttribute,
      {},
      startDate,
      endDate,
      selectedGranLevel,
      {
        isRateUsageBased,
        carbonEmissionsUsage,
        filtersMap: fieldToFilterdValuesMap,
        excludedFiltersStatusMap,
        likedFiltersStatus: likeFiltersStatus,
      },
      currCostType,
      isShowAmortizeCost,
      isApplyMargin,
      isNetAmortize,
      isNetUnblended,
      isPublicCost,
      isDistributed,
      isListUnitPrice,
      isNegotiatedSavings,
    );
  };
}
