import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import { QueryClientProvider } from '@tanstack/react-query';
import App from './app/containers/App/App';
import { queryClient } from './queryClient';
// DO NOT DELETE - these polyfills are required to support stringifying of a map or a set
// (JSON.stringify(new Map()) is [] instead of {})
import 'core-js/fn/map';
import 'core-js/fn/set';
import 'core-js/fn/weak-map';

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <Router>
    <QueryClientProvider client={queryClient}>
      <App queryClient={queryClient} />
    </QueryClientProvider>
  </Router>,
);
