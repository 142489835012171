import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';

const menuWidth = '100%';
export const styles = {
  control: (baseStyle, state) => ({
    ...baseStyle,
    borderColor: state.isFocused ? 'rgba(38, 113, 255, 0.3)' : 'transparent',
    backgroundColor: '#E9E9F0FF',
    borderWidth: '2px',
    boxShadow: 'unset',
  }),
  container: (baseStyle) => ({
    ...baseStyle,
    flexGrow: 1,
  }),

  menu: (baseStyle) => ({
    ...baseStyle,
    width: menuWidth,
    'max-width': menuWidth,
  }),
  option: (baseStyle) => ({
    ...baseStyle,
    width: menuWidth,
    maxWidth: menuWidth,
  }),
  multiValue: (baseStyle) => ({
    ...baseStyle,
    backgroundColor: '#B5BBC3',
    alignItems: 'center',
    borderRadius: '6px',
  }),
  multiValueLabel: (baseStyle) => ({
    ...baseStyle,
    color: '#ffffff',
    fontWeight: '400',
    fontSize: '14px',
  }),
  multiValueRemove: (baseStyle) => ({
    ...baseStyle,
    backgroundColor: '#3D4C59',
    color: '#B5BBC3',
    padding: 0,
    height: '14px',
    margin: '0 2px',
    borderRadius: '50%',
  }),
};

const MultiSelect = ({ options, value, onChange, id = '' }) => (
  <Select styles={styles} options={options} id={id} value={value} onChange={onChange} isMulti />
);

MultiSelect.propTypes = {
  options: PropTypes.array.isRequired,
  value: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  id: PropTypes.string,
};

export default MultiSelect;
