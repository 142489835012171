import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { GenerateIcon, ICONS } from '@pileus-cloud/anodot-frontend-common';

import styles from './LeftSideTabs.module.scss';
import InfoPopover from '~/shared/components/andtComponents/InfoPopover/index.jsx';

const LeftSideTabs = ({ tabs, getSelectedTabComponent, selectedTab, setSelectedTab, showTabsTooltip }) => {
  const onTabClick = (tab) => {
    if (tab !== selectedTab) {
      setSelectedTab(tab);
    }
  };

  const isTabActive = (tab) => tab.id === selectedTab.id;

  return (
    <div className={styles.leftSideTabsContainer}>
      <div className={styles.leftSideMenu}>
        <div className={styles.leftSideTabs}>
          {tabs.map((tab) => (
            <div
              tabIndex={0}
              className={classNames(styles.leftSideTab, { [styles.isActive]: isTabActive(tab) })}
              onClick={() => onTabClick(tab)}
              onKeyDown={(e) => {
                if (e.key === 'Enter' || e.key === ' ') {
                  onTabClick(tab);
                }
              }}
              key={tab.id}
            >
              <div key={tab.id} className={styles.title}>
                {tab.label}
                {showTabsTooltip && tab?.tooltip && <InfoPopover isSimple>{tab?.tooltip}</InfoPopover>}
              </div>
              <div className={classNames(styles.selectionIndicator, { [styles.isActive]: isTabActive(tab) })}>
                {isTabActive(tab) ? (
                  <GenerateIcon iconName={ICONS.caretRight.name} className={styles.indicator} />
                ) : null}
              </div>
            </div>
          ))}
        </div>
        <div className={styles.side} />
      </div>
      <div className={styles.data}>{getSelectedTabComponent()}</div>
    </div>
  );
};

LeftSideTabs.propTypes = {
  getSelectedTabComponent: PropTypes.func,
  selectedTab: PropTypes.object,
  setSelectedTab: PropTypes.func,
  showTabsTooltip: PropTypes.bool,
  tabs: PropTypes.array,
};

export default LeftSideTabs;
