import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { cloneDeep, isEqual } from 'lodash';
import CustomModal, { MODAL_MODES } from 'shared/components/andtComponents/Modal';
import Stepper from 'shared/components/andtComponents/Stepper/Stepper';
import { GenerateIcon, ICONS } from '@pileus-cloud/anodot-frontend-common';
import useRoles from 'users/new-user-management/hooks/reactQuery/useRoles';
import useUsers from 'users/new-user-management/hooks/reactQuery/useUsers';
import toast from 'shared/components/andtComponents/Toast';
import DetailsStep from './DetailsStep';
import SubRolesStep from './SubRolesStep.jsx';
import EffectivePermissionsStep from './EffectivePermissionsStep';
import DataAccessStep from './DataAccessStep';
import UsersStep from './UsersStep.jsx';
import Spinner, { SPINNER_SIZES } from 'shared/components/andtComponents/Spinner';

import styles from './CreateEditRoleModal.module.scss';

const getRoleCreateEditSteps = (isDuplicate) => {
  return {
    DETAILS: {
      id: 'DETAILS',
      saveButtonTitle: `${isDuplicate ? 'Duplicate' : 'Create'}`,
      title: 'Details',
      titleShort: 'Details',
      component: DetailsStep,
    },
    SUB_ROLES: {
      id: 'SUB_ROLES',
      saveButtonTitle: 'Add Sub Roles',
      title: 'Sub Roles',
      titleShort: 'SubRoles',
      component: SubRolesStep,
    },
    PERMISSIONS: {
      id: 'PERMISSIONS',
      saveButtonTitle: 'Add Permissions',
      title: 'Permissions',
      titleShort: 'Permissions',
      component: EffectivePermissionsStep,
    },
    DATA_ACCESS: {
      id: 'DATA_ACCESS',
      saveButtonTitle: 'Add Data Access',
      title: 'Data Access',
      titleShort: 'DataAccess',
      component: DataAccessStep,
    },
    USERS: {
      id: 'USERS',
      saveButtonTitle: 'Add Users',
      title: 'Users',
      titleShort: 'Users',
      component: UsersStep,
    },
  };
};

const CreateEditRoleModal = ({ editRole, isOpen, onClose, hasUpdatePermissions = true, isDuplicate }) => {
  const [screenIndex, setScreenIndex] = useState(0);
  const [saveClicked, setSaveClicked] = useState(false);
  const [role, setRole] = useState(() => {
    let roleToSet = editRole ? { ...editRole } : {};
    if (isDuplicate) {
      const roleToDuplicate = {};
      roleToDuplicate.displayName = `${roleToSet.displayName} (Copy ${new Date().toLocaleString()})`;
      roleToDuplicate.description = roleToSet.description;
      roleToSet = roleToDuplicate;
    }
    return roleToSet;
  });
  const [roleOnCreateEdit, setRoleOnCreateEdit] = useState(cloneDeep(role));
  const [isSaving, setIsSaving] = useState(false);

  // isEdit mode meaning is: edit and not create.
  const isEditMode = role?.id;

  const createEditRoleSteps = useMemo(() => getRoleCreateEditSteps(isDuplicate), [isDuplicate]);

  const [roleValidations, setRoleValidations] = useState({});

  const Page = Object.values(createEditRoleSteps)?.[screenIndex]?.component;

  const { updateRolesAssignedToUsers } = useUsers();
  const {
    createRole,
    updateRole,
    updateSubRoles,
    setRolePermissions,
    updateAccountsAccessibilityForRole,
    updateCostCentersAccessibilityForRole,
  } = useRoles();

  const isValidTab = (tabId) => {
    let validations = {};
    if (tabId === createEditRoleSteps.DETAILS.id) {
      if (!role?.displayName) {
        validations = { ...validations, roleName: 'Role Name is required' };
      }
      setRoleValidations(validations);
      return !Object.keys(validations).length > 0;
    }
    return true;
  };

  const stepHandlers = {
    DETAILS: async (role, roleOnCreateEdit, { isEditMode, updateRole, createRole }) => {
      if (
        role.displayName === roleOnCreateEdit.displayName &&
        role.description === roleOnCreateEdit.description &&
        role?.id
      ) {
        return null;
      }

      const roleDetails = {
        displayName: role.displayName,
        description: role.description,
      };

      if (isEditMode) {
        return updateRole.mutateAsync({
          roleId: role.id,
          roleDetails,
        });
      }

      return createRole.mutateAsync({ role: roleDetails });
    },

    SUB_ROLES: async (role, roleOnCreateEdit, { updateSubRoles }) => {
      if (isEqual(role.subRoles, roleOnCreateEdit.subRoles)) {
        return null;
      }
      if (!role?.subRoles?.length) {
        return null;
      }

      return updateSubRoles.mutateAsync({
        roleId: role.id,
        subRolesPayload: role.subRolesPayload,
      });
    },

    PERMISSIONS: async (role, roleOnCreateEdit, { setRolePermissions }) => {
      if (isEqual(role.permissions, roleOnCreateEdit.permissions)) {
        return null;
      }
      if (!role?.permissions?.length) {
        return null;
      }
      return setRolePermissions.mutateAsync({
        roleId: role.id,
        roleCategoryPermissions: role.permissions,
      });
    },

    DATA_ACCESS: async (
      role,
      roleOnCreateEdit,
      { updateAccountsAccessibilityForRole, updateCostCentersAccessibilityForRole },
    ) => {
      if (
        isEqual(role.roleDataAccess, roleOnCreateEdit.roleDataAccess) &&
        isEqual(role.roleCostCentersDataAccess, roleOnCreateEdit.roleCostCentersDataAccess)
      ) {
        return null;
      }

      const promises = [];
      if (!isEqual(role.roleDataAccess, roleOnCreateEdit.roleDataAccess)) {
        const resAccounts = await updateAccountsAccessibilityForRole.mutateAsync({
          roleId: role.id,
          roleDataAccess: role.roleDataAccess,
        });
        promises.push(resAccounts);
      }
      if (!isEqual(role.roleCostCentersDataAccess, roleOnCreateEdit.roleCostCentersDataAccess)) {
        const resCostCenters = await updateCostCentersAccessibilityForRole.mutateAsync({
          roleId: role.id,
          roleDataAccess: role.roleCostCentersDataAccess,
        });
        promises.push(resCostCenters);
      }
      return Promise.all(promises);
    },

    USERS: async (role, roleOnCreateEdit, { updateRolesAssignedToUsers }) => {
      if (isEqual(role?.selectedUserIds, roleOnCreateEdit?.selectedUserIds)) {
        return null;
      }
      if (!role?.selectedUserIds?.length) {
        return null;
      }

      return updateRolesAssignedToUsers.mutateAsync({
        userIds: role.selectedUserIds,
        roleIdsToAdd: [role.id],
      });
    },
  };

  const onHandlerSuccess = {
    DETAILS: (role, roleOnCreateEdit, result) => {
      if (result) {
        setRole({ ...role, id: result });
        setRoleOnCreateEdit({
          ...roleOnCreateEdit,
          displayName: role.displayName,
          description: role.description,
          id: result,
        });
      }
    },
    SUB_ROLES: async (role, roleOnCreateEdit, result) => {
      if (result) {
        setRoleOnCreateEdit({
          ...roleOnCreateEdit,
          subRoles: role.subRoles,
        });
      }
    },

    PERMISSIONS: async (role, roleOnCreateEdit, result) => {
      if (result) {
        setRoleOnCreateEdit({
          ...roleOnCreateEdit,
          permissions: role.permissions,
        });
      }
    },
    DATA_ACCESS: async (role, roleOnCreateEdit, result) => {
      if (result) {
        setRoleOnCreateEdit({
          ...roleOnCreateEdit,
          roleDataAccess: role.roleDataAccess,
          roleCostCentersDataAccess: role.roleCostCentersDataAccess,
        });
      }
    },

    USERS: async (role, roleOnCreateEdit, result) => {
      if (result) {
        setRoleOnCreateEdit({
          ...roleOnCreateEdit,
          userIds: role.selectedUserIds,
        });
      }
    },
  };

  const saveRoleData = async () => {
    const currentStep = Object.values(createEditRoleSteps)[screenIndex].id;
    const handler = stepHandlers[currentStep];
    if (handler) {
      const result = await handler(role, roleOnCreateEdit, {
        isEditMode,
        updateRole,
        createRole,
        updateSubRoles,
        setRolePermissions,
        updateAccountsAccessibilityForRole,
        updateCostCentersAccessibilityForRole,
        updateRolesAssignedToUsers,
      });
      onHandlerSuccess[currentStep](role, roleOnCreateEdit, result);
    }
  };

  const setNextPage = async () => {
    setSaveClicked(true);
    if (hasUpdatePermissions) {
      if (!isValidTab(Object.values(createEditRoleSteps)?.[screenIndex].id)) {
        return;
      }
      try {
        setIsSaving(true);
        await saveRoleData();
      } catch (error) {
        if (error.response.status === 500) {
          toast.error(`Error on the ${Object.values(createEditRoleSteps)?.[screenIndex].title} step`);
        }
      } finally {
        setIsSaving(false);
      }
    }
    if (screenIndex < Object.values(createEditRoleSteps).length - 1) {
      const nextScreenIndex = screenIndex + 1;
      setScreenIndex(nextScreenIndex);
    } else {
      onClose();
    }
  };

  const getFooterMessage = () => {
    if (isSaving && screenIndex === 0) {
      return (
        <div className={styles.savingRoleIndication}>
          <div className={styles.spinner}>
            <Spinner size={SPINNER_SIZES.SMALL} />
          </div>
          <span className={styles.text}>We are {isEditMode ? 'editing' : 'creating'} the role</span>
        </div>
      );
    }
  };

  const getButtonTitle = (text, icon, isLeft) => (
    <div className={styles.buttonTitle}>
      {isLeft && <GenerateIcon iconName={icon} className={styles.buttonIcon} />}
      <div>{text}</div>
      {!isLeft && <GenerateIcon iconName={icon} className={styles.buttonIcon} />}
    </div>
  );

  const getModalTitle = () => {
    let modalTitle = isEditMode ? `Edit Role ${role.displayName}` : 'Create Role';
    if (!hasUpdatePermissions) {
      modalTitle = `View Role ${role.displayName}`;
    }
    if (isDuplicate) {
      modalTitle = `Duplicate Role ${editRole.displayName}`;
    }
    return modalTitle;
  };

  const getSaveButtonTitle = () => {
    let stepSaveButtonTitleIfEditAllowed = Object.values(createEditRoleSteps)?.[screenIndex].saveButtonTitle;
    if (!hasUpdatePermissions) {
      if (screenIndex === Object.values(createEditRoleSteps).length - 1) {
        stepSaveButtonTitleIfEditAllowed = 'Finish';
      } else {
        stepSaveButtonTitleIfEditAllowed = 'Next';
      }
    }
    return stepSaveButtonTitleIfEditAllowed;
  };

  return (
    <CustomModal
      automationId={`${isEditMode ? 'edit' : 'create'}-role-modal`}
      className={styles}
      closeOnSave={false}
      footerMessage={getFooterMessage()}
      isSecondPrimaryError={false}
      onClose={() => {
        onClose();
      }}
      onCloseClick={() => {
        onClose();
      }}
      headerMode={isEditMode ? MODAL_MODES.EDIT : MODAL_MODES.ADD}
      onSave={setNextPage}
      open={isOpen}
      onSecondPrimaryClick={() => setScreenIndex(screenIndex - 1)}
      secondPrimaryTitle={screenIndex !== 0 ? getButtonTitle('Back', ICONS.chevronLeft.name, true) : null}
      secondPrimaryDisabled={screenIndex === 0}
      saveDisabled={isSaving}
      saveTitle={getButtonTitle(getSaveButtonTitle(), ICONS.chevronRight.name, false)}
      title={getModalTitle()}
    >
      <div className={styles.stepperContainer}>
        <Stepper activeStepIndex={screenIndex} steps={Object.values(createEditRoleSteps)} />
      </div>
      {Page && (
        <Page
          isViewOnly={isEditMode && !hasUpdatePermissions}
          isEditMode={isEditMode}
          roleId={isDuplicate ? editRole.id : role.id}
          role={role}
          setRole={setRole}
          saveClicked={saveClicked}
          roleValidationErrors={roleValidations}
          setRoleOnCreateEdit={setRoleOnCreateEdit}
        />
      )}
    </CustomModal>
  );
};

CreateEditRoleModal.propTypes = {
  editRole: PropTypes.object,
  isDuplicate: PropTypes.bool,
  hasUpdatePermissions: PropTypes.bool,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
};

export default CreateEditRoleModal;
