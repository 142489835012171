/* eslint-disable no-unused-vars */
/* eslint-disable react/no-array-index-key */

import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Pie, PieChart, ResponsiveContainer, Text, Tooltip } from 'recharts';
import { Row } from 'reactstrap';
import { StringToColor } from 'shared/utils/graphicUtil';
import { useUserSettingsContext } from 'users/utils/contexts/UserSettingsContext';

const renderLegend = ({ payload }) => (
  // <ul className="dashboard__chart-legend">
  (<ul style={{}}>
    {payload.map((entry, index) => (
      <li key={`item-${index}`}>
        <span style={{ backgroundColor: `${entry.color}` }} />
        {entry.value}
      </li>
    ))}
  </ul>)
);

renderLegend.propTypes = {
  payload: PropTypes.arrayOf(
    PropTypes.shape({
      color: PropTypes.string,
      vslue: PropTypes.string,
    }),
  ).isRequired,
};

const prepareTopPanelPieData = (data) => {
  const aggData = data.reduce((acc, { totalCost, groupBy }) => {
    const tempObj = { value: totalCost, name: groupBy, fill: StringToColor.next(groupBy) };
    const index = acc.findIndex((obj) => obj.name === tempObj.name);
    if (index > -1) {
      acc[index].value += tempObj.value;
    } else {
      acc.push(tempObj);
    }
    return acc;
  }, []);
  return aggData;
};

const PieChartComp = memo((props) => {
  const { getCurrencyNumber } = useUserSettingsContext();
  return (
    <Row id={props.chartId} className="chart-box-style">
      <ResponsiveContainer className="dashboard__chart-pie" width="100%" height={props.height}>
        <PieChart className="dashboard__chart-pie-container">
          <Pie
            data={prepareTopPanelPieData(props.data)}
            dataKey="value"
            nameKey="name"
            cy={140}
            innerRadius="60%"
            outerRadius="85%"
          />
          <Tooltip formatter={(value) => `${getCurrencyNumber(value, 0, { roundNumber: true })}`} />
          <Text x={400} y={200} textAnchor="middle" dominantBaseline="middle">
            Donut
          </Text>
        </PieChart>
      </ResponsiveContainer>
    </Row>
  );
});

PieChartComp.propTypes = {
  data: PropTypes.object.isRequired,
  height: PropTypes.object.isRequired,
  chartId: PropTypes.object.isRequired,
};

export default PieChartComp;
