import React from 'react';
import PropTypes from 'prop-types';
import { ComposableMap, Geographies, Geography, Marker, ZoomableGroup } from 'react-simple-maps';
import { DisplayMetricTypes } from 'usage/constants/costAndUsageConstants';
import PanelWithActionButton from 'shared/components/panels/PanelWithActionButton';
import LinkIconButton from 'shared/components/buttons/LinkIconButton';
import { cloudTypeToRegionMarkersMap } from 'shared/constants/regionMarkersConstants';
import { REPORT_TYPES } from 'usage/constants/usageConstants';
import ReportIcon from 'mdi-react/FileDocumentBoxIcon';
import { useUserSettingsContext } from 'users/utils/contexts/UserSettingsContext';
import { useRootStore } from 'app/contexts/RootStoreContext';
import geoData from './world-50m.json';

const wrapperStyles = {
  width: '100%',
  maxWidth: 980,
  margin: '0 auto',
};

const getUsageCost = (regionData, regionTag) => {
  const region = regionData.find((currRegion) => currRegion.regionTag === regionTag);
  if (region) {
    return region.usageCost;
  }

  return 0;
};

const percentOfTotal = (regionSum, totalRegionSum) => {
  let per = '0.0';
  try {
    if (totalRegionSum > 0) {
      per = (regionSum / totalRegionSum) * 100;
      per = per.toFixed(3);
    }
  } catch (error) {
    per = '';
  }

  return per;
};

const filterMarkersMap = (regionFilters, markers) => {
  const resultMarkets = (markers || []).filter((marker) => {
    const result = regionFilters.filter((curr) => curr.regionTag.includes(marker.regionTag));
    return result.length > 0;
  });

  return resultMarkets;
};

const groupByRegions = (regionsArr) => {
  const result = [];
  regionsArr.reduce((res, value) => {
    if (!res[value.regionTag]) {
      res[value.regionTag] = { regionTag: value.regionTag, usageCost: 0 };
      result.push(res[value.regionTag]);
    }
    res[value.regionTag].usageCost += value.usageCost;
    return res;
  }, {});

  return result;
};

const normalizeRegionCost = (regionsArr, minRange, maxRange) => {
  const costArr = regionsArr.map((region) => region.usageCost);
  const max = Math.max.apply(null, costArr);
  const min = Math.min.apply(null, costArr);

  const firstStep = regionsArr.map((region) => ({
    regionTag: region.regionTag,
    usageCost: region.usageCost - min,
  }));
  const secondStep = firstStep.map((region) => ({
    regionTag: region.regionTag,
    usageCost: region.usageCost / max,
  }));

  const rangeDiff = maxRange - minRange;
  const thirdStep = secondStep.map((region) => ({
    regionTag: region.regionTag,
    usageCost: region.usageCost * rangeDiff,
  }));
  const result = thirdStep.map((region) => ({
    regionTag: region.regionTag,
    usageCost: region.usageCost + minRange,
  }));

  return result;
};

const unifyZones = (resultRegions) =>
  resultRegions.map((region) => ({
    regionName: region.regionName,
    regionTag: region.regionTag,
    usageCost: region.usageCost,
  }));

const unionZonesToRegionArray = (resultZones) => {
  const resultWithRegion = unifyZones(resultZones);
  const resultGroupBy = groupByRegions(resultWithRegion);
  return resultGroupBy;
};

const unionZonesToNormalizedRegionArray = (resultZones, markSizeRange) => {
  const resultUnionedZones = unionZonesToRegionArray(resultZones);
  const resultNormalize = normalizeRegionCost(resultUnionedZones, markSizeRange.min, markSizeRange.max);
  return resultNormalize;
};

function RegionActivityMap({
  data,
  md = 12,
  lg = 12,
  xl = 12,
  sm = 12,
  xs = 12,
  markSizeRange = { min: 3, max: 16 },
  title,
  handleGoToCostExplorerReport,
  actionButtonStyle,
  isCustomDashboard = false,
  overrideCurrency = null,
}) {
  const { usersStore } = useRootStore();
  const regionsData = unionZonesToRegionArray(data);
  const normalizedRegionsData = unionZonesToNormalizedRegionArray(data, markSizeRange);
  const regionNotAvailableUsageCost = getUsageCost(data, 'Global');
  const totalRegionUsageCostSum = getUsageCost(data, 'Total Cost');
  const markers = cloudTypeToRegionMarkersMap.get((usersStore || {}).currDispUserCloudAccountType);
  const { numStrAbriviaionByDisplayMetric } = useUserSettingsContext();
  const renderBody = () => (
    <>
      <h5>
        {'Total cost by region:  '}
        <span style={{ fontWeight: '425' }}>
          {numStrAbriviaionByDisplayMetric(
            totalRegionUsageCostSum,
            totalRegionUsageCostSum,
            DisplayMetricTypes.COST,
            overrideCurrency,
          )}
        </span>
      </h5>
      <h5>
        {'Global costs:  '}
        <span style={{ fontWeight: '425' }}>
          {numStrAbriviaionByDisplayMetric(
            regionNotAvailableUsageCost,
            regionNotAvailableUsageCost,
            DisplayMetricTypes.COST,
            overrideCurrency,
          )}
        </span>
      </h5>
      <div style={wrapperStyles}>
        <ComposableMap
          projection="geoEqualEarth"
          projectionConfig={{
            scale: 210,
            xOffset: isCustomDashboard ? -20 : 0,
            yOffset: 0,
            rotation: [-40, 0, 0],
          }}
          width={980}
          height={551}
          style={{
            width: '100%',
            height: 'auto',
          }}
        >
          <ZoomableGroup center={[0, 10]} disablePanning>
            <Geographies geography={geoData}>
              {({ geographies, projection }) =>
                geographies.map(
                  (geography) =>
                    geography.id !== 'ATA' && (
                      <Geography
                        // eslint-disable-next-line react/no-array-index-key
                        key={geography.rsmKey}
                        geography={geography}
                        projection={projection}
                        style={{
                          default: {
                            fill: '#e8e8e8',
                            stroke: '#e8e8e8',
                            strokeWidth: 0.75,
                            outline: 'none',
                          },
                          hover: {
                            fill: '#e8e8e8',
                            stroke: '#e8e8e8',
                            strokeWidth: 1,
                            outline: 'none',
                          },
                        }}
                      />
                    ),
                )
              }
            </Geographies>

            {filterMarkersMap(normalizedRegionsData, markers).map((marker, i) => (
              <Marker
                id={`marker-${i}`}
                // eslint-disable-next-line react/no-array-index-key
                key={i}
                coordinates={marker.coordinates}
                style={{
                  default: { fill: '#134b66' },
                  hover: { fill: '#FFFFFF' },
                  pressed: { fill: '#FFFFFF' },
                }}
              >
                <circle
                  cx={0}
                  cy={0}
                  r={getUsageCost(normalizedRegionsData, marker.regionTag)}
                  style={{
                    stroke: '#134b66',
                    strokeWidth: 2,
                    opacity: 0.7,
                  }}
                />

                <text
                  fontSize="14"
                  // textAnchor="start"
                  textAnchor="middle"
                  x={marker.markerOffsetX}
                  y={marker.markerOffsetY}
                  style={{
                    fontFamily: 'Roboto, sans-serif',
                    fill: '#ff653a',
                  }}
                >
                  {marker.displayName}
                </text>
                <text
                  fontSize="12"
                  // textAnchor="start"
                  textAnchor="middle"
                  x={marker.subOffsetX}
                  y={marker.subOffsetY}
                  style={{
                    fontFamily: 'Roboto, sans-serif',
                    fill: '#000000',
                  }}
                >
                  {`${numStrAbriviaionByDisplayMetric(
                    getUsageCost(regionsData, marker.regionTag),
                    getUsageCost(regionsData, marker.regionTag),
                    DisplayMetricTypes.COST,
                    overrideCurrency,
                  )} (${percentOfTotal(getUsageCost(regionsData, marker.regionTag), totalRegionUsageCostSum)}%)`}
                </text>
              </Marker>
            ))}
          </ZoomableGroup>
        </ComposableMap>
      </div>
    </>
  );
  if (isCustomDashboard) {
    return <div>{renderBody()}</div>;
  }
  return (
    <PanelWithActionButton
      divider
      xl={xl}
      lg={lg}
      sm={sm}
      md={md}
      xs={xs}
      title={title}
      ActionButtonComp={LinkIconButton}
      ButtonIcon={ReportIcon}
      handler={handleGoToCostExplorerReport}
      id={REPORT_TYPES.REGION}
      actionButtonStyle={actionButtonStyle}
    >
      {renderBody()}
    </PanelWithActionButton>
  );
}

RegionActivityMap.propTypes = {
  handleGoToCostExplorerReport: PropTypes.func.isRequired,
  isCustomDashboard: PropTypes.bool,
  actionButtonStyle: PropTypes.object.isRequired,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      regionName: PropTypes.string.isRequired,
      regionTag: PropTypes.string.isRequired,
      usageCost: PropTypes.number.isRequired,
    }),
  ).isRequired,
  md: PropTypes.number,
  lg: PropTypes.number,
  xl: PropTypes.number,
  sm: PropTypes.number,
  xs: PropTypes.number,
  markSizeRange: PropTypes.shape({
    min: PropTypes.number.isRequired,
    max: PropTypes.number.isRequired,
  }),
  title: PropTypes.string.isRequired,
  overrideCurrency: PropTypes.string,
};

export default RegionActivityMap;
