/* eslint-disable no-unused-vars */
 
 
import { RecommendationTypes } from 'recommendations/constants/recommendationsConstants';
import { AzureRiRecommendation } from 'recommendations/models/recommTypes';
import { RecommendationCost } from 'recommendations/models/recommCommonTypes';
import AzureCostRecommendationBuilder from 'recommendations/models/builders/azureCostRecommBuilder';
import { calcNumOfMonthsToBreakEven } from 'shared/utils/calcUtil';

export default class AzureRiCostRecommendationBuilder extends AzureCostRecommendationBuilder {
  constructor(rawRecommendation) {
    super(rawRecommendation);
  }

  createRecommendation() {
    this._recommendation = new AzureRiRecommendation();
  }

  getRecommType() {
    return RecommendationTypes.AZURE_VM_RI;
  }

  buildRecommTitle() {
    const { action } = this._rawRecommendation;
    this._recommendation.title = action ? `${action} Reserved Instances` : 'Buy Reserved Instances';
  }

  build() {
    super.build();
    const {
      quantity,
      sku_name: skuName,
      meter_id: meterId,
      recommended_plan: riType,
      yearly_cost_current: currYearlyCost,
      yearly_cost_recommended: recommYearlyCost,
      total_cost_recommended: recommMonthlyCost,
      total_cost_current: currMonthlyCost,
      recommended_plan: recommPlan,
      action,
      zone_tag: zoneTag,
      additional_recommended_plans: additionalRecommPlans,
      ri_table: riTable,
      // potential_savings: potentialSavings,
      running_hours_needed: runningHoursNeeded,
      total_hours: totalRunningHours,
      ri_instances_types: riInstancesTypes,
    } = this._rawRecommendation;

    const mapInstanceTypeToCount = {};

    if (riInstancesTypes) {
      riInstancesTypes.forEach((instanceType) => {
        if (!(instanceType in mapInstanceTypeToCount)) {
          const count = riInstancesTypes.filter((countedType) => countedType === instanceType).length;
          mapInstanceTypeToCount[instanceType] = count;
        }
      });
    }

    this._recommendation.quantity = parseInt(quantity, 0);
    this._recommendation.riType = riType;

    const yearlyRecommCost = new RecommendationCost(currYearlyCost, recommYearlyCost);
    this._recommendation.breakEvenMonth = calcNumOfMonthsToBreakEven(recommYearlyCost, currYearlyCost);
    this._recommendation.region = zoneTag;
    this._recommendation.skuName = skuName;
    this._recommendation.yearlyCost = yearlyRecommCost;
    this._recommendation.recommPlan = recommPlan;
    this._recommendation.action = action;
    this._recommendation.additionalRecommPlans = additionalRecommPlans;
    this._recommendation.riTable = riTable;
    // this._recommendation.potentialSavings = potentialSavings;
    this._recommendation.runningHoursNeeded = runningHoursNeeded;
    this._recommendation.totalRunningHours = totalRunningHours;
    this._recommendation.mapInstanceTypeToCount = mapInstanceTypeToCount;
  }
}
