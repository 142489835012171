import React, { useState } from 'react';
import PropTypes from 'prop-types';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import IconFromPng from 'shared/components/IconFromPng';
import ICONS from 'shared/constants/assetsConstants';
import Tooltip from 'shared/components/andtComponents/Tooltip';
import classes from './alertActions.module.scss';

const AlertsActions = ({ onAdd, onDelete, isCreditAlert = false, tooltipText = 'Credit Alert' }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const toggleMenu = (value) => {
    setAnchorEl(value);
  };
  return (
    <>
      <Tooltip arrow placement="bottom" title={tooltipText}>
        <IconButton
          aria-owns={anchorEl ? 'simple-menu' : null}
          aria-haspopup="true"
          onClick={(e) => toggleMenu(e.currentTarget)}
          size="large"
        >
          <IconFromPng icon={isCreditAlert ? ICONS.alert : ICONS.emptyAlert} size="13px" />
        </IconButton>
      </Tooltip>
      <Menu
        classes={{ list: classes.menu }}
        id="simple-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => toggleMenu(null)}
      >
        <MenuItem onClick={onAdd} classes={{ root: classes.menuItem }}>
          <div>
            <IconFromPng className="me-2" icon={ICONS.alertBlack} size="15px" matchHeight="15px" />
            <span className={classes.menuItem}>{isCreditAlert ? 'Edit / View Alert' : 'Create Alert'}</span>
          </div>
        </MenuItem>
        <MenuItem classes={{ root: classes.menuItem }} onClick={onDelete} disabled={!isCreditAlert}>
          <div>
            <IconFromPng className="me-2" icon={ICONS.deleteIcon} size="14px" matchHeight="16px" />
            <span className={classes.menuItem}>Delete Alert</span>
          </div>
        </MenuItem>
      </Menu>
    </>
  );
};

AlertsActions.propTypes = {
  onAdd: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  isCreditAlert: PropTypes.bool,
  tooltipText: PropTypes.string,
};

export default AlertsActions;
