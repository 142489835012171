import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Modal from 'shared/components/andtComponents/Modal';
import { useCustomer } from 'shared/hooks/react-query/useDivisions';
import UpdateResellerCustomerRoleForm from './UpdateResellerCustomerRoleForm';
import RoleDivisionReviewTable from './RoleDivisionReviewTable';

const EditRoleModal = ({ isOpen = false, onClose, role }) => {
  const [newDivisions, setNewDivisions] = useState(role.linkedDivisions);
  const [divisionState, setDivisionState] = useState({
    accountId: '',
    divisionId: '',
    accountKey: '',
  });
  const { data: customerData, isLoading: isCustomerDataLoading } = useCustomer(divisionState, {
    retry: false,
  });
  return (
    <Modal open={isOpen} onClose={onClose} title={`Edit role ${role.roleName} (${role.uuid})`} saveTitle="Update role">
      <div className="d-flex flex-column justify-content-center">
        <UpdateResellerCustomerRoleForm
          divisionState={divisionState}
          divisionDispatcher={({ field, value }) => setDivisionState((prevState) => ({ ...prevState, [field]: value }))}
          onAddCustomer={() => setNewDivisions((prevState) => [...prevState, customerData])}
          addCustomerDisabled={
            !customerData ||
            newDivisions.some((division) =>
              ['accountId', 'accountKey', 'divisionId'].every((key) => division[key] === customerData[key]),
            )
          }
          isCustomerDataLoading={isCustomerDataLoading}
        />
        <RoleDivisionReviewTable
          roleDivisions={newDivisions}
          onDelete={({ accountId, accountKey, divisionId }) =>
            setNewDivisions((prevState) =>
              prevState.filter(
                (division) =>
                  division.accountId !== accountId ||
                  +division.accountKey !== +accountKey ||
                  +division.divisionId !== +divisionId,
              ),
            )
          }
          onSave={() => {}}
          saveButtonDisabled={!customerData}
          saveButtonLoading={isCustomerDataLoading}
        />
      </div>
    </Modal>
  );
};

EditRoleModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  role: PropTypes.shape({
    uuid: PropTypes.string.isRequired,
    linkedDivisions: PropTypes.array || [],
    roleName: PropTypes.string.isRequired,
  }).isRequired,
};

export default EditRoleModal;
