import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Select, { components } from 'react-select';
import { GenerateIcon, ICONS, SelectMulti, MenuList, customStyles, OptionMulti, EmptyComponent } from '@pileus-cloud/anodot-frontend-common';
import styles from './filterField.module.scss';

const optionComponent = ({ isSelected = false, label, isFocused = false, data, ...props }) => (
    <components.Option {...props}>
        <div className={`${styles.option} ${isFocused || isSelected ? styles.focused : null}`}>
            <label
                className={`${isSelected ? styles.activeOption : ''}`}
                style={{
                    width: `${label.length * 8}px`,
                    fontWeight: data?.bold ? 'bold' : 'normal',
                }}
                htmlFor="cb-item"
            >
                {label}
            </label>
        </div>
    </components.Option>
);

const InputComponent = (props) => (
    <div className={styles.inputWrapper}>
        <GenerateIcon iconName={ICONS.search.name} className={styles.searchIcon} />
        <span className={styles.placeholder}>{!props.selectProps.inputValue ? 'Search' : null}</span>
        <components.Input {...props} className={styles.searchInput} />
    </div>
);

InputComponent.propTypes = {
    selectProps: PropTypes.object.isRequired
};

const customComponents = (isMulti) => ({
    Input: InputComponent,
    DropdownIndicator: EmptyComponent,
    MultiValue: EmptyComponent,
    SingleValue: EmptyComponent,
    MultiValueRemove: EmptyComponent,
    Placeholder: EmptyComponent,
    ClearIndicator: EmptyComponent,
    Option: isMulti ? OptionMulti : optionComponent,
    MenuListFooter: MenuList
});

const filterSelectStyles = (menuWidth, menuHeight, menuMinWidth) => {
    const styles = customStyles(menuWidth, menuHeight, menuMinWidth);
    return {
        ...styles,
        control: (base) => ({
            ...base,
            ...styles.control(base),
            zIndex: 1,
            background: '#ebf1ff'
        }),
    };
};

const FilterField = ({
                         options,
                         filterType,
                         handleSelectionChange,
                         onSelectMultiButtonClick,
                         selectedOptions,
                         height = 140,
                         width = 200,
                         isMulti = false
                     }) => {
    const [isSelectMenuOpen, setIsSelectMenuOpen] = useState(false);

    const selectButtonComponentGetter = (onButtonClick, selectedOptions) => {
        const counterLabel = selectedOptions?.length > 1 ? `+ ${selectedOptions?.length - 1}` : '';
        return (
            <button className={styles.filterButtonWrapper} onClick={onButtonClick}>
                <span className={styles.name}>{filterType?.label}</span>
                <span className={styles.value}>
                    {selectedOptions?.length ? `${selectedOptions[0]?.label} ${counterLabel}` : 'Selected'}
                </span>
                <GenerateIcon iconName={ICONS.caretDown.name} />
            </button>
        );
    };

    const onBlur = () => {
        setIsSelectMenuOpen(false);
    };

    const onMenuClick = () => {
        setIsSelectMenuOpen(!isSelectMenuOpen);
        onSelectMultiButtonClick(isSelectMenuOpen, filterType?.id);
    };

    return (
        <div className={styles.filterWrapper} automation-id={`md-filter-${filterType.label}`}>
            {isMulti ? (
                <SelectMulti
                    handleSelectionChange={(selected) => handleSelectionChange(selected)}
                    label={filterType?.label}
                    onSelectMultiButtonClick={(isMenuOpen) => onSelectMultiButtonClick(isMenuOpen, filterType?.id)}
                    selected={selectedOptions}
                    selectButtonComponentGetter={selectButtonComponentGetter}
                    onSelectMultiMenuClose={() => handleSelectionChange(selectedOptions)}
                    showCounter={false}
                    options={options}
                    height={height}
                />
            ) : (
                <div>
                    {selectButtonComponentGetter(onMenuClick, selectedOptions)}
                    {isSelectMenuOpen && (
                        <Select
                            menuIsOpen={isSelectMenuOpen}
                            onChange={(selected) => handleSelectionChange(selected)}
                            options={options}
                            components={customComponents(isMulti)}
                            value={selectedOptions}
                            styles={filterSelectStyles(width, height, width)}
                            isMulti={isMulti}
                            autoFocus
                            closeMenuOnSelect={false}
                            escapeClearsValue
                            onBlur={onBlur}
                            hideSelectedOptions={false}
                            defaultMenuIsOpen
                            placeholder="Search"
                        />
                    )}
                </div>
            )}
        </div>
    );
};

FilterField.propTypes = {
    options: PropTypes.array.isRequired,
    filterType: PropTypes.object.isRequired,
    handleSelectionChange: PropTypes.func.isRequired,
    onSelectMultiButtonClick: PropTypes.func.isRequired,
    selectedOptions: PropTypes.array.isRequired,
    height: PropTypes.number,
    width: PropTypes.number,
    minWidth: PropTypes.number,
    isMulti: PropTypes.bool,
};

customComponents.propTypes = {
    selectProps: PropTypes.object.isRequired
};

export default FilterField;
