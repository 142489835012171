import React from "react";
import PropTypes from 'prop-types';
import InfoPopover from "~/shared/components/andtComponents/InfoPopover/index.jsx";
import { calculateFieldValue, getPropertyValue } from 'commitment/containers/spAnalyzerNew/utils/savingPlansAnalyzerUtils.js';
import styles from './selectedProperties.module.scss';
import {useUserSettingsContext} from "~/users/utils/contexts/UserSettingsContext.jsx";
import {GenerateIcon, ICONS} from "@pileus-cloud/anodot-frontend-common";

const SelectedProperties = ({  selectedProperties, shareProperties, plan}) => {

  const { getCurrencyNumber } = useUserSettingsContext();

  const getPercentValue = (percentProps) => {
      const currentValue = getPropertyValue( percentProps.currentKey, shareProperties, plan, getCurrencyNumber);
      const recommendedValue = getPropertyValue(percentProps.estimatedKey, shareProperties, plan, getCurrencyNumber);
      return Number((currentValue - recommendedValue) * 100 / currentValue).toFixed(2)
  }
  return (
    <div className={styles.mainWrapper}>
        {Object.keys(selectedProperties).map((key, index) => (
            <div key={key} className={`${styles.propertiesWrapper} ${index > 0 && styles.secondPartWrapper}`}>
                <span className={`${styles.header} ${selectedProperties[key].info && styles.smallHeader}`}>
                    {selectedProperties[key].header}
                    {selectedProperties[key].info &&
                       (<InfoPopover isSimple mode="outline" className={{icon: styles.info}}>
                           {selectedProperties[key].info}
                       </InfoPopover>)}
                </span>
                {selectedProperties[key].properties?.map((property, index) => (
                  <>
                    {property.splitLine && <div className={styles.splitLine}></div>}
                    <div key={property}
                         className={`${styles.propertyWrapper} 
                         ${index === selectedProperties[key].properties.length - 1 && styles.noBorder}`}
                    >
                      <span className={styles.propertyValue}>
                          {property.estimatedProp && (<span className={styles.triangle}></span>)}
                          {property.calculatedFields?.length ? calculateFieldValue(property, shareProperties, plan, getCurrencyNumber)
                              : getPropertyValue(property, shareProperties, plan, getCurrencyNumber)}
                      </span>
                      <span className={`${styles.propertyName} ${property.name.split(' ').length > 3 && styles.longName}`}>{property.name}</span>
                    </div>
                      {property.percentOf && (
                          <div className={`${styles.percentTag} ${getPercentValue(property.percentOf) < 0 && styles.redTag}`} >
                              {`${getPercentValue(property.percentOf)}% `}
                              <GenerateIcon iconName={getPercentValue(property.percentOf) > 0 ? ICONS.arrowTrendDown.name : ICONS.arrowTrendUp.name} />
                          </div>
                      )}
                  </>
                ))}
            </div>)
        )}
    </div>
  );
}

SelectedProperties.propTypes = {
    selectedProperties: PropTypes.shape({
        newCost: PropTypes.shape({
            header: PropTypes.string.isRequired,
            info: PropTypes.string,
            properties: PropTypes.arrayOf(
                PropTypes.shape({
                    name: PropTypes.string.isRequired,
                    key: PropTypes.string.isRequired,
                    calculatedFields: PropTypes.array,
                })
            ).isRequired,
        }),
        totalCost: PropTypes.shape({
            header: PropTypes.string.isRequired,
            info: PropTypes.string,
            properties: PropTypes.arrayOf(
                PropTypes.shape({
                    name: PropTypes.string.isRequired,
                    key: PropTypes.string.isRequired,
                    calculatedFields: PropTypes.array,
                })
            ).isRequired,
        }),
    }).isRequired,
    shareProperties: PropTypes.object.isRequired,
    plan: PropTypes.object.isRequired,
};
export default SelectedProperties;
