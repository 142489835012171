import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from 'shared/components/andtComponents/Tooltip';
import LabelCoordinator from 'shared/modules/labelCoordinator';
import styles from './alertRuleDetailsFilter.module.scss';

const AlertRuleDetailsFilter = ({ title, field, include = [], exclude = [] }) => (
  <div className="d-flex align-items-center mb-2">
    <span className="me-2">{title}</span>
    <div className={`d-flex ${styles.filterValuesContainer}`}>
      <div className="d-flex">
        {include[field] && include[field].length ? <span className="me-1">+ Include</span> : null}
        {!include[field] || !include[field].length || include[field] === 'all' ? (
          <span className={styles.filterValue}>All</span>
        ) : (
          include[field].slice(0, 4).map((filterValue, idx) => (
            <Tooltip title={filterValue}>
              <div className={`${styles.filterValue} ${idx ? 'ms-2' : ''}`}>
                {LabelCoordinator.getServiceNameDisplayValue(filterValue)}
              </div>
            </Tooltip>
          ))
        )}
        {include[field].length > 4 ? (
          <Tooltip
            title={include[field]
              .slice(4)
              .map((filterValue) => LabelCoordinator.getServiceNameDisplayValue(filterValue))
              .join(', ')}
          >
            <div className={`${styles.filterValue} ms-2`}>+ {include[field].length - 4}</div>
          </Tooltip>
        ) : null}
      </div>
      {exclude[field] && exclude[field].length ? (
        <div className="d-flex ms-2">
          <span className="me-1">- Exclude</span>
          {exclude[field].slice(0, 4).map((filterValue, idx) => (
            <Tooltip title={filterValue}>
              <div className={`${styles.filterValue} ${idx ? 'ms-2' : ''}`}>
                {LabelCoordinator.getServiceNameDisplayValue(filterValue)}
              </div>
            </Tooltip>
          ))}
          {exclude[field].length > 4 ? (
            <Tooltip
              title={exclude[field]
                .slice(4)
                .map((filterValue) => LabelCoordinator.getServiceNameDisplayValue(filterValue))
                .join(', ')}
            >
              <div className={`${styles.filterValue} ms-2`}>+ {exclude[field].length - 4}</div>
            </Tooltip>
          ) : null}
        </div>
      ) : null}
    </div>
  </div>
);

AlertRuleDetailsFilter.propTypes = {
  field: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  include: PropTypes.array,
  exclude: PropTypes.array,
};

export default AlertRuleDetailsFilter;
