import React from 'react';
import PropTypes from 'prop-types';
import { capitalize } from 'shared/utils/strUtil';
import Tooltip from 'shared/components/andtComponents/Tooltip';

export default function AdminAccountItem({ user, handleUserClicked, id = 0 }) {
  const title = capitalize(user ? user.userDisplayName : '');
  return (
    <Tooltip title={title} placement="top" arrow>
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      <a
        id={`user_list_${id}`}
        href="#"
        style={user.isSecondary ? { paddingLeft: '20px' } : {}}
        className="topbar__link"
        onClick={() => handleUserClicked(user.userKey)}
      >
        {title}
      </a>
    </Tooltip>
  );
}

AdminAccountItem.propTypes = {
  user: PropTypes.object.isRequired,
  handleUserClicked: PropTypes.func.isRequired,
  id: PropTypes.number,
};
