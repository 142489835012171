import React, { useEffect, useState } from 'react';
import { ICONS, GenerateIcon, SelectMultiItem } from '@pileus-cloud/anodot-frontend-common';
import Input from 'shared/components/andtComponents/Input';
import Button from 'shared/components/andtComponents/Button';
import CustomCSVDownload from 'shared/components/buttons/CustomCSVDownload';
import PropTypes from 'prop-types';
import Tooltip from 'shared/components/andtComponents/Tooltip';
import tooltipStyles from 'shared/components/andtComponents/Tooltip.module.scss';
import { components } from 'react-select';
import Pagination from 'shared/components/andtComponents/Pagination';
import classes from './recommendationsTableHeader.module.scss';
import { MAX_EXPORTED_ROWS } from '../../../shared/constants/appConstants';
import { useRecommendationsNewContext } from '../../contexts/recommendationsNewContext';

function OptionMulti(props) {
  const { isSelected, label, isFocused, data } = props;
  const { recTypes } = data;
  return (
    <components.Option {...props}>
      <div className={`${classes.option} ${isFocused ? classes.focused : null}`}>
        <input className={classes.checkbox} type="checkbox" checked={isSelected} onChange={() => null} />{' '}
        <Tooltip title={label}>
          <span className={`${classes.label} ${isSelected ? classes.activeOption : ''}`}>{label}</span>
        </Tooltip>
        {recTypes?.length > 0 && (
          <Tooltip
            placement="bottom"
            title={
              <div className={classes.tagTooltipWrap}>
                {recTypes.map((tag) => (
                  <div key={`tagTP-${tag}`}>
                    <span className={classes.tagName}>{tag}</span>
                    <br />
                  </div>
                ))}
              </div>
            }
            classes={{ tooltip: tooltipStyles.whiteTooltip, arrow: tooltipStyles.whiteArrow }}
          >
            <div className={classes.chipWrapper}>
              <span className={classes.chip}>{`${recTypes.length} Types`}</span>
            </div>
          </Tooltip>
        )}
      </div>
    </components.Option>
  );
}
OptionMulti.propTypes = {
  label: PropTypes.string.isRequired,
  isSelected: PropTypes.bool.isRequired,
  isFocused: PropTypes.bool.isRequired,
};

const RecommendationsTableHeader = React.memo(
  ({
    rowsCount,
    displayCount,
    searchText = null,
    setSearchText,
    showFilters = false,
    setShowFilters,
    isExpanded = false,
    setIsExpanded,
    fetchExportData,
    options,
    setHiddenColumnNames,
    pageChange,
    currentPage,
    totalPages,
    isDataExporting,
    selectedItems,
  }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const { setShowCustomColumnsNames } = useRecommendationsNewContext();

    useEffect(() => {
      const handleClickOutside = (event) => {
        const selectMultiItem = document.querySelector('#select-multi-item');
        if (selectMultiItem && !selectMultiItem.contains(event.target)) {
          setAnchorEl(false);
        }
      };

      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, []);

    return (
      <div className={classes.header}>
        <div>
          <Button
            automationId="expand-rows"
            text=""
            isSecondary
            icon={() => <GenerateIcon iconName={isExpanded ? ICONS.collapse.name : ICONS.expand.name} />}
            onClick={() => {
              setIsExpanded(!isExpanded);
            }}
          />
          <span className={classes.headerText}>Recommendations</span>
          <span className={classes.count}>
            {`(${displayCount && displayCount !== rowsCount ? `${displayCount}/${rowsCount}` : rowsCount || ''})`}
          </span>
        </div>

        <div className={classes.rightMenu}>
          <div className="position-relative d-flex align-items-center">
            <Input
              className={classes.input}
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
              placeholder="Search"
              name="search-recommendations"
              isSearch
              searchComponent={<GenerateIcon iconName={ICONS.search.name} />}
              type="text"
            />
          </div>
          <div>
            <Tooltip title="Show Columns Chooser" arrow>
              <Button
                text="Columns"
                automationId="chooser-columns"
                onClick={() => setAnchorEl(!anchorEl)}
                isSecondary
                icon={() => <GenerateIcon iconName={ICONS.penToSquare.name} />}
              />
              <span />
            </Tooltip>
            <div style={{ zIndex: 10, position: 'absolute' }} id="select-multi-item">
              <SelectMultiItem
                isOpen={anchorEl}
                options={options}
                onChange={(values) => {
                  const newHidden = options.filter((column) => !values.find((v) => v.value === column.value));
                  setHiddenColumnNames(newHidden.map((column) => column.value));
                  const showCustomColumns = options.filter((column) =>
                    values.find((v) => v.value === column.value && v.recTypes?.length > 0),
                  );
                  setShowCustomColumnsNames(showCustomColumns.map((column) => column.value));
                }}
                selected={selectedItems}
                onMenuBlur={(e) => {
                  const columnsButton = document.querySelector('[automation-id="chooser-columns"]');
                  if (e.relatedTarget !== columnsButton) {
                    setAnchorEl(false);
                  }
                }}
                customOptionMultiComponent={OptionMulti}
                hideFooter
                width={390}
              />
            </div>
          </div>
          <Tooltip title="Filter columns value" arrow>
            <Button
              text="Filter Results"
              automationId="filter-results"
              onClick={() => {
                setShowFilters(!showFilters);
              }}
              overrideStyles={showFilters ? { background: '#DFE9FF' } : {}}
              isSecondary
              icon={() => <GenerateIcon iconName={showFilters ? ICONS.xMark.name : ICONS.filterList.name} />}
            />
            <span />
          </Tooltip>
          <Tooltip
            title={
              rowsCount > MAX_EXPORTED_ROWS
                ? 'You can export up to 100,000 recommendation records, please refine your filters to enable export'
                : 'Export'
            }
            arrow
          >
            <CustomCSVDownload
              fetchData={fetchExportData}
              automationId="export-recommendations"
              showDownloadIcon
              isLoading={isDataExporting}
              filesNumber={1}
              disabled={rowsCount > MAX_EXPORTED_ROWS}
              isSecondary
              isLightButton
              hideText
              style={{
                height: 36,
              }}
            />
            <span />
          </Tooltip>
          <Pagination currPageIdx={currentPage} onPageChange={pageChange} totalPages={totalPages} />
        </div>
      </div>
    );
  },
);

RecommendationsTableHeader.propTypes = {
  rowsCount: PropTypes.number.isRequired,
  displayCount: PropTypes.number.isRequired,
  searchText: PropTypes.string,
  setSearchText: PropTypes.func.isRequired,
  showFilters: PropTypes.bool,
  setShowFilters: PropTypes.func.isRequired,
  isExpanded: PropTypes.bool,
  setIsExpanded: PropTypes.func.isRequired,
};

export default RecommendationsTableHeader;
