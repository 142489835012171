 
import { RecommendationTypes } from 'recommendations/constants/recommendationsConstants';
import { KmsIdleRecommendation } from 'recommendations/models/recommTypes';
import CostRecommendationBuilder from 'recommendations/models/builders/costRecommBuilder';

export default class KmsIdleRecommendationBuilder extends CostRecommendationBuilder {
  constructor(rawRecommendation) {
    super(rawRecommendation);
  }

  createRecommendation() {
    this._recommendation = new KmsIdleRecommendation();
  }

  getRecommType() {
    return RecommendationTypes.KMS_IDLE;
  }

  buildRecommTitle() {}

  build() {
    super.build();
    const { zone_tag: region } = this._rawRecommendation;
    if (region && !this._recommendation.region) {
      this._recommendation.region = region;
    }
  }
}
