import CostRecommendationBuilder from 'recommendations/models/builders/costRecommBuilder';
import { GcpCostRecommendation } from 'recommendations/models/recommTypes';
// import { GcpRecommendationCost } from 'recommendations/models/recommCommonTypes';
import { RecommendationCost } from 'recommendations/models/recommCommonTypes';

export default class GcpCostRecommendationBuilder extends CostRecommendationBuilder {
  constructor(rawRecommendation) {
    super(rawRecommendation);
  }

  createRecommendation() {
    this._recommendation = new GcpCostRecommendation();
  }

  build() {
    super.build();
    const {
      total_cost_current: currTotalCost,
      total_cost_recommended: recommTotalCost,
      potential_savings: potentialSavings,
    } = this._rawRecommendation;
    const recommCost = new RecommendationCost(currTotalCost, recommTotalCost);

    // FLAT OBJECT FOR NOW
    this._recommendation.potentialSavings = parseFloat(potentialSavings);
    this._recommendation.currTotalCost = parseFloat(recommCost.currYearlyCost);
    this._recommendation.recommTotalCost = parseFloat(recommCost.recommYearlyCost);
    this._recommendation.currMonthlyCost = parseFloat(recommCost.currMonthlyCost);
    this._recommendation.recommMonthlyCost = parseFloat(recommCost.recommMonthlyCost);
    this._recommendation.reducePercent = parseFloat(recommCost.reducePercent);
    this._recommendation.monthlyCostPotentialSavings =
      parseFloat(recommCost.currMonthlyCost) - parseFloat(recommCost.recommMonthlyCost);
    this._recommendation.costPotentialSavings =
      parseFloat(recommCost.currYearlyCost) - parseFloat(recommCost.recommYearlyCost);
  }
}
