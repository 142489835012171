import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import apiConstants from 'shared/api/apiConstants';
import { API } from 'shared/utils/apiMiddleware';
import { toast } from 'react-toastify';

const root = '/api/v1/integrations/slack';

const getSlackIntegrations = () => API.get('billings', `${root}/integrations`);
const getSlackInstallUrl = () => API.get('billings', `${root}/install`);
const getSlackChannels = () => API.get('billings', `${root}/channels`);
const testSlackChannel = (slackChannel) => API.post('billings', `${root}/test`, { body: slackChannel });
const uninstallSlackIntegration = () => API.del('billings', `${root}/uninstall`);

export default function useSlack() {
  const queryClient = useQueryClient();
  return {
    getSlackIntegrations: (options = {}) =>
      useQuery([apiConstants.QUERY_KEYS.SLACK, 'integrations'], () => getSlackIntegrations(), {
        onError: () =>
          toast.error('Error fetching integrations data', {
            position: toast.POSITION.BOTTOM_RIGHT,
          }),
        staleTime: 10 * 60 * 1000,
        ...options,
      }),
    getSlackInstallUrl: (options = {}) =>
      useQuery([apiConstants.QUERY_KEYS.SLACK, 'install'], () => getSlackInstallUrl(), {
        onError: () =>
          toast.error('Error adding slack', {
            position: toast.POSITION.BOTTOM_RIGHT,
          }),
        staleTime: 10 * 60 * 1000,
        ...options,
      }),
    getSlackChannels: (options = {}) =>
      useQuery([apiConstants.QUERY_KEYS.SLACK, 'channels'], () => getSlackChannels(), {
        onError: () =>
          toast.error('Error fetching slack channels', {
            position: toast.POSITION.BOTTOM_RIGHT,
          }),
        staleTime: 10 * 60 * 1000,
        ...options,
      }),
    testSlackChannel: () => useMutation(testSlackChannel),
    uninstallSlackIntegration: () =>
      useMutation(uninstallSlackIntegration, {
        onSuccess: () => queryClient.invalidateQueries(apiConstants.QUERY_KEYS.SLACK),
      }),
  };
}
