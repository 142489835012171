import React from 'react';
import PropTypes from 'prop-types';
import ModalTable from 'shared/components/tables/DevExpSearchAndVisibilityTable';
import Spinner from 'shared/components/andtComponents/Spinner';
import CustomModal from 'shared/components/andtComponents/Modal';
import {
  CB_MODAL_TABLE_COLUMNS,
  CB_MODAL_TABLE_COLUMNS_WIDTHS,
  CB_MODAL_TABLE_CURRENCY_COLUMNS,
  RI_MODAL_TABLE_COLUMN_EXTENSIONS,
  RI_MODAL_TABLE_COLUMNS,
  RI_MODAL_TABLE_COLUMNS_WIDTHS,
  RI_MODAL_TABLE_CURRENCY_COLUMNS,
  SP_CB_MODAL_TABLE_COLUMNS,
  SP_CB_MODAL_TABLE_COLUMNS_WIDTHS,
  SP_CB_MODAL_TABLE_CURRENCY_COLUMNS,
} from '../constants/commitmentConstants';

const RiFlexibilityOrChargeBackModal = (props) => {
  const { toggle, isOpen, className = '', title, type, data, commitmentType, modalIsLoading } = props;
  const prepareModalTable = (tableType, tableData) => {
    const preparedData = tableData.map((dataItem) => {
      if ('usageDate' in dataItem) {
        // eslint-disable-next-line no-param-reassign
        dataItem.usageDate = dataItem.usageDate.slice(0, 10);
      }
      return dataItem;
    });
    let columns = [];
    let currenciesColumns = [];
    let columnWidths = [];
    let columnExtensions = [];
    let numericColumns = [];
    let csvTitle = 'csv';
    if (tableType === 'flexibility') {
      columns = RI_MODAL_TABLE_COLUMNS;
      currenciesColumns = RI_MODAL_TABLE_CURRENCY_COLUMNS;
      columnWidths = RI_MODAL_TABLE_COLUMNS_WIDTHS;
      columnExtensions = RI_MODAL_TABLE_COLUMN_EXTENSIONS;
      csvTitle = 'Ri_Flexability_data';
      numericColumns = ['totalUsageQuantity'];
    }
    if (tableType === 'cb') {
      columns = commitmentType === 'sp' ? SP_CB_MODAL_TABLE_COLUMNS : CB_MODAL_TABLE_COLUMNS;
      currenciesColumns =
        commitmentType === 'sp' ? SP_CB_MODAL_TABLE_CURRENCY_COLUMNS : CB_MODAL_TABLE_CURRENCY_COLUMNS;
      columnWidths = commitmentType === 'sp' ? SP_CB_MODAL_TABLE_COLUMNS_WIDTHS : CB_MODAL_TABLE_COLUMNS_WIDTHS;
      numericColumns = commitmentType === 'sp' ? ['quantity'] : [];
      csvTitle = 'Chargeback_data';
    }
    return { preparedData, columns, currenciesColumns, columnWidths, columnExtensions, csvTitle, numericColumns };
  };
  const { preparedData, columns, currenciesColumns, columnWidths, columnExtensions, csvTitle, numericColumns } =
    prepareModalTable(type, data);
  return (
    <CustomModal
      overrideStyles={{ width: 800 }}
      title={title}
      footerDisabled
      open={isOpen}
      onClose={toggle}
      className={className}
    >
      {modalIsLoading ? (
        <Spinner />
      ) : (
        <ModalTable
          data={preparedData}
          columns={columns}
          columnWidths={columnWidths}
          tableColumnExtensions={columnExtensions}
          numericColumns={numericColumns}
          defaultHiddenColumnNames={[]}
          defaultSorting={[]}
          currenciesColumns={currenciesColumns}
          csvTitle={csvTitle}
          costColumnsArray={currenciesColumns}
          isResize
          isPaging
          isVisibility
        />
      )}
    </CustomModal>
  );
};

RiFlexibilityOrChargeBackModal.propTypes = {
  toggle: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  isOpen: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  commitmentType: PropTypes.string.isRequired,
  modalIsLoading: PropTypes.bool.isRequired,
  className: PropTypes.bool,
};
export default RiFlexibilityOrChargeBackModal;
