import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import CustomModal, { MODAL_MODES } from 'shared/components/andtComponents/Modal';
import useTable from 'shared/hooks/customHooks/useTable';
import useUsers from 'users/new-user-management/hooks/reactQuery/useUsers';
import { doesUserMatchSearch, PAGINATION_DIRECTION } from 'users/containers/Organization/consts';
import AddUsersToRoleModalTable from './AddUsersToRoleModalTable';
import AddUsersToRoleModalHeader from './AddUsersToRoleModalHeader';

import styles from './AddUsersToRoleModal.module.scss';

const AddUsersToRoleModal = ({ isOpen, setIsOpen, role }) => {
  const [saveClicked, setSaveClicked] = useState(false);
  const [selectedUserIds, setSelectedUserIds] = useState([]);
  const [search, setSearch] = useState();
  const [paginationToken, setPaginationToken] = useState(null);

  const { NewTableWrapper } = useTable();

  const { fetchUsers, updateRolesAssignedToUsers } = useUsers(!isOpen);
  const { data, isLoading } = fetchUsers({ paginationToken, search });

  const displayedUsers = useMemo(() => {
    if (data?.users && !isLoading && isOpen) {
      const usersWithoutTheRole = data?.users.filter((user) => user.roles.every((r) => r.id !== role?.id));
      return search ? usersWithoutTheRole.filter((user) => doesUserMatchSearch(user, search)) : usersWithoutTheRole;
    }
  }, [data, isLoading, role, isOpen, search]);

  useEffect(() => {
    if (!saveClicked) {
      setSelectedUserIds([]);
    }
  }, [data?.users, saveClicked]);

  const pageChanged = (direction) => {
    if (direction === PAGINATION_DIRECTION.NEXT && data?.paginationToken) {
      setPaginationToken(data?.paginationToken);
    }
  };

  const onSave = async () => {
    setSaveClicked(true);
    await updateRolesAssignedToUsers.mutateAsync({
      userIds: selectedUserIds,
      roleIdsToAdd: [role.id],
    });
    setSaveClicked(false);
    setIsOpen(false);
  };

  return (
    <CustomModal
      className={styles}
      onClose={() => {
        setIsOpen(false);
      }}
      onCloseClick={() => {
        setIsOpen(false);
      }}
      headerMode={MODAL_MODES.ADD}
      footerDisabled={isLoading && saveClicked}
      onSave={onSave}
      open={isOpen}
      automationId="add-users-to-role-modal"
      saveTitle="Add"
      saveDisabled={false}
      title="Add Users"
    >
      <NewTableWrapper className={classNames(['sub-table', styles.newTableWrapper])} isCompact>
        <AddUsersToRoleModalHeader
          selectedUsersCount={selectedUserIds?.length}
          search={search}
          setSearch={setSearch}
          pageChanged={pageChanged}
          total={data?.total}
        />
        <AddUsersToRoleModalTable
          users={displayedUsers}
          setSelectedUserIds={setSelectedUserIds}
          isLoading={isLoading}
        />
      </NewTableWrapper>
    </CustomModal>
  );
};

AddUsersToRoleModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
  role: PropTypes.object,
};

export default AddUsersToRoleModal;
