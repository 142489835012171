import React from 'react';
import PropTypes from 'prop-types';
import { sortedUniqBy } from 'lodash/array';
import { GenerateIcon, ICONS } from '@pileus-cloud/anodot-frontend-common';
import { AwsCommonFields } from 'shared/constants/awsConstants';
import { FilterSelect } from './FilterSelect';
import classes from './FieldSidebarFilter.module.scss';
import LabelCoordinator from '~/shared/modules/labelCoordinator.js';

const filterOptions = (options, subMenuOpenTagKey, searchText, isLoading, field) => {
  if (subMenuOpenTagKey) {
    if (isLoading) {
      return [];
    }
    const trimLabel = (label) => {
      if (!label) {
        return '';
      }
      const keyLabel = LabelCoordinator.getDataKeyDisplayName('cueDisplayCoordinator', subMenuOpenTagKey);
      if (label.includes(keyLabel)) {
        return label.replace(`${keyLabel}: `, '');
      }
      return label?.split(': ')[1];
    };
    const filtered = options
      .filter((opt) => String(opt.value)?.startsWith(`${subMenuOpenTagKey}: `))
      .map((opt) => ({
        ...opt,
        label: trimLabel(opt.label),
      }))
      .filter((opt) => String(opt.value)?.split(': ')[1] !== 'no_tag');
    return [
      ...(field === 'accounttags' || field === AwsCommonFields.BUSINESS_MAPPING || field === AwsCommonFields.WORKLOAD
        ? []
        : [
            {
              label: 'no_tag',
              value: `${subMenuOpenTagKey}: no_tag`,
              bold: true,
            },
          ]),
      ...filtered,
    ];
  }
  return sortedUniqBy(
    options
      .map((opt) => ({
        value: (opt.value || '').split(': ')[0],
        label: (opt.label || '').split(': ')[0],
      }))
      .filter((opt) => opt.value.toLowerCase().includes((searchText || '').toLowerCase()))
      .sort((a, b) => a.value.localeCompare(b.value)),
    'value',
  );
};

const FieldWithSubMenuFilter = ({
  searchText = '',
  options = [],
  renderOption,
  subMenuOpenTagKey = null,
  subMenuOpenTagLabel = null,
  setSubMenuOpenTagKey,
  backClick,
  isLoading = false,
  field,
  isOpen = false,
  setIsOpen,
  selectedOptions = [],
  changeSelectedOptions,
  specialOptionChange,
  showKeysOfSubMenuLoading,
  showNotAllocated = true,
}) => {
  const optionsFiltered = filterOptions(options, subMenuOpenTagKey, searchText, isLoading, field);
  const fullOptions = [...options];
  if (field === AwsCommonFields.CUSTOM_TAGS && showNotAllocated) {
    fullOptions.unshift({ label: 'Not Tagged', value: 'not_tagged', specialOption: true });
  }
  return (
    <>
      {subMenuOpenTagKey ? (
        <>
          <button
            className={classes.backContainer}
            style={{ width: '100%' }}
            onClick={() => {
              backClick();
            }}
            type="button"
            id="backButton"
          >
            <span className={classes.leftSide}>
              <GenerateIcon iconName={ICONS.chevronLeft.name} />
            </span>
            <span className={classes.key}> {subMenuOpenTagLabel}</span>
          </button>
          {renderOption(optionsFiltered, 0, {})}
        </>
      ) : (
        <FilterSelect
          subMenuOpenTagKey={subMenuOpenTagKey}
          setSubMenuOpenTagKey={(item) => setSubMenuOpenTagKey(item)}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          onMenuBlur={() => setIsOpen(false)}
          limitOnBlurInId={`toggle-${field}`}
          isMulti={false}
          hasSubItems
          hideFooter
          isLoading={isLoading}
          showKeysOfSubMenuLoading={showKeysOfSubMenuLoading}
          selectedItems={selectedOptions}
          changeSelectedItems={changeSelectedOptions}
          specialOptionChange={(selectItem) =>
            field === AwsCommonFields.CUSTOM_TAGS ? specialOptionChange(selectItem) : null
          }
          options={fullOptions.filter((d) => d.value !== null)}
          searchHandler={() => {}}
        />
      )}
    </>
  );
};

FieldWithSubMenuFilter.propTypes = {
  searchText: PropTypes.string,
  field: PropTypes.string.isRequired,
  options: PropTypes.array,
  renderOption: PropTypes.func.isRequired,
  setSubMenuOpenTagKey: PropTypes.func.isRequired,
  isOpen: PropTypes.bool,
  setIsOpen: PropTypes.func.isRequired,
  subMenuOpenTagKey: PropTypes.string,
  subMenuOpenTagLabel: PropTypes.string,
  isLoading: PropTypes.bool,
  backClick: PropTypes.func.isRequired,
  specialOptionChange: PropTypes.func.isRequired,
  selectedOptions: PropTypes.array,
  showNotAllocated: PropTypes.bool,
};

export default FieldWithSubMenuFilter;
