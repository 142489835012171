import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { GenerateIcon, ICONS } from '@pileus-cloud/anodot-frontend-common';
import styles from './percentTag.module.scss';

const PercentTag = ({ percent }) => {
    const isArrowUp = percent > 0;

    return (
        <div className={classNames(styles.percentTag, isArrowUp && styles.redTag, percent === 0 && styles.grayTag)}>
            {(percent ?? 0)?.toFixed(2)}%
            {percent !== 0 && <GenerateIcon automation-id="arrow-icon" iconName={isArrowUp ? ICONS.arrowTrendUp.name : ICONS.arrowTrendDown.name }/>}
      </div>
    );
};

PercentTag.propTypes = {
    percent: PropTypes.number.isRequired,
};
export default PercentTag;
