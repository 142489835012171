import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Button, Col, Collapse, Form, Row } from 'reactstrap';
import PlusIcon from 'mdi-react/PlusIcon';
import MinusIcon from 'mdi-react/MinusIcon';
import IconInputField from 'shared/components/IconInputField';
import Spinner from 'shared/components/andtComponents/Spinner';
import AppRegistrationTable from './AppRegistrationTable';
import AlertError from '../../shared/components/Alerts/AlertError';
import EditModal from './AppRegEditModal';

class AppRegistrationConfiguration extends PureComponent {
  static propTypes = {
    usersStore: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      directoryId: '',
      applicationId: '',
      clientSecret: '',
      appName: '',
      regAppsData: [],
      isRegAppFormOpen: false,
      isSavingData: false,
      dataIsLoading: false,
      isSaveDataError: false,
      showNoInputValueWarning: false,
      isAppRegButtonDisabled: true,
      isEditModal: false,
      isValidateError: false,
      isAppIdRegisteredError: false,
      clientSecretInputType: 'password',
    };
    this.columns = [
      { name: 'appName', title: 'Application name' },
      { name: 'appId', title: 'Application ID' },
      { name: 'isVerified', title: 'Verification Status', getCellValue: (row) => row.isVerified },
      { name: 'userActions', title: ' ' },
    ];
    this.columnWidths = [
      { columnName: 'appName', width: 200 },
      { columnName: 'appId', width: 200 },
      { columnName: 'isVerified', width: 200 },
      { columnName: 'userActions', width: 200 },
    ];
  }

  async componentDidMount() {
    try {
      const { usersStore } = this.props;
      this.setState({ dataIsLoading: true });
      const regAppsData = await usersStore.getAzureRegisteredApps();
      if (regAppsData) {
        this.setState({ regAppsData, dataIsLoading: false });
      }
    } catch {
      this.setState({ dataIsLoading: false });
    }
  }

  handleAppRegistrationInputsChange = (newInput, e) => {
    if (!e) {
      return;
    }
    const currState = { ...this.state };
    const validInput = newInput.replace(/["\s]+/g, '');
    this.setState({ [e.target.name]: validInput, isSaveDataError: false });
    currState[e.target.name] = validInput;
    if (
      currState.directoryId.length > 0 &&
      currState.applicationId.length > 0 &&
      currState.clientSecret.length > 0 &&
      currState.appName.length > 0
    ) {
      this.setState({ isAppRegButtonDisabled: false });
    }
  };

  handleEditApp = (regApp) => {
    this.setState({ isEditModal: true, currentEditedApp: regApp, appName: regApp.appName });
  };
  handleUpdateAppName = (e) => {
    const validInput = e && e.target.value.replace(/["\s]+/g, '');
    this.setState({ appName: validInput });
  };
  handleSaveEditApp = async () => {
    try {
      const { usersStore } = this.props;
      const { regAppsData, appName, currentEditedApp } = this.state;
      const { accountId, id } = currentEditedApp;
      this.setState({ dataIsLoading: true });
      await usersStore.updateAppName({ accountId, id, appName });
      const updatedItemIndex = regAppsData.findIndex((item) => item.id === id);
      if (updatedItemIndex > -1) {
        regAppsData[updatedItemIndex].appName = appName;
      }
      this.setState({ regAppsData, dataIsLoading: false, isEditModal: false, currentEditedApp: {}, appName: '' });
    } catch {
      this.setState({ dataIsLoading: false, isSaveDataError: true });
    }
  };
  handleDeleteApp = async (regApp) => {
    try {
      const { accountId, id } = regApp;
      const { regAppsData } = this.state;
      const { usersStore } = this.props;
      this.setState({ dataIsLoading: true });
      await usersStore.deleteAppRegistrationConfiguration({ accountId, id });
      const deleteItemIndex = regAppsData.findIndex((item) => item.id === id);
      if (deleteItemIndex > -1) {
        regAppsData.splice(deleteItemIndex, 1);
      }
      this.setState({ regAppsData, dataIsLoading: false });
    } catch {
      this.setState({ dataIsLoading: false, isSaveDataError: true });
    }
  };
  disableEnterKeySub = (event) => {
    if (event.which === 13 /* Enter */) {
      event.preventDefault();
    }
  };

  validateForm = () => {
    let result = true;
    const { directoryId, applicationId, clientSecret, appName } = this.state;
    if (directoryId.length === 0 || applicationId.length === 0 || clientSecret.length === 0 || appName.length === 0) {
      result = false;
    }
    return result;
  };

  saveAppRegistrationConfiguration = async () => {
    const { directoryId, applicationId, clientSecret, appName, activeIndex, regAppsData } = this.state;
    const appConfiguration = { directoryId, applicationId, clientSecret, appName };
    if (!this.validateForm(activeIndex)) {
      this.setState({ showNoInputValueWarning: true });
      return;
    }
    if (regAppsData.find((regAppData) => regAppData.appId === applicationId)) {
      this.setState({ isAppIdRegisteredError: true, dataIsLoading: false });
      return;
    }
    try {
      const { usersStore } = this.props;
      this.setState({ dataIsLoading: true });
      const { accountId, id, errorMsg } = await usersStore.saveAzureOnBoardingAppRegistrationConfiguration(
        appConfiguration,
      );
      if (errorMsg) {
        this.setState({ isValidateError: true, dataIsLoading: false });
        return;
      }
      regAppsData.unshift({ appName, appId: applicationId, accountId, id });
      this.setState({
        regAppsData,
        dataIsLoading: false,
        directoryId: '',
        applicationId: '',
        clientSecret: '',
        appName: '',
        isValidateError: false,
      });
    } catch {
      this.setState({ dataIsLoading: false, isSaveDataError: true, isValidateError: false });
    }
  };

  updateAlertClosed = () => {
    this.setState({ showNoInputValueWarning: false });
  };
  updateDataErrorClosed = () => {
    this.setState({ isSaveDataError: false });
  };

  validationErrorClosed = () => {
    this.setState({ isValidateError: false });
  };
  appIdRegisteredErrorClosed = () => {
    this.setState({ isAppIdRegisteredError: false });
  };

  toggleRegAppForm = () => {
    const { isRegAppFormOpen } = this.state;
    this.setState({
      isRegAppFormOpen: !isRegAppFormOpen,
      directoryId: '',
      applicationId: '',
      clientSecret: '',
      appName: '',
    });
  };

  handleChangeClientSecretInputType = (e) => {
    e.preventDefault();
    const { clientSecretInputType } = this.state;
    const newClientSecretInputType = clientSecretInputType === 'password' ? 'text' : 'password';
    this.setState({ clientSecretInputType: newClientSecretInputType });
  };

  render() {
    const {
      directoryId,
      applicationId,
      clientSecret,
      isAppRegButtonDisabled,
      appName,
      isRegAppFormOpen,
      regAppsData,
      dataIsLoading,
      clientSecretInputType,
      isSavingData,
      showNoInputValueWarning,
      isSaveDataError,
      isValidateError,
      isAppIdRegisteredError,
      isEditModal,
      currentEditedApp,
    } = this.state;
    const disabled = isAppRegButtonDisabled;
    if (dataIsLoading) {
      return <Spinner />;
    }
    return (
      <div className="divisions__create-form-container">
        <div className="divisions__create-form-header">
          <span>
            {isRegAppFormOpen ? (
              <MinusIcon onClick={this.toggleRegAppForm} className="divisions__create-form-header-icon" />
            ) : (
              <PlusIcon onClick={this.toggleRegAppForm} className="divisions__create-form-header-icon" />
            )}
          </span>
          <span className="divisions__create-form-header-text">Register a New Application</span>
        </div>
        <Collapse isOpen={isRegAppFormOpen}>
          <Row>
            {isSavingData && <Spinner />}
            <AlertError
              text="Please fill in the below fields to proceed"
              withCloseIcon
              toShow={showNoInputValueWarning}
              updateAlertClosed={this.updateAlertClosed}
            />
            <AlertError
              text="There was an error, please try again later"
              withCloseIcon
              toShow={isSaveDataError}
              updateAlertClosed={this.updateDataErrorClosed}
            />
            <AlertError
              text="There was an error, please check your app credentials and try again"
              withCloseIcon
              toShow={isValidateError}
              updateAlertClosed={this.validationErrorClosed}
            />
            <AlertError
              text="This application ID is already registered, please provide a new application ID to proceed"
              withCloseIcon
              toShow={isAppIdRegisteredError}
              updateAlertClosed={this.appIdRegisteredErrorClosed}
            />
            <Col xs={12} md={12} lg={12} xl={12}>
              <p>
                You can find more information in the link below <br />{' '}
                <a
                  rel="noopener noreferrer"
                  target="_blank"
                  href="https://cloudcost.anodot.com/hc/en-us/articles/17715327015324-Connect-Azure-Applications"
                >
                  Grant access to Azure application{' '}
                </a>
              </p>
              <br />
            </Col>
            <Col xs={12} md={12} lg={8} xl={8}>
              <Form onKeyPress={this.disableEnterKeySub} className="form">
                <div className="form__form-group">
                  <IconInputField
                    icon="lnr lnr-database"
                    handler={this.handleAppRegistrationInputsChange}
                    label="Directory ID"
                    name="directoryId"
                    value={directoryId}
                    type="text"
                    placeHolder="Enter your Directory Id"
                  />
                </div>
                <div className="form__form-group">
                  <IconInputField
                    icon="lnr lnr-cloud"
                    handler={this.handleAppRegistrationInputsChange}
                    label="Application ID"
                    name="applicationId"
                    value={applicationId}
                    type="text"
                    placeHolder="Enter your Application ID"
                  />
                </div>
                <div className="form__form-group">
                  <IconInputField
                    icon="lnr lnr-cloud"
                    handler={this.handleAppRegistrationInputsChange}
                    label="Client Secret"
                    name="clientSecret"
                    value={clientSecret}
                    type={clientSecretInputType}
                    placeHolder="Enter your Client Secret"
                    handleChangeInputType={this.handleChangeClientSecretInputType}
                    isPassword
                  />
                </div>
                <div className="form__form-group">
                  <IconInputField
                    icon="lnr lnr-cloud"
                    handler={this.handleAppRegistrationInputsChange}
                    label="Application Name"
                    name="appName"
                    value={appName}
                    type="text"
                    placeHolder="You can choose a name for this APP"
                  />
                </div>
              </Form>
            </Col>
          </Row>
          <Row style={{ marginTop: '20px' }}>
            <Col style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
              <div>
                <Button
                  disabled={disabled}
                  style={{ margin: '0 10px' }}
                  color="primary"
                  onClick={this.saveAppRegistrationConfiguration}
                >
                  Save
                </Button>
              </div>
            </Col>
          </Row>
        </Collapse>
        <br />
        <div>
          {regAppsData.length ? (
            <AppRegistrationTable
              regAppsData={regAppsData}
              tableColumns={this.columns}
              columnWidths={this.columnWidths}
              editApp={this.handleEditApp}
              deleteApp={this.handleDeleteApp}
            />
          ) : null}
        </div>
        <EditModal
          open={isEditModal}
          onClose={() => this.setState({ isEditModal: false })}
          saveEditApp={this.handleSaveEditApp}
          appName={appName}
          isLoading={dataIsLoading}
          updateAppName={this.handleUpdateAppName}
          selectedReg={currentEditedApp}
        />
      </div>
    );
  }
}

export default AppRegistrationConfiguration;
