import { useMutation, useQuery } from '@tanstack/react-query';
import apiConstants from 'shared/api/apiConstants';
import { API } from 'shared/utils/apiMiddleware';
import { getQueryParams } from 'shared/utils/helpers.ts';
import { AccountFeaturesT } from '../types.ts';

const root = '/api/v1/admin/features';
const fetchFeaturesList = (): Promise<{
  featureName: string;
}> => API.get('billings', root);
const fetchDefaultFeatures = (): Promise<Omit<AccountFeaturesT, 'changedFeatures'>> =>
  API.get('billings', `${root}/default-features`);
const updateAccountsFeatures = (features: Pick<AccountFeaturesT, 'accountId' | 'features'>[]) =>
  API.post('billings', `${root}/update`, { body: { features } });
export const useFetchFeatures = () => ({
  getFeaturesList: () => useQuery([apiConstants.QUERY_KEYS.FEATURE_FLAGS_LIST], fetchFeaturesList),
  getDefaultFeatures: () => useQuery([apiConstants.QUERY_KEYS.DEFAULT_FEATURES], fetchDefaultFeatures),
  getAccountsFeatures: (accountsIds: string[], companiesIds: string[], options = { enabled: false }) =>
    useQuery(
      [apiConstants.QUERY_KEYS.ACCOUNTS_FEATURES],
      (): Promise<Omit<AccountFeaturesT, 'changedFeatures'>[]> =>
        API.get(
          'billings',
          `${root}/accounts-features?${getQueryParams({
            accountsIds,
            companiesIds,
          })}`,
        ),
      options,
    ),
  saveAccountsFeatures: () => useMutation([apiConstants.QUERY_KEYS.UPDATE_FEATURES], updateAccountsFeatures),
});
