import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Paper from '@mui/material/Paper';
import {
  DataTypeProvider,
  FilteringState,
  IntegratedFiltering,
  IntegratedPaging,
  IntegratedSorting,
  PagingState,
  SortingState,
} from '@devexpress/dx-react-grid';
import {
  ColumnChooser,
  Grid,
  PagingPanel,
  TableColumnResizing,
  TableColumnVisibility,
  TableFilterRow,
  TableHeaderRow,
  Toolbar,
} from '@devexpress/dx-react-grid-material-ui';
import { Col, Row } from 'reactstrap';
import Delete from 'mdi-react/TrashCanOutlineIcon';
import PencilIcon from 'mdi-react/PencilIcon';
import SendIcon from 'mdi-react/SendIcon';
import Eye from 'mdi-react/EyeIcon';
import LinkIconButton from 'shared/components/buttons/LinkIconButton';
import { Routes } from 'shared/constants/routes';
import { kFormatter } from 'shared/utils/strUtil';
import { RdsIsActiveValues } from 'usage/constants/usageConstants';
import { Action, HierarchicalEntityCategory } from '@anodot-cost/rbac-client';
import { USER_SAVED_REPORT_TYPES, TEST_MODAL_TYPE } from 'usage/constants/costAndUsageConstants';
import ReadOnlyDisplayWrapper from 'shared/components/ReadOnlyDisplayWrapper';
import TableWrapper from 'shared/components/tables/TableWrapper';
import toast from 'shared/components/andtComponents/Toast';
import ReportModal from 'shared/components/reportModal/ReportModal';
import { SuccessNotificationsLabels } from 'shared/constants/notificationsConstants';
import { withUserSettingsConsumer } from 'users/utils/contexts/UserSettingsContext';
import ShareEntityModal from '~/shared/components/ShareEntityModal/ShareEntityModal.jsx';
import { GenerateIcon, ICONS } from '@pileus-cloud/anodot-frontend-common';

const buttonContainerStyle = {
  display: 'flex',
  flexFlow: 'row',
  alignSelf: 'center',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  height: '100%',
};

const linkStyle = {
  display: 'inline-block',
  position: 'relative',
  zIndex: 1,
  padding: '1em',
  margin: '-1em',
};

class ReportsTable extends React.Component {
  static propTypes = {
    rows: PropTypes.object.isRequired,
    columns: PropTypes.object.isRequired,
    columnWidths: PropTypes.object.isRequired,
    tableColumnExtensions: PropTypes.object.isRequired,
    defaultHiddenColumnNames: PropTypes.object.isRequired,
    defaultSorting: PropTypes.object.isRequired,
    currenciesColumns: PropTypes.object.isRequired,
    deleteHandler: PropTypes.func.isRequired,
    sendHandler: PropTypes.func.isRequired,
    editHandler: PropTypes.func.isRequired,
    usersStore: PropTypes.object.isRequired,
    getCurrencyNumber: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    const { columns, columnWidths, tableColumnExtensions, defaultHiddenColumnNames, currenciesColumns } = this.props;
    this.state = {
      columns,
      columnWidths,
      tableColumnExtensions,
      hiddenColumnNames: defaultHiddenColumnNames,
      currenciesColumns,
      sendReportModalOpenReport: false,
      shareSettingsModalOpen: false,
      pageSizes: [5, 10, 20],
      filteringStateColumnExtensions: [{ columnName: 'userAction', filteringEnabled: false }],
    };
  }

  userActionsFormatter = (data) => {
    const { usersStore } = this.props;
    const renderGoToLink = () => {
      if (data.row.reportType === USER_SAVED_REPORT_TYPES.CUE) {
        return (
          <Link
            linkStyle={linkStyle}
            key={data.row.id}
            to={{
              pathname: `${Routes.COST_USAGE_EXPLORER}`,
            }}
            state={{
              name: data.row.name,
              id: data.row.id,
              isFromReports: true,
            }}
          />
        );
      }
      if (data.row.reportType === USER_SAVED_REPORT_TYPES.CUSTOM_DASHBOARD) {
        return (
          <Link
            linkStyle={linkStyle}
            key={data.row.id}
            to={{
              pathname: `${Routes.CUSTOM_DASHBOARD}/${data.row.sourceKey}`,
              state: {
                id: data.row.sourceKey,
              },
            }}
          />
        );
      }
      if (data.row.reportType === USER_SAVED_REPORT_TYPES.ASSET) {
        return (
          <Link
            linkStyle={linkStyle}
            key={data.row.id}
            to={{
              pathname: data.row.isK8S ? Routes.K8S_ASSETS : Routes.ASSETS,
            }}
            state={{
              id: data.row.id,
              isFromReports: true,
              name: data.row.name,
            }}
          />
        );
      }
      return null;
    };
    return (
      <i>
        <Row>
          <Col>
            <ReadOnlyDisplayWrapper isHide={false} category={HierarchicalEntityCategory.Reports} action={Action.Update}>
              <LinkIconButton containerStyle={buttonContainerStyle}>
                <a href="#" onClick={(e) => this.props.deleteHandler(e, data.row.id)} />
                <Delete />
              </LinkIconButton>
            </ReadOnlyDisplayWrapper>
          </Col>
          <Col>
            <ReadOnlyDisplayWrapper isHide={false} category={HierarchicalEntityCategory.Reports} action={Action.Update}>
              <LinkIconButton containerStyle={buttonContainerStyle}>
                <a href="/" onClick={(e) => this.props.editHandler(e, data.row)} />
                <PencilIcon />
              </LinkIconButton>
            </ReadOnlyDisplayWrapper>
          </Col>
          <Col>
            <LinkIconButton containerStyle={buttonContainerStyle}>
              {renderGoToLink()}
              <Eye />
            </LinkIconButton>
          </Col>
          <Col>
            <ReadOnlyDisplayWrapper isHide={false} category={HierarchicalEntityCategory.Reports} action={Action.Update}>
              <LinkIconButton containerStyle={buttonContainerStyle}>
                <button
                  type="button"
                  style={{
                    ...linkStyle,
                    color: '#70bbfd',
                  }}
                  className="btn-no-style"
                  onClick={() => {
                    this.handleOpenSendReportModal(data.row);
                  }}
                />
                <SendIcon />
              </LinkIconButton>
            </ReadOnlyDisplayWrapper>
          </Col>
          {usersStore.rootStore.appStore.isKeyCloakManagement && (
            <Col>
              <LinkIconButton containerStyle={buttonContainerStyle}>
                <button
                  type="button"
                  style={{
                    ...linkStyle,
                    color: '#70bbfd',
                  }}
                  className="btn-no-style"
                  onClick={() => {
                    this.setShareSettingsModalOpen(data.row);
                  }}
                />
                <GenerateIcon style={{ height: 24 }} iconName={ICONS.share.name} />
              </LinkIconButton>
            </Col>
          )}
        </Row>
      </i>
    );
  };

  currenciesFormatter = (value) => this.props.getCurrencyNumber(kFormatter(value.value));

  changeColumnWidths = (columnWidths) => {
    this.setState({ columnWidths });
  };

  hiddenColumnNamesChange = (hiddenColumnNames) => {
    this.setState({ hiddenColumnNames });
  };

  handleOpenSendReportModal = (report) => {
    this.setState(() => ({
      sendReportModalOpenReport: report || null,
    }));
  };

  handleClosetModal = () => {
    this.setState(() => ({
      sendReportModalOpenReport: null,
    }));
  };

  setShareSettingsModalOpen = (isOpen) => {
    this.setState({ shareSettingsModalOpen: isOpen });
  };

  render() {
    const {
      columns,
      columnWidths,
      tableColumnExtensions,
      hiddenColumnNames,
      currenciesColumns,
      sendReportModalOpenReport,
      filteringStateColumnExtensions,
      pageSizes,
      shareSettingsModalOpen,
    } = this.state;
    const { rows, sendHandler, usersStore, defaultSorting } = this.props;
    const csvModifiedRows = JSON.parse(JSON.stringify(rows));
    csvModifiedRows.map((row) => {
      if (row.isActive === RdsIsActiveValues.NOT_ACTIVE) {
        row.isActive = 'No';
        return row;
      }

      row.isActive = 'Yes';
      return row;
    });

    return (
      <Row style={{ width: '100%' }} ref={this.refCallback}>
        <ReportModal
          onClose={this.handleClosetModal}
          modalType={TEST_MODAL_TYPE}
          isOpen={!!sendReportModalOpenReport}
          onSave={(report) => {
            sendHandler(report);
            toast.success(SuccessNotificationsLabels.REPORT_SENT);
            this.handleClosetModal();
          }}
          usersStore={usersStore}
          title="Send Report"
          data={sendReportModalOpenReport}
        />
        {shareSettingsModalOpen && usersStore.rootStore.appStore.isKeyCloakManagement ? (
          <ShareEntityModal
            entity={HierarchicalEntityCategory.Reports}
            entityId={shareSettingsModalOpen.uuid}
            onClose={() => this.setShareSettingsModalOpen(false)}
          />
        ) : null}
        <Col xs={12} md={12} lg={12} xl={12}>
          <Paper>
            <Grid rows={rows} columns={columns}>
              <SortingState defaultSorting={defaultSorting} />
              <FilteringState defaultFilters={[]} columnExtensions={filteringStateColumnExtensions} />
              <IntegratedSorting />
              <IntegratedFiltering />
              <PagingState defaultCurrentPage={0} defaultPageSize={pageSizes[3]} />
              <IntegratedPaging />
              {currenciesColumns && (
                <DataTypeProvider for={currenciesColumns} formatterComponent={this.currenciesFormatter} />
              )}
              <DataTypeProvider for={['userAction']} formatterComponent={this.userActionsFormatter} />
              <TableWrapper columnExtensions={tableColumnExtensions} />
              <TableColumnResizing columnWidths={columnWidths} onColumnWidthsChange={this.changeColumnWidths} />
              <TableHeaderRow showSortingControls />
              <TableFilterRow showFilterSelector />
              <PagingPanel pageSizes={pageSizes} />
              <TableColumnVisibility
                hiddenColumnNames={hiddenColumnNames}
                onHiddenColumnNamesChange={this.hiddenColumnNamesChange}
              />
              <Toolbar />
              <ColumnChooser />
            </Grid>
          </Paper>
        </Col>
      </Row>
    );
  }
}

const ObserverReportsTable = withUserSettingsConsumer(ReportsTable);
export default ObserverReportsTable;
