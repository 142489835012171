import { Link } from 'react-router-dom';
import React from 'react';
import PropTypes from 'prop-types';
import { prepareLinkAccountLabel } from 'recommendations/containers/Dashboard/helpers/dataPrepareHelpers';
import LabelCoordinator from 'shared/modules/labelCoordinator';
import { CLOUD_TYPE_IDS, TABS_IDS } from 'users/constants/usersConstants';
import { Routes } from 'shared/constants/routes';
import { formatDateTime } from 'shared/utils/dateUtil';
import { NOTIFICATIONS_TYPES } from './notificationsConstants';

const NotificationsDescriptions = ({ type, details, cloudType, isCurrentUserReseller }) => {
  switch (type) {
    case NOTIFICATIONS_TYPES.LINKED_ACCOUNT_NOT_ASSOCIATED_WITH_DIVISION:
      return (
        <>
          The {prepareLinkAccountLabel(cloudType, true)} isn’t associated with any of your{' '}
          {LabelCoordinator.getFieldLabel('Cost Center')}. To associate it with a{' '}
          {LabelCoordinator.getFieldLabel('Cost Center')}, click{' '}
          {isCurrentUserReseller ? (
            <Link
              to={{
                pathname: Routes.CUSTOMERS_MANAGE,
              }}
            >
              here
            </Link>
          ) : (
            <Link
              to={{
                pathname: Routes.ACCOUNT,
                state: {
                  defaultTabId: TABS_IDS.COST_CENTER,
                },
              }}
            >
              here
            </Link>
          )}
          .
        </>
      );
    case NOTIFICATIONS_TYPES.NEW_LINKED_ACCOUNT:
      return `A new ${prepareLinkAccountLabel(cloudType, true)} has been
     added to the account on the ${formatDateTime(details.date)}.`;
    case NOTIFICATIONS_TYPES.NOT_CONNECTED_LINKED_ACCOUNT:
      return (
        <>
          {details.count} {prepareLinkAccountLabel(cloudType, false)} are not connected. To connect, click{' '}
          <Link
            to={{
              pathname: Routes.ACCOUNT,
              state: {
                defaultTabId: cloudType === CLOUD_TYPE_IDS.AWS ? TABS_IDS.LINKED_ACCOUNTS : TABS_IDS.SUBSCRIPTIONS,
              },
            }}
          >
            here
          </Link>{' '}
          or check your policy.
        </>
      );
    case NOTIFICATIONS_TYPES.AZURE_APP_REGISTRATION_EXPIRES:
      return `Please note that the Azure API key for the 
    Azure account: ${details.accountId}  is about to expire within a month on the ${formatDateTime(details.date)}.`;
    case NOTIFICATIONS_TYPES.ONBOARDING_SUCCEED:
      return <>We`ve finished processing your account data You can now start monitoring and optimizing your cost.</>;
    case NOTIFICATIONS_TYPES.ONBOARDING_FAILED:
      return (
        <>
          The Account has failed to be validated. To view the failure reason please click{' '}
          <Link
            to={{
              pathname: Routes.ACCOUNT,
              state: {
                defaultTabId: TABS_IDS.CLOUD_ACCOUNTS,
              },
            }}
          >
            here
          </Link>{' '}
          and open the onboarded account.
        </>
      );
    default:
      return null;
  }
};

NotificationsDescriptions.propTypes = {
  type: PropTypes.string.isRequired,
  details: PropTypes.object.isRequired,
  cloudType: PropTypes.number.isRequired,
  isCurrentUserReseller: PropTypes.bool.isRequired,
};

export default NotificationsDescriptions;
