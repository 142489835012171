import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import {
  GenerateIcon,
  ICONS,
  Popover,
  PopoverContent,
  PopoverPortal,
  PopoverTrigger,
} from '@pileus-cloud/anodot-frontend-common';
import Button from 'shared/components/andtComponents/Button';
import { useRecommendationsNewContext } from 'recommendationsNew/contexts/recommendationsNewContext';
import useRecViews from 'recommendationsNew/hooks/react-query/useRecViews';
import { calculateRange, CLOSED_AND_DONE_RECS_DATES_FILTERS, FILTERS, VIEWS_TYPE } from 'recommendationsNew/consts';
import styles from './viewActions.module.scss';
import { useHeatMapContext } from '../contexts/heatMapContext';

const saveIcon = () => <GenerateIcon iconName={ICONS.floppyDisk.name} className={styles.iconPadding} />;
const renameIcon = () => <GenerateIcon iconName={ICONS.penToSquare.name} className={styles.iconPadding} />;
const defaultIcon = () => <GenerateIcon iconName={ICONS.badgeCheck.name} className={styles.iconPadding} />;
const duplicateIcon = () => <GenerateIcon iconName={ICONS.copy.name} className={styles.iconPadding} />;
const copyUrlIcon = () => <GenerateIcon iconName={ICONS.bracketsCurly.name} className={styles.iconPadding} />;
const deleteIcon = () => <GenerateIcon iconName={ICONS.deleteRegular.name} className={styles.iconPadding} />;
export const ViewActions = ({ view, changeViewName, setIsOpen, viewType }) => {
  const { useDeleteViewPanel, useUpdateViewPanel, useCreateViewPanel } = useRecViews();
  const { recommendationFilters, showDoneCompletedOnly } = useRecommendationsNewContext();
  const { isWasteChart, heatMapGroupByOptions } = useHeatMapContext();
  const { mutate: deleteView } = useDeleteViewPanel();
  const { mutate: updateView } = useUpdateViewPanel();
  const { mutate: createView } = useCreateViewPanel();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();

  const closeMenus = () => {
    setIsMenuOpen(false);
    setIsOpen(false);
  };

  const toggleOpen = (e) => {
    e.stopPropagation();
  };

  const getDateRange = () => {
    let dateRange = calculateRange(
      showDoneCompletedOnly
        ? recommendationFilters?.[FILTERS.CLOSED_AND_DONE_RECS_DATES.id]?.[
            CLOSED_AND_DONE_RECS_DATES_FILTERS.LAST_UPDATE_DATE
          ]
        : recommendationFilters?.[FILTERS.OPEN_RECS_CREATION_DATE.id],
    );
    if (
      showDoneCompletedOnly &&
      recommendationFilters?.[FILTERS.CLOSED_AND_DONE_RECS_DATES.id]?.[CLOSED_AND_DONE_RECS_DATES_FILTERS.CREATION_DATE]
    ) {
      dateRange = {
        ...dateRange,
        creationDate: calculateRange(
          recommendationFilters?.[FILTERS.CLOSED_AND_DONE_RECS_DATES.id]?.[
            CLOSED_AND_DONE_RECS_DATES_FILTERS.CREATION_DATE
          ],
        ),
      };
    }
    return { ...dateRange, isCloseDate: showDoneCompletedOnly };
  };
  return (
    <div className={styles.actionWrapper} onClick={(e) => toggleOpen(e)}>
      <Popover open={isMenuOpen} onOpenChange={() => setIsMenuOpen(!isMenuOpen)}>
        <PopoverTrigger as="button" type="button">
          <GenerateIcon iconName={ICONS.verticalDots.name} />
        </PopoverTrigger>

        <PopoverPortal>
          <PopoverContent sideOffset={10} side="bottom" className={styles.actionPopoverContent}>
            <Button
              isTextButton
              text="Save changes"
              automationId="save-view-filters"
              className={{ button: styles.menuItem }}
              icon={saveIcon}
              onClick={() => {
                updateView({
                  ...view,
                  context_data: {
                    recommendationFilters: { ...recommendationFilters, dateRange: getDateRange() },
                    isWasteChart,
                    heatMapGroupByOptions,
                  },
                  viewType,
                });
                closeMenus();
              }}
            />
            <Button
              isTextButton
              text="Rename"
              automationId="rename-view"
              className={{ button: styles.menuItem }}
              icon={renameIcon}
              onClick={() => {
                changeViewName({ id: view.id, name: view.view_name });
                setIsMenuOpen(false);
              }}
            />
            <Button
              isTextButton
              text={view.is_default ? 'Set Main View as default' : 'Set as Default View'}
              automationId="set-default-view"
              className={{ button: styles.menuItem }}
              icon={defaultIcon}
              onClick={() => {
                updateView({ ...view, is_default: !view.is_default, viewType });
                closeMenus();
              }}
              disabled={viewType !== VIEWS_TYPE.Organization}
            />
            <Button
              isTextButton
              text="Duplicate"
              automationId="duplicate-view"
              className={{ button: styles.menuItem }}
              icon={duplicateIcon}
              onClick={() => {
                createView({
                  viewName: `Copy of ${view.view_name}`,
                  contextData: {
                    recommendationFilters: { ...recommendationFilters, dateRange: getDateRange() },
                    isWasteChart,
                    heatMapGroupByOptions,
                  },
                  isDefault: false,
                  viewType,
                });
                closeMenus();
              }}
            />
            <Button
              isTextButton
              text="Copy this view URL"
              automationId="copy-view-url"
              className={{ button: `${styles.menuItem} ${styles.paddingIcon}` }}
              icon={copyUrlIcon}
              onClick={() => {
                navigator.clipboard.writeText(window.location.origin + location.pathname + location.search);
                closeMenus();
              }}
            />
            <Button
              isTextButton
              text="Delete"
              automationId="delete-view"
              className={{ button: styles.menuItem }}
              icon={deleteIcon}
              onClick={() => {
                deleteView(view.id);
                navigate(location.pathname, { replace: true });
                closeMenus();
              }}
            />
          </PopoverContent>
        </PopoverPortal>
      </Popover>
    </div>
  );
};
