import React, { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import capitalize from 'lodash/capitalize';
import CustomModal from 'shared/components/andtComponents/Modal';
import Input from 'shared/components/andtComponents/Input';
import DatePickerFilter from 'shared/components/DatePickerFilter';
import {
  AMORTIZE_COST_TYPES_ITEMS,
  AZURE_FOCUS_COST_TYPES_ITEMS,
  CostTypeModes,
  mapGranLevelToDisplayValue,
} from 'usage/constants/costAndUsageConstants';
import { CLOUD_TYPE_IDS } from 'users/constants/usersConstants';
import { ReactComponent as TrashIcon } from 'shared/img/icons/trash.svg';
import { ReactComponent as InfoIcon } from 'shared/img/icons/info.svg';
import Chip, { CHIP_COLORS as CHIP_COLOR } from 'shared/components/andtComponents/Chip';
import LabelCoordinator from 'shared/modules/labelCoordinator';
import Tooltip from 'shared/components/andtComponents/Tooltip';
import { useCUEGoals } from 'users/hooks/react-query/useCUEGoals';
import { useVirtualTags } from 'usage/hooks/react-query/useVirtualTags';
import { GCPCommonFieldLabels } from 'shared/constants/gcpConstants';
import styles from './SaveGoalModal.module.scss';

const SaveGoalModal = ({
  cloudType,
  granularity,
  cueStartDate,
  cueEndDate,
  item = null,
  onClose,
  avgTarget,
  usageSelected,
  costSelected,
  costTypeMode,
  explorerState,
  onGoalSaved,
}) => {
  const { deleteCUEGoal, addCUEGoal, updateCUEGoal } = useCUEGoals();
  const { mutateAsync: handleDelete } = deleteCUEGoal();
  const { mutateAsync: handleAdd } = addCUEGoal();
  const { mutateAsync: handleUpdate } = updateCUEGoal();
  const editMode = !!item;
  const [title, setTitle] = useState(editMode ? item.title : '');
  const [description, setDescription] = useState(editMode ? item.description : '');
  const [target, setTarget] = useState(editMode ? item.target : '');
  const [startDate, setStartDate] = useState(editMode ? item.startDate : cueStartDate);
  const [endDate, setEndDate] = useState(editMode ? item.endDate : cueEndDate);
  const { fetchVirtualTags } = useVirtualTags();
  const { data: virtualTags } = fetchVirtualTags();
  const handleDateChange = ({ startDate: start, endDate: end }) => {
    const startFormatted = moment(start || startDate).format('YYYY-MM-DD');
    const endFormatted = moment(end || endDate).format('YYYY-MM-DD');
    setStartDate(startFormatted);
    setEndDate(endFormatted > startFormatted ? endFormatted : startFormatted);
  };
  const costSelectedLabel =
    (
      [...Object.values(AMORTIZE_COST_TYPES_ITEMS), ...Object.values(AZURE_FOCUS_COST_TYPES_ITEMS)].find(
        (costType) => costType.value === costSelected,
      ) || {}
    ).name || 'Cost';
  const currentMetric = {
    [CostTypeModes.COST]: costSelectedLabel.toLowerCase(),
    [CostTypeModes.USAGE]: usageSelected.toLowerCase(),
    [CostTypeModes.RATE]: 'Rate',
  }[costTypeMode];

  const formatLabel = (field) => {
    let label = '';
    if (cloudType === CLOUD_TYPE_IDS.GCP) {
      label = GCPCommonFieldLabels.get(field);
    }
    if (!label) {
      label = LabelCoordinator.getFieldLabel(field);
    }
    return label;
  };
  const renderStateDescription = () => {
    const renderFilterInfo = (key, values, isExclude, isLike) => {
      const renderNumberInsteadOfValues = values.length > 1;
      const valuesFormatted =
        key === 'virtualcustomtags'
          ? values.map((f) => {
              const vt = virtualTags?.find((v) => v.uuid === f);
              return (vt || {}).name || f;
            })
          : values;
      const label = renderNumberInsteadOfValues ? values.length : valuesFormatted.join(', ');
      return (
        <Chip
          key={key}
          label={`${LabelCoordinator.getFieldLabel(key)}${isLike ? ` contains(${label})` : `: ${label}`}`}
          color={isExclude ? CHIP_COLOR.RED : CHIP_COLOR.BLUE}
        />
      );
    };
    return (
      <div>
        <Chip
          color={CHIP_COLOR.DEFAULT}
          label={`Type: ${
            costTypeMode !== CostTypeModes.RATE ? capitalize(costTypeMode.toLowerCase()) : ''
          } ${currentMetric}`}
        />
        <Chip color={CHIP_COLOR.BLUE} label={`Group By: ${formatLabel(explorerState.groupBy)}`} />
        {Object.keys(explorerState.filters).map((key) =>
          renderFilterInfo(
            key,
            explorerState.filters[key],
            !!explorerState.excludeFilters[key],
            !!(explorerState.likeFilters || {})[key],
          ),
        )}
      </div>
    );
  };
  return (
    <CustomModal
      title={editMode ? 'Edit Goal' : 'Create Goal'}
      open
      onClose={onClose}
      overrideStyles={{ width: 560 }}
      saveDisabled={!title || !target}
      menuContent={
        editMode && (
          <>
            <li
              onClick={() => {
                handleDelete({ id: item.uuid });
                onGoalSaved(null);
                onClose();
              }}
            >
              <span>
                <TrashIcon />
                Delete goal
              </span>
            </li>
          </>
        )
      }
      onSave={async () => {
        const goal = {
          startDate: startDate.split('T')[0],
          endDate: endDate.split('T')[0],
          title,
          description,
          target,
          granularity,
          explorerState,
        };
        const newGoal = await (editMode ? handleUpdate({ id: item.uuid, data: goal }) : handleAdd({ data: goal }));
        onGoalSaved(newGoal.uuid);
      }}
    >
      <label className={styles.input}>
        <span>Goal details</span>
        {renderStateDescription()}
      </label>
      <label htmlFor="title" className={styles.input}>
        <span>Goal name*</span>
        <Input
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          name="title"
          type="text"
          automationId="goal-name"
        />
      </label>
      <label htmlFor="description" className={styles.input} data-long>
        <span>Goal description</span>
        <Input
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          name="description"
          type="text"
          automationId="goal-description"
        />
      </label>
      <div className={styles.twoCols}>
        <label htmlFor="target" className={styles.input}>
          <span>Goal ({currentMetric})*</span>
          <Input
            value={target}
            onChange={(e) => setTarget(e.target.value)}
            name="target"
            type="number"
            automationId="goal-value"
          />
        </label>
        <label htmlFor="avgTarget" className={styles.input} style={{ width: 170 }}>
          <span>
            Avg. current ({currentMetric})
            <Tooltip
              title={
                <span>
                  For the time period <br /> presented in graph
                </span>
              }
            >
              <span>
                <InfoIcon />
              </span>
            </Tooltip>
          </span>
          <Input value={avgTarget} disabled name="avgTarget" type="text" automationId="goal-avarage" />
        </label>
      </div>
      <div className={styles.twoCols}>
        <label htmlFor="gran" className={styles.input}>
          <span>
            Time Granularity
            <Tooltip title="Bounded to current granularity display">
              <span>
                <InfoIcon />
              </span>
            </Tooltip>
          </span>
          <Input
            value={mapGranLevelToDisplayValue.get(granularity)}
            disabled
            name="gran"
            type="text"
            automationId="goal-granularity"
          />
        </label>
        <div className={styles.input}>
          <span>Time range*</span>
          <DatePickerFilter
            onDateChange={handleDateChange}
            startDate={startDate}
            endDate={endDate}
            currPeriodGranLevel={granularity}
            isDateRangeError={false}
            maxDate={new Date(new Date().setFullYear(new Date().getFullYear() + 3))}
            andtLook
          />
        </div>
      </div>
    </CustomModal>
  );
};

SaveGoalModal.propTypes = {
  cloudType: PropTypes.string.isRequired,
  granularity: PropTypes.string.isRequired,
  cueStartDate: PropTypes.string.isRequired,
  cueEndDate: PropTypes.string.isRequired,
  item: PropTypes.object,
  onClose: PropTypes.func.isRequired,
  avgTarget: PropTypes.number.isRequired,
  usageSelected: PropTypes.string.isRequired,
  costSelected: PropTypes.string.isRequired,
  costTypeMode: PropTypes.string.isRequired,
  explorerState: PropTypes.object.isRequired,
  onGoalSaved: PropTypes.func.isRequired,
};

export default SaveGoalModal;
