import { ONBOARDING_FIELDS } from '../utils/OnboardingConstants';

export const AWS_ONBOARDING_STEPS = {
  ACCOUNT_TYPE: 'ACCOUNT_TYPE',
  CREATE_CUR_FILE: 'CREATE_CUR_FILE',
  AWS_DETAILS: 'AWS_DETAILS',
  GRANT_ACCESS: 'GRANT_ACCESS',
  VALIDATE_ACCESS: 'VALIDATE_ACCESS',
  BILLING_PROFILE: 'BILLING_PROFILE',
  LINKED_ACCOUNTS: 'LINKED_ACCOUNTS',
  PROCESS_DATA: 'PROCESS_DATA',
};

export const AWS_ONBOARDING_STEPS_SHORT_TITLES = {
  CREATE_CUR_FILE: 'Create CUR file',
  AWS_DETAILS: 'AWS Details',
  GRANT_ACCESS: 'Grant Access',
  VALIDATE_ACCESS: 'Validate Access',
  BILLING_PROFILE: 'Billing Profile',
  LINKED_ACCOUNTS: 'Linked Account Status',
  PROCESS_DATA: 'Process Data',
};

export const AWS_ONBOARDING_FIELDS = {
  ...ONBOARDING_FIELDS,
  AUTO_SETUP_LINKED_ACCOUNTS: 'autoSetupLinkedAccounts',
  ROLE_ARN: 'roleARN',
  GRANT_ACCESS_REMINDER: 'grantAccessReminder',
  FILE_UPLOADED: 'fileUploaded',
  BUCKET_NAME: 's3BucketName',
  BUCKET_REGION: 's3BucketRegion',
  EXTERNAL_ID: 'externalId',
  BILLING_PROFILE: 'billingProfile',
};

export const AWS_ONBOARDING_STEP_TO_FIELDS = {
  [AWS_ONBOARDING_STEPS.AWS_DETAILS]: [
    AWS_ONBOARDING_FIELDS.ACCOUNT_NAME,
    AWS_ONBOARDING_FIELDS.ACCOUNT_ID,
    AWS_ONBOARDING_FIELDS.BUCKET_NAME,
    AWS_ONBOARDING_FIELDS.BUCKET_REGION,
  ],
  [AWS_ONBOARDING_STEPS.ACCOUNT_TYPE]: [AWS_ONBOARDING_FIELDS.RESELLER_CUSTOMER],
  [AWS_ONBOARDING_STEPS.GRANT_ACCESS]: [AWS_ONBOARDING_FIELDS.ROLE_ARN],
  [AWS_ONBOARDING_STEPS.VALIDATE_ACCESS]: [AWS_ONBOARDING_FIELDS.FILE_UPLOADED],
};
