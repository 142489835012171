import React from 'react';
import FilterField from 'app/containers/MainDashboard/components/FilterField.jsx';
import {useRootStore} from 'app/contexts/RootStoreContext.jsx';
import { getIncludeOptions} from 'app/containers/MainDashboard/utils.js';
import {useMainDashboardContext} from 'app/containers/MainDashboard/contexts/mainDashboardContext.jsx';
import {getCostOptions} from 'usage/containers/CostAndUsageExplorer/helpers/customDashboardHelperMethods.js';
import {getCostTypeOptions} from 'shared/components/FilterSidebar/filterUtil.js';
import styles from './mainDashboardHeader.module.scss';

const filtersFields  = {
    costType: {label: 'Cost Type', id: 'costType'},
    cost: {label: 'Cost', id: 'cost'},
    include: {label: 'Include', id: 'include'}
}
const MainDashboardFilters = () => {
    const { usersStore } = useRootStore();
    const { filters, setFilters  } = useMainDashboardContext();
    const includeOptions = getIncludeOptions(usersStore);
    const costOptions = getCostOptions(usersStore, true);
    return (
      <div className={styles.filterWrapper}>
        <FilterField filterType={filtersFields.costType}
                     filterDataLength={0}
                     isMulti
                     onSelectMultiButtonClick={() => {}}
                     selectedOptions={filters.costType || []}
                     handleSelectionChange={(options) => setFilters({...filters, costType: options})}
                     options={getCostTypeOptions(false)}
                     width={215}
                     height={160}
                   />
        {costOptions?.length > 0 && (<FilterField filterType={filtersFields.cost}
                   filterDataLength={0}
                   handleSelectionChange={(options) => setFilters({...filters, cost: [options] })}
                   onSelectMultiButtonClick={() => {}}
                   selectedOptions={filters.cost || []}
                   setSelectedOptions={(options) => setFilters({...filters, cost: [options] })}
                   options={costOptions}
                   height={getCostOptions(usersStore)?.length * 45}
                   width={185}/>)}
        {includeOptions?.length > 0 && (
            <FilterField filterType={filtersFields.include}
                          isMulti
                          filterDataLength={0}
                          onSelectMultiButtonClick={() => {}}
                          selectedOptions={filters.include || []}
                          handleSelectionChange={(options) => setFilters({...filters, include: options})}
                          options={includeOptions}
                          height={120}/>
            )}
      </div>);
};

export default MainDashboardFilters;
