import React, { useCallback, useEffect, useState } from 'react';
import { GenerateIcon, ICONS } from '@pileus-cloud/anodot-frontend-common';
import SwitchButton from 'shared/components/andtComponents/Switch';
import Button from 'shared/components/andtComponents/Button';
import Spinner from 'shared/components/andtComponents/Spinner';
import RowLikeAccordion from 'shared/components/RowLikeAccordion';
import TableLikeHeader from 'shared/components/TableLikeHeader.tsx';
import Tooltip from 'shared/components/andtComponents/Tooltip';
import classNames from 'classnames';
import { CLOUD_TYPE_IDS } from 'users/constants/usersConstants';
import { useRootStore } from 'app/contexts/RootStoreContext';
import { AlertTypeOnUpdate, AlertTypesEnum, CommitmentExpAlert } from '../types.ts';
import styles from '../alerts.module.scss';
import UpdateModal from './UpdateModal.tsx';
import t from '../texts.ts';
import { useAlerts } from '../hooks/useAlerts.ts';
import DeleteConfirmationModal from '../components/DeleteConfirmationModal.tsx';
import AlertForm from './Form.tsx';
import EditDeleteButtons from '../components/EditDeleteButtons.tsx';
import { useUsersSettings, riServerPropName, spServerPropName } from '../hooks/useUsersSettings.ts';
import { isDefaultExpanded } from '../ServiceCostTab/constants.ts';
import EmptyAlerts from '../components/EmptyState';

export const hideCustomerNameField = true; // TODO: remove after validation
const numberToAlertType = [
  'changeCostService',
  'commitementExpirationRi',
  'commitementExpirationSP',
] as AlertTypeOnUpdate[];

export interface CommitmentExpirationTabProps {
  expirationAlerts: CommitmentExpAlert[];

  refetchAlerts(): void;
}

const CommitmentExpirationTab: React.FC<CommitmentExpirationTabProps> = ({
  expirationAlerts,
  refetchAlerts,
}) => {
  const { usersStore } = useRootStore();
  const [isAllExpanded, setIsAllExpanded] = useState(isDefaultExpanded);

  const [isSPExpanded, setIsSPExpanded] = useState(isDefaultExpanded);
  const [showUpdateModal, setShowUpdateModal] = useState<boolean>(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [activeAlert, setActiveAlert] = useState<CommitmentExpAlert | null>(null);
  const { updateAlerts, deleteAlert, checkSPPlansExist } = useAlerts();
  const { mutate: handleUpdateAlert } = updateAlerts(refetchAlerts);
  const { refetch: handleDelete } = deleteAlert(activeAlert?.uuid || '');
  const { updateUsersSettings, fetchUsersSettings, setUsersSettingsLocally } = useUsersSettings();
  const { refetch: refetchSettings, isLoading, data: usersSettings } = fetchUsersSettings();
  const { mutate: handleUpdateSettings } = updateUsersSettings({ onSuccess: () => refetchSettings() });
  const {
    data: isSpPlansExist = false,
    isLoading: isSpPlansLoading,
    refetch: refetchIsSpPlansExist,
  } = checkSPPlansExist();
  const onCloseDeleteAlert = useCallback(() => setShowDeleteModal(false), []);
  const isAzure = usersStore.currDispUserCloudAccountType === CLOUD_TYPE_IDS.AZURE;
  const canAddAlertsEmpty = isAzure && isSpPlansExist && !isSpPlansLoading;

  useEffect(() => {
    if (isAzure) {
      refetchIsSpPlansExist();
    }
  }, [isAzure]);

  useEffect(() => {
    if (expirationAlerts?.length === 0) {
      setIsSPExpanded(false);
    }
  }, [expirationAlerts]);

  const onUpdate = useCallback(
    (
      daysBeforeExpiry: number,
      recipientsEmails: string,
      recipients: object[],
      uuid?: string,
    ) => {
      handleUpdateAlert([
        {
          uuid,
          daysBeforeExpiry,
          recipientsEmails,
          recipients,
          alertStatus: 'save',
          alertType: numberToAlertType[AlertTypesEnum.SP_EXPIRATION],
          cloudType: CLOUD_TYPE_IDS.GCP
        },
      ]);
      setShowUpdateModal(false);
    },
    [handleUpdateAlert, activeAlert],
  );

  const onCreateClick = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();
      setActiveAlert(null);
      setShowUpdateModal(true);
    },
    [],
  );

  const onEditClick = useCallback((alert: CommitmentExpAlert) => {
    setActiveAlert(alert);
    setShowUpdateModal(true);
  }, []);

  const onDeleteClick = useCallback((alert: CommitmentExpAlert) => {
    setActiveAlert(alert);
    setShowDeleteModal(true);
  }, []);

  const onDeleteAlert = useCallback(() => {
    handleDelete();
    setTimeout(refetchAlerts, 1000);
    setShowDeleteModal(false);
  }, []);

  const onAllExpanded = useCallback(() => {
    setIsAllExpanded(!isAllExpanded);
    setIsSPExpanded(!isAllExpanded);
  }, [isAllExpanded]);

  const onSwitchAlerts = useCallback(
    (alertType: AlertTypesEnum) => async (checked) => {
      setUsersSettingsLocally(
        alertType === AlertTypesEnum.RI_EXPIRATION ? riServerPropName : spServerPropName,
        checked ? 1 : 0,
      );
      handleUpdateSettings({ alertType, checked });
    },
    [],
  );
  const isSPActive = !!usersSettings?.[spServerPropName];
  const showEmptyState = !isSPActive && (isAzure);

  return (
    <div>
      <TableLikeHeader
        onExpandClick={onAllExpanded}
        title={t('ALERT_RULES')}
        isExpanded={isAllExpanded}
        totalNumber={(expirationAlerts.length || 0)}
        className={styles.marginBottom32}
        onCreate={onCreateClick}
      >
        <span className={styles.alertSwitcherLabel}>{`${t('ENABLE_ALERTS')} (${expirationAlerts.length})`}</span>
        <Tooltip title={t('ENABLE_ALERTS')} arrow placement="top" className={styles.tooltipFlex}>
          {' '}
          <SwitchButton
            isChecked={isSPActive}
            onChange={onSwitchAlerts(AlertTypesEnum.SP_EXPIRATION)}
            className="styles.switch"
          />
        </Tooltip>
      </TableLikeHeader>
      {isLoading && <Spinner />}
      {showEmptyState && <EmptyAlerts canAdd={canAddAlertsEmpty} onAdd={onCreateClick} />}
      {isSPActive && (
        <RowLikeAccordion
          isExternalExpand={isSPExpanded && expirationAlerts.length > 0}
          isExpandDisabled={!expirationAlerts.length}
          headerContent={t('CUD_EXPIRATION')}
          onExpand={(e, expanded) => setIsSPExpanded(expanded)}
      >
          {expirationAlerts.map((alert) => (
            <div className={styles.alertsRow} automation-id={`${alert.uuid}alert-container`}>
              <AlertForm
                className={classNames(styles.commitmentForm, styles.formDisabled)}
                daysBeforeExpiry={alert.daysBeforeExpiry}
                recipientsEmails={alert.recipientsEmails}
              />
              <EditDeleteButtons
                style={{ marginTop: 13 }}
                onEdit={() => onEditClick(alert)}
                onDelete={() => onDeleteClick(alert)}
                alertId={alert.uuid}
              />
            </div>
          ))}
        </RowLikeAccordion>
      )}

      {showUpdateModal && (
        <UpdateModal
          isEdit={Boolean(activeAlert)}
          onUpdate={onUpdate}
          onClose={() => setShowUpdateModal(false)}
          daysBeforeExpiry={activeAlert?.daysBeforeExpiry}
          recipientsEmails={activeAlert?.recipientsEmails}
          recipients={activeAlert?.recipients}
          uuid={activeAlert?.uuid}
        />
      )}
      {showDeleteModal && <DeleteConfirmationModal onClose={onCloseDeleteAlert} onDelete={onDeleteAlert} />}
    </div>
  );
};

export default CommitmentExpirationTab;
