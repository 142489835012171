 
import { RecommendationTypes } from 'recommendations/constants/recommendationsConstants';
import { EbsOutdatedSnapshotRecommendation } from 'recommendations/models/recommTypes';
import CostRecommendationBuilder from 'recommendations/models/builders/costRecommBuilder';

export default class EbsOutdatedSnapshotRecommendationBuilder extends CostRecommendationBuilder {
  constructor(rawRecommendation) {
    super(rawRecommendation);
  }

  createRecommendation() {
    this._recommendation = new EbsOutdatedSnapshotRecommendation();
  }

  getRecommType() {
    return RecommendationTypes.EBS_OUTDATED_SNAPSHOT;
  }

  buildRecommTitle() {
    const { recommended_action: action } = this._rawRecommendation;
    this._recommendation.title = `${action || ''} EBS Outdated Snapshot`;
  }

  build() {
    super.build();
    const {
      resource_id: resourceId,
      volume_id: volumeId,
      account_id: accountId,
      resource_description: resourceDescription,
      resource_name: resourceName,
      snapshot_size: snapshotSize,
      potential_savings: potentialSavings,
      zone_tag: zoneTag,
      environment,
      project,
      elapsed_time_since_creation: elapsedTimeSinceCreation,
      cost_since_creation: costSinceCreation,
    } = this._rawRecommendation;
    this._recommendation.accountId = accountId;
    this._recommendation.volumeId = volumeId;
    this._recommendation.resourceId = resourceId;
    this._recommendation.resourceName = resourceName;
    this._recommendation.resourceDescription = resourceDescription;
    this._recommendation.snapshotSize = snapshotSize;
    this._recommendation.region = zoneTag;
    this._recommendation.environment = environment;
    this._recommendation.project = project;
    this._recommendation.elapsedTimeSinceCreation = elapsedTimeSinceCreation;
    this._recommendation.costSinceCreation = costSinceCreation;

    this._recommendation.potentialSavings = potentialSavings;

    // BETA
    this._recommendation.isBeta = true;
  }
}
