import React, {useMemo, useState} from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import {graphColors, palette} from 'shared/constants/colorsConstants.js';
import { numberWithCommas } from 'shared/utils/strUtil.js';
import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  LabelList,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from 'recharts';
import { CustomTooltip } from '../CustomTooltip.jsx';
import { CustomizedLegend } from '../CustomizedLegend/CustomizedLegend.jsx';

const BarsChart = ({data, dataKeys, dataProperties, specialGradient, axisSettings, tooltipComponent, showLabel, height=350}) => {
  const [currKey, setCurrKey] = useState();
  const customMouseOver = (payload) => {
    const dataKey = payload?.tooltipPayload[0]?.dataKey;
    if (dataKey !== currKey) {
      setCurrKey(dataKey);
    }
  };
  const topBars = useMemo(() => {
    return data.reduce((acc, entry) => {
      dataKeys.forEach(key => {
        if (entry[key] !== undefined) {
          acc[entry.usageDate] = key;
        }
      });
      return acc;
    }, {});
  }, [data, dataKeys]);
  const valueAccessor = dataKey => ({ payload }) => {
    if(dataKeys.length === 1) {
      return payload[dataKey];
    }
    if(topBars[payload.usageDate] === dataKey) {
      return Object.keys(payload)
        .filter((pointKey) => pointKey !== 'usageDate')
        .reduce((sum, pointKey) => sum + parseFloat(payload[pointKey] || 0), 0);
    }
  };
  return (<div>
    <ResponsiveContainer width="100%" height={height}>
      <BarChart
        data={data}
        margin={{
          top: 15,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        {specialGradient}
        <CartesianGrid stroke={palette.gray[100]} vertical={false}/>
        <XAxis
          dataKey="usageDate"
          tickCount={0}
          axisLine={false}
          tickLine={false}
          tick={{fill: palette.gray[400]}}
          tickFormatter={(value) => moment(value).format('MMM')}
        />
        <YAxis
          type="number"
          axisLine={false}
          tickLine={false}
          tick={{fill: palette.gray[450]}}
          tickFormatter={(value) => axisSettings.yTickFormatter ? axisSettings.yTickFormatter(value) : value}
        />
        <Tooltip itemSorter={() => 1}
                 content={tooltipComponent || <CustomTooltip activeKey={currKey}/>}/>
        <Legend content={<CustomizedLegend />}/>
        {dataKeys?.map((dataKey, index) => (
          <Bar
            key={dataKey}
            barSize={28}
            dataKey={dataKey}
            fill={dataProperties?.[dataKey]?.fill || graphColors[index]}
            onMouseOver={customMouseOver}
            onFocus={customMouseOver}
            stackId="a"
          >
            {data.map((entry) => {
              if (topBars[entry.usageDate] === dataKey) {
                return <Cell key={`cell-${dataKey}`} radius={[5, 5, 0, 0]} />;
              }
              return <Cell key={`cell-${dataKey}`} radius={0} />;
            })}
            {showLabel && (<LabelList
                              valueAccessor={valueAccessor(dataKey)}
                              position="top"
                              fill={palette.gray[400]}
                              formatter={(value) => numberWithCommas(value, 2)}
            />)}
          </Bar>
        ))}
      </BarChart>
    </ResponsiveContainer>
  </div>)
}
BarsChart.propTypes = {
  data: PropTypes.array.isRequired,
  dataProperties: PropTypes.object,
  specialGradient: PropTypes.node,
  axisSettings: PropTypes.object,
  tooltipComponent: PropTypes.node,
  dataKeys: PropTypes.array.isRequired,
  showLabel: PropTypes.bool,
  height: PropTypes.number,
};
export default BarsChart;
