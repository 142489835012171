import { useQuery, useQueryClient } from '@tanstack/react-query';
import apiConstants from 'shared/api/apiConstants';
import { buildFilterParams, buildWhereParams } from 'shared/utils/apiUtil';
import { getCostAndUsageData } from 'invoices/hooks/react-query/cost-and-usage';
import { CostTrackingConstants, CostTypes } from 'usage/constants/costAndUsageConstants';

const prepareAzureVirtualMachineUsageData = (rawData) => {
  const data = rawData.map((rawDataObj) => ({
    accountId: rawDataObj.account_id,
    accountName: rawDataObj.account_Name,
    groupBy: rawDataObj.group_by,
    usageDate: rawDataObj.usage_date,
    totalUsage: rawDataObj.total_usage_quantity,
    totalQuantity: rawDataObj.group_num_of_items,
    totalCost: rawDataObj.total_cost || 0,
  }));
  return data;
};

const fetchVmUsageData = async (startDate, endDate, currPeriodGranLevel) => {
  let data = [];
  // Building "where"'s Map
  const wheresMap = new Map();
  wheresMap.set('service', ['Virtual Machines']);
  const whereParams = buildWhereParams(wheresMap);

  // Building FiltersMap
  const filtersMap = new Map();
  const filterType = '';
  const selectedOptions = [];
  filtersMap.set(filterType, selectedOptions);
  const filterParams = buildFilterParams(filtersMap);
  const isCountNeeded = true;
  const isShowAmortize = false;
  try {
    data = await getCostAndUsageData(
      'operation',
      'quantitytype',
      whereParams,
      startDate,
      endDate,
      currPeriodGranLevel || CostTrackingConstants.GRAN_LEVEL_DAILY,
      filterParams,
      [CostTypes.COST],
      isCountNeeded,
      isShowAmortize,
    );
  } catch (error) {
    this.state = 'error';
  }
  return data;
};

const fetchVmUsageCost = async (startDate, endDate, periodGranLevel) => {
  try {
    const rawData = await fetchVmUsageData(startDate, endDate, periodGranLevel);
    const preparedUsageData = prepareAzureVirtualMachineUsageData(rawData);
    return preparedUsageData;
  } catch (error) {
    throw error;
  }
};

export default function azureVmUsageProvider({ startDate, endDate, periodGranLevel, isPpApplied, limit }) {
  const queryClient = useQueryClient();

  const queryKey = [apiConstants.QUERY_KEYS.AZURE_VM_USAGE, startDate, endDate, periodGranLevel, isPpApplied, limit];

  return {
    invalidate: () => queryClient.invalidateQueries(queryKey),
    reset: () => queryClient.resetQueries(queryKey),
    fetchAllVmUsageCost: () =>
      queryClient.fetchQuery(queryKey, () => fetchVmUsageCost(startDate, endDate, periodGranLevel), {
        retry: false,
      }),
    fetchVmUsageCost: () =>
      useQuery(queryKey, () => fetchVmUsageCost(startDate, endDate, periodGranLevel), { retry: false }),
  };
}
