 
import { RecommendationTypes } from 'recommendations/constants/recommendationsConstants';
import { RedshiftLowUtilizationRecommendation } from 'recommendations/models/recommTypes';
import CostRecommendationBuilder from 'recommendations/models/builders/costRecommBuilder';

export default class RedshiftLowUtilizationRecommendationBuilder extends CostRecommendationBuilder {
  constructor(rawRecommendation) {
    super(rawRecommendation);
  }

  createRecommendation() {
    this._recommendation = new RedshiftLowUtilizationRecommendation();
  }

  getRecommType() {
    return RecommendationTypes.REDSHIFT_LOW_UTILIZATION;
  }

  buildRecommTitle() {
    const { action } = this._rawRecommendation;
    this._recommendation.title = `${action || ''} Redshift`;
  }

  build() {
    super.build();
    const {
      cluster_id: clusterId,
      cluster_status: clusterStatus,
      days_to_check: daysToCheck,
      db_name: dbName,
      master_username: masterUsername,
      max_num_of_connections: maxNumOfConnections,
      node_type: nodeType,
      num_of_connections: numOfConnections,
      num_of_nodes: numOfNodes,
      zone_tag: region,

      // resource_name: resourceName,
    } = this._rawRecommendation;
    this._recommendation.clusterId = clusterId;
    this._recommendation.clusterStatus = clusterStatus;
    this._recommendation.daysToCheck = daysToCheck;
    this._recommendation.dbName = dbName;
    this._recommendation.masterUsername = masterUsername;
    this._recommendation.maxNumOfConnections = maxNumOfConnections;
    this._recommendation.nodeType = nodeType;
    this._recommendation.numOfConnections = numOfConnections;
    this._recommendation.numOfNodes = numOfNodes;
    this._recommendation.region = region;

    // BETA
    // this._recommendation.isBeta = true;
  }
}
