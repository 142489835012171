import React from 'react';
import PropTypes from 'prop-types';
import ColoredTabs from 'shared/components/tabs/ColoredTabs';
import Policies from './components/Policies';
import GeneralDetails from './components/GeneralDetails';
import { CLOUD_TYPE_IDS } from '../../constants/usersConstants';

import styles from './Settings.module.scss';

const Settings = ({ usersStore }) => {
  const { currDispUserCloudAccountType: cloudAccountType, isCurrentUserReCustomer } = usersStore;
  const checkIfPoliciesTabDisplayed = () => cloudAccountType === CLOUD_TYPE_IDS.AWS && !isCurrentUserReCustomer;
  const arrOfNavItems = [
    { id: 0, name: 'Policies', disabled: !checkIfPoliciesTabDisplayed() },
    {
      id: 1,
      name: 'General Info',
      disabled: usersStore.isCurrentUserReCustomer,
    },
  ];
  return (
    <ColoredTabs className={styles.tab} arrOfNavItems={arrOfNavItems.filter((nav) => !nav.disabled)} vertical>
      <Policies usersStore={usersStore} />
      <GeneralDetails usersStore={usersStore} />
    </ColoredTabs>
  );
};

Settings.propTypes = {
  usersStore: PropTypes.object.isRequired,
};

export default Settings;
