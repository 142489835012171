import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import useRoles from 'users/new-user-management/hooks/reactQuery/useRoles';
import useDataAccessAccounts from 'users/new-user-management/hooks/reactQuery/useDataAccessAccounts';
import Spinner from 'shared/components/andtComponents/Spinner';
import LinkedAccountsComponentWrapper from '../../../components/LinkedAccounts/LinkedAccountsComponentWrapper';
import {
  DATA_ACCESS_TABS,
  ENTITIES,
  ROLE_DATA_ACCESS_LINKED_ACCOUNTS_COLUMNS,
  ROLE_DATA_ACCESS_PAYER_ACCOUNTS_COLUMNS,
} from '../../../consts';
import {
  getInitialAccountsDataWithCounters,
  provideAccountsData,
} from '../../../components/LinkedAccounts/dataAccessHelperFunctions';
import CostCenters from 'users/containers/Organization/CostCenters/CostCenters';
import {
  getCostCentersDataAccessCombinedData,
  mergeCostCentersDataWithDataAccess,
} from 'users/containers/Organization/CostCenters/costCentersHelperFunctions';
import LeftSideTabs from 'users/containers/Organization/Roles/rolesExpandedRowComponents/DataAccess/LeftSideTabs';
// import { useRootStore } from 'app/contexts/RootStoreContext';

const DataAccessTab = ({
  roleId,
  isCreateEditMode,
  isInProgress,
  isViewOnly,
  modifiedSelectionById,
  onRemoveLinkedAccounts,
  onRemoveCostCenters,
  onAccountsSelectionChange,
  onCostCentersSelectionChange,
  setInitialCostCentersDataAccessData,
}) => {
  const [selectedTab, setSelectedTab] = useState(DATA_ACCESS_TABS.ACCOUNTS);

  // const { usersStore } = useRootStore();
  // const organizationId = usersStore.getCurrentUser?.organizationId;

  // TODO: In case the Role data access is derived from more than one Organization, need to call the API for each?
  // TODO: Or should I only display the data for the User organization?
  // TODO: If the user is a reseller, then the data access is derived from all organizations? And then call for each?
  const { fetchDataAccessPayerAccounts, fetchDataAccessCostCenters } = useDataAccessAccounts();
  const { data: accountsData, isLoading: isAccountsDataLoading } = fetchDataAccessPayerAccounts();
  const { data: costCentersData, isLoading: isCostCentersDataAccessLoading } = fetchDataAccessCostCenters();

  const { fetchRoleDataAccess } = useRoles();
  const { data: roleDataAccess, isLoading: isRoleDataAccessLoading } = fetchRoleDataAccess(roleId);
  // const { data: resellerCustomersData, isLoading: isResellerCustomersDataLoading } = fetchDataAccessResellerCustomers();

  const dataToDisplay = useMemo(() => {
    if (selectedTab.id === DATA_ACCESS_TABS.ACCOUNTS.id) {
      if (!accountsData?.length || !roleDataAccess) {
        return null;
      }
      const combinedData = getInitialAccountsDataWithCounters(accountsData);
      const combinedPayerAccountsData = provideAccountsData({
        accountsData,
        roleDataAccess,
        currentRoleId: roleId,
        isCreateEditMode,
      });
      return { ...combinedData, accountsData: combinedPayerAccountsData };
    }

    if (selectedTab.id === DATA_ACCESS_TABS.COST_CENTERS.id) {
      if (!costCentersData?.length || !roleDataAccess) {
        return null;
      }
      const data = getCostCentersDataAccessCombinedData(
        costCentersData,
        roleDataAccess,
        roleId,
        modifiedSelectionById,
        !isCreateEditMode,
      );
      if (isCreateEditMode) {
        setInitialCostCentersDataAccessData(data);
        // If createEdit mode - get the assigned together with the unassigned cost centers
        return mergeCostCentersDataWithDataAccess(costCentersData, data);
      } else {
        return data.filter((cc) => cc.costCenter.isAllowed || cc.costCenter.effectiveIsAllowed);
      }
    }
    return null;
  }, [
    accountsData,
    costCentersData,
    isCreateEditMode,
    modifiedSelectionById,
    roleDataAccess,
    roleId,
    selectedTab.id,
    setInitialCostCentersDataAccessData,
  ]);

  const getSelectedTabComponent = useCallback(() => {
    let tabComponent;
    switch (selectedTab.id) {
      case DATA_ACCESS_TABS.ACCOUNTS.id:
        tabComponent =
          isRoleDataAccessLoading || isAccountsDataLoading || isInProgress ? (
            <div className="position-relative ">
              <Spinner />
            </div>
          ) : (
            <LinkedAccountsComponentWrapper
              additionalData={{ roleId: roleId }}
              allAccountsData={dataToDisplay}
              entity={ENTITIES.ROLE.id}
              isCreateEditMode={isCreateEditMode}
              isViewOnly={isViewOnly}
              linkedAccountsColumns={Object.values(ROLE_DATA_ACCESS_LINKED_ACCOUNTS_COLUMNS).map((c) => c.columnName)}
              onRemoveLinkedAccount={onRemoveLinkedAccounts}
              onSelectionChange={onAccountsSelectionChange}
              payerAccountsColumns={Object.values(ROLE_DATA_ACCESS_PAYER_ACCOUNTS_COLUMNS).map((c) => c.columnName)}
            />
          );
        break;
      case DATA_ACCESS_TABS.COST_CENTERS.id:
        tabComponent = (
          <CostCenters
            isRoleCostCentersDataAccessView={true}
            isSelectionEnabled={isCreateEditMode && !isViewOnly}
            isRoleCostCentersDataAccessLoading={
              isRoleDataAccessLoading || isCostCentersDataAccessLoading || isInProgress
            }
            modifiedSelectionById={modifiedSelectionById}
            onRemoveCostCenters={onRemoveCostCenters}
            onSelectionChange={onCostCentersSelectionChange}
            roleCostCentersDataAccess={dataToDisplay}
            roleId={roleId}
          />
        );
        break;
      default:
        tabComponent = null;
    }
    return tabComponent;
  }, [
    dataToDisplay,
    isAccountsDataLoading,
    isCostCentersDataAccessLoading,
    isCreateEditMode,
    isInProgress,
    isRoleDataAccessLoading,
    modifiedSelectionById,
    onAccountsSelectionChange,
    onCostCentersSelectionChange,
    onRemoveCostCenters,
    onRemoveLinkedAccounts,
    roleId,
    selectedTab.id,
  ]);

  return (
    <LeftSideTabs
      getSelectedTabComponent={getSelectedTabComponent}
      selectedTab={selectedTab}
      setSelectedTab={setSelectedTab}
      showTabsTooltip={isCreateEditMode}
      tabs={Object.values(DATA_ACCESS_TABS)}
    />
  );
};

DataAccessTab.propTypes = {
  isCreateEditMode: PropTypes.bool,
  isInProgress: PropTypes.bool,
  isViewOnly: PropTypes.bool,
  modifiedSelectionById: PropTypes.object,
  onAccountsSelectionChange: PropTypes.func,
  onCostCentersSelectionChange: PropTypes.func,
  onRemoveCostCenters: PropTypes.func,
  onRemoveLinkedAccounts: PropTypes.func,
  roleId: PropTypes.string,
  setInitialCostCentersDataAccessData: PropTypes.func,
};

export default DataAccessTab;
