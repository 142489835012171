import React, { useState, useEffect, createContext } from 'react';
import PropTypes from 'prop-types';
import CustomModal from 'shared/components/andtComponents/Modal';
import Input from 'shared/components/andtComponents/Input';
import { isEmailValid } from 'shared/utils/strUtil';
import { isEmptyArray } from 'shared/utils/arrayUtils';
import RecipientsSelect from 'shared/components/RecipientsSelect';
import DeliverySelector from 'shared/components/reportModal/DeliverySelector';
import { EnvelopeIcon } from 'mdi-react';
import toast from 'shared/components/andtComponents/Toast';
import { SuccessNotificationsLabels } from 'shared/constants/notificationsConstants';
import { TEST_MODAL_TYPE } from 'usage/constants/costAndUsageConstants';
import useChannels from 'shared/components/slackIntegration/hooks/useChannels';
import styles from './ReportModal.module.scss';

export const ReportContext = createContext({
  reportData: {},
  setReportData: () => {},
});

const ReportModal = ({
  modalType,
  title,
  data = {},
  usersStore,
  isOpen,
  onSave,
  onSend,
  onClose,
  isScroll = false,
  children = null,
  isSaving = false,
}) => {
  const [errors, setErrors] = useState({ name: 'error', email: 'error', slack: 'error' });
  const [reportData, setReportData] = useState({
    reportName: {
      value: '',
      isValid: true,
    },
    emails: {
      value: '',
      isValid: true,
      length: 0,
    },
    recipients: [],
    isOnlyEmail: false,
  });

  const { getChannels } = useChannels();
  const { data: channels, isLoading } = getChannels();

  const validateEmail = (value) => {
    let length = 0;
    if (!value) {
      setReportData((prev) => ({ ...prev, emails: { value: '', isValid: true, length } }));
      setErrors((prev) => ({
        ...prev,
        email: 'error',
      }));
      return;
    }
    const isValid =
      !isEmptyArray(value.split(', ')) &&
      value
        .split(',')
        .map((email) => email.trim())
        .every((mail) => {
          if (isEmailValid(mail)) {
            length++;
            return true;
          }
          return false;
        });
    setReportData((prev) => ({ ...prev, emails: { value, isValid, length } }));
    setErrors((prev) => ({
      ...prev,
      email: isValid ? null : 'error',
    }));
  };

  useEffect(() => {
    if (!isOpen) {
      setReportData({
        reportName: {
          value: '',
          isValid: true,
        },
        emails: {
          value: '',
          isValid: true,
          length: 0,
        },
        recipients: [],
      });
      setErrors({ name: 'error', email: 'error', slack: 'error' });
    }
  }, [isOpen]);

  useEffect(() => {
    if (data && data.id) {
      setReportData((prev) => ({
        ...prev,
        reportName: {
          value: data.name,
          isValid: true,
        },
        recipients: data.recipients || [],
        saveDisabled: false,
      }));
      validateEmail(data.email);
      let slackErrors = 'error';
      if (data.recipients?.length > 0) {
        slackErrors = null;
      }
      setErrors((prev) => ({
        ...prev,
        name: data.name ? null : 'error',
        email: data.email ? null : 'error',
        slack: slackErrors,
      }));
    }
  }, [data]);

  const checkIsSaveDisabled = () => {
    let isDisabled = false;
    if (reportData.isSnapshotChecked && reportData.reportName.value) {
      return false;
    }
    if ((errors.email && errors.slack) || !reportData.emails.isValid) {
      return true;
    }
    if (modalType === TEST_MODAL_TYPE) {
      return false;
    }
    // eslint-disable-next-line consistent-return
    Object.keys(errors).forEach((key) => {
      if (key !== 'email' && key !== 'slack') {
        if (errors[key]) {
          isDisabled = true;
        }
      }
    });
    return isDisabled;
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    switch (name) {
      case 'reportName':
        setReportData((prev) => ({
          ...prev,
          reportName: { ...prev.reportName, value, isValid: value.length !== 0 },
        }));
        setErrors((prev) => ({
          ...prev,
          name: value.length === 0 ? 'error' : null,
        }));
        break;
      case 'emails':
        validateEmail(value);
        break;
      default:
    }
  };

  const addUserEmailToList = () => {
    const { currUserName: userEmail } = usersStore;
    if (!reportData.emails.isValid || reportData.emails.value.includes(userEmail)) {
      return;
    }
    let tempUserEmail = userEmail;
    if (reportData.emails.length > 0) {
      if (reportData.emails.value.at(-1) !== ',') {
        tempUserEmail = `, ${userEmail}`;
      }
    }
    const event = {
      target: { name: 'emails', value: reportData.emails.value.concat(tempUserEmail) },
    };
    handleChange(event);
  };

  const handleRecipientsChange = (recipients) => {
    if (!recipients) {
      setReportData((prev) => ({ ...prev, recipients: [] }));
      setErrors((prev) => ({ ...prev, slack: 'error' }));
      return;
    }
    setReportData((prev) => ({ ...prev, recipients }));
    setErrors((prev) => ({ ...prev, slack: recipients.length > 0 ? null : 'error' }));
  };

  return (
    (<CustomModal
      title={title}
      footerDisabled={isSaving}
      onClose={onClose}
      open={isOpen}
      closeOnSave={false}
      saveDisabled={checkIsSaveDisabled()}
      className={isScroll ? { content: styles.scroll } : {}}
      onSave={() => {
        if (modalType === TEST_MODAL_TYPE) {
          const { recipients, emails } = reportData;
          onSave({ recipients, email: emails.value, id: data.id });
        } else {
          onSave(reportData);
        }
      }}
      saveTitle={modalType === TEST_MODAL_TYPE ? 'Send' : 'Save'}
    >
      {modalType !== TEST_MODAL_TYPE ? (
        <div className={styles.field}>
          <div className={styles.fieldTitle}>Report Name *</div>
          <Input
            type="text"
            className={styles.input}
            name="reportName"
            id="reportName"
            placeholder="Enter report name"
            onChange={(event) => handleChange(event)}
            value={reportData.reportName.value}
          />
          {!reportData.reportName.isValid ||
          (reportData.recipients.length > 0 && errors.name) ||
          (reportData.emails.length > 0 && errors.name) ? (
            <div className={styles.errorLabel}>Report must have a name</div>
          ) : null}
        </div>
      ) : null}
      <ReportContext.Provider value={{ reportData, setReportData, data, errors, setErrors }}>
        {children}
        {!reportData.isSnapshotChecked ? (
          <div className={styles.sendWrapper}>
            {modalType !== TEST_MODAL_TYPE ? <div className={styles.sendHeader}>Send options</div> : null}
            <div className={styles.send}>
              <div className={`${styles.fieldTitle} ${styles.withIcon}`}>
                <div className={styles.iconEnv}>
                  <EnvelopeIcon size={18} />
                </div>
                <div className={styles.emailTitle}>
                  <div>{`Email recipients (${reportData.emails.length}) ${reportData.isOnlyEmail ? '*' : ''}`}</div>
                  <div className={styles.myEmail} onClick={addUserEmailToList}>
                    My email
                  </div>
                </div>
              </div>
              <Input
                type="email"
                className={styles.input}
                name="emails"
                id="emails"
                placeholder="example@anodot.com, example1@anodot.com"
                onChange={(event) => handleChange(event)}
                value={reportData.emails.value}
              />
              {!reportData.emails.isValid ? <div className={styles.errorLabel}>Emails must be valid</div> : null}
              {reportData.isOnlyEmail &&
              reportData.reportName.value &&
              reportData.emails.isValid &&
              reportData.emails.length === 0 ? (
                <div className={styles.errorLabel}>Report must have Email</div>
              ) : null}
            </div>
            {!reportData.isOnlyEmail ? ( // we currently do not support slack for customer reports
              (<div className={`${styles.field} ${styles.send}`}>
                <RecipientsSelect
                  channels={channels}
                  isLoading={isLoading}
                  savedRecipients={reportData.recipients}
                  onChangeHandler={handleRecipientsChange}
                />
                {reportData.reportName.value &&
                reportData.emails.isValid &&
                reportData.recipients.length === 0 &&
                reportData.emails.length === 0 ? (
                  <div className={styles.errorLabel}>
                    Please select at least one sending option: email or Slack channel
                  </div>
                ) : null}
              </div>)
            ) : null}
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              {modalType !== TEST_MODAL_TYPE ? (
                <>
                  <DeliverySelector />
                  <div
                    className={`${styles.sendTest} ${checkIsSaveDisabled() ? styles.invalid : null}`}
                    onClick={() => {
                      if (checkIsSaveDisabled()) {
                        return;
                      }
                      onSend(reportData);
                      toast.success(SuccessNotificationsLabels.REPORT_SENT);
                    }}
                  >
                    Send test
                  </div>
                </>
              ) : null}
            </div>
          </div>
        ) : null}
      </ReportContext.Provider>
    </CustomModal>)
  );
};

ReportModal.propTypes = {
  modalType: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  data: PropTypes.object,
  usersStore: PropTypes.object.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onSave: PropTypes.func.isRequired,
  onSend: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  isScroll: PropTypes.bool,
  children: PropTypes.object,
  isSaving: PropTypes.bool,
};

export default ReportModal;
