import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import apiConstants from 'shared/api/apiConstants';
import { API } from 'shared/utils/apiMiddleware';
import { toast } from 'react-toastify';

const root = '/api/v1/channels';

const getChannels = () => API.get('billings', root);
const createChannel = (channelData) => API.post('billings', root, { body: { channelData } });
const deleteChannel = (channelId) => API.del('billings', `${root}/${channelId}`);
const deleteBulkChannels = (channelIds) => API.del('billings', `${root}/bulk`, { body: channelIds });
const getPossibleRecipients = (channelId) => API.get('billings', `${root}/${channelId}/possibleRecipients`);
const getJiraIssueTypes = ({ channelId, projectId }) =>
  API.get('billings', `${root}/jira/${channelId}/${projectId}/issueTypes`);
const addRecipients = ({ channelId, recipients }) =>
  API.post('billings', `${root}/${channelId}/recipients`, { body: { recipients } });
const testRecipients = ({ channelId, recipients, additionalRecipients, managerRequest }) =>
  API.post('billings', `${root}/test`, { body: { channelId, recipients, additionalRecipients, managerRequest } });
const deleteRecipient = (recipientId) => API.del('billings', `${root}/recipients/${recipientId}`);

export default function useChannels() {
  const queryClient = useQueryClient();

  return {
    getChannels: (options = {}) =>
      useQuery([apiConstants.QUERY_KEYS.CHANNELS], () => getChannels(), {
        onError: () => toast.error('Error fetching channels recipients'),
        staleTime: 10 * 60 * 1000,
        ...options,
      }),
    createChannel: ({ onSuccess = () => {}, onError = () => {}, ...options } = {}) =>
      useMutation(createChannel, {
        onError: () => {
          toast.error('Error creating channel');
          onError();
        },
        onSuccess: (channel) => {
          toast.success('Channel created');
          queryClient.invalidateQueries(apiConstants.QUERY_KEYS.CHANNELS);
          onSuccess(channel);
        },
        ...options,
      }),
    deleteChannel: (options = {}) =>
      useMutation(deleteChannel, {
        onSuccess: () => {
          toast.success('Channel deleted');
          queryClient.invalidateQueries(apiConstants.QUERY_KEYS.CHANNELS);
        },
        ...options,
      }),
    deleteBulkChannels: (options = {}) =>
      useMutation(deleteBulkChannels, {
        onSuccess: () => {
          toast.success('Channels deleted');
          queryClient.invalidateQueries(apiConstants.QUERY_KEYS.CHANNELS);
        },
        ...options,
      }),
    getPossibleRecipients: (channelId, options = {}) =>
      useQuery([apiConstants.QUERY_KEYS.CHANNELS, channelId], () => getPossibleRecipients(channelId), {
        onError: () => toast.error('Error fetching possible recipients'),
        staleTime: 10 * 60 * 1000,
        enabled: !!channelId,
        ...options,
      }),
    getJiraIssueTypes: ({ channelId, projectId }, options = {}) =>
      useQuery(
        [apiConstants.QUERY_KEYS.CHANNELS, channelId, projectId],
        () => getJiraIssueTypes({ channelId, projectId }),
        {
          onError: () => toast.error('Error fetching issue types'),
          staleTime: 10 * 60 * 1000,
          enabled: !!channelId && !!projectId,
          ...options,
        },
      ),
    addRecipients: (options = {}) =>
      useMutation(addRecipients, {
        onError: () => toast.error('Error adding recipients'),
        onSuccess: () => {
          toast.success('Channels updated');
          queryClient.invalidateQueries(apiConstants.QUERY_KEYS.CHANNELS);
        },
        ...options,
      }),
    testRecipients: ({ onSuccess = () => {}, onError = () => {}, ...options } = {}) =>
      useMutation(testRecipients, {
        onSuccess: () => {
          toast.success('Channel tested successfully');
          onSuccess();
        },
        onError: () => {
          toast.error('Error testing channel');
          onError();
        },
        ...options,
      }),
    deleteRecipient: (options = {}) =>
      useMutation(deleteRecipient, {
        onSuccess: () => {
          toast.success('Channel deleted');
          queryClient.invalidateQueries(apiConstants.QUERY_KEYS.CHANNELS);
        },
        ...options,
      }),
  };
}
