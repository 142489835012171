import React from 'react';
import PropTypes from 'prop-types';
import Input from 'shared/components/andtComponents/Input';

const propTypes = {
  inputValue: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
};

const TextInput = ({ inputValue = '', onChange, placeholder = '' }) => (
  <Input
    name="search"
    placeholder={placeholder}
    onInput={(e) => onChange(e.target.value)}
    value={inputValue}
    onChange={(e) => onChange(e.target.value)}
    overrideStyles={{
      marginInlineEnd: '40px',
      width: 'auto',
      height: '35px',
      fontFamily: 'Roboto',
      fontSize: '14px',
    }}
  />
);

TextInput.propTypes = propTypes;
export default TextInput;
