import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import IconFromPng from 'shared/components/IconFromPng';
import Icons from 'shared/constants/assetsConstants';

const handleChange = (event, handler, mapValuesDescToValues) => {
  const getValue = event.target.value;
  const validatedValue = mapValuesDescToValues && mapValuesDescToValues.get(getValue) ? getValue : getValue;
  handler(validatedValue, event);
};

const renderList = (arrValues, mapRegionNamesToDescription, id) => (
  <datalist id={id}>
    {arrValues.map((val, indx) => (
      <option key={`${val + indx}`} value={val}>
        {mapRegionNamesToDescription.get(val)}
      </option>
    ))}
  </datalist>
);

const IconInputField = memo(
  ({
    handler,
    label,
    name,
    value,
    type = 'text',
    placeHolder,
    icon,
    arrValues,
    mapRegionNamesToDescription,
    list = null,
    onBlurHandler,
    accept,
    isPassword = false,
    handleChangeInputType = (e) => {
      e.preventDefault();
    },
    maxLength = 100,
    ...rest
  }) => (
    <div>
      <span className="inner-wizard-input-legend">{label}</span>
      <div className="form__form-group-field">
        <div className="form__form-group-icon">
          <span className={icon} />
        </div>
        <input
          name={`${name}`}
          value={value}
          onChange={(event) => handleChange(event, handler, mapRegionNamesToDescription)}
          type={`${type}`}
          placeholder={placeHolder}
          list={list || null}
          maxLength={maxLength}
          onBlur={onBlurHandler || null}
          accept={accept}
          autoComplete="off"
        />
        {isPassword && (
          <Button onClick={handleChangeInputType} className="btn eye-btn">
            <IconFromPng icon={type === 'password' ? Icons.eye : Icons.eyeOff} />
          </Button>
        )}
        {list && arrValues.length > 0 ? renderList(arrValues, mapRegionNamesToDescription, list) : null}
      </div>
    </div>
  ),
);

export default IconInputField;

IconInputField.propTypes = {
  handler: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  type: PropTypes.string,
  placeHolder: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  list: PropTypes.string,
  mapRegionNamesToDescription: PropTypes.object.isRequired,
  arrValues: PropTypes.array.isRequired,
  isPassword: PropTypes.bool,
  handleChangeInputType: PropTypes.func,
  maxLength: PropTypes.number,
};
