import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import useTable from 'shared/hooks/customHooks/useTable';
import useUsers from 'users/new-user-management/hooks/reactQuery/useUsers';
import { doesUserMatchSearch, PAGINATION_DIRECTION } from 'users/containers/Organization/consts';
import AddUsersToRoleModalTable from '../AddUsersToRoleModal/AddUsersToRoleModalTable';
import AddUsersToRoleModalHeader from '../AddUsersToRoleModal/AddUsersToRoleModalHeader';

import styles from './CreateEditRoleModal.module.scss';

const UsersStep = ({ isViewOnly, role, setRole }) => {
  const [search, setSearch] = useState();
  const [paginationToken, setPaginationToken] = useState(null);

  const { NewTableWrapper } = useTable();

  const { fetchUsers } = useUsers();
  const { data, isLoading } = fetchUsers({ paginationToken, search });

  const displayedUsers = useMemo(() => {
    if (data?.users && !isLoading) {
      const usersWithoutTheRole = data?.users.filter((user) => user.roles.every((r) => r.id !== role?.id));
      return search ? usersWithoutTheRole.filter((user) => doesUserMatchSearch(user, search)) : usersWithoutTheRole;
    }
  }, [data, isLoading, role, search]);

  const pageChanged = (direction) => {
    if (direction === PAGINATION_DIRECTION.NEXT && data?.paginationToken) {
      setPaginationToken(data?.paginationToken);
    }
  };

  const onSelectedUsersIdsChange = (selectedUserIds) => {
    setRole({ ...role, selectedUserIds });
  };

  return (
    <NewTableWrapper className={classNames(['sub-table', styles.newTableWrapper])} isCompact>
      <AddUsersToRoleModalHeader
        selectedUsersCount={role?.selectedUserIds?.length || 0}
        search={search}
        setSearch={setSearch}
        pageChanged={pageChanged}
        total={data?.total}
      />
      <AddUsersToRoleModalTable
        isDisabled={isViewOnly}
        users={displayedUsers}
        setSelectedUserIds={onSelectedUsersIdsChange}
        isLoading={isLoading}
      />
    </NewTableWrapper>
  );
};

UsersStep.propTypes = {
  isViewOnly: PropTypes.bool,
  role: PropTypes.object,
  setRole: PropTypes.func,
};

export default UsersStep;
