import { useMutation, useQuery } from '@tanstack/react-query';
import apiConstants from 'shared/api/apiConstants';
import { queryClient } from 'queryClient';
import { useRootStore } from 'app/contexts/RootStoreContext';
import { STALE_TIME } from 'users/containers/Organization/consts';
import { handleError } from './helperFunctions';
import {
  createDataAccessCostCenter,
  deleteDataAccessCostCenter,
  fetchDataAccessCostCenterAccounts,
  fetchDataAccessCostCenters,
  fetchDataAccessCostCenterUnassignedAccounts,
  fetchDataAccessPayerAccounts,
  fetchDataAccessResellerCustomers,
  fetchPayerLinkedAccounts,
  updateDataAccessCostCenter,
  updateLinkedAccountData,
} from './apiDataAccessAccounts';
import { toast } from 'react-toastify';

export default function useDataAccessAccounts(isDisabled = false) {
  const { usersStore } = useRootStore();
  const userAccountKey = usersStore?.currDispUserAccountKey;

  const manageAccountsQueryKey = [apiConstants.QUERY_KEYS.USER_MANAGEMENT_DATA_ACCESS, userAccountKey];

  return {
    invalidate: () => queryClient.invalidateQueries(manageAccountsQueryKey),
    reset: () => queryClient.resetQueries(manageAccountsQueryKey),
    fetchDataAccessPayerAccounts: () =>
      useQuery(
        [...manageAccountsQueryKey, apiConstants.QUERY_KEYS.USER_MANAGEMENT_DATA_ACCESS_ACCOUNTS],
        () => fetchDataAccessPayerAccounts(),
        {
          enabled: !isDisabled,
          retry: false,
          staleTime: STALE_TIME,
          onError: handleError,
        },
      ),
    fetchPayerLinkedAccounts: (payerId) =>
      useQuery(
        [...manageAccountsQueryKey, apiConstants.QUERY_KEYS.USER_MANAGEMENT_DATA_ACCESS_ACCOUNTS, payerId],
        () => fetchPayerLinkedAccounts(payerId),
        {
          enabled: !isDisabled,
          retry: false,
          staleTime: STALE_TIME,
          onError: handleError,
        },
      ),
    fetchDataAccessCostCenters: () =>
      useQuery(
        [...manageAccountsQueryKey, apiConstants.QUERY_KEYS.USER_MANAGEMENT_DATA_ACCESS_COST_CENTERS],
        fetchDataAccessCostCenters,
        {
          enabled: !isDisabled,
          retry: false,
          staleTime: STALE_TIME,
          onError: handleError,
        },
      ),
    fetchCostCenterUnassignedAccounts: (costCenterId) =>
      useQuery(
        [
          ...manageAccountsQueryKey,
          apiConstants.QUERY_KEYS.USER_MANAGEMENT_DATA_ACCESS_COST_CENTERS,
          costCenterId,
          'unassigned',
        ],
        () => fetchDataAccessCostCenterUnassignedAccounts(costCenterId),
        {
          enabled: !isDisabled,
          retry: false,
          staleTime: STALE_TIME,
          onError: () =>
            toast.error('Error fetching cost center unassigned accounts data', {
              position: toast.POSITION.BOTTOM_RIGHT,
            }),
        },
      ),
    fetchCostCenterAccountsData: (costCenterId) =>
      useQuery(
        [...manageAccountsQueryKey, apiConstants.QUERY_KEYS.USER_MANAGEMENT_DATA_ACCESS_COST_CENTERS, costCenterId],
        () => costCenterId && fetchDataAccessCostCenterAccounts(costCenterId),
        {
          enabled: !isDisabled,
          retry: false,
          staleTime: STALE_TIME,
          onError: () =>
            toast.error('Error fetching cost center accounts data', {
              position: toast.POSITION.BOTTOM_RIGHT,
            }),
        },
      ),
    fetchDataAccessResellerCustomers: () =>
      useQuery(
        [...manageAccountsQueryKey, apiConstants.QUERY_KEYS.USER_MANAGEMENT_DATA_ACCESS_RESELLER_CUSTOMERS],
        () => fetchDataAccessResellerCustomers(),
        {
          enabled: !isDisabled,
          retry: false,
          staleTime: STALE_TIME,
          onError: handleError,
        },
      ),
    createDataAccessCostCenter: useMutation(
      ({ costCenter, accountsPayload }) => createDataAccessCostCenter({ costCenter, accountsPayload }),
      {
        onSuccess: async () => {
          await queryClient.invalidateQueries([
            ...manageAccountsQueryKey,
            apiConstants.QUERY_KEYS.USER_MANAGEMENT_DATA_ACCESS_COST_CENTERS,
          ]);
          await queryClient.invalidateQueries([
            ...manageAccountsQueryKey,
            apiConstants.QUERY_KEYS.USER_MANAGEMENT_ROLES,
            userAccountKey,
            apiConstants.QUERY_KEYS.USER_MANAGEMENT_DATA_ACCESS,
          ]);
        },
        onError: handleError,
      },
    ),
    updateDataAccessCostCenter: useMutation(
      ({ costCenterId, costCenterAccountsData }) =>
        updateDataAccessCostCenter({ costCenterId, costCenterAccountsData }),
      {
        onSuccess: async () => {
          await queryClient.invalidateQueries([
            ...manageAccountsQueryKey,
            apiConstants.QUERY_KEYS.USER_MANAGEMENT_DATA_ACCESS_COST_CENTERS,
          ]);
          await queryClient.invalidateQueries([
            ...manageAccountsQueryKey,
            apiConstants.QUERY_KEYS.USER_MANAGEMENT_ROLES,
            userAccountKey,
            apiConstants.QUERY_KEYS.USER_MANAGEMENT_DATA_ACCESS,
          ]);
        },
        onError: handleError,
      },
    ),
    deleteDataAccessCostCenter: useMutation((costCenterId) => deleteDataAccessCostCenter(costCenterId), {
      onSuccess: async () => {
        await queryClient.invalidateQueries([
          ...manageAccountsQueryKey,
          apiConstants.QUERY_KEYS.USER_MANAGEMENT_DATA_ACCESS_COST_CENTERS,
        ]);
        await queryClient.invalidateQueries([
          ...manageAccountsQueryKey,
          apiConstants.QUERY_KEYS.USER_MANAGEMENT_ROLES,
          userAccountKey,
          apiConstants.QUERY_KEYS.USER_MANAGEMENT_DATA_ACCESS,
        ]);
      },
      onError: handleError,
    }),
    updateLinkedAccountData: useMutation(
      ({ payerAccountId, linkedAccountId, linkedAccountData }) =>
        updateLinkedAccountData({ payerAccountId, linkedAccountId, linkedAccountData }),
      {
        onSuccess: async () => {
          await queryClient.invalidateQueries([
            ...manageAccountsQueryKey,
            apiConstants.QUERY_KEYS.USER_MANAGEMENT_DATA_ACCESS_ACCOUNTS,
          ]);
        },
        onError: handleError,
      },
    ),
  };
}
