
const SvgPattern = ({ color }) => {
  return `url("data:image/svg+xml;utf8,` +
    `<svg xmlns='http://www.w3.org/2000/svg' width='10' height='10' viewBox='0 0 10 10'>` +
    `<rect width='10' height='10' fill='${encodeURIComponent(color)}' fill-opacity='0.5' />` +
    `<line x1='10' y1='0' x2='0' y2='10' stroke='white' stroke-width='0.8' />` +
    `</svg>")`;
};

export default SvgPattern;
