import PropTypes from 'prop-types';
import classNames from 'classnames';
import React from 'react';
import moment from 'moment';
import {GenerateIcon, ICONS} from '@pileus-cloud/anodot-frontend-common';
import { useUserSettingsContext } from 'users/utils/contexts/UserSettingsContext.jsx';
import styles from './customTooltip.module.scss';


const getDateFormat = (date) => {
  return moment(date).format('MMM YYYY');
};
export const CustomTooltip = ({ payload, activeKey }) => {
  const { getCurrencyNumber } = useUserSettingsContext();
  if (!payload?.length) {
    return null;
  }
  const dataPoint = payload[0]?.payload;
  const total = Object.keys(dataPoint)
    .filter((pointKey) => pointKey !== 'usageDate')
    .reduce((sum, pointKey) => sum + parseFloat(dataPoint[pointKey] || 0), 0);

  return (
    <div className={styles.customizedTooltip}>
      <div className={styles.tooltipTitleContainer}>
        <GenerateIcon iconName={ICONS.calendarDay.name}/>
        <div className={styles.tooltipTitle}>{getDateFormat(dataPoint.usageDate)}</div>
      </div>
      <div className={styles.dataWrapper}>
        {payload.map((pointKey) => (
          <div key={`${pointKey.dataKey}-${pointKey.value}`} className={classNames(styles.tooltipRow, pointKey.dataKey === activeKey && styles.bold)}>
            <div className={styles.tooltipValue}>
              <div className={styles.keyWrapper}>
                <svg width="14" height="10" className={styles.legend}>
                  <rect width="14" height="10" fill={pointKey.color}/>
                </svg>
                <span>{pointKey.dataKey}</span>
              </div>
              <span>{getCurrencyNumber(pointKey.value)}</span>
            </div>
          </div>
        ))}
        {payload.length > 1 && (<div className={styles.summaryRow}>
          <span>Total</span>
          <span>{getCurrencyNumber(total)}</span>
        </div>)}
      </div>
    </div>
  )
};

CustomTooltip.propTypes = {
  payload: PropTypes.object,
  activeKey: PropTypes.object,
}
