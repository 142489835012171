import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import { segmentPageEvent } from 'shared/modules/segmentAndAptrinsicHandler';
import { Routes } from '~/shared/constants/routes.js';

const MainWrapper = ({ children, childProps }) => {
  const location = useLocation();

  useEffect(() => {
    if (location.pathname !== Routes.LOG_IN) {
      segmentPageEvent(childProps.usersStore);
    }
  }, [location.pathname, childProps]);

  return (
    <div className="theme-light_dark_outer_layout">
      <div className="wrapper" automation-id="wrapper">
        {children}
      </div>
    </div>
  );
};

MainWrapper.propTypes = {
  children: PropTypes.arrayOf(PropTypes.element).isRequired,
  childProps: PropTypes.object.isRequired,
};

export default MainWrapper;
