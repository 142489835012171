import React, { memo } from 'react';
import PropTypes from 'prop-types';

const ControlledToggleButton = memo(
  ({ handler, className = '', isFixColor = false, disabled = false, checked = false, selectedItem }) => {
    const OnClickHandler = (e) => {
      e.preventDefault();
      if (handler && (selectedItem || selectedItem === 0)) {
        handler(selectedItem);
      }
    };
    return (
      <div style={{ width: 'auto' }} className={`toggle-btn customizer__toggle ${className}`}>
        <input
          disabled={disabled}
          className="toggle-btn__input"
          type="checkbox"
          name="top_menu_toggle"
          checked={checked}
          onChange={() => {}}
        />
        {
          // eslint-disable-next-line react/button-has-type
          <button
            disabled={disabled}
            className={`toggle-btn__input-label${isFixColor ? '-fix-blue' : ''}`}
            onClick={(e) => OnClickHandler(e)}
          />
        }
      </div>
    );
  },
);

ControlledToggleButton.propTypes = {
  handler: PropTypes.func.isRequired,
  className: PropTypes.string,
  isFixColor: PropTypes.bool,
  disabled: PropTypes.bool,
  checked: PropTypes.bool,
  selectedItem: PropTypes.oneOfType([PropTypes.bool, PropTypes.string, PropTypes.number]),
};

export default ControlledToggleButton;
