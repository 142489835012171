import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import SimpleSelect from 'shared/components/andtComponents/SimpleSelect';
import Input from 'shared/components/andtComponents/Input';
import RadioButtonList from 'shared/components/andtComponents/RadioButtonList';
import CustomModal from 'shared/components/andtComponents/Modal';
import { useCostCenter } from 'shared/hooks/react-query/useDivisions';
import ErrorMsg from 'shared/components/ErrorMsg';
import styles from './MoveLinkedAccountsModal.module.scss';

const MoveLinkedAccountsModal = ({
  isOpen = false,
  fromDivision,
  linkedAccountIds,
  divisions,
  onClose,
  fetchDivisions,
}) => {
  const { moveLinkedAccounts } = useCostCenter();
  const { mutateAsync: mutateMoveLinkedAccounts } = moveLinkedAccounts({
    onError: () => onClose(),
    onSuccess: () => {
      fetchDivisions();
      onClose();
    },
  });
  const [saveClicked, setSaveClicked] = useState(false);
  const [costCenterName, setCostCenterName] = useState('');
  const [costCenterCode, setCostCenterCode] = useState('');
  const [toDivision, setToDivision] = useState(null);
  const [isNew, setIsNew] = useState(false);
  const toDivisionsOptions = useMemo(
    () =>
      divisions
        .filter(
          ({ divisionName, divisionId }) =>
            divisionName !== fromDivision?.divisionName && divisionId !== fromDivision?.divisionId,
        )
        .map(
          ({
            divisionNameDisplay,
            accountId,
            divisionCode,
            divisionId,
            divisionName,
            divisionTypeId,
            roleId,
          } = {}) => ({
            value: { divisionNameDisplay, accountId, divisionCode, divisionId, divisionName, divisionTypeId, roleId },
            label: divisionNameDisplay || divisionName || '',
          }),
        ),
    [divisions, fromDivision],
  );
  const [errors, setErrors] = useState({});
  return (
    <CustomModal
      saveTitle="Submit"
      closeOnSave={false}
      className={styles.container}
      open={isOpen}
      onClose={onClose}
      onSave={async (e) => {
        e.preventDefault();
        setSaveClicked(true);
        if (!isNew && !toDivision) {
          setErrors((prevErrors) => ({ ...prevErrors, toCostCenter: 'You must select a cost center' }));
          return;
        }
        if (isNew && !costCenterName) {
          setErrors((prevErrors) => ({ ...prevErrors, costCenterName: 'Cost center name is mandatory' }));
          return;
        }
        if (isNew) {
          await mutateMoveLinkedAccounts({
            fromDivision,
            linkedAccounts: linkedAccountIds,
            toDivision: { divisionName: costCenterName, divisionCode: costCenterCode },
          });
        } else {
          await mutateMoveLinkedAccounts({
            fromDivision,
            linkedAccounts: linkedAccountIds,
            toDivision,
          });
        }
        onClose();
      }}
      saveDisabled={isNew ? !costCenterName : !toDivision}
    >
      <div className="d-flex">
        <div className="d-flex">
          <div
            onClick={() => setIsNew(false)}
            data-type-selected={!isNew}
            className={`d-flex flex-column ${styles.toDivisionTypeContainer}`}
          >
            <div className={styles.toDivisionTypeTitle} data-active={!isNew} onClick={() => setIsNew(false)}>
              <div>
                <RadioButtonList
                  options={[{ value: 'exist', label: 'Move to another Cost Center', primary: true }]}
                  value={isNew ? 'new' : 'exist'}
                  onChange={() => {
                    setErrors((prevErrors) => ({ ...prevErrors, costCenterName: '' }));
                    setIsNew(false);
                  }}
                />
              </div>
            </div>
            <div className={`d-flex flex-column ${styles.toDivisionTypeBody}`}>
              <span>Select Cost Center</span>
              <SimpleSelect
                onChange={(costCenter) => {
                  setToDivision(costCenter);
                  setErrors((prevErrors) => ({ ...prevErrors, toCostCenter: '' }));
                }}
                isClearable={false}
                options={toDivisionsOptions}
                placeholder="Select"
              />
              <ErrorMsg errorMsg={errors?.toCostCenter || ''} display={saveClicked && errors?.toCostCenter} />
            </div>
          </div>
          <div
            onClick={() => setIsNew(true)}
            data-type-selected={!!isNew}
            className={`d-flex flex-column ${styles.toDivisionTypeContainer}`}
          >
            <div className={styles.toDivisionTypeTitle} data-active={!!isNew} onClick={() => setIsNew(true)}>
              <div>
                <RadioButtonList
                  options={[{ value: 'new', label: 'Move to a new Cost Center', primary: true }]}
                  value={isNew ? 'new' : 'exist'}
                  onChange={() => {
                    setErrors((prevErrors) => ({ ...prevErrors, toCostCenter: '' }));
                    setIsNew(true);
                  }}
                />
              </div>
            </div>
            <div className={`d-flex flex-column ${styles.toDivisionTypeBody}`}>
              <span>Cost Center Name</span>
              <Input
                label="Cost Center Name"
                name="costCenterName"
                overrideStyles={{ height: 36, marginBottom: '24px' }}
                value={costCenterName}
                onChange={(e) => {
                  const { value } = e?.target || {};
                  setErrors((prevErrors) => ({ ...prevErrors, costCenterName: '' }));
                  const trimmedValue = value.trim().replace(/\s/g, '');
                  setCostCenterName(trimmedValue || null);
                }}
                placeholder="Enter Name"
                invalidComponent={
                  <ErrorMsg errorMsg={errors?.costCenterName} display={saveClicked && errors?.costCenterName} />
                }
                isInvalid={saveClicked && !!errors?.costCenterName}
              />
              <span>Cost Center Code</span>
              <Input
                label="Cost Center Code"
                name="costCenterCode"
                overrideStyles={{ height: 36 }}
                value={costCenterCode}
                onChange={(e) => {
                  setCostCenterCode(e?.target?.value || null);
                }}
                placeholder="Enter Code"
              />
            </div>
          </div>
        </div>
      </div>
    </CustomModal>
  );
};

MoveLinkedAccountsModal.propTypes = {
  isOpen: PropTypes.bool,
  fromDivision: PropTypes.object.isRequired,
  linkedAccountIds: PropTypes.array.isRequired,
  divisions: PropTypes.array.isRequired,
  onClose: PropTypes.func.isRequired,
  fetchDivisions: PropTypes.func.isRequired,
};

export default MoveLinkedAccountsModal;
