/* eslint-disable no-unused-vars */
import { CostUsageStates } from 'usage/constants/costAndUsageConstants';
import BaseCostUsageDataState from './baseCostUsageDataState';

export default class BusinessMappingDataState extends BaseCostUsageDataState {
  constructor(context) {
    super(context);
  }

  getNewDataStateType(dataKey) {
    const newDataState = CostUsageStates.BUSINESS_MAPPING;
    return newDataState;
  }

  getDataStateType() {
    return CostUsageStates.BUSINESS_MAPPING;
  }
}
