import CustomModal from 'shared/components/andtComponents/Modal';
import Input from 'shared/components/andtComponents/Input';
import React, { useEffect, useState } from 'react';
import { ICONS, GenerateIcon } from '@pileus-cloud/anodot-frontend-common';
import Button from 'shared/components/andtComponents/Button';
import PropTypes from 'prop-types';
import { palette } from 'shared/constants/colorsConstants';
import Checkbox from 'shared/components/andtComponents/Checkbox';
import classes from './recommendationModal.module.scss';

const RecommendationLabel = ({ closeModal, recLabels = [], saveLabels, recIds, isFromBulk = false }) => {
  const [label, setLabel] = useState('');
  const [labels, setLabels] = useState();

  useEffect(() => {
    setLabels(
      recLabels.map((l) => ({
        label: l.label,
        recIds: l.recIds,
        selectLabel: true,
        appearInAll: l.recIds.length === recIds.length && l.recIds.every((value, index) => value === recIds[index]),
      })),
    );
  }, [recLabels]);

  return (
    <CustomModal
      open
      onClose={closeModal}
      onSave={() => {
        let addList = [];
        let removeList = [];
        if (isFromBulk) {
          addList = labels.filter((l) => l.appearInAll && l.selectLabel);
          removeList = labels.filter((l) => l.appearInAll && !l.selectLabel);
        } else {
          addList = labels.filter((l) => !recLabels.map((rl) => rl.label).includes(l.label));
          removeList = recLabels.filter((rl) => !labels.map((l) => l.label).includes(rl.label));
        }
        saveLabels(
          addList.map((l) => l.label),
          removeList.map((l) => l.label),
        );
      }}
      className={classes}
      title="Add Label"
    >
      <>
        <div className={classes.section}>
          <span className={classes.text}>Label</span>
          <div className={classes.flex}>
            <Input
              placeholder="Max 50 charts"
              className={classes.labelInput}
              value={label}
              onChange={(e) => {
                if (/^[^,]*$/.test(e.target.value)) {
                  setLabel(e.target.value);
                }
              }}
            />
            <Button
              automationId="btn-save-label"
              onClick={() => {
                if (!label) {
                  return;
                }
                setLabels([...(labels || []), { label, recIds, appearInAll: true, selectLabel: true }]);
                setLabel('');
              }}
              text="Create"
            />
          </div>
        </div>
        <div>
          {(labels || []).map((l) => (
            <div key={l.label} className={classes.label}>
              {isFromBulk ? (
                <div className={classes.deleteCB}>
                  <Checkbox
                    isHalfChecked={!l.appearInAll}
                    isChecked={l.selectLabel}
                    primary
                    onChange={(e) => {
                      const labelsTemp = labels.map((la) => {
                        if (la.label === l.label) {
                          return { ...la, selectLabel: l.appearInAll ? e : true, appearInAll: true };
                        }
                        return la;
                      });

                      setLabels(labelsTemp);
                    }}
                    automation-id="select-label"
                  />
                </div>
              ) : (
                <div className={classes.deleteIcon} automation-id="delete-label">
                  <GenerateIcon
                    iconName={ICONS.delete.name}
                    onClick={() => {
                      setLabels(labels.filter((la) => la.label !== l.label));
                    }}
                    color={palette.blue[500]}
                  />
                </div>
              )}
              <span>{l.label}</span>
            </div>
          ))}
        </div>
      </>
    </CustomModal>
  );
};

export default RecommendationLabel;

RecommendationLabel.propTypes = {
  closeModal: PropTypes.func.isRequired,
  saveLabels: PropTypes.func.isRequired,
  recIds: PropTypes.array.isRequired,
  isFromBulk: PropTypes.bool,
  recLabels: PropTypes.array,
};
