import { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

const useStateParams = ({ initialState, paramsName, serialize, deserialize }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const search = new URLSearchParams(location.search);
  const existingValue = search.get(paramsName);
  const [state, setState] = useState(existingValue ? deserialize(existingValue) : initialState);

  useEffect(() => {
    if (existingValue && deserialize(existingValue) !== state) {
      setState(deserialize(existingValue));
    }
  }, [existingValue]);

  const onChange = (s) => {
    setState(s);
    const searchParams = new URLSearchParams(location.search);
    searchParams.set(paramsName, serialize(s));
    const { pathname } = location;
    navigate({ pathname, search: searchParams.toString() });
  };

  return [state, onChange];
};

export default useStateParams;
