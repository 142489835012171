import React, { useEffect, useMemo, useState } from 'react';
import { useSpAnalyzerContext } from 'commitment/containers/spAnalyzerNew/contexts/spAnalyzerContext';
import Button from 'shared/components/andtComponents/Button';
import {
  ACTUAL_CHART,
  ACTUAL_CHART_LEGEND,
  ACTUAL_TP_MAP_DATA, CURRENT_PROPERTIES, ON_DEMAND_CHART,
  ON_DEMAND_CHART_LEGEND,
  ON_DEMAND_TP_MAP_DATA, SIMULATION_PROPERTIES,
} from 'commitment/containers/spAnalyzerNew/utils/consts';
import DiscountTable from './DiscountTable';
import RecommendedPlanDetails from './RecommendedPlanDetails';
import styles from './recommendedPlanData.module.scss';
import RecommendedChart from './recommendedCharts/RecommendedChart';
import {GenerateIcon, ICONS} from '@pileus-cloud/anodot-frontend-common';
import SelectedProperties from 'commitment/containers/spAnalyzerNew/components/SelectedProperties.jsx';
import {
  getActualChartData,
  getOnDemandChartData
} from 'commitment/containers/spAnalyzerNew/utils/savingPlansAnalyzerUtils.js';

const RecommendedPlanData = () => {
  const { recommendationPlanData: recPlanData, planDataByKey, isSimulateData, setIsSimulateData, selectedPlan, setSelectedPlan} = useSpAnalyzerContext();
  const [recPerPlanData, setRecPerPlanData] = useState();

  useEffect(() => {
    if (selectedPlan) {
      setRecPerPlanData(planDataByKey?.[selectedPlan] || recPlanData?.recommendationPerPlan?.[selectedPlan]);
    }
  }, [planDataByKey, recPlanData, selectedPlan]);

  const onDemandChartData = useMemo(() => {
    if (!selectedPlan || !recPerPlanData) {
      return null;
    }
    return getOnDemandChartData(recPlanData, recPerPlanData, isSimulateData);
  }, [recPlanData, recPerPlanData, selectedPlan, isSimulateData]);

  const actualChartData = useMemo(() => {
    if (!selectedPlan || !recPerPlanData) {
      return null;
    }
    return getActualChartData(recPlanData, recPerPlanData, isSimulateData);

  }, [recPerPlanData, recPlanData, isSimulateData, selectedPlan]);

  const shareProperties = {
    type: recPlanData?.savingsPlanType,
    currentMonthlyOdCost: recPlanData?.currentMonthlyOdCost,
    currentMonthlyNetCost: recPlanData?.currentMonthlyNetCost,
    simulatedCurrentMonthlyNetCost: recPlanData?.simulatedCurrentMonthlyNetCost,
    simulatedCurrentMonthlyOdCost: recPlanData?.simulatedCurrentMonthlyOdCost,
  };

  return (
    <div className={styles.planDataWrapper}>
      <RecommendedPlanDetails selectedPlan={selectedPlan} setSelectedPlan={setSelectedPlan} shareProperties={shareProperties} />
      {recPlanData && recPerPlanData && (
          <>
            <div className={styles.swaggerWrapper}>
              <Button isSecondary={!isSimulateData}
                      overrideStyles={isSimulateData && {height: '24px', borderRadius: '4px'}} text="Simulation"
                      onClick={() => setIsSimulateData(true)}
                      icon={() => <GenerateIcon iconName={ICONS.chartBar.name}/>}/>
              <Button isSecondary={isSimulateData}
                      overrideStyles={!isSimulateData && {height: '24px', borderRadius: '4px'}} text="Current"
                      onClick={() => setIsSimulateData(false)}
                      icon={() => <GenerateIcon iconName={ICONS.presentationScreen.name}/>}/>
            </div>
            <div>
              <SelectedProperties
                  selectedProperties={isSimulateData ? SIMULATION_PROPERTIES : CURRENT_PROPERTIES}
                  shareProperties={shareProperties}
                  plan={recPerPlanData|| {}} />
            </div>
          </>
      )}
      <div className={styles.container} automation-id="onDemandChart">
        {recPlanData && recPerPlanData && (
          <RecommendedChart
            legend={isSimulateData ? ON_DEMAND_CHART_LEGEND.simulate : ON_DEMAND_CHART_LEGEND.current}
            data={isSimulateData ? onDemandChartData.simulateData : onDemandChartData.currentData}
            isAnimationActive
            info={ON_DEMAND_CHART[isSimulateData ? 'simulate': 'current'].info}
            header={ON_DEMAND_CHART[isSimulateData ? 'simulate': 'current'].header}
            tooltipMapData={isSimulateData ? ON_DEMAND_TP_MAP_DATA.simulate : ON_DEMAND_TP_MAP_DATA.current}
            automationId="onDemandChart"
            chartType="onDemand"
            isCurrentTab={!isSimulateData}
            expirationEvents={!isSimulateData ? recPlanData.expirationEvents : {}}
            purchaseEvents={!isSimulateData ? recPlanData.purchaseEvents : {}}
          />
        )}
      </div>
      <div className={styles.container} automation-id="actualChart">
        {recPlanData && recPerPlanData && (
          <RecommendedChart
            legend={isSimulateData ? ACTUAL_CHART_LEGEND.simulate : ACTUAL_CHART_LEGEND.current}
            data={isSimulateData ? actualChartData.simulateData : actualChartData.currentData}
            includeEstimatedOption={isSimulateData}
            isCurrentTab={!isSimulateData}
            info={ACTUAL_CHART[isSimulateData ? 'simulate': 'current'].info}
            header={ACTUAL_CHART[isSimulateData ? 'simulate': 'current'].header}
            tooltipMapData={isSimulateData ? ACTUAL_TP_MAP_DATA.simulate : ACTUAL_TP_MAP_DATA.current}
            automationId="actualChart"
            chartType="actual"
            isAnimationActive={false}
            isCurrentData={!isSimulateData}
            expirationEvents={!isSimulateData ? recPlanData.expirationEvents : {}}
            purchaseEvents={!isSimulateData ? recPlanData.purchaseEvents : {}}
          />
        )}
      </div>
      <div className={styles.container}>
        {selectedPlan && !isSimulateData && <DiscountTable discountData={recPlanData?.discountTables?.[selectedPlan] || []} />}
      </div>
    </div>
  );
};

export default RecommendedPlanData;
