import PropTypes from 'prop-types';
import React from 'react';

import classes from './optionsSwitchButton.module.scss';

const OptionsSwitchButton = ({ label = 'Sort by', optionOne, optionTwo, selectedOption, toggleOption }) => {
  const isOptionOneSelected = selectedOption === optionOne.property;

  const toggleSwitchButton = () => {
    const newSortByOption = !isOptionOneSelected ? optionOne.property : optionTwo.property;
    toggleOption(newSortByOption);
  };

  return (
    <div className={classes.container} onClick={toggleSwitchButton}>
      <div className={classes.label}>{label}</div>
      <div className={classes.sortByOptions}>
        <div
          className={`${classes.option} ${isOptionOneSelected ? classes.selectedOption : classes.notSelectedOption}`}
        >
          {optionOne.symbol}
        </div>
        <div
          className={`${classes.option} ${!isOptionOneSelected ? classes.selectedOption : classes.notSelectedOption}`}
        >
          {optionTwo.symbol}
        </div>
      </div>
      <div className={classes.optionName}>{isOptionOneSelected ? optionOne.label : optionTwo.label}</div>
    </div>
  );
};

OptionsSwitchButton.propTypes = {
  label: PropTypes.string,
  selectedOption: PropTypes.string.isRequired,
  optionOne: PropTypes.object.isRequired,
  optionTwo: PropTypes.object.isRequired,
  toggleOption: PropTypes.func.isRequired,
};

export default OptionsSwitchButton;
