import React from 'react';
import classNames from 'classnames';
import Pagination from 'shared/components/andtComponents/Pagination';
import { GenerateIcon, ICONS } from '@pileus-cloud/anodot-frontend-common';
import { CSVLink } from 'react-csv';
import { cloneDeep } from 'lodash';
import styles from '../BillingSummary.module.scss';
import { BillingMarginRow } from '../../types.ts';

type Props = {
  rowsCount: number;
  rowName: string;
  tableData: BillingMarginRow[];
  onPageChange(newIndex: number): void;
  currentPage: number;
  totalPages: number;
  headerText?: string;
  categoryName: string;
};
const MarginsTableFilters: React.FC<Props> = ({
  headerText,
  rowName,
  tableData,
  currentPage,
  rowsCount,
  onPageChange,
  totalPages,
  categoryName,
}) => (
  <div className={styles.tableFilters} style={{ maxWidth: 'calc(100vw - 115px)' }}>
    <div>
      <div className={classNames(styles.headerText, 'mb-3')}>
        <span>{headerText}</span>
        <span> ({rowsCount})</span>
      </div>
    </div>

    <div className={styles.rightMenu}>
      <CSVLink data={cloneDeep(tableData)} headers={null} filename={`${rowName}_${categoryName}.csv`}>
        <div className={styles.downloadLinkButton} title="Export CSV">
          <GenerateIcon iconName={ICONS.download.name} />
          <span className="ms-2">Margin Summary</span>
        </div>
      </CSVLink>
      <Pagination currPageIdx={currentPage} onPageChange={onPageChange} totalPages={totalPages} />
    </div>
  </div>
);

export default MarginsTableFilters;
