import React from 'react';
import PropTypes from 'prop-types';
import RecommendationPreferences from '../recommendationGenericComponents/recommendationPreferences';
import RecommendationCommand, { COMMAND_TYPES } from '../recommendationGenericComponents/recommendationCommand';
import RecommendationInsideLook from '../recommendationGenericComponents/recommendationInsideLook';
import RecommendationDetailsLayout from '../recommendationDetailsLayout';
import { BASE_PROPERTIES, COST_PROPERTIES } from '../recommendationPropertyUtils';
import RecommendationDetails from '../recommendationGenericComponents/recommendationDetails';
import OtherResourceIdRecommendations from '../recommendationGenericComponents/otherResourceIdRecommendations';

const BaseRecommendation = ({
  recommendation,
  dynamicProps = {},
  description = null,
  descriptionUrl = null,
  commandsList = [],
}) => {
  const properties = { ...COST_PROPERTIES, ...BASE_PROPERTIES, ...dynamicProps };

  const detailsComponent = (
    <RecommendationDetails recommendationProperties={properties} recommendation={recommendation} />
  );

  const otherResourceIdRecComponent = <OtherResourceIdRecommendations resourceId={recommendation?.resourceId} />;

  if (!commandsList?.length && recommendation?.recData?.command) {
    commandsList = recommendation?.recData?.command
      ? [
          {
            type: COMMAND_TYPES.CLI,
            instructions: [
              {
                actionText: '',
                actionCommand: recommendation?.recData?.command,
              },
            ],
          },
        ]
      : null;
  }
  const cliCommandComponent = <RecommendationCommand commandsList={commandsList} />;

  const descriptionComponent = (
    <RecommendationInsideLook description={description || 'No Data Available'} descriptionUrl={descriptionUrl} />
  );

  const preferencesComponent = <RecommendationPreferences recommendationType={recommendation.typeId} />;

  return (
    <RecommendationDetailsLayout
      otherResourceIdRecommendations={otherResourceIdRecComponent}
      details={detailsComponent}
      command={cliCommandComponent}
      preferences={preferencesComponent}
      insideLook={descriptionComponent}
    />
  );
};

BaseRecommendation.propTypes = {
  commandsList: PropTypes.array,
  description: PropTypes.string,
  descriptionUrl: PropTypes.string,
  dynamicProps: PropTypes.object,
  recommendation: PropTypes.object.isRequired,
};

export default BaseRecommendation;
