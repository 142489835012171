import React from 'react';
import PropTypes from 'prop-types';
import Panel from 'shared/components/Panel';
import Alert from 'shared/components/Alert';

const alertByType = (type) => {
  if (type === 'error') {
    return (
      <Alert className="alert--bordered" icon color="danger">
        There was an error in the permission settings, we are working on it - Please visit us again in a few hours
      </Alert>
    );
  }
  return (
    <Alert className="alert--bordered" icon color="info">
      We&apos;re currently processing your data - Please visit us again in a few hours
    </Alert>
  );
};

const ProcessingDataPanel = ({ type = '' }) => (
  <Panel divider title="Data is processing">
    {alertByType(type)}
  </Panel>
);

ProcessingDataPanel.propTypes = {
  type: PropTypes.string,
};

export default ProcessingDataPanel;
