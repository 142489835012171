import React, { useState } from 'react';
import PropTypes from 'prop-types';
import CustomCSVDownload from 'shared/components/buttons/CustomCSVDownload';
import { palette } from 'shared/constants/colorsConstants';
import { GenerateIcon, ICONS } from '@pileus-cloud/anodot-frontend-common';
import Button from 'shared/components/andtComponents/Button';
import { downloadUsers } from 'users/new-user-management/hooks/reactQuery/apiUsers';
import DeleteUsersModal from './userModals/DeleteUsersModal';
import EnableDisableUsersModal from './userModals/EnableDisableUsersModal';
import ResetPasswordModal from './userModals/ResetPasswordModal';
import { useUsersContext } from './contexts/usersContext';
import { USER_ACTIONS } from 'users/containers/Organization/consts';
import AssignRolesModal from 'users/containers/Organization/Users/userModals/AssignRolesModal/AssignRolesModal';

import styles from './UsersBulkOperations.module.scss';

const UsersBulkOperations = ({ users }) => {
  const [isDeleteUserModalOpen, setIsDeleteUserModalOpen] = useState(false);
  const [isEnableDisableUserModalOpen, setIsEnableDisableUserModalOpen] = useState(false);
  const [isResetUserPasswordModalOpen, setIsResetUserPasswordModalOpen] = useState(false);
  const [isAssignRolesModalOpen, setIsAssignRolesModalOpen] = useState(false);
  const [isEnable, setIsEnable] = useState();

  const { selectedRows, setSelectedRows, totalUsers } = useUsersContext();
  const selectedUsers = selectedRows.map((row) => users[row]);

  const onBulkOperationSuccess = () => {
    setSelectedRows([]);
  };

  const onEnableDisableOpen = (isEnable) => {
    setIsEnable(isEnable);
    setIsEnableDisableUserModalOpen(true);
  };

  const fetchExportData = async () => {
    const userIds = selectedUsers.map((user) => user.id);
    const data = await downloadUsers(userIds, totalUsers);
    if (data?.users?.length) {
      const usersWithRoles = data.users.map((user) => {
        const roles = user?.roles?.map((role) => role.displayName).join(', ') || '';
        return { ...user, roles };
      });
      return [{ data: usersWithRoles, filename: 'users.csv' }];
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.subContainer}>
        <CustomCSVDownload
          fetchData={fetchExportData}
          automationId="export-users"
          showDownloadIcon
          isLoading={false}
          filesNumber={1}
          isSecondary
          isLightButton
          hideText
          style={{ background: 'none', fontSize: 16, color: palette.gray[400], padding: 0 }}
        />
        <span>Export</span>
      </div>
      <Button
        className={{ buttonContent: styles.subContainer }}
        icon={<GenerateIcon iconName={ICONS.circleCheck.name} className={styles.icon} />}
        isTextButton
        onClick={() => onEnableDisableOpen(true)}
        text="Enable"
        automationId="enableUsersBulk"
      />
      <Button
        className={{ buttonContent: styles.subContainer }}
        icon={() => <GenerateIcon iconName={ICONS.circleMinus.name} className={styles.icon} />}
        isTextButton
        onClick={() => onEnableDisableOpen(false)}
        text="Disable"
        automationId="disableUsersBulk"
      />
      <EnableDisableUsersModal
        setIsOpen={setIsEnableDisableUserModalOpen}
        isOpen={isEnableDisableUserModalOpen}
        users={selectedUsers}
        onSuccess={onBulkOperationSuccess}
        isEnable={isEnable}
      />
      <Button
        className={{ buttonContent: styles.subContainer }}
        icon={<GenerateIcon iconName={ICONS.deleteRegular.name} className={styles.icon} />}
        isTextButton
        onClick={() => setIsDeleteUserModalOpen(true)}
        text="Delete"
        automationId="deleteUsersBulk"
      />
      <DeleteUsersModal
        setIsOpen={setIsDeleteUserModalOpen}
        isOpen={isDeleteUserModalOpen}
        users={selectedUsers}
        onSuccess={onBulkOperationSuccess}
      />
      <Button
        className={{ buttonContent: styles.subContainer }}
        icon={<GenerateIcon iconName={ICONS.reset.name} className={styles.icon} />}
        isTextButton
        onClick={() => setIsResetUserPasswordModalOpen(true)}
        text="Reset Password"
        automationId="resetUsersPasswordBulk"
      />
      <ResetPasswordModal
        setIsOpen={setIsResetUserPasswordModalOpen}
        isOpen={isResetUserPasswordModalOpen}
        users={selectedUsers}
        onSuccess={onBulkOperationSuccess}
      />
      <Button
        className={{ buttonContent: styles.subContainer }}
        icon={<GenerateIcon iconName={USER_ACTIONS.ASSIGN_ROLES.icon} className={styles.icon} />}
        text={USER_ACTIONS.ASSIGN_ROLES.label}
        isTextButton
        onClick={() => setIsAssignRolesModalOpen(!isAssignRolesModalOpen)}
        automationId="assignRolesBulk"
      />
      {isAssignRolesModalOpen && (
        <AssignRolesModal isOpen={isAssignRolesModalOpen} setIsOpen={setIsAssignRolesModalOpen} users={selectedUsers} />
      )}
    </div>
  );
};

UsersBulkOperations.propTypes = {
  users: PropTypes.array,
};

export default UsersBulkOperations;
