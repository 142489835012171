import React from 'react';
import PropTypes from 'prop-types';
import MuiSlider from '@mui/material/Slider';
import { styled } from '@mui/material/styles';

const CustomizedSlider = styled(MuiSlider)(() => ({
  '& .MuiSlider-markLabel': {
    fontSize: 9,
  },
}));

const Slider = ({ defaultValue, marks, step = 1, onChange, value, ...other }) => (
  <CustomizedSlider
    defaultValue={defaultValue}
    marks={marks}
    step={step}
    onChange={onChange}
    value={value}
    color="primary"
    {...other}
  />
);

Slider.propTypes = {
  defaultValue: PropTypes.number,
  marks: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number.isRequired,
      label: PropTypes.string.isRequired,
    }),
  ),
  step: PropTypes.number,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.number.isRequired,
};

export default Slider;
