import React from 'react';
import classNames from 'classnames';
import MtdCost from 'app/containers/MainDashboard/panels/MtdCost.jsx';
import PreviousMonthCost from 'app/containers/MainDashboard/panels/PreviousMonthCost.jsx';
import AnnualSavings from 'app/containers/MainDashboard/panels/AnnualSavings.jsx';
import OpenRecommendations from 'app/containers/MainDashboard/panels/OpenRecommendations.jsx';
import ForecastedCost from 'app/containers/MainDashboard/panels/ForecastedCost.jsx';
import styles from './costPanelWrapper.module.scss';
import mainStyles from '../mainDashboardCommon.module.scss';
import LinkedAccountTotal from 'app/containers/MainDashboard/panels/LinkedAccountTotal.jsx';

const CostPanelsWrapper = () => {

    return (
        <div className={classNames(styles.costPanelsWrapper, mainStyles.fullWidth)}>
            <MtdCost/>
            <PreviousMonthCost/>
            <ForecastedCost />
            <AnnualSavings/>
            <div className={styles.dataPanelsWrapper}>
              <OpenRecommendations/>
              <LinkedAccountTotal />
            </div>
        </div>
    );
};

export default CostPanelsWrapper;
