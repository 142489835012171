import React from 'react';
import PropTypes from 'prop-types';
import CustomModal from 'shared/components/andtComponents/Modal';

const WarningModal = ({
  isOpen = false,
  warningText = '',
  executeButtonTitle = 'Continue',
  isLoading = false,
  handleDelete,
}) => (
  <CustomModal
    open={isOpen}
    title="Please Note!"
    saveDisabled={isLoading}
    saveWarning
    saveTitle={executeButtonTitle}
    onSave={() => handleDelete('delete')}
    onClose={() => handleDelete('cancel')}
    save
  >
    <p>{warningText}</p>
  </CustomModal>
);

WarningModal.propTypes = {
  warningText: PropTypes.string,
  executeButtonTitle: PropTypes.string,
  isOpen: PropTypes.bool,
  isLoading: PropTypes.bool,
  handleDelete: PropTypes.func.isRequired,
};

export default WarningModal;
