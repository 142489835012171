import React from 'react';
import PropTypes from 'prop-types';
import Button from 'shared/components/andtComponents/Button';
import TextInput from './TextInput';

const propTypes = {
  divisionState: PropTypes.object.isRequired,
  divisionDispatcher: PropTypes.func.isRequired,
  onAddCustomer: PropTypes.func.isRequired,
  addCustomerDisabled: PropTypes.bool.isRequired,
  isCustomerDataLoading: PropTypes.bool.isRequired,
};

const UpdateResellerCustomerRoleForm = ({
  divisionState,
  divisionDispatcher,
  onAddCustomer,
  addCustomerDisabled,
  isCustomerDataLoading,
}) => (
  <div className="d-flex flex-wrap mb-2">
    <div className="d-flex flex-column me-2">
      <span>Account ID:</span>
      <TextInput
        inputValue={divisionState.accountId}
        placeholder="Account ID"
        onChange={(value) => divisionDispatcher({ field: 'accountId', value })}
      />
    </div>
    <div className="d-flex flex-column me-2">
      <span>Account Key:</span>
      <TextInput
        inputValue={divisionState.accountKey}
        placeholder="Account key"
        onChange={(value) => divisionDispatcher({ field: 'accountKey', value })}
      />
    </div>
    <div className="d-flex flex-column me-2">
      <span>Division ID:</span>
      <TextInput
        inputValue={divisionState.divisionId}
        placeholder="Division ID"
        onChange={(value) => divisionDispatcher({ field: 'divisionId', value })}
      />
    </div>
    <div className="align-self-end">
      <Button
        type="button"
        text="Add Customer"
        onClick={onAddCustomer}
        disabled={addCustomerDisabled}
        isLoading={isCustomerDataLoading}
      />
    </div>
  </div>
);

UpdateResellerCustomerRoleForm.propTypes = propTypes;
export default UpdateResellerCustomerRoleForm;
