import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate, useParams } from 'react-router-dom';
import { observer } from 'mobx-react';
import Spinner from 'shared/components/andtComponents/Spinner';
import { useBusinessMapping } from 'usage/containers/BusinessMapping/hooks/react-query/useBusinessMapping';
import { useInvalidateFiltersDistinctValues } from 'invoices/hooks/react-query/useInvoiceFilters';
import { BUSINESS_MAPPING_COLUMNS } from '../../constants/businessMappingConstants';
import BusinessMappingGroupsTable from './BusinessMappingTable';

const BusinessMappingViewpointInfoTable = ({ usageStore, kubernetesStore, invoiceStore, usersStore }) => {
  const navigate = useNavigate();
  const [editingBusinessMappingGroup, setEditingBusinessMappingGroup] = useState(null);
  const [cloningBusinessMappingGroup, setCloningBusinessMappingGroup] = useState(null);
  const [deletingBusinessMappingGroup, setDeletingBusinessMappingGroup] = useState(null);
  const [importFromGlobalModalOpen, setImportFromGlobalModalOpen] = useState(false);
  const [createBusinessMappingGroupModalOpen, setCreateBusinessMappingGroupModalOpen] = useState(false);
  const { id: viewpointId } = useParams();
  const {
    fetchBusinessMappingPreferences,
    updateHideChangeRankWarning,
    makeBusinessMappingGlobal,
    fetchGlobalBusinessMappingGroups,
    deleteBusinessMappingGroup,
    updateBusinessMappingGroupRank,
    addBusinessMappingGroupToViewpoint,
    updateBusinessMappingGroup,
    addBusinessMappingGroup,
    fetchBusinessMappingGroups,
    fetchBusinessMappingViewpoint,
    invalidateBusinessMappingGroups,
    invalidateBusinessMappingViewpoint,
    invalidateBusinessMappingGlobalGroups,
    invalidateBusinessMappingPreferences,
  } = useBusinessMapping();
  const { invalidatePlainFilters } = useInvalidateFiltersDistinctValues();
  const { data: viewpoint, isLoading: isViewpointLoading } = fetchBusinessMappingViewpoint(viewpointId);
  const { data: globalMappings } = fetchGlobalBusinessMappingGroups();
  const {
    data: groups,
    isLoading: isGroupsLoading,
    isFetching: isGroupsFetching,
  } = fetchBusinessMappingGroups(viewpointId);
  const { data: preferences } = fetchBusinessMappingPreferences();
  const invalidateQuery = () => {
    invalidateBusinessMappingGroups();
    invalidateBusinessMappingViewpoint();
    invalidatePlainFilters();
  };
  const { mutateAsync: editGroup } = updateBusinessMappingGroup({
    onSuccess: () => {
      invalidateQuery();
      setEditingBusinessMappingGroup(null);
    },
  });

  const { mutateAsync: editGroupRank } = updateBusinessMappingGroupRank({
    onSuccess: () => {
      invalidateQuery();
    },
  });

  const { mutateAsync: deleteGroup } = deleteBusinessMappingGroup({
    onSuccess: () => {
      invalidateQuery();
      setDeletingBusinessMappingGroup(null);
    },
  });

  const { mutateAsync: makeGlobalGroup } = makeBusinessMappingGlobal({
    onSuccess: () => {
      invalidateQuery();
    },
  });

  const { mutateAsync: addGroup } = addBusinessMappingGroup({
    onSuccess: () => {
      invalidateQuery();
      setImportFromGlobalModalOpen(null);
      setCreateBusinessMappingGroupModalOpen(null);
      setCloningBusinessMappingGroup(null);
    },
  });

  const { mutateAsync: importFromGlobalGroup } = addBusinessMappingGroupToViewpoint({
    onSuccess: () => {
      invalidateQuery();
      invalidateBusinessMappingGlobalGroups();
      setImportFromGlobalModalOpen(null);
    },
  });

  const { mutateAsync: hideChangeRankWarning } = updateHideChangeRankWarning({
    onSuccess: () => {
      invalidateQuery();
      invalidateBusinessMappingPreferences();
    },
  });

  if (isViewpointLoading) {
    return <Spinner />;
  }
  if (!isGroupsLoading && !viewpoint) {
    navigate('/page-not-found');
    return null;
  }
  return (
    <BusinessMappingGroupsTable
      columns={BUSINESS_MAPPING_COLUMNS}
      usageStore={usageStore}
      invoiceStore={invoiceStore}
      kubernetesStore={kubernetesStore}
      usersStore={usersStore}
      groups={groups}
      globalMappings={globalMappings}
      viewpoint={viewpoint}
      isGroupsFetching={isGroupsFetching}
      isGroupsLoading={isGroupsLoading}
      setCreateBusinessMappingGroupModalOpen={setCreateBusinessMappingGroupModalOpen}
      createBusinessMappingGroupModalOpen={createBusinessMappingGroupModalOpen}
      setDeletingBusinessMappingGroup={setDeletingBusinessMappingGroup}
      deletingBusinessMappingGroup={deletingBusinessMappingGroup}
      setCloningBusinessMappingGroup={setCloningBusinessMappingGroup}
      cloningBusinessMappingGroup={cloningBusinessMappingGroup}
      setEditingBusinessMappingGroup={setEditingBusinessMappingGroup}
      editingBusinessMappingGroup={editingBusinessMappingGroup}
      setImportFromGlobalModalOpen={setImportFromGlobalModalOpen}
      importFromGlobalModalOpen={importFromGlobalModalOpen}
      addGroup={addGroup}
      deleteGroup={deleteGroup}
      editGroup={editGroup}
      makeGlobalGroup={makeGlobalGroup}
      editGroupRank={editGroupRank}
      importFromGlobalGroup={importFromGlobalGroup}
      hideChangeRankWarning={hideChangeRankWarning}
      isOrderChangeWarningHidden={preferences && preferences.changeRankingWarningHide}
    />
  );
};

BusinessMappingViewpointInfoTable.propTypes = {
  usageStore: PropTypes.object.isRequired,
  kubernetesStore: PropTypes.object.isRequired,
  usersStore: PropTypes.object.isRequired,
  invoiceStore: PropTypes.object.isRequired,
};

export default observer(BusinessMappingViewpointInfoTable);
