import React, { useCallback, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import DataAccessContent from 'users/containers/Organization/Roles/rolesExpandedRowComponents/DataAccess/DataAccessContent';

const DataAccessStep = ({ isViewOnly, role, setRole }) => {
  const [modifiedSelectionById, setModifiedSelectionById] = useState({});
  const initialCostCentersDataAccessData = useRef(undefined);

  const handleAccountsSelectionChange = useCallback(
    (dataAccessSelection) => {
      setRole({ ...role, roleDataAccess: dataAccessSelection });
    },
    [role, setRole],
  );

  const handleCostCentersSelectionChange = useCallback(
    ({ costCenterId, newSelected }) => {
      const tmpModified = { ...modifiedSelectionById };

      if (tmpModified[costCenterId] === undefined) {
        tmpModified[costCenterId] = newSelected;
      } else {
        delete tmpModified[costCenterId];
      }
      const payload = {
        itemsToAdd: [],
        itemsToRemove: [],
      };
      Object.keys(tmpModified).forEach((costCenterId) => {
        const costCenter = initialCostCentersDataAccessData.current.find((cc) => cc.costCenter.id === costCenterId);
        if (costCenter?.costCenter?.isAllowed === true && tmpModified[costCenterId] !== true) {
          payload.itemsToRemove.push(costCenterId);
        } else if (costCenter?.costCenter?.isAllowed !== true && tmpModified[costCenterId] === true) {
          payload.itemsToAdd.push(costCenterId);
        }
      });
      setModifiedSelectionById(tmpModified);
      setRole({ ...role, roleCostCentersDataAccess: payload });
    },
    [modifiedSelectionById, setRole, role],
  );

  const assignInitialCostCentersDataAccessData = useCallback(
    (value) => {
      initialCostCentersDataAccessData.current = value;
    },
    [initialCostCentersDataAccessData],
  );

  return (
    <DataAccessContent
      isCreateEditMode={true}
      isViewOnly={isViewOnly}
      modifiedSelectionById={modifiedSelectionById}
      onAccountsSelectionChange={handleAccountsSelectionChange}
      onCostCentersSelectionChange={handleCostCentersSelectionChange}
      roleId={role?.id}
      setInitialCostCentersDataAccessData={assignInitialCostCentersDataAccessData}
    />
  );
};

DataAccessStep.propTypes = {
  isViewOnly: PropTypes.bool,
  role: PropTypes.object,
  setRole: PropTypes.func,
};

export default DataAccessStep;
