import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { useRootStore } from '~/app/contexts/RootStoreContext.jsx';
import { useLocation, useSearchParams } from 'react-router-dom';
import Spinner from '~/shared/components/andtComponents/Spinner.jsx';
import usePreviousValue from '~/shared/hooks/customHooks/usePrevious.js';
import useCtrlKeyPressed from '~/shared/hooks/customHooks/useKeyPressed.ts';

const stringifyObject = (obj) => {
  const newObj = {};
  Object.keys(obj).map((key) => {
    if (obj[key] !== undefined) {
      newObj[key] = JSON.stringify(obj[key]);
    }
  });
  return newObj;
};

const withUrlParamsComponentWrapper = (Component) => {
  function UrlParamsComponentWrapper(props) {
    const [loaded, setLoaded] = useState(false);
    const [passedState, setPassedState] = useState(null);
    const { usersStore } = useRootStore();
    const location = useLocation();
    const { currDispUserDivisionId, currDispUserAccountKey } = usersStore;
    const previousIsPpApplied = usePreviousValue(props.isPpApplied);
    const [params, setParams] = useSearchParams();
    const ctrlPressedRef = useCtrlKeyPressed();
    const handleSyncParams = useCallback(
      (newPartialParams, baseState = {}) => {
        setParams((prev) => {
          const prevObj = Object.fromEntries(prev.entries());
          const newObj = stringifyObject(newPartialParams);
          const baseObj = stringifyObject(baseState);
          const mergedObj = {
            ...baseObj,
            ...prevObj,
            ...newObj,
          };
          const sortedKeys = Object.keys(baseObj);
          const sortedMergedObj = {};
          // sort keys to maintain url consistency
          sortedKeys.forEach((key) => {
            sortedMergedObj[key] = mergedObj[key];
          });
          // append all keys that are not from sortedKeys at the end
          Object.keys(mergedObj).forEach((key) => {
            if (!sortedKeys.includes(key)) {
              sortedMergedObj[key] = mergedObj[key];
            }
          });
          if (ctrlPressedRef.current) {
            window.open(`${window.location.pathname}?${new URLSearchParams(sortedMergedObj).toString()}`, '_blank');
          }
          return sortedMergedObj;
        });
        return !ctrlPressedRef.current;
      },
      [setParams],
    );
    useEffect(() => {
      if ((!params.has('accountKey') || previousIsPpApplied !== props.isPpApplied) && props.isPpApplied !== undefined) {
        handleSyncParams({
          accountKey: usersStore.currDispUserAccountKey,
          cloudAccountTypeId: usersStore.currDispUserCloudAccountType,
          divisionId: usersStore.currDispUserDivisionId,
          isPpApplied: props.isPpApplied,
          divisionName: usersStore.currDispUserDivisionName,
        });
      }
      setTimeout(() => {
        setLoaded(true);
      }, 150);
    }, [currDispUserDivisionId, currDispUserAccountKey, props.isPpApplied]);
    useEffect(() => {
      if (Object.values(location.state || {}).length > 0) {
        setPassedState(location.state);
      }
    }, [location.state]);

    const formattedParams = Object.fromEntries(params.entries());
    Object.keys(formattedParams).map((key) => {
      if (formattedParams[key] === 'undefined' || formattedParams[key] === 'null') {
        formattedParams[key] = undefined;
      } else {
        try {
          formattedParams[key] = JSON.parse(formattedParams[key]);
        } catch (e) {
          // eslint-disable-next-line no-console
          console.log(e);
        }
      }
    });
    return !loaded ? (
      <div className="cue-loading-page">
        <Spinner position="relative" />
      </div>
    ) : (
      <Component
        key={`${usersStore.currDispUserDivisionId}_${usersStore.currDispUserAccountKey}_${props.isPpApplied}`}
        {...props}
        urlParams={formattedParams}
        urlState={passedState}
        setUrlParams={handleSyncParams}
      />
    );
  }

  UrlParamsComponentWrapper.propTypes = {
    isPpApplied: PropTypes.bool,
  };
  return UrlParamsComponentWrapper;
};

export default withUrlParamsComponentWrapper;
