import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment/moment.js';
import { Grid, TableRowDetail, TableTreeColumn } from '@devexpress/dx-react-grid-material-ui';
import {
  CustomTreeData,
  DataTypeProvider,
  RowDetailState,
  SelectionState,
  TreeDataState,
} from '@devexpress/dx-react-grid';
import useTable from 'shared/hooks/customHooks/useTable';
import TableWrapper from 'shared/components/tables/TableWrapper';
import { CustomFormatterWithTooltipAndComponent } from 'shared/components/andtComponents/TableComponents/TableComponents';
import { COST_CENTERS_COLUMNS, COST_CENTERS_DATA_ACCESS_COLUMNS, getColumns } from '../consts';
import { useCostCentersContext } from './contexts/costCentersContext';
import CostCentersNoData from 'users/containers/Organization/components/EmptyStates/CostCentersNoData';
import Spinner from 'shared/components/andtComponents/Spinner';
import CostCentersIcon from 'users/containers/Organization/CostCenters/CostCentersIcon';
import CostCenterActions from 'users/containers/Organization/CostCenters/CostCenterActions';
import CostCenterAccountsData from 'users/containers/Organization/CostCenters/CostCenterAccountsData';
import RemoveButton from 'users/containers/Organization/components/RemoveButton';
import InfoPopover from 'shared/components/andtComponents/InfoPopover/index';
import CostCenterAssignedBy from 'users/containers/Organization/CostCenters/CostCenterAssignedBy';
import {
  CustomTreeCheckboxCell,
  RowContextProvider,
} from 'users/containers/Organization/CostCenters/CustomTreeCheckboxCell';
import { getChildRows } from 'users/containers/Organization/CostCenters/costCentersHelperFunctions';
import CreateEditCostCenter from 'users/containers/Organization/CostCenters/costCentersModals/CreateEditCostCenter/CreateEditCostCenter';
import EditCostCenterAccounts from 'users/containers/Organization/CostCenters/costCentersModals/CreateEditCostCenter/EditCostCenterAccounts';
import useDataAccessAccounts from 'users/new-user-management/hooks/reactQuery/useDataAccessAccounts';
import { GenerateIcon, ICONS } from '@pileus-cloud/anodot-frontend-common';
import { OrganizationEntityCategory, Action } from '@anodot-cost/rbac-client';
import ReadOnlyDisplayWrapper from 'shared/components/ReadOnlyDisplayWrapper';

import styles from './CostCenterTable.module.scss';

const CostCentersTable = ({
  costCenters,
  isLoading,
  isSelectionEnabled,
  isRoleDataAccessView = false,
  onRemoveCostCenter,
  onSelectionChange,
}) => {
  const { NewTableRow, CustomToggleCell } = useTable();

  const { fetchDataAccessPayerAccounts } = useDataAccessAccounts();
  const { data: accountsData } = fetchDataAccessPayerAccounts();

  const [expandedRowIds, setExpandedRowIds] = useState([]);
  const [selectedCostCenters, setSelectedCostCenters] = useState([]);
  const [expandedTreeRowIds, setExpandedTreeRowIds] = useState([]);

  function handleExpandedTreeRowIdsChange(rowIds) {
    setExpandedTreeRowIds(rowIds);
  }

  const { isAllExpanded, setIsAllExpanded } = useCostCentersContext();

  const [tableColumnExtensions] = useState([
    { columnName: COST_CENTERS_COLUMNS.COST_CENTER_NAME.columnName },
    { columnName: COST_CENTERS_COLUMNS.CREATED_AT.columnName, width: '170px' },
    { columnName: COST_CENTERS_COLUMNS.CREATE_SUB_COST_CENTER.columnName, width: '60px' },
    { columnName: COST_CENTERS_COLUMNS.ADD_LINKED_ACCOUNTS.columnName, width: '60px' },
    { columnName: COST_CENTERS_COLUMNS.ACTIONS.columnName, width: '40px' },
    { columnName: COST_CENTERS_DATA_ACCESS_COLUMNS.ASSIGNED_BY.columnName, width: '300px' },
    { columnName: COST_CENTERS_DATA_ACCESS_COLUMNS.REMOVE.columnName, width: '80px' },
  ]);

  useEffect(() => {
    if (!isAllExpanded) {
      setExpandedRowIds([]);
    } else {
      setExpandedRowIds(costCenters?.map((row, index) => index));
    }
  }, [costCenters, isAllExpanded]);

  useEffect(() => {
    if (expandedRowIds?.length === 0) {
      setIsAllExpanded(false);
    }
  }, [expandedRowIds, setIsAllExpanded]);

  const renderRemoveOrInfoButton = ({ row }) => {
    if (isRoleDataAccessView && row?.isAllowed) {
      if (row?.isDirectlyAssigned) {
        return (
          <ReadOnlyDisplayWrapper isHide={false} category={OrganizationEntityCategory.Roles} action={Action.Update}>
            <RemoveButton removeClicked={() => onRemoveCostCenter?.(row)} />
          </ReadOnlyDisplayWrapper>
        );
      } else {
        return (
          <div className="d-flex justify-content-end pe-4">
            <InfoPopover>
              <div>
                {`This cost center is assigned to a sub-role. To remove it, go to the sub-role and remove it there.`}
              </div>
            </InfoPopover>
          </div>
        );
      }
    }
    return null;
  };

  const renderAssignedBy = ({ row }) => {
    if (row) {
      return <CostCenterAssignedBy costCenter={row} />;
    }
    return null;
  };

  const renderCostCenterActions = ({ row }) => {
    const subTreeCostCenters = getChildRows(row, costCenters);
    return <CostCenterActions costCenter={row} subTreeCostCenters={subTreeCostCenters} />;
  };

  const renderCreateSubCostCenter = ({ row }) => <CreateEditCostCenter parentCcId={row.id} />;

  const renderCreatedAt = ({ row }) => (
    <div className={styles.createdAt}>
      <GenerateIcon iconName={ICONS.calendar.name} />
      Created: {row?.creationTime ? moment(row?.creationTime).format('MMM DD YYYY') : null}
    </div>
  );

  const renderEditCostCenter = ({ row }) => <EditCostCenterAccounts costCenter={row} parentCcId={row.id} />;

  const getCostCenterAccountsCounter = useCallback(
    ({ row }) => {
      let counter = 0;
      if (accountsData && row?.accounts) {
        const accounts = row?.accounts;
        accounts?.fullyAssignedPayerAccountIDs.forEach((paId) => {
          const pa = accountsData.find((account) => account.payerAccount.id === paId);
          if (pa?.linkedAccounts.length > 0) {
            counter += pa?.linkedAccounts.length;
          }
        });
        accounts?.payerWithLinkedAccounts?.forEach((pa) => {
          counter += pa.linkedAccountIds.length;
        });
      }
      return counter;
    },
    [accountsData],
  );

  return isLoading ? (
    <div className={styles.spinnerContainer}>
      <Spinner />
    </div>
  ) : (
    costCenters && (
      <div className={styles.costCentersGrid}>
        <Grid
          rows={costCenters}
          columns={getColumns(isRoleDataAccessView ? COST_CENTERS_DATA_ACCESS_COLUMNS : COST_CENTERS_COLUMNS)}
          automationId="cost-centers-list"
        >
          {isSelectionEnabled && (
            <SelectionState selection={selectedCostCenters} onSelectionChange={setSelectedCostCenters} />
          )}
          <TreeDataState expandedRowIds={expandedTreeRowIds} onExpandedRowIdsChange={handleExpandedTreeRowIdsChange} />
          <CustomTreeData getChildRows={getChildRows} />
          <RowDetailState expandedRowIds={expandedRowIds} onExpandedRowIdsChange={setExpandedRowIds} />
          <DataTypeProvider
            for={[
              COST_CENTERS_COLUMNS.COST_CENTER_NAME.columnName,
              COST_CENTERS_DATA_ACCESS_COLUMNS.COST_CENTER_NAME.columnName,
            ]}
            formatterComponent={(props) => (
              <div className={styles.costCenterNameAndId}>
                <CustomFormatterWithTooltipAndComponent {...props} isLeftComponent>
                  {!isRoleDataAccessView ? <CostCentersIcon /> : null}
                </CustomFormatterWithTooltipAndComponent>
                <div className={styles.costCenterId}>{props?.row?.externalId}</div>
                <div className={styles.costCenterCount}>({getCostCenterAccountsCounter(props)})</div>
              </div>
            )}
          />
          {!isRoleDataAccessView ? (
            <DataTypeProvider for={[COST_CENTERS_COLUMNS.CREATED_AT.columnName]} formatterComponent={renderCreatedAt} />
          ) : null}
          <DataTypeProvider
            for={[COST_CENTERS_COLUMNS.CREATE_SUB_COST_CENTER.columnName]}
            formatterComponent={renderCreateSubCostCenter}
          />
          {!isRoleDataAccessView ? (
            <DataTypeProvider
              for={[COST_CENTERS_COLUMNS.ACTIONS.columnName]}
              formatterComponent={renderCostCenterActions}
            />
          ) : null}
          {!isRoleDataAccessView ? (
            <DataTypeProvider
              for={[COST_CENTERS_COLUMNS.ADD_LINKED_ACCOUNTS.columnName]}
              formatterComponent={renderEditCostCenter}
            />
          ) : null}
          {isRoleDataAccessView ? (
            <DataTypeProvider
              for={[COST_CENTERS_DATA_ACCESS_COLUMNS.ASSIGNED_BY.columnName]}
              formatterComponent={renderAssignedBy}
            />
          ) : null}
          {isRoleDataAccessView && !isSelectionEnabled ? (
            <DataTypeProvider
              for={[COST_CENTERS_DATA_ACCESS_COLUMNS.REMOVE.columnName]}
              formatterComponent={renderRemoveOrInfoButton}
            />
          ) : null}
          <TableWrapper
            noDataCellComponent={CostCentersNoData}
            rowComponent={(props) => (
              <NewTableRow expandedRowIds={expandedRowIds} setExpandedRowIds={setExpandedRowIds} {...props} />
            )}
            height="auto"
            columnExtensions={tableColumnExtensions}
          />
          <TableTreeColumn
            cellComponent={({ row, ...restProps }) => (
              <RowContextProvider row={row}>
                <TableTreeColumn.Cell {...restProps} />
              </RowContextProvider>
            )}
            checkboxComponent={(props) => <CustomTreeCheckboxCell {...props} onToggle={onSelectionChange} />}
            for={COST_CENTERS_COLUMNS.COST_CENTER_NAME.columnName}
            showSelectionControls={isSelectionEnabled}
          />
          <TableRowDetail
            contentComponent={(props) => <CostCenterAccountsData {...props} isSelectionEnabled={isSelectionEnabled} />}
            toggleCellComponent={CustomToggleCell}
          />
        </Grid>
      </div>
    )
  );
};

CostCentersTable.propTypes = {
  costCenters: PropTypes.array.isRequired,
  isLoading: PropTypes.bool,
  isSelectionEnabled: PropTypes.bool,
  isRoleDataAccessView: PropTypes.bool,
  onRemoveCostCenter: PropTypes.func,
  onSelectionChange: PropTypes.func,
};

export default CostCentersTable;
