import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Col, Row, Progress } from 'reactstrap';
import Paper from '@mui/material/Paper';
import {
  RowDetailState,
  SortingState,
  FilteringState,
  IntegratedFiltering,
  IntegratedSorting,
  DataTypeProvider,
} from '@devexpress/dx-react-grid';
import {
  Grid,
  TableHeaderRow,
  TableRowDetail,
  TableFilterRow,
  Toolbar,
  TableColumnResizing,
} from '@devexpress/dx-react-grid-material-ui';
import TableWrapper from 'shared/components/tables/TableWrapper';
import { percentStrNoCommas } from 'shared/utils/strUtil';
import {
  COMMITMENT_DETAIL_NAMES,
  MAP_COMMITMENT_DETAIL_VALUES_TO_DISPLAY_NAMES,
} from '../../Commitments/constants/commitmentConstants';

const RowDetail = ({ row }) => {
  const arrRow = row ? Object.entries(row) : [];
  return arrRow
    .filter(([key, value]) => COMMITMENT_DETAIL_NAMES.indexOf(key) > -1 && !!value)
    .map(([key, value]) => (
      <Col xs={12} md={12} lg={12} xl={12} key={key}>
        <Row>
          <p style={{ padding: '3px', margin: '2px 0px' }}>
            <b>{MAP_COMMITMENT_DETAIL_VALUES_TO_DISPLAY_NAMES.get(key)}</b>
            <span>{`: ${value}`}</span>
          </p>
        </Row>
      </Col>
    ));
};

RowDetail.propTypes = {
  row: PropTypes.object.isRequired,
};

const UtilizationCommitmentTable = ({ tableColumns, defaultSortBy = '', tableData, percentColumns = [] }) => {
  const [columnWidth, setColumnWidth] = useState(tableColumns.map((col) => ({ columnName: col.name, width: 230 })));
  const columnExtensions = tableColumns.map((col) => ({ columnName: col.name, align: 'center' }));
  const changeColumnWidths = (columnWidths) => {
    setColumnWidth(columnWidths);
  };

  const utilizationFormatter = (data) => {
    const { value } = data;
    const getColor = (val) => {
      if (data.column.name !== 'utilizationPercentage') {
        return '';
      }
      if (+val < 75 && +val >= 50) {
        return 'warning';
      } else if (+val < 50) {
        return 'danger';
      }
      return 'success';
    };
    return (
      <div>
        <div className="text-center">{percentStrNoCommas(value, 2)}</div>
        <Progress value={value} color={getColor(value)} />
      </div>
    );
  };

  return (
    <Paper style={{ overflowX: 'scroll' }}>
      <Grid rows={tableData} columns={tableColumns}>
        <RowDetailState />
        <FilteringState defaultFilters={[]} />
        <SortingState defaultSorting={[{ columnName: defaultSortBy, direction: 'asc' }]} />
        <IntegratedSorting />
        <IntegratedFiltering />
        <DataTypeProvider for={percentColumns} formatterComponent={utilizationFormatter} />
        <TableWrapper columnExtensions={columnExtensions} />
        <TableColumnResizing
          columnWidths={columnWidth}
          defaultColumnWidths={300}
          onColumnWidthsChange={changeColumnWidths}
        />
        <TableHeaderRow showSortingControls />
        <TableRowDetail contentComponent={RowDetail} />
        <TableFilterRow showFilterSelector />
        <Toolbar />
      </Grid>
    </Paper>
  );
};

UtilizationCommitmentTable.propTypes = {
  tableData: PropTypes.array.isRequired,
  tableColumns: PropTypes.array.isRequired,
  defaultSortBy: PropTypes.string,
  percentColumns: PropTypes.array,
};

export default UtilizationCommitmentTable;
