import React, { memo } from 'react';
import PropTypes from 'prop-types';

const handleSelect = (event, handler) => {
  handler(event);
};

const renderList = (selectedValue, arrValues, mapValuesToDescription) =>
  arrValues.map((val) => {
    const value = val;
    const displayedVal = mapValuesToDescription.get(val);
    return (
      <option selected={val === selectedValue} value={value}>
        {displayedVal}
      </option>
    );
  });

const SimpleSelectWithPropList = memo(({ handler, label, name, value, arrValues, mapValuesToDescription, ...rest }) => (
  <div>
    <label style={{ float: 'left' }} htmlFor={name}>
      {label}
    </label>
    <select
      style={{ float: rest.selectStyle.float || 'right', ...rest.selectStyle }}
      id={name}
      name={`${name}`}
      onChange={(event) => handleSelect(event, handler, mapValuesToDescription)}
      className="borderFormField"
    >
      {renderList(value, arrValues, mapValuesToDescription)}
    </select>
  </div>
));

export default SimpleSelectWithPropList;

SimpleSelectWithPropList.propTypes = {
  handler: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  placeHolder: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  mapValuesToDescription: PropTypes.object.isRequired,
  arrValues: PropTypes.array.isRequired,
};
