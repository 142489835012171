import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { GenerateIcon, ICONS } from '@pileus-cloud/anodot-frontend-common';
import Button from 'shared/components/andtComponents/Button';
import Tooltip from 'shared/components/andtComponents/Tooltip';
import CreateEditCostCenterModal from './CreateEditCostCenterModal';
import { OrganizationEntityCategory, Action, CategoryAction } from '@anodot-cost/rbac-client';
import ReadOnlyDisplayWrapper from 'shared/components/ReadOnlyDisplayWrapper';

import styles from './CreateEditCostCenterModal.module.scss';

const CreateEditCostCenter = ({ costCenter, parentCcId, isSecondary }) => {
  const [isOpen, setIsOpen] = useState(false);

  const onClick = (e) => {
    e.stopPropagation();
    setIsOpen(true);
  };

  return (
    <ReadOnlyDisplayWrapper
      isHide={false}
      category={OrganizationEntityCategory.CostCenters}
      action={costCenter?.id ? Action.Update : CategoryAction.Create}
    >
      <Tooltip title={'Create Sub Cost Center'} placement={'top'} disabled={!parentCcId}>
        <Button
          automationId="createCostCenterButton"
          className={
            parentCcId
              ? { button: styles.addCostCenters }
              : { button: isSecondary ? styles.addCostCentersSecondary : '' }
          }
          disabled={false}
          icon={
            !parentCcId ? (
              <GenerateIcon iconName={ICONS.plus.name} />
            ) : (
              <GenerateIcon iconName={ICONS.addCostCenters.name} className={styles.addCostCenterIcon} />
            )
          }
          onClick={onClick}
          text={!parentCcId ? 'Create Cost Centers' : ''}
        />
        {isOpen && (
          <CreateEditCostCenterModal
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            costCenter={costCenter}
            parentCcId={parentCcId}
          />
        )}
      </Tooltip>
    </ReadOnlyDisplayWrapper>
  );
};

CreateEditCostCenter.propTypes = {
  costCenter: PropTypes.string,
  isSecondary: PropTypes.bool,
  parentCcId: PropTypes.string,
};

export default CreateEditCostCenter;
