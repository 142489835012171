import React, { useContext } from 'react';
import { useSubTableState } from 'shared/hooks/customHooks/useSubTableState';

const UsersTableContext = React.createContext();
export function withUsersTableContextProvider(Component) {
  return ({ ...props }) => {
    const tableState = useSubTableState();
    return (
      // eslint-disable-next-line react/jsx-filename-extension
      (<UsersTableContext.Provider
        value={{
          usersTable: tableState,
        }}
      >
        <Component {...props} />
      </UsersTableContext.Provider>)
    );
  };
}
export const useUsersTableState = (roleId) => {
  const {
    usersTable: { getPage, setPage, getSize, setSize, getSelectedRows, setSelectedRows, anySelected },
  } = useContext(UsersTableContext);
  return {
    page: getPage(roleId),
    setPage: (newPage) => setPage(roleId, newPage),
    size: getSize(roleId),
    setSize: (newSize) => setSize(roleId, newSize),
    selectedRows: getSelectedRows(roleId),
    setSelectedRows: (rows) => setSelectedRows(roleId, rows),
    anySelected,
  };
};

const LinkedAccountsTableContext = React.createContext();
export function withLinkedAccountsTableContextProvider(Component) {
  return ({ ...props }) => {
    const tableState = useSubTableState();
    return (
      // eslint-disable-next-line react/jsx-filename-extension
      (<LinkedAccountsTableContext.Provider
        value={{
          linkedAccountsTable: tableState,
        }}
      >
        <Component {...props} />
      </LinkedAccountsTableContext.Provider>)
    );
  };
}
export const useLinkedAccountsTableState = (roleId) => {
  const {
    linkedAccountsTable: {
      getPage,
      setPage,
      getSize,
      setSize,
      anySelected,
      getSelectedRows,
      setSelectedRows,
      getExpandedRows,
      setExpandedRows,
      getShowFilterRows,
      setShowFilterRows,
      getAdditionalProps,
      setAdditionalProps,
    },
  } = useContext(LinkedAccountsTableContext);
  return {
    page: getPage(roleId),
    setPage: (newPage) => setPage(roleId, newPage),
    size: getSize(roleId),
    setSize: (newSize) => setSize(roleId, newSize),
    anySelected,
    selectedRows: getSelectedRows(roleId),
    setSelectedRows: (rows) => setSelectedRows(roleId, rows),
    expandedRows: getExpandedRows(roleId),
    setExpandedRows: (rows) => setExpandedRows(roleId, rows),
    showFilterRows: getShowFilterRows(roleId),
    setShowFilterRows: (show) => setShowFilterRows(roleId, show),
    cloudTypeId: getAdditionalProps(roleId).cloudTypeId === undefined ? null : getAdditionalProps(roleId).cloudTypeId,
    setCloudTypeId: (cloudTypeId) => setAdditionalProps(roleId, { cloudTypeId }),
    getLinkedAccountsPage: (accountId) =>
      getAdditionalProps(roleId).linkedAccountsPage === undefined
        ? 0
        : getAdditionalProps(roleId).linkedAccountsPage[accountId] || 0,
    setLinkedAccountsPage: (accountId, linkedAccountsPage) =>
      setAdditionalProps(roleId, {
        linkedAccountsPage: {
          ...(getAdditionalProps(roleId).linkedAccountsPage || {}),
          [accountId]: linkedAccountsPage,
        },
      }),
  };
};
