 
 
export default class CustomDashboard {
  constructor(cdbParams) {
    this.accountId = cdbParams.accountId;
    this.accountKey = cdbParams.accountKey;
    this.cloudTypeId = cdbParams.cloudTypeId;
    this.uuid = cdbParams.uuid;
    this.name = cdbParams.name;
    this.isDefault = cdbParams.isDefault;
    this.userKey = cdbParams.userKey;
    this.createdBy = cdbParams.createdBy;
    this.setting = cdbParams.setting;
    this.filters = cdbParams.filters;
    this.allowedActions = cdbParams.allowedActions;
    this.panelsIds = cdbParams.panelsIds || [];
    this.panelsDisplaySettings = this.preparePanelsDisplaySettings(
      cdbParams.panelsDisplaySettings,
      cdbParams.panelTypes,
    );
    this.panelsAccounts = cdbParams.panelsAccounts || {};
    this.creationDate = cdbParams.creationDate;
    this.notToDelete = cdbParams.notToDelete;
  }

  addPanel = (id, panelType) => {
    if (!this.panelsDisplaySettings.has(id)) {
      this.addDisplaySettingsToNewPanel(id, panelType);
    }
    this.panelsIds.push(id);
  };
  getPanelIndexById = (id) => this.panelsIds.findIndex((pId) => pId === id);
  getPanelOrder = (id) => id && this.panelsDisplaySettings.get(id) && this.panelsDisplaySettings.get(id).order;
  getPanelSpan = (id) => id && this.panelsDisplaySettings.get(id) && this.panelsDisplaySettings.get(id).span;
  initPanelSpan = (panelType = '') => (panelType.includes('table') ? 2 : 1);
  addDisplaySettingsToNewPanel = (panelId, panelType) => {
    const maxOrder = this.panelsIds.length;
    this.panelsDisplaySettings.set(panelId, { order: maxOrder + 1, span: this.initPanelSpan(panelType) });
  };

  updatePanelDisplaySettingsOnPanelRemoval = () => {
    for (const panlId of this.panelsIds) {
      const currOrder = this.getPanelOrder(panlId);
      if (currOrder > 1) {
        this.updatePanelOrder(panlId, currOrder - 1);
      }
    }
  };
  removePanel = (id) => {
    try {
      const index = this.getPanelIndexById(id);
      if (index > -1) {
        this.panelsIds.splice(index, 1);
        this.panelsDisplaySettings.delete(id);
        this.updatePanelDisplaySettingsOnPanelRemoval(id);
      }
      return true;
    } catch {
      return false;
    }
  };
  preparePanelsDisplaySettings = (settings = null, panelTypes = {}) => {
    const tempSettings = settings ? new Map(settings) : new Map();
    if (!settings) {
      this.panelsIds.forEach((uuid, index) => {
        tempSettings.set(uuid, {
          span: this.initPanelSpan(panelTypes[uuid]),
          order: index + 1,
        });
      });
    }
    return tempSettings;
  };
  updatePanelSpan = (panelId, newVal) => {
    try {
      const currSettings = this.panelsDisplaySettings.get(panelId);
      currSettings.span = newVal;
      this.panelsDisplaySettings.set(panelId, currSettings);
      return true;
    } catch {
      return false;
    }
  };
  updatePanelOrder = (panelId, newVal) => {
    try {
      const currSettings = this.panelsDisplaySettings.get(panelId);
      currSettings.order = newVal;
      this.panelsDisplaySettings.set(panelId, currSettings);
      return true;
    } catch {
      return false;
    }
  };

  reArrangePanelsAccordingToOrder = () => {
    const sortedPanelArr = [...this.panelsDisplaySettings.entries()].sort((a, b) => a[1].order - b[1].order);
    for (let index = 0; index < this.panelsDisplaySettings.size; index++) {
      sortedPanelArr[index][1].order =
        sortedPanelArr[index][1].order === index + 1 ? sortedPanelArr[index][1].order : index + 1;
    }
    this.panelsDisplaySettings = new Map(sortedPanelArr);
  };
}
