import React from 'react';
import PropTypes from 'prop-types';
import CustomModal, { MODAL_MODES } from 'shared/components/andtComponents/Modal';
import { ReactComponent as AzureFocusIcon } from 'shared/img/cloud-providers/azure-focus.svg';
import Input from 'shared/components/andtComponents/Input';
import OnboardingLinks from '~/app/containers/Onboarding/utils/OnboardingLinks.js';
import styles from './AzureFocusMigrationModal.module.scss';

const AzureFocusMigrationModal = ({ onClose, isOpen, onSave, accountData }) => {
  const [directoryPrefix, setDirectoryPrefix] = React.useState('');
  const handleOnSaveButtonClick = async (e) => {
    e.preventDefault();
    await onSave({ accountId: accountData.accountId, directoryPrefix });
  };
  return (
    <CustomModal
      open={isOpen}
      onClose={onClose}
      headerMode={MODAL_MODES.ADD}
      headerIcon={<AzureFocusIcon />}
      onSave={(e) => handleOnSaveButtonClick(e)}
      title="Migrate to FOCUS"
      className={{
        content: styles.container,
      }}
    >
      <p>To migrate your Azure account to FOCUS export:</p>
      <ol>
        <li>Create a new FOCUS export in Azure.</li>
        <li>
          Place the new FOCUS export in a new directory within your existing container (name:{' '}
          <b>{accountData.accountInfo?.container_name}</b>).
        </li>
        <li>Enter the new directory path below:</li>
      </ol>
      <Input
        type="text"
        value={directoryPrefix}
        onChange={(e) => setDirectoryPrefix(e.target.value)}
        placeholder="Enter Directory Path"
      />
      <p className={styles.notesTitle}>Important Notes:</p>
      <ol>
        <li>Service names in the FOCUS export may differ due to Azure&apos;s updated service classification.</li>
        <li>
          Migration does not include K8s data. If your account uses K8s, do not migrate it. Contact support for guidance
          - <a href="mailto:support@anodot.com">support@anodot.com</a>.
        </li>
      </ol>
      <p>
        For detailed instructions,{' '}
        <a href={OnboardingLinks.AZURE.focusGuide} rel="noopener noreferrer" target="_blank">
          see our documentation
        </a>
        .
      </p>
    </CustomModal>
  );
};

AzureFocusMigrationModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onSave: PropTypes.func.isRequired,
  accountData: PropTypes.object.isRequired,
};

export default AzureFocusMigrationModal;
