import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { ClickAwayListener } from '@mui/material';
import Tooltip from 'shared/components/andtComponents/Tooltip';
import { GenerateIcon, ICONS } from '@pileus-cloud/anodot-frontend-common';
import PieChartGroupByBased from 'shared/components/charts/PieChartGroupByBased';
import { useBusinessMapping } from 'usage/containers/BusinessMapping/hooks/react-query/useBusinessMapping';
import { ReactComponent as EmptyListIcon } from 'shared/img/icons/empty-list.svg';
import { ReactComponent as K8SIcon } from 'shared/img/icons/k8s.svg';
import Spinner from 'shared/components/andtComponents/Spinner';
import Button from 'shared/components/andtComponents/Button';
import { standardDateFormat } from 'shared/utils/dateUtil';
import { Routes } from 'shared/constants/routes';
import { useRootStore } from 'app/contexts/RootStoreContext';
import styles from './BusinessMappingPiePreview.module.scss';

const BusinessMappingPiePreview = ({
  onClose = () => {},
  viewpoint,
  staticMode = false,
  menu = null,
  splitWarning = false,
}) => {
  const { kubernetesStore, invoiceStore } = useRootStore();
  const { fetchBusinessMappingPreview, invalidateBusinessMappingPreview } = useBusinessMapping();
  const { data, isLoading, isFetching } = fetchBusinessMappingPreview(viewpoint, kubernetesStore, invoiceStore);
  const renderBody = () => {
    if (isLoading || isFetching) {
      return (
        <div className={styles.emptyBlock}>
          <Spinner position="static" />
          <p>Loading graph view...</p>
        </div>
      );
    }
    if (!data || !data.length) {
      return (
        <div className={styles.emptyBlock}>
          <EmptyListIcon />
          <p className="mt-3">No data found</p>
        </div>
      );
    }
    return (
      <div className={styles.chartContainer}>
        <PieChartGroupByBased data={data} chartId="bm-preview-chart" height={320} />
      </div>
    );
  };
  const renderTitle = () => (
    <div className={styles.title}>
      <div>
        <p className={styles.name}>
          {viewpoint.name}
          {viewpoint.k8s && (
            <Tooltip title="This Viewpoint is based on K8s Cost & Usage">
              <span className="ms-2">
                <K8SIcon />
              </span>
            </Tooltip>
          )}
        </p>
        {splitWarning ? <p className="pb-2">Each item includes the assigned split cost allocation.</p> : null}
        <p>
          <span>Creation Date: </span>
          {standardDateFormat(viewpoint.dbCreationTime)}
        </p>
        <p>
          <span>Created By: </span>
          {viewpoint.createdBy}
        </p>
      </div>
      <div onClick={(e) => e.preventDefault()}>
        {menu || (
          <Button
            text="Refresh"
            automationId="refreshBMPiePreview"
            isTextButton
            disabled={isLoading || isFetching}
            onClick={() => invalidateBusinessMappingPreview()}
            icon={() => <GenerateIcon iconName={ICONS.refresh.name} />}
          />
        )}
      </div>
    </div>
  );
  if (staticMode) {
    return (
      <Link to={`${Routes.BUSINESS_MAPPING}/${viewpoint.uuid}`}>
        <div className={styles.container} data-static={staticMode}>
          {renderTitle()}
          {renderBody()}
        </div>
      </Link>
    );
  }
  return (
    <ClickAwayListener onClickAway={onClose}>
      <div className={styles.container}>
        {renderTitle()}
        {renderBody()}
      </div>
    </ClickAwayListener>
  );
};

BusinessMappingPiePreview.propTypes = {
  menu: PropTypes.object,
  viewpoint: PropTypes.object.isRequired,
  onClose: PropTypes.func,
  staticMode: PropTypes.bool,
  splitWarning: PropTypes.bool,
};

export default BusinessMappingPiePreview;
