import React, { useEffect, useState } from 'react';
import PageHeader from 'shared/components/PageHeader';
import { PageNames } from 'shared/constants/appConstants';
import { TableHeaderProvider, useTableHeaderContext } from 'shared/components/tableHeader/TableHeaderContext';
import { Container } from 'reactstrap';
import { useRootStore } from 'app/contexts/RootStoreContext';
import Spinner from 'shared/components/andtComponents/Spinner';
import { useInvoiceFilters } from 'invoices/contexts/InvoiceFiltersContext';
import toast from 'shared/components/andtComponents/Toast';
import { CLOUD_TYPE_IDS } from 'users/constants/usersConstants';
import useStateParams from 'shared/hooks/customHooks/useStateParams';
import useBudgets from './hooks/useBudgets';
import BudgetTable from './components/BudgetTable';
import BudgetModal from './components/budgetModal/BudgetModal';

function Budgets() {
  const { getPageFilters } = useInvoiceFilters();
  const { searchText, setSearchText, isCreateMode, setIsCreateMode, isActive, isExpired } = useTableHeaderContext();
  const [isEditMode, setIsEditMode] = useState(false);
  const [stillCreateOrEditMode, setStillCreateOrEditMode] = useState(false);
  const { getUserBudget, deleteBudget, createUserBudget, editBudget } = useBudgets();
  const { data = [], isLoading } = getUserBudget();
  const { refetch: refetchBudgets } = getUserBudget();
  const { mutateAsync: deleteB } = deleteBudget(refetchBudgets);

  const [urlSearch, setUrlSearch] = useStateParams({
    initialState: '',
    paramsName: 'search',
    serialize: (s) => s,
    deserialize: (s) => s,
  });

  useEffect(() => {
    setSearchText(urlSearch);
  }, []);

  useEffect(() => {
    setUrlSearch(searchText);
  }, [searchText]);

  const createOrEditSuccess = (res, editMode) => {
    refetchBudgets().then((result) => {
      if (result) {
        toast.success(`budget ${res?.budget_name} ${editMode ? 'edited' : 'created'} successfully`);
        setIsEditMode(false);
        setIsCreateMode(false);
        setStillCreateOrEditMode(false);
      }
    });
  };

  const editRowSuccess = (result) => {
    setStillCreateOrEditMode(true);
    createOrEditSuccess(result, true);
  };
  const createRowSuccess = (result) => {
    setStillCreateOrEditMode(true);
    createOrEditSuccess(result, false);
  };
  const { mutateAsync: createBudget, isLoading: isCreateLoading } = createUserBudget(createRowSuccess);
  const { mutateAsync: editB, isLoading: isEditLoading } = editBudget(editRowSuccess);
  const [filterData, setFilterData] = useState([]);
  const deleteRow = (budgetId) => deleteB(budgetId);
  const saveBudget = (budget) => createBudget(budget);
  const editRow = (budget) => editB(budget);
  const { usersStore } = useRootStore();
  const fieldToFieldDistincValuesMap = getPageFilters(PageNames.BUDGET, usersStore.currDispUserCloudAccountType);

  const getFilteredData = () =>
    data.filter(
      (d) =>
        [d.budgetName, d.uuid].some((attr) => attr.toUpperCase().includes(searchText.toUpperCase())) &&
        ((isActive && !d.isExpired) || (isExpired && d.isExpired)),
    );
  useEffect(() => {
    setFilterData(getFilteredData());
  }, [searchText, isActive, isExpired]);

  useEffect(() => {
    if (!isLoading) {
      setFilterData(getFilteredData());
    }
  }, [data]);

  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : (
        <>
          <BudgetTable
            rows={filterData}
            allRowCount={data?.length}
            isEditMode={isEditMode}
            setIsEditMode={setIsEditMode}
            deleteRow={deleteRow}
            editRow={editRow}
            duplicateRow={saveBudget}
            totalRows={data?.length}
            isLoading={isEditLoading || isCreateLoading || stillCreateOrEditMode}
            fieldToFieldDistincValuesMap={fieldToFieldDistincValuesMap}
            budgetsNames={data?.map((d) => d.budgetName)}
          />
        </>
      )}
      {isCreateMode && (
        <BudgetModal
          fieldToFieldDistincValuesMap={fieldToFieldDistincValuesMap}
          saveBudget={saveBudget}
          isOpen={isCreateMode}
          setIsOpen={setIsCreateMode}
          isLoading={isEditLoading || isCreateLoading || stillCreateOrEditMode}
          budgetsNames={data?.map((d) => d.budgetName)}
          newBudget={{
            isFlexible: 0,
            excludeFilters:
              usersStore.currDispUserCloudAccountType === CLOUD_TYPE_IDS.AWS ? { chargetype: ['Tax'] } : {},
            includeFilters:
              usersStore.currDispUserCloudAccountType === CLOUD_TYPE_IDS.MULTI
                ? { cloudprovider: ['AWS', 'AZURE', 'GCP'] }
                : {},
          }}
        />
      )}
    </>
  );
}

const Budget = (props) => (
  <TableHeaderProvider>
    <Container>
      <PageHeader showDate title={PageNames.BUDGET} />
      <Budgets {...props} />
    </Container>
  </TableHeaderProvider>
);

export default Budget;
