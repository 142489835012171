import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from 'shared/components/andtComponents/Tooltip';
import { Table } from '@devexpress/dx-react-grid-material-ui';
import styles from './alertRulesTableCell.module.scss';

const propTypes = {
  value: PropTypes.any,
  column: PropTypes.shape({ name: PropTypes.string.isRequired }).isRequired,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};

const AlertRulesTableCell = (props) => {
  const { value = null, column, children = null } = props;
  if (['name'].includes(column.name)) {
    return (
      <Table.Cell {...props}>
        <Tooltip placement="top" title={value} arrow>
          <div className={styles.tableCell}>{children || value}</div>
        </Tooltip>
      </Table.Cell>
    );
  }
  return <Table.Cell {...props} />;
};

AlertRulesTableCell.propTypes = propTypes;
export default AlertRulesTableCell;
