import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { API } from 'shared/utils/apiMiddleware';
import apiConstants from 'shared/api/apiConstants';
import { toast } from 'react-toastify';
import moment from 'moment';

const root = '/api/v1/usage/views';

async function fetchViews() {
  return API.get('billings', root);
}

async function addView({ view, createdBy }) {
  const creationDate = moment().format('YYYY-MM-DD');
  const newView = { ...view, createdBy, creationDate };
  const body = { ...newView };
  return API.post('billings', root, { body });
}

async function updateView({ viewParams, historyView }) {
  const newView = { ...viewParams };
  if (historyView) {
    newView.history = [
      {
        sub_views: historyView.subViews,
        uuid: historyView.uuid,
        last_use_date: moment().add(-1, 'day').format('YYYY-MM-DD'),
      },
      ...(newView.history || []),
    ];
  } else {
    newView.history = [];
  }
  const body = { ...newView };
  return API.put('billings', root, { body });
}

async function deleteView(uuid) {
  const body = { uuid };
  return API.del('billings', root, { body });
}

export const useViews = () => {
  const queryClient = useQueryClient();
  const queryKey = [apiConstants.QUERY_KEYS.VIEWS];
  return {
    reset: () => queryClient.resetQueries(queryKey),
    getViews: () => useQuery({ queryKey, queryFn: fetchViews, retry: false }),
    postView: ({ onError = () => {}, ...mutationSettings } = {}) =>
      useMutation({
        mutationFn: addView,
        onError: () => {
          toast.error('Error on add view');
          onError();
        },
        ...mutationSettings,
      }),
    putView: ({ onError = () => {}, ...mutationSettings } = {}) =>
      useMutation({
        mutationFn: updateView,
        onError: () => {
          toast.error('Error on update view');
          onError();
        },
        ...mutationSettings,
      }),
    delView: ({ onError = () => {}, ...mutationSettings } = {}) =>
      useMutation({
        mutationFn: deleteView,
        onError: () => {
          toast.error('Error on delete view');
          onError();
        },
        ...mutationSettings,
      }),
  };
};
