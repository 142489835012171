import React, {useState, useMemo, useEffect} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Grid, TableHeaderRow } from '@devexpress/dx-react-grid-material-ui';
import {SortingState, IntegratedSorting, DataTypeProvider} from '@devexpress/dx-react-grid';
import moment from 'moment';
import { PANELS, PANELS_TYPES } from 'app/containers/MainDashboard/consts.js';
import {useMainDashboardContext} from 'app/containers/MainDashboard/contexts/mainDashboardContext.jsx';
import {compareFormatterNumber, getFirstDayOfMonth, getSearchParamsPanel} from 'app/containers/MainDashboard/utils.js';
import PercentTag from 'app/containers/MainDashboard/components/PercentTag.jsx';
import useCueData from 'app/containers/MainDashboard/hooks/react-query/useCueData.js';
import Spinner, {SPINNER_SIZES} from 'shared/components/andtComponents/Spinner.jsx';
import TableWrapper from 'shared/components/tables/TableWrapper.jsx';
import useTable from 'shared/hooks/customHooks/useTable.jsx';
import DateFilter from 'shared/modules/dateFilter.js';
import { useUserSettingsContext } from 'users/utils/contexts/UserSettingsContext.jsx';
import InfoPopover from 'shared/components/andtComponents/InfoPopover/index.jsx';
import NavigateIcon from 'app/containers/MainDashboard/components/NavigateIcon.jsx';
import {useRootStore} from 'app/contexts/RootStoreContext.jsx';
import styles from './mtdTop10.module.scss';
import mainStyles from '../mainDashboardCommon.module.scss';
const getColumns = (getCurrencyNumber) => {
    return [  { name: 'service', title: 'Service' },
        { name: 'previousMonthCost', title: 'Previous MTD Costs',  getCellValue: (row) => `${getCurrencyNumber(row.previousMonthCost)}`,
          info: 'Previous month costs reflect the same number of days as MTD for accurate comparison.' },
        { name: 'mtdCost', title: 'MTD Costs', getCellValue: (row) => `${getCurrencyNumber(row.mtdCost)}` },
        { name: 'change',
          title: 'Change (%)',
          getCellValue: (row) => row.previousMonthCost ? (row.previousMonthCost - row.mtdCost) * 100 / row.previousMonthCost : 0 },
    ]
}

const columnExtensions = [
    { columnName: 'service', width: '200px' },
    { columnName: 'previousMonthCost', compare: compareFormatterNumber, width: '150px' },
    { columnName: 'mtdCost', compare: compareFormatterNumber, width: '150px'  },
    { columnName: 'change', width: '100px' },
];

const PercentColumnFormatter = ({ value }) => <PercentTag percent={value * -1} />;

PercentColumnFormatter.propTypes = {
    value: PropTypes.number.isRequired,
};

const HeaderCell = ({ column, ...restProps }) => {
    const { children } = restProps;
        return (
            <TableHeaderRow.Cell
                column={column}
                {...restProps}
            >
                {children}
                {column.info && <InfoPopover isSimple mode="outline" className={{icon: styles.infoIcon}}>
                    {column.info}
                </InfoPopover>}
            </TableHeaderRow.Cell>
        );
};
HeaderCell.propTypes = {
    column: PropTypes.shape({
        title: PropTypes.string.isRequired,
        info: PropTypes.string,
    }).isRequired,
};

const MtdTop10 = () => {
    const { updatePanelLoadingState, dateFromLastMonth, getDynamicFilters, } = useMainDashboardContext();
    const { NewTableWrapper } = useTable();
    const cueHook = useCueData();
    const { appStore } = useRootStore();
    const { getCurrencyNumber } = useUserSettingsContext();
    const { data: mtdData, isLoading: isMtdLoading } = cueHook.fetchCueData({
        ...PANELS[PANELS_TYPES.MTD_TOP_10].params,
        start: getFirstDayOfMonth(moment(DateFilter.getDate())),
        end: moment(DateFilter.getDate()).format('YYYY-MM-DD'),
        ...getDynamicFilters(),
    });
    const { data: previousMtdData, isLoading: isPreviousMtdLoading } = cueHook.fetchCueData( {
        ...PANELS[PANELS_TYPES.MTD_TOP_10].params,
        start: getFirstDayOfMonth(dateFromLastMonth),
        end: dateFromLastMonth.format('YYYY-MM-DD'),
        ...getDynamicFilters(),
    });
    const navigateCueParams = {
        ...PANELS[PANELS_TYPES.MTD_TOP_10].params,
        periodGranLevel: 'day',
        start: getFirstDayOfMonth(dateFromLastMonth),
        end: moment(DateFilter.getDate()).format('YYYY-MM-DD'),
    }
    const isLoading =  isPreviousMtdLoading || isMtdLoading;
    const [sorting, setSorting] = useState([{ columnName: 'mtdCost', direction: 'desc' }]);

    const servicesData = useMemo(() => {
        if (!mtdData || !previousMtdData) {
            return [];
        }
        return mtdData.sort((a, b) => b.total_cost - a.total_cost).slice(0, 10).map((service) => {
            const previousService = previousMtdData.find((prevService) => prevService.service_name === service.service_name);
            return {
                service: service.service_name,
                previousMonthCost: previousService?.total_cost || 0,
                mtdCost: service.total_cost || 0,
            };
        });
    },[previousMtdData, mtdData]);

    useEffect(() => {
      updatePanelLoadingState(PANELS_TYPES.MTD_TOP_10, isLoading);
    }, [isLoading]);

    return (
        <div className={classNames(mainStyles.panelWrapper, mainStyles.fullWidth)}>
            {isLoading ? <Spinner className={mainStyles.spinner} size={SPINNER_SIZES.MEDIUM}/>  :
             (<>
                <div className={mainStyles.headerRow}>
                    <span className={mainStyles.header}>{PANELS[PANELS_TYPES.MTD_TOP_10].title}</span>
                    <NavigateIcon
                        getSearchParams={()=> getSearchParamsPanel(navigateCueParams, appStore.isPpApplied)}
                        id={PANELS_TYPES.MTD_TOP_10} />
                </div>
                <NewTableWrapper>
                   <div className="sub-table">
                     <Grid rows={servicesData}
                           columns={getColumns(getCurrencyNumber)}
                     >
                        <SortingState sorting={sorting}
                                      onSortingChange={setSorting}
                        />
                        <IntegratedSorting columnExtensions={columnExtensions}/>
                        <DataTypeProvider
                           for={['change']}
                           formatterComponent={PercentColumnFormatter}
                        />
                        <TableWrapper virtual height="280px" columnExtensions={columnExtensions}/>
                        <TableHeaderRow showSortingControls cellComponent={HeaderCell}/>
                     </Grid>
                   </div>
               </NewTableWrapper>
             </>)
            }
        </div>
    );
};

export default MtdTop10;
