import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import {
  SelectRoot,
  SelectTrigger,
  SelectValue,
  SelectIcon,
  SelectViewport,
  SelectScrollUpButton,
  SelectContent,
  SelectScrollDownButton,
  GenerateIcon,
  ICONS,
  SelectGroup,
  SelectItem,
  SelectItemText,
  SelectItemIndicator,
} from '@pileus-cloud/anodot-frontend-common';

import useContainerDimensions from '../../hooks/customHooks/useElementSize';
import styles from './SimpleSelect.module.scss';

const SimpleSelect = ({
  label = '',
  options,
  value,
  onChange,
  disabled = false,
  placeholder = 'Select',
  className = '',
  isInvalid = false,
  isClearable = true,
  isPrimarySmall = false,
  renderLabel = null,
  invalidComponent = null,
  automationId,
}) => {
  const ref = useRef();
  const { width } = useContainerDimensions(ref);
  const [key, setKey] = useState(Math.random());
  return (
    <div automation-id={automationId} className={`${styles.container} ${className}`} ref={ref}>
      {label && <p className={styles.label}>{label}</p>}
      <SelectRoot value={value || undefined} disabled={disabled} onValueChange={onChange} key={key}>
        <SelectTrigger
          className={`${styles.trigger} ${isInvalid ? styles.error : ''} ${disabled ? styles.disabled : ''} ${
            isPrimarySmall ? styles.isPrimarySmall : ''
          }`}
        >
          <SelectValue placeholder={placeholder} aria-label={value || undefined} className={styles.value} />
          {!disabled && (
            <SelectIcon>
              {isClearable && value ? (
                <span
                  onClick={(e) => {
                    e.stopPropagation();
                    onChange(null);
                    // https://github.com/radix-ui/primitives/issues/1569
                    setKey(Math.random());
                  }}
                  className="ps-2"
                >
                  <GenerateIcon iconName={ICONS.xMark.name} style={{ pointerEvents: 'all' }} />
                </span>
              ) : (
                <GenerateIcon iconName={ICONS.caretDown.name} />
              )}
            </SelectIcon>
          )}
        </SelectTrigger>
        <SelectContent className={styles.content} position="popper">
          <SelectScrollUpButton>
            <GenerateIcon iconName={ICONS.chevronUp.name} />
          </SelectScrollUpButton>
          <SelectViewport className={styles.viewport} style={{ minWidth: width }}>
            <SelectGroup>
              {options.map((item) => (
                <SelectItem value={item.value} key={item.value}>
                  <SelectItemText>{renderLabel ? renderLabel(item.label) : item.label}</SelectItemText>
                  <SelectItemIndicator>
                    <GenerateIcon iconName={ICONS.check.name} />
                  </SelectItemIndicator>
                </SelectItem>
              ))}
            </SelectGroup>
          </SelectViewport>
          <SelectScrollDownButton>
            <GenerateIcon iconName={ICONS.chevronDown.name} />
          </SelectScrollDownButton>
        </SelectContent>
      </SelectRoot>
      {isInvalid && <span className={styles.error}>{invalidComponent}</span>}
    </div>
  );
};

SimpleSelect.propTypes = {
  label: PropTypes.string,
  className: PropTypes.string,
  options: PropTypes.array.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func.isRequired,
  renderLabel: PropTypes.func,
  placeholder: PropTypes.string,
  isInvalid: PropTypes.bool,
  isClearable: PropTypes.bool,
  disabled: PropTypes.bool,
  isPrimarySmall: PropTypes.bool,
  invalidComponent: PropTypes.node,
  automationId: PropTypes.string,
};

export default SimpleSelect;
