import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { GenerateIcon, ICONS } from '@pileus-cloud/anodot-frontend-common';
import Button from 'shared/components/andtComponents/Button';
import AddSubRolesModal from './AddSubRolesModal';
import { OrganizationEntityCategory, Action } from '@anodot-cost/rbac-client';
import ReadOnlyDisplayWrapper from 'shared/components/ReadOnlyDisplayWrapper';

const AddSubRolesToRole = ({ role }) => {
  const [isOpen, setIsOpen] = useState(false);

  const onClick = (e) => {
    e.stopPropagation();
    setIsOpen(true);
  };

  return (
    <ReadOnlyDisplayWrapper isHide={false} category={OrganizationEntityCategory.Roles} action={Action.Update}>
      <Button
        automationId="addSubRolesToRole"
        icon={() => <GenerateIcon iconName={ICONS.plus.name} />}
        onClick={onClick}
        text="Add Roles"
      />
      {isOpen && <AddSubRolesModal isOpen={isOpen} setIsOpen={setIsOpen} role={role} />}
    </ReadOnlyDisplayWrapper>
  );
};

AddSubRolesToRole.propTypes = {
  role: PropTypes.string.isRequired,
};

export default AddSubRolesToRole;
