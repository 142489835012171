import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { debounce, uniqBy } from 'lodash';
import { ICONS, GenerateIcon } from '@pileus-cloud/anodot-frontend-common';
import { Chip, CircularProgress, TextField } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import LabelCoordinator from 'shared/modules/labelCoordinator';
import { modifiedPurchaseOptionToDisplay, modifyRegionNameToDisplay } from 'shared/utils/awsUtils';
import { OPERATORS_KEYS } from 'shared/constants/appConstants';
import { AwsCommonFields } from 'shared/constants/awsConstants';
import Input from 'shared/components/andtComponents/Input';
import Checkbox from 'shared/components/andtComponents/Checkbox';
import { ALL_TAGS_FIELD, SELECT_ALL_FIELD } from '../../constants/businessMappingConstants';
import styles from './FilterSelector.module.scss';

const debouncedLoader = debounce((str, onLoad, tagKey) => onLoad(str, tagKey), 1500);

const filterOptionsByInput = (allOptions, inputValue) => {
  if (!inputValue) {
    return allOptions;
  }
  const lowerCaseValue = inputValue.toLowerCase();
  return allOptions.filter(
    ({ value, label }) =>
      value.toLowerCase().includes(lowerCaseValue) ||
      label.toLowerCase().includes(lowerCaseValue) ||
      value === SELECT_ALL_FIELD,
  );
};

const selectAllChecked = (options, selected) => options.every((opt) => selected.some((s) => s.value === opt.value));

const ValueSelect = ({
  value: { operator, field, values, prefix, ruleId, index },
  filtersList,
  filterProps,
  handleChange,
  currDispUserCloudAccountType,
}) => {
  const {
    onLoad,
    isLoading,
    values: asyncOptions,
    isAsyncLoad,
    isAsyncLoadClick,
    isAutoComplete,
    subMenu,
  } = filterProps[field] || {};
  const [inputValue, setInputValue] = useState('');
  useEffect(() => {
    if (prefix === ALL_TAGS_FIELD) {
      return;
    }
    if (isAsyncLoad && field) {
      onLoad();
    }
    if (subMenu && field && prefix) {
      onLoad('', prefix);
    }
  }, [field, prefix]);
  const options = isAsyncLoad || isAutoComplete || isAsyncLoadClick ? asyncOptions || [] : filtersList.get(field);
  if (!field || !options) {
    return null;
  }
  const isSelect = [OPERATORS_KEYS.IS, OPERATORS_KEYS.IS_NOT].includes(operator);
  const formattedOptions = options.map((fieldValue) => {
    let value = '';
    let label = '';
    if (typeof fieldValue === 'object' && 'linkedAccountId' in fieldValue) {
      value = fieldValue.linkedAccountId;
      label = fieldValue.displayLabel;
    } else if (field === 'virtualcustomtags') {
      value = fieldValue;
      label = fieldValue;
    } else if (field === 'purchaseoption') {
      value = fieldValue;
      label = modifiedPurchaseOptionToDisplay(fieldValue);
    } else if (field === 'division') {
      value = fieldValue.divisionName;
      label = fieldValue.divisionNameDisplay;
    } else if (field === AwsCommonFields.SERVICE) {
      value = fieldValue;
      label = LabelCoordinator.getDataKeyDisplayName('cueDisplayCoordinator', fieldValue);
    } else {
      value = fieldValue;
      label = modifyRegionNameToDisplay(field, fieldValue);
    }
    if (subMenu && field !== 'virtualcustomtags') {
      [, label] = label.split(': ');
      [, value] = value.split(': ');
    }
    return { value, label };
  });
  const inputLabel = subMenu ? 'Value' : LabelCoordinator.getFieldLabelByCloudType(field, currDispUserCloudAccountType);
  if (!isSelect) {
    return (
      <Input
        value={values}
        placeholder={inputLabel}
        className={styles.input}
        onChange={(e) =>
          handleChange({
            values: e.target.value,
            operator,
            field,
            prefix,
            ruleId,
            index,
          })
        }
      />
    );
  }
  const currentFiltered = filterOptionsByInput(formattedOptions, inputValue);
  return (
    <Autocomplete
      fullWidth
      openOnFocus
      inputValue={inputValue}
      onInputChange={(e, value, reason) => {
        if (reason === 'reset') {
          return;
        }
        setInputValue(value);
      }}
      disableCloseOnSelect
      isOptionEqualToValue={(a, b) => a.value === b.value}
      size="small"
      getOptionLabel={(option) => option.label}
      classes={{
        option: styles.menuOption,
      }}
      disableClearable
      multiple
      renderTags={() => null}
      popupIcon={<GenerateIcon iconName={ICONS.caretDown.name} />}
      loading={isLoading}
      filterOptions={(allOptions, state) => filterOptionsByInput(allOptions, state.inputValue)}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder={`${inputLabel}${isAsyncLoadClick || isAutoComplete ? ' (type to search)' : ''}`}
          onChange={(e) => {
            e.stopPropagation();
            const { value: str } = e.target;
            if (isAutoComplete || isAsyncLoadClick) {
              debouncedLoader(str, onLoad, prefix);
            }
          }}
          className={styles.autocompleteInput}
          variant="filled"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {values.length > 0 && (
                  <Chip
                    className={styles.chipComponent}
                    label={`${values.length} selected`}
                    onDelete={() =>
                      handleChange({
                        values: [],
                        operator,
                        field,
                        prefix,
                        ruleId,
                        index,
                      })
                    }
                  />
                )}
                {isLoading ? <CircularProgress color="inherit" size={20} className={styles.loader} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
      SelectProps={{
        MenuProps: {
          style: {
            maxHeight: 400,
          },
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          getContentAnchorEl: null,
        },
      }}
      value={values}
      onChange={(e, newVal) => {
        let newValsArr = newVal;
        if (newVal.some((newValue) => newValue.value === SELECT_ALL_FIELD)) {
          if (
            selectAllChecked(
              currentFiltered,
              newVal.filter((v) => v.value !== SELECT_ALL_FIELD),
            )
          ) {
            newValsArr = values.filter((v) => !currentFiltered.some((value) => value.value === v.value));
          } else {
            newValsArr = uniqBy(
              [...values, ...currentFiltered].filter((v) => v.value !== SELECT_ALL_FIELD),
              (v) => v.value,
            );
          }
        }
        e.stopPropagation();
        handleChange({
          values: newValsArr,
          operator,
          field,
          prefix,
          ruleId,
          index,
        });
      }}
      renderOption={(props, option) => (
        <span key={option.value} {...props}>
          <Checkbox
            className={styles.checkbox}
            isChecked={
              values.some((val) => val.value === option.value) ||
              (option.value === SELECT_ALL_FIELD && selectAllChecked(currentFiltered, values))
            }
            primary
            text={option.label}
            noPaddings
          />
        </span>
      )}
      options={[
        {
          label: 'Select All',
          value: SELECT_ALL_FIELD,
        },
        ...formattedOptions.sort((a, b) => {
          if (a.label === 'no_tag') {
            return -1;
          }
          if (b.label === 'no_tag') {
            return 1;
          }
          return a.label.localeCompare(b.label);
        }),
      ]}
    />
  );
};

ValueSelect.propTypes = {
  currDispUserCloudAccountType: PropTypes.number.isRequired,
  value: PropTypes.shape({
    values: PropTypes.array.isRequired,
    field: PropTypes.string.isRequired,
    operator: PropTypes.string.isRequired,
    prefix: PropTypes.string,
    ruleId: PropTypes.number,
    index: PropTypes.number,
  }).isRequired,
  filtersList: PropTypes.object.isRequired,
  filterProps: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
};

export default ValueSelect;
