import { useMutation } from '@tanstack/react-query';
import { API } from 'shared/utils/apiMiddleware';
import { BILLINGS_API_NAME, USER_MANAGEMENT_ORGANIZATIONS } from './helperFunctions';

export async function onboardOrganizationApi(data) {
  try {
    return API.post(BILLINGS_API_NAME, USER_MANAGEMENT_ORGANIZATIONS, { body: data });
  } catch (error) {
    return Promise.reject(error);
  }
}

export default function useOrganization() {
  return {
    onboardOrganization: useMutation((data) => onboardOrganizationApi(data)),
  };
}
