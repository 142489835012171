import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import Sidebar from './sidebar/Sidebar';
import TopNavbar from './topNavbar/TopNavbar';

const Layout = ({ childProps }) => {
  return (
    <div className="layout">
      <TopNavbar childProps={childProps} currDisplayedAccount={childProps?.usersStore.getCurrDisplayedAccount} />
      {!childProps?.usersStore.isCurrentUserNew && <Sidebar childProps={childProps} />}
    </div>
  );
};

Layout.propTypes = {
  childProps: PropTypes.object.isRequired,
};

export default observer(Layout);
