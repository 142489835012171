import React from 'react';
import AnodotReactSelect from 'shared/components/AnodotReactSelect';
import { getCostBasisOptions } from 'shared/utils/costUtils';
import { FlatObject } from 'shared/types/commonTypes.ts';
import Select from 'shared/components/andtComponents/SimpleSelect';
import { useRootStore } from 'app/contexts/RootStoreContext';
import ColoredBlock from 'shared/components/ColoredFormBlock';
// @ts-ignore
import styles from '../alerts.module.scss';
import t from '../texts.ts';
import { createOptions } from '../helpers.ts';
import {
  alertsGranLevelDisplayedLabels,
  alertsThresholdGranLevelDescriptions,
  alertsThresholdGranLevelDisplayedLabels,
  alertsTypeDescriptions,
  alertsTypeDisplayedLabels,
  CAU_ALERTS_GRAN_LEVELS,
  getAlertsLevelDescriptions,
  getAlertsLevelDisplayedLabels,
  weekDaysDisplayedLabels,
} from '../../../constants/usageConstants';
import { AlertCostEnum, AlertTypesEnum } from '../types.ts';
import { FormServiceCostProps } from './FormServiceCost.tsx';
import { CLOUD_TYPE_IDS } from '../../../../users/constants/usersConstants';

interface Props {
  automationPostfix: string;
}

const AlertTypeFormBlock: React.FC<Props & FormServiceCostProps> = ({
  showErrors,
  setAlert,
  alert,
  disabled,
  automationPostfix,
}) => {
  const { usersStore } = useRootStore();
  const cloudTypeId = usersStore.currDispUserCloudAccountType;
  const alertsTypesOptions = createOptions(alertsTypeDisplayedLabels, alertsTypeDescriptions);
  const isTrendType = String(alert.alertType) === String(AlertTypesEnum.COST_CHANGES);
  const alertCostOptions = getCostBasisOptions({
    cloudType: usersStore.currDispUserCloudAccountType,
    isCurrentUserSharedReCustomer: usersStore.isCurrentUserSharedReCustomer,
    isCurrentUserDedicatedReCustomer: usersStore.isCurrentUserDedicatedReCustomer,
  })?.map((d = {} as FlatObject) => ({ value: d.value, label: d.label }));

  return (
    <ColoredBlock className="mb-3" icon="arrowsTurnToDots" color="lilach">
      <div className={styles.twoColumns}>
        <div className="alerts-left-column">
          <div automation-id={`alert-type-field-wrapper${automationPostfix}`}>
            <h5>{t('ALERT_TYPE')}</h5>
            <AnodotReactSelect
              className={styles.select}
              automationId={`alert-type-field${automationPostfix}`}
              options={alertsTypesOptions}
              value={String(alert.alertType)}
              onChange={(selected) => {
                setAlert?.('alertType', selected);
              }}
              disabled={disabled}
              invalid={showErrors && !alert.alertType}
            />
          </div>
          <div style={{ marginTop: 10 }} automation-id={`alert-level-field-wrapper${automationPostfix}`}>
            <h5>{t('ALERT_LEVEL')}</h5>
            <AnodotReactSelect
              className={styles.select}
              automationId={`alert-level-field${automationPostfix}`}
              options={createOptions(
                getAlertsLevelDisplayedLabels(cloudTypeId),
                getAlertsLevelDescriptions(cloudTypeId),
              )}
              value={alert.alertLevel || null}
              onChange={(selected) => {
                setAlert?.('alertLevel', selected);
              }}
              disabled={disabled}
              invalid={showErrors && !alert.alertLevel}
            />
          </div>
          {isTrendType && Number(alert.granularity?.value) === CAU_ALERTS_GRAN_LEVELS.DAY_IN_WEEK && (
            <div style={{ marginTop: 10 }}>
              <h5>{t('DAY')}</h5>
              <Select
                className={styles.select}
                automationId={`day-of-week-field${automationPostfix}`}
                options={createOptions(weekDaysDisplayedLabels)}
                value={alert.dayInWeek?.value}
                onChange={(selected) => {
                  setAlert?.('dayInWeek', selected);
                }}
                placeholder="Select"
                disabled={disabled}
                isClearable={false}
              />
            </div>
          )}
        </div>
        <div className="alerts-right-column">
          <div automation-id={`comparing-rule-field-wrapper${automationPostfix}`}>
            <h5>{t(isTrendType ? 'COMPARING_RULE' : 'ALERT_GRANULARITY')}</h5>
            <AnodotReactSelect
              className={styles.select}
              automationId={`comparing-rule-field${automationPostfix}`}
              options={createOptions(
                isTrendType ? alertsGranLevelDisplayedLabels : alertsThresholdGranLevelDisplayedLabels,
                isTrendType ? undefined : alertsThresholdGranLevelDescriptions,
              )}
              value={alert.granularity?.value}
              onChange={(selected) => {
                setAlert?.('granularity', selected);
              }}
              placeholder="Select"
              disabled={disabled}
            />
          </div>
          {cloudTypeId !== CLOUD_TYPE_IDS.AZURE || usersStore.getIsCurrentAccountAzureFocus ? (
            <div style={{ marginTop: 10 }} automation-id={`alert-cost-field-wrapper${automationPostfix}`}>
              <h5>{t('COST')}</h5>
              <AnodotReactSelect
                className={styles.select}
                automationId={`alert-cost-field${automationPostfix}`}
                options={alertCostOptions}
                value={alert.costType || AlertCostEnum.UNBLENDED}
                onChange={(selected) => {
                  setAlert?.('costType', selected);
                }}
                placeholder="Select"
                disabled={disabled}
              />
            </div>
          ) : null}
        </div>
      </div>
    </ColoredBlock>
  );
};

export default AlertTypeFormBlock;
