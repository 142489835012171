import React, { useState } from 'react';
import PropTypes from 'prop-types';
import InformationIcon from 'mdi-react/InformationIcon';
import { UncontrolledTooltip } from 'reactstrap';
import PencilIcon from 'shared/img/icons/PencilIcon';
import Button from 'shared/components/andtComponents/Button';
import Input from 'shared/components/andtComponents/Input';
import { useUserSettingsContext } from 'users/utils/contexts/UserSettingsContext';

const ContentInfo = ({
  isEditable = false,
  small = false,
  value,
  id,
  title,
  isInfo = false,
  infoValue,
  isCost = false,
  editFunc,
}) => {
  const [isCurrEdit, setIsCurrEdit] = useState(false);
  const [currValue, setCurrValue] = useState(value);
  const { getCurrencyNumber } = useUserSettingsContext();

  const toggleEnableEdit = () => {
    // when closing and value chenged - call editFunc
    if (isCurrEdit && currValue !== value) {
      editFunc(currValue);
    }
    // close edit mode
    setIsCurrEdit(!isCurrEdit);
  };

  const renderTextInput = () => (
    <div>
      <Input
        value={currValue}
        onChange={(e) => setCurrValue(e.target.value)}
        type="number"
        automationId="edit-hourly-commitment"
      />
    </div>
  );

  return (
    <div className="savigns-plans__content-info-container" style={{ maxWidth: '300px' }}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <h5 className="savigns-plans__content-info-header">
          {title}
          {isInfo ? (
            <span>
              <InformationIcon size={16} />
            </span>
          ) : null}
        </h5>
        {isEditable ? (
          <div onClick={toggleEnableEdit} className="savigns-plans__toggle-edit">
            {isCurrEdit ? (
              <Button text="Calculate" isTextButton onClick={() => {}} />
            ) : (
              <PencilIcon onClick={() => []} />
            )}
          </div>
        ) : null}
      </div>
      {isCurrEdit ? (
        renderTextInput()
      ) : (
        <h3 className={`savigns-plans__content-info-value ${small ? 'small' : ''}`}>
          {isCost ? `${getCurrencyNumber(value, 3)}` : value}
        </h3>
      )}
      {isInfo ? (
        <UncontrolledTooltip placement="top" target={`TooltipTop${id}`}>
          {infoValue}
        </UncontrolledTooltip>
      ) : null}
    </div>
  );
};

export default ContentInfo;

ContentInfo.propTypes = {
  title: PropTypes.number.isRequired,
  value: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  infoValue: PropTypes.string.isRequired,
  isInfo: PropTypes.bool,
  small: PropTypes.bool,
  isEditable: PropTypes.bool,
  editFunc: PropTypes.func.isRequired,
  isCost: PropTypes.bool,
};
