import React from 'react';
import { useNavigate } from 'react-router-dom';
import { GenerateIcon, ICONS } from '@pileus-cloud/anodot-frontend-common';

import CustomModal from 'shared/components/andtComponents/Modal';
import Button from 'shared/components/andtComponents/Button';
import { Routes } from 'shared/constants/routes';
import styles from './ValidateModal.module.scss';
import ValidationGif from '../../assets/validation.gif';

const ValidateModal = ({ children = null, subtitle = '', noteTitle, title, text, checkbox = null }) => {
  const navigate = useNavigate();

  return (
    <CustomModal
      open
      onClose={() => {}}
      overrideStyles={{
        width: 560,
      }}
      className={{
        title: styles.loadingModalTitle,
      }}
      closeOnOutside={false}
      title={title || 'We are currently validating your account details'}
      footerDisabled
    >
      <div className={styles.loadingModal}>
        {subtitle}
        {!children && <img src={ValidationGif} alt="validation" />}
        {children}
        <div className={styles.checkboxBox}>
          <p>
            <span>{noteTitle || 'This step can take up to 1 hour'}</span>
            <br />
            {text || 'You can close this window, and we`ll email you when the validation process is done.'}
          </p>
          {checkbox}
        </div>
        <Button
          text="Go back to My Cloud Accounts"
          icon={() => <GenerateIcon iconName={ICONS.chevronLeft.name} />}
          onClick={() => {
            navigate(Routes.ACCOUNT);
          }}
        />
      </div>
    </CustomModal>
  );
};

export default ValidateModal;
