import React, { useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import CustomModal, { MODAL_MODES } from 'shared/components/andtComponents/Modal';
import useRoles from 'users/new-user-management/hooks/reactQuery/useRoles';
import useDataAccessAccounts from 'users/new-user-management/hooks/reactQuery/useDataAccessAccounts';
import {
  getCostCentersDataAccessCombinedData,
  mergeCostCentersDataWithDataAccess,
} from 'users/containers/Organization/CostCenters/costCentersHelperFunctions';
import CostCenters from 'users/containers/Organization/CostCenters/CostCenters';
import Spinner from 'shared/components/andtComponents/Spinner';

import styles from './AssignCostCentersToRoleModal.module.scss';
import { DATA_ACCESS_TOOLTIPS } from '~/users/containers/Organization/consts.js';

const AssignCostCentersToRoleModal = ({ isOpen, setIsOpen, roleId }) => {
  const [saveClicked] = useState(false);
  const [modifiedSelectionById, setModifiedSelectionById] = useState({});
  const initialDataAccessData = useRef(undefined);

  const { fetchDataAccessCostCenters } = useDataAccessAccounts();
  const { data: costCentersData, isLoading: isCostCentersDataAccessLoading } = fetchDataAccessCostCenters();

  const { fetchRoleDataAccess, updateCostCentersAccessibilityForRole } = useRoles();
  const { data: roleDataAccess, isLoading: isRoleDataAccessLoading } = fetchRoleDataAccess(roleId);

  const combinedData = useMemo(() => {
    if (!isCostCentersDataAccessLoading && !isRoleDataAccessLoading) {
      const data = getCostCentersDataAccessCombinedData(costCentersData, roleDataAccess, roleId, modifiedSelectionById);
      if (initialDataAccessData?.current === undefined) {
        initialDataAccessData.current = data;
      }

      return mergeCostCentersDataWithDataAccess(costCentersData, data);
    }
    return undefined;
  }, [
    costCentersData,
    isCostCentersDataAccessLoading,
    isRoleDataAccessLoading,
    modifiedSelectionById,
    roleDataAccess,
    roleId,
  ]);

  const handleCostCentersSelectionChange = ({ costCenterId, newSelected }) => {
    const tmpModified = { ...modifiedSelectionById };

    if (tmpModified[costCenterId] === undefined) {
      tmpModified[costCenterId] = newSelected;
    } else {
      delete tmpModified[costCenterId];
    }
    setModifiedSelectionById(tmpModified);
  };

  const onSave = async () => {
    const payload = {
      itemsToAdd: [],
      itemsToRemove: [],
    };
    Object.keys(modifiedSelectionById).forEach((costCenterId) => {
      const costCenter = initialDataAccessData.current.find((cc) => cc.costCenter.id === costCenterId);
      if (costCenter?.isAllowed === true && modifiedSelectionById[costCenterId] !== true) {
        payload.itemsToRemove.push(costCenterId);
      } else if (costCenter?.isAllowed !== true && modifiedSelectionById[costCenterId] === true) {
        payload.itemsToAdd.push(costCenterId);
      }
    });
    await updateCostCentersAccessibilityForRole.mutateAsync({
      roleId: roleId,
      roleDataAccess: payload,
    });
    setIsOpen(false);
  };

  return (
    <CustomModal
      className={styles}
      comment={DATA_ACCESS_TOOLTIPS.COST_CENTERS_TOOLTIP}
      onClose={() => {
        setIsOpen(false);
      }}
      onCloseClick={() => {
        setIsOpen(false);
      }}
      headerMode={MODAL_MODES.EDIT}
      footerDisabled={isRoleDataAccessLoading && isCostCentersDataAccessLoading && saveClicked}
      onSave={onSave}
      open={isOpen}
      automationId="assign-cost-center-modal"
      saveTitle="Save"
      saveDisabled={false}
      title="Edit Role Cost Centers"
    >
      <>
        {isRoleDataAccessLoading || isCostCentersDataAccessLoading ? (
          <div className="d-flex justify-content-center">
            <Spinner />
          </div>
        ) : (
          <CostCenters
            isRoleCostCentersDataAccessView={true}
            isSelectionEnabled={true}
            modifiedSelectionById={modifiedSelectionById}
            onSelectionChange={handleCostCentersSelectionChange}
            roleCostCentersDataAccess={combinedData}
            roleId={roleId}
          />
        )}
      </>
    </CustomModal>
  );
};

AssignCostCentersToRoleModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  roleId: PropTypes.string.isRequired,
  setIsOpen: PropTypes.func.isRequired,
};

export default AssignCostCentersToRoleModal;
