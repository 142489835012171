import React, {useEffect} from 'react';
import {PANELS, PANELS_TYPES} from 'app/containers/MainDashboard/consts.js';
import UsageCost from '../components/UsageCost.jsx';
import Spinner, { SPINNER_SIZES } from 'shared/components/andtComponents/Spinner.jsx';
import {useMainDashboardContext} from 'app/containers/MainDashboard/contexts/mainDashboardContext.jsx';
import useForecastData from 'app/containers/MainDashboard/hooks/react-query/useForecastData.js';
import styles from 'app/containers/MainDashboard/panels/costPanels.module.scss';

const ForecastedCost = () => {
    const { updatePanelLoadingState } = useMainDashboardContext();
    const forecastHook = useForecastData();
    const { data: forecastData, isLoading } = forecastHook.fetchForecastData();

    useEffect(() => {
        updatePanelLoadingState(PANELS_TYPES.FORECASTED_MONTHLY_COST, isLoading);
    },[isLoading]);

    return (
        <div className={styles.costPanel}>
            {isLoading ? <Spinner className={styles.spinner} size={SPINNER_SIZES.MEDIUM}/> :
                (<UsageCost
                    title={PANELS[PANELS_TYPES.FORECASTED_MONTHLY_COST].title}
                    value={forecastData?.forecastTotal}
                    infoValue={PANELS[PANELS_TYPES.FORECASTED_MONTHLY_COST].info}
                    showPercent
                    percent={forecastData?.forecastPercent}
                />)}
        </div>);
};
export default ForecastedCost;
