import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { capitalize, cloneDeep } from 'lodash';
import { GenerateIcon, ICONS } from '@pileus-cloud/anodot-frontend-common';
import {
  DataTypeProvider,
  IntegratedPaging,
  IntegratedSorting,
  PagingState,
  SortingState,
} from '@devexpress/dx-react-grid';
import { Grid, PagingPanel, TableColumnResizing, TableHeaderRow } from '@devexpress/dx-react-grid-material-ui';
import { getZeroPadAccountId } from 'shared/utils/awsUtils';
import Spinner from 'shared/components/andtComponents/Spinner';
import TableWrapper from 'shared/components/tables/TableWrapper';
import toast from 'shared/components/andtComponents/Toast';
import Button from 'shared/components/andtComponents/Button';
import Chip from 'shared/components/andtComponents/Chip';
import TableHeader from 'shared/components/tableHeader/TableHeader';
import { useTableHeaderContext } from 'shared/components/tableHeader/TableHeaderContext';
import linkedAccountsProvider from 'shared/hooks/react-query/useLinkedAccounts';
import { CLOUD_TYPE_IDS } from 'users/constants/usersConstants';
import useTable from 'shared/hooks/customHooks/useTable';
import styles from './LinkedAccounts.module.scss';
import EditLinkedAccountNameModal from './EditLinkedAccountNameModal';

const CLOUD_CONFIG = {
  [CLOUD_TYPE_IDS.AWS]: {
    title: 'Connected Linked Account',
    infoTitle: 'linked account',
    idTitle: 'Linked Account ID',
    nameTitle: 'Linked Account Name',
    statusTitle: 'Verification Status',
    csvFileName: 'linked_accounts.csv',
  },
  [CLOUD_TYPE_IDS.AZURE]: {
    title: 'Connected Subscription',
    infoTitle: 'subscription',
    idTitle: 'Subscription ID',
    nameTitle: 'Subscription Name',
    statusTitle: 'Status',
    csvFileName: 'subscription.csv',
  },
  [CLOUD_TYPE_IDS.GCP]: {
    title: 'Connected Project',
    infoTitle: 'project',
    idTitle: 'Project Number',
    nameTitle: 'Project ID',
    statusTitle: 'Status',
    csvFileName: 'project.csv',
  },
};

const LinkedAccounts = ({ usersStore, linkedAccounts = null, onboardingMode = false }) => {
  const { searchText } = useTableHeaderContext();
  const [isUpdating, setIsUpdating] = useState(false);
  const [editLinkedAccount, setEditLinkedAccount] = useState(null);
  const newRows = linkedAccounts || usersStore.usersModel.userLinkedAccounts;
  const { updateLinkedAccountName } = linkedAccountsProvider();
  const handleUpdateLinkedAccountName = updateLinkedAccountName();
  const { connectedLinkedAcc, totalLinkedAcc } = usersStore.usersModel.getNumOfConnectedLinkedAccounts(newRows);
  const cloudTypeId = usersStore.currDispUserCloudAccountType;
  const { NewTableWrapper } = useTable();
  const filteredData = useMemo(
    () =>
      (newRows || []).filter((row) => {
        if (!searchText) {
          return true;
        }
        const searchLowerCase = searchText.toLowerCase();
        return (
          row.linkedAccountName?.toLowerCase().includes(searchLowerCase) ||
          row.customerName?.toLowerCase().includes(searchLowerCase) ||
          row.linkedAccountId?.toLowerCase().includes(searchLowerCase)
        );
      }),
    [searchText, newRows],
  );

  const handleConnectLinkedAccountsBulk = async (linkedAccounts) => {
    setIsUpdating(true);
    const result = await usersStore.updateLinkedAccounts(linkedAccounts);
    Object.keys(result || {}).forEach((linkedAccountId) => {
      if (!result[linkedAccountId].isVerified) {
        toast.error(`${linkedAccountId}: ${result[linkedAccountId].message}`, { autoClose: false });
      } else {
        toast.success(`${linkedAccountId}: validated successfully`, { autoClose: false });
      }
    });
    setIsUpdating(false);
  };

  const prepareToConnectLinkedAccount = async (linkedAccountId, arnRoleNum, externalId, accountId) => {
    if (!linkedAccountId || !arnRoleNum) {
      return;
    }
    await handleConnectLinkedAccountsBulk([{ linkedAccountId, linkedAccountArn: arnRoleNum, externalId, accountId }]);
  };

  const getColor = (isVerified) => {
    if (!isVerified) {
      return 'full-red';
    }
    return 'full-green';
  };

  const prepareRoleArn = (linkedAccountId) => {
    const fixedLinkedAccountId = getZeroPadAccountId(linkedAccountId);
    const pileusRole = `arn:aws:iam::${fixedLinkedAccountId}:role/PileusRole`;
    return pileusRole;
  };

  const verificationStatus = (data) => {
    return <Chip color={getColor(data.row.isVerified)} label={data.row.isVerified ? 'Connected' : 'Not Connected'} />;
  };

  if (!newRows || isUpdating) {
    return <Spinner />;
  }

  const cloudConfig = CLOUD_CONFIG[cloudTypeId];
  const isAWS = cloudTypeId === CLOUD_TYPE_IDS.AWS;
  const isReseller = usersStore.isCurrentUserReseller;
  if (!cloudConfig) {
    return null;
  }
  const columns = [
    ...(isReseller
      ? [{ name: 'customerName', title: 'Customer Name', getCellValue: (row) => row.customerName || '-' }]
      : []),
    { name: 'linkedAccountId', title: cloudConfig.idTitle },
    {
      name: 'linkedAccountName',
      title: cloudConfig.nameTitle,
      getCellValue: (row) => (
        <>
          {row.linkedAccountName}
          {row.allowUpdates === false ? <Chip className={styles.editedChip} label="edited" /> : ''}
        </>
      ),
    },
    {
      name: 'isVerified',
      title: cloudConfig.statusTitle,
      getCellValue: (row) => row.isVerified,
    },
    ...(!isAWS
      ? []
      : [
          {
            name: 'connect',
            title: ' ',
            getCellValue: (row) =>
              !row.isVerified ? (
                <Button
                  isTextButton
                  overrideStyles={{
                    marginLeft: -8,
                  }}
                  icon={() => <GenerateIcon iconName={ICONS.arrowUpRightFromSquare.name} />}
                  text="Open AWS platform to connect"
                  onClick={() => {
                    window.open(
                      /* eslint-disable-next-line max-len */
                      `https://console.aws.amazon.com/cloudformation/home?region=us-east-1#/stacks/quickcreate?stackName=PileusOnBoarding&templateURL=https://pileus-cloudformation-public.s3.amazonaws.com/PileuseOnboardingCFT.json&param_ExternalId=${row.externalId}`,
                      '_blank',
                    );
                  }}
                />
              ) : null,
          },
          ...(onboardingMode
            ? []
            : [
                {
                  name: 'refresh',
                  title: ' ',
                  getCellValue: (row) =>
                    !row.isVerified ? (
                      <Button
                        text="Validate Connection"
                        icon={() => <GenerateIcon iconName={ICONS.refresh.name} />}
                        onClick={() =>
                          prepareToConnectLinkedAccount(
                            row.linkedAccountId,
                            prepareRoleArn(row.linkedAccountId),
                            row.externalId,
                            row.accountId,
                          )
                        }
                      />
                    ) : null,
                },
              ]),
        ]),
    {
      name: 'action',
      title: ' ',
      getCellValue: (row) => (
        <Button
          isTextButton
          text=""
          icon={() => <GenerateIcon iconName={ICONS.edit.name} />}
          onClick={() => setEditLinkedAccount(row)}
        />
      ),
    },
  ];

  const csvModifiedRows = cloneDeep(filteredData).map((row) => {
    if (isAWS) {
      const currRow = row;
      delete currRow.isConnectMeSent;
      delete currRow.id;
      return currRow;
    }
    const { linkedAccountId, linkedAccountName, isVerified, customerName } = row;
    const csvRow = {
      [cloudConfig.idTitle]: linkedAccountId,
      [cloudConfig.nameTitle]: linkedAccountName,
      [cloudConfig.statusTitle]: capitalize(`${!!isVerified}`),
    };
    if (isReseller) {
      csvRow['Customer Name'] = customerName;
    }
    return csvRow;
  });

  const columnsWidth = [
    ...(isReseller ? [{ columnName: 'customerName', width: '170px' }] : []),
    { columnName: 'linkedAccountId', width: '15%' },
    { columnName: 'linkedAccountName', width: '20%' },
    {
      columnName: 'isVerified',
      width: '10%',
    },
    {
      columnName: 'connect',
      width: '15%',
      sortingEnabled: false,
    },
    ...(onboardingMode
      ? []
      : [
          {
            columnName: 'refresh',
            width: '20%',
            sortingEnabled: false,
          },
        ]),
    {
      columnName: 'action',
      align: 'right',
      width: 'auto',
      sortingEnabled: false,
    },
  ];
  return (
    <div className={styles.container}>
      <EditLinkedAccountNameModal
        open={!!editLinkedAccount}
        title={cloudConfig.infoTitle}
        linkedAccountName={editLinkedAccount?.linkedAccountName}
        onClose={() => setEditLinkedAccount(null)}
        onSave={async (name) => {
          await handleUpdateLinkedAccountName.mutateAsync({
            name,
            id: editLinkedAccount.linkedAccountId,
          });
        }}
      />
      <TableHeader
        tableName={cloudConfig.title}
        filteredRows={connectedLinkedAcc}
        totalRows={totalLinkedAcc}
        isCreatable={false}
        csvData={{ data: csvModifiedRows, filename: cloudConfig.csvFileName }}
        infoTooltip={`Connect your ${cloudConfig.infoTitle}s in order to receive recommendations for them.`}
        actionButton={
          !isAWS ? null : (
            <div className={styles.validateAllContainer}>
              <Button
                text="Validate All Connections"
                isTextButton
                onClick={() =>
                  handleConnectLinkedAccountsBulk(
                    filteredData.map((ln) => ({
                      linkedAccountId: ln.linkedAccountId,
                      linkedAccountArn: prepareRoleArn(ln.linkedAccountId),
                      externalId: ln.externalId,
                      accountId: ln.accountId,
                    })),
                  )
                }
                icon={() => <GenerateIcon iconName={ICONS.refresh.name} />}
              />
            </div>
          )
        }
      />
      <NewTableWrapper isCompact>
        <Grid rows={filteredData} columns={columns}>
          <SortingState columnExtensions={columnsWidth} />
          <IntegratedSorting />
          <PagingState defaultCurrentPage={0} defaultPageSize={onboardingMode ? 5 : 10} />
          <IntegratedPaging />
          <DataTypeProvider for={['isVerified']} formatterComponent={verificationStatus} />
          <TableWrapper columnExtensions={columnsWidth} />
          <TableColumnResizing columnWidths={columnsWidth} resizingMode="nextColumn" />
          <TableHeaderRow showSortingControls />
          <PagingPanel pageSizes={[5, 10, 15]} />
        </Grid>
      </NewTableWrapper>
    </div>
  );
};

LinkedAccounts.propTypes = {
  usersStore: PropTypes.object.isRequired,
  linkedAccounts: PropTypes.array,
  onboardingMode: PropTypes.bool,
};

export default observer(LinkedAccounts);
