import { useMutation } from '@tanstack/react-query';
import { API } from 'shared/utils/apiMiddleware';
import { sanitizeQuery } from 'shared/utils/apiUtil';
import { buildStartAndEndDate } from 'shared/utils/dateUtil';

const getResourceDetails = (resourceField, resourceValue, rawStartDate, rawEndDate) => {
  const { startDate, endDate } = buildStartAndEndDate(rawStartDate, rawEndDate);
  return API.get(
    'billings',
    // eslint-disable-next-line max-len
    `/api/v1/usage/resource-explorer/resource-details?resourceField=${resourceField}&resourceValue=${resourceValue}&startDate=${startDate}&endDate=${endDate}`,
  );
};

const getResourceOperationsData = (periodGranLevel, rawStartDate, rawEndDate, resourceField, resourceValue) => {
  const { startDate, endDate } = buildStartAndEndDate(rawStartDate, rawEndDate);
  // eslint-disable-next-line max-len
  let apiQuery = `/api/v1/usage/resource-explorer/resource?periodGranLevel=${periodGranLevel}&startDate=${startDate}&endDate=${endDate}&resourceField=${resourceField}&resourceValue=${resourceValue}`;
  apiQuery = sanitizeQuery(apiQuery);
  return API.get('billings', apiQuery);
};

export const getResourcesIdsAndNamesDistinctValues = (value) =>
  API.get('billings', `/api/v1/usage/resource-explorer/distinct?value=${value}`);

export function useFetchResourceDetails() {
  const {
    data: selectedResource,
    isLoading: resourceIsLoading,
    mutate,
  } = useMutation(async ({ startDate, endDate, resourceField, resourceValue, recs, periodGranLevel }) => {
    const [resourceDetails, data] = await Promise.all([
      getResourceDetails(resourceField, resourceValue, startDate, endDate),
      getResourceOperationsData(periodGranLevel, startDate, endDate, resourceField, resourceValue),
    ]);
    const dataFormatted = data.map((res) => ({
      title: res.title,
      totalCost: res.total_cost,
      totalUsage: res.total_usage_quantity,
      usageDate: res.usage_date,
      instanceType: res.instance_type,
      instanceOperationName: res.operation_name,
      resourceId: res.resource_id,
      costType: res.cost_type_description,
    }));
    const resourceDetailsFormatted = resourceDetails.map((res) => ({
      serviceName: res.service_name,
      resourceName: res.resource_name,
      resourceId: res.resource_id,
      instanceType: res.instance_type,
      customTags: res.custom_tags,
      regionTagName: res.region_tag_name,
      memory: res.memory_in_gb,
      vCPU: res.vCPU,
      ecu: res.ecu,
      instanceStorage: res.instance_storage,
      networkPerformance: res.network_performance,
      project: res.project_name,
      title: res.title,
      totalCost: res.total_cost,
      costType: res.cost_type_name,
    }));

    const currResourceRecs = (recs || []).filter((rec) => {
      if (resourceValue) {
        if (rec.resourceId === resourceValue) {
          return true;
        }
        if (rec.resources && rec.resources.length) {
          const isPresent = rec.resources.some(
            (el) => el.resource_id === resourceValue || el.resource_name === resourceValue,
          );
          return isPresent;
        }
        return false;
      }
      return false;
    });

    return {
      startDate: new Date(startDate),
      endDate: new Date(endDate),
      periodGranLevel,
      costChartData: dataFormatted,
      costTableData: dataFormatted,
      currResourceRecs,
      resourceDetails: resourceDetailsFormatted,
      selectedResource: resourceField,
    };
  });
  return {
    selectedResource: selectedResource || null,
    fetchResource: (params) => mutate(params),
    resourceIsLoading,
  };
}
