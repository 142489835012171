import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { API } from 'shared/utils/apiMiddleware';
import apiConstants from 'shared/api/apiConstants';
import LinkedAccount from 'users/models/linkedAccount';

const root = '/api/v1/users/on-boarding/v2';

const fetchOnboardingsApi = async () => API.get('billings', `${root}`);

const fetchDatabaseModeApi = async () => API.get('billings', '/api/v1/users/database-mode');

const fetchOnboardingStatusApi = async (accountId) => API.get('billings', `${root}/${accountId}/status`);

const fetchOnboardingInvoiceStatusApi = async (accountId) => API.get('billings', `${root}/${accountId}/invoice-status`);

const fetchOnboardingLinkedAccountsApi = async (accountId) => {
  const data = await API.get('billings', `${root}/${accountId}/linked-accounts`);
  return data.map((rawAccount) => new LinkedAccount(rawAccount));
};

const migrateAzureToFocusApi = async (data) => API.post('billings', `/api/v1/users/on-boarding/migrate-azure-to-focus-account`, { body: data });

export const fetchOnboardingFileUploadStatusApi = async (accountId, bucketName, bucketRegion, roleARN, externalId) =>
  API.get(
    'billings',
    // eslint-disable-next-line max-len
    `${root}/${accountId}/file-upload-status?roleARN=${roleARN}&bucketName=${bucketName}&bucketRegion=${bucketRegion}&externalId=${externalId}`,
  );

export const fetchAzureOnboardingAppRegistrationStatusApi = async (
  accountId,
  directoryId,
  applicationId,
  clientSecret,
) =>
  API.get(
    'billings',
    // eslint-disable-next-line max-len
    `${root}/${
      accountId || '-'
    }/app-registration-status?directoryId=${directoryId}&applicationId=${applicationId}&clientSecret=${clientSecret}`,
  );

const startOnboardingApi = async ({
  accountId,
  accountName,
  isReseller,
  externalId,
  s3BucketName,
  s3BucketRegion,
  ...other
}) =>
  API.post('billings', `${root}/${accountId}/start`, {
    body: {
      ...other,
      accountName,
      s3BucketName,
      isReseller,
      externalId,
      s3BucketRegion,
    },
  });

const restartOnboardingApi = async ({
  accountId,
  roleARN,
  accountName,
  isReseller,
  externalId,
  s3BucketName,
  s3BucketRegion,
  ...other
}) =>
  API.post('billings', `${root}/${accountId}/restart`, {
    body: { ...other, accountName, roleARN, s3BucketName, s3BucketRegion, isReseller, externalId },
  });

const cancelOnboardingApi = async ({ accountId }) => API.del('billings', `${root}/${accountId}/cancel`);

const updateOnboardingParamsApi = async ({ accountId, roleARN, accountName, s3BucketName, s3BucketRegion, ...other }) =>
  API.put('billings', `${root}/${accountId}`, {
    body: { ...other, accountName, roleARN, s3BucketName, s3BucketRegion },
  });

const startOnboardingValidationApi = async ({ accountId, ...params }) =>
  API.post('billings', `${root}/${accountId}/validate`, { body: params });

const startOnboardingInvoiceApi = async ({ accountId }) => API.post('billings', `${root}/${accountId}/run-invoice`);

export function useOnboarding() {
  const queryClient = useQueryClient();
  const queryKey = apiConstants.QUERY_KEYS.V2_ONBOARDING;
  return {
    fetchDatabaseMode: () => useQuery(['database-mode'], fetchDatabaseModeApi),
    fetchOnboardings: () => useQuery([queryKey], fetchOnboardingsApi),
    fetchOnboardingStatus: (accountId, options) =>
      useQuery([queryKey, accountId], () => fetchOnboardingStatusApi(accountId), options),
    fetchOnboardingLinkedAccounts: (accountId, options) =>
      useQuery([queryKey, 'linked-accounts', accountId], () => fetchOnboardingLinkedAccountsApi(accountId), options),
    fetchOnboardingInvoiceStatus: (accountId, options) =>
      useQuery([queryKey, 'invoice-status', accountId], () => fetchOnboardingInvoiceStatusApi(accountId), options),
    startOnboarding: (options = {}) =>
      useMutation(startOnboardingApi, {
        onSuccess: () => {
          queryClient.invalidateQueries(queryKey);
        },
        ...options,
      }),
    cancelOnboarding: () =>
      useMutation(cancelOnboardingApi, {
        onSuccess: () => {
          queryClient.invalidateQueries(queryKey);
        },
      }),
    updateOnboardingParams: () =>
      useMutation(updateOnboardingParamsApi, {
        onSuccess: () => {
          queryClient.invalidateQueries(queryKey);
        },
      }),
    startOnboardingValidation: () =>
      useMutation(startOnboardingValidationApi, {
        onSuccess: () => {
          queryClient.invalidateQueries(queryKey);
        },
      }),
    startOnboardingInvoice: () =>
      useMutation(startOnboardingInvoiceApi, {
        onSuccess: () => {
          queryClient.invalidateQueries(queryKey);
        },
      }),
    restartOnboarding: () =>
      useMutation(restartOnboardingApi, {
        onSuccess: () => {
          queryClient.invalidateQueries(queryKey);
        },
      }),
    migrateAzureToFocus: (options) =>
      useMutation(migrateAzureToFocusApi, options),
  };
}
