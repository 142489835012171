import React from 'react';
import PropType from 'prop-types';
import { ReactComponent as NoCostCenters } from 'users/containers/Organization/CostCenters/img/no-cost-centers.svg';
import CreateEditCostCenter from 'users/containers/Organization/CostCenters/costCentersModals/CreateEditCostCenter/CreateEditCostCenter';

import styles from './CostCentersNoData.module.scss';

const CostCentersNoData = ({ colSpan, noCostCentersInOrg }) => {
  return (
    <td colSpan={colSpan} className="border-bottom-0 border-start-0 border-end-0 border-top-0">
      <div className={styles.costCentersEmptyState}>
        <NoCostCenters className={styles.svgImage} />
        <div className={styles.emptyStateText}>
          <span className={styles.mainText}>
            {noCostCentersInOrg
              ? 'There are currently no Cost Centers defined in this organization'
              : 'There are no Cost Centers matching your search'}
          </span>
          {noCostCentersInOrg ? (
            <div className={styles.createButtonContainer}>
              <CreateEditCostCenter isSecondary={true} />
            </div>
          ) : null}
        </div>
      </div>
    </td>
  );
};

CostCentersNoData.propTypes = {
  colSpan: PropType.number,
  noCostCentersInOrg: PropType.bool,
};

export default CostCentersNoData;
