import React from 'react';
import PropTypes from 'prop-types';

const TextField = ({ name, value, placeHolder, onChange, label, disabled = false, autoComplete = 'off' }) => (
  <div>
    <label htmlFor={name}>{label}</label>
    <input
      className="insert-budget-input"
      type="text"
      name={name}
      value={value}
      onChange={onChange}
      placeHolder={placeHolder}
      disabled={disabled}
      autoComplete={autoComplete}
    />
  </div>
);

TextField.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  placeHolder: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  autoComplete: PropTypes.string,
};

export default TextField;
