import React, { useContext } from 'react';
import { useSubTableState } from 'shared/hooks/customHooks/useSubTableState';

const LinkedAccountsTableContext = React.createContext();
export function withLinkedAccountsTableContextProvider(Component) {
  return ({ ...props }) => {
    const tableState = useSubTableState();
    return (
      // eslint-disable-next-line react/jsx-filename-extension
      (<LinkedAccountsTableContext.Provider
        value={{
          linkedAccountsTable: tableState,
        }}
      >
        <Component {...props} />
      </LinkedAccountsTableContext.Provider>)
    );
  };
}

export const useLinkedAccountsTableState = (divisionId) => {
  const {
    linkedAccountsTable: { getPage, setPage, anySelected, getSelectedRows, setSelectedRows },
  } = useContext(LinkedAccountsTableContext);
  return {
    page: getPage(divisionId),
    setPage: (newPage) => setPage(divisionId, newPage),
    anySelected,
    selectedRows: getSelectedRows(divisionId),
    setSelectedRows: (rows) => setSelectedRows(divisionId, rows),
  };
};
