/* eslint-disable no-unused-vars */
import { isFamilyTypeHasTypes } from 'shared/utils/cloudUtils';
import { CostUsageStates } from 'usage/constants/costAndUsageConstants';
import BaseCostUsageDataState from './baseCostUsageDataState';

export default class PlatformDataState extends BaseCostUsageDataState {
  constructor(context) {
    super(context);
  }

  getNewDataStateType(dataKey) {
    return CostUsageStates.TYPE;
  }

  getDataStateType() {
    return CostUsageStates.PLATFORM;
  }
}
