import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import apiConstants from 'shared/api/apiConstants';
import { API } from 'shared/utils/apiMiddleware';
import { FlatObject } from 'shared/types/commonTypes.ts';
import { AlertTypesEnum, UsersSettings } from '../types.ts';

const root = '/api/v1/users/user-settings/notifications';

export const riServerPropName = 'is_alert_ri_expiration';
export const spServerPropName = 'is_alert_sp_expiration';
export const costServerPropName = 'is_alert_cost_change';
const numberToServerPropName = [costServerPropName, riServerPropName, spServerPropName];
const getUserNotificationSettings = async (): Promise<UsersSettings> => {
  const settings = await API.get('billings', root);
  return settings?.userNotificationRawData?.[0];
};

const numberToUpdateFieldsName = ['isShowAlertCostChange', 'isShowAlertRiExpiration', 'isShowAlertSpExpiration'];
const updateSettings = (payload: { alertType: AlertTypesEnum; checked: boolean }): Promise<any> =>
  API.put('billings', root, { body: { [numberToUpdateFieldsName[payload.alertType]]: payload.checked ? 1 : 0 } });

export const useUsersSettings = () => {
  const queryClient = useQueryClient();
  const queryKey = [apiConstants.QUERY_KEYS.USERS_SETTINGS_NOTIFICATIONS];
  return {
    fetchUsersSettings: () => useQuery(queryKey, getUserNotificationSettings),
    setUsersSettingsLocally: (alertPropName: string, checked: 1 | 0) => {
      queryClient.setQueryData(queryKey, (old: FlatObject) => ({ ...old, [alertPropName]: checked }));
    },
    updateUsersSettings: (options = {}) =>
      useMutation({
        mutationFn: updateSettings,
        mutationKey: queryKey,
        onError: (err, payload) => {
          const alertPropName = numberToServerPropName[payload.alertType];
          queryClient.setQueryData(queryKey, (old: FlatObject) => ({
            ...old,
            [alertPropName]: payload.checked ? 0 : 1, // reversed value
          }));
        },
        ...options,
      }),
  };
};
