import React from 'react';
import { ReactComponent as NoData } from 'shared/img/icons/empty-list.svg';
import PropTypes from 'prop-types';

export default function NoDataFoundComponent({
  callBack = null,
  height = '',
  isBorderNeeded = true,
  title = 'No Data Found',
  bodyText = 'Try changing your filter or date parameters',
}) {
  const handleCallBack = (e) => {
    e.preventDefault();
    callBack();
  };
  return (
    <div
      style={{
        width: '100%',
        height,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
        padding: '10px 0',
        border: `${isBorderNeeded ? '0.5px solid #d8d8d8' : ''}`,
        borderRadius: '3px',
      }}
    >
      <div>
        <p
          style={{
            color: '#8080809e',
            fontSize: '20px',
            marginBottom: '5px',
          }}
        >
          {title}
        </p>
        <p
          style={{
            color: '#8080809e',
            fontSize: '15px',
            marginBottom: '10px',
          }}
        >
          {callBack && (
            <>
              <a href="/" onClick={(e) => handleCallBack(e)}>
                Click here
              </a>
              <span>{` to try again, or `}</span>
            </>
          )}
          <span>{bodyText}</span>
        </p>
      </div>
      <div
        className="w-100 d-flex justify-content-center"
        style={{
          maxWidth: '200px',
        }}
      >
        <div
          style={{
            padding: '25%',
            background: '#eff1f5',
            borderRadius: '50%',
            maxWidth: '90%',
          }}
        >
          <NoData />
        </div>
      </div>
    </div>
  );
}

NoDataFoundComponent.propTypes = {
  height: PropTypes.string,
  title: PropTypes.string,
  bodyText: PropTypes.string,
  callBack: PropTypes.func,
  isBorderNeeded: PropTypes.bool,
};
