import { useState, useEffect } from 'react';
import { PAGINATION_DIRECTION } from 'users/containers/Organization/consts';

const usePagination = ({ data, onPageChange }) => {
  const [currentPage, setCurrentPage] = useState(0);
  const [paginationTokenList, setPaginationTokenList] = useState({ 0: null });

  useEffect(() => {
    if (data?.paginationToken) {
      setPaginationTokenList((prev) => ({
        ...prev,
        [currentPage + 1]: data.paginationToken,
      }));
    }
  }, [data?.paginationToken, currentPage]);

  const pageChanged = (direction) => {
    if (direction === PAGINATION_DIRECTION.NEXT) {
      const nextPage = currentPage + 1;
      const nextPageToken = paginationTokenList[nextPage];
      if (nextPageToken) {
        setCurrentPage(nextPage);
        onPageChange?.(nextPageToken, nextPage);
      }
    } else if (direction === PAGINATION_DIRECTION.PREV) {
      const prevPage = currentPage - 1;
      if (prevPage >= 0) {
        const prevPageToken = paginationTokenList[prevPage];
        setCurrentPage(prevPage);
        onPageChange?.(prevPageToken, prevPage);
      }
    }
  };

  return {
    currentPage,
    pageChanged,
  };
};

export default usePagination;
