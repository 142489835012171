import { useQuery } from '@tanstack/react-query';
import apiConstants from 'shared/api/apiConstants';
import { queryClient } from 'queryClient';
import { API } from 'shared/utils/apiMiddleware';
import { BILLINGS_API_NAME, FILTERS, RECOMMENDATIONS_HEATMAP_ROOT, STALE_TIME } from 'recommendationsNew/consts';
import {
  transformFiltersContextToApiFormat,
  transformSelectDataToClientFormat,
  transformParentSelectDataToClientFormat,
} from 'recommendationsNew/hooks/react-query/apiUtils';
import { useRootStore } from 'app/contexts/RootStoreContext';

const getPageSizeByDynamicFilter = (dynamicFilter) => {
  switch (dynamicFilter) {
    case FILTERS.LINKED_ACCOUNT_ID.id:
      return 5000;
    case FILTERS.REGION.id:
    case FILTERS.INSTANCE_TYPE.id:
      return 1000;
    case FILTERS.ENRICHMENT_TAGS.id:
    case FILTERS.CUSTOM_TAGS.id:
      return 5000;
    case FILTERS.RESOURCE_ID.id:
      return 5000;
    default:
      return 50;
  }
};
async function fetchDynamicFilter(dynamicFilter, filters) {
  if (!dynamicFilter) {
    return null;
  }
  // const { [dynamicFilter]: currentFilter, ...filtersNotIncludingCurrent } = filters;
  const transformedFilters = transformFiltersContextToApiFormat(filters);
  const data = await API.post(BILLINGS_API_NAME, `${RECOMMENDATIONS_HEATMAP_ROOT}/dynamicFilter/${dynamicFilter}`, {
    body: {
      filters: transformedFilters || {},
      pagination_token: null,
      page_size: getPageSizeByDynamicFilter(dynamicFilter),
    },
  });
  // Transform data to match the format of react-select
  let pageData = transformSelectDataToClientFormat(data?.page, dynamicFilter);
  // Temporary remove '+' and null values from the tags filters. Later need to add these as checkboxes in the filters.
  if (dynamicFilter === FILTERS.ENRICHMENT_TAGS.id || dynamicFilter === FILTERS.CUSTOM_TAGS.id) {
    pageData = pageData.filter((item) => item.value !== '+' && item.value !== null);
  }
  return data?.page ? { ...data, page: pageData } : data;
}
async function fetchDynamicTagFilter(dynamicFilter, dynamicTag, filters) {
  if (!dynamicFilter || !dynamicTag) {
    return null;
  }
  const transformedFilters = transformFiltersContextToApiFormat(filters);
  const data = await API.post(
    BILLINGS_API_NAME,
    `${RECOMMENDATIONS_HEATMAP_ROOT}/dynamicFilterWithParams?tagType=${dynamicFilter}&tagName=${dynamicTag}`,
    {
      body: {
        filters: transformedFilters || {},
        pagination_token: null,
        // cann't call to getPageSizeByDynamicFilter
        // because dynamicTag is depended on data and not const options as dynamicFilter
        page_size: 5000,
      },
    },
  );
  return transformParentSelectDataToClientFormat(data.page, dynamicTag);
}

export default function useDynamicFilter(currentFilter = undefined) {
  const { usersStore } = useRootStore();
  const userAccountKey = usersStore?.currDispUserAccountKey;

  const queryKey = [
    apiConstants.QUERY_KEYS.RECOMMENDATIONS,
    apiConstants.QUERY_KEYS.RECOMMENDATIONS_DYNAMIC_FILTER,
    userAccountKey,
  ];

  return {
    invalidate: ({ currentFilter, filters }) =>
      queryClient.invalidateQueries([...queryKey, currentFilter, filters] || queryKey),
    reset: ({ currentFilter, filters }) => queryClient.resetQueries([...queryKey, currentFilter, filters] || queryKey),
    fetchDynamicFilter: (filters, customSelect) =>
      useQuery([...queryKey, currentFilter, filters], () => fetchDynamicFilter(currentFilter, filters), {
        retry: false,
        staleTime: STALE_TIME,
        enabled: !!currentFilter,
        select: customSelect,
      }),
    fetchDynamicTagFilter: (filters, dynamicTag) =>
      useQuery(
        [...queryKey, currentFilter, filters, dynamicTag],
        () => fetchDynamicTagFilter(currentFilter, dynamicTag, filters),
        {
          retry: false,
          staleTime: STALE_TIME,
          enabled: !!currentFilter && !!dynamicTag,
        },
      ),
  };
}
