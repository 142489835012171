import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useNavigate, useLocation } from 'react-router-dom';
import { querystring } from 'shared/utils/apiUtil';
import { Routes } from 'shared/constants/routes';
import integrationsUtils, { validateIntegrationType } from 'shared/utils/integrationsUtils';

const UnauthenticatedRoute = ({ component: C, props: cProps }) => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const integrationType = new URLSearchParams(location.search).get('type');
    if (validateIntegrationType(integrationType)) {
      sessionStorage.setItem('integration', location.search);
      integrationsUtils.completeAuthForIntegrations();
    }
  }, [location]);

  useEffect(() => {
    const currPath = querystring('redirect') || String(location.pathname);
    let nextRoutePath = cProps.isSessionStorageAuth() ? `${Routes.LANDING}` : `${Routes.LOG_IN}`;
    if (location.hash && nextRoutePath === `${Routes.LOG_IN}`) {
      nextRoutePath += location.hash;
    }

    if (currPath !== '/' && currPath !== `${Routes.LOG_IN}` && currPath !== `${Routes.ACCOUNTS_OVERVIEW}`) {
      // Pass the redirect query param and search params to the landing page
      nextRoutePath += `?redirect=${currPath}`;
      if (location.search && !querystring('redirect')) {
        nextRoutePath += location.search;
      }
    }
    const isRedirectNeeded =
      nextRoutePath &&
      nextRoutePath !== `${String(location.pathname)}${String(location.search)}` &&
      !currPath.startsWith(Routes.RESET_PASSWORD.split('/:')[0]);
    if (isRedirectNeeded) {
      navigate(nextRoutePath, { replace: true });
    }
  });

  return <C {...cProps} />;
};

UnauthenticatedRoute.propTypes = {
  component: PropTypes.func.isRequired,
  props: PropTypes.object.isRequired,
};

export default UnauthenticatedRoute;
