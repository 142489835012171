export const DEFAULT_SELECTED_FILTERS_LENGTH = 45;
export const LINECHART_SELECTED_FILTERS_LENGTH = 10;

export default class LegendKeysFilterHandler {
  constructor(context) {
    this.context = context;
  }

  updateState = (data) => {
    this.context.setState(data);
  };

  setKeysFilterHandler = (dataKeys) => {
    const dataKeysNames = dataKeys.map((dataKey) => dataKey.name);
    const dataKeysIds = dataKeys.map((dataKey) => dataKey.id);
    const newNames = Array.from(new Set(dataKeysNames));
    const newIds = Array.from(new Set(dataKeysIds));
    this.updateState({
      filteredKeys: newNames,
      favourites: newIds,
    });
  };

  addKeysFilterHandler = (dataKeys, isInitialLoad, updateVisible) => {
    const { filteredKeys, favourites, visibleFilters } = this.context.state;
    const dataKeysNames = dataKeys?.map((dataKey) => dataKey.name);
    const dataKeysIds = dataKeys?.map((dataKey) => dataKey.id);
    const newNames = filteredKeys?.concat(dataKeysNames);
    const newFav = favourites?.concat(dataKeysIds);
    this.updateState({
      filteredKeys: newNames,
      favourites: newFav,
      isInitialDataKeyFilterLoad: false,
      legendInitiated: false,
      visibleFilters: updateVisible ? dataKeys : visibleFilters,
    });
  };

  legendInitFinished = () => {
    this.updateState({
      legendInitiated: false,
    });
  };

  removeKeysFilterHandler = (dataKeys, isClearAll) => {
    const { filteredKeys, favourites, isInitialDataKeyFilterLoad } = this.context.state;
    const dataKeysNames = dataKeys.map((dataKey) => dataKey.name);
    const dataKeysIds = dataKeys.map((dataKey) => dataKey.id);
    const newNames = filteredKeys.filter((filterKey) => !dataKeysNames.includes(filterKey));
    const newFav = favourites.filter((filterKey) => !dataKeysIds.includes(filterKey));
    this.updateState({
      filteredKeys: newNames,
      favourites: newFav,
      isInitialDataKeyFilterLoad: isClearAll ? false : isInitialDataKeyFilterLoad,
    });
    return { filteredKeys: newNames, favourites: newFav };
  };

  isShowOthersChangeHandler = (isShowOthers) => {
    this.updateState({
      isShowOthers,
    });
  };

  filterDataKeys = (dataKeys, filteredKeys, isInitialLoad) => {
    if (isInitialLoad) {
      dataKeys = this.initFavourites(dataKeys, isInitialLoad);
    } else if (filteredKeys.length !== 0) {
      dataKeys = dataKeys.filter((dataKey) => filteredKeys.indexOf(dataKey.name) > -1);
    }
    return dataKeys;
  };

  initFavourites = (dataKeys, isInitialLoad) => {
    const dataLength = dataKeys.length;
    const N = dataLength < DEFAULT_SELECTED_FILTERS_LENGTH ? dataLength : DEFAULT_SELECTED_FILTERS_LENGTH;
    const addedKeys = dataKeys.map(({ name }, i) => ({ id: i, name }));
    addedKeys.splice(N, addedKeys.length - 1);
    this.addKeysFilterHandler(addedKeys, isInitialLoad, true);
    return addedKeys;
  };

  prepareFilterKeysFromDataKeys = (dataKeys) => {
    if (!dataKeys) {
      return [];
    }
    return dataKeys.map((key, idx) => ({ id: idx, name: key.name }));
  };

  pushNewDataFilterKey = (dataKeys, newFilterKey) => {
    if (newFilterKey) {
      dataKeys.push(newFilterKey);
    }
  };
}
