 
import { RecommendationTypes } from 'recommendations/constants/recommendationsConstants';
import AzureDbIdleCostRecommendationBuilder from 'recommendations/models/builders/azureDbIdleCostRecommendationBuilder';
import { AzureMysqlIdleRecommendation } from '../recommTypes';

export default class AzureMysqlDbIdleCostRecommendationBuilder extends AzureDbIdleCostRecommendationBuilder {
  constructor(rawRecommendation) {
    super(rawRecommendation);
  }

  createRecommendation() {
    this._recommendation = new AzureMysqlIdleRecommendation();
  }

  getRecommType() {
    return RecommendationTypes.AZURE_MYSQL_DB_IDLE;
  }

  buildRecommTitle() {
    const { recommended_action: action } = this._rawRecommendation;
    this._recommendation.title = `${action || ''} MySQL DB Idle`;
  }
}
