import React from 'react';
import SaveAsDashboardPanel from './saveAsDashboardPanel';
import ViewsPanel from './viewsPanel';
import SavingsTracker from './savingsTracker';
import CostModeToggle from 'recommendationsNew/components/heatMap/heatMapActions/costModeToggle.jsx';
import { useRootStore } from 'app/contexts/RootStoreContext.jsx';
import { ACCOUNT_FEATURES } from 'users/constants/usersConstants.js';
import checkFeatureFlag from 'shared/utils/featureFlagUtil.js';

import classes from './heatMapActions.module.scss';

const HeatMapActions = () => {
  const { usersStore } = useRootStore();
  const isNetAmortizedSupported = checkFeatureFlag(usersStore, ACCOUNT_FEATURES.SUPPORT_NET_AMORTIZE);
  return (
    <div className={classes.actions}>
      {isNetAmortizedSupported && !usersStore.isCurrentUserReCustomer && <CostModeToggle />}
      <SavingsTracker />
      <ViewsPanel />
      <SaveAsDashboardPanel />
    </div>
  );
};
export default HeatMapActions;
