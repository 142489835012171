import React from 'react';
import PropTypes from 'prop-types';
import { GenerateIcon, ICONS } from '@pileus-cloud/anodot-frontend-common';
import Button from 'shared/components/andtComponents/Button';
import { ReactComponent as AnobotAvatarInfo } from '../assets/anobot-avatar-info.svg';
import { ReactComponent as AnobotAvatarDesc } from '../assets/anobot-avatar-desc.svg';
import styles from './gpt-modal.module.scss';

export const GptModal = ({ onClose, type = 'info', title = '', content = <></>, onApproval = () => {} }) => (
  <div className={styles.gptModalContainer} onClick={onClose}>
    <div className={`${styles.modal} ${styles[type]}`} onClick={(e) => e.stopPropagation()}>
      <header className={!title && styles.noTitle}>
        {type === 'info' ? <AnobotAvatarInfo /> : <AnobotAvatarDesc />}
        {title && <h3 className={styles.title}>{title}</h3>}
      </header>
      {content && <div className={styles.content}>{content}</div>}
      {type === 'warning' && (
        <footer>
          <Button text="No" onClick={onClose} isSecondary />
          <Button text="Yes" onClick={onApproval} />
        </footer>
      )}
      <button onClick={onClose} className={styles.close} type="button">
        <GenerateIcon iconName={ICONS.circleXmark.name} size="xl" />
      </button>
    </div>
  </div>
);

GptModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  type: PropTypes.string,
  title: PropTypes.string,
  onApproval: PropTypes.func,
  content: PropTypes.node,
};

export default GptModal;
