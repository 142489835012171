import React, {useEffect, useMemo} from 'react';
import classNames from 'classnames';
import { palette } from 'shared/constants/colorsConstants.js';
import { PANELS, PANELS_TYPES} from 'app/containers/MainDashboard/consts.js';
import useCueData from 'app/containers/MainDashboard/hooks/react-query/useCueData.js';
import Spinner, {SPINNER_SIZES} from 'shared/components/andtComponents/Spinner.jsx';
import { getFirstDayOfMonth } from 'app/containers/MainDashboard/utils.js';
import { useMainDashboardContext } from 'app/containers/MainDashboard/contexts/mainDashboardContext.jsx';
import { CustomizedAxisTick } from 'app/containers/MainDashboard/panels/HistoryWithForecast/CustomizedAxisTick.jsx';
import moment from 'moment';
import DateFilter from 'shared/modules/dateFilter.js';
import {
    Area,
    CartesianGrid,
    ComposedChart, Legend,
    ResponsiveContainer,
    XAxis,
    YAxis
} from 'recharts';
import { useUserSettingsContext } from 'users/utils/contexts/UserSettingsContext.jsx';
import ForecastHeader from 'app/containers/MainDashboard/panels/HistoryWithForecast/ForecastHeader.jsx';
import useForecastData from 'app/containers/MainDashboard/hooks/react-query/useForecastData.js';
import { CustomizedLegend } from './CustomizedLegend.jsx';
import mainStyles from '../../mainDashboardCommon.module.scss';

const getMaxValue = (data) => {
    const maxValue = data?.reduce((acc, currVal) => {
        if (acc >= currVal.currMonth || acc >= currVal.prevMonth) {
            return acc;
        }
        return currVal.currMonth > currVal.prevMonth ? currVal.currMonth : currVal.prevMonth;
    }, 1000);
    return maxValue || 1000;
};
const HistoryWithForecast = () => {
    const { numStrAbriviaionByDisplayMetric } = useUserSettingsContext();
    const { updatePanelLoadingState, dateFromLastMonth } = useMainDashboardContext();
    const [forecastNumMonth, setForecastNumMonth] = React.useState('1M');
    const cueHook = useCueData();
    const forecastHook = useForecastData();

    const params = {
        ...PANELS[PANELS_TYPES.HISTORY_DAILY_WITH_FORECAST].params,
        start: getFirstDayOfMonth(dateFromLastMonth),
        end:  moment(DateFilter.getDate()).format('YYYY-MM-DD')
    };
    const { data, isLoading: isHistoryDataLoading } = cueHook.fetchCueData(params);
    const { data: forecastData, isLoading: isForecastDataLoading } = forecastHook.fetchForecastData();

    const isLoading = isHistoryDataLoading || isForecastDataLoading;

    const chartData = useMemo(()=> {
     if(!data) return [];
     //calculate prev month data
     const previousMonth = data.filter((d) => d.usage_date < getFirstDayOfMonth(DateFilter.getDate()));
     const prevData = previousMonth.map((d) => ({
         day: moment(d.usage_date).format('DD'),
         fullDate: d.usage_date,
         prevMonth: +d.total_cost,
     }));
     //marge the last day in previous month with first day in current month
     if(prevData.length  > 0 ) {
         prevData[prevData.length - 1].currMonth = prevData[prevData.length - 1].prevMonth;
     }
     //calculate current month data
     const currentMonth = data.filter((d) => d.usage_date >= getFirstDayOfMonth(DateFilter.getDate()));
     const currentData = currentMonth.map((d) => ({
         day: moment(d.usage_date).format('DD'),
         fullDate: d.usage_date,
         currMonth: +d.total_cost,
     }));
         return [...prevData, ...currentData, ...(forecastData?.forecast || [])];
    },[data, forecastData])

    useEffect(() => {
        updatePanelLoadingState(PANELS_TYPES.HISTORY_DAILY_WITH_FORECAST, isLoading);
    },[isLoading]);

    const maxValue = getMaxValue(data) * 10;

    const customTicks = (props) => <CustomizedAxisTick {...props} data={chartData} />;

    return (
      <div className={classNames(mainStyles.panelWrapper, mainStyles.halfWidthPanelWrapper)}>
        {isLoading ? <Spinner className={mainStyles.spinner} size={SPINNER_SIZES.MEDIUM}/>  :
           (<>
              <ForecastHeader title={PANELS[PANELS_TYPES.HISTORY_DAILY_WITH_FORECAST].title}
                              params={params}
                              forecastMonths={['1M', '3M']}
                              forecastNumMonth={forecastNumMonth}
                              setForecastNumMonth={setForecastNumMonth}/>
                  <div>
                     <ResponsiveContainer width="100%" height={350}>
                        <ComposedChart
                            data={chartData}
                            margin={{ top: 15 }}
                        >
                          <XAxis dataKey="day"
                                 tick={customTicks}
                                 interval={0}
                                 domain={['dataMin', 'dataMax + 0.5']}
                                 axisLine={false}
                                 tickLine={false}/>
                          <XAxis dataKey="day" interval={0} domain={['dataMin', 'dataMax + 0.5']}/>
                          <YAxis
                             axisLine={false}
                             tickLine={false}
                             tick={{ fill: palette.gray[450]}}
                             tickFormatter={(value) => numStrAbriviaionByDisplayMetric(maxValue, value, 'cost')}
                          />
                          <defs>
                             <linearGradient id="colorPrevMonth" x1="0" y1="0" x2="0" y2="1">
                                <stop offset="0%" stopColor={palette.blue[400]} stopOpacity={0}/>
                                <stop offset="100%" stopColor={palette.blue[400]} stopOpacity={0.4}/>
                             </linearGradient>
                             <linearGradient id="colorCurrentMonth" x1="0" y1="0" x2="0" y2="1">
                                <stop offset="0%" stopColor={palette.blue[500]} stopOpacity={0}/>
                                <stop offset="100%" stopColor={palette.blue[500]} stopOpacity={0.4}/>
                             </linearGradient>
                             <linearGradient id="colorForecastMonth" x1="0" y1="0" x2="0" y2="1">
                                 <stop offset="0%" stopColor={palette.azure[500]} stopOpacity={0.4}/>
                                 <stop offset="100%" stopColor={palette.azure[500]} stopOpacity={0}/>
                             </linearGradient>
                             <pattern id="patternForecastMonth" patternUnits="userSpaceOnUse" width="16" height="16">
                                 <rect width="100%" height="100%" fill="url(#colorForecastMonth)"/>
                                 <path d="M 0 16 L 16 0" stroke="white" strokeWidth="0.8"/>
                             </pattern>
                          </defs>
                          {/*<Tooltip*/}
                          {/*  content={<CustomTooltip*/}
                          {/*         numStrAbbreviationByDisplayMetric={numStrAbriviaionByDisplayMetric}/>}*/}
                          {/*/>*/}
                           <Legend content={<CustomizedLegend dashLegends={['currExpected']} />}/>
                           <CartesianGrid stroke={palette.gray[100]} vertical={false}/>
                           <Area type="monotone" dataKey="prevMonth" dot={false} stroke={palette.blue[400]} fill="url(#colorPrevMonth)"
                                 strokeWidth={1.5}
                                 name="Previous month"/>
                           <Area type="monotone" dataKey="currMonth" dot={false} stroke={palette.blue[500]} fill="url(#colorCurrentMonth)"
                                 strokeWidth={1.5}
                                 name="Actual month"/>
                           <Area
                                 type="monotone"
                                 dataKey="currExpected"
                                 dot={false}
                                 stroke={palette.azure[500]}
                                 fill="url(#patternForecastMonth)"
                                 strokeWidth={1.5}
                                 strokeLinecap="round"
                                 name="Forecast month"/>
                        </ComposedChart>
                     </ResponsiveContainer>
                  </div>
                </>)
            }
        </div>
    );
};

export default HistoryWithForecast;
