import React from 'react';
import PropTypes from 'prop-types';
import useTable from 'shared/hooks/customHooks/useTable';
import AddRolesTable from './AddRolesTable';
import AddRolesHeader from './AddRolesHeader';

const AddRoles = ({
  roles,
  pageChanged,
  search,
  setSearch,
  selectedRoles,
  onRolesAddedRemoved,
  isLoading,
  isDisabled,
}) => {
  const { NewTableWrapper } = useTable();

  return (
    <NewTableWrapper className="sub-table" isCompact>
      <AddRolesHeader
        pageChanged={pageChanged}
        search={search}
        selectedRolesCount={selectedRoles?.length}
        setSearch={setSearch}
        total={roles?.length}
      />
      <AddRolesTable
        isLoading={isLoading}
        isDisabled={isDisabled}
        onRolesAddedRemoved={onRolesAddedRemoved}
        roles={roles}
        search={search}
        selectedRoles={selectedRoles}
      />
    </NewTableWrapper>
  );
};

AddRoles.propTypes = {
  isLoading: PropTypes.bool,
  isDisabled: PropTypes.bool,
  roles: PropTypes.array,
  pageChanged: PropTypes.func.isRequired,
  search: PropTypes.string,
  setSearch: PropTypes.func,
  selectedRoles: PropTypes.array,
  onRolesAddedRemoved: PropTypes.func.isRequired,
};

export default AddRoles;
