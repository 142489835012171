 
 
import { RecommendationTypes } from 'recommendations/constants/recommendationsConstants';
import { RiRecommendation } from 'recommendations/models/recommTypes';
import { RecommendationCost } from 'recommendations/models/recommCommonTypes';
import Ec2CostRecommendationBuilder from 'recommendations/models/builders/ec2CostRecommBuilder';
import { calcNumOfMonthsToBreakEven } from 'shared/utils/calcUtil';
// import { sliceLastChar } from 'shared/utils/strUtil';

export default class RiEc2CostRecommendationBuilder extends Ec2CostRecommendationBuilder {
  constructor(rawRecommendation) {
    super(rawRecommendation);
  }

  createRecommendation() {
    this._recommendation = new RiRecommendation();
  }

  getRecommType() {
    return RecommendationTypes.EC2_RI;
  }

  buildRecommTitle() {
    const { recommended_action: action } = this._rawRecommendation;
    this._recommendation.title = `${action} Reserved Instances`;
  }

  build() {
    super.build();
    const {
      quantity: numOfRecommRIs,
      recommended_plan: riType,
      yearly_cost_current: currYearlyCost,
      yearly_cost_recommended: recommYearlyCost,
      total_yearly_cost_recommended: totalRecommYearlyCost,
      monthly_cost_recommended: recommMonthlyCost,
      monthly_cost_current: currMonthlyCost,
      total_monthly_cost_recommended: recommTotalCost,
      recommended_plan: recommPlan,
      recommended_action: action,
      zone_tag: tagZone,
      additional_recommended_plans: additionalRecommPlans,
      ri_table: riTable,
      potential_savings: potentialSavings,
      running_hours_needed: runningHoursNeeded,
      total_hours: totalRunningHours,
      ri_instances_types: riInstancesTypes,
    } = this._rawRecommendation;

    const mapInstanceTypeToCount = {};

    if (riInstancesTypes) {
      riInstancesTypes.forEach((instanceType) => {
        if (!(instanceType in mapInstanceTypeToCount)) {
          const count = riInstancesTypes.filter((countedType) => countedType === instanceType).length;
          mapInstanceTypeToCount[instanceType] = count;
        }
      });
    }

    this._recommendation.numOfRecommRIs = numOfRecommRIs;
    this._recommendation.riType = riType;

    const yearlyRecommCost = new RecommendationCost(currYearlyCost, totalRecommYearlyCost);
    this._recommendation.breakEvenMonth = calcNumOfMonthsToBreakEven(recommYearlyCost, currYearlyCost);
    this._recommendation.region = tagZone;
    this._recommendation.yearlyCost = yearlyRecommCost;
    this._recommendation.recommMonthlyCost = parseFloat(recommMonthlyCost);
    this._recommendation.recommTotalCost = parseFloat(recommTotalCost);
    this._recommendation.currMonthlyCost = parseFloat(currMonthlyCost);
    this._recommendation.currTotalCost = this._recommendation.currMonthlyCost;
    this._recommendation.recommPlan = recommPlan;
    this._recommendation.action = action;
    this._recommendation.additionalRecommPlans = additionalRecommPlans;
    this._recommendation.riTable = riTable;
    this._recommendation.potentialSavings = potentialSavings;
    this._recommendation.runningHoursNeeded = runningHoursNeeded;
    this._recommendation.totalRunningHours = totalRunningHours;
    this._recommendation.mapInstanceTypeToCount = mapInstanceTypeToCount;
  }
}
