 
import { RecommendationTypes } from 'recommendations/constants/recommendationsConstants';
import { AzureIdleLoadBalancerRecommendation } from 'recommendations/models/recommTypes';
import AzureCostRecommendationBuilder from 'recommendations/models/builders/azureCostRecommBuilder';

export default class AzureIdleLoadBalancerRecommendationBuilder extends AzureCostRecommendationBuilder {
  constructor(rawRecommendation) {
    super(rawRecommendation);
  }

  createRecommendation() {
    this._recommendation = new AzureIdleLoadBalancerRecommendation();
  }

  getRecommType() {
    return RecommendationTypes.AZURE_IDLE_LOAD_BALANCER;
  }

  buildRecommTitle() {
    const { recommended_action: action } = this._rawRecommendation;
    this._recommendation.title = `${action || ''} Idle Load Balancer`;
  }

  build() {
    super.build();
    const {
      zone_tag: tagZone,
      // potential_savings: potentialSavings,
      ip_address: ipAddress,
      // dns_name: dnsName,
      resource_id: resourceId,
      load_balancer_name: loadBalancerName,
      load_balancer_type: loadBalancerType,
    } = this._rawRecommendation;

    this._recommendation.resourceId = resourceId;
    this._recommendation.ipAddress = ipAddress;
    this._recommendation.loadBalancerName = loadBalancerName;
    this._recommendation.loadBalancerType = loadBalancerType;
    this._recommendation.region = tagZone;
    // this._recommendation.potentialSavings = potentialSavings;
  }
}
