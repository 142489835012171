 
import AzureCostRecommendationBuilder from 'recommendations/models/builders/azureCostRecommBuilder';

export default class AzureDbIdleCostRecommendationBuilder extends AzureCostRecommendationBuilder {
  constructor(rawRecommendation) {
    super(rawRecommendation);
  }

  buildRecommTitle() {
    const { recommended_action: action } = this._rawRecommendation;
    this._recommendation.title = `${action || ''} DB Idle`;
  }

  build() {
    super.build();
    const {
      resource_id: resourceId,
      resource_group: resourceGroup,
      resource_name: resourceName,
      days_to_check: daysToCheck,
      db_name: dbName,
      db_storage_size: dbStorageSize,
      starting_time: startingTime,
      num_of_connections: numOfConnections,
      instance_type_model: instanceTypeModel,
      instance_type_size: instanceTypeSize,
      server,
      db_type: dbType,
      db_account: dbAccount,
      linked_account_name: accountName,
    } = this._rawRecommendation;
    this._recommendation.accountName = accountName;
    this._recommendation.dbType = dbType;
    this._recommendation.server = server;
    this._recommendation.dbAccount = dbAccount;
    this._recommendation.resourceGroup = resourceGroup;
    this._recommendation.resourceName = resourceName;
    this._recommendation.startingTime = startingTime;
    this._recommendation.resourceId = resourceId;
    this._recommendation.daysToCheck = daysToCheck;
    this._recommendation.instanceTypeSize = instanceTypeSize;
    this._recommendation.instanceTypeModel = instanceTypeModel;
    this._recommendation.numOfConnections = numOfConnections;
    this._recommendation.dbStorageSize = dbStorageSize;
    this._recommendation.dbName = dbName;
  }
}
