import React from 'react';
import PropTypes from 'prop-types';
import Input from 'shared/components/andtComponents/Input';
import TextArea from 'shared/components/andtComponents/TextArea';
import { ReactComponent as NoRoles } from '../../img/no-roles.svg';

import styles from './CreateEditRoleModal.module.scss';

const DetailsStep = ({ isViewOnly, role, setRole, roleValidationErrors, saveClicked }) => (
  <div className={styles.createEditRoleContainer}>
    <div className={styles.propertiesContainer}>
      <div className={styles.propertyContainer}>
        <span>Role Name</span>
        <Input
          disabled={isViewOnly}
          invalidComponent={<div>{roleValidationErrors.roleName}</div>}
          isInvalid={saveClicked && roleValidationErrors.roleName}
          onChange={(e) => setRole({ ...role, displayName: e?.target?.value })}
          placeholder="Enter Name"
          value={role?.displayName}
        />
      </div>
      <div className={styles.propertyContainer}>
        <span>
          Description <i>(Optional)</i>
        </span>
        <TextArea
          disabled={isViewOnly}
          onChange={(e) => setRole({ ...role, description: e?.target?.value })}
          placeholder="Enter Description"
          value={role?.description}
        />
      </div>
    </div>
    <div className={styles.noRolesImageContainer}>
      <NoRoles />
    </div>
  </div>
);

DetailsStep.propTypes = {
  isViewOnly: PropTypes.bool,
  role: PropTypes.shape({
    displayName: PropTypes.string,
    description: PropTypes.string,
  }),
  roleValidationErrors: PropTypes.object,
  saveClicked: PropTypes.bool,
  setRole: PropTypes.func.isRequired,
};

export default DetailsStep;
