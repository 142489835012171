import React from 'react';
import PropTypes from 'prop-types';
import { useFormContext } from 'react-hook-form';
import moment from 'moment';

import { AZURE_ONBOARDING_FIELDS } from '../../../AzureOnboardingConstants';
import styles from './DataPreview.module.scss';

const DataPreview = ({ data = null }) => {
  const { getValues } = useFormContext();
  const containerName = getValues(AZURE_ONBOARDING_FIELDS.CONTAINER_NAME);
  if (!data) {
    return null;
  }
  return (
    <div className={styles.container}>
      <p className={styles.title}>
        {containerName} <span>(container):</span>
      </p>
      {Object.keys(data).map((key) => (
        <div key={key} className={styles.folder}>
          <p>
            {key} <span>(folder):</span>
          </p>
          {Object.keys(data[key]).map((subKey) => (
            <div key={subKey} className={styles.export}>
              <p>
                {subKey} <span>(export):</span>
              </p>
              {Object.keys(data[key][subKey]).map((date) => {
                const month = moment(date.split('-')[0]).format('YYYY-MM');
                return (
                  <p key={date} className={styles.month}>
                    <span>
                      {month}: {data[key][subKey][date]} files
                    </span>
                  </p>
                );
              })}
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};

DataPreview.propTypes = {
  data: PropTypes.object,
};

export default DataPreview;
