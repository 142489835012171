import React from 'react';
import { PropTypes } from 'mobx-react';
import IconFromPng from 'shared/components/IconFromPng';
import ICONS from 'shared/constants/assetsConstants';
import styles from './empyOptions.module.scss';

const EmptyOptions = ({ size = '20px' }) => (
  <div className="d-flex flex-column align-items-center">
    <IconFromPng size={size} matchHeight icon={ICONS.emptyOptions} />
    <span className={styles.noReultsTxt}>No Results</span>
  </div>
);

EmptyOptions.propTypes = {
  size: PropTypes.string,
};

export default EmptyOptions;
