import React, { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment/moment';
import { GenerateIcon, ICONS } from '@pileus-cloud/anodot-frontend-common';
import { useRootStore } from 'app/contexts/RootStoreContext';
import Spinner, { SPINNER_SIZES } from 'shared/components/andtComponents/Spinner.jsx';
import CustomModal, { MODAL_MODES } from 'shared/components/andtComponents/Modal';
import Stepper from 'shared/components/andtComponents/Stepper/Stepper.jsx';
import { isUsernameValid } from 'users/utils/userUtil';
import useUsers from 'users/new-user-management/hooks/reactQuery/useUsers';
import AddRolesStep from './AddRolesStep.jsx';
import CreateUsersStep from './CreateUsersStep.jsx';

import styles from './AddUsersModal.module.scss';

const ADD_USERS_STEPS_CONFIG = [
  {
    title: 'Create Users',
    titleShort: 'Create Users',
    component: CreateUsersStep,
  },
  {
    title: 'Add Roles',
    titleShort: 'Add Roles',
    component: AddRolesStep,
  },
];

const EMAIL_ERRORS = {
  INVALID_EMAIL_FORMAT: 'Wrong Email format',
  DUPLICATE_EMAILS: 'Duplicate emails are not allowed',
  SERVER_ERROR: 'User creation failed',
};

const AddUsersModal = ({ isOpen, setIsOpen }) => {
  const [emailsToSave, setEmailsToSave] = useState([]);
  const [isSaving, setIsSaving] = useState(false);
  const [isShowingResumeEditing, setIsShowingResumeEditing] = useState(false);
  const [rolesToAssign, setRolesToAssign] = useState([]);
  const [screenIndex, setScreenIndex] = useState(0);
  const [savedUsers, setSavedUsers] = useState([]);
  const [validations, setValidations] = useState();

  const Page = ADD_USERS_STEPS_CONFIG[screenIndex]?.component;

  const { usersStore } = useRootStore();
  const { currDispUserAccountKey } = usersStore;
  const accountName = usersStore.getCurrentDisplayedAccountName(currDispUserAccountKey);

  const onClose = () => {
    setIsSaving(false);
    setSavedUsers([]);
    setScreenIndex(0);
    setIsOpen(false);
    setValidations(undefined);
    setEmailsToSave([]);
    setIsShowingResumeEditing(false);
    setRolesToAssign([]);
  };

  const { createUsers, updateRolesAssignedToUsers } = useUsers();

  const saveUsers = async () => {
    let userValidations;
    setIsSaving(true);

    const invalidEmails = emailsToSave?.filter((e) => e.trim() && !isUsernameValid(e.trim()).validResult);
    if (invalidEmails?.length) {
      const errors = invalidEmails.map((email) => ({ email, error: EMAIL_ERRORS.INVALID_EMAIL_FORMAT }));
      setIsSaving(false);
      userValidations = { users: errors };
    } else if (emailsToSave?.length !== [...new Set(emailsToSave.map((email) => email.trim()))].length) {
      setIsSaving(false);
      userValidations = { users: [{ email: '', error: EMAIL_ERRORS.DUPLICATE_EMAILS }] };
    } else {
      const users = emailsToSave?.map((email) => ({
        username: email.trim(),
        createdAt: moment().toISOString(),
      }));
      try {
        const result = await createUsers.mutateAsync({ users });
        setSavedUsers(result);
      } catch (error) {
        if (error?.response?.status === 500) {
          const failedUsers = error?.response?.data?.clientMessage?.failed;
          if (failedUsers) {
            const errorEmails = Object.keys(failedUsers).map((user) => ({ email: user, error: failedUsers[user] }));
            userValidations = { users: errorEmails };
          }
        }
      } finally {
        setIsSaving(false);
      }
    }
    return userValidations;
  };

  const assignRoles = async () => {
    let roleValidations;
    try {
      await updateRolesAssignedToUsers.mutateAsync({
        userIds: Object.values(savedUsers),
        roleIdsToAdd: rolesToAssign?.map((r) => r.id),
      });
    } catch (error) {
      roleValidations = { roles: error };
      setIsSaving(false);
    }
    return roleValidations;
  };

  const isValidTab = (indexTab) => {
    let validations = {};

    switch (indexTab) {
      case 0:
        validations = {};
        setValidations(validations);
        return !Object.keys(validations).length > 0;
      default:
        return true;
    }
  };

  const setNextPage = async () => {
    setIsSaving(true);
    if (!isValidTab(screenIndex)) {
      return;
    }
    let saveValidations;
    if (screenIndex === 0) {
      saveValidations = await saveUsers();
      if (!saveValidations?.users?.length) {
        setIsSaving(false);
        setScreenIndex(screenIndex + 1);
      }
    } else if (screenIndex === 1) {
      if (isShowingResumeEditing === true) {
        onClose();
      } else {
        if (rolesToAssign?.length > 0) {
          saveValidations = await assignRoles();
          if (!saveValidations?.roles?.length) {
            onClose();
          }
        } else {
          setIsShowingResumeEditing(true);
          setIsSaving(false);
        }
      }
    }
    setValidations(saveValidations);
  };

  const onResumeEditing = () => {
    if (isShowingResumeEditing === true) {
      setIsShowingResumeEditing(false);
      setIsSaving(false);
    }
  };

  const footerMessage = () => {
    if (isSaving && screenIndex === 0) {
      return (
        <div className={styles.savingUsersIndication}>
          <div className={styles.spinner}>
            <Spinner size={SPINNER_SIZES.SMALL} />
          </div>
          <span className={styles.text}>We are creating the users</span>
        </div>
      );
    }
    if (screenIndex === 1 && validations?.roles?.length > 0) {
      return (
        <div className={styles.assignRoleErrorIndication}>
          <GenerateIcon iconName={ICONS.triangleExclamation.name} />
          <span className={styles.text}>Error assigning the roles</span>
        </div>
      );
    }
  };

  return (
    <CustomModal
      automationId="addUsersModal"
      className={styles}
      closeOnSave={false}
      footerMessage={footerMessage()}
      headerMode={MODAL_MODES.ADD}
      isSecondPrimaryError={false}
      onSecondPrimaryClick={onResumeEditing}
      onSave={setNextPage}
      open={isOpen}
      onClose={() => {}}
      onCloseClick={onClose}
      saveDisabled={
        isSaving ||
        (screenIndex === 0 && validations?.users?.length > 0) ||
        (screenIndex === 1 && validations?.roles?.length > 0)
      }
      saveTitle={screenIndex === 0 ? 'Create' : 'Done'}
      saveHidden={screenIndex === 0 && isSaving}
      secondPrimaryTitle={isShowingResumeEditing ? 'Resume Editing' : undefined}
      cancelHidden={isShowingResumeEditing}
      title={`Add Users to ${accountName}`}
    >
      <div className={styles.stepperContainer}>
        <Stepper activeStepIndex={screenIndex} steps={ADD_USERS_STEPS_CONFIG} />
      </div>
      <div className={styles.addUsersModalContainer}>
        {Page && (
          <Page
            isShowingResumeEditing={isShowingResumeEditing}
            rolesToAssign={rolesToAssign}
            saveClicked={isSaving}
            savedUsers={savedUsers}
            setRolesToAssign={setRolesToAssign}
            setEmailsToSave={setEmailsToSave}
            validationErrors={validations}
            setValidationErrors={setValidations}
          />
        )}
      </div>
    </CustomModal>
  );
};

AddUsersModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
};

export default AddUsersModal;
