import React from 'react';
import PropTypes from 'prop-types';
import CustomModal from 'shared/components/andtComponents/Modal';
import Tooltip from 'shared/components/andtComponents/Tooltip';
import { awsCommonFieldToDisplayField, AwsCommonFields } from 'shared/constants/awsConstants';
import LabelCoordinator from 'shared/modules/labelCoordinator';
import Input from 'shared/components/andtComponents/Input';
import Button from 'shared/components/andtComponents/Button';
import ErrorMessage from 'shared/components/andtComponents/ErrorMessage';
import { ReactComponent as InfoComponent } from 'shared/img/icons/info.svg';
import { useUserSettingsContext } from 'users/utils/contexts/UserSettingsContext';
import RecipientsSelect from 'shared/components/RecipientsSelect';
import useChannels from 'shared/components/slackIntegration/hooks/useChannels';
import { validateAlertRule, prepareAlertRule, initiateAlertRule } from '../anomalyDetectionHelpers';
import AlertRuleFilter from './AlertRuleFilter';
import styles from './addAlertRuleModalLegacy.module.scss';

const AddAlertRuleModal = ({
  selectedAlertRule = null,
  open = false,
  handleSaveAlertRule,
  onClose,
  divisions = [],
  linkedAccounts = [],
  services = [],
  getMyEmail,
  isLoading = false,
  alertRules = [],
}) => {
  const alertRuleNames = alertRules
    .filter(({ uuid }) => uuid !== selectedAlertRule?.uuid)
    .map(({ ruleName }) => ruleName);
  const [alertRule, setAlertRule] = React.useState(
    initiateAlertRule(selectedAlertRule, { services, divisions, linkedAccounts }),
  );
  const [alertRuleValidationErrors, setAlertRuleValidation] = React.useState(null);
  const [saveClicked, setSaveClicked] = React.useState(false);
  const { currencySymbol } = useUserSettingsContext();
  const { getChannels } = useChannels();
  const { data: channels, isLoading: isLoadingChannels } = getChannels();

  React.useEffect(() => {
    const alertRuleValidation = validateAlertRule(alertRule, alertRuleNames);
    setAlertRuleValidation(alertRuleValidation);
  }, [alertRule]);

  const setEmails = (email) => {
    setAlertRule((prevAlert) => ({ ...prevAlert, email }));
  };
  const setRecipients = (recipients) => {
    setAlertRule((prevAlert) => ({ ...prevAlert, recipients }));
  };
  const addMyEmail = () => {
    const myEmail = getMyEmail();
    if (!alertRule.email.length) {
      setEmails(myEmail);
      return;
    }
    const email = alertRule.email.split(',').map((currentEmails) => currentEmails.trim());
    if (!email.some((currentEmails) => currentEmails === myEmail.trim())) {
      email.push(myEmail);
      setEmails(email.join(', '));
    }
  };

  const handleSetAlertRule = (field, value) => {
    setAlertRule((prevAlertRule) => ({ ...prevAlertRule, [field]: value }));
  };
  const handleSetAlertRuleNumberValues = (field, value) => {
    if (!Number.isNaN(+value)) {
      setAlertRule((prevAlertRule) => ({ ...prevAlertRule, [field]: value }));
    }
  };

  const onSave = async () => {
    setSaveClicked(true);
    if (alertRuleValidationErrors) {
      return;
    }
    const preparedAlertRule = prepareAlertRule(alertRule);
    await handleSaveAlertRule(preparedAlertRule);
  };

  const setFilters = (filterType, field, val) => {
    setAlertRule(({ filters: { [filterType]: oldFilterType, ...restFilters }, ...restAlertRule }) => ({
      ...restAlertRule,
      filters: { ...restFilters, [filterType]: { ...oldFilterType, [field]: val } },
    }));
  };

  return (
    <CustomModal
      open={open}
      onClose={onClose}
      title={`${selectedAlertRule ? 'Edit' : 'New'} Alert Rule`}
      saveTitle="Save"
      onSave={onSave}
      saveDisabled={isLoading || (saveClicked && !!alertRuleValidationErrors)}
      closeOnSave={false}
      className={styles}
      automationId="addAlertRuleModal"
    >
      <div className="d-flex flex-column">
        <div className="d-flex flex-column mb-3">
          <h5>Alert Rule Name</h5>
          <Input
            className={styles.nameInput}
            value={alertRule.ruleName}
            onChange={(e) => handleSetAlertRule('ruleName', e.target.value)}
            isInvalid={saveClicked && alertRuleValidationErrors && !!alertRuleValidationErrors.ruleName}
            automationId="alert-rule-name-input"
            invalidComponent={
              <ErrorMessage
                displayError={saveClicked && alertRuleValidationErrors && !!alertRuleValidationErrors.ruleName}
                errMsg={alertRuleValidationErrors && alertRuleValidationErrors.ruleName}
                automationId="alert-rule-name-input-error"
              />
            }
            placeholder="Alert Rule Name"
            type="text"
          />
        </div>
        <div className="d-flex">
          <div className="d-flex flex-column">
            <div className="d-flex mb-1">
              <div className="d-flex flex-column me-2">
                <div className="d-flex align-items-center">
                  <h5 className={`me-1 ${styles.impactCostTitle}`}>Min. Cost impact ({currencySymbol})</h5>
                  <Tooltip
                    placement="top"
                    title="Represents the difference between the anomalous data point and the previous normal value."
                    arrow
                  >
                    <div className="d-flex align-items-center">
                      <InfoComponent />
                    </div>
                  </Tooltip>
                </div>
                <div className={styles.impactInput}>
                  <Input
                    className={`${styles.inputHeight} ${styles.impactInput}`}
                    value={alertRule.minAmountChange}
                    onChange={(e) => handleSetAlertRuleNumberValues('minAmountChange', e.target.value)}
                    isInvalid={saveClicked && alertRuleValidationErrors && !!alertRuleValidationErrors.minImpact}
                    placeholder="1000"
                    type="number"
                    automationId="min-impact-cost-input"
                    min={50}
                  />
                </div>
              </div>
              <span className={styles.and}>&</span>
              <div className="d-flex flex-column ms-2">
                <div className="d-flex align-items-center">
                  <h5 className="me-1">Min. Cost delta (%)</h5>
                  <Tooltip
                    placement="top"
                    title="Represents the difference between the anomalous data point and the previous normal value."
                    arrow
                  >
                    <div className="d-flex align-items-center">
                      <InfoComponent />
                    </div>
                  </Tooltip>
                </div>
                <div className={styles.impactInput}>
                  <Input
                    className={`${styles.inputHeight} ${styles.impactInput}`}
                    value={alertRule.minPercentChange}
                    onChange={(e) => handleSetAlertRuleNumberValues('minPercentChange', e.target.value)}
                    isInvalid={saveClicked && alertRuleValidationErrors && !!alertRuleValidationErrors.minImpact}
                    placeholder="1000"
                    type="text"
                    automationId="min-impact-percent-input"
                  />
                </div>
              </div>
            </div>
            <ErrorMessage
              displayError={saveClicked && alertRuleValidationErrors && !!alertRuleValidationErrors.minImpact}
              errMsg={alertRuleValidationErrors && alertRuleValidationErrors.minImpact}
              automationId="min-impact-input-error"
            />
            <span className={styles.impactExplanation}>
              Enter either Cost Impact {currencySymbol} amount, Cost delta % amount, or both.
            </span>
          </div>
        </div>
        <div className={`d-flex flex-column ${styles.filtersContainer}`}>
          <div className={`${styles.or} ${styles.internalContainer}`}>
            {!!divisions?.length && (
              <div className="d-flex flex-column">
                <div className="d-flex justify-content-between">
                  <div className="d-flex align-items-center">
                    <h5 className="me-1">
                      {LabelCoordinator.getFieldLabel(awsCommonFieldToDisplayField.get(AwsCommonFields.DIVISION))}
                    </h5>
                    <Tooltip
                      placement="top"
                      // eslint-disable-next-line max-len
                      title={`Once a ${LabelCoordinator.getFieldLabel(
                        awsCommonFieldToDisplayField.get(AwsCommonFields.DIVISION),
                      )} is included, all of their attached Linked Accounts will be automatically included`}
                      arrow
                    >
                      <div className="d-flex align-items-center">
                        <InfoComponent />
                      </div>
                    </Tooltip>
                  </div>
                  <span className={`ms-auto ${styles.optional}`}>(optional)</span>
                </div>
                <AlertRuleFilter
                  filterType="division"
                  relation={alertRule.filters.division.relation}
                  values={alertRule.filters.division.values}
                  options={divisions}
                  setFilter={setFilters}
                  displayError={saveClicked && alertRuleValidationErrors && alertRuleValidationErrors.division}
                  errMsg={alertRuleValidationErrors && alertRuleValidationErrors.division}
                />
              </div>
            )}
            {!!divisions?.length && (
              <div className={`${styles.explanationTag}`}>
                <span>OR</span>
              </div>
            )}
            <div className="d-flex flex-column mb-2">
              <div className="d-flex justify-content-between">
                <h5>Linked Account</h5>
                <span className={`ms-auto ${styles.optional}`}>(optional)</span>
              </div>
              <AlertRuleFilter
                filterType="linkedaccid"
                relation={alertRule.filters.linkedaccid.relation}
                values={alertRule.filters.linkedaccid.values}
                options={linkedAccounts}
                setFilter={setFilters}
                displayError={saveClicked && alertRuleValidationErrors && alertRuleValidationErrors.linkedaccid}
                errMsg={alertRuleValidationErrors && alertRuleValidationErrors.linkedaccid}
              />
            </div>
          </div>
          <div className={`${styles.internalContainer}`}>
            <div className={`${styles.explanationTag}`}>
              <span>AND</span>
            </div>
            <div className="d-flex flex-column">
              <div className="d-flex justify-content-between">
                <h5>Service</h5>
                <span className={`ms-auto ${styles.optional}`}>(optional)</span>
              </div>
              <AlertRuleFilter
                filterType="service"
                relation={alertRule.filters.service.relation}
                values={alertRule.filters.service.values}
                options={services}
                setFilter={setFilters}
                displayError={saveClicked && alertRuleValidationErrors && alertRuleValidationErrors.service}
                errMsg={alertRuleValidationErrors && alertRuleValidationErrors.service}
              />
            </div>
          </div>
        </div>
        <div className={`d-flex flex-column ${styles.recipientEmailsContainer}`}>
          <div className="d-flex align-items-center justify-content-between">
            <div className="d-flex">
              <h5 className="me-1">Recipients ({alertRule.email?.split(',').filter((str) => str.trim()).length})</h5>
              <Tooltip placement="top" title="Use a comma delimiter in case of multiple email addresses" arrow>
                <div className="d-flex align-items-center">
                  <InfoComponent />
                </div>
              </Tooltip>
            </div>
            <Button
              type="button"
              text="My Email"
              onClick={addMyEmail}
              overrideStyles={{ backgroundColor: 'transparent', color: '#2671FF', fontSize: '12px' }}
              disabled={alertRule.email?.split(',').some((email) => email.trim() === getMyEmail().trim())}
            />
          </div>
          <div className={`d-flex flex-column ${styles.email}`}>
            <Input
              className={styles.inputHeight}
              value={alertRule.email}
              onChange={(e) => setEmails(e.target.value)}
              isInvalid={saveClicked && alertRuleValidationErrors && !!alertRuleValidationErrors.email}
              placeholder="example@gmail.com, example@gmail.com "
              name="email"
              automationId="recipient-emails-input"
            />
          </div>
          <RecipientsSelect
            channels={channels}
            isLoading={isLoadingChannels}
            savedRecipients={alertRule.recipients}
            onChangeHandler={setRecipients}
          />
          <ErrorMessage
            displayError={saveClicked && alertRuleValidationErrors && !!alertRuleValidationErrors.email}
            errMsg={alertRuleValidationErrors && alertRuleValidationErrors.email}
          />
        </div>
      </div>
    </CustomModal>
  );
};

AddAlertRuleModal.propTypes = {
  selectedAlertRule: PropTypes.object,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool,
  divisions: PropTypes.array,
  linkedAccounts: PropTypes.array,
  services: PropTypes.array,
  alertRules: PropTypes.array,
  handleSaveAlertRule: PropTypes.func.isRequired,
  getMyEmail: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
};

export default AddAlertRuleModal;
