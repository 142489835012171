/* eslint-disable no-unused-vars */
import { isFamilyTypeHasTypes } from 'shared/utils/cloudUtils';
import { CostUsageStates } from 'usage/constants/costAndUsageConstants';
import BaseCostUsageDataState from './baseCostUsageDataState';

export default class BenefitDataState extends BaseCostUsageDataState {
  constructor(context) {
    super(context);
  }

  getNewDataStateType(dataKey) {
    return CostUsageStates.SERVICE;
  }

  getDataStateType() {
    return CostUsageStates.PARTNER_CREDIT_RATE;
  }
}
