import React from 'react';
import PropTypes from 'prop-types';
import CustomModal from 'shared/components/andtComponents/Modal';
import Button from 'shared/components/andtComponents/Button';
import { ReactComponent as NoteImage } from './img/note.svg';

import styles from './noteModal.module.scss';

const NoteModal = ({ title, message, btnText, close, cancel }) => (
  <CustomModal
    open
    onClose={cancel}
    overrideStyles={{
      width: 560,
    }}
    headerIcon={<NoteImage height={45} width={45} />}
    headerMode="add"
    closeOnOutside={true}
    title={title || 'Please note'}
    footerDisabled
  >
    <div className={styles.modalWrapper}>
      <p className={styles.msg}>{message}</p>
      <div className={styles.buttonContainer}>
        <Button text={btnText} onClick={close} />
      </div>
    </div>
  </CustomModal>
);

NoteModal.propTypes = {
  title: PropTypes.string,
  message: PropTypes.string.isRequired,
  btnText: PropTypes.string.isRequired,
  close: PropTypes.func.isRequired,
  cancel: PropTypes.func.isRequired,
};

export default NoteModal;
