import { useQuery } from '@tanstack/react-query';
import apiConstants from 'shared/api/apiConstants';
import { API } from 'shared/utils/apiMiddleware';
import { CompanyT } from '../types.ts';

const root = '/api/v1/admin/companies';
const queryKey = [apiConstants.QUERY_KEYS.COMPANIES];
const fetchCompaniesList = (): Promise<CompanyT[]> => API.get('billings', root);
const useFetchCompaniesList = () => useQuery(queryKey, fetchCompaniesList);

export default useFetchCompaniesList;
