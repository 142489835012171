import { useQuery } from '@tanstack/react-query';
import { API } from 'shared/utils/apiMiddleware';
import apiConstants from 'shared/api/apiConstants';
import DateFilter from 'shared/modules/dateFilter';
import { buildFilterParams } from 'shared/utils/apiUtil';
import { prepareFiltersMap } from 'recommendations/utils/recommUtils';
import { AWS_RECOMMENDATIONS_ROOT, BILLINGS_API_NAME } from 'recommendations/hooks/react-query/api';
import moment from 'moment';
import { queryClient } from 'queryClient';

const fetchDashboardSummaryData = async (filterParams, lastProcessDate, limit) =>
  API.get(
    BILLINGS_API_NAME,
    `${AWS_RECOMMENDATIONS_ROOT}/dashboard/summary-table?${
      filterParams ? `${filterParams}&` : ''
    }lastProcessDate=${lastProcessDate}${limit ? `&limit=${limit}` : ''}`,
  );

export const fetchDashboardSummaryTableData = async (filterMap, lastProcessDate, limit) => {
  try {
    const prepFilterMap = prepareFiltersMap(filterMap);
    const filterParams = buildFilterParams(prepFilterMap);
    return fetchDashboardSummaryData(filterParams, lastProcessDate, limit);
  } catch (error) {
    throw error;
  }
};

export default function useDashboardSummary({ isEnabled, filterMap, lastProcessDate, isPpApplied, limit }) {
  const queryKey = [
    apiConstants.QUERY_KEYS.RECOMMENDATION_DASHBOARD_SUMMARY,
    {
      filterMap,
      lastProcessDate: moment(lastProcessDate || DateFilter.getDate()),
      isPpApplied,
      limit,
    },
  ];

  return {
    invalidate: () => queryClient.invalidateQueries(queryKey),
    reset: () => queryClient.resetQueries(queryKey),
    fetchDashboardSummaryData: (currentLimit) =>
      useQuery(
        queryKey,
        () => fetchDashboardSummaryTableData(filterMap, queryKey[1].lastProcessDate, currentLimit || limit),
        {
          enabled: isEnabled,
          retry: false,
        },
      ),
  };
}
