import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  CLOSED_AND_DONE_RECS_DATES_FILTERS,
  CLOSED_AND_DONE_RECS_DATES_OPERATOR,
  DEFAULT_RECS_DATE_RANGE,
  FILTERS,
} from 'recommendationsNew/consts';
import { useRecommendationsNewContext } from 'recommendationsNew/contexts/recommendationsNewContext';
import SelectedFilterDisplay from './selectedFilterDisplay';
import { isFilterEqualToEmptyState } from '../filterStates';

import classes from './heatMapSelectedFiltersDisplay.module.scss';

const HeatMapSelectedFiltersDisplay = ({ unsetCurrentFilter }) => {
  const {
    recommendationFilters: filtersContext,
    setRecommendationFilters: setFiltersContext,
  } = useRecommendationsNewContext();

  const selectedFiltersKeys = Object.keys(filtersContext);

  const isEmptyFilter = () => isFilterEqualToEmptyState(filtersContext);

  const removeSelectedFilter = ({ filter, statusFilter = null, isDropDown = false }) => {
    const newFilters = { ...filtersContext };
    if (filter === FILTERS.USER_STATUS.id) {
      newFilters[filter][statusFilter] = null;
    } else if (filter === FILTERS.OPEN_RECS_CREATION_DATE.id) {
      newFilters[filter] = DEFAULT_RECS_DATE_RANGE;
    } else if (filter === FILTERS.CLOSED_AND_DONE_RECS_DATES.id) {
      newFilters[filter] = {
        [CLOSED_AND_DONE_RECS_DATES_FILTERS.LAST_UPDATE_DATE]: DEFAULT_RECS_DATE_RANGE,
        [CLOSED_AND_DONE_RECS_DATES_FILTERS.OPERATOR]: CLOSED_AND_DONE_RECS_DATES_OPERATOR.AND,
      };
    } else if (isDropDown) {
      delete newFilters[filter];
      if (newFilters?.excludedAdditionalFilters?.[filter]) {
        delete newFilters.excludedAdditionalFilters[filter];
        if (Object.keys(newFilters?.excludedAdditionalFilters)?.length === 0) {
          delete newFilters.excludedAdditionalFilters;
        }
      }
    } else {
      newFilters[filter] = null;
    }
    setFiltersContext(newFilters);
    unsetCurrentFilter();
  };

  const [isOpen, setIsOpen] = useState(Array(selectedFiltersKeys?.length).fill(false));
  const setIsOpenAtIndex = (index, newValue) => {
    const newIsOpen = [...isOpen];
    newIsOpen[index] = newValue;
    setIsOpen(newIsOpen);
  };
  return selectedFiltersKeys?.length && !isEmptyFilter() ? (
    <div className={`${classes.selectedFiltersDisplay}`}>
      {selectedFiltersKeys?.map((filter, index) => (
        <SelectedFilterDisplay
          selectedFilter={filter}
          removeSelectedFilter={removeSelectedFilter}
          isOpen={isOpen[index]}
          setIsOpen={(val) => setIsOpenAtIndex(index, val)}
          key={filter}
        />
      ))}
    </div>
  ) : (
    <div className={classes.noFiltersToDisplay}>No filters selected...</div>
  );
};

HeatMapSelectedFiltersDisplay.propTypes = {
  unsetCurrentFilter: PropTypes.func.isRequired,
};

export default HeatMapSelectedFiltersDisplay;
