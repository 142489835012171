import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import styles from './ProgressLoader.module.scss';

const ProgressLoader = ({ steps, currentStep, listClassName = '', className = '' }) => (
  <div className={classNames(styles.loader, className)}>
    <div className={listClassName}>
      {steps.map((step, index) => (
        <span key={step} className={index === currentStep ? styles.active : ''}>
          {step.description}
        </span>
      ))}
    </div>
    <SwitchTransition>
      <CSSTransition
        key={currentStep}
        classNames="scale-in-out"
        addEndListener={(node, done) => {
          node.addEventListener('transitionend', done, false);
        }}
      >
        {steps[currentStep].image}
      </CSSTransition>
    </SwitchTransition>
  </div>
);

ProgressLoader.propTypes = {
  steps: PropTypes.array.isRequired,
  currentStep: PropTypes.number.isRequired,
  listClassName: PropTypes.string,
  className: PropTypes.string,
};

export default ProgressLoader;
