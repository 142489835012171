import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import HeatMapContent from 'recommendationsNew/components/heatMap/heatMapContent';
import HeatMapSummary from 'recommendationsNew/components/heatMap/heatMapSummary';
import HeatMapCloseButton from 'recommendationsNew/components/heatMap/heatMapCloseButton';
import useGroupByOptions from 'recommendationsNew/hooks/react-query/useGroupByOptions';
import { useHeatMapContext } from 'recommendationsNew/components/heatMap/contexts/heatMapContext';
import HeatMapFilters from 'recommendationsNew/components/heatMap/heatMapFilters/mainFilters/heatMapFilters';
import { GROUP_BY_OPTIONS } from 'recommendationsNew/consts';
import { useLocation } from 'react-router-dom';
import { useRecommendationsNewContext } from 'recommendationsNew/contexts/recommendationsNewContext';
import { transformKeysToSnakeCase } from 'shared/utils/strUtil';
import classNames from 'classnames';
import classes from './heatMap.module.scss';

const HeatMap = ({ usersStore }) => {
  const recommendationsGroupByOptionsHook = useGroupByOptions();
  const recommendationsGroupByOptions = recommendationsGroupByOptionsHook.fetchGroupByOptions();
  const { data: groupByOptionsData } = recommendationsGroupByOptions;

  const { setHeatMapGroupByOptions, isHeatMapClosed, setIsHeatMapClosed, setIsWasteChart } = useHeatMapContext();
  const { isDashboardPanel, setRecommendationFilters: setFiltersContext } = useRecommendationsNewContext();

  const { search, state: source } = useLocation();

  useEffect(() => {
    const searchParams = new URLSearchParams(search);
    const groupByParamFromUrl = searchParams.get('groupBy');

    if (!isDashboardPanel && !groupByParamFromUrl && groupByOptionsData?.length > 0) {
      const tmpOptions = groupByOptionsData
        ?.filter((option) => option.default === true)
        ?.map((option) => {
          if (
            option.id === GROUP_BY_OPTIONS.items.SERVICE?.id ||
            option.id === GROUP_BY_OPTIONS.items.TYPE_ID?.id ||
            option.id === GROUP_BY_OPTIONS.items.CAT_ID?.id
          ) {
            return { ...option, isPinned: true };
          }
          return option;
        });
      setHeatMapGroupByOptions(tmpOptions);
    }
  }, [groupByOptionsData]);

  useEffect(() => {
    if (source?.filterContext) {
      const transformedFilterContext = transformKeysToSnakeCase(source.filterContext);
      setFiltersContext(transformedFilterContext);
    }
    if (source?.groupBy) {
      setHeatMapGroupByOptions(source.groupBy);
    }
    if (source?.state?.isWasteChart !== null) {
      setIsWasteChart(source?.state?.isWasteChart);
    }
  }, [source]);

  const onCloseButtonClick = () => {
    setIsHeatMapClosed(!isHeatMapClosed);
  };

  return (
    <div className={classes.container}>
      <div className={classNames(classes.heatMapContainer, isDashboardPanel && classes.dashboardWrapper) } >
        <HeatMapFilters usersStore={usersStore} />
        {isHeatMapClosed ? <div /> : <HeatMapContent />}
        <HeatMapSummary />
      </div>
      {!isDashboardPanel && (
        <HeatMapCloseButton
          isClosed={isHeatMapClosed}
          onClick={onCloseButtonClick}
          automation-id="heatmap-close-open-button"
        />
      )}
    </div>
  );
};

HeatMap.propTypes = {
  usersStore: PropTypes.object.isRequired,
};

export default HeatMap;
