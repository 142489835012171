 
/* eslint-disable no-unused-vars */
import { CostUsageStates } from 'usage/constants/costAndUsageConstants';
import { AWS_QUANTITY_TYPE_SELECT, AwsQuantityTypes } from 'shared/constants/awsConstants';
import BaseCostUsageDataState from './baseCostUsageDataState';

export default class TypeDataState extends BaseCostUsageDataState {
  constructor(context) {
    super(context);
  }

  getNewDataStateType(dataKey) {
    const newDataState = CostUsageStates.OPERATION;
    return newDataState;
  }

  getDataStateType() {
    return CostUsageStates.TYPE;
  }

  getSupportedQuantityTypes = () => [
    AWS_QUANTITY_TYPE_SELECT,
    AwsQuantityTypes.HOURS,
    AwsQuantityTypes.REQUESTS,
    AwsQuantityTypes.BYTES,
    AwsQuantityTypes.OTHER,
    AwsQuantityTypes.QUANTITY,
  ];
}
