import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import apiConstants from 'shared/api/apiConstants';
import { API } from 'shared/utils/apiMiddleware';
import { getEmailFromRecipients, prepareNewRecipients } from 'shared/utils/sharedUtils.js';
import { CommitmentExpAlert, ServiceCostAlert, ServiceCostAlertUpdate, UpdatedCommitmentAlert } from '../types.ts';

const root = '/api/v1/usage/alerts';

const fetchAlertsList = (): Promise<(CommitmentExpAlert | ServiceCostAlert)[]> =>
  API.get('billings', root).then((data) =>
    data.map((alert: CommitmentExpAlert | ServiceCostAlert) => ({
      ...alert,
      recipientsEmails: getEmailFromRecipients(alert.recipients),
    })),
  );

const checkSPPlans = (): Promise<boolean> =>
  API.get('billings', `${root}/check-sp-plans`).then((spRecordsLength) => {
    const parsedLength = parseInt(spRecordsLength, 10);
    return !Number.isNaN(parsedLength) && parsedLength > 0;
  });

const deleteAlert = (deletedAlertId: string) => () => API.del('billings', `${root}/${deletedAlertId}`);

const updateAlerts = (payload: UpdatedCommitmentAlert[] | ServiceCostAlertUpdate[]): Promise<any> =>
  API.post('billings', root, { body: payload });

export const useAlerts = () => {
  const queryClient = useQueryClient();
  return {
    fetchAlertsList: () => useQuery([apiConstants.QUERY_KEYS.CUE_ALERTS], fetchAlertsList),
    checkSPPlansExist: () =>
      useQuery([apiConstants.QUERY_KEYS.CUE_ALERTS, 'checkPlan'], checkSPPlans, { enabled: false }),
    setAlertsLocally: (updatedAlerts: (CommitmentExpAlert | ServiceCostAlert)[]) => {
      queryClient.setQueryData([apiConstants.QUERY_KEYS.CUE_ALERTS], () => updatedAlerts);
    },
    deleteAlert: (deletedAlertId: string) =>
      useQuery([`${apiConstants.QUERY_KEYS.CUE_ALERTS}Delete`], deleteAlert(deletedAlertId), { enabled: false }),
    updateAlerts: (onSuccess?: (d: any) => void) =>
      useMutation(
        [`${apiConstants.QUERY_KEYS.CUE_ALERTS}Update`],
        (alerts: UpdatedCommitmentAlert[] | ServiceCostAlertUpdate[]) =>
          updateAlerts(
            alerts.map((alert) => ({
              ...alert,
              recipients: prepareNewRecipients({ email: alert.recipientsEmails, recipients: alert.recipients }),
            })),
          ),
        {
          onSuccess: (d) => {
            onSuccess?.(d);
          },
        },
      ),
  };
};
