import React, { useState } from 'react';
import PropTypes from 'prop-types';
import CustomModal from 'shared/components/andtComponents/Modal';
import Input from 'shared/components/andtComponents/Input';
import ErrorMessage from 'shared/components/andtComponents/ErrorMessage';
import SwitchButton from 'shared/components/andtComponents/Switch';
import Tooltip from 'shared/components/andtComponents/Tooltip';
import { ReactComponent as InfoIcon } from 'shared/img/icons/info.svg';
import RadioButtonList from 'shared/components/andtComponents/RadioButtonList';
import checkFeatureFlag from 'shared/utils/featureFlagUtil';
import { ACCOUNT_FEATURES, CLOUD_TYPE_IDS } from 'users/constants/usersConstants';
import { useRootStore } from 'app/contexts/RootStoreContext';
import styles from './CreateBusinessMappingViewpointModal.module.scss';

const CreateBusinessMappingViewpointModal = ({
  takenNames,
  closeModal,
  isOpen,
  isEditMode = false,
  onSave,
  viewpoint = null,
  isLoading = false,
}) => {
  const { usersStore } = useRootStore();
  const [name, setName] = React.useState(viewpoint ? viewpoint.name : '');
  const [isK8S, setIsK8S] = useState(viewpoint ? viewpoint.k8s : false);
  const [k8sPods, setK8SPods] = useState(viewpoint && viewpoint.k8sConfig ? viewpoint.k8sConfig.podsView : false);
  const k8sSupport = checkFeatureFlag(usersStore, ACCOUNT_FEATURES.K8S);
  const isGCP = usersStore.currDispUserCloudAccountType === CLOUD_TYPE_IDS.GCP;
  const getModalTitle = () => {
    if (isEditMode) {
      return 'Edit Viewpoint';
    }
    return 'Create Viewpoint';
  };
  return (
    <CustomModal
      open={isOpen}
      onClose={closeModal}
      closeOnSave={false}
      title={getModalTitle()}
      saveDisabled={!name || isLoading || takenNames.includes(name)}
      onSave={async () => {
        await onSave({
          viewpoint: { name, k8s: isK8S, k8s_config: { pods_view: k8sPods } },
          id: isEditMode ? viewpoint.uuid : undefined,
        });
        closeModal();
      }}
      automationId="createEditViewpointModal"
    >
      <div className={styles.titleLine}>
        <label htmlFor="viewpoint-name" className={styles.input}>
          <span>Viewpoint name</span>
          <Input
            value={name}
            onChange={(e) => setName((e.target.value || '').slice(0, 90))}
            placeholder="Name"
            name="viewpoint-name"
            type="text"
          />
        </label>
      </div>
      {k8sSupport && !isEditMode && (
        <>
          <div className="d-flex align-items-center">
            <SwitchButton label="Kubernetes Viewpoint" isChecked={isK8S} onChange={() => setIsK8S(!isK8S)} />
            <Tooltip title="Base this Viewpoint on K8s Cost & Usage" arrow>
              <span className="ms-2">
                <InfoIcon />
              </span>
            </Tooltip>
          </div>
          {isK8S && isGCP && (
            <Tooltip title="Choose to query data by Nodes or Pods.">
              <div className={styles.radioButtonList}>
                <RadioButtonList
                  options={[
                    { value: 'nodes', label: 'Nodes', primary: true },
                    { value: 'pods', label: 'Pods', primary: true },
                  ]}
                  value={k8sPods ? 'pods' : 'nodes'}
                  onChange={(val) => {
                    setK8SPods(val === 'pods');
                  }}
                />
              </div>
            </Tooltip>
          )}
        </>
      )}
      {takenNames.includes(name) && <ErrorMessage displayError errMsg="Viewpoint with such name already exists." />}
    </CustomModal>
  );
};

CreateBusinessMappingViewpointModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.object.isRequired,
  isEditMode: PropTypes.bool,
  isLoading: PropTypes.bool,
  onSave: PropTypes.func.isRequired,
  viewpoint: PropTypes.object,
  takenNames: PropTypes.array.isRequired,
};

export default CreateBusinessMappingViewpointModal;
