import React from 'react';
import PropTypes from 'prop-types';
import CustomModal from './andtComponents/Modal';

const DeleteWarningModal = ({
  deletedItemName = '',
  isOpen,
  handleDelete,
  warningMessage = 'Be advise you are about to delete the item',
  modalTitle,
  executeButtonTitle = 'Delete',
  buttonDisabled = false,
  isShowDeleteButton = true,
}) => (
  <CustomModal
    open={isOpen || false}
    title={modalTitle || 'Delete Warning'}
    saveDisabled={!isShowDeleteButton || buttonDisabled}
    saveWarning
    saveTitle={executeButtonTitle}
    onSave={() => handleDelete('delete')}
    onClose={() => handleDelete('cancel')}
    save
  >
    {typeof warningMessage === 'function' ? warningMessage() : <span>{warningMessage} </span>}
    <b>{deletedItemName || ''}</b>
  </CustomModal>
);

export default DeleteWarningModal;

DeleteWarningModal.propTypes = {
  buttonDisabled: PropTypes.bool,
  deletedItemName: PropTypes.string,
  warningMessage: PropTypes.string,
  modalTitle: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  handleDelete: PropTypes.func.isRequired,
  executeButtonTitle: PropTypes.string,
  isShowDeleteButton: PropTypes.bool,
};
