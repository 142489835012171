import React from 'react';
import PropTypes from 'prop-types';
import useRoles from 'users/new-user-management/hooks/reactQuery/useRoles';
import Spinner from 'shared/components/andtComponents/Spinner';
import EffectivePermissions from '../../components/EffectivePermissions/EffectivePermissions';

const EffectivePermissionsTab = ({ row }) => {
  const { fetchRolePermissions, setRolePermissions } = useRoles();
  const { data, isLoading } = fetchRolePermissions(row?.id);

  const onPermissionChange = (categoryNewPermissions) => {
    onCategoriesPermissionsChanged([categoryNewPermissions]);
  };

  const onCategoriesPermissionsChanged = (categoriesNewPermissions) => {
    setRolePermissions.mutate({
      roleId: row?.id,
      roleCategoryPermissions: categoriesNewPermissions,
    });
  };

  return isLoading ? (
    <div className="position-relative">
      <Spinner />
    </div>
  ) : (
    <EffectivePermissions
      roleId={row?.id}
      permissions={data?.permissions}
      permissionChanged={onPermissionChange}
      actionPermissionForAllCategoriesChanged={onCategoriesPermissionsChanged}
    />
  );
};

EffectivePermissionsTab.propTypes = {
  row: PropTypes.shape({
    id: PropTypes.string,
  }),
};

export default EffectivePermissionsTab;
