import React from 'react';
import PropTypes from 'prop-types';
import RecommendedChart from 'commitment/containers/spAnalyzerNew/components/recommendedCharts/RecommendedChart.jsx';
import {
    ACTUAL_CHART,
    ACTUAL_CHART_LEGEND, ACTUAL_TP_MAP_DATA,
    ON_DEMAND_CHART,
    ON_DEMAND_CHART_LEGEND,
    ON_DEMAND_TP_MAP_DATA
} from 'commitment/containers/spAnalyzerNew/utils/consts.js';
import {
    getActualChartData,
    getOnDemandChartData
} from 'commitment/containers/spAnalyzerNew/utils/savingPlansAnalyzerUtils.js';

const SPRecommendedChart = ({ state, data }) => {
    const {isSimulateData, selectedPlan, spChartType} = state;
    const isActualChart = spChartType === 'actual';

    const prepareData = isActualChart ? getActualChartData(data[0], data[0]?.recommendationPerPlan?.[selectedPlan], isSimulateData)
        : getOnDemandChartData(data[0],  data[0]?.recommendationPerPlan?.[selectedPlan], isSimulateData)
    const settings = isActualChart ? ACTUAL_CHART : ON_DEMAND_CHART;
    const settingsLegendData = isActualChart ? ACTUAL_CHART_LEGEND : ON_DEMAND_CHART_LEGEND;
    const settingsMapData = isActualChart ? ACTUAL_TP_MAP_DATA : ON_DEMAND_TP_MAP_DATA;

    return (
        <div>
            <RecommendedChart
                legend={isSimulateData ? settingsLegendData.simulate : settingsLegendData.current}
                data={isSimulateData ? prepareData.simulateData : prepareData.currentData}
                includeEstimatedOption={isActualChart && isSimulateData}
                isAnimationActive={!isActualChart}
                info={settings[isSimulateData ? 'simulate': 'current'].info}
                header={settings[isSimulateData ? 'simulate': 'current'].header}
                tooltipMapData={isSimulateData ? settingsMapData.simulate : settingsMapData.current}
                automationId={`${spChartType}Chart`}
                chartType="monotone"
                isCurrentTab={!isSimulateData}
                isCurrentData={isActualChart && !isSimulateData}
                expirationEvents={!isSimulateData ? data[0].expirationEvents : {}}
                purchaseEvents={!isSimulateData ? data[0].purchaseEvents : {}}
                isDashboardPanel
                estimateMode={state.isEstimatedMode}
                dailyMode={state.isDailyMode}
            />
        </div>
    );
};

SPRecommendedChart.propTypes = {
    state: PropTypes.object.isRequired,
    data: PropTypes.object.isRequired,
};
export default SPRecommendedChart;
