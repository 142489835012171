import React from 'react';
import classNames from 'classnames';
import uniq from 'lodash/uniq';
import Checkbox from 'shared/components/andtComponents/Checkbox';
import PropTypes from 'prop-types';
import { useFilteredLinkedAccounts } from './hooks/useCounters';
import styles from './AccountsChooser.module.scss';
import { getAccountLabelById } from './utils';

const LinkedAccountsList = ({
  accounts,
  accountIdOpened,
  selectedAccounts,
  setSelectedAccounts,
  linkedAccounts,
  secondSearch = '',
}) => {
  const secondItemsFiltered = useFilteredLinkedAccounts(secondSearch, linkedAccounts, accountIdOpened);

  const getIsAccountSelected = (linkedAccountId) => {
    const account = selectedAccounts.find((acc) => acc.accountId === accountIdOpened);
    return account?.linkedAccountIds.includes(linkedAccountId) || account?.linkedAccountIds === 'all';
  };

  const handleLinkedAccountClick = (linkedAccountId, isSelected) => {
    const accSelected = selectedAccounts.find((acc) => acc.accountId === accountIdOpened);
    if (isSelected) {
      setSelectedAccounts((prev) =>
        prev.map((acc) =>
          acc.accountId === accountIdOpened
            ? {
                ...acc,
                linkedAccountIds: (acc.linkedAccountIds === 'all'
                  ? linkedAccounts.get(accountIdOpened).map((a) => a.linkedAccountId)
                  : acc.linkedAccountIds
                ).filter((la) => la !== linkedAccountId),
              }
            : acc,
        ),
      );
    } else {
      setSelectedAccounts((prev) =>
        accSelected
          ? prev.map((acc) =>
              acc.accountId === accountIdOpened
                ? {
                    ...acc,
                    linkedAccountIds: [...acc.linkedAccountIds, linkedAccountId],
                  }
                : acc,
            )
          : [
              ...prev,
              {
                accountId: accountIdOpened,
                linkedAccountIds: [linkedAccountId],
                label: getAccountLabelById(accounts, accountIdOpened),
              },
            ],
      );
    }
  };

  const renderSelectAllInAccountButton = (accountId) => {
    const itemSelected = selectedAccounts.find((s) => s.accountId === accountId);
    const allItemsSelected =
      itemSelected &&
      (itemSelected.linkedAccountIds === 'all' ||
        secondItemsFiltered.every((ln) => itemSelected.linkedAccountIds.includes(ln.linkedAccountId)));
    const totalCount = secondItemsFiltered.length;
    if (totalCount === 0) {
      return null;
    }
    const handleSelectAll = () => {
      if (allItemsSelected) {
        setSelectedAccounts((prev) => prev.filter((s) => s.accountId !== accountId));
      } else {
        const newLinkedAccounts =
          secondItemsFiltered.length === linkedAccounts.get(accountIdOpened)?.length
            ? 'all'
            : uniq([...secondItemsFiltered.map((s) => s.linkedAccountId), ...(itemSelected?.linkedAccountIds || [])]);
        if (itemSelected) {
          setSelectedAccounts((prev) =>
            prev.map((s) => (s.accountId === accountId ? { ...s, linkedAccountIds: newLinkedAccounts } : s)),
          );
        } else {
          setSelectedAccounts((prev) => [
            ...prev,
            {
              accountId,
              linkedAccountIds: newLinkedAccounts,
              label: getAccountLabelById(accounts, accountId),
            },
          ]);
        }
      }
    };
    return (
      <div className={classNames(styles.menuItem)} onClick={handleSelectAll}>
        <div className={styles.menuItemLabel}>
          <Checkbox isChecked={allItemsSelected} primary text="" onChange={handleSelectAll} />
          <span className={styles.selectAll}>Select All</span>
          <span className={styles.counter}>{totalCount}</span>
        </div>
      </div>
    );
  };

  return (
    <div className={styles.list}>
      {renderSelectAllInAccountButton(accountIdOpened)}
      {secondItemsFiltered.map(({ linkedAccountId, linkedAccountName }) => {
        const isSelected = getIsAccountSelected(linkedAccountId);
        return (
          <div
            className={classNames(styles.menuItem, styles.menuLnItem)}
            onClick={() => handleLinkedAccountClick(linkedAccountId, isSelected)}
            key={linkedAccountId}
          >
            <div className={styles.menuItemLabel}>
              <Checkbox
                isChecked={!!isSelected}
                onChange={(_, e) => {
                  e.stopPropagation();
                  handleLinkedAccountClick(linkedAccountId, isSelected);
                }}
                primary
                text={`${linkedAccountName} (${linkedAccountId})`}
              />
            </div>
          </div>
        );
      })}
    </div>
  );
};

LinkedAccountsList.propTypes = {
  accountIdOpened: PropTypes.string.isRequired,
  selectedAccounts: PropTypes.array.isRequired,
  setSelectedAccounts: PropTypes.func.isRequired,
  linkedAccounts: PropTypes.instanceOf(Map).isRequired,
  secondSearch: PropTypes.string,
  accounts: PropTypes.object.isRequired,
};

export default LinkedAccountsList;
