import React, { memo } from 'react';
import PropTypes from 'prop-types';

// eslint-disable-next-line react/display-name
const Icon = memo(({ size = 23, height = null, icon, className = '', matchHeight = false }) => {
  const styles = {
    img: {
      width: `${size}`,
      height: matchHeight ? `${height || size}` : '100%',
    },
  };
  if (icon && typeof icon !== 'string') {
    if (!React.isValidElement(icon)) {
      const IconComp = icon;
      return <IconComp />;
    }
    return icon;
  }
  return <img className={className} style={styles.img} src={icon} alt="img" />;
});

Icon.propTypes = {
  size: PropTypes.string,
  height: PropTypes.string,
  icon: PropTypes.oneOfType([PropTypes.string, PropTypes.func]).isRequired,
  className: PropTypes.string,
  matchHeight: PropTypes.bool,
};

export default Icon;
