import React, { useContext, useEffect } from 'react';
import {
  mapDeliveryFrequencyToDefaultDeliveryTime,
  WeekDays,
  FrequencyOptions,
  ReportSendFrequency,
} from 'usage/constants/costAndUsageConstants';
import DatePicker from 'react-datepicker';
import { Collapse } from 'reactstrap';
import Input from 'shared/components/andtComponents/Input';
import Select from 'shared/components/Select';
import { CalendarIcon } from 'mdi-react';
import { ReportContext } from 'shared/components/reportModal/ReportModal';
import { validateNumberInput } from 'shared/components/reportModal/reportsUtil';
import { dateToStr } from 'shared/utils/dateUtil';
import styles from './ReportModal.module.scss';

const DeliverySelector = () => {
  const { reportData, setReportData, data } = useContext(ReportContext);

  useEffect(() => {
    setReportData((prev) => ({
      ...prev,
      delivery: {
        frequency:
          data.reportFreq === null || data.reportFreq === undefined ? ReportSendFrequency.NONE : +data.reportFreq,
        time: +data.frequencyDeliveryTime,
        customFrequency: data.customFrequencyInDays,
        customStartDate: data.firstDate ? data.firstDate : dateToStr(new Date(), 'yyyy-mm-dd'),
      },
    }));
  }, [data?.delivery, data?.reportFreq, data?.frequencyDeliveryTime, data?.customFrequencyInDays, data?.firstDate]);

  const getIsCollapsed = () =>
    reportData.delivery
      ? [ReportSendFrequency.MONTHLY, ReportSendFrequency.WEEKLY, ReportSendFrequency.CUSTOM].includes(
          reportData.delivery.frequency,
        )
      : false;

  // eslint-disable-next-line react/prop-types
  const RenderDatePickerValue = ({ value, onClick }) => (
    <div onClick={onClick} style={{ display: 'flex' }}>
      <span>{value}</span>
      <CalendarIcon size={18} />
    </div>
  );

  const RenderDeliveryTime = () => {
    const { delivery } = reportData;
    if (delivery && delivery.frequency === ReportSendFrequency.WEEKLY) {
      return (
        <div className={styles.halfField}>
          <Select
            className={styles.select}
            value={delivery.time}
            onChange={(event) => {
              setReportData((prev) => ({ ...prev, delivery: { ...prev.delivery, time: event.target.value } }));
            }}
            options={WeekDays}
          />
        </div>
      );
    }
    if (delivery && delivery.frequency === ReportSendFrequency.MONTHLY) {
      return (
        <div>
          <div className={styles.fieldTitle}>Each # of Month:</div>
          <Input
            type="number"
            className={styles.input}
            name="deliveryTime"
            id="deliveryTime"
            placeholder="1st - 31st"
            onChange={(event) => {
              const { target } = event;
              if (target) {
                setReportData((prev) => ({
                  ...prev,
                  delivery: { ...prev.delivery, time: validateNumberInput(target.value, 1, 31) },
                }));
              }
            }}
            value={delivery.time}
          />
        </div>
      );
    }
    if (delivery && delivery.frequency === ReportSendFrequency.CUSTOM) {
      return (
        <div className={styles.customWrapper}>
          <div className={styles.inlineField}>
            <div className={styles.fieldTitle}>Every # day:</div>
            <Input
              type="number"
              className={styles.input}
              name="deliveryTime"
              id="deliveryTime"
              placeholder="1 - 180 days"
              onChange={(event) => {
                const { target } = event;
                if (target) {
                  setReportData((prev) => ({
                    ...prev,
                    delivery: { ...prev.delivery, customFrequency: validateNumberInput(target.value, 1, 180) },
                  }));
                }
              }}
              value={delivery.customFrequency}
              overrideStyles={{ minWidth: 'unset' }}
            />
          </div>
          <div className={styles.inlineField}>
            <div className={styles.fieldTitle}>Start from</div>
            <div className={styles.datePickerWrapper}>
              <DatePicker
                selected={delivery.customStartDate ? new Date(delivery.customStartDate) : new Date()}
                onChange={(date) => {
                  setReportData((prev) => ({
                    ...prev,
                    delivery: { ...prev.delivery, customStartDate: date },
                  }));
                }}
                customInput={<RenderDatePickerValue />}
              />
            </div>
          </div>
        </div>
      );
    }
    return <></>;
  };
  return (
    <div className={styles.frequencyWrapper}>
      <div className={styles.frequency}>
        <div className={styles.fieldTitle}>Frequency</div>
        <div className={styles.smallSelect}>
          <Select
            value={reportData.delivery ? reportData.delivery.frequency : null}
            onChange={(event) => {
              setReportData((prev) => ({
                ...prev,
                delivery: {
                  ...prev.delivery,
                  frequency: +event.target.value,
                  time: mapDeliveryFrequencyToDefaultDeliveryTime.get(+event.target.value),
                  customFrequency: +event.target.value === ReportSendFrequency.CUSTOM ? 1 : prev.customFrequency,
                },
              }));
            }}
            options={FrequencyOptions}
          />
        </div>
      </div>
      <Collapse isOpen={getIsCollapsed()}>{RenderDeliveryTime()}</Collapse>
    </div>
  );
};

export default DeliverySelector;
