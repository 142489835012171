import React from 'react';
import PropTypes from 'prop-types';
import {
    BASE_PROPERTIES, COST_PROPERTIES
} from 'recommendationsNew/components/detailedRecommendation/recommendationPropertyUtils.js';
import RecommendationDetailsLayout
    from 'recommendationsNew/components/detailedRecommendation/recommendationDetailsLayout.jsx';
import RecommendationInsideLook
    from 'recommendationsNew/components/detailedRecommendation/recommendationGenericComponents/recommendationInsideLook.jsx';
import RecommendationDetails
    from 'recommendationsNew/components/detailedRecommendation/recommendationGenericComponents/recommendationDetails.jsx';
import RecommendationPreferences
    from 'recommendationsNew/components/detailedRecommendation/recommendationGenericComponents/recommendationPreferences.jsx';
import RecommendationCommand, {
    COMMAND_TYPES
} from 'recommendationsNew/components/detailedRecommendation/recommendationGenericComponents/recommendationCommand.jsx';


const CLOUD_WATCH_PROPERTIES = {
    LOG_TYPE: {
        label: 'Log Type',
        getterFunction: (recommendation) => recommendation?.recData?.log_type,
    },
    LOG_CLASS: {
        label: 'Log Class',
        getterFunction: (recommendation) => recommendation?.recData?.log_class,
    },
    NEW_LOG_CLASS: {
        label: 'New Log Class',
        getterFunction: (recommendation) => recommendation?.recData?.new_log_class,
    },
}
const attentionComment = (
    <div>
        <span>Note, this class has limited feature support. Review </span>
         <a href="https://docs.aws.amazon.com/AmazonCloudWatch/latest/logs/CloudWatch_Logs_Log_Classes.html">
            AWS documentation
         </a>
        <span> for more details before changing the log class.</span>
    </div>
);
const CloudwatchLogsClassChange = ({ recommendation }) => {
    const properties = {
        ...BASE_PROPERTIES,
        ...COST_PROPERTIES,
        ...CLOUD_WATCH_PROPERTIES,
    };

    const description =
        'Your log group is currently using the Standard log class. By switching to the Infrequent Access class, you can reduce costs by at least 50%.';

    const recommendationWithAttentionComment = {
        ...recommendation,
        recData: {
            ...recommendation.recData,
            attention_comment: attentionComment,
        },
    };
    const insideLookComponent = <RecommendationInsideLook description={description} />;

    const detailsComponent = (
        <RecommendationDetails recommendationProperties={properties} recommendation={recommendationWithAttentionComment}  />
    );

    const commandsList = [
            {
                type: COMMAND_TYPES.CLI,
                comment: `Delete the existing log group, and create a new log group with the new group class./n
                  Optional: before deleting the existing log group, you can archive logs into S3 Glacier (will carry additional costs).`,
                hideNotice: true,
                instructions: [
                    {
                        actionText: '',
                        actionCommand: `aws logs delete-log-group --log-group-name <exist-log-group-name> -- region ${recommendation.region}`,
                    },
                    {
                        actionText: '',
                        actionCommand: `aws logs create-log-group --log-group-name <log-group-name>
                        --log-group-class ${recommendation.recData?.new_log_class} -- region ${recommendation.region}`
                    },
                ],
            },
        ];
    const cliCommandComponent = <RecommendationCommand commandsList={commandsList} />;

    const preferencesComponent = <RecommendationPreferences recommendationType={recommendation.typeId} />;

    return (
        <RecommendationDetailsLayout
            insideLook={insideLookComponent}
            command={cliCommandComponent}
            details={detailsComponent}
            preferences={preferencesComponent}
        />
    );
};

CloudwatchLogsClassChange.propTypes = {
    recommendation: PropTypes.object.isRequired,
};

export default CloudwatchLogsClassChange;
