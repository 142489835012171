/* eslint-disable no-unused-vars */
import BaseRecommendationBuilder from 'recommendations/models/builders/baseRecommBuilder';
import { CostRecommendation } from 'recommendations/models/recommTypes';
import { RecommendationCost } from 'recommendations/models/recommCommonTypes';
import { camelCase, mapKeys } from 'lodash';

export default class CostRecommendationBuilder extends BaseRecommendationBuilder {
  constructor(rawRecommendation) {
    super(rawRecommendation);
    if (rawRecommendation.type === 'rds-ri') {
      const a = 'stop';
    }
  }

  createRecommendation() {
    this._recommendation = new CostRecommendation();
  }

  build() {
    super.build();
    const {
      total_cost_current: currTotalCost,
      total_cost_recommended: recommTotalCost,
      potential_savings: potentialSavings,
      monthly_cost_current: monthlyCostCurrent,
      monthly_cost_recommended: monthlyCostRecommended,
      alternatives,
    } = this._rawRecommendation;
    const recommCost = new RecommendationCost(
      currTotalCost || monthlyCostCurrent,
      recommTotalCost || monthlyCostRecommended,
    );
    // FLAT OBJECT FOR NOW
    this._recommendation.potentialSavings = parseFloat(potentialSavings);
    this._recommendation.currTotalCost = parseFloat(recommCost.currYearlyCost); // Using yearly for total cost
    this._recommendation.recommTotalCost = parseFloat(recommCost.recommYearlyCost); // Using yearly for total cost
    this._recommendation.currMonthlyCost = parseFloat(recommCost.currMonthlyCost);
    this._recommendation.recommMonthlyCost = parseFloat(recommCost.recommMonthlyCost);
    this._recommendation.reducePercent = parseFloat(recommCost.reducePercent);
    this._recommendation.monthlyCostPotentialSavings =
      parseFloat(recommCost.currMonthlyCost) - parseFloat(recommCost.recommMonthlyCost);
    this._recommendation.costPotentialSavings =
      parseFloat(this._recommendation.currTotalCost) - parseFloat(this._recommendation.recommTotalCost);

    const camelCaseAlternatives = alternatives?.map((a) => mapKeys(a, (v, k) => camelCase(k)));
    this._recommendation.alternatives = camelCaseAlternatives?.map((a) => ({
      ...a,
      annualCost: parseFloat(a.annualCost),
      command: a.command,
      monthlyCost: parseFloat(a.monthlyCost),
      potentialSavings: parseFloat(a.potentialSavings),
      savingAmount: parseFloat(a.savingAmount),
    }));
  }
}
