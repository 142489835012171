import React, { createContext, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import getSymbolFromCurrency from 'currency-symbol-map';
import { DisplayMetricTypes } from 'usage/constants/costAndUsageConstants';
import { maxNumSizeAbbreviation, numberWithCommas, percentStrNoCommas, strNumToSize } from 'shared/utils/strUtil';
import { CURRENCY_DETAILS } from 'shared/constants/appConstants';
import { useRootStore } from 'app/contexts/RootStoreContext';

const UserDataContext = createContext();

export const UserSettingsProvider = ({ children }) => {
  const [currencyCode, setCurrencyCode] = useState(null);
  const [currencySymbol, setCurrencySymbol] = useState(getSymbolFromCurrency(CURRENCY_DETAILS.USD));
  const { usersStore } = useRootStore() || {};
  const numStrAbriviaionByDisplayMetric = (
    maxValue,
    value,
    displayMetric,
    // Please note that this prop is not in use by the
    // function and is kept for not refactoring all usage of the function
    overrideCurrencyCode = false,
    options = {
      showPercent: false,
      isTrunc: false,
    },
  ) => {
    const decimal = options.decimal || 1;
    // eslint-disable-next-line no-param-reassign
    value ??= 0;
    let sign = '';
    if (displayMetric === DisplayMetricTypes.COST) {
      sign = overrideCurrencyCode ? getSymbolFromCurrency(overrideCurrencyCode) : currencySymbol;
    }

    if (options.showPercent) {
      return percentStrNoCommas(value, decimal);
    }
    if (displayMetric === DisplayMetricTypes.BYTES) {
      return `${strNumToSize(value, decimal, usersStore?.currDispUserCloudAccountType)}`;
    }
    return `${sign} ${maxNumSizeAbbreviation(
      maxValue,
      options.isTrunc ? Math.trunc(parseFloat(value)) : parseFloat(value),
      options.decimal || 2,
    )}`;
  };

  const getCurrencyNumber = (value, decimalDigits = 2, options, overrideCurrencyCode = null) => {
    // eslint-disable-next-line no-param-reassign
    value ??= 0;
    const symbol = overrideCurrencyCode ? getSymbolFromCurrency(overrideCurrencyCode) : currencySymbol;
    if (typeof value === 'string') {
      return `${symbol} ${value}`;
    }
    const number = options?.roundNumber && value.toFixed(1).endsWith('0') ? value.toFixed(0) : value;
    const formatNumber = numberWithCommas(number, decimalDigits);
    return `${symbol} ${formatNumber}`;
  };

  const updateData = (currencyCode) => {
    setCurrencyCode(currencyCode);
    setCurrencySymbol(getSymbolFromCurrency(currencyCode || CURRENCY_DETAILS.USD));
  };

  return (
    <UserDataContext.Provider
      value={{ currencyCode, updateData, numStrAbriviaionByDisplayMetric, getCurrencyNumber, currencySymbol }}
    >
      {children}
    </UserDataContext.Provider>
  );
};

export default UserDataContext;

UserSettingsProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export function withUserSettingsConsumer(Component) {
  return function UserSettingsConsumer({ ...props }) {
    return <UserDataContext.Consumer>{(data) => <Component {...data} {...props} />}</UserDataContext.Consumer>;
  };
}

export const useUserSettingsContext = () => useContext(UserDataContext);
