import React, { memo } from 'react';
import { Card, Col } from 'reactstrap';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { removeDecPoint } from 'shared/utils/strUtil'
import { useUserSettingsContext } from 'users/utils/contexts/UserSettingsContext';
import { GenerateIcon, ICONS } from '@pileus-cloud/anodot-frontend-common';
import InfoPopover from 'shared/components/andtComponents/InfoPopover/index.jsx';
import PercentTag from 'app/containers/MainDashboard/components/PercentTag.jsx';
import styles from './usageCost.module.scss';



const UsageCost = memo(
    ({
         containerClassName='',
         withHeader=true,
         title,
         showIcon = false,
         infoValue = '',
         value,
         showPercent= false,
         percent,
         overrideCurrency= null,
         navigateTo = null,
         moreDetails,
   }) => {
    const { getCurrencyNumber } = useUserSettingsContext();
    return (
      <Col className={containerClassName}>
         <Card className={styles.savingPanel}>
           {withHeader ? (
              <div className={styles.headerWrapper}>
                 <div className={styles.mainHeader}>
                    <h5 className={styles.description}>
                      {title}
                    </h5>
                    {showIcon ? (
                       <InfoPopover isSimple mode="outline">
                          {infoValue}
                       </InfoPopover>
                    ) : null}
                 </div>
                {moreDetails && (<h5 className={styles.secondHeader}>
                   {moreDetails.title}
                </h5>)}
              </div>) : null}
              <div className={classNames(styles.cardWrapper, moreDetails && styles.smallGap)}>
                 <div className={styles.valueWrapper}>
                    <h2 className={classNames(styles.title, Array.isArray(value) ? styles.fullWidth : '')}>
                       {!Array.isArray(value) ?
                          (<span>
                             {value ? getCurrencyNumber(removeDecPoint(value, 10), 0, {}, overrideCurrency) : '-'}
                           </span>)
                           : (value.map((item) => (
                               <div key={item.description} className={styles.valueRow}>
                                  <div>
                                     <span>{item.value}</span>
                                     <span className={styles.usageDescription}>{item.description}</span>
                                  </div>
                                  {item.navigateTo &&
                                     <Link
                                        automation-id={`investigate-${item.navigateTo?.path}`}
                                        rel="stylesheet"
                                        key={`navigate ${item.description}`}
                                        to={{
                                            pathname: `${item.navigateTo.path}`,
                                            search: item.navigateTo.searchParams,
                                            }}>
                                              <GenerateIcon iconName={ICONS.chevronRight.name}
                                                            automation-id="investigate-icon"
                                                            className={styles.arrowIcon} />
                                     </Link>}
                               </div>))
                           )}
                    </h2>
                    {moreDetails && (
                      <div className={styles.secondValue}>
                         {moreDetails.value ? `${getCurrencyNumber(removeDecPoint(moreDetails.value, 10), 0, {}, overrideCurrency)}` :'-'}
                      </div>)}
                 </div>
                 <div className={styles.detailsWrapper}>
                    <div>
                       {showPercent && percent ? (
                          <PercentTag percent={percent}/>
                       ) : null }
                    </div>
                    {navigateTo && <Link
                                 automation-id={`investigate-${navigateTo?.path}`}
                                 rel="stylesheet"
                                 to={{
                                     pathname: `${navigateTo?.path}`,
                                     search: navigateTo.searchParams,
                                 }}>
                                    <GenerateIcon iconName={ICONS.chevronRight.name}
                                                  className={styles.arrowIcon}  automation-id="investigate-icon"/>
                                 </Link>}
                 </div>
              </div>
           </Card>
         </Col>
        );
    },
);

UsageCost.propTypes = {
    title: PropTypes.string,
    value: PropTypes.number.isRequired,
    percent: PropTypes.string,
    infoValue: PropTypes.string,
    showPercent: PropTypes.bool,
    showIcon: PropTypes.bool,
    containerClassName: PropTypes.object,
    withHeader: PropTypes.bool,
    overrideCurrency: PropTypes.string,
    navigateTo: PropTypes.string,
    moreDetails: PropTypes.object,
};


export default UsageCost;

UsageCost.displayName = 'UsageCost';
