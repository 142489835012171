import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Progress } from 'reactstrap';
import {
  RowDetailState,
  DataTypeProvider,
  FilteringState,
  IntegratedFiltering,
  IntegratedSorting,
  SortingState,
  PagingState,
  IntegratedPaging,
} from '@devexpress/dx-react-grid';
import {
  Grid,
  TableHeaderRow,
  TableRowDetail,
  TableFilterRow,
  Toolbar,
  PagingPanel,
  TableColumnResizing,
} from '@devexpress/dx-react-grid-material-ui';
import InfoOutline from 'mdi-react/InfoOutlineIcon';
import TableWrapper from 'shared/components/tables/TableWrapper';
import { kFormatterWithDecimal } from 'shared/utils/strUtil';
import { withUserSettingsConsumer } from 'users/utils/contexts/UserSettingsContext';
import {
  SP_UTILIZATION_TABLE_COLUMN_EXTENSIONS,
  SP_UTILIZATION_TABLE_COLUMN_WIDTHS,
  SP_UTILIZATION_DETAIL_NAMES_MAP,
} from '../constants/commitmentConstants';

const camelToStrings = (camelStr) => camelStr.replace(/([A-Z])/g, ' $1').replace(/^./, (str) => str.toUpperCase());

const FilterIcon = ({ type, ...restProps }) => <TableFilterRow.Icon type={type} {...restProps} />;

FilterIcon.propTypes = {
  type: PropTypes.string.isRequired,
};

const RowDetail = ({ row }) => {
  const arrRow = row ? Object.entries(row) : [];
  const prepareSectionText = (sectionKey) =>
    arrRow
      .filter(([key]) => SP_UTILIZATION_DETAIL_NAMES_MAP.get(sectionKey).indexOf(key) > -1)
      .map(([key, value]) => (
        // <Col xs={12} md={12} lg={4} xl={4}>
        (<p style={{ padding: '3px', margin: '2px 0px' }}>
          <b>{`${camelToStrings(key)} : `}</b>
          <span>{`${key === 'UnusedCommitment' ? parseFloat(value).toFixed(3) : value}`}</span>
        </p>)
        // </Col>
      ));
  return (
    <>
      <Row>
        <Col xs={12} md={12} lg={12} xl={12}>
          <p style={{ padding: '5px 0px', margin: '10px 0px' }}>
            <b>Savings Plan ID:</b>
            <span>{` ${row.SavingsPlanID}`}</span>
          </p>
          <p style={{ padding: '5px 0px', margin: '10px 0px' }}>
            <b>Savings Plan ARN:</b>
            <span>{` ${row.SavingsPlanARN}`}</span>
          </p>
        </Col>
      </Row>
      <Row>
        {[...SP_UTILIZATION_DETAIL_NAMES_MAP.keys()].map((sectionKey) => (
          <Col xs={12} md={12} lg={4} xl={4}>
            <h5>{sectionKey}</h5>
            <hr />
            {prepareSectionText(sectionKey)}
          </Col>
        ))}
      </Row>
    </>
  );
};

RowDetail.propTypes = {
  row: PropTypes.object.isRequired,
};

class SavingsPlansUtilizationTable extends Component {
  static propTypes = {
    data: PropTypes.object.isRequired,
    getChargeBackData: PropTypes.func.isRequired,
    tableColumns: PropTypes.object.isRequired,
    getCurrencyNumber: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    const { data } = this.props;
    this.state = {
      rows: data,
      pageSizes: [5, 10, 20, 0],
      columnWidths: SP_UTILIZATION_TABLE_COLUMN_WIDTHS,
      numericFilterOperations: [
        'equal',
        'notEqual',
        'greaterThan',
        'greaterThanOrEqual',
        'lessThan',
        'lessThanOrEqual',
      ],
      filteringStateColumnExtensions: [
        { columnName: 'chargeBack', filteringEnabled: false },
        // { columnName: 'riFlexability', filteringEnabled: false },
      ],
    };
  }
  getChargeBackIcon = ({ row }) => (
    <InfoOutline
      onClick={() => this.handleGetChargeBack(row.SavingsPlanARN, row.month, row.year)}
      style={{ margin: 'auto', height: '25px', width: '25px', cursor: 'pointer' }}
    />
  );
  handleGetChargeBack = (arn, month, year) => {
    const { getChargeBackData } = this.props;
    if (arn && month && year) {
      getChargeBackData(arn, month, year);
    }
  };
  currenciesFormatter = (value) => {
    const { getCurrencyNumber } = this.props;
    const decimal = value.value % 1 > 0 ? 3 : 0;
    return getCurrencyNumber(kFormatterWithDecimal(value.value, decimal));
  };
  utilizationFormatter = (data) => {
    const { value } = data;
    const decimal = value.value % 1 > 0 ? 3 : 0;
    const getColor = (val) => {
      let color = 'success';
      if (val < 75 && val >= 50) {
        color = 'warning';
      } else if (val < 50) {
        color = 'danger';
      }
      return color;
    };
    // const valInt = +value.slice(0, -1);
    return (
      <div>
        <div className="text-center">{`${value.toFixed(decimal)}%`}</div>
        <Progress value={value} color={getColor(value)} />
      </div>
    );
  };
  changeColumnWidths = (columnWidths) => {
    this.setState({ columnWidths });
  };
  render() {
    const { numericFilterOperations, filteringStateColumnExtensions, rows, columnWidths, pageSizes } = this.state;
    const { tableColumns } = this.props;
    return (
      <div className="page-wrapper" style={{ overflowX: 'scroll' }}>
        <Grid rows={rows} columns={tableColumns}>
          <RowDetailState />
          <FilteringState defaultFilters={[]} columnExtensions={filteringStateColumnExtensions} />
          <SortingState />
          <PagingState defaultCurrentPage={0} defaultPageSize={pageSizes[2]} />
          <IntegratedFiltering />
          <IntegratedSorting />
          <IntegratedPaging />
          <DataTypeProvider
            for={['UtilizationPercentage']}
            formatterComponent={this.utilizationFormatter}
            availableFilterOperations={numericFilterOperations}
          />
          <DataTypeProvider
            for={['NetSavings']}
            formatterComponent={this.currenciesFormatter}
            availableFilterOperations={numericFilterOperations}
          />
          <DataTypeProvider
            for={['HourlyCommitment']}
            formatterComponent={this.currenciesFormatter}
            availableFilterOperations={numericFilterOperations}
          />
          <DataTypeProvider for={['chargeBack']} formatterComponent={this.getChargeBackIcon} />
          <TableWrapper columnExtensions={SP_UTILIZATION_TABLE_COLUMN_EXTENSIONS} />
          <TableColumnResizing columnWidths={columnWidths} onColumnWidthsChange={this.changeColumnWidths} />
          <TableHeaderRow showSortingControls />
          <TableRowDetail contentComponent={RowDetail} />
          <TableFilterRow showFilterSelector iconComponent={FilterIcon} />
          <PagingPanel pageSizes={pageSizes} />
          <Toolbar />
        </Grid>
      </div>
    );
  }
}

const ObserverSavingsPlansUtilizationTable = withUserSettingsConsumer(SavingsPlansUtilizationTable);
export default ObserverSavingsPlansUtilizationTable;
