import { useQuery } from '@tanstack/react-query';
import { API } from 'shared/utils/apiMiddleware';
import apiConstants from 'shared/api/apiConstants';

const root = '/api/v1/usage/azure-service-plans';

const getServicePlans = ({ subscriptionId, servicePlan, resourceGroup, customTags, region, startDate, endDate }) => {
  const params = new URLSearchParams({
    subscriptionId,
    servicePlan,
    resourceGroup,
    customTags,
    region,
    startDate,
    endDate,
  });
  return API.get('billings', `${root}?${params.toString()}`);
};

const getWebApplications = ({ servicePlan, startDate, endDate }) => {
  const params = new URLSearchParams({
    servicePlan,
    startDate,
    endDate,
  });
  return API.get('billings', `${root}/web-applications?${params.toString()}`);
};

export function useAzureServicePlans(data) {
  const { subscriptionId, servicePlan, resourceGroup, customTags, region, startDate, endDate } = data;
  const queryKey = [apiConstants.QUERY_KEYS.AZURE_SERVICE_PLANS];

  return {
    fetchServicePlans: () =>
      useQuery(
        [queryKey, subscriptionId, servicePlan, resourceGroup, customTags, region, startDate, endDate],
        () => getServicePlans(data),
        { staleTime: 60 * 1000 * 5 },
      ),
    fetchWebApplications: (servicePlan) =>
      useQuery(
        [queryKey, 'web-apps', startDate, endDate, servicePlan],
        () => getWebApplications({ startDate, endDate, servicePlan }),
        { enabled: !!servicePlan, staleTime: 60 * 1000 * 5 },
      ),
  };
}
