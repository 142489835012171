import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { GenerateIcon, ICONS } from '@pileus-cloud/anodot-frontend-common';
import { useUserSettingsContext } from 'users/utils/contexts/UserSettingsContext';
import Tooltip from 'shared/components/andtComponents/Tooltip';
import { bytesToGb, strGbToSize } from 'shared/utils/strUtil';
import { useRootStore } from 'app/contexts/RootStoreContext';
import InfoPopover from 'shared/components/andtComponents/InfoPopover';
import RecommendationOptionsContext from './recommendationOptionsContext';
import { useRecommendationsNewContext } from 'recommendationsNew/contexts/recommendationsNewContext.jsx';
import classes from './recommendationDetails.module.scss';
import { FILTERS } from 'recommendationsNew/consts.js';

const RecommendationDetails = ({ recommendationProperties, recommendation, viewAll }) => {
  const { recommendationFilters: filtersContext } = useRecommendationsNewContext();
  const { usersStore } = useRootStore();
  const cloudType = usersStore.currDispUserCloudAccountType;
  const { currencySymbol } = useUserSettingsContext();
  const { selectedOptionIndex } = useContext(RecommendationOptionsContext);

  const [isViewAllOpened, setIsViewAllOpened] = useState(viewAll || false);
  const [recommendationPropertiesAndValues, setRecommendationPropertiesAndValues] = useState({});

  useEffect(() => {
    const keys = Object.keys(recommendationProperties);
    const newObj = {};
    for (let i = 0; i < keys.length; i++) {
      const key = keys[i];
      const { getterFunction } = recommendationProperties[key];
      const value = getterFunction && getterFunction(recommendation, selectedOptionIndex, filtersContext[FILTERS.COST_MODE.id]);
      if (value !== null && typeof value !== 'undefined') {
        newObj[key] = { getterFunction, ...recommendationProperties[key], value };
      }
    }
    setRecommendationPropertiesAndValues(newObj);
  }, [recommendationProperties, selectedOptionIndex, recommendation, filtersContext[FILTERS.COST_MODE.id]]);

  const getValue = (property) => {
    const { isGBSize, isBSize, isPercent, displayNA, isMilliCores } = property;
    let { value } = property;
    if (value === null || typeof value === 'undefined') {
      return null;
    }
    if (isGBSize) {
      value = value || !displayNA ? strGbToSize(value) : 'N/A';
    } else if (isBSize) {
      value = value || !displayNA ? strGbToSize(bytesToGb(value, 10, true)) : 'N/A';
    } else if (isPercent) {
      value = `${value}%`;
    } else if (isMilliCores) {
      value = `${value} milliCores`;
    }
    return value;
  };

  const getPropertyValueComponent = (property) => {
    const { isCurrency, isTable, columns } = property;
    const label = property?.labelGetter ? property.labelGetter(cloudType) : property.label;

    return (
      <div className={classes.property}>
        <div className={classes.propertyTitle}>
          {label}: {property.info ? <InfoPopover isSimple>{property.info}</InfoPopover> : null}
        </div>
        {isTable && property.value.length > 0 ? (
          <div className={classes.propertyValueSubGrid}>
            <div className={classes.headerRow}>
              {columns.map((column) => (
                <div key={column.id} className={classes.propertySubTitle}>
                  {column.label} {column.isCurrency ? currencySymbol : ''}
                </div>
              ))}
            </div>
            {property.value.map((row, rowIndex) => (
              <div key={rowIndex} className={classes.tableRow}>
                {columns.map((column) => (
                  <Tooltip title={row[column.id]} key={column.id}>
                    <div className={classes.tableCell}>{row[column.id]}</div>
                  </Tooltip>
                ))}
              </div>
            ))}
          </div>
        ) : (
          <div className={classNames(classes.propertyValue, property.isBold && classes.propertyBold)}>
            {isCurrency && currencySymbol ? <span>{currencySymbol}</span> : null}
            <Tooltip title={property.value} placement="right">
              <div className={classes.text}>{getValue(property)}</div>
            </Tooltip>
          </div>
        )}
      </div>
    );
  };

  const getAttentionComment = () => {
   return ( <div className={classes.attentionComment}>
      <GenerateIcon iconName={ICONS.circleExclamation.name} className={classes.attentionIcon}/>
      <span>{recommendation.recData?.attention_comment}</span>
    </div>)
  }

  return (
      <div className={classes.detailsContainer}>
        {recommendation.recData?.attention_comment ? getAttentionComment(): null}
      <div className={classes.titleContainer}>
        <GenerateIcon iconName={ICONS.squareList.name} className={classes.titleIcon} />
        <div className={classes.titleText}>Details</div>
      </div>
      <div className={classes.properties}>
        {(isViewAllOpened
          ? Object.values(recommendationPropertiesAndValues)
          : Object.values(recommendationPropertiesAndValues)?.slice(0, 4)
        )?.map((property) => getPropertyValueComponent(property))}
      </div>
      <div className={classes.viewAll} onClick={() => setIsViewAllOpened(!isViewAllOpened)}>
        <div>{isViewAllOpened ? 'View Less' : 'View All'}</div>
        <GenerateIcon
          iconName={isViewAllOpened ? ICONS.chevronUp.name : ICONS.chevronDown.name}
          className={classes.chevronIcon}
        />
      </div>
    </div>
  );
};

RecommendationDetails.propTypes = {
  recommendation: PropTypes.object.isRequired,
  recommendationProperties: PropTypes.object.isRequired,
  viewAll: PropTypes.bool,
};

export default RecommendationDetails;
