 
import { RecommendationTypes } from 'recommendations/constants/recommendationsConstants';
import { AzureVmStoppedRecommendation } from 'recommendations/models/recommTypes';
import AzureCostRecommendationBuilder from 'recommendations/models/builders/azureCostRecommBuilder';

export default class AzureVmStoppedCostRecommendationBuilder extends AzureCostRecommendationBuilder {
  constructor(rawRecommendation) {
    super(rawRecommendation);
  }

  createRecommendation() {
    this._recommendation = new AzureVmStoppedRecommendation();
  }

  getRecommType() {
    return RecommendationTypes.AZURE_VM_STOPPED;
  }

  buildRecommTitle() {
    const { recommended_action: action } = this._rawRecommendation;
    this._recommendation.title = `${action || ''} VM Stopped`;
  }

  build() {
    super.build();
    const {
      resource_id: resourceId,
      resource_group: resourceGroup,
      zone_tag: tagZone,
      resource_name: resourceName,
      starting_time: startingTime,
      instance_type_model: model,
      attached_volumes: attachedVolumes,
      attached_volumes_costs: attachedVolumesCosts,
      stop_reason: stopReason,
      stop_time: stopTime,
      public_ip_address: publicIpAddress,
      public_ip_address_cost: publicIpAddressCost,
    } = this._rawRecommendation;
    this._recommendation.publicIpAddress = publicIpAddress;
    this._recommendation.publicIpAddressCost = publicIpAddressCost;
    this._recommendation.stopReason = stopReason;
    this._recommendation.stopTime = stopTime;
    this._recommendation.attachedVolumes = attachedVolumes;
    this._recommendation.attachedVolumesCosts = attachedVolumesCosts;
    this._recommendation.resourceGroup = resourceGroup;
    this._recommendation.region = tagZone;
    this._recommendation.resourceName = resourceName;
    this._recommendation.startingTime = startingTime;
    this._recommendation.resourceId = resourceId;
    this._recommendation.model = model;
  }
}
