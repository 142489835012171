import React from 'react';
import Paper from '@mui/material/Paper';
import { Grid, Table, TableHeaderRow, TableColumnResizing } from '@devexpress/dx-react-grid-material-ui';
import { DataTypeProvider, SortingState, IntegratedSorting } from '@devexpress/dx-react-grid';
import PropTypes from 'prop-types';
import PanelWithActionButton from 'shared/components/panels/PanelWithActionButton';
import { percentStr } from 'shared/utils/strUtil';
import { REPORT_TYPES } from 'usage/constants/usageConstants';
import ReportIcon from 'mdi-react/FileDocumentBoxIcon';
import LinkIconButton from 'shared/components/buttons/LinkIconButton';
import TableWrapper from 'shared/components/tables/TableWrapper';
import { useUserSettingsContext } from 'users/utils/contexts/UserSettingsContext';

const TrendCell = ({ value, style = {}, ...restProps }) => (
  <Table.Cell
    {...restProps}
    style={{
      color: 'white',
      ...style,
    }}
  >
    <span
      style={{
        backgroundColor: parseInt(value, 10) > 0 ? '#f55f5f' : parseInt(value, 10) < 0 ? '#93d160' : '#f3c06f',
        borderRadius: 3,
        padding: '2px 7px',
      }}
    >
      {percentStr(value, 1) || '0%'}
    </span>
  </Table.Cell>
);

TrendCell.propTypes = {
  value: PropTypes.number.isRequired,
  style: PropTypes.object,
};

const Cell = (props) => {
  const { column } = props;
  if (column.name === 'change') {
    return <TrendCell {...props} />;
  }
  return <Table.Cell {...props} />;
};

Cell.propTypes = {
  column: PropTypes.object.isRequired,
};

const AppTileClicks = ({
  data,
  actionButtonStyle,
  handleGoToCostExplorerReport,
  columnWidths,
  changeColumnWidths,
  columns,
  isCustomDashboard = false,
  overrideCurrency = '',
}) => {
  const { getCurrencyNumber } = useUserSettingsContext();
  const renderBody = () => (
    <Paper>
      <Grid rows={data} columns={columns}>
        <SortingState defaultSorting={[{ columnName: 'mtdCost', direction: 'desc' }]} />
        <IntegratedSorting />
        <DataTypeProvider
          for={['prevMonthCost', 'mtdCost']}
          formatterComponent={({ value }) => `${getCurrencyNumber(value, 2, {}, overrideCurrency)}`}
        />
        {data.find((row) => row.forecasted) ? (
          <DataTypeProvider
            for={['forecasted']}
            formatterComponent={({ value }) => `${getCurrencyNumber(value, 2, {}, overrideCurrency)}`}
          />
        ) : null}
        <TableWrapper cellComponent={Cell} />
        <TableColumnResizing
          columnWidths={columnWidths}
          onColumnWidthsChange={changeColumnWidths}
          resizingMode="nextColumn"
        />
        <TableHeaderRow showSortingControls />
      </Grid>
    </Paper>
  );
  if (isCustomDashboard) {
    return renderBody();
  }
  return (
    <PanelWithActionButton
      divider
      title="Month To-Date Top 5 Services"
      panelClass="panel--narrow"
      ActionButtonComp={LinkIconButton}
      ButtonIcon={ReportIcon}
      handler={handleGoToCostExplorerReport}
      id={REPORT_TYPES.TOP_5_SERVICES}
      actionButtonStyle={actionButtonStyle}
      lg={12}
      xl={6}
      md={12}
    >
      {renderBody()}
    </PanelWithActionButton>
  );
};

AppTileClicks.propTypes = {
  data: PropTypes.object.isRequired,
  actionButtonStyle: PropTypes.object.isRequired,
  handleGoToCostExplorerReport: PropTypes.func.isRequired,
  changeColumnWidths: PropTypes.func.isRequired,
  columnWidths: PropTypes.object.isRequired,
  columns: PropTypes.object.isRequired,
  isCustomDashboard: PropTypes.bool,
  overrideCurrency: PropTypes.string,
};

export default AppTileClicks;
