/* eslint-disable no-unused-vars */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { ChevronDown } from 'react-feather';
import { Col, Collapse, Row } from 'reactstrap';
import CircularProgress from '@mui/material/CircularProgress';
import LinkIconButton from 'shared/components/buttons/LinkIconButton';
import { StatementDownloadTypes } from 'shared/constants/appConstants';
import CustomCSVDownload from 'shared/components/buttons/CustomCSVDownload';

class DownloadDropdown extends PureComponent {
  static propTypes = {
    filesNumber: PropTypes.number.isRequired,
    downloadCsv: PropTypes.func.isRequired,
    downloadPdf: PropTypes.func.isRequired,
    isLoadingPdf: PropTypes.bool.isRequired,
    isLoadingCsv: PropTypes.bool.isRequired,
    handleChangeGranLevel: PropTypes.func.isRequired,
    currPeriodGranLevel: PropTypes.string.isRequired,
    isDateRangeError: PropTypes.bool.isRequired,
    startDate: PropTypes.object.isRequired,
    endDate: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      //   rSelected: this.props.currPeriodGranLevel,
    };
  }

  onRadioBtnClick = (rSelected) => {
    // this.props.handleChangeGranLevel(rSelected);
    this.toggle();
  };

  toggle = () => {
    this.setState({
      collapse: !this.state.collapse,
    });
  };

  mapFileTypeToDownloadFunc = new Map([
    [StatementDownloadTypes.CSV, this.props.downloadCsv],
    [StatementDownloadTypes.PDF, this.props.downloadPdf],
  ]);

  render() {
    const { isLoadingPdf, isLoadingCsv, downloadCsv, downloadPdf } = this.props;
    const { collapse } = this.state;
    return (
      <Row className="date-picker-container" style={{ marginBottom: '10px', width: '180px' }}>
        <Col onClick={this.toggle} xl="auto" lg="auto" sm="auto" xs="auto">
          <div style={{ display: 'flex', width: '80%' }}>
            <span className="lnr lnr-download" style={{ fontSize: '16px', marginRight: '8px' }} />
            <h5>Download</h5>
          </div>
        </Col>
        <Col style={{ padding: '1px 0px' }} xs={2} md={2} lg={2} xl={2}>
          <LinkIconButton className="topbar__avatar" onClick={this.toggle}>
            <p onClick={this.toggle} />
            <ChevronDown size={22} />
          </LinkIconButton>
        </Col>
        {collapse && <button className="topbar__back" onClick={this.toggle} />}
        <Collapse isOpen={collapse} className="billing-topbar__menu-wrap">
          <div className="drop__menu">
            <div
              className={`p-0 m-0 d-flex align-items-center collapse-item${
                isLoadingPdf || isLoadingCsv ? ' billing-history-download-disabled' : ''
              }`}
              style={{ display: 'flex' }}
            >
              <p
                onClick={
                  isLoadingPdf || isLoadingCsv
                    ? null
                    : async () => {
                        await downloadPdf();
                      }
                }
                className="p-3 w-100"
              >
                Download {StatementDownloadTypes.PDF}
              </p>
              {isLoadingPdf ? (
                <div style={{ marginLeft: '5px', height: 14 }} className="me-2">
                  <CircularProgress variant="indeterminate" disableShrink size={14} thickness={4} />
                </div>
              ) : null}
            </div>
            <div
              className={`p-0 m-0 d-flex align-items-center collapse-item${
                this.props.isLoadingPdf || this.props.isLoadingCsv ? ' billing-history-download-disabled' : ''
              }`}
            >
              <p
                onClick={
                  isLoadingPdf || isLoadingCsv
                    ? null
                    : async () => {
                        await downloadCsv();
                      }
                }
                className="p-3 w-100"
              >
                Download {StatementDownloadTypes.CSV}
              </p>
              {isLoadingCsv ? (
                <div style={{ marginLeft: '5px', height: 14 }} className="me-2">
                  <CircularProgress variant="indeterminate" disableShrink size={14} thickness={4} />
                </div>
              ) : null}
            </div>
          </div>
        </Collapse>
      </Row>
    );
  }
}

export default DownloadDropdown;
