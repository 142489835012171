import React, { useMemo } from 'react';
import PageHeader from 'shared/components/PageHeader';
import { PageNames } from 'shared/constants/appConstants';
import { Container } from 'reactstrap';
import { useRootStore } from 'app/contexts/RootStoreContext';
import Spinner from 'shared/components/andtComponents/Spinner';
import { CLOUD_TYPE_IDS } from 'users/constants/usersConstants';
import { GenerateIcon, ICONS, Tabs, TabsContent, TabsList, TabsTrigger } from '@pileus-cloud/anodot-frontend-common';
import styles from '../ResourceExplorer/ResourceExplorer.module.scss';
import ServiceCostTab from './ServiceCostTab';
import CommitmentExpirationTab from './CommitmentExpirationTab';
import GCPCommitmentExpirationTab from './GCP_CUDExpirationTab';
import { AlertTypesEnum, CommitmentExpAlert, ServiceCostAlert } from './types.ts';
import { useAlerts } from './hooks/useAlerts';
import t from './texts.ts';

enum AlertTabs {
  SERVICE_COST = t('SERVICE_COST'),
  COMMITMENT_EXPIRATION = t('COMMITMENT_EXPIRATION'),
  GCP_COMMITMENT_EXPIRATION = t('GCP_COMMITMENT_EXPIRATION'),
}

const Alerts: React.FC = () => {
  const { usersStore } = useRootStore();
  const { fetchAlertsList } = useAlerts();
  const { data: allAlerts = [], isLoading: isAlertsLoading = [], refetch } = fetchAlertsList();

  const { serviceCostAlerts, staticAlerts, riExpirationAlerts, spExpirationAlerts, spExpirationCUDAlerts } = useMemo(
    () =>
      (Array.isArray(allAlerts) ? allAlerts : []).reduce(
        (acc, alert) => {
          if (alert.alertType === AlertTypesEnum.COST_CHANGES) {
            acc.serviceCostAlerts.push(alert as ServiceCostAlert);
          }
          if (String(alert.alertType) === String(AlertTypesEnum.STATIC)) {
            acc.staticAlerts.push(alert as ServiceCostAlert);
          }
          if (alert.alertType === AlertTypesEnum.RI_EXPIRATION) {
            acc.riExpirationAlerts.push(alert as CommitmentExpAlert);
          }
          if (alert.alertType === AlertTypesEnum.SP_EXPIRATION) {
            if ("cloudType" in alert && String(alert.cloudType) === String(CLOUD_TYPE_IDS.GCP)) {
              acc.spExpirationCUDAlerts.push(alert as CommitmentExpAlert)
            } else {
              acc.spExpirationAlerts.push(alert as CommitmentExpAlert);
            }
          }
          return acc;
        },
        {
          serviceCostAlerts: [] as ServiceCostAlert[],
          staticAlerts: [] as ServiceCostAlert[],
          riExpirationAlerts: [] as CommitmentExpAlert[],
          spExpirationAlerts: [] as CommitmentExpAlert[],
          spExpirationCUDAlerts: [] as CommitmentExpAlert[],
        },
      ),
    [allAlerts],
  );

  const isAWS = usersStore.currDispUserCloudAccountType === CLOUD_TYPE_IDS.AWS;
  const isAzure = usersStore.currDispUserCloudAccountType === CLOUD_TYPE_IDS.AZURE;
  const isGCP = usersStore.currDispUserCloudAccountType === CLOUD_TYPE_IDS.GCP;
  const commitmentKey = isGCP ? AlertTabs.GCP_COMMITMENT_EXPIRATION : AlertTabs.COMMITMENT_EXPIRATION

  return (
    <Container>
      <PageHeader showDate title={PageNames.CUE_ALERTS} />
      {isAlertsLoading ? (
        <Spinner />
      ) : (
        <Tabs defaultValue={AlertTabs.SERVICE_COST}>
          <TabsList className={styles.navbar}>
            <TabsTrigger
              value={AlertTabs.SERVICE_COST}
              className={styles.navbarButton}
              automation-id="service-cost-tab"
            >
              <GenerateIcon iconName={ICONS.coins.name} />{' '}
              <span>
                {AlertTabs.SERVICE_COST} ({serviceCostAlerts.length + staticAlerts.length})
              </span>
            </TabsTrigger>
            { (isAzure || isAWS || isGCP ) && (
              <TabsTrigger
                value={commitmentKey}
                className={styles.navbarButton}
                automation-id="commitments-tab"
              >
                <GenerateIcon iconName={ICONS.calendarDay.name} />{' '}
                <span>
                  {commitmentKey} (
                  {spExpirationAlerts.length + (isAWS ? riExpirationAlerts.length : 0)})
                </span>
              </TabsTrigger>
            )}
          </TabsList>

          <TabsContent value={AlertTabs.SERVICE_COST}>
            <ServiceCostTab costChangesAlerts={serviceCostAlerts} staticAlerts={staticAlerts} refetchAlerts={refetch} />
          </TabsContent>
          <TabsContent value={commitmentKey}>
            {isGCP ? <GCPCommitmentExpirationTab
              expirationAlerts={spExpirationCUDAlerts}
              refetchAlerts={refetch}
            /> : <CommitmentExpirationTab
              spExpirationAlerts={spExpirationAlerts}
              riExpirationAlerts={isAWS || isAzure ? riExpirationAlerts : undefined}
              refetchAlerts={refetch}
            />}
          </TabsContent>
        </Tabs>
      )}
    </Container>
  );
};

export default Alerts;
