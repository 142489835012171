 
import { RecommendationTypes } from 'recommendations/constants/recommendationsConstants';
import { NatGatewayLowUtilizationRecommendation } from 'recommendations/models/recommTypes';
import CostRecommendationBuilder from 'recommendations/models/builders/costRecommBuilder';

export default class NatGatewayLowUtilizationRecommendationBuilder extends CostRecommendationBuilder {
  constructor(rawRecommendation) {
    super(rawRecommendation);
  }

  createRecommendation() {
    this._recommendation = new NatGatewayLowUtilizationRecommendation();
  }

  getRecommType() {
    return RecommendationTypes.ELASTICSEARCH_LOW_UTILIZATION;
  }

  buildRecommTitle() {
    const { action } = this._rawRecommendation;
    this._recommendation.title = `${action || ''} NAT Gateway`;
  }

  build() {
    super.build();
    const {
      active_connections: activeConnections,
      max_active_connections: maxActiveConnections,
      state,
      subnet_id: subnetId,
      resource_id: resourceId,
      vpc_id: vpcId,
      zone_tag: region,
      days_to_check: daysToCheck,
    } = this._rawRecommendation;
    this._recommendation.activeConnections = activeConnections;
    this._recommendation.maxActiveConnections = maxActiveConnections;
    this._recommendation.state = state;
    this._recommendation.resourceId = resourceId;
    this._recommendation.subnetId = subnetId;
    this._recommendation.vpcId = vpcId;
    this._recommendation.region = region;
    this._recommendation.daysToCheck = daysToCheck;

    // BETA
    // this._recommendation.isBeta = true;
  }
}
