/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { AwsCommonFields } from 'shared/constants/awsConstants';
import LabelCoordinator from 'shared/modules/labelCoordinator';
import { StringToColor } from 'shared/utils/graphicUtil';
import { CostTrackingConstants, ItemsOptionsValues } from 'usage/constants/costAndUsageConstants';
import ChartKeysSearchAndFilterPanel from 'shared/components/ChartKeysSearchAndFilterPanel';
import {
  DEFAULT_SELECTED_FILTERS_LENGTH,
  LINECHART_SELECTED_FILTERS_LENGTH,
} from 'shared/components/ChartKeysFilter/legendKeysFilterHandler';
import { modifyRegionNameToDisplay } from 'shared/utils/awsUtils';
import './keysFilter.css';

const liStyle = (isSelected, nameColor) => ({
  position: 'relative',
  display: 'inline-block',
  padding: '0.4rem 0.4rem',
  marginBottom: '-2px',
  borderRadius: '50%',
  border: isSelected ? '0.5px solid #e0e0e0' : `1px solid ${nameColor}`,
  background: isSelected ? nameColor : 'none',
});

const prepareDisplayName = (groupBy, info) => {
  if (!info) {
    return '';
  }
  const { name } = info;

  if (groupBy?.startsWith(AwsCommonFields.ACCOUNT_TAGS)) {
    return name;
  }
  if (groupBy === AwsCommonFields.REGION) {
    return modifyRegionNameToDisplay(groupBy, name);
  }
  return LabelCoordinator.getDataKeyDisplayName('cueDisplayCoordinator', name);
};

const Name = ({ info, selected, handleFavourite, groupBy, color }) => (
  <div style={{ display: 'inline-block', paddingRight: '15px' }}>
    <li style={liStyle(selected, `  ${color} `)} className="filter" onClick={() => handleFavourite(info)}>
      {' '}
    </li>
    <button className="filter" type="button" onClick={() => handleFavourite(info)} />
    <button className="filter" type="button" onClick={() => handleFavourite(info)}>
      {prepareDisplayName(groupBy, info)}
    </button>
  </div>
);

const FilteredList = ({ visibleFilters, toggleFavourite, groupBy, filteredKeys, getLabelColor }) => {
  const requirementsActualCounter = {
    requirments: '',
    actual: '',
  };
  const favList = visibleFilters
    .filter((fav) => fav.name !== CostTrackingConstants.OTHERS)
    .map((fav, i) => {
      const selected = filteredKeys.includes(fav.name);
      return (
        <Name
          color={getLabelColor(fav.name, requirementsActualCounter)}
          id={fav.id}
          key={i}
          info={fav}
          selected={selected}
          handleFavourite={toggleFavourite}
          groupBy={groupBy}
        />
      );
    });
  return (
    <>
      <div className="favourites">
        <ul>{favList}</ul>
      </div>
    </>
  );
};

class ChartKeysFilter extends PureComponent {
  static propTypes = {
    data: PropTypes.array.isRequired,
    filteredDataKeys: PropTypes.object.isRequired,
    favourites: PropTypes.object.isRequired,
    filteredKeys: PropTypes.object.isRequired,
    addKeysFilterHandler: PropTypes.func.isRequired,
    setKeysFilterHandler: PropTypes.func.isRequired,
    removeKeysFilterHandler: PropTypes.func.isRequired,
    isShowOthersChange: PropTypes.func.isRequired,
    isOthersVisible: PropTypes.bool,
    hidePanel: PropTypes.bool,
    isShowOthers: PropTypes.bool.isRequired,
    groupBy: PropTypes.string,
    getLabelColor: PropTypes.func,
    isLineChart: PropTypes.bool,
    isFromCustomDb: PropTypes.bool,
    disableUpdateFavourites: PropTypes.bool,
    visibleFilterKeysCount: PropTypes.number,
    numberOfItems: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  };

  static defaultProps = {
    groupBy: null,
    hidePanel: false,
    isOthersVisible: false,
    isLineChart: false,
    isFromCustomDb: false,
    disableUpdateFavourites: false,
    visibleFilterKeysCount: 0,
    numberOfItems: ItemsOptionsValues.ALL,
    getLabelColor: (name) => StringToColor.next(name),
  };

  constructor(props) {
    super(props);
    this.state = {
      searchValue: '',
      visibleFilters: this.initVisibleFilters(props.data, props.numberOfItems),
    };
    this.toggleFavourite = this.toggleFavourite.bind(this);
    this.handleAddOrRemoveMoreFilters = this.handleAddOrRemoveMoreFilters.bind(this);
    this.removeAddFiltersFromFavouritesIfNeeded = this.removeAddFiltersFromFavouritesIfNeeded.bind(this);
  }

  componentDidMount() {
    const {
      filteredDataKeys,
      data,
      isFromCustomDb,
      favourites,
      visibleFilterKeysCount,
      disableUpdateFavourites,
      numberOfItems,
      legendInitiated,
      initFavourites,
    } = this.props;
    if (isFromCustomDb && favourites && favourites.length && !disableUpdateFavourites) {
      if (favourites.length === visibleFilterKeysCount) {
        this.selectAllFilters();
      } else {
        const updatedDataKeysIds = filteredDataKeys.reduce((acc, { name }) => {
          const dataKey = data.find((currData) => currData.name === name);
          if (dataKey && dataKey.id) {
            acc.push(dataKey.id);
          }
          return acc;
        }, []);
        this.setFavourites(updatedDataKeysIds);
      }
    } else if (initFavourites && !legendInitiated) {
      initFavourites(data);
      const initVisibleIds = this.initVisibleFilters(data, numberOfItems).map(({ id }) => id);
      const arrOfUniqueFilteredDataKeys = this.getUniqueVisibleFiltersList(initVisibleIds);

      this.setState({ visibleFilters: arrOfUniqueFilteredDataKeys });
    }
  }

  componentDidUpdate(prevProps) {
    const { data, isLineChart, numberOfItems } = this.props;
    if (JSON.stringify(prevProps.data) !== JSON.stringify(data) || prevProps.isLineChart !== isLineChart) {
      const initVisibleIds = this.initVisibleFilters(data, numberOfItems).map(({ id }) => id);
      const arrOfUniqueFilteredDataKeys = this.getUniqueVisibleFiltersList(initVisibleIds);
      this.setState({ visibleFilters: arrOfUniqueFilteredDataKeys });
    }
  }

  getUniqueVisibleFiltersList = (newVisibleIds) => {
    const { data } = this.props;
    const arrOfUniqueFilteredIds = Array.isArray(newVisibleIds) ? newVisibleIds : [...newVisibleIds];
    const arrOfUniqueFilteredDataKeys = data.filter(({ id }) => arrOfUniqueFilteredIds.indexOf(id) > -1);
    return arrOfUniqueFilteredDataKeys;
  };

  setFavourites(ids) {
    const { data, setKeysFilterHandler } = this.props;
    const addedKeys = data.filter((entry) => ids.includes(entry.id));
    setKeysFilterHandler(addedKeys);
  }

  initVisibleFilters = (data, numberOfItems) => {
    const { isLineChart } = this.props;
    if (numberOfItems && numberOfItems !== ItemsOptionsValues.ALL) {
      const visibleFilters = data.slice(0, numberOfItems);
      if (visibleFilters.length) {
        this.setFavourites(visibleFilters.map((f) => f.id));
      }
      return visibleFilters;
    }
    if (data.length < 10) {
      return data;
    }
    if (isLineChart) {
      return data.slice(0, LINECHART_SELECTED_FILTERS_LENGTH);
    }
    return data.slice(0, DEFAULT_SELECTED_FILTERS_LENGTH);
  };

  // add clicked name ID to the favourites array
  addFavourite(addedId) {
    const { addKeysFilterHandler, data } = this.props;
    const addedDataKey = data.filter(({ id }) => id === addedId);
    addKeysFilterHandler(addedDataKey);
  }

  initFavourites(ids) {
    const { favourites, addKeysFilterHandler } = this.props;
    if (favourites.length === 0) {
      const { data } = this.props;
      const addedKeys = data.filter((entry) => ids.includes(entry.id));
      addKeysFilterHandler(addedKeys);
    }
  }

  // remove ID from the favourites array
  deleteFavourite(removedId) {
    const { removeKeysFilterHandler, data } = this.props;
    const removedDataKey = data.filter(({ id }) => id === removedId);
    removeKeysFilterHandler(removedDataKey);
  }

  toggleFavourite(key) {
    const { favourites, isShowOthersChange } = this.props;
    const isSelected = favourites.indexOf(key.id) > -1;
    if (isShowOthersChange) {
      isShowOthersChange(false);
    }
    if (isSelected) {
      this.deleteFavourite(key.id);
    } else {
      this.addFavourite(key.id);
    }
  }

  removeAddFiltersFromFavouritesIfNeeded = (addFiltersIds) => {
    const { favourites, data, removeKeysFilterHandler } = this.props;
    const idsToRemoveFromFav = favourites.filter((id) => addFiltersIds.indexOf(id) === -1);
    if (idsToRemoveFromFav.length > 0) {
      const removedKeys = data.filter((entry) => idsToRemoveFromFav.includes(entry.id));
      removeKeysFilterHandler(removedKeys, false); // true == clear all
    }
  };

  handleAddOrRemoveMoreFilters = (addFiltersIds) => {
    const arrOfUniqueFilteredDataKeys = this.getUniqueVisibleFiltersList(addFiltersIds); // composing a new visible list
    this.removeAddFiltersFromFavouritesIfNeeded(addFiltersIds);
    const updatedFavourites = arrOfUniqueFilteredDataKeys.map((item) => item.id);
    this.setFavourites(updatedFavourites);
    this.setState({ visibleFilters: arrOfUniqueFilteredDataKeys });
  };

  // clear all IDs from the favourites array
  clearFavourites(id) {
    const { favourites } = this.props;
    this.setState({
      shouldOpenCollapse: true,
    });
    const { data, removeKeysFilterHandler } = this.props;
    const removedKeys = data.filter((entry) => favourites.includes(entry.id));
    removeKeysFilterHandler(removedKeys, true); // true == clear all
  }

  clearAllFilters = () => {
    const { isShowOthersChange } = this.props;
    this.clearFavourites();
    this.setState({ searchValue: '' });
    if (isShowOthersChange) {
      isShowOthersChange(false);
    }
  };

  filterSearchValues = (listOfValues) => {
    const { searchValue } = this.state;
    if (!searchValue) {
      return listOfValues;
    }
    const filteredList = listOfValues.filter((val) => {
      const fullString = val.name.toLowerCase();
      const fullLabel = (LabelCoordinator.getDataKeyDisplayName('cueDisplayCoordinator', val.name) || '').toLowerCase();
      const subString = searchValue.toLowerCase();
      return subString.length === 0 || fullString.includes(subString) || fullLabel.includes(subString);
    });
    return filteredList;
  };

  selectAllFilters = () => {
    const { visibleFilters } = this.state;
    const dk = this.filterSearchValues(visibleFilters).map(({ id }) => id);
    this.setFavourites(dk);
    this.setState({ searchValue: '' });
  };

  handleSearchInputChange = (e) => {
    const searchValue = e.target.value;
    this.setState({ searchValue });
  };

  getSelectedIds = (visibleFilters) => visibleFilters.map(({ id }) => id);

  render() {
    const {
      hidePanel,
      data,
      favourites,
      filteredKeys,
      isShowOthersChange,
      isOthersVisible,
      isShowOthers,
      groupBy,
      getLabelColor,
    } = this.props;
    const { visibleFilters, searchValue } = this.state;
    const visibleFiltersWithSearchValues = this.filterSearchValues(visibleFilters);
    const selectedIds = this.getSelectedIds(visibleFilters);
    return (
      <>
        {!hidePanel && (
          <ChartKeysSearchAndFilterPanel
            clearAllFilters={this.clearAllFilters}
            selectAllFilters={this.selectAllFilters}
            handleSearchInputChange={this.handleSearchInputChange}
            handleAddOrRemoveMoreFilters={this.handleAddOrRemoveMoreFilters}
            allFilters={data.filter((fav) => fav.name !== CostTrackingConstants.OTHERS)}
            visibleFilters={visibleFilters.filter((fav) => fav.name !== CostTrackingConstants.OTHERS)}
            filteredKeys={filteredKeys}
            groupBy={groupBy}
            searchValue={searchValue}
            isOthersVisible={isOthersVisible}
            isShowOthers={isShowOthers}
            isShowOthersChange={isShowOthersChange}
            selectedIds={selectedIds}
            visibleFiltersWithSearchValues={visibleFiltersWithSearchValues}
          />
        )}
        <FilteredList
          data={data}
          visibleFilters={visibleFiltersWithSearchValues}
          favourites={favourites}
          toggleFavourite={this.toggleFavourite}
          groupBy={groupBy}
          filteredKeys={filteredKeys}
          getLabelColor={getLabelColor}
        />
      </>
    );
  }
}

export default ChartKeysFilter;
