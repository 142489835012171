import React from 'react';
import PropTypes from 'prop-types';
import Input from 'shared/components/andtComponents/Input';
import styles from '../Login.module.scss';

const RequestCodeForm = ({ email, handleEmailChange, readOnly = true }) => (
  <form className={styles.requestCodeForm}>
    <Input
      readOnly={readOnly}
      name="email"
      value={email}
      onChange={handleEmailChange}
      type="email"
      placeholder="Email"
    />
  </form>
);
export default RequestCodeForm;

RequestCodeForm.propTypes = {
  handleEmailChange: PropTypes.func.isRequired,
  email: PropTypes.string.isRequired,
  readOnly: PropTypes.bool,
};
