import { observer } from 'mobx-react';
import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { cloneDeep } from 'lodash';
import { toast } from 'react-toastify';
import { GenerateIcon, ICONS } from '@pileus-cloud/anodot-frontend-common';
import Button from 'shared/components/andtComponents/Button';
import Input from 'shared/components/andtComponents/Input';
import useUsers from 'users/new-user-management/hooks/reactQuery/useUsers';
import Spinner from 'shared/components/andtComponents/Spinner';

import styles from './GeneralDetails.module.scss';

const GeneralDetails = ({ usersStore }) => {
  const [isLocked, setIsLocked] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const [localUser, setLocalUser] = useState({});

  const currentUser = usersStore.getCurrentUser;

  const { fetchUser, updateUser } = useUsers();
  const { data: currentUserData, isLoading: isLoadingCurrentUserData } = fetchUser(currentUser.userId);

  useEffect(() => {
    if (currentUserData) {
      setLocalUser(cloneDeep(currentUserData.user));
    }
  }, [currentUserData]);

  const handleCancelEdit = () => {
    setLocalUser(cloneDeep(currentUserData.user));
    setIsLocked(true);
  };

  const handleUpdateUserGeneralData = async () => {
    try {
      setIsSaving(true);
      await updateUser.mutateAsync({ user: localUser });
      setIsLocked(true);
      toast.success('Info Saved.');
    } catch (error) {
      toast.error('Error saving info.', error);
    } finally {
      setIsSaving(false);
    }
  };

  const isUserDataChanged = useMemo(() => {
    if (!currentUserData) return false;
    return (
      localUser.firstName !== currentUserData.user.firstName ||
      localUser.lastName !== currentUserData.user.lastName ||
      localUser.jobTitle !== currentUserData.user.jobTitle
    );
  }, [localUser, currentUserData]);

  return (
    <>
      <div className={styles.header}>
        <div className={styles.title}>General Information</div>
      </div>
      {isLoadingCurrentUserData ? (
        <Spinner />
      ) : (
        <>
          <div className={styles.form}>
            <div>
              <label htmlFor="first-name">First Name</label>
              <div id="first-name">
                <Input
                  disabled={isLocked || isSaving}
                  onChange={(e) => setLocalUser({ ...localUser, firstName: e.target.value })}
                  name="firstName"
                  value={localUser?.firstName}
                />
              </div>
            </div>
            <div>
              <label htmlFor="last-name">Last Name</label>
              <div id="last-name">
                <Input
                  disabled={isLocked || isSaving}
                  onChange={(e) => setLocalUser({ ...localUser, lastName: e.target.value })}
                  name="lastName"
                  value={localUser?.lastName}
                />
              </div>
            </div>
            <div>
              <label htmlFor="full-name">Company Name</label>
              <Input disabled={true} onChange={() => {}} name="companyName" value={currentUser.companyName} />
            </div>
            <div>
              <label htmlFor="job-title">Job Title</label>
              <Input
                disabled={isLocked || isSaving}
                onChange={(e) => setLocalUser({ ...localUser, jobTitle: e.target.value })}
                name="jobTitle"
                value={localUser?.jobTitle}
              />
            </div>
          </div>
          {isLocked ? (
            <Button
              text="Edit"
              isTextButton
              icon={() => <GenerateIcon iconName={ICONS.edit.name} />}
              onClick={() => setIsLocked(false)}
            />
          ) : (
            <div className={styles.buttons}>
              <Button onClick={handleCancelEdit} text="Cancel" isSecondary disabled={isSaving} />
              <Button
                onClick={handleUpdateUserGeneralData}
                text="Save"
                isLoading={isSaving}
                disabled={!isUserDataChanged}
              />
            </div>
          )}
        </>
      )}
    </>
  );
};

GeneralDetails.propTypes = {
  usersStore: PropTypes.object.isRequired,
};

const ObserverGeneralDetails = observer(GeneralDetails);
export default ObserverGeneralDetails;
