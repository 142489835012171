import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { RoleUsersType } from 'users/constants/usersConstants';
import { withRolesContextConsumer } from 'users/contexts/RolesProvider';
import Input from 'shared/components/andtComponents/Input';
import CustomModal from 'shared/components/andtComponents/Modal';
import RadioButtonList from 'shared/components/andtComponents/RadioButtonList';
import { prepareCloudHirarcheyObject } from 'users/utils/rolesUtil';
import { isAccountAllAccounts } from 'users/utils/userUtil';
import { withLinkedAccountsContextConsumer } from 'users/contexts/LinkedAccountsProvider';
import { ReactComponent as EditorIcon } from 'users/containers/RolesAndUsers/assets/editor.svg';
import useFormattedLinkedAccounts from 'users/containers/RolesAndUsers/hooks/useFormattedLinkedAccounts';
import { ReactComponent as ViewerIcon } from 'users/containers/RolesAndUsers/assets/viewer.svg';
import { getLinkedAccountName } from 'shared/utils/cloudUtils';
import useRoles from 'users/hooks/react-query/useRoles';
import styles from './CreateOrUpdateRoleModal.module.scss';
import AccountsChooser from '../AccountsChooser/AccountsChooser';

const CreateOrUpdateRoleModal = ({
  editedRole = null,
  duplicatedRole = null,
  usersStore,
  onClose,
  refetchRoles,
  linkedAccountsMode,
  cloudAccountsLinkedAccount: linkedAccountsRaw,
}) => {
  const predefinedRole = editedRole || duplicatedRole;
  const [roleName, setRoleName] = useState(editedRole ? editedRole.roleName : '');
  const [roleType, setRoleType] = useState(predefinedRole ? predefinedRole.roleUserType : RoleUsersType.EDITOR);
  const [accounts, setAccounts] = useState(predefinedRole ? predefinedRole.accounts : []);
  const { createNewRole, updateRole } = useRoles();
  const cloudAccountsLinkedAccount = useFormattedLinkedAccounts(linkedAccountsRaw);
  const { mutateAsync: handleAdd } = createNewRole();
  const { mutateAsync: handleUpdate } = updateRole();
  const options = useMemo(
    () =>
      prepareCloudHirarcheyObject(
        usersStore.getAllCurrDisplayedUserAccounts().filter((acc) => !isAccountAllAccounts(acc)),
      ),
    [usersStore.getAllCurrDisplayedUserAccounts()],
  );
  const handleSaveRole = async () => {
    const params = {
      roleName,
      roleUserType: roleType,
      roleType: 'userRole',
      useSameAccountKey: true,
      accounts,
    };
    if (!editedRole) {
      params.createdBy = usersStore.currentDisplayedUserName;
      const newRole = await handleAdd(params);
      if (newRole) {
        await refetchRoles();
      }
    } else {
      params.uuid = editedRole.uuid;
      params.createdBy = usersStore.currentDisplayedUserName;
      const updateRole = await handleUpdate(params);
      if (updateRole) {
        await refetchRoles();
      }
    }
    onClose();
  };
  const headerMode = editedRole ? 'edit' : 'add';
  return (
    <CustomModal
      saveDisabled={!roleName || !accounts.length}
      className={styles.modal}
      // eslint-disable-next-line no-nested-ternary
      title={linkedAccountsMode ? '' : editedRole ? 'Edit Role' : duplicatedRole ? 'Duplicate Role' : 'Create Role'}
      open
      onClose={onClose}
      onSave={handleSaveRole}
      headerMode={linkedAccountsMode ? undefined : headerMode}
      automationId="roleAccountChooser"
    >
      <div className={styles.container}>
        {!linkedAccountsMode ? (
          <>
            <div>
              <p className={styles.label}>Role Name</p>
              <Input
                label="Role Name"
                name="roleName"
                overrideStyles={{ height: 36 }}
                value={roleName}
                onChange={(e) => setRoleName(e.target.value)}
                placeholder="Enter role name"
              />
            </div>
            <div>
              <p className={styles.label}>Role Type</p>
              <div className={styles.roleTypeOptions}>
                <div
                  className={styles.roleTypeOption}
                  data-active={roleType === RoleUsersType.EDITOR}
                  onClick={() => setRoleType(RoleUsersType.EDITOR)}
                >
                  <div className={styles.roleTypeHeader}>
                    <RadioButtonList
                      options={[{ value: RoleUsersType.EDITOR, label: 'Editor', primary: true }]}
                      value={roleType}
                      onChange={(val) => {
                        setRoleType(val);
                      }}
                    />
                  </div>
                  <div className={styles.roleTypeBody}>
                    <EditorIcon />
                    <p>Can view and manage accounts</p>
                  </div>
                </div>
                <div
                  className={styles.roleTypeOption}
                  data-active={roleType === RoleUsersType.VIEWER}
                  onClick={() => setRoleType(RoleUsersType.VIEWER)}
                >
                  <div className={styles.roleTypeHeader}>
                    <RadioButtonList
                      options={[{ value: RoleUsersType.VIEWER, label: 'Viewer', primary: true }]}
                      value={roleType}
                      onChange={(val) => {
                        setRoleType(val);
                      }}
                    />
                  </div>
                  <div className={styles.roleTypeBody}>
                    <ViewerIcon />
                    <p>Can only view accounts</p>
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : null}
        {linkedAccountsMode || (!editedRole && !duplicatedRole) ? (
          <div>
            <p className={styles.label}>{getLinkedAccountName(usersStore.currDispUserCloudAccountType)}s</p>
            <AccountsChooser
              position={linkedAccountsMode ? 'bottom' : 'top'}
              selectedAccounts={accounts}
              setSelectedAccounts={setAccounts}
              accounts={options}
              linkedAccounts={cloudAccountsLinkedAccount}
            />
          </div>
        ) : null}
      </div>
    </CustomModal>
  );
};

CreateOrUpdateRoleModal.propTypes = {
  editedRole: PropTypes.object,
  duplicatedRole: PropTypes.object,
  usersStore: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  refetchRoles: PropTypes.func.isRequired,
  cloudAccountsLinkedAccount: PropTypes.object.isRequired,
  linkedAccountsMode: PropTypes.bool.isRequired,
};

export default withLinkedAccountsContextConsumer(withRolesContextConsumer(CreateOrUpdateRoleModal));
