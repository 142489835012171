import React, { PureComponent } from 'react';
import { Container, Row, ButtonToolbar } from 'reactstrap';
import If from 'shared/components/SimpleIf';
import DownloadAsPngLink from 'shared/components/DownloadAsPngLink';
import PropTypes from 'prop-types';
import { random5DigitNumber } from 'shared/utils/mathUtil';
import GoalIcon from 'shared/img/icons/Goal';

import CustomTooltip from 'shared/components/CustomTooltip';
import AnodotTooltip from 'shared/components/andtComponents/Tooltip';
import IconFromPng from 'shared/components/IconFromPng';
import ICONS from 'shared/constants/assetsConstants';
import {
  Area,
  Bar,
  CartesianGrid,
  Cell,
  ComposedChart,
  LabelList,
  Line,
  Pie,
  PieChart,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import ChartKeysFilter from 'shared/components/ChartKeysFilter/ChartKeysFilter';
import { StringToColor } from 'shared/utils/graphicUtil';
import {
  calcTotalChartCost,
  modifiedDailyBalancesToAverage,
  prepareTotalSumWithOthers,
} from 'shared/utils/dataPrepareUtil';
import {
  CARBON_EMISSION_TO_UNIT,
  CostTrackingConstants,
  CostTypeModes,
  DisplayMetricTypes,
  mapGranLevelToDisplayValue,
} from 'usage/constants/costAndUsageConstants';
import { AWS_QUANTITY_TYPE_SELECT, AwsCommonFields, AwsQuantityTypes } from 'shared/constants/awsConstants';
import CustomizedDateXAxisTick from 'shared/components/CustomizedDateXAxisTick';
import TotalChartValueLabel from 'shared/components/chartComponents/TotalChartValueLabel';
import DisplayedChartColumnsPicker from 'shared/components/DisplayedChartColumnsPicker';
import renderCustomizedPieLabel from 'shared/components/chartComponents/CustomizedPieLabel';
import toast from 'shared/components/andtComponents/Toast';
import { K8S_QUANTITY_TYPE_SELECT, k8sGranLevel } from 'shared/constants/k8sConstants';
import { CLOUD_TYPE_IDS } from 'users/constants/usersConstants';
import LabelCoordinator from 'shared/modules/labelCoordinator';
import { withUserSettingsConsumer } from 'users/utils/contexts/UserSettingsContext';
import { withCUEEventsChartBlockProvider } from 'users/contexts/CUEEventsChartBlockProvider';

const mapQuantityTypeToYAxisDisplay = new Map([
  [AwsQuantityTypes.HOURS, AwsQuantityTypes.HOURS],
  [AwsQuantityTypes.BYTES, AwsQuantityTypes.BYTES],
  [AwsQuantityTypes.REQUESTS, AwsQuantityTypes.REQUESTS],
  [AwsQuantityTypes.OTHER, AwsQuantityTypes.OTHER],
  [AwsQuantityTypes.QUANTITY, 'Resources'],
]);

const SECONDARY_COLUMNS_LIMIT = 30;

class CostChart extends PureComponent {
  constructor(props) {
    super(props);
    const chartDisplayedColumns = props.isSecondaryGroupBy
      ? this.prepareTopColumnsDataList(props.data, SECONDARY_COLUMNS_LIMIT)
      : null;
    this.state = {
      currKey: '',
      chartDisplayedColumns,
    };
    if (props.isSecondaryGroupBy && SECONDARY_COLUMNS_LIMIT < (props.data || []).length) {
      const secondGroupBy = LabelCoordinator.getDisplayNameDataKey('cueDisplayCoordinator', props.secondaryGroupBy);
      toast.warning(
        `Query results are based on ${SECONDARY_COLUMNS_LIMIT} ${secondGroupBy}s.
         You can refine the selected ${secondGroupBy}s.`,
      );
    }
    if (props.warningToast) {
      toast.warning(props.warningToast);
    }
    this.chartRef = React.createRef();
  }

  componentDidMount() {
    const { mainLegendKeysFilterHandler } = this.props;
    mainLegendKeysFilterHandler.legendInitFinished();
  }
  getColTotalCost = (col) => {
    // eslint-disable-next-line no-unused-vars
    const { groupBySecondary, ...costItems } = col;
    return Object.values(costItems).reduce((preValue, curValue) => preValue + curValue, 0);
  };
  getDisplayMetricTypeByUsageType = () => {
    const { k8sMode = '', displayedMetric, selectedUsageType = '' } = this.props;
    if (!k8sMode) {
      return displayedMetric;
    }
    if (selectedUsageType === K8S_QUANTITY_TYPE_SELECT) {
      return DisplayMetricTypes.COST;
    }
    if (selectedUsageType === 'Bytes' || selectedUsageType === 'Memory') {
      return DisplayMetricTypes.BYTES;
    }
    return selectedUsageType;
  };
  getColorForChart = (name) => StringToColor.next(name);
  getChartAvgValue = () => {
    const { data, filteredKeys, isShowOthers } = this.props;
    return modifiedDailyBalancesToAverage(data, filteredKeys, isShowOthers, false);
  };
  getIsGoalReached = () => {
    const { goalLine } = this.props;
    if (!goalLine) {
      return false;
    }
    return this.getChartAvgValue() >= goalLine.target;
  };
  getUniqueVisibleColumnsList = (newVisibleIds) => {
    const { data } = this.props;
    const allColumns = this.prepareColumnDataList(data);
    const arrOfUniqueFilteredIds = Array.isArray(newVisibleIds) ? newVisibleIds : [...newVisibleIds];
    return allColumns.filter(({ id }) => arrOfUniqueFilteredIds.indexOf(id) > -1);
  };
  getTopColumns = (data, topNumber) => {
    const columnCostsArray = data
      .map((col) => ({
        cost: this.getColTotalCost(col),
        name: col.groupBySecondary,
      }))
      .filter((item) => item.cost > -Infinity);
    columnCostsArray.sort((a, b) => b.cost - a.cost);
    const topColumns = columnCostsArray.splice(0, Math.min(columnCostsArray.length, topNumber));
    return topColumns.map(({ name }) => name);
  };
  prepareTopColumnsDataList = (data, topNumber) => {
    const topColunms = this.getTopColumns(data, topNumber);
    return data
      .filter((col) => topColunms.includes(col.groupBySecondary))
      .map((item, id) => ({
        id,
        name: item.groupBySecondary,
      }));
  };
  prepareDisplayedChartColumns = (dataWithTotalCost, chartDisplayedColumns) => {
    const displayedColumnsNames = chartDisplayedColumns?.map((item) => item.name) || [];
    return dataWithTotalCost.filter((col) => displayedColumnsNames.indexOf(col.groupBySecondary) >= 0);
  };
  prepareColumnDataList = (data) => data.map((item, id) => ({ id, name: item.groupBySecondary }));
  removeAddFiltersFromFavouritesIfNeeded = (addFiltersIds) => {
    const { favourites, data } = this.props;
    const idsToRemoveFromFav = favourites.filter((id) => addFiltersIds.indexOf(id) === -1);
    if (idsToRemoveFromFav.length > 0) {
      const removedKeys = data.filter((entry) => idsToRemoveFromFav.includes(entry.id));
      this.props.removeKeysFilterHandler(removedKeys, false); // true == clear all
    }
  };
  handleBarClick = (data, index, dataKey) => {
    const { barClickHandler, barClickable, groupBy } = this.props;
    if ((groupBy || '').startsWith(AwsCommonFields.ACCOUNT_TAGS) && dataKey === 'no tag') {
      toast.warning('No tag drill down is not supported.');
      return;
    }
    if (barClickable && dataKey !== CostTrackingConstants.OTHERS && dataKey !== CostTrackingConstants.NO_DIVISION) {
      const { usageDate } = data;
      barClickHandler(usageDate, dataKey);
    }
  };
  handleAddOrRemoveMoreColumns = (addColumnIds) => {
    const arrOfUniqueFilteredDataKeys = this.getUniqueVisibleColumnsList(addColumnIds); // composing a new visible list
    this.removeAddFiltersFromFavouritesIfNeeded(addColumnIds);
    this.setState({ chartDisplayedColumns: arrOfUniqueFilteredDataKeys });
  };
  handleMouseOver = (dataKey) => {
    const { currKey } = this.state;
    if (dataKey.name !== currKey) {
      this.setState({ currKey: dataKey.name });
    }
  };

  getYLabel = () => {
    const {
      displayedMetric,
      selectedUsageType = '',
      carbonEmissionsValue = '',
      costTypeMode = CostTypeModes.COST,
    } = this.props;
    const displayMetricType = this.getDisplayMetricTypeByUsageType();
    if (costTypeMode === CostTypeModes.CARBON_EMISSIONS) {
      return CARBON_EMISSION_TO_UNIT[carbonEmissionsValue];
    }
    return `${displayMetricType} ${
      selectedUsageType === AWS_QUANTITY_TYPE_SELECT || DisplayMetricTypes.RATE === displayedMetric
        ? ''
        : mapQuantityTypeToYAxisDisplay.get(selectedUsageType) || ''
    }`;
  };

  prepareDataForPie = (data) =>
    Object.values(
      data.reduce((acc, currData) => {
        Object.entries(currData).forEach(([key, value]) => {
          if (key !== 'usageDate' && key !== 'totalSum') {
            if (!acc[key]) {
              acc[key] = {
                value: 0,
                name: key,
              };
            }
            acc[key].value += +value;
          }
        });
        return acc;
      }, {}),
    );

  renderChartByType = (dataKeys) => {
    const { isAreaChart, isLineChart } = this.props;
    if (isAreaChart) {
      return this.renderChart(Area, dataKeys);
    }
    if (isLineChart) {
      return this.renderChart(Line, dataKeys);
    }
    return this.renderChart(Bar, dataKeys);
  };

  renderChart(Component, dataKeys) {
    return dataKeys.map((dataKey) => {
      const color =
        dataKey.name === CostTrackingConstants.OTHERS
          ? CostTrackingConstants.OTHERS_NAME_COLOR
          : this.getColorForChart(dataKey.name);
      const { barClickable } = this.props;
      return (
        <Component
          type="monotone"
          key={dataKey.name}
          maxBarSize={150}
          dataKey={dataKey.name}
          // line has not this prop but without it - event dot is not rendered (???)
          stackId="a"
          stroke={color}
          fill={color}
          onClick={(data, idx) => this.handleBarClick(data, idx, dataKey.name)}
          onMouseOver={() => this.handleMouseOver(dataKey)}
          style={barClickable ? { cursor: 'pointer' } : null}
          cursor={barClickable ? 'pointer' : 'default'}
          strokeWidth={1.5}
        />
      );
    });
  }

  renderGoalInfoLabel = (goal) => {
    if (!goal) {
      return null;
    }
    const { granLevel, displayedMetric, handleGoalEdit, numStrAbriviaionByDisplayMetric } = this.props;
    const avg = this.getChartAvgValue();
    return (
      <TotalChartValueLabel
        granularity={granLevel}
        goalName={goal.title}
        editHandler={handleGoalEdit}
        goalReached={this.getIsGoalReached()}
        subTitle={`Avg. ${mapGranLevelToDisplayValue.get(granLevel) || 'current'}
         amount: ${numStrAbriviaionByDisplayMetric(avg, avg, displayedMetric, null)}`}
        totalCost={goal.target}
        costSelected={null}
        usageSelected={null}
        costTypeMode={CostTypeModes.GOAL}
        displayedMetric={displayedMetric}
      />
    );
  };

  renderTotalCostLabel = (totalCost, filteredKeys, filteredDataKeys) => {
    const {
      dataForAvgRateCalculation,
      costValue,
      selectedUsageTypeLabel = '',
      costTypeMode = CostTypeModes.COST,
      isCumulative,
      isShowOthers,
      isSecondaryGroupBy,
      costOptions = [],
      carbonEmissionsValue = '',
      carbonEmissionsOptions = [],
    } = this.props;
    const costDataFormatted = dataForAvgRateCalculation
      ? prepareTotalSumWithOthers(
          dataForAvgRateCalculation.costData,
          filteredKeys,
          filteredDataKeys,
          isShowOthers,
          isSecondaryGroupBy,
        )
      : null;
    const usageDataFormatted = dataForAvgRateCalculation
      ? prepareTotalSumWithOthers(
          dataForAvgRateCalculation.usageData,
          filteredKeys,
          filteredDataKeys,
          isShowOthers,
          isSecondaryGroupBy,
        )
      : null;
    return (
      <TotalChartValueLabel
        dataForRate={{
          totalCostSum: costDataFormatted && calcTotalChartCost(costDataFormatted.dataWithTotalCost, isCumulative),
          totalUsageSum: usageDataFormatted && calcTotalChartCost(usageDataFormatted.dataWithTotalCost, isCumulative),
        }}
        totalCost={totalCost}
        costSelected={costValue}
        usageSelected={selectedUsageTypeLabel}
        costTypeMode={costTypeMode}
        displayedMetric={this.getDisplayMetricTypeByUsageType()}
        costOptions={costOptions}
        carbonEmissionsValue={carbonEmissionsValue}
        carbonEmissionsOptions={carbonEmissionsOptions}
      />
    );
  };
  renderLegend() {
    const {
      data,
      favourites,
      mainLegendKeysFilterHandler,
      filteredKeys,
      addKeysFilterHandler,
      setKeysFilterHandler,
      removeKeysFilterHandler,
      isShowOthersChangeHandler,
      isNewKeyNeeded,
      dataKeys,
      isShowOthers,
      isOthersVisible,
      groupBy,
      isFiltersOpen,
      isLineChart,
      isFromCustomDb,
      legendInitiated,
      isSecondaryGroupBy,
      secondaryGroupBy,
      currentCloudType,
      k8sMode = '',
      selectedUsageType = '',
    } = this.props;
    const { chartDisplayedColumns } = this.state;
    const filteredDataKeys = mainLegendKeysFilterHandler.filterDataKeys(dataKeys, filteredKeys);
    const chartKeysData = mainLegendKeysFilterHandler.prepareFilterKeysFromDataKeys(dataKeys);
    const allColumns = isSecondaryGroupBy ? this.prepareColumnDataList(data) : null;
    return (
      <div>
        {isSecondaryGroupBy && (
          <Row style={{ marginTop: '20px' }}>
            <DisplayedChartColumnsPicker
              secondaryGroupBy={secondaryGroupBy}
              handleAddOrRemoveMoreColumns={this.handleAddOrRemoveMoreColumns}
              allColumns={allColumns}
              visibleColumns={chartDisplayedColumns}
              currentCloudType={currentCloudType}
            />
          </Row>
        )}
        <Row style={{ marginTop: '20px', marginLeft: isFiltersOpen ? '50px' : '60px' }}>
          {k8sMode &&
          !isLineChart &&
          (selectedUsageType === AwsQuantityTypes.MEMORY || selectedUsageType === AwsQuantityTypes.CPU) &&
          !(currentCloudType === CLOUD_TYPE_IDS.GCP && k8sMode === k8sGranLevel.NODES) ? (
            <div style={{ margin: '10px 10px 0 0' }}>
              <ButtonToolbar style={{ margin: 0 }}>
                <AnodotTooltip
                  placement="top"
                  title="Filter by Actual or Required items to get accurate total number"
                  arrow
                >
                  <button type="button" className="panel__btn_lg">
                    <IconFromPng icon={ICONS.info} />
                  </button>
                </AnodotTooltip>
              </ButtonToolbar>
            </div>
          ) : null}
          <ChartKeysFilter
            data={chartKeysData}
            legendInitiated={isSecondaryGroupBy ? null : legendInitiated}
            favourites={favourites}
            filteredKeys={filteredKeys}
            initFavourites={mainLegendKeysFilterHandler.initFavourites}
            addKeysFilterHandler={addKeysFilterHandler}
            setKeysFilterHandler={setKeysFilterHandler}
            removeKeysFilterHandler={removeKeysFilterHandler}
            isOthersVisible={isOthersVisible}
            isShowOthers={isShowOthers}
            isShowOthersChange={isShowOthersChangeHandler}
            isNewKeyNeeded={isNewKeyNeeded}
            filteredDataKeys={filteredDataKeys}
            groupBy={isSecondaryGroupBy ? null : groupBy}
            isLineChart={isLineChart}
            isFromCustomDb={isFromCustomDb}
            disableUpdateFavourites
          />
        </Row>
      </div>
    );
  }
  renderGoalsLine = () => {
    const { goalLine, displayedMetric, granLevel, numStrAbriviaionByDisplayMetric } = this.props;
    if (!goalLine) {
      return null;
    }
    const avg = this.getChartAvgValue();
    const goalReached = this.getIsGoalReached();
    return (
      <ReferenceLine
        y={goalLine.target}
        isFront
        strokeWidth={1}
        stroke="#f00"
        strokeDasharray="6 6"
        label={({ viewBox }) => (
          <GoalIcon
            x={viewBox.x - 8}
            y={viewBox.y - 8}
            className="goal-icon"
            avg={numStrAbriviaionByDisplayMetric(avg, avg, displayedMetric, null)}
            granularity={granLevel}
            goalReached={goalReached}
            target={numStrAbriviaionByDisplayMetric(goalLine.target, goalLine.target, displayedMetric, null)}
            name={goalLine.title}
          />
        )}
      />
    );
  };

  render() {
    const {
      data,
      displayedMetric,
      mainLegendKeysFilterHandler,
      filteredKeys,
      isRenderDownloadAsPng,
      chartId,
      yAxisLabelTitle,
      dataKeys,
      xAxisLabelTitle,
      isTrendLine,
      selectedUsageType = '',
      isFiltersOpen,
      isPieChart,
      isLineChart,
      isCumulative,
      goalLine,
      isSecondaryGroupBy,
      isShowOthers,
      granLevel,
      renderEventsComponents,
      numStrAbriviaionByDisplayMetric,
      isUsageChecked = true,
    } = this.props;
    const { chartDisplayedColumns, currKey } = this.state;
    const finalChartId = chartId || `cost-chart-${random5DigitNumber()}`;
    const filteredDataKeys = mainLegendKeysFilterHandler.filterDataKeys(dataKeys, filteredKeys);
    const displayMetricType = this.getDisplayMetricTypeByUsageType();

    let { dataWithTotalCost, maxValue, minValue, maxSingleValue } = prepareTotalSumWithOthers(
      data,
      filteredKeys,
      filteredDataKeys,
      isShowOthers,
      isSecondaryGroupBy,
    );
    dataWithTotalCost = isSecondaryGroupBy
      ? this.prepareDisplayedChartColumns(dataWithTotalCost, chartDisplayedColumns)
      : dataWithTotalCost;

    const dataForPieChart = isPieChart ? this.prepareDataForPie(dataWithTotalCost) : [];
    const totalCost = calcTotalChartCost(dataWithTotalCost, isCumulative);
    const customYLabel = this.getYLabel();
    return (
      <Container>
        <div className="d-flex">
          {this.renderTotalCostLabel(totalCost, filteredKeys, filteredDataKeys)}
          {this.renderGoalInfoLabel(goalLine)}
        </div>
        <If cond={isRenderDownloadAsPng}>
          <DownloadAsPngLink chartRef={this.chartRef} />
        </If>
        <Row
          id={finalChartId}
          style={isFiltersOpen ? { boxShadow: 'none', border: 'none' } : {}}
          className="cost-chart-container"
        >
          <ResponsiveContainer height={400} ref={this.chartRef}>
            {isPieChart ? (
              <PieChart>
                <Pie
                  data={dataForPieChart}
                  dataKey="value"
                  nameKey="name"
                  innerRadius="50%"
                  outerRadius="85%"
                  labelLine={false}
                  isAnimationActive={false}
                  label={(item) =>
                    renderCustomizedPieLabel(
                      item,
                      (value) => numStrAbriviaionByDisplayMetric(totalCost, value, displayMetricType, null),
                      (value) =>
                        numStrAbriviaionByDisplayMetric(totalCost, value, displayedMetric, false, {
                          showPercent: true,
                          decimal: 0,
                        }),
                    )
                  }
                >
                  {dataForPieChart.map((currData, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={
                        currData.name === 'Others'
                          ? CostTrackingConstants.OTHERS_NAME_COLOR
                          : this.getColorForChart(currData.name)
                      }
                    />
                  ))}
                </Pie>
                <Tooltip
                  formatter={(value) => `${numStrAbriviaionByDisplayMetric(totalCost, value, displayMetricType, null)}
                        (${numStrAbriviaionByDisplayMetric(
                          totalCost,
                          (value / (totalCost || 1)) * 100,
                          displayedMetric,
                          false,
                          { showPercent: true, decimal: 2 },
                        )})`}
                />
              </PieChart>
            ) : (
              <ComposedChart
                stackOffset="sign"
                width={1000}
                height={400}
                data={dataWithTotalCost}
                margin={{
                  top: 0,
                  right: 30,
                  left: 20,
                  bottom: 20,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis
                  label={{ value: xAxisLabelTitle || '' }}
                  dataKey={isSecondaryGroupBy ? 'groupBySecondary' : 'usageDate'}
                  tickCount={isSecondaryGroupBy ? undefined : 0}
                  interval={isSecondaryGroupBy ? 0 : undefined}
                  tick={
                    <CustomizedDateXAxisTick
                      granLevel={granLevel}
                      detailedInfo={isSecondaryGroupBy}
                      isSecondaryGroupBy={isSecondaryGroupBy}
                    />
                  }
                />
                <YAxis
                  tickLine
                  label={{
                    value: yAxisLabelTitle || customYLabel,
                    offset: 0,
                    angle: -90,
                    position: 'left',
                  }}
                  type="number"
                  domain={[
                    'dataMin',
                    (dataMax) => {
                      const goalTarget = goalLine ? goalLine.target : -Infinity;
                      return Math.max(isLineChart && !isTrendLine ? maxSingleValue : dataMax, goalTarget);
                    },
                  ]}
                  padding={{ top: isTrendLine ? 60 : 20 }} // add top margin for trendline labels
                  tickFormatter={(value) =>
                    numStrAbriviaionByDisplayMetric(
                      // calc max shown value for formatting (-10 000 means we need to format like 10K as well as 10 000)
                      Math.max(maxValue, Math.abs(minValue)),
                      value,
                      ['Memory', 'Bytes'].includes(selectedUsageType) && isUsageChecked ? '' : displayedMetric,
                      null,
                      { decimal: +value < 10 ? 4 : 2 },
                    )
                  }
                />
                <Tooltip
                  cursor={false}
                  content={
                    <CustomTooltip
                      data={data}
                      currKey={currKey}
                      displayedMetric={
                        selectedUsageType &&
                        ['memory', 'bytes'].includes(selectedUsageType.toLowerCase()) &&
                        isUsageChecked
                          ? ''
                          : displayedMetric
                      }
                    />
                  }
                />
                <ReferenceLine y={0} stroke="#000" />
                {this.renderChartByType(filteredDataKeys)}
                {isTrendLine ? (
                  <Line type="linear" dataKey="totalSum" stroke="#ff7300" isAnimationActive={false}>
                    <LabelList
                      dataKey="totalSum"
                      position="top"
                      angle="35"
                      offset={20}
                      formatter={(value) =>
                        numStrAbriviaionByDisplayMetric(
                          Math.max(maxValue, Math.abs(minValue)),
                          value,
                          displayMetricType,
                          null,
                        )
                      }
                    />
                  </Line>
                ) : null}
                {this.renderGoalsLine()}
                {renderEventsComponents(maxSingleValue)}
              </ComposedChart>
            )}
          </ResponsiveContainer>
        </Row>
        {this.renderLegend()}
      </Container>
    );
  }
}

CostChart.propTypes = {
  chartId: PropTypes.string,
  data: PropTypes.object.isRequired,
  filteredKeys: PropTypes.object.isRequired,
  favourites: PropTypes.object.isRequired,
  mainLegendKeysFilterHandler: PropTypes.object.isRequired,
  addKeysFilterHandler: PropTypes.func.isRequired,
  setKeysFilterHandler: PropTypes.func.isRequired,
  removeKeysFilterHandler: PropTypes.func.isRequired,
  isShowOthersChangeHandler: PropTypes.func.isRequired,
  isNewKeyNeeded: PropTypes.bool.isRequired,
  isCumulative: PropTypes.bool,
  yAxisLabelTitle: PropTypes.string,
  xAxisLabelTitle: PropTypes.string,
  barClickHandler: PropTypes.func.isRequired,
  barClickable: PropTypes.bool.isRequired,
  isAreaChart: PropTypes.bool.isRequired,
  isPieChart: PropTypes.bool,
  isLineChart: PropTypes.bool,
  displayedMetric: PropTypes.object.isRequired,
  selectedUsageType: PropTypes.string,
  selectedUsageTypeLabel: PropTypes.string,
  isRenderDownloadAsPng: PropTypes.bool,
  dataKeys: PropTypes.object.isRequired,
  isTrendLine: PropTypes.bool,
  isOthersVisible: PropTypes.bool,
  isShowOthers: PropTypes.bool.isRequired,
  granLevel: PropTypes.string,
  groupBy: PropTypes.string,
  costTypeMode: PropTypes.string,
  isFiltersOpen: PropTypes.bool,
  handleGoalEdit: PropTypes.func,
  renderEventsComponents: PropTypes.func.isRequired,
  legendInitiated: PropTypes.bool,
  isFromCustomDb: PropTypes.bool,
  k8sMode: PropTypes.string,
  isSecondaryGroupBy: PropTypes.bool,
  secondaryGroupBy: PropTypes.string,
  dataForAvgRateCalculation: PropTypes.object,
  goalLine: PropTypes.object,
  costValue: PropTypes.string,
  warningToast: PropTypes.string,
  currentCloudType: PropTypes.number,
  numStrAbriviaionByDisplayMetric: PropTypes.func.isRequired,
  costOptions: PropTypes.array,
  carbonEmissionsOptions: PropTypes.array,
  carbonEmissionsValue: PropTypes.string,
};

const ObserverCostChart = withCUEEventsChartBlockProvider(withUserSettingsConsumer(CostChart));

export default ObserverCostChart;
