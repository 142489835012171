import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Input, InputGroup } from 'reactstrap';
import FiltersSidebarContainer from 'shared/components/FilterSidebar/FiltersSidebarContainer';
import { PageNames } from 'shared/constants/appConstants';
import { ACCOUNT_FEATURES } from 'users/constants/usersConstants';
import { AwsQuantityTypes, K8S_CUE_FIELDS } from 'shared/constants/awsConstants';
import { formatExcludeFilterMap } from 'shared/utils/filtersUtils';
import CustomModal from 'shared/components/andtComponents/Modal';
import { withInvoiceFiltersContextConsumer } from 'invoices/contexts/InvoiceFiltersContext';
import checkFeatureFlag from 'shared/utils/featureFlagUtil';

// need to check if added category dont make cyclic dependency. For example if 1 -> 2 - then 2 -> 1 should be disabled
// the same for 1 -> 2, 2 -> 3, then 3 -> 1 should be disabled
const isCycle = (currentCategory, allCategories, visitedCategories = []) => {
  if (visitedCategories.includes(currentCategory)) {
    return true;
  }
  const { categories } = allCategories.find((c) => c.name === currentCategory) || {};
  if (!categories || categories.length === 0) {
    return false;
  }
  return categories.some((c) => isCycle(c, allCategories, [...visitedCategories, currentCategory]));
};

const getCategoriesFromFilters = (filters) => ([...filters].find((f) => f[0] === 'categories') || [null, []])[1] || [];

const FilterGroupModal = ({
  closeModal,
  categories,
  isOpen,
  isEditMode = false,
  onSave,
  category = null,
  usersStore,
  isLoading = false,
  getPageFilters,
}) => {
  const [name, setName] = useState(isEditMode ? category.name : '');
  const [fieldToFilterValuesMap, setFieldToFilterValuesMap] = useState(
    new Map(isEditMode ? category.filters : undefined),
  );
  const [selectedOptionsMap, setSelectedOptionsMap] = useState(
    new Map(isEditMode ? category.selectedOptions : undefined),
  );
  const [excludedFiltersStatusMap, setExcludedFiltersStatusMap] = useState(
    new Map(isEditMode ? category.excludedFilters : undefined),
  );

  const getFieldToFieldDistinctValuesMap = () => {
    const fields = getPageFilters(PageNames.FILTER_GROUP, usersStore.currDispUserCloudAccountType, [
      {
        name: 'categories',
        filter: (value) => {
          if (value === name && isEditMode) {
            return false;
          }
          const selectedCategories = fieldToFilterValuesMap.get('categories') || [];
          const filterCategories = [...getCategoriesFromFilters(fieldToFilterValuesMap), value];
          const categoriesWithCurrent = [
            ...categories.map((c) => ({
              name: c.name,
              categories:
                isEditMode && c.uuid === category.uuid ? filterCategories : getCategoriesFromFilters(c.filters),
            })),
            ...(!isEditMode
              ? [
                  {
                    name,
                    categories: filterCategories,
                  },
                ]
              : []),
          ];
          if (selectedCategories.includes(value)) {
            return true;
          }
          return !isCycle(name, categoriesWithCurrent);
        },
      },
    ]);
    if (!checkFeatureFlag(usersStore, ACCOUNT_FEATURES.CUE_K8S_FILTERS)) {
      K8S_CUE_FIELDS.forEach((key) => {
        fields.delete(key);
      });
    }
    return fields;
  };

  const handleChangeFilterType = (field, subField) => {
    setExcludedFiltersStatusMap(formatExcludeFilterMap(excludedFiltersStatusMap, field, subField));
  };
  const handleFilterChange = (filterType, selectedOptions) => {
    let filteredValues = [];
    const removedQuantityOption = selectedOptions === AwsQuantityTypes.QUANTITY ? null : selectedOptions;
    filteredValues = (removedQuantityOption || []).map(({ value }) => value);
    const newFieldToFilterMap = new Map(fieldToFilterValuesMap);
    newFieldToFilterMap.set(filterType, filteredValues);
    setFieldToFilterValuesMap(newFieldToFilterMap);

    const newSelectedOptionsMap = new Map(selectedOptionsMap);
    newSelectedOptionsMap.set(filterType, selectedOptions);
    setSelectedOptionsMap(newSelectedOptionsMap);
  };
  const handleRemoveFieldFromFiltersValuesMap = (field) => {
    const newFieldToFilterMap = new Map(fieldToFilterValuesMap);
    newFieldToFilterMap.delete(field);
    setFieldToFilterValuesMap(newFieldToFilterMap);
  };
  const nameTaken = categories
    .filter((c) => !isEditMode || c.uuid !== category.uuid)
    .map(({ name: categoryName }) => categoryName)
    .includes(name);
  return (
    <CustomModal
      onClose={closeModal}
      open={isOpen}
      overrideStyles={{ width: 800 }}
      title={isEditMode ? 'Edit Filter Group' : 'Create Filter Group'}
      className="categories__create-category-modal"
      saveDisabled={isLoading || nameTaken || !name}
      onSave={() => {
        const data = {
          name,
          filters: [...fieldToFilterValuesMap],
          selectedOptions: [...selectedOptionsMap],
          excludedFilters: [...excludedFiltersStatusMap],
          creationTime: isEditMode ? category.creationTime : undefined,
          createdBy: isEditMode ? category.createdBy : undefined,
        };
        onSave(data, isEditMode ? category.uuid : undefined);
      }}
    >
      <>
        <div>
          <p className="mb-2">Filter Group name:</p>
          <InputGroup>
            <Input
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder="Enter Filter Group name"
            />
          </InputGroup>
          {nameTaken ? <p style={{ color: 'red' }}>Filter group with such name already exists</p> : null}
        </div>
        <div>
          <FiltersSidebarContainer
            isOpen
            selectedOptionsMap={selectedOptionsMap}
            filtersStatusMap={fieldToFilterValuesMap}
            excludedFiltersStatusMap={excludedFiltersStatusMap}
            fieldToFieldDistincValuesMap={getFieldToFieldDistinctValuesMap()}
            currDispUserCloudAccountType={usersStore.currDispUserCloudAccountType}
            handleChangeFilterType={handleChangeFilterType}
            handleFilterChange={handleFilterChange}
            handleRemoveFieldFromFiltersValuesMap={handleRemoveFieldFromFiltersValuesMap}
            className="filter-group"
          />
        </div>
      </>
    </CustomModal>
  );
};

FilterGroupModal.propTypes = {
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      uuid: PropTypes.string.isRequired,
    }),
  ).isRequired,
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.object.isRequired,
  isEditMode: PropTypes.bool,
  isLoading: PropTypes.bool,
  onSave: PropTypes.func.isRequired,
  usersStore: PropTypes.object.isRequired,
  getPageFilters: PropTypes.func.isRequired,
  category: PropTypes.object,
};

const ObserverFilterGroupModal = withInvoiceFiltersContextConsumer(observer(FilterGroupModal));
export default ObserverFilterGroupModal;
