import { useMutation, useQuery } from '@tanstack/react-query';
import apiConstants from 'shared/api/apiConstants';
import { queryClient } from 'queryClient';
import { useRootStore } from 'app/contexts/RootStoreContext';
import { STALE_TIME } from 'users/containers/Organization/consts';
import {
  addPartialRolePermissions,
  createRole,
  deletePartialRolePermissions,
  deleteRoles,
  fetchAvailableCategoryActions,
  fetchMergedPermissionsOfRoles,
  fetchPartialRolePermissions,
  fetchRoleById,
  fetchRoleDataAccess,
  fetchRolePermissions,
  fetchRoles,
  setRolePermissions,
  updateAccountsAccessibilityForRole,
  updateCostCentersAccessibilityForRole,
  updateResellerCustomersAccessibilityForRole,
  updateRole,
  updateSubRoles,
} from './apiRoles';
import { handleError } from './helperFunctions';

export default function useRoles(isDisabled = false) {
  const { usersStore } = useRootStore();
  const userAccountKey = usersStore?.currDispUserAccountKey;

  const rolesQueryKey = [apiConstants.QUERY_KEYS.USER_MANAGEMENT_ROLES, userAccountKey];
  const usersQueryKey = [apiConstants.QUERY_KEYS.USER_MANAGEMENT_USERS, userAccountKey];

  return {
    invalidate: () => queryClient.invalidateQueries(rolesQueryKey),
    reset: () => queryClient.resetQueries(rolesQueryKey),
    fetchRoles: (params) => {
      if (params && !params.search) {
        delete params.search;
      }
      const queryKeyFetchRoles = [...rolesQueryKey];
      if (params && Object.values(params)?.length) {
        const paramsQueryKey = Object.values(params).filter((p) => p);
        if (paramsQueryKey.length) {
          queryKeyFetchRoles.push([...Object.values(params).filter((p) => p)]);
        }
      }

      return useQuery(queryKeyFetchRoles, () => fetchRoles(params), {
        enabled: !isDisabled,
        retry: false,
        staleTime: STALE_TIME,
        onError: handleError,
      });
    },
    fetchRoleById: (roleId) =>
      useQuery([...rolesQueryKey, roleId], () => fetchRoleById(roleId), {
        enabled: !isDisabled,
        retry: false,
        staleTime: STALE_TIME,
        onError: handleError,
      }),
    fetchMergedPermissionsOfRoles: ({ rolesIds }) =>
      useQuery([...rolesQueryKey, ...rolesIds], () => fetchMergedPermissionsOfRoles(rolesIds), {
        enabled: !isDisabled,
        retry: false,
        staleTime: STALE_TIME,
        onError: handleError,
      }),
    fetchRolePermissions: (roleId) =>
      useQuery(
        [...rolesQueryKey, apiConstants.QUERY_KEYS.USER_MANAGEMENT_ROLE_PERMISSIONS, roleId],
        () => fetchRolePermissions(roleId),
        {
          enabled: !isDisabled,
          retry: false,
          staleTime: STALE_TIME,
          onError: handleError,
        },
      ),
    fetchPartialRolePermissions: ({ roleId, category, actionId, paginationToken, search }) =>
      useQuery(
        [
          ...rolesQueryKey,
          apiConstants.QUERY_KEYS.USER_MANAGEMENT_ROLE_PERMISSIONS,
          roleId,
          category,
          actionId,
          paginationToken,
          search,
        ],
        () => fetchPartialRolePermissions(roleId, category, actionId, paginationToken, search),
        {
          enabled: !isDisabled,
          retry: false,
          staleTime: STALE_TIME,
          onError: handleError,
        },
      ),
    fetchAvailableCategoryActions: () =>
      useQuery(
        [apiConstants.QUERY_KEYS.USER_MANAGEMENT_ROLES_AVAILABLE_ACTION_CATEGORIES],
        () => fetchAvailableCategoryActions(),
        {
          enabled: !isDisabled,
          retry: false,
          staleTime: STALE_TIME,
          onError: handleError,
        },
      ),
    fetchRoleDataAccess: (roleId) =>
      useQuery(
        [...rolesQueryKey, apiConstants.QUERY_KEYS.USER_MANAGEMENT_DATA_ACCESS, roleId],
        () => fetchRoleDataAccess(roleId),
        {
          enabled: !isDisabled,
          retry: false,
          staleTime: STALE_TIME,
          onError: handleError,
        },
      ),
    createRole: useMutation(({ role }) => createRole(role), {
      onSuccess: async () => {
        await queryClient.invalidateQueries(rolesQueryKey);
        await queryClient.invalidateQueries(usersQueryKey);
      },
      onError: handleError,
    }),
    updateRole: useMutation(({ roleId, roleDetails }) => updateRole(roleId, roleDetails), {
      onSuccess: async () => {
        await queryClient.invalidateQueries(rolesQueryKey);
        await queryClient.invalidateQueries(usersQueryKey);
      },
      onError: handleError,
    }),
    deleteRoles: useMutation(({ rolesIds }) => deleteRoles(rolesIds), {
      onSuccess: async () => {
        await queryClient.invalidateQueries(rolesQueryKey);
        await queryClient.invalidateQueries(usersQueryKey);
      },
      onError: handleError,
    }),
    updateSubRoles: useMutation(({ roleId, subRolesPayload }) => updateSubRoles(roleId, subRolesPayload), {
      onSuccess: async (_, variables) => {
        await queryClient.invalidateQueries([...rolesQueryKey, variables.roleId]);
        await queryClient.invalidateQueries([
          ...rolesQueryKey,
          apiConstants.QUERY_KEYS.USER_MANAGEMENT_ROLE_PERMISSIONS,
        ]);
        await queryClient.invalidateQueries([
          ...usersQueryKey,
          apiConstants.QUERY_KEYS.USER_MANAGEMENT_ROLE_PERMISSIONS,
        ]);
        await queryClient.invalidateQueries([
          ...rolesQueryKey,
          apiConstants.QUERY_KEYS.USER_MANAGEMENT_DATA_ACCESS,
          variables.roleId,
        ]);
      },
      onError: handleError,
    }),
    setRolePermissions: useMutation(
      ({ roleId, roleCategoryPermissions }) => setRolePermissions(roleId, roleCategoryPermissions),
      {
        onSuccess: async (_, variables) => {
          await queryClient.invalidateQueries([...rolesQueryKey, variables.roleId]);
          await queryClient.invalidateQueries([
            ...rolesQueryKey,
            apiConstants.QUERY_KEYS.USER_MANAGEMENT_ROLE_PERMISSIONS,
          ]);
          await queryClient.invalidateQueries([
            ...usersQueryKey,
            apiConstants.QUERY_KEYS.USER_MANAGEMENT_ROLE_PERMISSIONS,
          ]);
        },
        onError: handleError,
      },
    ),
    addPartialRolePermissions: useMutation(
      ({ roleId, category, action, roleCategoryPermissions }) =>
        addPartialRolePermissions(roleId, category, action, roleCategoryPermissions),
      {
        onSuccess: async (_, variables) => {
          await queryClient.invalidateQueries([
            ...rolesQueryKey,
            variables.roleId,
            variables.category,
            variables.action,
          ]);
          await queryClient.invalidateQueries([
            ...rolesQueryKey,
            apiConstants.QUERY_KEYS.USER_MANAGEMENT_ROLE_PERMISSIONS,
          ]);
          await queryClient.invalidateQueries([
            ...usersQueryKey,
            apiConstants.QUERY_KEYS.USER_MANAGEMENT_ROLE_PERMISSIONS,
          ]);
        },
        onError: handleError,
      },
    ),
    deletePartialRolePermissions: useMutation(
      ({ roleId, category, action, roleCategoryPermissions }) =>
        deletePartialRolePermissions(roleId, category, action, roleCategoryPermissions),
      {
        onSuccess: async (_, variables) => {
          await queryClient.invalidateQueries([
            ...rolesQueryKey,
            variables.roleId,
            variables.category,
            variables.action,
          ]);
          await queryClient.invalidateQueries([
            ...rolesQueryKey,
            apiConstants.QUERY_KEYS.USER_MANAGEMENT_ROLE_PERMISSIONS,
          ]);
          await queryClient.invalidateQueries([
            ...usersQueryKey,
            apiConstants.QUERY_KEYS.USER_MANAGEMENT_ROLE_PERMISSIONS,
          ]);
        },
        onError: handleError,
      },
    ),
    updateResellerCustomersAccessibilityForRole: useMutation(
      ({ roleId, roleDataAccess }) => updateResellerCustomersAccessibilityForRole(roleId, roleDataAccess),
      {
        onSuccess: (_, variables) =>
          queryClient.invalidateQueries([
            ...rolesQueryKey,
            apiConstants.QUERY_KEYS.USER_MANAGEMENT_DATA_ACCESS,
            variables.roleId,
          ]),
        onError: handleError,
      },
    ),
    updateAccountsAccessibilityForRole: useMutation(
      ({ roleId, roleDataAccess }) => updateAccountsAccessibilityForRole(roleId, roleDataAccess),
      {
        onSuccess: () =>
          queryClient.invalidateQueries([...rolesQueryKey, apiConstants.QUERY_KEYS.USER_MANAGEMENT_DATA_ACCESS]),
        onError: handleError,
      },
    ),
    updateCostCentersAccessibilityForRole: useMutation(
      ({ roleId, roleDataAccess }) => updateCostCentersAccessibilityForRole(roleId, roleDataAccess),
      {
        onSuccess: () =>
          queryClient.invalidateQueries([...rolesQueryKey, apiConstants.QUERY_KEYS.USER_MANAGEMENT_DATA_ACCESS]),
        onError: handleError,
      },
    ),
  };
}
