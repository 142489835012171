import React from 'react';
import { Bar, BarChart, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import PropTypes from 'prop-types';
import PanelWithActionButton from 'shared/components/panels/PanelWithActionButton';
import { REPORT_TYPES } from 'usage/constants/usageConstants';
import ReportIcon from 'mdi-react/FileDocumentBoxIcon';
import LinkIconButton from 'shared/components/buttons/LinkIconButton';
import moment from 'moment/moment';
import { useUserSettingsContext } from 'users/utils/contexts/UserSettingsContext';
import { DisplayMetricTypes } from 'usage/constants/costAndUsageConstants';

const getYesterdayDateLabel = (data) => {
  let label = '';
  if (data) {
    const keys = Object.keys(data[0]);
    label = keys[1];
  }

  return label;
};
const tickFormatter = (value) => {
  const limit = 23; // put your maximum character
  if (value.length < limit) return value;
  return `${value.substring(0, limit)}...`;
};

const getDayBeforeYesterdayDateLabel = (data) => {
  let label = '';
  if (data) {
    const keys = Object.keys(data[0]);
    label = keys[2];
  }
  return label;
};

const AppTileClicks = ({
  data,
  actionButtonStyle,
  handleGoToCostExplorerReport,
  isCustomDashboard = false,
  overrideCurrency = null,
}) => {
  if (!data || data.length === 0) {
    return null;
  }
  const { numStrAbriviaionByDisplayMetric } = useUserSettingsContext();

  const renderBody = () => (
    <ResponsiveContainer height={300} className="dashboard__active-users-chart">
      <BarChart
        width={650}
        height={220}
        data={data}
        layout="vertical"
        barGap={3}
        barCategoryGap={40}
        stackOffset="expand"
        margin={{ top: 0, right: 20, bottom: 0, left: 40 }}
      >
        <XAxis
          type="number"
          tickFormatter={(value) =>
            numStrAbriviaionByDisplayMetric(value, value, DisplayMetricTypes.COST, overrideCurrency)
          }
        />
        <Legend formatter={(value) => moment(value).format('MMM DD')} />
        <YAxis type="category" dataKey="name" tickLine={false} verticalAnchor="start" tickFormatter={tickFormatter} />
        <Tooltip
          formatter={(value) =>
            numStrAbriviaionByDisplayMetric(value, value, DisplayMetricTypes.COST, overrideCurrency)
          }
          itemSorter={() => 1}
        />
        <Bar dataKey={getYesterdayDateLabel(data)} fill="#a44ab0" barSize={13} />
        <Bar dataKey={getDayBeforeYesterdayDateLabel(data)} fill="#a8a5a5" barSize={13} />
      </BarChart>
    </ResponsiveContainer>
  );
  if (isCustomDashboard) {
    return renderBody();
  }
  return (
    <PanelWithActionButton
      divider
      title="Daily Change Top 5 Services Cost"
      panelClass="panel--narrow"
      ActionButtonComp={LinkIconButton}
      ButtonIcon={ReportIcon}
      handler={handleGoToCostExplorerReport}
      id={REPORT_TYPES.YESTERDAY_TOP_5_SERVICES}
      actionButtonStyle={actionButtonStyle}
      lg={12}
      xl={6}
      md={12}
    >
      {renderBody()}
    </PanelWithActionButton>
  );
};

AppTileClicks.propTypes = {
  data: PropTypes.object.isRequired,
  isCustomDashboard: PropTypes.bool,
  actionButtonStyle: PropTypes.object.isRequired,
  handleGoToCostExplorerReport: PropTypes.func.isRequired,
  overrideCurrency: PropTypes.string,
};

export default AppTileClicks;
