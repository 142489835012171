/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
import { API } from 'shared/utils/apiMiddleware';
import { sanitizeParam, sanitizeQuery } from 'shared/utils/apiUtil';
import { K8S_QUANTITY_TYPE_SELECT } from 'shared/constants/k8sConstants';
import { K8S_USAGE_TYPES } from '../constants/k8sConstants';

class KubernetesApi {
  getK8sUsageData = async (startDate, endDate) => {
    const apiQuery = `/api/v1/kubernetes?startDate=${startDate}&endDate=${endDate}`;
    const result = await API.get('billings', apiQuery);
    return result;
  };
  getK8sMetricTypesByUsage = (usage) => {
    if (usage === K8S_QUANTITY_TYPE_SELECT || !usage) {
      return [];
    }
    if (
      [K8S_USAGE_TYPES.BYTES, K8S_USAGE_TYPES.HOURS, K8S_USAGE_TYPES.POD_HOURS].includes(usage) ||
      usage.includes(K8S_USAGE_TYPES.QUANTITY)
    ) {
      return ['actual'];
    }
    return ['actual', 'requirements'];
  };
  getK8sCostAndUsage = (
    groupBy,
    secondaryGroupBy = false,
    whereParams,
    startDate,
    endDate,
    periodGranLevel,
    filterParams,
    costUsageType,
    selectedUsageType = K8S_QUANTITY_TYPE_SELECT,
    selectedUsageTypeMetric = null,
    excludeFilterParams,
    likeFilterParams,
    isNetUnblended = false,
    isShowAmortize = false,
    isNetAmortize = false,
    isListUnitPrice = false,
    isNegotiatedSavings = false,
    panelId,
    dashboardId,
    wasteCostAllocationChecked,
    k8SGranularity,
    filtersConfigParams = '',
  ) => {
    const arrCostUsageType = costUsageType.map((ct) => `&costUsageType=${ct}`).join('');
    const arrUsageType = Object.values(K8S_USAGE_TYPES).includes(selectedUsageType)
      ? `&usageType=${selectedUsageType}`
      : '';
    const arrMetricType = this.getK8sMetricTypesByUsage(selectedUsageType)
      .map((mt) => `&metricType=${mt}`)
      .join('');
    const secondaryGroupByParam = secondaryGroupBy ? `&groupBy=${secondaryGroupBy}` : '';
    const showAmortize = isShowAmortize ? '&isAmortized=true' : '';
    const netAmortize = isNetAmortize ? '&isNetAmortized=true' : '';
    const netUnblended = isNetUnblended ? '&isNetUnblended=true' : '';
    const listUnitPriceCost = isListUnitPrice ? '&isListUnitPrice=true' : '';
    const negotiatedSavings = isNegotiatedSavings ? '&isNegotiatedSavings=true' : '';
    const panelIdQuery = panelId ? `&panelId=${panelId}` : '';
    const dashboardIdQuery = dashboardId ? `&dashboardId=${dashboardId}` : '';
    const wasteCostQuery = wasteCostAllocationChecked ? `&isWasteCostAllocation=${wasteCostAllocationChecked}` : '';
    const k8SGranularityQuery = k8SGranularity ? `&k8SGranularity=${k8SGranularity}` : '';
    let apiQuery = `/api/v1/kubernetes/caui?groupBy=${sanitizeParam(
      groupBy,
    )}${secondaryGroupByParam}${whereParams}&startDate=${startDate}&endDate=${endDate}&periodGranLevel=${periodGranLevel}${arrCostUsageType}${filterParams}${
      excludeFilterParams || ''
    }${
      likeFilterParams || ''
    }${filtersConfigParams}${arrUsageType}${arrMetricType}${listUnitPriceCost}${negotiatedSavings}${showAmortize}${netAmortize}${netUnblended}${panelIdQuery}${dashboardIdQuery}${wasteCostQuery}${k8SGranularityQuery}`;
    apiQuery = sanitizeQuery(apiQuery);
    return API.get('billings', apiQuery);
  };
  getK8sPodData = async (
    startDate,
    endDate,
    clusterName = null,
    nodeName = null,
    podName = null,
    isNetUnblended = false,
    isShowAmortize = false,
    isNetAmortize = false,
    isListUnitPrice = false,
    isNegotiatedSavings = false,
  ) => {
    const showAmortize = isShowAmortize ? '&isAmortized=true' : '';
    const netAmortize = isNetAmortize ? '&isNetAmortized=true' : '';
    const netUnblended = isNetUnblended ? '&isNetUnblended=true' : '';
    const listUnitPriceCost = isListUnitPrice ? '&isListUnitPrice=true' : '';
    const negotiatedSavings = isNegotiatedSavings ? '&isNegotiatedSavings=true' : '';
    const apiQuery = `/api/v1/kubernetes/cost-and-usage/pod?startDate=${startDate}&endDate=${endDate}&clusterName=${clusterName}&nodeName=${nodeName}&podName=${podName}${listUnitPriceCost}${negotiatedSavings}${showAmortize}${netAmortize}${netUnblended}`;
    const result = await API.get('billings', apiQuery);
    return result;
  };
}

export default KubernetesApi;
