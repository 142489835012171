import React, { useEffect, useState } from 'react';
import useTable from 'shared/hooks/customHooks/useTable';
import useRoles from 'users/new-user-management/hooks/reactQuery/useRoles';
import { RolesContext } from './contexts/rolesContext';
import RolesHeader from './RolesHeader';
import RolesTable from './RolesTable';
import { GET_ROLES_QUERY_PARAMS } from '../consts';
import usePagination from 'users/hooks/usePagination';

import styles from './Roles.module.scss';

const Roles = () => {
  const [totalRoles, setTotalRoles] = useState(0);
  const [search, setSearch] = useState();
  const [paginationToken, setPaginationToken] = useState(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const [isAllExpanded, setIsAllExpanded] = useState(false);
  const [excludeAnodotRoles, setExcludeAnodotRoles] = useState(true);

  const { NewTableWrapper } = useTable();

  const { fetchRoles } = useRoles();
  const { data, isLoading, isFetching } = fetchRoles({
    [GET_ROLES_QUERY_PARAMS.PAGINATION_TOKEN.name]: paginationToken,
    [GET_ROLES_QUERY_PARAMS.SEARCH.name]: search,
    [GET_ROLES_QUERY_PARAMS.EXCLUDE_ANODOT_ROLES.name]: excludeAnodotRoles,
    [GET_ROLES_QUERY_PARAMS.PAGE_SIZE.name]: GET_ROLES_QUERY_PARAMS.PAGE_SIZE.defaultValue,
  });

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const searchParam = searchParams.get(GET_ROLES_QUERY_PARAMS.SEARCH.name);
    if (searchParam) {
      setSearch(searchParam);
    }
  }, []);

  const handlePageChange = (newPaginationToken) => {
    setPaginationToken(newPaginationToken);
  };

  const { currentPage, pageChanged } = usePagination({
    data,
    onPageChange: handlePageChange,
  });

  useEffect(() => {
    if (data?.total) {
      setTotalRoles(data.total);
    }
  }, [data?.total]);

  return (
    <div className={styles.roles}>
      <RolesContext.Provider
        value={{
          isAllExpanded,
          excludeAnodotRoles,
          paginationToken,
          search,
          selectedRows,
          setIsAllExpanded,
          setExcludeAnodotRoles,
          setSearch,
          setSelectedRows,
          setPaginationToken,
          totalRoles,
        }}
      >
        <NewTableWrapper>
          <RolesHeader
            pageChanged={pageChanged}
            currentPage={currentPage}
            isNextPageLoading={isLoading || isFetching}
          />
          <RolesTable isLoading={isLoading} roles={data?.roles || []} />
        </NewTableWrapper>
      </RolesContext.Provider>
    </div>
  );
};

export default Roles;
