import React from 'react';
import PropTypes from 'prop-types';
import Spinner from 'shared/components/andtComponents/Spinner';
import useUsers from 'users/new-user-management/hooks/reactQuery/useUsers';
import EffectivePermissions from '../../components/EffectivePermissions/EffectivePermissions';

const UserEffectivePermissionsTab = ({ row }) => {
  const { fetchUser } = useUsers();
  const { data, isLoading } = fetchUser(row?.id);

  return (
    <>
      {isLoading ? (
        <div className="position-relative">
          <Spinner />
        </div>
      ) : (
        <EffectivePermissions
          permissions={data?.permissions?.permissions}
          permissionChanged={() => {}}
          userId={row?.id}
        />
      )}
    </>
  );
};

UserEffectivePermissionsTab.propTypes = {
  row: PropTypes.shape({
    id: PropTypes.string,
  }),
};

export default UserEffectivePermissionsTab;
