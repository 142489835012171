import { cloneDeep, uniqBy } from 'lodash';

export const getChildRows = (row, rootRows) => {
  const childRows = rootRows.filter((r) => r.parentId === (row ? row.id : null));
  return childRows.length ? childRows : null;
};

export const findFilteredData = (data, search, parentId) => {
  const children = data.filter((cc) => cc.parentId === parentId);
  if (children.length === 0) {
    return [];
  } else {
    return children.reduce((acc, cc) => {
      const filteredChildren = findFilteredData(data, search, cc.id);
      if (cc.name.toLowerCase().includes(search.toLowerCase()) || filteredChildren.length > 0) {
        return [...acc, cc, ...filteredChildren];
      }
      return acc;
    }, []);
  }
};

export const getCostCenterAccountsData = (accountsData, costCenterAccountsData, isCreateEditMode) => {
  const combinedCostCenterAccountsData = [];
  costCenterAccountsData?.fullyAssignedPayerAccountIDs.forEach((paId) => {
    const payerAccount = accountsData.find((account) => account.payerAccount.id === paId);
    if (payerAccount) {
      const laCount = payerAccount.linkedAccounts.length;
      const tmpPayerAccount = {
        ...payerAccount,
        payerAccount: {
          ...payerAccount.payerAccount,
          isSelectedInitial: !!isCreateEditMode,
          isSelectionEnabled: true,
          isAssignedByCurrentEntity: true,
        },
      };
      payerAccount.linkedAccounts.forEach((la) => {
        la.isPayerFullyAssigned = true;
      });
      combinedCostCenterAccountsData.push({
        ...tmpPayerAccount,
        totalPayerLinkedAccounts: laCount,
        totalSelectedPayerLinkedAccounts: laCount,
      });
    }
  });

  costCenterAccountsData?.payerWithLinkedAccounts?.forEach(({ payerAccountId, linkedAccountIds }) => {
    const payerAccount = accountsData.find((account) => account.payerAccount.id === payerAccountId);
    if (payerAccount) {
      const selectedLinkedAccounts = payerAccount.linkedAccounts
        .filter((la) => linkedAccountIds.includes(la.id))
        .map((la) => ({
          ...la,
          isSelectedInitial: !!isCreateEditMode,
          isSelectionEnabled: true,
          isAssignedByCurrentEntity: true,
        }));
      const tmpPayerAccount = {
        payerAccount: {
          ...payerAccount.payerAccount,
          isSelectionEnabled: payerAccount?.payerAccount?.isFullyAssignable ?? true,
        },
        linkedAccounts: selectedLinkedAccounts,
        totalPayerLinkedAccounts: payerAccount.linkedAccounts.length,
        totalSelectedPayerLinkedAccounts: selectedLinkedAccounts.length,
      };
      combinedCostCenterAccountsData.push(tmpPayerAccount);
    }
  });

  if (isCreateEditMode) {
    accountsData.forEach((account) => {
      const selectedPayerAccount = combinedCostCenterAccountsData.find(
        (cc) => cc.payerAccount.id === account.payerAccount.id,
      );
      if (!selectedPayerAccount) {
        const tmpPayerAccount = {
          ...account,
          payerAccount: {
            ...account.payerAccount,
            isSelectedInitial: false,
            isSelectionEnabled: account?.payerAccount?.isFullyAssignable ?? true,
            isAssignedByCurrentEntity: false,
          },
        };
        combinedCostCenterAccountsData.push({
          ...tmpPayerAccount,
          totalPayerLinkedAccounts: account.linkedAccounts?.length,
          totalSelectedPayerLinkedAccounts: 0,
        });
      } else {
        selectedPayerAccount.linkedAccounts = [
          ...(account.linkedAccounts.filter(
            (la) => !selectedPayerAccount.linkedAccounts.find((sla) => sla.id === la.id),
          ) || []),
          ...selectedPayerAccount.linkedAccounts,
        ];
      }
    });
  }
  return combinedCostCenterAccountsData;
};

function enrichCostCenters(
  costCentersData,
  costCenterDataAccess,
  modifiedSelectionById,
  currentRoleId,
  isViewDataAccessOnly,
) {
  const copyOfCostCentersData = cloneDeep(costCentersData);
  costCenterDataAccess.forEach((roleDataAccess) => {
    let assignedCostCenterIds = roleDataAccess?.costCenters?.items;
    const modifiedSelectionIds = modifiedSelectionById ? Object.keys(modifiedSelectionById) : [];
    if (modifiedSelectionIds.length) {
      const newSelectedIds = modifiedSelectionIds.filter((key) => modifiedSelectionById[key] === true);
      assignedCostCenterIds = [...(roleDataAccess?.costCenters?.items || []), ...newSelectedIds];
    }
    assignedCostCenterIds?.forEach((assignedCostCenterId) => {
      if (modifiedSelectionById) {
        if (modifiedSelectionById[assignedCostCenterId] === false) {
          return;
        }
      }
      const costCenter = copyOfCostCentersData.find((cc) => cc.costCenter.id === assignedCostCenterId);
      if (costCenter) {
        if (isViewDataAccessOnly) {
          // Here we go through the first level of role data access, so in order to show this part only - setting parent to null.
          costCenter.costCenter.parentCcId = null;
        }
        costCenter.costCenter.isAllowed = true;
        costCenter.costCenter.isDirectlyAssigned =
          costCenter.costCenter.isDirectlyAssigned ||
          modifiedSelectionById?.[assignedCostCenterId] === true ||
          currentRoleId === roleDataAccess.derivedFromRole.id;
        if (!costCenter.costCenter.assignedByRoles) {
          costCenter.costCenter.assignedByRoles = [];
        }
        costCenter.costCenter.assignedByRoles =
          costCenter.costCenter.isDirectlyAssigned === true
            ? []
            : [...costCenter.costCenter.assignedByRoles, roleDataAccess.derivedFromRole];
      }
    });
  });
  return uniqBy(copyOfCostCentersData, 'costCenter.id');
}

function mutateAddEffectiveIsAllowed(parentCostCenters) {
  parentCostCenters.forEach((parentCostCenter) => {
    const parent = parentCostCenter.costCenter;
    const children = parentCostCenters.filter((cc) => cc.costCenter.parentCcId === parent.id);

    if (parent.isAllowed || parent.effectiveIsAllowed) {
      for (const child of children) {
        child.costCenter.effectiveIsAllowed = true;
      }
    }

    if (children.length > 0) {
      mutateAddEffectiveIsAllowed(children);
    }
  });
}

export const getCostCentersDataAccessCombinedData = (
  costCentersData,
  costCenterDataAccess,
  currentRoleId,
  modifiedSelectionById,
  isViewDataAccessOnly,
) => {
  if (costCenterDataAccess.find((roleDataAccess) => roleDataAccess.costCenters.all === true)) {
    return costCentersData;
  }
  const enrichedCostCenters = enrichCostCenters(
    costCentersData,
    costCenterDataAccess,
    modifiedSelectionById,
    currentRoleId,
    isViewDataAccessOnly,
  );
  mutateAddEffectiveIsAllowed(enrichedCostCenters);

  return enrichedCostCenters;
};

export const mergeCostCentersDataWithDataAccess = (costCentersData, roleDataAccessCostCentersData) => {
  const subGroupIds = new Set(roleDataAccessCostCentersData.map((cc) => cc.costCenter.id));
  const remainingOriginals = costCentersData.filter((cc) => !subGroupIds.has(cc.costCenter.id));

  return [...roleDataAccessCostCentersData, ...remainingOriginals];
};

export const getAssignableAccounts = (accountsData, unassignedAccountsData, costCenterAccounts) => {
  const unassignedAccounts = [];
  let unassignedPayerAccount;
  accountsData.forEach((account) => {
    const unassignedAccount = unassignedAccountsData.find(
      (ua) => ua?.payerWLinked.payerAccountId === account.payerAccount.id,
    );
    // If unassigned payer account, received from unassigned api
    if (unassignedAccount) {
      const payerUnassignedLinkedAccounts = account.linkedAccounts.filter((la) =>
        unassignedAccount.payerWLinked?.linkedAccountIds.includes(la.id),
      );
      unassignedPayerAccount = {
        payerAccount: { ...account.payerAccount, isFullyAssignable: unassignedAccount.isFullyAssignable },
        linkedAccounts: payerUnassignedLinkedAccounts,
      };
      unassignedAccounts.push(unassignedPayerAccount);
    }
    // If the account is fully assigned to this cost center, also need to display it in the list
    const fullyAssignedInCurrentCostCenter = costCenterAccounts?.fullyAssignedPayerAccountIDs?.find(
      (paId) => paId === account.payerAccount.id,
    );
    if (fullyAssignedInCurrentCostCenter) {
      unassignedAccounts.push(account);
    } else {
      // In case part of the linked accounts are assigned to this cost center
      const payerWithLinkedAccounts = costCenterAccounts?.payerWithLinkedAccounts?.find(
        (ca) => ca.payerAccountId === account.payerAccount.id,
      );
      if (payerWithLinkedAccounts) {
        const existingAccountIndex = unassignedAccounts.findIndex(
          (ua) => ua.payerAccount.id === account.payerAccount.id,
        );

        if (existingAccountIndex !== -1) {
          // Filter new linked accounts directly
          const newLinkedAccounts = account.linkedAccounts?.filter(
            (la) =>
              payerWithLinkedAccounts.linkedAccountIds.includes(la.id) ||
              (unassignedAccount && unassignedAccount.payerWLinked.linkedAccountIds.includes(la.id)),
          );

          // Update the existing entry instead of pushing a new one
          unassignedAccounts[existingAccountIndex] = {
            payerAccount: {
              ...account.payerAccount,
              isFullyAssignable: unassignedAccount ? unassignedAccount.isFullyAssignable : false,
            },
            linkedAccounts: newLinkedAccounts,
          };
        } else if (unassignedPayerAccount) {
          // If no existing entry, push the modified account
          unassignedAccounts.push(unassignedPayerAccount);
        }
      }
    }
  });
  return unassignedAccounts;
};
