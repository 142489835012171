import React from 'react';
import PropTypes from 'prop-types';

const UserAuthorizationDisplayWrapper = ({ children, viewerUserType, authorizedUsersTypeList, isHide = true }) => {
  const disabledStyle = { pointerEvents: 'none', opacity: '0.4' };
  const hideStyle = { display: 'none' };

  const checkIfUserOnAuthorizedList = (authorizedUsersTypeList, viewerUserType) => {
    let isOnList = false;
    isOnList = authorizedUsersTypeList.some((ut) => ut === viewerUserType);
    return isOnList;
  };

  const getStyle = () => {
    let style = {};
    let isAuthorized = false;
    try {
      isAuthorized = checkIfUserOnAuthorizedList(authorizedUsersTypeList, viewerUserType);
    } catch {
      isAuthorized = false;
    }
    if (!isAuthorized) {
      style = isHide ? hideStyle : disabledStyle;
    }
    return style;
  };

  return <div style={getStyle()}>{children}</div>;
};

UserAuthorizationDisplayWrapper.propTypes = {
  children: PropTypes.object.isRequired,
  viewerUserType: PropTypes.number.isRequired,
  authorizedUsersTypeList: PropTypes.array.isRequired,
  isHide: PropTypes.bool,
};

export default UserAuthorizationDisplayWrapper;
