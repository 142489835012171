import React from 'react';
import PropTypes from 'prop-types';
import { Row } from 'reactstrap';
import FlexibilityTable from 'shared/components/tables/DevExpSearchAndVisibilityTable';
import CustomModal from 'shared/components/andtComponents/Modal';
import Spinner from 'shared/components/andtComponents/Spinner';
import { useFlexibilityMargin } from '../../hooks/useCustomers';
import styles from './explainFlexibilityModal.module.scss';

const ExplainFlexibilityModal = (props) => {
  const {
    toggle,
    type,
    tableColumns,
    columnWidths,
    costColumnsArray,
    currenciesColumns,
    startDate,
    endDate,
    accountKey,
    divisionId,
    divisionName,
  } = props;
  const { getFlexibilityMarginData } = useFlexibilityMargin();
  const { data: tableData, isLoading } = getFlexibilityMarginData({
    divisionId,
    divisionName,
    accountKey,
    flexibilityType: type,
    startDate,
    endDate,
  });
  const getModalTitles = (type) => {
    let modalTitle = '';
    if (type === 'ri') {
      modalTitle = 'Resources using RI';
    }
    if (type === 'sp') {
      modalTitle = 'Resources using SP';
    }
    if (type === 'pp') {
      modalTitle = 'Public Price Margins per Service';
    }
    if (type === 'credit') {
      modalTitle = 'Credit usage history';
    }
    return { modalTitle };
  };

  return (
    <CustomModal
      overrideStyles={{ width: 800 }}
      open
      onClose={toggle}
      className={{ content: styles.modal }}
      title={getModalTitles(type).modalTitle}
      footerDisabled
    >
      <Row>
        {isLoading ? (
          <Spinner />
        ) : (
          <FlexibilityTable
            data={tableData}
            columns={tableColumns}
            costColumnsArray={costColumnsArray}
            currenciesColumns={currenciesColumns}
            csvTitle={`explain_flexibility_${type}`}
            columnWidths={columnWidths}
            numCostDecimal={2}
            isResize
            isPaging
          />
        )}
      </Row>
    </CustomModal>
  );
};

ExplainFlexibilityModal.propTypes = {
  toggle: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  tableColumns: PropTypes.object.isRequired,
  columnWidths: PropTypes.object.isRequired,
  currenciesColumns: PropTypes.object.isRequired,
  costColumnsArray: PropTypes.object.isRequired,
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
  accountKey: PropTypes.string.isRequired,
  divisionId: PropTypes.string.isRequired,
  divisionName: PropTypes.string.isRequired,
};

export default ExplainFlexibilityModal;
