import React from 'react';
import PropTypes from 'prop-types';
import styles from './RadioButton.module.scss';

const RadioButton = ({
  checked,
  label,
  onClick,
  isDisabled = false,
  large = false,
  primary = false,
  className = null,
  automationId,
}) => {
  const selectedClassName = primary ? styles.selectedPrimary : styles.selected;

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
    (<div
      className={[styles.container, isDisabled ? styles.disabled : ''].join(' ')}
      onClick={onClick}
      automation-id={automationId}
    >
      <div className={[styles.ico, 'icon', checked ? selectedClassName : ''].join(' ')} />
      <div
        data-label="true"
        className={`${styles.label} ${checked ? styles.selectedLabel : ''}
       ${large ? styles.large : ''} ${className || ''}`}
      >
        {label}
      </div>
    </div>)
  );
};

RadioButton.propTypes = {
  checked: PropTypes.bool.isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  onClick: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool,
  large: PropTypes.bool,
  primary: PropTypes.bool,
  className: PropTypes.object,
};

export default RadioButton;
