import React, {useState} from 'react';
import moment from 'moment';
import {GenerateIcon, ICONS} from '@pileus-cloud/anodot-frontend-common';
import Tooltip from 'shared/components/andtComponents/Tooltip';
import { ReactComponent as HideFilter } from 'recommendationsNew/img/reset-filter.svg';
import { useRootStore } from 'app/contexts/RootStoreContext.jsx';
import DateFilter from 'shared/modules/dateFilter.js';
import MainDashboardFilters from 'app/containers/MainDashboard/components/MainDashboardFilters.jsx';
import styles from './mainDashboardHeader.module.scss';
import tooltipStyles from 'shared/components/andtComponents/Tooltip.module.scss';

const MainDashboardHeader = () => {
    const { usersStore } = useRootStore();
    const [showFilters, setShowFilters] = useState(false);
    const formattedDate = moment(DateFilter.getDate()).format('MMM D, YYYY');

    return (
      <div className={styles.headerWrapper}>
          <div>
            <span className={styles.name}>Welcome {usersStore.getCurrUserName}!</span>
            <span className={styles.date}> Updated: {formattedDate}</span>
          </div>
          <div className={styles.flex}>
              {showFilters && <MainDashboardFilters/>}
              <Tooltip placement="top" title={<span className={styles.tooltip}>{showFilters ? 'Hide Filters' : 'Shows Filters'}</span>}
                       arrow
                       classes={{ tooltip: tooltipStyles.whiteTooltip, arrow: tooltipStyles.whiteArrow }}>
              <span  onClick={() => setShowFilters(!showFilters)}
                     onKeyDown={(event) => {
                         if (event.key === 'Enter' || event.key === ' ') {
                             setShowFilters(!showFilters);
                         }
                     }}
                     tabIndex={0}
                     role="button"
                     className={styles.filterIconWrapper}>
                 {showFilters ? <HideFilter className={styles.icon} /> : <GenerateIcon iconName={ICONS.barsFilter.name} className={styles.icon}  />}
              </span>
              </Tooltip>
          </div>
      </div>);
};

export default MainDashboardHeader;
