import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Input from 'shared/components/andtComponents/Input';
import Button from 'shared/components/andtComponents/Button';
import LoginPhases from '../loginPhases';
import styles from '../Login.module.scss';

const LogIn = ({
  handleForgotPassword,
  handleSubmit,
  email,
  handleEmailChange,
  password,
  handlePasswordChange,
  logPhase,
  isEmailValid,
}) => {
  const passwordInputRef = useRef(null);
  const [isShowEmailAsInvalid, setShowEmailAsInvalid] = useState(false);

  const usePreviousValue = (value) => {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  };

  const previousLogPhase = usePreviousValue(logPhase);

  useEffect(() => {
    if (previousLogPhase === LoginPhases.LOG_PHASE_EMAIL && logPhase === LoginPhases.LOG_PHASE_LOGIN) {
      passwordInputRef.current.focus();
    }
  }, [logPhase, passwordInputRef]);

  useEffect(() => {
    if (isEmailValid) {
      setShowEmailAsInvalid(false);
    }
  }, [isEmailValid]);

  const handleKeyPress = (target) => {
    if (target.charCode === 13) {
      handleSubmit();
    }
  };

  const handleEmailBlur = () => {
    setShowEmailAsInvalid(!isEmailValid);
  };

  const emailSection = (
    <div className={styles.emailSection}>
      <div className={styles.label}>Email</div>
      <Input
        onBlur={handleEmailBlur}
        value={email}
        onKeyPress={handleKeyPress}
        onChange={handleEmailChange}
        type="email"
        placeholder="sam@company.com"
        name="email"
        autoFocus
        isInvalid={isShowEmailAsInvalid}
        invalidComponent={<span className={styles.invalid}>A valid email is required.</span>}
        automationId="login-email"
      />
    </div>
  );

  const passwordSection = (
    <div className={styles.passwordSection}>
      <div className={styles.labelSection}>
        <div className={styles.label}>Password</div>
        <Button onClick={handleForgotPassword} text="Forgot password" type="button" isTextButton />
      </div>
      <Input
        ref={passwordInputRef}
        name="password"
        type="password"
        value={password}
        // onKeyPress={handleSubmit}
        onChange={handlePasswordChange}
        onKeyPress={handleKeyPress}
        placeholder="8 Characters minimum"
        automationId="login-password"
      />
    </div>
  );

  const hidePassword = logPhase === LoginPhases.LOG_PHASE_EMAIL;

  // R.V - show password option is not available to the client
  return (
    <form className={styles.loginFormSection} onSubmit={handleSubmit}>
      <div className="">
        {emailSection}
        {hidePassword ? null : passwordSection}
      </div>
    </form>
  );
};

LogIn.propTypes = {
  handleForgotPassword: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  email: PropTypes.string.isRequired,
  handleEmailChange: PropTypes.func.isRequired,
  password: PropTypes.string,
  handlePasswordChange: PropTypes.func.isRequired,
  logPhase: PropTypes.string.isRequired,
  isEmailValid: PropTypes.bool.isRequired,
};

export default LogIn;
