import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { ChevronDown } from 'react-feather';
import { Popover, PopoverContent, PopoverTrigger } from '@pileus-cloud/anodot-frontend-common';
import { observer } from 'mobx-react';
import { Routes } from 'shared/constants/routes';
import Tooltip from 'shared/components/andtComponents/Tooltip';
import TopBarSearch from 'shared/components/TopBarSearch';
import LabelCoordinator from 'shared/modules/labelCoordinator';
import MenuItem from 'app/containers/Layout/topNavbar/components/MenuItem';
import styles from 'app/containers/Layout/topNavbar/TopNavBar.module.scss';
import ChevronLeftIcon from 'mdi-react/ChevronLeftIcon';
import { useNavigate } from 'react-router-dom';

const customStyles = {
  inputStyle: {
    backgroundColor: '#E9F0FF',
    height: '45px',
    border: 'none',
    color: '#8995A0',
    fontWeight: '400',
    borderRadius: '0',
    borderBottom: '1px solid #d0dfff',
    margin: '0px',
    width: '250px',
    padding: '5px 0px 0px 30px',
  },
  iconStyle: { top: '13px', left: '5px' },
};

const ScopePicker = ({
  classes,
  currentDisplayedCustomer,
  currentDisplayedCloudTypeId,
  handleBackToCurrDisplayedAccount,
  divisionsList,
  handleChangeCustomer,
  currAccountDivisionId,
  currDispUserDivisionId,
  isAccountLoading = false,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchValue, setSearchValue] = useState('');

  const [filteredValues, setFilteredValues] = useState([]);
  const navigate = useNavigate();

  const toggle = () => {
    setIsOpen((prev) => !prev);
  };

  const backToMainScope = () => {
    toggle();
    handleBackToCurrDisplayedAccount();
    navigate(Routes.DASHBOARD);
  };

  const sortSearchValues = (values) => {
    if (values) {
      return values.sort((a, b) => {
        const getDivisionName = (d) => d.divisionNameDisplay || d.divisionName;
        return getDivisionName(a).toLowerCase().localeCompare(getDivisionName(b).toLowerCase());
      });
    }
    return [];
  };

  useEffect(() => {
    const filteredList =
      divisionsList && divisionsList.length
        ? divisionsList.filter((val) => {
            const fullString = val.divisionName.toLowerCase();
            const updatedName = (val.divisionNameDisplay || '').toLowerCase();
            const displayName = LabelCoordinator.getDataKeyDisplayName('cueDisplayCoordinator', val.divisionName);
            const subString = searchValue.toLowerCase();
            return (
              subString.length === 0 ||
              fullString.includes(subString) ||
              updatedName.includes(subString) ||
              displayName.toLowerCase().includes(subString)
            );
          })
        : [];
    const sortedList = sortSearchValues(filteredList);
    setFilteredValues(sortedList);
  }, [searchValue, divisionsList]);
  const handleChangeDivisionClicked = (division) => {
    toggle();
    handleChangeCustomer(
      division.divisionId,
      division.divisionNameDisplay || division.divisionName,
      division.accountKey,
    );
  };

  const renderScopePicker = () => {
    const displayName = LabelCoordinator.getDataKeyDisplayName('cueDisplayCoordinator', currentDisplayedCustomer);
    const overrideClass = { ...classes };
    overrideClass.item = classes.customItem;
    return (
      <Popover open={isOpen} onOpenChange={toggle}>
        <PopoverTrigger>
          <Tooltip title={`Explore a specific ${LabelCoordinator.getFieldLabel('Cost Center').toLowerCase()}`}>
            <div className={classes.topPickerEnv}>
              <span>Scope:</span>
              <span>{currAccountDivisionId === currDispUserDivisionId ? 'Main Account' : displayName}</span>
              <ChevronDown size={16} />
            </div>
          </Tooltip>
        </PopoverTrigger>
        <div className={classes.popoverWrapper}>
          <PopoverContent className={classes.noPadding}>
            {currAccountDivisionId !== currDispUserDivisionId && !isAccountLoading ? (
              <div className={classes.itemWrap}>
                <MenuItem
                  classes={overrideClass}
                  handler={backToMainScope}
                  item={{
                    key: 'main-account-div',
                    value: 'Back to Main Account',
                    cloudId: currentDisplayedCloudTypeId,
                  }}
                  id="back_to_main_account"
                >
                  <span>
                    <ChevronLeftIcon className="pagination__link-icon" />
                    Back to Main Account
                  </span>
                </MenuItem>
              </div>
            ) : null}
            <div>
              {divisionsList && divisionsList.length ? (
                <div className={`${classes.itemWrap} ${classes.marginBottom}`}>
                  <TopBarSearch
                    handler={(e) => {
                      setSearchValue(e.target.value);
                    }}
                    inputStyle={customStyles.inputStyle}
                    iconStyle={customStyles.iconStyle}
                    id="topbarScopeSearch"
                    placeholder="Search scope"
                  />
                </div>
              ) : null}
              {filteredValues && filteredValues.length ? (
                <div className={`${styles.listWrapper} show-scroll`}>
                  {filteredValues.map((division, index) => (
                    <div
                      className={classes.itemWrap}
                      key={`${division.divisionNameDisplay}scopePicker-${index}`}
                      style={isAccountLoading ? { pointerEvents: 'none', opacity: '0.4' } : {}}
                    >
                      <MenuItem
                        activeName={displayName}
                        classes={classes}
                        handler={() => handleChangeDivisionClicked(division)}
                        item={{
                          value: LabelCoordinator.getDataKeyDisplayName(
                            'cueDisplayCoordinator',
                            division.divisionNameDisplay || division.divisionName,
                          ),
                        }}
                        id={`${division.accountKey}_scopePicker`}
                      />
                    </div>
                  ))}
                </div>
              ) : null}
            </div>
          </PopoverContent>
        </div>
      </Popover>
    );
  };

  return renderScopePicker();
};

ScopePicker.propTypes = {
  classes: PropTypes.object.isRequired,
  currentDisplayedCustomer: PropTypes.string.isRequired,
  currentDisplayedCloudTypeId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  handleBackToCurrDisplayedAccount: PropTypes.func.isRequired,
  accountsList: PropTypes.array.isRequired,
  divisionsList: PropTypes.array.isRequired,
  handleChangeCustomer: PropTypes.func.isRequired,
  currAccountDivisionId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  currDispUserDivisionId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  isAccountLoading: PropTypes.bool,
};

const ObserverScopePicker = observer(ScopePicker);
export default ObserverScopePicker;
