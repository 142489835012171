import React, { useContext } from 'react';
import { useSubTableState } from 'shared/hooks/customHooks/useSubTableState';

const UsersTableContext = React.createContext();
export function withUsersTableContextProvider(Component) {
  return ({ ...props }) => {
    const tableState = useSubTableState();
    return (
      // eslint-disable-next-line react/jsx-filename-extension
      (<UsersTableContext.Provider
        value={{
          usersTable: tableState,
        }}
      >
        <Component {...props} />
      </UsersTableContext.Provider>)
    );
  };
}
export const useUsersTableState = (roleId) => {
  const {
    usersTable: { getPage, setPage, getSelectedRows, setSelectedRows, anySelected },
  } = useContext(UsersTableContext);
  return {
    page: getPage(roleId),
    setPage: (newPage) => setPage(roleId, newPage),
    selectedRows: getSelectedRows(roleId),
    setSelectedRows: (rows) => setSelectedRows(roleId, rows),
    anySelected,
  };
};

const LinkedAccountsTableContext = React.createContext();
export function withLinkedAccountsTableContextProvider(Component) {
  return ({ ...props }) => {
    const tableState = useSubTableState();
    return (
      // eslint-disable-next-line react/jsx-filename-extension
      (<LinkedAccountsTableContext.Provider
        value={{
          linkedAccountsTable: tableState,
        }}
      >
        <Component {...props} />
      </LinkedAccountsTableContext.Provider>)
    );
  };
}
export const useLinkedAccountsTableState = (roleId) => {
  const {
    linkedAccountsTable: {
      getPage,
      setPage,
      anySelected,
      getSelectedRows,
      setSelectedRows,
    },
  } = useContext(LinkedAccountsTableContext);
  return {
    page: getPage(roleId),
    setPage: (newPage) => setPage(roleId, newPage),
    anySelected,
    selectedRows: getSelectedRows(roleId),
    setSelectedRows: (rows) => setSelectedRows(roleId, rows),
  };
};
