import { FilterTypes } from 'usage/constants/usageConstants';
import {RECOMMENDATION_PLAN_PROPERTIES} from "commitment/containers/spAnalyzerNew/utils/consts.js";
export const prepareSPAnalyzerFilters = (selectedOptions, filterStatusType) => {
  let includeFilterParams = '';
  let excludeFilterParams = '';
  Object.entries(selectedOptions || {}).forEach(([filter, filterValues]) => {
    if (filterValues && filterValues.length) {
      filterValues.forEach((filterValue) => {
        if (filterStatusType[filter] === FilterTypes.INCLUDE) {
          includeFilterParams = includeFilterParams.concat(`&includeFilters[${filter}]=${filterValue.value}`);
        } else if (filterStatusType[filter] === FilterTypes.EXCLUDE) {
          excludeFilterParams = excludeFilterParams.concat(`&excludeFilters[${filter}]=${filterValue.value}`);
        }
      });
    }
  });

  return { includeFilterParams, excludeFilterParams };
};

export const getPropertyValue = (prop, shareProperties, plan, getCurrencyNumber) => {
  const value = prop.isShareProperty ? shareProperties?.[prop.key] : plan?.[prop.key];
  return prop.isCurrency ? getCurrencyNumber(Number(value)) : value;
};

export const calculateFieldValue = (prop, shareProperties, plan, getCurrencyNumber) => {
  const calculatedFields = prop.calculatedFields.map((field) => {
    const prop = RECOMMENDATION_PLAN_PROPERTIES.find((prop) => prop.key === field);
    if(!prop) {
      return  field.isShareProperty ? shareProperties?.[field.key] : plan?.[field.key]
    }
    return prop.isShareProperty ? shareProperties?.[prop.key] : plan?.[prop.key];
  });
  return getCurrencyNumber(calculatedFields.reduce((a, b) => Number(a) - Number(b)));
};

export const getOnDemandChartData = (recPlanData, recPerPlanData, isSimulateData) => {
  const currentSpChartDaily = recPlanData?.currentSpCoverage?.daily;
  const onDemandChartDaily = recPlanData?.grossCosts?.daily;
  const recommendedSpChartDaily = recPerPlanData?.recommendedSpCoverage?.daily;
  const simulateSpChartDaily = recPlanData?.simulatedCurrentSpCoverage?.daily || {};

  const currentSpChartHourly = recPlanData?.currentSpCoverage?.hourly;
  const onDemandCharHourly = recPlanData?.grossCosts?.hourly;
  const recommendedSpChartHourly = recPerPlanData?.recommendedSpCoverage?.hourly;
  const simulateSpChartHourly = recPlanData?.simulatedCurrentSpCoverage?.hourly || {};


  const dailyData = Object.entries(onDemandChartDaily || {}).map(([date, onDemand], index) => ({
    date,
    onDemand,
    key: `Day ${index + 1}`,
    currentSP: isSimulateData ? simulateSpChartDaily?.[date] : currentSpChartDaily?.[date],
    recommendedSP: recommendedSpChartDaily[date],
  }));
  let dayIndex = 0;
  let previousDate = '';

  const HourlyData = Object.entries(onDemandCharHourly || {}).map(([date, onDemand]) => {
    const currentDate = date.split(' ')[0];
    if (currentDate !== previousDate) {
      previousDate = currentDate;
      dayIndex++;
    }
    return ({
      date,
      key: `Day ${dayIndex} - ${date.split(' ')[1]}`,
      onDemand,
      currentSP: isSimulateData ? simulateSpChartHourly[date] : currentSpChartHourly[date],
      recommendedSP: recommendedSpChartHourly[date],
    })
  });

  return {
    currentData: {
      daily: dailyData,
      hourly: HourlyData,
    },
    simulateData: {
      daily: dailyData.map((d) => (
          {date: d.key, onDemand: d.onDemand, currentSP: d.currentSP, recommendedSP: d.recommendedSP}
      )),// change the recommendedSP with new simulate data
      hourly: HourlyData.map((d) => ({
        date: d.key,
        onDemand: d.onDemand,
        currentSP: d.currentSP,
        recommendedSP: d.recommendedSP
      })),
    }

  }
}

export const getActualChartData =(recPlanData, recPerPlanData, isSimulateData) => {
  const hourlyCommitmentDaily = recPlanData?.currentCommitments?.daily;
  const netCostDataDaily = recPlanData?.currentNetCosts?.daily;
  const hourlyCommitmentHourly = recPlanData?.currentCommitments?.hourly;
  const netCostDataHourly = recPlanData?.currentNetCosts?.hourly;

  const simulateHourlyCommitmentDaily = recPlanData?.simulatedCurrentCommitments?.daily;
  const simulateNetCostDataDaily = recPlanData?.simulatedCurrentNetCosts?.daily;
  const simulateHourlyCommitmentHourly = recPlanData?.simulatedCurrentCommitments?.hourly;
  const simulateNetCostDataHourly = recPlanData?.simulatedCurrentNetCosts?.hourly;

  const recommHourlyCommitmentDaily = recPerPlanData?.recommendedCommitments?.daily;
  const recommNetCostDaily = recPerPlanData?.recommendedNetCosts?.daily;
  const recommHourlyCommitmentHourly = recPerPlanData?.recommendedCommitments?.hourly;
  const recommNetCostHourly = recPerPlanData?.recommendedNetCosts?.hourly;

  const unUsedCommitmentDaily = recPerPlanData?.unusedCommitments?.daily;
  const unUsedCommitmentHourly = recPerPlanData?.unusedCommitments?.hourly;

  const dailyData = Object.entries((isSimulateData ? simulateHourlyCommitmentDaily : hourlyCommitmentDaily )|| {}).map(([date, hourlyCommitment], index) => ({
    date,
    hourlyCommitment,
    key: `Day ${index+1}`,
    actualOnDemand: (isSimulateData ? simulateNetCostDataDaily[date] : netCostDataDaily[date]) - hourlyCommitment,
    netCost: isSimulateData ? simulateNetCostDataDaily[date] : netCostDataDaily[date],
    accHourlyCommitment: recommHourlyCommitmentDaily[date],
    actualAccOnDemand: recommNetCostDaily[date] - recommHourlyCommitmentDaily[date],
    accNetCost: recommNetCostDaily[date],
    unUsedCommitment: unUsedCommitmentDaily?.[date],
  }));
  let dayIndex = 0;
  let previousDate = '';
  const hourlyData = Object.entries((isSimulateData ? simulateHourlyCommitmentHourly : hourlyCommitmentHourly) || {})
      .map(([date, hourlyCommitment]) => {
        const currentDate = date.split(' ')[0];
        if (currentDate !== previousDate) {
          previousDate = currentDate;
          dayIndex++;
        }
        return({
          date,
          hourlyCommitment,
          key: `Day ${dayIndex} - ${date.split(' ')[1]}`,
          actualOnDemand: (isSimulateData ? simulateNetCostDataHourly[date] : netCostDataHourly[date]) - hourlyCommitment,
          netCost: isSimulateData ? simulateNetCostDataHourly[date] : netCostDataHourly[date],
          accHourlyCommitment: recommHourlyCommitmentHourly[date],
          accNetCost: recommNetCostHourly[date],
          actualAccOnDemand: recommNetCostHourly[date] - recommHourlyCommitmentHourly[date],
          recommendedOnDemandValue: (recommNetCostHourly[date] - recommHourlyCommitmentHourly[date]) > 0
              ? recommNetCostHourly[date] : recommHourlyCommitmentHourly[date] - unUsedCommitmentHourly?.[date],
          unUsedCommitment: unUsedCommitmentHourly?.[date],
        })
      });

  return {
    currentData: {
      daily: dailyData,
      hourly: hourlyData,
    },
    simulateData: {
      daily: dailyData.map((d) => ({
        ...d,
        date: d.key,
      })),
      hourly: hourlyData.map((d) => ({
        ...d,
        date: d.key,
      })),
    }
  }
}
