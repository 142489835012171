import { API } from 'shared/utils/apiMiddleware';
import { useQuery } from '@tanstack/react-query';
import apiConstants from 'shared/api/apiConstants';
import { useRootStore } from 'app/contexts/RootStoreContext';

const root = '/api/v1/users/accessibleCategories';

const fetchAccessibleCategoriesApi = () => API.get('billings', root);

export const usePermissionCategories = () => {
  const { usersStore, appStore } = useRootStore();
  const queryKey = [apiConstants.QUERY_KEYS.ACCESSIBLE_CATEGORIES, usersStore.currentDisplayedUserKey];
  return {
    fetchAccessibleCategories: () =>
      useQuery(queryKey, fetchAccessibleCategoriesApi, {
        enabled: !!usersStore.currentDisplayedUserKey && appStore.isKeyCloakManagement,
        staleTime: Infinity,
      }),
  };
};
