import React from 'react';
import { Tabs, TabsList, TabsTrigger, TabsContent, GenerateIcon, ICONS } from '@pileus-cloud/anodot-frontend-common';
import PropTypes from 'prop-types';
import styles from './Tabs.module.scss';

const tabTriggerCreator = (index, name, icon) => (
  <TabsTrigger value={index}>
    <div className={styles.triggerWrapper}>
      {icon && <GenerateIcon iconName={ICONS[icon].name} />}
      {name}
    </div>
  </TabsTrigger>
);

const tabContentCreator = (index, title, children, className) => {
  const elements = React.Children.toArray(children);
  return (
    <TabsContent value={index} className={className}>
      {title ? <div className={styles.title}>{title}</div> : null}
      <div>{React.cloneElement(elements[index])}</div>
    </TabsContent>
  );
};

const ColoredTabs = ({
  className,
  arrOfNavItems,
  defaultAccountIndex = 0,
  children,
  vertical = false,
  onTabChange = null,
  staticInfo,
}) => (
  <div>
    <Tabs
      defaultValue={defaultAccountIndex}
      orientation={vertical ? 'vertical' : 'horizontal'}
      onValueChange={(data) => onTabChange && onTabChange(data.value)}
    >
      <TabsList className={staticInfo && styles.tabWrapper}>
        <div className={vertical && styles.flexColumn}>
          {arrOfNavItems.map((navItem) => tabTriggerCreator(navItem.id, navItem.name, navItem.icon))}
        </div>
        {staticInfo}
      </TabsList>
      {arrOfNavItems.map((navItem) => tabContentCreator(navItem.id, navItem.title, children, className))}
    </Tabs>
  </div>
);

export default ColoredTabs;

ColoredTabs.propTypes = {
  arrOfNavItems: PropTypes.array.isRequired,
  children: PropTypes.object.isRequired,
  defaultAccountIndex: PropTypes.number,
  vertical: PropTypes.bool,
  onTabChange: PropTypes.func,
  className: PropTypes.string,
  staticInfo: PropTypes.object,
};
