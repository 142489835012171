import { API } from 'shared/utils/apiMiddleware';
import { BILLINGS_API_NAME, USER_MANAGEMENT_DATA_ACCESS_ACCOUNTS } from './helperFunctions';

export async function fetchDataAccessPayerAccounts() {
  return API.get(BILLINGS_API_NAME, `${USER_MANAGEMENT_DATA_ACCESS_ACCOUNTS}/payer-accounts`);
}

export async function fetchPayerLinkedAccounts(payerId) {
  return API.get(
    BILLINGS_API_NAME,
    `${USER_MANAGEMENT_DATA_ACCESS_ACCOUNTS}/payer-accounts/${payerId}/linked-accounts`,
  );
}

export async function fetchDataAccessCostCenters() {
  return API.get(BILLINGS_API_NAME, `${USER_MANAGEMENT_DATA_ACCESS_ACCOUNTS}/cost-centers`);
}

export async function fetchDataAccessCostCenterUnassignedAccounts(costCenterId) {
  if (costCenterId) {
    return API.get(
      BILLINGS_API_NAME,
      `${USER_MANAGEMENT_DATA_ACCESS_ACCOUNTS}/cost-centers/${costCenterId}/accounts/unassigned`,
    );
  }
  return API.get(BILLINGS_API_NAME, `${USER_MANAGEMENT_DATA_ACCESS_ACCOUNTS}/cost-centers/accounts/unassigned`);
}

export async function fetchDataAccessCostCenterAccounts(costCenterId) {
  return API.get(BILLINGS_API_NAME, `${USER_MANAGEMENT_DATA_ACCESS_ACCOUNTS}/cost-centers/${costCenterId}/accounts`);
}

export async function fetchDataAccessResellerCustomers() {
  return API.get(BILLINGS_API_NAME, `${USER_MANAGEMENT_DATA_ACCESS_ACCOUNTS}/reseller-customers`);
}

export async function createDataAccessCostCenter({ costCenter, accountsPayload }) {
  const payload = {
    body: { costCenter, accountsPayload },
  };
  return API.put(BILLINGS_API_NAME, `${USER_MANAGEMENT_DATA_ACCESS_ACCOUNTS}/cost-centers`, payload);
}

export async function updateDataAccessCostCenter({ costCenterId, costCenterAccountsData }) {
  const payload = {
    body: { costCenterAccountsData },
  };
  const encodedCostCenterId = encodeURIComponent(costCenterId);
  return API.put(
    BILLINGS_API_NAME,
    `${USER_MANAGEMENT_DATA_ACCESS_ACCOUNTS}/cost-centers/${encodedCostCenterId}`,
    payload,
  );
}

export async function deleteDataAccessCostCenter(costCenterId) {
  const encodedCostCenterId = encodeURIComponent(costCenterId);
  return API.del(BILLINGS_API_NAME, `${USER_MANAGEMENT_DATA_ACCESS_ACCOUNTS}/cost-centers/${encodedCostCenterId}`);
}

export async function updateLinkedAccountData({ payerAccountId, linkedAccountId, linkedAccountData }) {
  const payload = {
    body: { linkedAccountData },
  };
  const encodedPayerAccountId = encodeURIComponent(payerAccountId);
  const encodedLinkedAccountId = encodeURIComponent(linkedAccountId);

  return API.put(
    BILLINGS_API_NAME,
    `${USER_MANAGEMENT_DATA_ACCESS_ACCOUNTS}/payer-accounts/${encodedPayerAccountId}/linked-accounts/${encodedLinkedAccountId}`,
    payload,
  );
}
