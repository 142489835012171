import { RecommendationTypes } from 'recommendations/constants/recommendationsConstants';
import { GcpVmRightSizingRecommendation } from 'recommendations/models/recommTypes';
import GcpCostRecommendationBuilder from 'recommendations/models/builders/gcpCostRecommBuilder';
import { calcNumOfMonthsToBreakEven } from '../../../shared/utils/calcUtil';

export default class GcpUsageCommitmentRecommendationBuilder extends GcpCostRecommendationBuilder {
  constructor(rawRecommendation) {
    super(rawRecommendation);
  }

  createRecommendation() {
    this._recommendation = new GcpVmRightSizingRecommendation();
  }

  getRecommType() {
    return RecommendationTypes.GCP_USAGE_COMMITMENT;
  }

  buildRecommTitle() {
    const { recommended_action: action } = this._rawRecommendation;
    this._recommendation.title = `${action || ''} Usage Commitment`;
  }

  build() {
    super.build();
    const {
      ram_size: ramSize,
      vcpus: vCPUs,
      recommended_commitment: recommendedCommitment,
      commitment_type: commitmentType,
      commitment_plan: commitmentPlan,
      instance_type_model: instanceTypeModel,
      project: projectName,
      zone_tag: tagZone,
      resource_name: resourceName,
      num_of_days: numOfDays,
      starting_time: startingTime,
      potential_savings: potentialSavings,
      additional_recommended_plans: additionalRecommendedPlans,
      total_cost_current: costCurrent,
      total_cost_recommended: costRecommended,
    } = this._rawRecommendation;
    this._recommendation.breakEvenMonth = calcNumOfMonthsToBreakEven(costRecommended * 12, costCurrent * 12);
    this._recommendation.projectName = projectName;
    this._recommendation.region = tagZone;
    this._recommendation.resourceName = resourceName;
    this._recommendation.numOfDays = numOfDays;
    this._recommendation.startingTime = startingTime;
    this._recommendation.potentialSavings = potentialSavings;
    this._recommendation.additionalRecommendedPlans = additionalRecommendedPlans;
    this._recommendation.ramSize = ramSize;
    this._recommendation.vCPUs = vCPUs;
    this._recommendation.recommendedCommitment = recommendedCommitment;
    this._recommendation.recommendedCommitmentTitle = (recommendedCommitment || '').replace('_', ' ').toLowerCase();
    this._recommendation.commitmentType = commitmentType;
    this._recommendation.commitmentPlan = commitmentPlan;
    this._recommendation.instanceTypeModel = instanceTypeModel;
  }
}
