import React, { useState } from 'react';
import { GenerateIcon, ICONS } from '@pileus-cloud/anodot-frontend-common';
import Button from 'shared/components/andtComponents/Button';
import AddUsersModal from './AddUsersModal.jsx';
import { OrganizationEntityCategory, CategoryAction } from '@anodot-cost/rbac-client';
import ReadOnlyDisplayWrapper from 'shared/components/ReadOnlyDisplayWrapper';

const AddUsers = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <ReadOnlyDisplayWrapper
      isHide={false}
      category={OrganizationEntityCategory.UsersManagement}
      action={CategoryAction.Create}
    >
      <Button
        text="Add Users"
        onClick={() => {
          setIsOpen(true);
        }}
        icon={() => <GenerateIcon iconName={ICONS.plus.name} />}
        automationId="newUser"
      />
      <AddUsersModal isOpen={isOpen} setIsOpen={setIsOpen} />
    </ReadOnlyDisplayWrapper>
  );
};

export default AddUsers;
