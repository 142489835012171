 
import { RecommendationTypes } from 'recommendations/constants/recommendationsConstants';
import { AzureDbRiRecommendation } from 'recommendations/models/recommTypes';
import AzureCostRecommendationBuilder from 'recommendations/models/builders/azureCostRecommBuilder';

export default class AzureDbRiRecommendationBuilder extends AzureCostRecommendationBuilder {
  constructor(rawRecommendation) {
    super(rawRecommendation);
  }

  createRecommendation() {
    this._recommendation = new AzureDbRiRecommendation();
  }

  getRecommType() {
    return RecommendationTypes.AZURE_DB_RI;
  }

  buildRecommTitle() {
    const { recommended_action: action } = this._rawRecommendation;
    this._recommendation.title = `${action || ''} DB Ri`;
  }

  build() {
    super.build();
    const { table_name: tableName, starting_time: startingTime, days_to_check: daysToCheck } = this._rawRecommendation;
    this._recommendation.startingTime = startingTime;
    this._recommendation.daysToCheck = daysToCheck;
    this._recommendation.tableName = tableName;
  }
}
