import { useMutation } from '@tanstack/react-query';
import apiConstants from 'shared/api/apiConstants';
import { queryClient } from 'queryClient';
import { API } from 'shared/utils/apiMiddleware';
import {
  ACTION_SUCCESS_MESSAGE,
  BILLINGS_API_NAME,
  RECOMMENDATION_ACTIONS_ROOT,
  RECOMMENDATION_BULK_ACTIONS_ROOT,
  RECOMMENDATIONS_ROOT,
} from 'recommendationsNew/consts';
import { toast } from 'react-toastify';
import { useRootStore } from 'app/contexts/RootStoreContext';

function userActions({ recId, action, actionParams }) {
  return API.post(BILLINGS_API_NAME, `${RECOMMENDATION_ACTIONS_ROOT}`, {
    body: {
      recId,
      action,
      actionParams,
    },
  });
}
function bulkUserAction({ recIds, action, actionParams }) {
  return API.post(BILLINGS_API_NAME, `${RECOMMENDATION_BULK_ACTIONS_ROOT}`, {
    body: {
      recIds,
      action,
      actionParams,
    },
  });
}

const onUserActionSuccess = () => {
  queryClient.invalidateQueries(apiConstants.QUERY_KEYS.RECOMMENDATIONS);
};

export default function useUserRecAction() {
  const { usersStore } = useRootStore();
  const userAccountKey = usersStore?.currDispUserAccountKey;

  const queryKey = [
    apiConstants.QUERY_KEYS.RECOMMENDATIONS,
    apiConstants.QUERY_KEYS.RECOMMENDATION_USER_ACTION,
    userAccountKey,
  ];

  return {
    invalidate: () => queryClient.invalidateQueries(queryKey),
    reset: () => queryClient.resetQueries(queryKey),
    recUserAction: (success) => {
      let lastParams;

      return useMutation(
        (params) => {
          lastParams = params;
          return userActions(params);
        },
        {
          onSuccess: () => {
            onUserActionSuccess();
            success(lastParams?.action, lastParams.recId);
            toast.success(ACTION_SUCCESS_MESSAGE[lastParams?.action]);
          },
        },
      );
    },
    bulkUserAction: () =>
      useMutation((params) => bulkUserAction(params), {
        onSuccess: (data, variables) => {
          onUserActionSuccess();
          toast.success(ACTION_SUCCESS_MESSAGE[variables?.action]);
        },
      }),
  };
}

export function recommendationCommentsProvider(recId) {
  const url = `${RECOMMENDATIONS_ROOT}/${recId}/comments`;
  return {
    createComment: ({ onSuccess, onError }) =>
      useMutation({
        mutationFn: (payload) => API.post(BILLINGS_API_NAME, url, { body: payload }),
        onSuccess,
        onError,
      }),
    deleteComment: ({ onSuccess, onError }) =>
      useMutation({
        mutationFn: (payload) => API.del(BILLINGS_API_NAME, url, { body: payload }),
        onSuccess,
        onError,
      }),
    updateComment: ({ onSuccess, onError }) =>
      useMutation({
        mutationFn: (payload) => API.put(BILLINGS_API_NAME, url, { body: payload }),
        onSuccess,
        onError,
      }),
  };
}
