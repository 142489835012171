/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-unused-vars */
import React from 'react';
import PropTypes from 'prop-types';
import { Row } from 'reactstrap';

const IconTextButton = ({ style, handler, iconComp, title, iconStyle, fontSize = null }) => {
  const Icon = React.createElement(iconComp, { style: { ...iconStyle } });
  return (
    <div className="d-flex" key="IconTextButton" onClick={handler} style={{ ...style }}>
      {/* <Icon /> */}
      <div>
        {Icon}
        {/* <iconComp style={iconStyle} /> */}
      </div>
      <a href="#" style={{ fontSize: fontSize || '16px' }}>
        {title}
      </a>
    </div>
  );
};
IconTextButton.propTypes = {
  handler: PropTypes.func.isRequired,
  style: PropTypes.object.isRequired,
  iconComp: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
  iconStyle: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  fontSize: PropTypes.string,
};
export default IconTextButton;
