import { useEffect, useRef } from 'react';

const useCtrlKeyPressed = (targetKey: string) => {
  const isKeyPressedRef = useRef(false);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.ctrlKey || event.metaKey) {
        isKeyPressedRef.current = true;
      }
    };

    const handleKeyUp = () => {
      isKeyPressedRef.current = false;
    };

    window.addEventListener('keydown', handleKeyDown);
    window.addEventListener('keyup', handleKeyUp);
    window.addEventListener('blur', handleKeyUp); // Reset on window blur

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      window.removeEventListener('keyup', handleKeyUp);
      window.removeEventListener('blur', handleKeyUp);
    };
  }, [targetKey]); // Re-attaches only if the key changes

  // Return the ref directly (no state means no re-render)
  return isKeyPressedRef;
};

export default useCtrlKeyPressed;
