import Modal from 'shared/components/andtComponents/Modal';
import { Search } from 'users/containers/Organization/components/Search.jsx';
import React, { useEffect, useMemo } from 'react';
import { DataTypeProvider, IntegratedSorting, SortingState } from '@devexpress/dx-react-grid';
import TableWrapper from 'shared/components/tables/TableWrapper.jsx';
import { Grid, TableHeaderRow } from '@devexpress/dx-react-grid-material-ui';
import Checkbox from 'shared/components/andtComponents/Checkbox.jsx';
import { useSharingEntities } from 'users/new-user-management/hooks/reactQuery/useSharing.js';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import styles from './ShareEntityModal.module.scss';
import useTable from 'shared/hooks/customHooks/useTable.jsx';
import {
  CustomFormatterWithTooltipOnlyIfLongText,
  CustomHeaderCell,
} from 'shared/components/andtComponents/TableComponents/TableComponents.jsx';
import { GenerateIcon, ICONS } from '@pileus-cloud/anodot-frontend-common';
import Spinner from '~/shared/components/andtComponents/Spinner.jsx';
import { Action } from '@anodot-cost/rbac-client';

const COLUMNS = [
  {
    name: 'name',
    title: 'Role Name',
  },
  {
    name: 'id',
    title: 'Role ID',
  },
  {
    name: 'view',
    title: 'View',
  },
  {
    name: 'edit',
    title: 'Edit',
  },
];

const TextWithTooltip = (props) => <CustomFormatterWithTooltipOnlyIfLongText value={props?.row?.id} isSmallText />;
TextWithTooltip.propTypes = {
  row: PropTypes.object,
};

const ShareEntityModal = ({ onClose, entity, entityId, onSuccess }) => {
  const [search, setSearch] = React.useState('');
  const [selectedRoles, setSelectedRoles] = React.useState({});
  const { useGetEntityPermissionsRoles, useUpdateEntityPermissions } = useSharingEntities();
  const { data = [], isLoading } = useGetEntityPermissionsRoles({ category: entity, id: entityId });
  useEffect(() => {
    setSelectedRoles(
      data.reduce((acc, role) => {
        acc[role.role.id] = {
          view: role.permissions.includes(Action.View),
          edit: role.permissions.includes(Action.Update),
        };
        return acc;
      }, {}),
    );
  }, [data]);
  const { NewTableWrapper } = useTable();
  const roles = useMemo(
    () =>
      data.filter(({ role }) => {
        return role.displayName.toLowerCase().includes(search.toLowerCase()) || role.id.toString().includes(search);
      }),
    [data, search],
  );
  const handleSubmit = useUpdateEntityPermissions({
    onSuccess: () => {
      toast.success('Permissions updated');
      if (onSuccess) {
        onSuccess();
      }
      onClose();
    },
  });
  const renderCheckbox = (row, action) => {
    return (
      <Checkbox
        isChecked={selectedRoles[row.id]?.[action]}
        onChange={() => {
          if (!row.id) {
            return;
          }
          setSelectedRoles({
            ...selectedRoles,
            [row.id]: {
              ...(selectedRoles[row.id] || {}),
              [action]: !selectedRoles[row.id]?.[action],
            },
          });
        }}
      />
    );
  };
  const renderActionColumnTitle = ({ column: { name, title }, ...rest }) => {
    const isSelected = roles.every((role) => selectedRoles[role.role.id]?.[name]);
    return (
      <CustomHeaderCell column={{ name, title }} {...rest}>
        <span className="d-flex align-items-center">
          {name === 'view' || name === 'edit' ? (
            <Checkbox
              isChecked={isSelected}
              onChange={() => {
                setSelectedRoles(
                  roles.reduce((acc, role) => {
                    acc[role.role.id] = {
                      ...(selectedRoles[role.role.id] || {}),
                      [name]: !isSelected,
                    };
                    return acc;
                  }, {}),
                );
              }}
            />
          ) : null}
          {name === 'view' && <GenerateIcon iconName={ICONS.eye.name} className="me-2" />}
          {name === 'edit' && <GenerateIcon iconName={ICONS.pencil.name} className="me-2" />}
          {title}
        </span>
      </CustomHeaderCell>
    );
  };
  return (
    <Modal
      open
      onClose={onClose}
      title="Share Permissions"
      headerMode="lock"
      saveTitle="Submit"
      overrideStyles={{ width: 800 }}
      isLoading={handleSubmit.isLoading}
      onSave={() => handleSubmit.mutateAsync({ category: entity.toLowerCase(), id: entityId, roles: selectedRoles })}
    >
      <div className={styles.header}>
        <p>Select the roles you wish to allow to view and/or edit.</p>
        <Search search={search} setSearch={setSearch} />
      </div>
      {isLoading ? (
        <div className={styles.loading}>
          <Spinner />
        </div>
      ) : (
        <NewTableWrapper isCompact className={styles.table}>
          <Grid
            rows={roles.map((r) => ({
              name: r.role.displayName,
              id: r.role.id,
              view: selectedRoles[r.role.id]?.view,
              edit: selectedRoles[r.role.id]?.edit,
            }))}
            columns={COLUMNS}
          >
            <SortingState defaultSorting={[{ columnName: 'name', direction: 'asc' }]} />
            <IntegratedSorting />
            <DataTypeProvider for={['id']} formatterComponent={TextWithTooltip} />
            <DataTypeProvider for={['view']} formatterComponent={({ row }) => renderCheckbox(row, 'view')} />
            <DataTypeProvider for={['edit']} formatterComponent={({ row }) => renderCheckbox(row, 'edit')} />
            <TableWrapper columnExtensions={[]} />
            <TableHeaderRow showSortingControls cellComponent={renderActionColumnTitle} />
          </Grid>
        </NewTableWrapper>
      )}
    </Modal>
  );
};

ShareEntityModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  entity: PropTypes.oneOf(['dashboard', 'report', 'budget', 'alert']).isRequired,
  entityId: PropTypes.string.isRequired,
  onSuccess: PropTypes.func,
};

ShareEntityModal.defaultProps = {
  onSuccess: null,
};

export default ShareEntityModal;
