import { API } from 'shared/utils/apiMiddleware';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import apiConstants from 'shared/api/apiConstants';
import toast from 'shared/components/andtComponents/Toast';

const root = '/api/v1/users/notifications';

const fetchNotificationsApi = () => API.get('billings', root);

export const useNotifications = () => {
  const queryClient = useQueryClient();
  const queryKey = [apiConstants.QUERY_KEYS.NOTIFICATIONS];
  return {
    fetchNotifications: (options = {}) =>
      // eslint-disable-next-line react-hooks/rules-of-hooks
      useQuery({
        queryKey,
        queryFn: fetchNotificationsApi,
        refetchInterval: 60 * 30 * 1000,
        staleTime: 60 * 5 * 1000,
        ...options,
      }),
    excludeLinkedAccounts: () =>
      // eslint-disable-next-line react-hooks/rules-of-hooks
      useMutation({
        mutationFn: (data) => API.post('billings', `${root}/exclude-linked-accounts`, { body: data }),
        onSuccess: () => {
          queryClient.invalidateQueries({ queryKey });
          toast.success('Notifications excluded.');
        },
      }),
  };
};
