import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Select, { components } from 'react-select';
import { useUserSettingsContext } from 'users/utils/contexts/UserSettingsContext';
import useGroupByOption from 'recommendationsNew/hooks/react-query/useGroupByOption';
import { useHeatMapContext } from 'recommendationsNew/components/heatMap/contexts/heatMapContext';
import { useRecommendationsNewContext } from 'recommendationsNew/contexts/recommendationsNewContext';
import {
  GenerateIcon,
  ICONS,
  Popover,
  PopoverContent,
  PopoverPortal,
  PopoverTrigger,
} from '@pileus-cloud/anodot-frontend-common';
import { palette } from 'shared/constants/colorsConstants';
import Tooltip from 'shared/components/andtComponents/Tooltip';

import classes from './heatMapCard.module.scss';

const CustomControl = ({ children, ...props }) => (
  <components.Control {...props}>
    <GenerateIcon iconName={ICONS.magnifyingGlass.name} />
    {children}
  </components.Control>
);
CustomControl.propTypes = {
  children: PropTypes.node.isRequired,
};

const CustomOption = ({ data, ...props }) => {
  const { currencySymbol } = useUserSettingsContext();
  return (
    <components.Option {...props}>
      <div className={classes.optionContainer}>
        <Tooltip title={data?.label} placement="left">
          <div className={classes.optionLabel}>{data?.label}</div>
        </Tooltip>
        {data?.savings ? (
          <div className={classes.optionNumber}>
            <div>{currencySymbol}</div>
            <div>{data?.savings?.toLocaleString()}</div>
          </div>
        ) : (
          <div />
        )}
      </div>
    </components.Option>
  );
};
CustomOption.propTypes = {
  label: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,
};

export const HeatMapCardMoreList = ({ groupByOptionId, lastOption = {}, onChange }) => {
  const [isOpen, setIsOpen] = useState(false);

  const { heatMapSortByOption } = useHeatMapContext();

  const { recommendationFilters: filtersContext } = useRecommendationsNewContext();

  const heatMapGroupByOption = useGroupByOption(isOpen);
  const { data, isLoading } = heatMapGroupByOption.fetchGroupByOption({
    groupBy: groupByOptionId,
    filters: filtersContext,
    sortBy: heatMapSortByOption,
    lastItemOfPrevPage: lastOption,
  });

  const customComponents = {
    Option: CustomOption,
    Control: CustomControl,
  };

  const customStyles = {
    control: (base) => ({
      ...base,
      backgroundColor: palette.blue[200],
      borderRadius: '4px 4px 0 0',
      border: 'none',
      boxShadow: 'none',
      color: palette.gray[400],
      height: 24,
      outline: 'none',
      paddingLeft: 10,
      '&:hover': {
        cursor: 'pointer',
      },
    }),
    indicatorSeparator: () => ({
      display: 'none',
    }),
    dropdownIndicator: () => ({
      display: 'none',
    }),
    menu: (base) => ({
      ...base,
      border: 'none',
      borderRadius: '0 0 4px 4px',
      boxShadow: 'none',
      margin: 0,
      height: 130,
      position: 'relative',
    }),
    menuList: (base) => ({
      ...base,
      border: 'none',
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
    }),
    option: (base, state) => ({
      ...base,
      backgroundColor: state.isSelected ? palette.gray[100] : palette.white[500],
      color: state.isSelected ? palette.blue[500] : palette.gray[500],
      fontSize: 14,
      fontWeight: 400,
      padding: '8px 14px 8px 14px',
      lineHeight: '20px',
      '&:hover': {
        backgroundColor: palette.gray[100],
        cursor: 'pointer',
      },
    }),
  };

  return (
    <div className={classes.moreButtonContainer}>
      <Popover open={isOpen} onOpenChange={() => setIsOpen(!isOpen)}>
        <PopoverTrigger>
          <div className={classes.moreButton}>
            More <GenerateIcon iconName={ICONS.chevronRight.name} className={classes.chevronRight} />
          </div>
        </PopoverTrigger>

        <PopoverPortal>
          <PopoverContent sideOffset={45} side="bottom right" className={classes.popoverContent}>
            <Select
              placeholder="Search..."
              menuIsOpen={isOpen}
              styles={customStyles}
              components={customComponents}
              closeMenuOnSelect={false}
              isSearchable
              name="heatMapCardMore"
              options={data?.page}
              value={null}
              isLoading={isLoading}
              onChange={onChange}
            />
          </PopoverContent>
        </PopoverPortal>
      </Popover>
    </div>
  );
};

HeatMapCardMoreList.propTypes = {
  groupByOptionId: PropTypes.string.isRequired,
  lastOption: PropTypes.object,
  onChange: PropTypes.func.isRequired,
};
