/* eslint-disable no-unused-vars */
 
 
import { RecommendationTypes } from 'recommendations/constants/recommendationsConstants';
import { AzureSnapshotMigrationRecommendation } from 'recommendations/models/recommTypes';
import { RecommendationCost } from 'recommendations/models/recommCommonTypes';
import AzureCostRecommendationBuilder from 'recommendations/models/builders/azureCostRecommBuilder';
import { calcNumOfMonthsToBreakEven } from 'shared/utils/calcUtil';

export default class AzureSnapshotMigrationRecommendationBuilder extends AzureCostRecommendationBuilder {
  constructor(rawRecommendation) {
    super(rawRecommendation);
  }

  createRecommendation() {
    this._recommendation = new AzureSnapshotMigrationRecommendation();
  }

  getRecommType() {
    return RecommendationTypes.AZURE_SNAPSHOT_MIGRATION;
  }

  buildRecommTitle() {
    const { action } = this._rawRecommendation;
    this._recommendation.title = action ? `${action} snapshot to standard disk` : 'Migrate snapshot to standard disk';
  }

  build() {
    super.build();
    const {
      // quantity,
      snapshot_name: snapShotName,
      account_id: accountId,
      yearly_cost_current: currYearlyCost,
      yearly_cost_recommended: recommYearlyCost,
      total_cost_recommended: recommMonthlyCost,
      total_cost_current: currMonthlyCost,
      recommended_plan: recommPlan,
      // action,
      zone_tag: zoneTag,
      resource_group: resourceGroup,
      service,
    } = this._rawRecommendation;

    // this._recommendation.quantity = parseInt(quantity, 0);

    const yearlyRecommCost = new RecommendationCost(currYearlyCost, recommYearlyCost);
    this._recommendation.breakEvenMonth = calcNumOfMonthsToBreakEven(recommYearlyCost, currYearlyCost);
    this._recommendation.region = zoneTag;
    this._recommendation.snapShotName = snapShotName;
    this._recommendation.yearlyCost = yearlyRecommCost;
    this._recommendation.recommPlan = recommPlan;
    // this._recommendation.action = action;
    this._recommendation.accountId = accountId;
    this._recommendation.resourceGroup = resourceGroup;
    this._recommendation.service = service;
  }
}
