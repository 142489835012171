import React from 'react';
import PropTypes from 'prop-types';
import RecommendationDetails from '../../recommendationGenericComponents/recommendationDetails';
import { AZURE_PROPERTIES, BASE_PROPERTIES, COST_PROPERTIES, getBooleanValue } from '../../recommendationPropertyUtils';
import RecommendationDetailsLayout from '../../recommendationDetailsLayout';
import RecommendationPreferences from '../../recommendationGenericComponents/recommendationPreferences';
import RecommendationInsideLook from '../../recommendationGenericComponents/recommendationInsideLook';
import OtherResourceIdRecommendations from '../../recommendationGenericComponents/otherResourceIdRecommendations';
import RecommendationCommand, { COMMAND_TYPES } from '../../recommendationGenericComponents/recommendationCommand';
import { Y_AXIS_UNIT } from '../../recommendationGenericComponents/recommendationChart/chartConsts';
import RecommendationChartData, {
  CHART_TYPES,
} from '../../recommendationGenericComponents/recommendationChart/recommendationChartData';

const AZURE_VM_RIGT_SIZING_PROPERTIES = {
  SKU_NAME_CURRENT: {
    label: 'Current Instance Type (SKU)',
    getterFunction: (recommendation) => recommendation?.recData?.sku_name_current,
  },
  SKU_NAME_RECOMMENDED: {
    label: 'Recommended Instance Type (SKU)',
    getterFunction: (recommendation) => recommendation?.recData?.sku_name_recommended,
  },
  MAX_NETWORK: {
    label: 'Max Network',
    isBSize: true,
    getterFunction: (recommendation) => recommendation?.recData?.max_total_network,
  },
  CPU_95: {
    label: '95PCT CPU Utilization (%)',
    isPercent: true,
    getterFunction: (recommendation) => recommendation?.recData?.cpu_percentile,
  },
  CURRENT_MODEL_RI_COVERAGE: {
    label: 'Current Model RI Coverage',
    getterFunction: (recommendation) => getBooleanValue(recommendation?.recData?.is_current_reserved),
  },
  TARGET_MODEL_RI_COVERAGE: {
    label: 'Target Model RI Coverage',
    getterFunction: (recommendation) => getBooleanValue(recommendation?.recData?.is_recommended_reserved),
  },
};
const attentionComment = (
  <ul style={{ paddingLeft: '20px' }}>
    <li key={1}>
      In a <strong>MCA/CSP Export Amortize </strong> account type the potential savings and current cost are calculated
      based on the <strong>Effective Cost </strong>(RI price).
    </li>
    <li key={2}>
      In a <strong>MCA/CSP Export Unblended</strong> account type the potential savings and current cost are calculated
      based on the
      <strong> List Price</strong> (on demand).
    </li>
  </ul>
);
const AzureVmRightSizing = ({ recommendation }) => {
  const properties = {
    ...AZURE_VM_RIGT_SIZING_PROPERTIES,
    ...AZURE_PROPERTIES,
    ...COST_PROPERTIES,
    ...BASE_PROPERTIES,
  };

  const description = `We recommend using a more suitable instance type that will cost less and be more appropriate
                        as this VM instance is under utilized.`;
  const insideLookComponent = <RecommendationInsideLook description={description} />;

  const otherResourceIdRecommendations = <OtherResourceIdRecommendations resourceId={recommendation?.resourceId} />;

  const recommendationWithAttentionComment = {
    ...recommendation,
    recData: {
      ...recommendation.recData,
      attention_comment: attentionComment,
    },
  };
  const detailsComponent = (
    <RecommendationDetails recommendationProperties={properties} recommendation={recommendationWithAttentionComment} />
  );

  const commandsList = [
    {
      type: COMMAND_TYPES.CLI,
      instructions: [
        {
          actionText: '',
          // eslint-disable-next-line max-len
          actionCommand: `az vm resize --resource-group ${recommendation?.recData?.resource_group} --name ${recommendation?.recData?.role_name} --size ${recommendation?.recData?.sku_name_recommended}`,
        },
      ],
    },
  ];

  const recommendationCommandComponent = <RecommendationCommand commandsList={commandsList} />;

  const preferencesComponent = <RecommendationPreferences recommendationType={recommendation.typeId} />;

  const cpuUtilizationLines =  [{
        id: 'cpuUtilization',
        label: 'CPU',
        data: recommendation?.recData?.cpu_util_statistics_usage,
      },
      {
        id: 'maxCpuUtilization',
        label: 'Max CPU Utilization',
        data: recommendation?.recData?.max_cpu_statistics_usage,
  }
  ];

  const chartsData = [
    {
      chartType: CHART_TYPES.GENERIC,
      chartTitle: 'Network Performance',
      yAxisLabel: 'Network I/O (MB)',
      yAxisUnit: Y_AXIS_UNIT.MB,
      linesList: [
        {
          id: 'networkIn',
          label: 'Network In',
          data: recommendation?.recData?.network_in_statistics_usage,
        },
        {
          id: 'networkOut',
          label: 'Network Out',
          data: recommendation.recData?.network_out_statistics_usage,
        },
      ],
      referenceLines: [
        {
          yAxisValue: recommendation?.recData?.max_total_network,
          label: `Low Network Performance Benchamark ${recommendation?.recData?.max_total_network} MB`,
        },
      ],
    },
    {
      chartType: CHART_TYPES.GENERIC,
      chartTitle: 'CPU Performance',
      yAxisLabel: 'CPU Utilization',
      yAxisUnit: Y_AXIS_UNIT.PERCENT,
      linesList: !recommendation?.recData?.cpu_p95_statistics_usage ? cpuUtilizationLines : [
        ...cpuUtilizationLines,
        {
          id: 'p95CpuUtilization',
          label: '95P CPU Utilization',
          data: recommendation?.recData?.cpu_p95_statistics_usage,
        },
      ],
      referenceLines: recommendation?.recData?.cpu_p95_statistics_usage ? [] : [
        {
          yAxisValue: recommendation?.recData?.cpu_percentile,
          label: '95P CPU Utilization',
        },
      ],
    },
    {
      chartType: CHART_TYPES.GENERIC,
      chartTitle: 'Memory Performance',
      yAxisLabel: 'Memory Utilization',
      yAxisUnit: Y_AXIS_UNIT.PERCENT,
      linesList: [
        {
          id: 'memoryUtilization',
          label: 'Memory',
          data: recommendation?.recData?.mem_utilization_percentage,
        },
      ],
    },
  ];

  const recommendationChartsComponent = <RecommendationChartData chartsData={chartsData} />;

  return (
    <RecommendationDetailsLayout
      insideLook={insideLookComponent}
      otherResourceIdRecommendations={otherResourceIdRecommendations}
      details={detailsComponent}
      command={recommendationCommandComponent}
      preferences={preferencesComponent}
      charts={recommendationChartsComponent}
    />
  );
};

AzureVmRightSizing.propTypes = {
  recommendation: PropTypes.object.isRequired,
};

export default AzureVmRightSizing;
