 
import { RecommendationTypes } from 'recommendations/constants/recommendationsConstants';
import AzureDbIdleCostRecommendationBuilder from 'recommendations/models/builders/azureDbIdleCostRecommendationBuilder';
import { AzureMariaDbIdleRecommendation } from '../recommTypes';

export default class AzureMariaDbIdleCostRecommendationBuilder extends AzureDbIdleCostRecommendationBuilder {
  constructor(rawRecommendation) {
    super(rawRecommendation);
  }

  createRecommendation() {
    this._recommendation = new AzureMariaDbIdleRecommendation();
  }

  getRecommType() {
    return RecommendationTypes.AZURE_MARIA_DB_IDLE;
  }

  buildRecommTitle() {
    const { recommended_action: action } = this._rawRecommendation;
    this._recommendation.title = `${action || ''} Maria DB Idle`;
  }
}
