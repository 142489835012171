import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import styles from './recommendedChart.module.scss';

const CustomizedAxisTick = ({ x, y, payload, isDailyMode, isDateFormat, index, tickLength, isDashboardPanel }) => {
    const { value } = payload;
    const dateFormat = moment(value).format('MMM DD');
    const calculateXTranslate = (x) => {
        if(tickLength === index){
            return isDashboardPanel ? x-5 : x;
        }
        return isDashboardPanel ? x : x+25;
    }
    if (isDailyMode) {
        return (
            <g transform={`translate(${calculateXTranslate(x)},${y})`}>
                <text x={0} y={0} dy={16} textAnchor="end" className={styles.chartTick} transform={`rotate(${isDashboardPanel ? -15 : 0})`}>
                    <tspan x="0" dy="1.2em">
                        {isDateFormat ? dateFormat : value}
                    </tspan>
                </text>
            </g>
        );
    }
    const timeFormat = moment(value).format('HH:mm');
    return (
        <g transform={`translate(${x},${y})`}>
            <text x={0} y={0} dy={16} textAnchor="end" className={styles.chartTick}>
                <tspan x="0" dy="0.8em">
                    {isDateFormat ? dateFormat : value.split('-')[0].trim()}
                </tspan>
                <tspan x="0" dy="1.25em">
                    {isDateFormat ? timeFormat : value.split('-')[1]?.trim()}
                </tspan>
            </text>
        </g>
    );
};

CustomizedAxisTick.propTypes = {
    x: PropTypes.number.isRequired,
    y: PropTypes.number.isRequired,
    payload: PropTypes.object.isRequired,
    isDailyMode: PropTypes.bool.isRequired,
    isDateFormat: PropTypes.bool.isRequired,
    tickLength: PropTypes.bool.isRequired,
    isDashboardPanel: PropTypes.bool,
    index: PropTypes.number.isRequired,
};

export default CustomizedAxisTick;
