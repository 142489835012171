import React from 'react';
import PropTypes from 'prop-types';
import { Progress } from 'reactstrap';
import { capitalize } from 'lodash';
import { DataTypeProvider, RowDetailState, IntegratedSorting, SortingState } from '@devexpress/dx-react-grid';
import {
  Grid,
  TableHeaderRow,
  TableRowDetail,
  TableColumnResizing,
  Table,
} from '@devexpress/dx-react-grid-material-ui';
import TableWrapper from 'shared/components/tables/TableWrapper';
import Spinner from 'shared/components/andtComponents/Spinner';
import { percentStrNoCommas } from 'shared/utils/strUtil';
import Tooltip from 'shared/components/andtComponents/Tooltip';
import moment from 'moment/moment';
import { useUserSettingsContext } from 'users/utils/contexts/UserSettingsContext';
import CreditActions from './CreditActions';
import AlertActions from './AlertActions';
import { currenciesFormatter, isCreditAlert, getCreditAlertByCreditId } from '../creditHelpers';
import { creditsColumns, keysToTitle, creditsColumnsWidths } from '../creditConstants';
import styles from './creditTable.module.scss';

const propTypes = {
  rows: PropTypes.array.isRequired,
  alerts: PropTypes.array.isRequired,
  onAlertClick: PropTypes.func.isRequired,
  onCloneCredit: PropTypes.func.isRequired,
  onDeleteCredit: PropTypes.func.isRequired,
  onDeleteAlert: PropTypes.func.isRequired,
  creditId: PropTypes.string,
  isAddingAlert: PropTypes.bool,
  isAlertsLoading: PropTypes.bool,
  isDeletingAlert: PropTypes.bool,
  expandedRows: PropTypes.array,
  setExpandedRows: PropTypes.func.isRequired,
};

const CreditsTable = ({
  rows,
  alerts,
  onAlertClick,
  onCloneCredit,
  onDeleteCredit,
  onDeleteAlert,
  isAddingAlert = false,
  creditId = '',
  expandedRows = [],
  setExpandedRows,
  isDeletingAlert = false,
  isAlertsLoading = false,
}) => {
  const { getCurrencyNumber } = useUserSettingsContext();

  const getDetailsKeyTitle = (key, value) => {
    if (!value) {
      return '';
    }
    if (value.relation) {
      return `${keysToTitle[`${value.relation}${capitalize(key)}`] || ''}${value.values.length ? ':' : ''}`;
    }
    return `${keysToTitle[key] || ''}${value.length ? ':' : ''}`;
  };

  const renderAmountComponent = ({ value, row, column }) => {
    const { name } = column;
    const percentage = (+value / +row.amount) * 100;
    return (
      <div className={`d-flex flex-column ${name === 'amount' ? `${styles.amountCell}` : ''}`}>
        <div style={{ margin: `0 ${name === 'amount' ? 'auto' : ''}` }}>
          <span>{currenciesFormatter(value, getCurrencyNumber)}</span>
          {name !== 'amount' && <span> | {percentStrNoCommas(percentage, 2)}</span>}
        </div>
        {name === 'amount' ? (
          <Progress
            value={(+row.amountUsed / +row.amount) * 100}
            color="#2671FF"
            barClassName={styles.progressBar}
            className={styles.progress}
          />
        ) : null}
      </div>
    );
  };

  renderAmountComponent.propTypes = {
    value: PropTypes.number.isRequired,
    row: PropTypes.object.isRequired,
    column: PropTypes.object.isRequired,
  };

  const renderAlertColComponent = ({ row }) => {
    const alert = getCreditAlertByCreditId(row.id, alerts);
    let tooltipText = 'Credit Alert';
    if (alert) {
      const { utilization, expiration } = alert.alerts;
      tooltipText = (
        <div className="d-flex flex-column">
          <span>
            {`Alert Credit Utilization: ${getCurrencyNumber(utilization.minAmount)}, ${utilization.minPercentage}%`}
          </span>
          <span>
            {`${
              expiration.isAlert
                ? `Alert credit expired: ${moment(new Date(row.expirationDate)).format('MMM YYYY')}`
                : ''
            }`}
          </span>
          <span>
            {`${utilization.creditCompletion ? `Alert credit complition: ${getCurrencyNumber(row.amount)}` : ''}`}
          </span>
        </div>
      );
    }
    if (isAlertsLoading || (creditId === row.id && isAddingAlert) || isDeletingAlert) {
      return (
        <div className="d-flex align-items-center justify-contnent-center">
          <Spinner size={30} position="unset" />
        </div>
      );
    }
    return (
      <AlertActions
        isCreditAlert={!!alert}
        onAdd={() => onAlertClick(row.id)}
        onDelete={() => onDeleteAlert(alert && alert.uuid)}
        tooltipText={tooltipText}
      />
    );
  };
  renderAlertColComponent.propTypes = {
    row: PropTypes.object.isRequired,
  };

  const renderListOfValues = (values, key) => {
    if (key === 'alerts' && values) {
      const { minPercentage, minAmount, creditCompletion } = values.alerts.utilization;
      const { isAlert: isExpiredAlert } = values.alerts.expiration;
      return (
        <div className="d-flex flex-column">
          <div>
            <span>
              Alert Credit Utilization: {minPercentage}% ({getCurrencyNumber(minAmount)})
            </span>
            {isExpiredAlert ? <span> | Alert when credit expired</span> : null}
            {creditCompletion ? <span> | Alert when credit completed</span> : null}
          </div>
          <div>
            <span className="me-1">Recipients ({values.recipientsEmails.split(',').length}):</span>
            <span>{values.recipientsEmails}</span>
          </div>
        </div>
      );
    }
    if (typeof values === 'object' && !Array.isArray(values)) {
      return (
        <span>
          {(Array.isArray(values.values) ? values.values : [values.values]).map((val) => capitalize(val)).join(', ')}
        </span>
      );
    }
    return <span>{(Array.isArray(values) ? values : [values]).map((val) => capitalize(val)).join(', ')}</span>;
  };

  const renderRowDetailsComponent = ({ row }) => {
    const { isExcludeAwsMarketplace } = row;
    const detailsKeys = Object.keys(row).filter((key) => ['divisionName', 'service'].includes(key));
    if (isExcludeAwsMarketplace) {
      detailsKeys.push('excludeAwsMarketplace');
    }
    if (isCreditAlert(row.id, alerts)) {
      detailsKeys.push('alerts');
    }
    const detailsValues = detailsKeys.reduce((acc, key) => {
      if (!acc[key]) {
        acc[key] = [];
      }
      if (key === 'divisionName') {
        acc[key].push(...(Array.isArray(row[key]) ? row[key] : [row[key]]));
      } else if (key === 'alerts') {
        const alert = alerts.find((currAlert) => currAlert.creditId === row.id);
        acc[key] = alert;
      } else if (key !== 'excludeAwsMarketplace') {
        acc[key] = row[key];
      }
      return { ...acc };
    }, {});

    return detailsKeys.map((detailsKey) => (
      <div className={`d-flex ${detailsKey === 'alerts' ? 'flex-column' : ''} mb-2`}>
        <b className="me-2">
          {`${getDetailsKeyTitle(detailsKey, detailsValues[detailsKey])}${detailsKey === 'alerts' ? ':' : ''}`}
        </b>
        <span>{renderListOfValues(detailsValues[detailsKey], detailsKey)}</span>
      </div>
    ));
  };
  renderRowDetailsComponent.propTypes = {
    row: PropTypes.object.isRequired,
  };

  const renderDetailsRow = (val) => (
    <tr>
      <td colSpan={`${creditsColumns.length + 2}`} className={`${styles.rowDetail}`}>
        {renderRowDetailsComponent(val)}
      </td>
    </tr>
  );
  const toggleExpanded = (expanded, row) => {
    if (expanded) {
      setExpandedRows((prevExpandedRows) => prevExpandedRows.filter((rowId) => rowId !== row.rowId));
    } else {
      setExpandedRows((prevExpandedRows) => [...prevExpandedRows, row.rowId]);
    }
  };

  const renderToggleCellComponent = ({ expanded, tableRow, ...restProps }) => (
    <TableRowDetail.ToggleCell {...restProps} onToggle={() => toggleExpanded(expanded, tableRow)} expanded={expanded} />
  );

  const renderTableRow = ({ row, tableRow, ...restProps }) => (
    <Table.Row
      {...restProps}
      /* eslint-disable-next-line react/prop-types */
      className={expandedRows.includes(tableRow.rowId) ? `${styles.expandedRow}` : ''}
    />
  );

  const renderNameOrCustomerComponent = ({ value }) => (
    <Tooltip placement="top" title={value}>
      <div className={`${styles.cutText}`}>{value}</div>
    </Tooltip>
  );

  return (
    <Grid rows={rows} columns={creditsColumns}>
      <SortingState defaultSorting={[{ columnName: 'expirationDate', direction: 'desc' }]} />
      <RowDetailState expandedRowIds={expandedRows} />
      <IntegratedSorting />
      <TableWrapper rowComponent={renderTableRow} />
      <TableColumnResizing columnWidths={creditsColumnsWidths} resizingMode="nextColumn" />
      <DataTypeProvider for={['amount', 'amountUsed', 'amountLeft']} formatterComponent={renderAmountComponent} />
      <DataTypeProvider
        for={['monthlyAmountLimit']}
        formatterComponent={({ value }) => (value ? currenciesFormatter(value, getCurrencyNumber) : '')}
      />
      <DataTypeProvider for={['name']} formatterComponent={renderNameOrCustomerComponent} />
      <DataTypeProvider for={['alert']} formatterComponent={renderAlertColComponent} />
      <DataTypeProvider for={['divisionName']} formatterComponent={renderNameOrCustomerComponent} />
      <DataTypeProvider
        for={['userActions']}
        formatterComponent={(props) => (
          <CreditActions {...props} onCloneCredit={onCloneCredit} onDeleteCredit={onDeleteCredit} />
        )}
      />
      <TableHeaderRow showSortingControls />
      <TableRowDetail rowComponent={renderDetailsRow} toggleCellComponent={renderToggleCellComponent} />
    </Grid>
  );
};

CreditsTable.propTypes = propTypes;
export default CreditsTable;
