import React from 'react';
import PropTypes from 'prop-types';
import { isEmptyArray } from 'shared/utils/arrayUtils';
import { CostTypeModes } from 'usage/constants/costAndUsageConstants';
import InfoPopover from 'shared/components/andtComponents/InfoPopover';
import ButtonMultiDropdown from 'shared/components/andtComponents/ButtonMultiDropdown';
import icons from '../icons';
import RateBlock from './RateBlock';
import styles from './CostTypeMenu.module.scss';
import Tooltip from '~/shared/components/andtComponents/Tooltip.jsx';
import tooltipStyles from '~/shared/components/andtComponents/Tooltip.module.scss';
import { palette } from '~/shared/constants/colorsConstants.js';

const { CostIcon, UsageIcon, RateIcon, CloudDotsIcon } = icons.costUsage;

const getLabel = (value, type) => {
  if (!type) {
    return value;
  }
  if (!value || value === type) {
    return type;
  }
  return `${type}: ${value}`;
};

const CostTypeMenu = ({ costUsageHandler }) => {
  const {
    costOptions = [],
    costReadOnlyOptions = undefined,
    costValue,
    usageOptions = [],
    usageValue,
    onChange,
    costTypeMode,
    isRateDisabled,
    costDefaultType,
    carbonEmissionsOptions,
    carbonEmissionsValue,
  } = costUsageHandler;
  const costOptionName = (costOptions.find((c) => c.value === costValue) || {}).name || costDefaultType || 'Cost';
  const usageOptionName = ((usageOptions || []).find((c) => c.value === usageValue) || {}).name;
  const carbonEmissionName = ((carbonEmissionsOptions || []).find((c) => c.value === carbonEmissionsValue) || {}).name;
  const isCostContainOptions = !isEmptyArray(costOptions) || !isEmptyArray(costReadOnlyOptions);
  const isUsage = costTypeMode === CostTypeModes.USAGE;
  const isCost = costTypeMode === CostTypeModes.COST;
  const isRate = costTypeMode === CostTypeModes.RATE;
  const isCarbonEmissions = costTypeMode === CostTypeModes.CARBON_EMISSIONS;
  const labelByType = {
    [CostTypeModes.COST]: costOptionName,
    [CostTypeModes.USAGE]: usageOptionName,
    [CostTypeModes.RATE]: 'Rate',
    [CostTypeModes.CARBON_EMISSIONS]: carbonEmissionName,
  };
  const tooltipByType = {
    [CostTypeModes.COST]: getLabel(costOptionName, 'Cost'),
    [CostTypeModes.USAGE]: getLabel(usageOptionName, 'Usage'),
    [CostTypeModes.CARBON_EMISSIONS]: getLabel(carbonEmissionName, 'Carbon Emissions'),
    [CostTypeModes.RATE]: getLabel(
      `${costOptionName === 'Cost' ? costValue : costOptionName} / ${usageOptionName}`,
      'Rate',
    ),
  };
  const wrapOptionInTooltip = (option, tooltip) =>
    tooltip ? (
      <Tooltip title={tooltip} key={tooltip} classes={{ tooltip: tooltipStyles.darkGrayTooltip }}>
        {option}
      </Tooltip>
    ) : (
      option
    );
  const options = [
    {
      id: 'cost',
      label: getLabel(isCost ? costOptionName : null, costDefaultType || 'Cost'),
      selected: isCost,
      icon: CostIcon,
      simpleOption: !isCostContainOptions,
      onClick: isCostContainOptions ? null : () => onChange(costValue, null, CostTypeModes.COST),
      content: isCostContainOptions ? (
        <>
          {costReadOnlyOptions?.length
            ? costReadOnlyOptions.map((o) =>
                wrapOptionInTooltip(
                  <li data-disabled={o.disabled} data-selected={o.selected && isCost} id={o.value}>
                    <span>{o.name}</span>
                  </li>,
                  o.tooltip,
                ),
              )
            : costOptions.map((o) =>
                wrapOptionInTooltip(
                  <li
                    onClick={() => {
                      onChange(o.value, null, CostTypeModes.COST);
                    }}
                    onKeyDown={() => {
                      onChange(o.value, null, CostTypeModes.COST);
                    }}
                    data-disabled={o.disabled}
                    data-selected={o.value === costValue && isCost}
                    key={o.value}
                    id={o.value}
                  >
                    <span>{o.name}</span>
                  </li>,
                  o.tooltip,
                ),
              )}
        </>
      ) : null,
    },
  ];
  if (usageOptions) {
    options.push({
      id: 'usage',
      label: getLabel(isUsage ? usageOptionName : null, 'Usage'),
      selected: isUsage,
      icon: UsageIcon,
      content: (
        <>
          {usageOptions.map((o) => (
            <li
              onClick={() => {
                onChange(null, o.value, CostTypeModes.USAGE);
              }}
              onKeyDown={() => {
                onChange(null, o.value, CostTypeModes.USAGE);
              }}
              data-selected={o.value === usageValue && isUsage}
              key={o.value}
              id={o.value}
            >
              <span className="w-100">
                {o.name}
                {o.tooltip ? (
                  <span className="ms-auto">
                    <InfoPopover isSimple>{o.tooltip}</InfoPopover>
                  </span>
                ) : null}
              </span>
            </li>
          ))}
        </>
      ),
    });
  }
  if (!isRateDisabled) {
    options.push({
      id: 'rate',
      label: 'Rate',
      selected: isRate,
      icon: RateIcon,
      content: <RateBlock costUsageHandler={costUsageHandler} />,
    });
  }
  if (carbonEmissionsOptions?.length) {
    options.push({
      id: 'carbonEmissions',
      label: 'Carbon Emissions',
      selected: isCarbonEmissions,
      icon: CloudDotsIcon,
      color: palette.mint[600],
      content: carbonEmissionsOptions.map((o) => (
        <li
          onClick={() => {
            onChange(o.value, null, CostTypeModes.CARBON_EMISSIONS);
          }}
          onKeyDown={() => {
            onChange(o.value, null, CostTypeModes.CARBON_EMISSIONS);
          }}
          data-disabled={o.disabled}
          data-selected={o.value === carbonEmissionsValue && isCarbonEmissions}
          key={o.value}
          id={o.value}
          className={styles.carbonEmissionsValues}
        >
          <span>{o.name}</span>
        </li>
      )),
    });
  }
  return (
    <div className={styles.container}>
      <ButtonMultiDropdown
        dropdownProps={{
          text: labelByType[costTypeMode],
          icon: icons.menu.costTypeIcon,
          tooltipText: tooltipByType[costTypeMode],
          overrideMenuStyles: { minWidth: 200 },
        }}
        className={styles.dropDown}
        items={options}
        automationId="costTypeMenu"
      />
    </div>
  );
};

CostTypeMenu.propTypes = {
  costUsageHandler: PropTypes.object.isRequired,
};

export default CostTypeMenu;
