import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import moment from 'moment';
import { capitalize } from 'shared/utils/strUtil';
import {
  CLOUD_TYPE_IDS,
  DIVISION_TYPE_ID,
  INVALID_RESULT_STRING,
  mapParentUserTypeToDivisionUserTypeAndId,
  UsersType,
} from 'users/constants/usersConstants';
import DateFilter from 'shared/modules/dateFilter';
import LabelCoordinator from 'shared/modules/labelCoordinator';
import { initAprinsic, initSegment } from 'shared/modules/segmentAndAptrinsicHandler';
import { getValueFromStorage, parseJwt, setLocalStorage, useSessionStorage } from 'shared/utils/tokenUtil';
import { getRealmLocally } from 'shared/keycloak/keycloak.service';
import User from '../models/user';
import { Action, OrganizationEntityCategory } from '@anodot-cost/rbac-client';

class UsersStore {
  currentHoliday = null;
  usersModel = null;
  authUserKey = null;
  authUserType = null;
  welcomeScreenAnimationDone = false;
  currentDisplayedUserKey = null;
  deprecatedCurrentDisplayedUserType = UsersType.UN_AUTH_USER;
  currentDisplayedUserName = '';
  currDispUserTrialDaysLeft = null;
  currDispUserPricingPlanId = null;
  currDispUserAccountKey = null;
  currDispUserDivisionId = null;
  currDispUserDivisionName = null;
  currDispUserCloudAccountType = null;
  isUserStoreDataReq = true;
  isLoading = false;
  isUserSubscribedToMonday = false;
  currUserInitDone = false;
  // new user management fields
  permissions = [];

  constructor(rootStore, usersModel) {
    this.rootStore = rootStore;
    this.usersModel = usersModel;
    LabelCoordinator.setUsersStore(this);
    makeObservable(this, {
      isLoading: observable,
      isUserStoreDataReq: observable,
      authUserKey: observable,
      authUserType: observable,
      permissions: observable,
      welcomeScreenAnimationDone: observable,
      currentDisplayedUserKey: observable,
      currentDisplayedUserName: observable,
      currDispUserPricingPlanId: observable,
      currDispUserCloudAccountType: observable,
      currDispUserAccountKey: observable,
      currDispUserDivisionId: observable,
      currDispUserDivisionName: observable,
      currUserInitDone: observable,
      currDispUserTrialDaysLeft: observable,
      updateAutoAssignAccountProperty: action,
      updateResellerAccountConfig: action,
      disableAutoCreationOfCustomers: action,
      updateFilterOutMicrosoft365AccountProperty: action,
      updateExcludeLinkedAccountRule: action,
      setWelcomeScreenAnimationDone: action,
      updateCurrentDisplayedUserKey: action,
      updateCurrentDisplayedUser: action,
      updateCurrentDisplayedUserAccountKeyAndCloudId: action,
      updateCurrentAuthUser: action,
      initMainUser: action,
      fetchData: action,
      registerUser: action,
      updateLinkedAccounts: action,
      fetchUserLinkedAccounts: action,
      stopImpersonation: action,
      impersonateUser: action,
      // divisions
      updateCurrDisplayedDivIdAndName: action,
      deleteSubUser: action,
      updateCuaAlertsSendStatus: action,
      updatePermissions: action,
      getCurrDisplayedAccount: computed,
      getCurrUserName: computed,
      currentDisplaydUser: computed,
      getIsCurrentAccountAzureFocus: computed,
      getIsCurrentAccountAzureUnblended: computed,
      deprecatedCurrentDisplayedUserType: observable,
      isCurrentUserSuperAdmin: computed,
      isCurrentUserSystemAdmin: computed,
      isCurrentUserReseller: computed,
      // is current user a reseller shared customer
      isCurrentUserSharedReCustomer: computed,
      // is current user a dedicated (EDP) reseller customer
      isCurrentUserDedicatedReCustomer: computed,
      // is current user a shared or dedicated (EDP) reseller customer
      isCurrentUserReCustomer: computed,
      isCurrentUserNew: computed,
      userEndTrial: computed,
      isImpersonate: computed,
      trialDaysLeft: computed,
    });
  }
  get trialDaysLeft() {
    if (this.rootStore.appStore.isKeyCloakManagement) {
      const mainUser = this.getMainUser();
      if (!mainUser?.endTrial) {
        return 0;
      }
      const daysDiff = moment(mainUser.endTrial).diff(moment(), 'days');
      return daysDiff > 0 ? daysDiff : 0;
    }
    return this.currDispUserTrialDaysLeft;
  }
  get isImpersonate() {
    return !!(this.currentDisplayedUserKey && this.currentDisplayedUserKey !== this.authUserKey);
  }
  get userEndTrial() {
    if (this.rootStore.appStore.isKeyCloakManagement) {
      const mainUser = this.getMainUser();
      return mainUser?.endTrial;
    }
    return +this.currDispUserPricingPlanId === 2;
  }

  get getCurrentUser() {
    const user = this.getUserByKey(this.currentDisplayedUserKey);
    if (user) {
      return user;
    }
    return null;
  }

  get isCurrentUserSystemAdmin() {
    if (this.rootStore.appStore.isKeyCloakManagement) {
      /* TODO: check if the user is a system admin in the new user management
      didn't implement yet in keycloak */
      return false;
    }
    return +this.deprecatedCurrentDisplayedUserType === UsersType.SUPER_USER;
  }

  get isCurrentUserSuperAdmin() {
    const user = this.getUserByKey(this.currentDisplayedUserKey);
    if (this.rootStore.appStore.isKeyCloakManagement) {
      return !!user?.isSuperAdmin;
    }
    return user?.isAdmin;
  }
  get isCurrentUserReseller() {
    if (this.rootStore.appStore.isKeyCloakManagement) {
      const mainUser = this.getMainUser();
      return !!mainUser?.isReseller;
    }
    return +this.deprecatedCurrentDisplayedUserType === UsersType.RESELLER;
  }
  get isCurrentUserSharedReCustomer() {
    if (this.rootStore.appStore.isKeyCloakManagement) {
      const mainUser = this.getMainUser();
      const account = this.getCurrDisplayedAccount;
      const resellerAccountType = account?.resellerAccountType;
      return !!mainUser?.isCustomer && resellerAccountType !== 'dedicated';
    }
    return +this.deprecatedCurrentDisplayedUserType === UsersType.RESELLER_CUSTOMER;
  }
  get isCurrentUserDedicatedReCustomer() {
    if (this.rootStore.appStore.isKeyCloakManagement) {
      const mainUser = this.getMainUser();
      const account = this.getCurrDisplayedAccount;
      const resellerAccountType = account?.resellerAccountType;
      return !!mainUser?.isCustomer && resellerAccountType === 'dedicated';
    }
    return +this.deprecatedCurrentDisplayedUserType === UsersType.RESELLER_CUSTOMER_EDP;
  }
  get isCurrentUserReCustomer() {
    return this.isCurrentUserSharedReCustomer || this.isCurrentUserDedicatedReCustomer;
  }
  get isCurrentUserMSP() {
    if (this.rootStore.appStore.isKeyCloakManagement) {
      const mainUser = this.getMainUser();
      return !!mainUser?.isCustomer || !!mainUser?.isReseller;
    }
    return [
      UsersType.RESELLER_CUSTOMER_EDP,
      UsersType.RESELLER_CUSTOMER,
      UsersType.RESELLER,
      UsersType.RESELLER_BUSINESS_ADMIN,
    ].some((val) => val === this.deprecatedCurrentDisplayedUserType);
  }

  get isCurrentUserNew() {
    if (this.rootStore.appStore.isKeyCloakManagement) {
      const availableAccounts = this.getAllCurrDisplayedUserAccounts();
      return !availableAccounts.length;
    }
    return (
      +this.deprecatedCurrentDisplayedUserType === UsersType.NEW_USER ||
      +this.deprecatedCurrentDisplayedUserType === UsersType.USER_ON_BOARDED
    );
  }

  get accountInfo() {
    const user = this.getUserByKey(this.currentDisplayedUserKey);
    const changedUserAccount = user.getAccountByAccKey(this.currDispUserAccountKey);
    return changedUserAccount.accountInfo;
  }

  get getCurrUserKey() {
    return this.currentDisplayedUserKey;
  }

  get getCurrUserName() {
    return capitalize(this.currentDisplayedUserName);
  }

  isHasPermission = (category, action, legacyUserTypeList) => {
    if (this.rootStore.appStore.isKeyCloakManagement) {
      return this.permissions?.some(
        (permission) => permission.category === category && permission.actions.includes(action),
      );
    }
    return (
      !this.deprecatedCurrentUserReadOnly &&
      (!legacyUserTypeList || legacyUserTypeList.includes(this.deprecatedCurrentDisplayedUserType))
    );
  };

  get currentDisplaydUser() {
    return this.getUserByKey(this.getCurrUserKey);
  }

  get relatedOrganizationsTokensData() {
    return this.relatedOrganizationsTokens;
  }

  stopImpersonation = async () => {
    /* Use for new user management back to impersonate user */
    setLocalStorage('impersonationToken', '', useSessionStorage());
    this.rootStore.invalidateStores();
    this.rootStore.invalidateStoresLogOut();
    const authUserKey = getValueFromStorage('authUserKey');
    this.updateCurrentDisplayedUserKey(authUserKey);
    const userData = await this.signinWithToken();
    this.updateCurrentDisplayedUserName(userData.userName);
    this.updateCurrentAuthUser(authUserKey);
    await this.initMainUser();
    this.rootStore.fetchData(authUserKey);
  };

  impersonateUser = async ({ token, userId }) => {
    /* Use for new user management user impersonation */
    setLocalStorage('impersonationToken', token, useSessionStorage());
    this.rootStore.invalidateStores();
    this.rootStore.invalidateStoresLogOut();
    this.updateCurrentDisplayedUserKey(userId); // in the new user management, the user id stands for the old user management user key
    const userData = await this.signinWithToken();
    this.updateCurrentDisplayedUserName(userData.userName);
    const authUserKey = getValueFromStorage('authUserKey');
    this.updateCurrentAuthUser(authUserKey);
    await this.initMainUser();
    this.rootStore.fetchData(userId);
  };

  updatePermissions(permissions) {
    runInAction(() => {
      this.permissions = permissions;
    });
  }

  initCurrUserAttributes = (user) => {
    runInAction(() => {
      this.currUserInitDone = false;
    });
    if (!user) {
      return null;
    }
    runInAction(() => {
      this.deprecatedCurrentDisplayedUserType =
        this.currDispUserDivisionId > 0 && user.userType === UsersType.RESELLER
          ? UsersType.RESELLER_CUSTOMER
          : user.userType;
      this.currentDisplayedUserKey = user.userKey;
      this.currDispUserTrialDaysLeft = user.remainingTrialDays;
      this.currDispUserPricingPlanId = user.pricingPlanId;
      this.currUserName = user.userName;
      const sessionAccounKey =
        window.sessionStorage.getItem('currDispUserAccountKey') ||
        window.localStorage.getItem('currDispUserAccountKey');
      const currDispUserAccount = user.getDefaultUserAccount(sessionAccounKey, user.settings);
      if (currDispUserAccount) {
        const sessionDivisionId = window.localStorage.getItem('currDispUserDivisionId');
        const lastProcessTime = currDispUserAccount.getLastProcessTime();
        DateFilter.setDate(lastProcessTime);
        this.currDispUserAccountKey = currDispUserAccount.getAccKey();
        this.currDispUserDivisionId = sessionDivisionId ? +sessionDivisionId : currDispUserAccount.getDivisionId();
        this.currDispUserDivisionName = currDispUserAccount.getMainDivisionName();
        this.updateCurrentCloudAccountType(currDispUserAccount.getAccTypeId());
      }
      this.currUserInitDone = true;
    });
  };

  initMainUser = async () => {
    this.usersModel.setIsKeycloakManagement(!!getRealmLocally());
    const mainAuthUser = await this.fetchMainAuthUser();
    this.initCurrUserAttributes(mainAuthUser);
    await this.usersModel.processMainUserAndUpdateIfNeeded(); // specific checks
  };

  reInitMainUser = () => {
    const mainUser = this.getMainUser();
    this.initCurrUserAttributes(mainUser);
    initAprinsic(this.getUserDataForSegment(), mainUser.userHash);
    initSegment(this.getUserDataForSegment());
  };

  translateTypeToName = (tabObj, value) => {
    const keys = Object.keys(tabObj);
    const vals = Object.values(tabObj);
    const index = vals.indexOf(value);
    return keys[index];
  };

  getUserDataForSegment = () => ({
    userKey: this.currentDisplayedUserKey,
    email: this.currUserName,
    userName: this.currentDisplayedUserName,
    account: this.getCurrDisplayedAccount?.accountName,
    isMSP: this.isCurrentUserMSP,
    isReseller: this.isCurrentUserReseller,
    isResellerCustomer: this.isCurrentUserSharedReCustomer,
    isNew: this.isCurrentUserNew,
    accountType: this.translateTypeToName(CLOUD_TYPE_IDS, this.currDispUserCloudAccountType),
    accountId: this.getCurrDisplayedAccount?.accountId,
    companyName: (this.currentDisplaydUser && this.currentDisplaydUser?.companyName) || null,
  });

  getMainUser() {
    return this.usersModel.mainUser;
  }

  getCurrDispUserInfo(key) {
    const user = this.getUserByKey(key);
    if (!user) {
      return null;
    }
    const account = user.getDefaultUserAccount(null, user.settings);
    const userInfo = {
      name: user.getUserDisplayName(),
      theme: user.getCustomTheme(),
      isResellerMode: user.getIsResellerMode(),
      remainingTrialDays: user.remainingTrialDays,
      roleId: user.roleId,
      isAdmin: user.getIsAdmin(),
    };
    if (!account) {
      return userInfo;
    }
    return {
      ...userInfo,
      accountKey: account.getAccKey(),
      divisionId: account.getDivisionId(),
      divisionName: account.getMainDivisionName(),
      accountProvider: account.getAccTypeId(),
      currencyCode: account.getUserCurrencyCode(),
    };
  }

  getCurrDisplayedUserTheme = () => {
    const user = this.getCurrDispUserInfo(this.currentDisplayedUserKey);
    if (!user) {
      return null;
    }
    return user.theme;
  };

  getCurrDisplayedUserRole = () => {
    const user = this.getCurrDispUserInfo(this.currentDisplayedUserKey);
    if (!user) {
      return null;
    }
    return user.roleId;
  };

  getCurrDisplayedUserIsOrgAdmin = () => {
    const user = this.getCurrDispUserInfo(this.currentDisplayedUserKey);
    if (!user) {
      return null;
    }
    if (this.rootStore.appStore.isKeyCloakManagement) {
      return this.isHasPermission(OrganizationEntityCategory.Organization, Action.Update);
    }
    return user.isAdmin;
  };

  getUserCurrencyByAccountDetails = (acckey) => {
    const user = this.getUserByKey(this.currentDisplayedUserKey);
    const account = user.getAccountByAccKey(acckey);
    return account?.currencyCode || '';
  };

  getAllCurrDisplayedUserAccounts = () => {
    try {
      const currUser = this.getUserByKey(this.currentDisplayedUserKey);
      return currUser ? currUser.getAllAccounts() : [];
    } catch {
      return [];
    }
  };

  getAccountByAccountId = (accountId) => {
    const currUser = this.getUserByKey(this.currentDisplayedUserKey);
    return currUser?.getAccountByAccountId(accountId);
  };

  getDefaultFeaturesForNewUser = () => {
    try {
      const currUser = this.getUserByKey(this.currentDisplayedUserKey);
      return currUser?.defaultAccountFeatures || [];
    } catch {
      return [];
    }
  };

  getIsAzureAccountMigrationRequired = (accountId) => {
    return this.getAllCurrDisplayedUserAccounts()
      .filter((acc) => !accountId || acc.accountId === accountId)
      .some((account) => account.accountInfo.azure_interface_type === 'EA');
  };

  get getIsCurrentAccountAzureFocus() {
    return !!+this.getCurrDisplayedAccount?.accountInfo.is_focus;
  }

  get getIsCurrentAccountAzureUnblended() {
    return !!+this.getCurrDisplayedAccount?.accountInfo.is_unblended;
  }

  deleteAccountFromDisplayedUserAccounts = (account) => {
    const currUser = this.getUserByKey(this.currentDisplayedUserKey);
    if (currUser && account) {
      currUser.deleteAcc(account);
    }
  };

  updateAutoAssignAccountProperty = async (autoAssignLinkedAccounts) => {
    runInAction(() => {
      this.usersModel.users = this.usersModel.users.map((u) => {
        if (u.userKey !== this.currentDisplayedUserKey) {
          return u;
        }
        return {
          ...u,
          accounts: u.accounts.map((a) => {
            if (a.getAccKey() !== this.currDispUserAccountKey) {
              return a;
            }
            a.autoAssignLinkedAccounts = autoAssignLinkedAccounts;
            return a;
          }),
        };
      });
    });
    await this.usersModel.apiGateway.usersApi.updateAutoAssignAccountProperty(autoAssignLinkedAccounts);
  };

  updateResellerAccountConfig = async (data) => {
    runInAction(() => {
      this.usersModel.users = this.usersModel.users.map((u) => {
        if (u.userKey !== this.currentDisplayedUserKey) {
          return u;
        }
        return {
          ...u,
          accounts: u.accounts.map((a) => {
            if (a.getAccKey() !== this.currDispUserAccountKey) {
              return a;
            }
            a.excludedLinkedAccountMatch = data.excludedLinkedAccountMatch;
            a.resellerAccountType = data.accountType;
            a.autoAssignLinkedAccounts = data.autoAssignLinkedAccounts;
            return a;
          }),
        };
      });
    });
    await this.usersModel.apiGateway.usersApi.updateResellerAccountConfig(data);
  };

  disableAutoCreationOfCustomers = async () => {
    runInAction(() => {
      this.usersModel.users = this.usersModel.users.map((u) => {
        if (u.userKey !== this.currentDisplayedUserKey) {
          return u;
        }
        return {
          ...u,
          accounts: u.accounts.map((a) => {
            if (a.getAccKey() !== this.currDispUserAccountKey) {
              return a;
            }
            a.autoCreateCustomersPropertyName = '';
            return a;
          }),
        };
      });
    });
    await this.usersModel.apiGateway.usersApi.disableAutoCreationOfCustomers();
  };

  updateFilterOutMicrosoft365AccountProperty = async (accountId, filterOutMicrosoft365) => {
    runInAction(() => {
      this.usersModel.users = this.usersModel.users.map((u) => {
        if (u.userKey !== this.currentDisplayedUserKey) {
          return u;
        }
        return {
          ...u,
          accounts: u.accounts.map((a) => {
            if (a.getAccId() !== accountId) {
              return a;
            }
            a.filterOutMicrosoft365 = filterOutMicrosoft365;
            return a;
          }),
        };
      });
    });
    await this.usersModel.apiGateway.usersApi.updateFilterOutMicrosoft365AccountProperty(
      accountId,
      filterOutMicrosoft365,
    );
  };

  updateExcludeLinkedAccountRule = async (excludedLinkedAccountMatch) => {
    runInAction(() => {
      this.usersModel.users = this.usersModel.users.map((u) => {
        if (u.userKey !== this.currentDisplayedUserKey) {
          return u;
        }
        return {
          ...u,
          accounts: u.accounts.map((a) => {
            if (a.getAccKey() !== this.currDispUserAccountKey) {
              return a;
            }
            a.excludedLinkedAccountMatch = excludedLinkedAccountMatch;
            return a;
          }),
        };
      });
    });
    await this.usersModel.apiGateway.usersApi.updateExcludeLinkedAccountMatch(excludedLinkedAccountMatch);
  };

  get getCurrDisplayedAccount() {
    const currUser = this.getUserByKey(this.currentDisplayedUserKey);
    return currUser ? currUser.getAccountByAccKey(this.currDispUserAccountKey) : null;
  }

  getCurrDisplayedAccountId = () => {
    const currDisplayedAccount = this.getCurrDisplayedAccount;
    if (currDisplayedAccount) {
      return currDisplayedAccount.accountId;
    }

    return '-1';
  };

  getCurrAccountDivisionId = () => {
    const currUser = this.getUserByKey(this.currentDisplayedUserKey);
    const currAccount = currUser ? currUser.getAccountByAccKey(this.currDispUserAccountKey) : '';
    return currAccount ? currAccount.getDivisionId() : this.currDispUserDivisionId;
  };

  getCurrAccountTypeId = () => {
    const currUser = this.getUserByKey(this.currentDisplayedUserKey);
    return currUser ? currUser.getAccTypeIdByAcckey(this.currDispUserAccountKey) : '';
  };

  getCurrentDisplayedAccountId = (currDispUserAccountKey) => {
    const allUserAccounts = this.getAllCurrDisplayedUserAccounts();
    const currentAccount = allUserAccounts.find((acc) => acc.accountKey === currDispUserAccountKey) || null;
    if (currentAccount) {
      return currentAccount.accountId;
    }
    return '';
  };

  getCurrentDisplayedAccountFeatures = (currDispUserAccountKey) => {
    const allUserAccounts = this.getAllCurrDisplayedUserAccounts();
    if (!allUserAccounts.length) {
      return this.getDefaultFeaturesForNewUser();
    }
    const currentAccount =
      allUserAccounts.length > 0 ? allUserAccounts.filter((acc) => acc.accountKey === currDispUserAccountKey) : [];
    if (currentAccount.length) {
      return currentAccount[0].features;
    }
    return [];
  };

  getCurrentDisplayedAccountName = (currDispUserAccountKey) => {
    const allUserAccounts = this.getAllCurrDisplayedUserAccounts();
    const currentAccount =
      allUserAccounts.length > 0 ? allUserAccounts.filter((acc) => acc.getAccKey() === currDispUserAccountKey) : [];
    if (currentAccount.length) {
      return currentAccount[0].accountName;
    }
    return '';
  };

  getUserByKey(key) {
    const userAsArr = this.usersModel.users.filter((user) => user.userKey === key);
    return userAsArr[0];
  }

  getCloudAccountData = async (accountId, cloudTypeId) => {
    try {
      const data = await this.usersModel.apiGateway.getCloudAccountData(accountId, cloudTypeId);
      return data;
    } catch {
      return {};
    }
  };

  getUserDataByAccount = (accountId) => this.usersModel.apiGateway.getUserDataByAccount(accountId);

  getIsUserSubscribedToMondayWebhook = async () => {
    const isUserSubscribed = await this.usersModel.getIsUserSubscribedToMondayWebhook();
    runInAction(() => {
      this.isUserSubscribedToMonday = isUserSubscribed;
    });
  };

  deleteAccount = async (accountSelectedForDelete) => {
    try {
      this.deleteAccountFromDisplayedUserAccounts(accountSelectedForDelete);
      const { accountId, cloudTypeId } = accountSelectedForDelete;
      await this.usersModel.apiGateway.deleteAccount(accountId, cloudTypeId);
      await this.usersModel.fetchUser();
      // eslint-disable-next-line no-empty
    } catch {}
  };

  updateCurrentDisplayedUserKey = (userKey) => {
    this.currentDisplayedUserKey = userKey;
    window.localStorage.setItem('dispUserKey', userKey);
    window.sessionStorage.setItem('dispUserKey', userKey);
  };

  updateCurrentDisplayedUserName = (name) => {
    this.currentDisplayedUserName = name;
  };

  updateCurrentCloudAccountType = (cloudAccType) => {
    this.currDispUserCloudAccountType = cloudAccType;
    LabelCoordinator.setCloudType(this.currDispUserCloudAccountType);
  };

  updateCurrentDisplayedUserAccount = async (accountId, accountKey, newAccountName, newRoleARN) => {
    const currUser = this.getUserByKey(this.currentDisplayedUserKey);
    if (currUser) {
      currUser.updateAccountName(accountKey, newAccountName);
      await this.usersModel.apiGateway.updateUserAccount(accountId, accountKey, newAccountName, newRoleARN);
      await this.usersModel.fetchUser();
    }
  };

  updateCurrDisplayedDivIdAndName = (divisionId, divisionName, accountKey, forceFetch = true) => {
    this.currDispUserDivisionId = divisionId;
    this.currDispUserDivisionName = divisionName;
    window.localStorage.setItem('currDispUserDivisionId', divisionId);
    if (!this.rootStore.appStore.isKeyCloakManagement) {
      this.currDispUserAccountKey = accountKey;
    }
    if (forceFetch) {
      this.rootStore.fetchData(this.currentDisplayedUserKey, false);
    }
  };

  updateCurrDisplayedDivisionId = (divisionId) => {
    const numericNewDivisionId = this.rootStore.appStore.isKeyCloakManagement ? divisionId : +divisionId;
    this.currDispUserDivisionId = numericNewDivisionId;
    window.localStorage.setItem('currDispUserDivisionId', numericNewDivisionId);
  };

  updateCurrentDisplayedUser = async (userKey) => {
    this.updateCurrentDisplayedUserKey(userKey);
    const account = this.getCurrDispUserInfo(userKey);
    this.currentDisplayedUserName = account.name;
    this.currDispUserTrialDaysLeft = this.getUserByKey(userKey).remainingTrialDays;
    this.currDispUserPricingPlanId = this.getUserByKey(userKey).pricingPlanId;
    this.deprecatedCurrentDisplayedUserType = this.deprecatedGetNewUserType(userKey);
    this.currDispUserAccountKey = account.accountKey;
    this.currDispUserDivisionId = account.divisionId;
    this.currDispUserDivisionName = account.divisionName;
    this.updateCurrentCloudAccountType(account.accountProvider);
  };

  updateCurrentDisplayedUserAccountKeyAndCloudId = (numericNewAccKey, cloudId) => {
    this.currDispUserAccountKey = numericNewAccKey;
    if (cloudId !== undefined) {
      this.updateCurrentCloudAccountType(cloudId);
    }
    const currUser = this.getUserByKey(this.currentDisplayedUserKey);
    this.currDispUserDivisionId = currUser.getAccountByAccKey(numericNewAccKey).getDivisionId();
    this.currDispUserDivisionName = currUser.getAccountByAccKey(numericNewAccKey).getMainDivisionName();
    this.deprecatedCurrentDisplayedUserType = currUser.getUserType();
    this.usersModel.userLinkedAccounts = undefined;
  };

  updateCurrentDisplayedUserLogIn = (userKey) => {
    this.updateCurrentDisplayedUserKey(userKey);
    this.currentDisplayedUserName = this.getUserByKey(userKey).userDisplayName;
    this.deprecatedCurrentDisplayedUserType ??= this.getUserByKey(userKey).userType;
    this.currDispUserTrialDaysLeft = this.getUserByKey(userKey).remainingTrialDays;
    this.currDispUserPricingPlanId = this.getUserByKey(userKey).pricingPlanId;
    this.currDispUserAccountKey ??= this.getCurrDispUserInfo(userKey).accountKey;
    this.currDispUserDivisionId ??= this.getCurrDispUserInfo(userKey).divisionId;
    this.currDispUserDivisionName = this.getCurrDispUserInfo(userKey).divisionName;
    this.updateCurrentCloudAccountType(this.getCurrDispUserInfo(userKey).accountProvider);
  };

  updateCurrentAuthUser = (userKey) => {
    this.authUserKey = userKey;
    window.localStorage.setItem('authUserKey', userKey);
  };

  getAllCustomerDivisionUserAccounts = () => {
    try {
      const currUser = this.getUserByKey(this.currentDisplayedUserKey);
      return currUser?.getAllCustomerDivisions();
    } catch {
      return [];
    }
  };

  handleDisplayedUserChange = async (userKey) => {
    try {
      this.rootStore.appStore.setIsAccountLoading(true);
      await this.deprecatedFetchSubUserDetails(userKey);
      const user = this.getUserByKey(userKey);
      const currDispUserAccount = user.getDefaultUserAccount(null, user.settings);
      const { lastProcessTime = new Date(null) } = currDispUserAccount || {};
      DateFilter.setDate(lastProcessTime);
      this.updateCurrentDisplayedUser(userKey);
      this.usersModel.userLinkedAccounts = undefined;
      this.rootStore.invalidateStores();
      this.rootStore.fetchData(this.currentDisplayedUserKey);
      runInAction(() => {
        this.rootStore.appStore.setIsAccountLoading(false);
      });
    } catch {
      runInAction(() => {
        this.rootStore.appStore.setIsAccountLoading(false);
      });
    }
  };

  handleDisplayedAccountChange = async (newKey, cloudId) => {
    const numericNewAccKey =
      this.rootStore.appStore.isKeyCloakManagement || Object.prototype.toString.call(newKey) === '[object Number]'
        ? newKey
        : +newKey;
    if (numericNewAccKey !== this.currDispUserAccountKey) {
      window.localStorage.removeItem('currDispUserDivisionId');
      window.localStorage.removeItem('isPpApplied');
      window.localStorage.setItem('currDispUserAccountKey', numericNewAccKey);
      const user = this.getUserByKey(this.currentDisplayedUserKey);
      if (!user || !user.getAccountByAccKey(numericNewAccKey)) {
        return;
      }
      const changedUserAccount = user.getAccountByAccKey(numericNewAccKey);
      const { lastProcessTime = new Date(null) } = changedUserAccount || {};
      DateFilter.setDate(lastProcessTime);
      this.updateCurrentDisplayedUserAccountKeyAndCloudId(numericNewAccKey, cloudId);
      this.rootStore.invalidateStores(false);
      // In order not to delay the page load, we fetchData after a timeout
      setTimeout(() => {
        this.rootStore.fetchData(this.currentDisplayedUserKey);
      }, 500);
    }
  };

  handleBackToCurrDisplayedAccount = () => {
    const user = this.getUserByKey(this.currentDisplayedUserKey);
    const { divisionId, mainDivisionName } = user.getAccountByAccKey(this.currDispUserAccountKey);
    this.deprecatedSetUserType(user.userType);
    this.rootStore.invalidateStores(true, true);
    this.updateCurrDisplayedDivIdAndName(divisionId, mainDivisionName, this.currDispUserAccountKey);
    this.fetchDivisionGroups();
  };

  registerUser = async (username, displayName, password) => {
    this.isLoading = true;
    try {
      const result = await this.usersModel.registerUser(username, displayName, password);
      runInAction(() => {
        this.isLoading = false;
      });
      return result;
    } catch (error) {
      this.state = `error: ${error}`;
    }

    return null;
  };

  isFetchRequired = () => {
    let isRequired = true;
    if (this.isCurrentUserNew) {
      isRequired = false;
    }
    return isRequired;
  };

  fetchUserLinkedAccounts = async () => {
    const mainUser = this.getMainUser();
    if (mainUser) {
      const result = await this.usersModel.fetchUserLinkedAccounts(
        this.currDispUserCloudAccountType,
        this.getCurrDisplayedAccountId(),
      );
      return result;
    }
    return false;
  };

  signinWithToken = async (selectedRole = null) => {
    const userInfo = await this.usersModel.signinWithToken(selectedRole);
    return userInfo;
  };

  setExternalUserNameToUserKey = async (externalUserName, userKey) => {
    const result = await this.usersModel.setExternalUserNameToUserKey(externalUserName, userKey);
    return result;
  };

  getUserKeyFromUserName = async (userName) => {
    const userKey = await this.usersModel.getUserKeyFromUserName(userName);
    return userKey;
  };

  fetchAvailableLinkedAccounts = async () => {
    const mainUser = this.getMainUser();
    if (mainUser) {
      const data = await this.usersModel.fetchAvailableLinkedAccounts();
      if (data) {
        return data;
      }
    }
    return false;
  };

  fetchAvailableDivisionLinkedAccounts = async () => {
    const mainUser = this.getMainUser();
    if (mainUser) {
      const data = await this.usersModel.fetchAvailableDivisionLinkedAccounts();
      if (data) {
        return data;
      }
    }
    return false;
  };

  updateLinkedAccounts = async (linkedAccounts) => {
    const mainUser = this.getMainUser();
    if (mainUser) {
      const result = await this.usersModel.updateLinkedAccounts(linkedAccounts);
      return result;
    }
    return {};
  };

  fetchMainAuthUser = async () => {
    let mainAuthuser = null;
    try {
      mainAuthuser = await this.usersModel.fetchUser();
    } catch {
      this.state = 'error';
    }
    return mainAuthuser;
  };

  resendSignUp = async (username) => {
    const result = await this.usersModel.resendSignUp(username);
    return result;
  };

  fetchUserNotifications = async () => {
    try {
      await this.usersModel.fetchUserNotifications();
    } catch {
      this.state = 'error';
    }
    return this.usersModel.usersNotifications;
  };

  // user general details form

  updateUserNotificationSettings = async (notificationInputsState, isShowCreateReport) => {
    try {
      await this.usersModel.updateUserNotificationSettings(notificationInputsState, isShowCreateReport);
      return true;
    } catch {
      this.state = 'error';
    }
    return false;
  };

  updateCuaAlertsSendStatus = async (newSendStatus) => {
    const result = await this.usersModel.updateCuaAlertsSendStatus(newSendStatus);
    return result;
  };

  updateUserGeneralData = (firstName, lastName, jobTitle) =>
    this.usersModel.updateUserGeneralData(this.currentDisplayedUserKey, firstName, lastName, jobTitle);

  fetchData = async (currDispUserKey, isForceFetch = false) => {
    if (this.isUserStoreDataReq) {
      this.isLoading = true;
      try {
        if (!this.rootStore.appStore.isKeyCloakManagement) {
          await this.usersModel.fetchData(currDispUserKey, isForceFetch);
        }
        runInAction(() => {
          this.updateCurrentDisplayedUserLogIn(currDispUserKey);
          this.reInitMainUser();
          this.isUserStoreDataReq = false;
          this.isLoading = false;
          this.currUserInitDone = true;
        });
      } catch {
        this.state = 'error';
      }
    }
    runInAction(() => {
      this.isLoading = false;
      this.currUserInitDone = true;
    });
  };

  updateUserRole = async (users) => {
    const result = await this.usersModel.updateUserRole(users);
    await this.deprecatedFetchSubUsers();
    return result;
  };
  isRoleHasUsersAttached = (roleId) => {
    let result = true;
    result = this.usersModel.isRoleInSubUsers(roleId);
    return result;
  };
  createNewUsersInRole = async (emails, userRole, isAdminUser, overrideReadOnly) => {
    const divisionTypeId = DIVISION_TYPE_ID.USER;
    const userType = this.deprecatedGetNewUserUserType(isAdminUser, this.deprecatedCurrentDisplayedUserType);
    const result = await this.usersModel.createNewUsersInRole(
      emails,
      userRole,
      divisionTypeId,
      userType,
      overrideReadOnly,
    );
    return result;
  };
  createNewCustomerSubUser = async (email, customerName, divisionId, accountKey, roleId) => {
    const { userType, divisionTypeId } = mapParentUserTypeToDivisionUserTypeAndId.get(
      this.deprecatedCurrentDisplayedUserType,
    );
    const currDisplayedAccount = this.getCurrDisplayedAccount;
    try {
      const result = await this.usersModel.createNewCustomerSubUser(
        email,
        customerName,
        divisionId,
        divisionTypeId,
        userType,
        currDisplayedAccount,
        roleId,
      );
      return result;
    } catch {
      return false;
    }
  };

  deleteSubUser = async (divUserKey, divisionId, userName, divisionTypeId, accountKey) => {
    const result = await this.usersModel.deleteSubUser(divUserKey, accountKey, userName, divisionTypeId);
    return result;
  };

  getSubUsersListByDisplayedType = () => {
    const type = this.usersModel.mapUserTypeToSubUsers.get(this.deprecatedCurrentDisplayedUserType);
    let list = [];
    if (type === 'costCenter') {
      list = this.usersModel.getCostCentersSubUsers;
    } else if (type === 'customer') {
      list = (this.usersModel.getCustomersSubUsers || []).filter(
        (cus) => cus.awsAccountId === this.getCurrDisplayedAccountId(),
      );
    }
    return list || [];
  };

  // Division Groups **************************************
  isAccountConnectedToDivisions = (accountId) => {
    const result = this.usersModel.isAccountConnectedToDivisions(accountId);
    return result;
  };
  fetchDivisionGroups = async (mapUpdate) => {
    if (this.rootStore.appStore.isKeyCloakManagement) {
      return;
    }
    const data = await this.usersModel.fetchDivisionGroups(mapUpdate);
    if (data) {
      return data;
    }
    return false;
  };

  createNewDivisionGroup = async (divGroupName, linkedAccounts, newDivision) => {
    const { divisionTypeId } = mapParentUserTypeToDivisionUserTypeAndId.get(this.deprecatedCurrentDisplayedUserType);
    await this.usersModel.createNewDivisionGroup(
      divGroupName,
      linkedAccounts,
      newDivision,
      this.currDispUserAccountKey,
      divisionTypeId,
    );
    await this.fetchDivisionGroups();
  };

  deleteDivisionGroup = async (divisionId, divisionName) => {
    const result = await this.usersModel.deleteDivisionGroup(divisionId, divisionName);
    await this.fetchAvailableDivisionLinkedAccounts();
    await this.fetchDivisionGroups();
    return result;
  };
  updateLocalModelDivisionName = (divId, newName) => {
    const { divisionGroups } = this.usersModel;
    const editedDivisionIndex = divisionGroups.findIndex((x) => x.divisionId === divId);
    if (editedDivisionIndex > -1) {
      divisionGroups[editedDivisionIndex].divisionNameDisplay = newName;
      return divisionGroups[editedDivisionIndex].divisionName;
    }
    return null;
  };
  updateLocalModelDivision = (divId, newName, newCode) => {
    const { divisionGroups } = this.usersModel;
    const editedDivisionIndex = divisionGroups.findIndex((x) => x.divisionId === divId);
    if (editedDivisionIndex > -1) {
      divisionGroups[editedDivisionIndex].divisionNameDisplay = newName;
      divisionGroups[editedDivisionIndex].divisionCode = newCode;
      return divisionGroups[editedDivisionIndex].divisionName;
    }
    return null;
  };
  updateDivision = async (divisionId, newDivDisplayName, newDivCode) => {
    const divisionName = this.updateLocalModelDivision(divisionId, newDivDisplayName, newDivCode);
    if (divisionName) {
      const divNameToDisplay = new Map([[divisionName, newDivDisplayName]]);
      LabelCoordinator.addNewKeyToDisplayAndReverseMapValues('cueDisplayCoordinator', divNameToDisplay);
    }
    const result = await this.usersModel.updateDivisionGroup(divisionId, newDivDisplayName, newDivCode);
    return result;
  };
  updateDivisionGroupName = async (divisionId, newDivDisplayName) => {
    const divisionName = this.updateLocalModelDivisionName(divisionId, newDivDisplayName);
    if (divisionName) {
      // add the new display value to the display map
      const divNameToDisplay = new Map([[divisionName, newDivDisplayName]]);
      LabelCoordinator.addNewKeyToDisplayAndReverseMapValues('cueDisplayCoordinator', divNameToDisplay);
    }
    const result = await this.usersModel.updateDivisionGroupName(divisionId, newDivDisplayName);
    return result;
  };

  updateDivisionGroupLinkAccounts = async (divId, linkedAccs, divisionName, divisionCode, divisionTypeId, roleId) => {
    const result = await this.usersModel.updateDivisionGroupLinkAccounts(
      divId,
      linkedAccs,
      divisionName,
      divisionCode,
      divisionTypeId,
      roleId,
    );
    return result;
  };
  updateAvailableDivisionLinkAccountLocally = (linkedAccountIds) => {
    this.usersModel.updateAvailableDivisionLinkAccountLocally(linkedAccountIds);
  };
  getDivisionValues = () => [...this.usersModel.getDivisionValues()];
  getDivisionValuesByPayerAccount = (accountId) => [...this.usersModel.getDivisionValuesByPayerAccount(accountId)];
  getDivisionIdByDivName = (name) => this.usersModel.getDivisionIdByDivName(name);
  getDivisionNameByDivId = (id) => this.usersModel.getDivisionNameByDivId(id);

  saveAzureOnBoardingAppRegistrationConfiguration = async (appConfiguration) => {
    const result = await this.usersModel.apiGateway.saveAzureOnBoardingAppRegistrationConfiguration(appConfiguration);
    return result;
  };
  deleteAppRegistrationConfiguration = async (data) => {
    const result = await this.usersModel.apiGateway.deleteAppRegistrationConfiguration(data);
    return result;
  };
  updateAppName = async (data) => {
    const result = await this.usersModel.apiGateway.updateAppName(data);
    return result;
  };
  getAzureRegisteredApps = async () => {
    const result = await this.usersModel.apiGateway.getAzureRegisteredApps();
    return result;
  };

  // PREFERENCES

  invalidateDivisionGroups = () => {
    this.usersModel.invalidateDivisionGroups();
  };

  invalidateTargetGoals = () => {
    this.usersModel.invalidateTargetGoals();
  };
  setWelcomeScreenAnimationDone = () => {
    this.welcomeScreenAnimationDone = true;
  };

  invalidateStore = () => {
    this.usersModel.invalidateData();
    this.authUserKey = null;
    this.authUserType = null;
    this.updateCurrentDisplayedUserKey(null);
    this.currDispUserAccountKey = null;
    this.currDispUserDivisionId = null;
    this.currDispUserDivisionName = null;
    this.currDispUserCloudAccountType = null;
    this.deprecatedCurrentDisplayedUserType = UsersType.UN_AUTH_USER;
    this.currentDisplayedUserName = '';
    this.isUserStoreDataReq = true;
    this.currDispUserTrialDaysLeft = null;
    this.isLoading = true;
    this.currUserInitDone = false;
    this.welcomeScreenAnimationDone = false;
    this.permissions = [];
  };

  // Deprecated - Legacy UM

  get deprecatedGetCurrentDisplayedUserType() {
    if (this.rootStore.appStore.isKeyCloakManagement) {
      return undefined;
    }
    return this.deprecatedCurrentDisplayedUserType;
  }

  get deprecatedCurrentUserReadOnly() {
    if (this.rootStore.appStore.isKeyCloakManagement) {
      return undefined;
    }
    const user = this.getUserByKey(this.currentDisplayedUserKey);
    return (user || {}).isReadOnly;
  }

  deprecatedChangeCustomerUserType = () => {
    if (this.rootStore.appStore.isKeyCloakManagement) {
      return;
    }
    if (this.deprecatedCurrentDisplayedUserType === UsersType.RESELLER) {
      this.deprecatedSetUserType(UsersType.RESELLER_CUSTOMER);
    }
  };
  deprecatedSetUserType = (userType) => {
    if (this.rootStore.appStore.isKeyCloakManagement) {
      return;
    }
    this.deprecatedCurrentDisplayedUserType = userType;
  };

  deprecatedGetNewUserType = (newUserKey) => {
    if (this.rootStore.appStore.isKeyCloakManagement) {
      return undefined;
    }
    const authUserType = this.getUserByKey(this.authUserKey).userType;
    const newUser = this.getUserByKey(newUserKey);
    const { userType, isReadOnly } = newUser;
    if (authUserType === UsersType.USER && isReadOnly) {
      return authUserType;
    }
    return userType;
  };

  deprecetedCreatePileusUser = async (username) => {
    if (this.rootStore.appStore.isKeyCloakManagement) {
      return;
    }
    this.isLoading = true;
    try {
      const result = await this.usersModel.createPileusUser(username);
      runInAction(() => {
        this.isLoading = false;
      });
      return result;
    } catch (error) {
      this.state = `error: ${error}`;
    }

    return null;
  };

  deprecatedConfirmUser = async (
    firstName,
    lastName,
    jobTitle,
    companyName,
    username,
    displayName,
    password,
    codeConfirmation,
    providerId,
    campaignId,
    messageId,
    isMSP,
    theme,
  ) => {
    if (this.rootStore.appStore.isKeyCloakManagement) {
      return;
    }
    const confirmResult = { result: true, msg: '' };
    try {
      let userKey = '';
      // make server API call instead of direct calling AWS
      const user = await this.usersModel.signIn(username, password);
      const { jwtToken: authToken, username: externalUserName } = user || {};
      userKey = externalUserName || '';
      // setting the initial displayed Account user to logged in user
      const decoded = parseJwt(authToken);
      setLocalStorage('authToken', authToken, decoded['custom:useSessionStorage'] === '1');
      this.updateCurrentAuthUser(userKey);
      this.updateCurrentDisplayedUserKey(userKey);
      await this.usersModel.createPileusUser(
        firstName,
        lastName,
        jobTitle,
        companyName,
        username,
        displayName,
        userKey,
        providerId,
        campaignId,
        messageId,
        isMSP,
        theme,
      );
    } catch (error) {
      confirmResult.result = false;
      confirmResult.msg = INVALID_RESULT_STRING;
      this.state = `error: ${error}`;
    }
    return confirmResult;
  };

  deprecatedGetNewUserUserType = (isAdminUser, deprecatedCurrentDisplayedUserType) => {
    if (this.rootStore.appStore.isKeyCloakManagement) {
      return undefined;
    }
    if (
      +deprecatedCurrentDisplayedUserType === UsersType.RESELLER_CUSTOMER_EDP ||
      +deprecatedCurrentDisplayedUserType === UsersType.RESELLER_CUSTOMER
    ) {
      return deprecatedCurrentDisplayedUserType;
    }
    if (!isAdminUser && +deprecatedCurrentDisplayedUserType !== UsersType.RESELLER) {
      return null;
    }
    return deprecatedCurrentDisplayedUserType;
  };

  // Sub Users **************************************

  deprecatedFetchSubUserDetails = async (userKey) => {
    const user = this.getUserByKey(userKey);
    if (user.accounts && user.accounts.length > 0) {
      // means that this user was fetched already
      return;
    }
    const data = await this.usersModel.fetchSubUserDetails(userKey);
    const newUser = new User({ ...data, isKeycloakManagement: !!this.rootStore.appStore.isKeyCloakManagement });
    this.usersModel.replaceUser(newUser);
  };

  deprecatedFetchSubUsers = async () => {
    const mainUser = this.getMainUser();
    if (mainUser) {
      const data = await this.usersModel.fetchSubUsers();
      if (data) {
        return data;
      }
    }
    return false;
  };
  invalidateDeprecatedSubUsers = () => {
    this.usersModel.invalidateSubUsers();
  };

  // End of Deprecated - Legacy UM
}
export default UsersStore;
