import moment from 'moment';
import getSymbolFromCurrency from 'currency-symbol-map';
import { CURRENCY_DETAILS } from 'shared/constants/appConstants';
import { mapCloudTypeIdToDescription } from 'users/constants/usersConstants';

export const CLOUD_ACCOUNTS_TABLE_COLUMNS = [
  { name: 'cloudTypeId', title: 'Cloud Type', getCellValue: (row) => mapCloudTypeIdToDescription.get(row.cloudTypeId) },
  { name: 'accountName', title: 'Account Name' },
  { name: 'accountId', title: 'Account ID' },
  {
    name: 'lastProcessTime',
    title: 'Last Processed',
  },
  {
    name: 'creationDate',
    title: 'Creation Date',
    getCellValue: (row) => (row.dbCreationTime ? moment(row.dbCreationTime).format('MMMM DD YYYY') : ''),
  },
  {
    name: 'details',
    title: 'Details',
    getCellValue: (row) => JSON.stringify(row.accountInfo),
  },
  {
    name: 'currencyCode',
    title: 'Currency',
    getCellValue: (row) => {
      if (row.onboarding) {
        return null;
      }
      return `${row.currencyCode || CURRENCY_DETAILS.USD} (${getSymbolFromCurrency(
        row.currencyCode || CURRENCY_DETAILS.USD,
      )})`;
    },
  },
  // TODO: enable when backend ready
  // {
  //   name: 'isFilterOutMicrosoft365',
  //   title: (
  //     <>
  //       Exclude Micro...
  //       <span style={{ pointerEvents: 'all' }}>
  //         <InfoPopover isSimple placement="top">
  //           <p>Exclude Microsoft Business Solutions</p>
  //           <p>Exclude from the invoice data all the records with the following FamilyTypes:</p>
  //           <ul>
  //             <li>Office 365 Global</li>
  //             <li>CRM</li>
  //             <li>Windows Commercial</li>
  //             <li>Windows Intune</li>
  //             <li>Software Subscription</li>
  //             <li>Not Available</li>
  //           </ul>
  //         </InfoPopover>
  //       </span>
  //     </>
  //   ),
  //   cloudTypeIds: [CLOUD_TYPE_IDS.AZURE],
  // },
  { name: 'isDefaultAcc', title: 'Default' },
  { name: 'edit', title: 'Edit' },
  { name: 'delete', title: 'Delete' },
];

export const CLOUD_ACCOUNTS_TABLE_COLUMN_WIDTHS = [
  { columnName: 'creationDate', width: 150 },
  { columnName: 'lastProcessTime', width: 150 },
  { columnName: 'accountId', width: 200 },
  { columnName: 'accountName', width: 200 },
  { columnName: 'details', width: 220, sortingEnabled: false },
  { columnName: 'cloudTypeId', width: 120 },
  { columnName: 'isDefaultAcc', width: 80, sortingEnabled: false },
  { columnName: 'isFilterOutMicrosoft365', sortingEnabled: false },
  { columnName: 'delete', width: 80, sortingEnabled: false },
  { columnName: 'edit', width: 80, sortingEnabled: false },
  { columnName: 'currencyCode', width: 80, sortingEnabled: false },
];
export const CLOUD_ACCOUNTS_DEFAULT_SORTING = [
  { columnName: 'onboarding', direction: 'asc' },
  { columnName: 'isMCAMigrationNeeded', direction: 'desc' },
  { columnName: 'cloudTypeId', direction: 'asc' },
  { columnName: 'accountId', direction: 'asc' },
];
