import { API } from 'shared/utils/apiMiddleware';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import apiConstants from 'shared/api/apiConstants';
import { createReportObject } from 'usage/utils/reportsUtil';
import { prepareNewRecipients } from 'shared/utils/sharedUtils';
import { dateToStr } from 'shared/utils/dateUtil';
import { v1 as uuidv1 } from 'uuid';

const root = '/api/v1/usage/reports';

const fetchReportsApi = async (userKey) => {
  const result = await API.get('billings', `${root}/all`);
  return result.map((report) => createReportObject(userKey, report));
};

const saveAssetReportApi = async ({
  id,
  type,
  name,
  email,
  recipients,
  reportFreq,
  savedState,
  mailDeliveryFrequency,
  mailFrequencyStartDate,
  frequencyDeliveryTime,
  sourceKey,
}) => {
  const body = {
    type,
    name,
    creationDate: dateToStr(new Date(), 'yyyy-mm-dd hh:mm:ss'),
    mailParams: {
      email,
      reportFreq,
      frequencyDeliveryTime,
      customFrequencyInDays: mailDeliveryFrequency,
      firstDate: mailFrequencyStartDate,
    },
    recipients: prepareNewRecipients({ email, recipients }),
    mapReportSavedParams: savedState,
    sourceKey,
  };
  const result = await API.post('billings', `${root}/save/asset/${id || uuidv1()}`, { body });
  return result;
};

const saveUserReportApi = async ({
  id,
  type,
  name,
  email,
  recipients,
  reportFreq,
  savedState,
  isPpApplied,
  mailDeliveryFrequency: saveModalCustomMailDeliveryFrequency = null,
  mailFrequencyStartDate: saveModalCustomMailFrequencyStartDate = null,
  frequencyDeliveryTime = null,
  isEdit = false,
}) => {
  const body = {
    type,
    name,
    mailParams: {
      email,
      reportFreq,
      frequencyDeliveryTime,
      customFrequencyInDays: saveModalCustomMailDeliveryFrequency,
      firstDate: saveModalCustomMailFrequencyStartDate,
    },
    recipients: prepareNewRecipients({ email, recipients }),
    creationDate: dateToStr(new Date(), 'yyyy-mm-dd hh:mm:ss'),
    isPpApplied,
    mapReportSavedParams: savedState,
    isEdit,
  };
  const result = await API.post('billings', `${root}/save/cue/${id || uuidv1()}`, { body });
  return result;
};

const saveCustomDashboardReportApi = async ({
  id,
  type,
  name,
  email,
  recipients,
  reportFreq,
  mailDeliveryFrequency,
  mailFrequencyStartDate,
  frequencyDeliveryTime,
  sourceKey,
}) => {
  const body = {
    type,
    name,
    creationDate: dateToStr(new Date(), 'yyyy-mm-dd hh:mm:ss'),
    mailParams: {
      email,
      reportFreq,
      frequencyDeliveryTime,
      customFrequencyInDays: mailDeliveryFrequency,
      firstDate: mailFrequencyStartDate,
    },
    recipients: prepareNewRecipients({ email, recipients }),
    sourceKey,
  };
  const result = await API.post('billings', `${root}/save/custom-dashboard/${id || uuidv1()}`, { body });
  return result;
};

const deleteReportApi = async ({ id }) => {
  await API.del('billings', `${root}/delete/${id}`);
};

const sendReportApi = (sendReport) => {
  const {
    id,
    type,
    name,
    email,
    recipients,
    reportFreq,
    frequencyDeliveryTime,
    customFrequencyInDays: saveModalCustomMailDeliveryFrequency,
    firstDate: saveModalCustomMailFrequencyStartDate,
    creationDate,
    isPpApplied,
    savedState,
    sourceKey,
    reportType,
  } = sendReport;
  const dataPrepared = {
    id,
    type,
    name,
    mailParams: {
      email,
      reportFreq,
      frequencyDeliveryTime,
      customFrequencyInDays: saveModalCustomMailDeliveryFrequency,
      firstDate: saveModalCustomMailFrequencyStartDate,
    },
    recipients: prepareNewRecipients({ email, recipients }),
    creationDate,
    isPpApplied,
    sourceKey,
    reportType,
    mapReportSavedParams: savedState,
  };
  const query = `${root}/send/${sendReport.id}`;
  const result = API.post('billings', query, { body: dataPrepared });
  return result;
};

export const useReports = () => {
  const queryClient = useQueryClient();
  const queryKey = [apiConstants.QUERY_KEYS.REPORTS];
  const onSuccess = () => {
    queryClient.invalidateQueries(queryKey);
  };
  return {
    fetchReports: (userKey, accountKey) =>
      useQuery([...queryKey, userKey, accountKey], () => fetchReportsApi(userKey), { staleTime: Infinity }),
    useSaveCueReport: () => useMutation(queryKey, saveUserReportApi, { onSuccess }),
    useSaveCustomDashboardReport: () => useMutation(queryKey, saveCustomDashboardReportApi, { onSuccess }),
    useSaveAssetReport: () => useMutation(queryKey, saveAssetReportApi, { onSuccess }),
    useDeleteReport: () => useMutation(queryKey, deleteReportApi, { onSuccess }),
    useSendReport: () => useMutation(queryKey, sendReportApi),
  };
};
