import React from 'react';
import PropTypes from 'prop-types';
import { Row } from 'reactstrap';
import CostBarChart from './CostBarChartForSavingsWaste';

function CostSavingWasteBarChart(props) {
  return (
    <Row style={{ padding: '20px 10px', margin: '5px 0px 0px 2px' }} className="chart-box-style">
      <CostBarChart data={props.data} granLevel="month" service={props.service} />
    </Row>
  );
}

CostSavingWasteBarChart.propTypes = {
  data: PropTypes.object.isRequired,
  service: PropTypes.string.isRequired,
};

export default CostSavingWasteBarChart;
