 
import { RecommendationTypes } from 'recommendations/constants/recommendationsConstants';
import { Ec2UnnecessaryDataTransferRecommendation } from 'recommendations/models/recommTypes';
import Ec2CostRecommendationBuilder from 'recommendations/models/builders/ec2CostRecommBuilder';

export default class Ec2UnnecessaryDataTransferRecommendationBuilder extends Ec2CostRecommendationBuilder {
  constructor(rawRecommendation) {
    super(rawRecommendation);
  }

  createRecommendation() {
    this._recommendation = new Ec2UnnecessaryDataTransferRecommendation();
  }

  getRecommType() {
    return RecommendationTypes.EC2_UNNECESSARY_DATA_TRANSFER;
  }

  buildRecommTitle() {
    const { recommended_action: action } = this._rawRecommendation;
    this._recommendation.title = `${action || ''} EC2 Unnecessary Data Transfer`;
  }

  build() {
    super.build();
    const {
      az: availabilityZone,
      resource_tags: resourceTags,
      resource_name: resourceName,
      dt_type: dtType,
    } = this._rawRecommendation;
    this._recommendation.resourceName = resourceName;
    this._recommendation.dtType = dtType;
    this._recommendation.availabilityZone = availabilityZone;
    this._recommendation.resourceTags = resourceTags;
    this._recommendation.instance = undefined;

    // BETA
    this._recommendation.isBeta = true;
  }
}
