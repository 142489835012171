import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { ClickAwayListener } from '@mui/material';
import { segmentEvent } from 'shared/modules/segmentAndAptrinsicHandler';
import Button from './Button';
import styles from './ButtonDropdown.module.scss';
import Tooltip from './Tooltip';

const ButtonDropdown = ({
  text = '',
  tooltipText = null,
  closeOnSelect = true,
  disabled = false,
  onClose = () => {},
  arrow = false,
  isSecondary = true,
  isTextButton = false,
  overrideMenuStyles = null,
  overrideButtonStyles = null,
  menuOpen: controlledMenuOpen = null,
  setMenuOpen: controlledSetMenuOpen = null,
  isLoading = false,
  automationId = '',
  iconPlacement = 'left',
  children,
  icon = null,
}) => {
  const [uncontrolledMenuOpen, uncontrolledSetMenuOpen] = useState(false);
  const menuOpen = controlledMenuOpen !== null ? controlledMenuOpen : uncontrolledMenuOpen;
  const setMenuOpen = controlledSetMenuOpen !== null ? controlledSetMenuOpen : uncontrolledSetMenuOpen;

  const handleSelect = (event) => {
    let currentElemet = event.target;
    let option = '';
    for (let i = 0; i < 10; i++) {
      if (currentElemet.id) {
        option = currentElemet.id;
        break;
      }
      currentElemet = currentElemet.parentElement;
    }
    segmentEvent({
      type: 'select',
      target: automationId || (typeof text === 'string' && text) || 'ButtonDropdown',
      data: {
        option,
      },
    });
    setMenuOpen(false);
  };

  useEffect(() => {
    if (!menuOpen) {
      onClose();
    }
  }, [onClose, menuOpen]);

  const button = (
    <div className={classnames({ [styles.withArrow]: arrow })}>
      <Button
        text={text}
        disabled={disabled}
        icon={icon}
        iconPlacement={iconPlacement}
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
          setMenuOpen(!menuOpen);
        }}
        overrideStyles={{ paddingLeft: 8, paddingRight: 8, ...overrideButtonStyles }}
        isSecondary={isSecondary}
        isTextButton={isTextButton}
        iconSize={16}
        isLoading={isLoading}
        automationId={automationId}
      />
    </div>
  );

  return (
    <ClickAwayListener mouseEvent="onMouseDown" onClickAway={() => setMenuOpen(false)}>
      <div className={styles.container}>
        {tooltipText && !menuOpen && !disabled ? (
          <Tooltip title={tooltipText} arrow>
            {button}
          </Tooltip>
        ) : (
          button
        )}
        {menuOpen && (
          <div
            onClick={(e) => closeOnSelect && handleSelect(e)}
            style={overrideMenuStyles}
            className={styles.buttonDropDown}
          >
            {children}
          </div>
        )}
      </div>
    </ClickAwayListener>
  );
};

ButtonDropdown.propTypes = {
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  tooltipText: PropTypes.string,
  closeOnSelect: PropTypes.bool,
  onClose: PropTypes.func,
  menuOpen: PropTypes.bool,
  overrideMenuStyles: PropTypes.object,
  overrideButtonStyles: PropTypes.object,
  disabled: PropTypes.bool,
  isTextButton: PropTypes.bool,
  arrow: PropTypes.bool,
  setMenuOpen: PropTypes.func,
  isSecondary: PropTypes.bool,
  isLoading: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.element]).isRequired,
  icon: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  automationId: PropTypes.string,
  iconPlacement: PropTypes.string,
};

export default ButtonDropdown;
