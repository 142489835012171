import { CLOUD_TYPE_IDS, UsersType } from 'users/constants/usersConstants';
import { resellerCommonFieldToDisplayField } from 'shared/constants/userTypesContants';
import { CostUsageStates } from 'usage/constants/costAndUsageConstants';
import InvoicesApi from 'invoices/api/invoicesApi';
import { awsCommonFieldToDisplayField } from 'shared/constants/awsConstants';
import { azureCommonFieldToDisplayField } from 'shared/constants/azureConstants';
import { GCPCommonFieldLabels, gcpCommonFieldToDisplayField } from 'shared/constants/gcpConstants';

class _LabelCoordinator {
  constructor() {
    this.serviceNames = new Map();
    this.serviceNamesReversed = new Map();
    this.cloudType = -1;
    this.usersStore = null;
    this.cueDisplayCoordinator = {
      dataKeyToDisplayName: new Map(),
      displayNameToDatakey: new Map(),
      costUsageStatesToDisplayFunc: {
        [CostUsageStates.RESOURCE]: ({ resourceId, resourceName, groupBy }) => {
          const resource =
            resourceName && resourceName !== resourceId && resourceName !== 'Not Available'
              ? `${resourceName} (${resourceId})`
              : resourceId;
          // it means metrics page use this file - we need to add this suffix to label
          const match = (groupBy || '').match(/\s-\s(Average|Minimum|Maximum)/);
          if (match) {
            return `${resource} ${match[0]}`;
          }
          return resource;
        },
      },
    };
    this.cloudTypeToCommonFieldToDisplayField = {
      [CLOUD_TYPE_IDS.AWS]: awsCommonFieldToDisplayField,
      [CLOUD_TYPE_IDS.AZURE]: azureCommonFieldToDisplayField,
      [CLOUD_TYPE_IDS.GCP]: gcpCommonFieldToDisplayField,
      [CLOUD_TYPE_IDS.MULTI]: new Map([
        ...gcpCommonFieldToDisplayField,
        ...azureCommonFieldToDisplayField,
        ...awsCommonFieldToDisplayField,
      ]),
      [CLOUD_TYPE_IDS.BYOD]: new Map([
        ...gcpCommonFieldToDisplayField,
        ...azureCommonFieldToDisplayField,
        ...awsCommonFieldToDisplayField,
      ]),
    };
    this.userTypeToCommonFieldToDisplayField = {
      [UsersType.RESELLER]: resellerCommonFieldToDisplayField,
      [UsersType.BETA_USER]: resellerCommonFieldToDisplayField,
    };
  }

  fetchServiceNames = async () => {
    const data = await new InvoicesApi().getServiceNames();
    const serviceNames = new Map(
      data.map(([key, value]) => {
        if (!value) {
          return [key, key];
        }
        if (value?.includes(key) || value?.length > key?.length) {
          return [key, value];
        }
        if (key?.includes(value)) {
          return [key, key];
        }
        return [key, `${key} (${value})`];
      }),
    );
    this.serviceNames = serviceNames;
    this.serviceNamesReversed = this.buildServiceNamesReversed(serviceNames);
  };
  buildServiceNamesReversed = (serviceNames) => {
    const serviceNamesReversedKeys = [...serviceNames.keys()];
    const filteredServiceNamesReversedKeys = serviceNamesReversedKeys.filter((key) => !!serviceNames.get(key));
    return new Map(filteredServiceNamesReversedKeys.map((key) => [serviceNames.get(key), key]));
  };

  setCloudType = (cloudType) => {
    if (cloudType !== this.cloudType) {
      this.fetchServiceNames();
    }
    this.cloudType = cloudType;
  };

  setUsersStore = (usersStore) => {
    this.usersStore = usersStore;
    this.initialPopulationOfDataKeyAndDisplayNameMaps('cueDisplayCoordinator');
  };
  // KEYS AND DISPLAY NAMES TO CHARTS *******************

  initialPopulationOfDataKeyAndDisplayNameMaps = (modalToPopulate) => {
    try {
      this[modalToPopulate].dataKeyToDisplayName = new Map([...this.getBaseDataKeyToDisplayMap()]);
      this[modalToPopulate].displayNameToDatakey = new Map([...this.getBaseDisplayMapToDataKey()]);
    } catch {
      this.cueDisplayCoordinator.dataKeyToDisplayName = new Map([...this.getBaseDataKeyToDisplayMap()]);
      this.cueDisplayCoordinator.displayNameToDatakey = new Map([...this.getBaseDisplayMapToDataKey()]);
    }
  };
  getServiceNameDisplayValue = (key) => this.serviceNames.get(key) || key;
  getServiceNameDataKeyByValue = (key) => this.serviceNamesReversed.get(key) || key;
  getBaseDataKeyToDisplayMap = () => this.serviceNames;
  getBaseDisplayMapToDataKey = () => {
    const { serviceNamesReversed } = this;
    return serviceNamesReversed;
  };
  getDataKeyDisplayName = (modalToUse, key) => {
    let displayName = key;
    if (this[modalToUse].dataKeyToDisplayName.has(key)) {
      displayName = this[modalToUse].dataKeyToDisplayName.get(key);
    }
    return displayName;
  };
  getDisplayNameDataKey = (modalToUse, dispName) => {
    let dataKey = dispName;
    if (this[modalToUse].displayNameToDatakey.has(dispName)) {
      dataKey = this[modalToUse].displayNameToDatakey.get(dispName);
    }
    return dataKey;
  };
  createReverseMap = (origMap) => new Map(Array.from(origMap, (a) => a.reverse()));
  populateNewDisplayKeyMapBasedOnDataState = (modalToUse, currDataState, data) => {
    if (`${currDataState}` in this[modalToUse].costUsageStatesToDisplayFunc) {
      const newDisplayMap = new Map([
        ...data.map((item) => {
          const key = item.groupBy;
          const value = this[modalToUse].costUsageStatesToDisplayFunc[currDataState](item);
          return [key, value];
        }),
      ]);
      this.addNewKeyToDisplayAndReverseMapValues(modalToUse, newDisplayMap);
    }
  };
  addNewKeyToDisplayMapValues = (modalToPopulate, mapKeyToDisplay = new Map()) => {
    this[modalToPopulate].dataKeyToDisplayName = new Map([
      ...this[modalToPopulate].dataKeyToDisplayName,
      ...this.getBaseDataKeyToDisplayMap(),
      ...mapKeyToDisplay,
    ]);
  };
  addNewKeyToDisplayAndReverseMapValues = (modalToPopulate, mapKeyToDisplay = new Map()) => {
    this[modalToPopulate].dataKeyToDisplayName = new Map([
      ...this[modalToPopulate].dataKeyToDisplayName,
      ...this.getBaseDataKeyToDisplayMap(),
      ...mapKeyToDisplay,
    ]);
    this[modalToPopulate].displayNameToDatakey = new Map([
      ...this[modalToPopulate].displayNameToDatakey,
      ...this.getBaseDisplayMapToDataKey(),
      ...this.createReverseMap(mapKeyToDisplay),
    ]);
  };
  // LABELS
  getFieldLabel = (field) => {
    let label = '';
    const userType = this.usersStore?.deprecatedGetCurrentDisplayedUserType;
    try {
      const commonUserTypeFieldToDisplayField = this.userTypeToCommonFieldToDisplayField[userType];
      label = commonUserTypeFieldToDisplayField.get(field) || field;
    } catch {
      label = field;
    }
    if (label === field) {
      try {
        const commonFieldToDisplayField = this.cloudTypeToCommonFieldToDisplayField[this.cloudType];
        label = commonFieldToDisplayField.get(field) || field;
      } catch {
        label = field;
      }
    }
    if (label === 'Categories' || label === 'Category') {
      return 'Filter Group';
    }
    if (label && label.includes('accounttags')) {
      label = label.replace('accounttags', 'Enrichment Tag');
    }
    return label;
  };

  getFieldLabelByCloudType = (field, cloudType) => {
    let label = '';
    try {
      const commonFieldToDisplayField = this.cloudTypeToCommonFieldToDisplayField[cloudType];
      label = commonFieldToDisplayField.get(field) || field;
      if (cloudType === CLOUD_TYPE_IDS.GCP && GCPCommonFieldLabels.has(field)) {
        label = GCPCommonFieldLabels.get(field);
      }
    } catch {
      label = field;
    }
    return label;
  };
}

const LabelCoordinator = new _LabelCoordinator();
export default LabelCoordinator;
