import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from 'shared/components/andtComponents/Tooltip';
import LabelCoordinator from 'shared/modules/labelCoordinator';
import { ReactComponent as SwitchDown } from 'shared/img/icons/switch-down.svg';
import { ReactComponent as SwitchUp } from 'shared/img/icons/switch-up.svg';
import styles from './IncludeExcludeButton.module.scss';

export const IncludeExcludeButton = ({
  excludeMode,
  field,
  label = '',
  subField = null,
  changeFilterType,
  isDisabled = false,
}) => (
  <Tooltip
    title={`Press here to ${excludeMode ? 'include' : 'exclude'} the selected values of ${
      label || LabelCoordinator.getFieldLabel(field)
    } field`}
    placement="top"
    arrow
  >
    <div className={styles.toggleContainer}>
      <button
        className={styles.includeButton}
        disabled={isDisabled}
        onClick={(e) => {
          changeFilterType(e, field, subField, !excludeMode);
        }}
        data-exclude={!!excludeMode}
        type="button"
        automation-id="include-button"
      >
        <div className={styles.content}>
          <span>Include</span>
          <span>Exclude</span>
        </div>
        {excludeMode ? <SwitchUp className={styles.switchIcon} /> : <SwitchDown className={styles.switchIcon} />}
      </button>
    </div>
  </Tooltip>
);

IncludeExcludeButton.propTypes = {
  excludeMode: PropTypes.bool.isRequired,
  field: PropTypes.object.isRequired,
  label: PropTypes.string,
  subField: PropTypes.object,
  changeFilterType: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool,
};
