 
import { RecommendationTypes } from 'recommendations/constants/recommendationsConstants';
import { GcpVmStoppedRecommendation } from 'recommendations/models/recommTypes';
import GcpCostRecommendationBuilder from 'recommendations/models/builders/gcpCostRecommBuilder';

export default class GcpVmStoppedCostRecommendationBuilder extends GcpCostRecommendationBuilder {
  constructor(rawRecommendation) {
    super(rawRecommendation);
  }

  createRecommendation() {
    this._recommendation = new GcpVmStoppedRecommendation();
  }

  getRecommType() {
    return RecommendationTypes.GCP_VM_IDLE;
  }

  buildRecommTitle() {
    const { recommended_action: action } = this._rawRecommendation;
    this._recommendation.title = `${action || ''} VM Stopped`;
  }

  build() {
    super.build();
    const {
      public_ip_address: publicIpAddress,
      attached_volumes: attachedVolumes,
      resource_name: resourceName,
      public_ip_address_cost: publicIpAddressCost,
      attached_volumes_monthly_cost: attachedVolumesCosts,
      zone_tag: region,
      stop_reason: stopReason,
      stop_time: stopTime,
    } = this._rawRecommendation;
    this._recommendation.publicIpAddress = publicIpAddress;
    this._recommendation.attachedVolumes = attachedVolumes;
    this._recommendation.resourceName = resourceName;
    this._recommendation.attachedVolumesCosts = attachedVolumesCosts;
    this._recommendation.publicIpAddressCost = publicIpAddressCost;
    this._recommendation.region = region;
    this._recommendation.stopReason = stopReason;
    this._recommendation.stopTime = stopTime;
  }
}
