import React, { useMemo } from 'react';
import { observer } from 'mobx-react';
import { Container, Row } from 'reactstrap';
import PropTypes from 'prop-types';
import { PageNames } from 'shared/constants/appConstants';
import PageHeader from 'shared/components/PageHeader';
import ColoredTabs from 'shared/components/tabs/ColoredTabs';
import { CLOUD_TYPE_IDS, TABS_IDS } from 'users/constants/usersConstants';
import Spinner from 'shared/components/andtComponents/Spinner';
import { TableHeaderProvider } from 'shared/components/tableHeader/TableHeaderContext';
import { usePermissionCategories } from 'usage/hooks/react-query/usePermissionCategories';
import CloudAccounts from 'users/containers/CloudAccounts/CloudAccounts';
import LinkedAccounts from './LinkedAccounts/LinkedAccounts';
import Users from './Users/Users';
import Roles from './Roles/Roles';
import Customers from './Customers/CustomersWrapper';
import CostCenters from './CostCenters/CostCenters';
import Channels from '../Channels';
import Settings from './Settings/Settings.jsx';
import { useLocation } from 'react-router-dom';
import { ICONS } from '@pileus-cloud/anodot-frontend-common';
import { HierarchicalEntityCategory, OrganizationEntityCategory } from '@anodot-cost/rbac-client';
import { TAB_PATHS } from './consts';
import { prepareLinkAccountLabel } from 'recommendations/containers/Dashboard/helpers/dataPrepareHelpers';
import AppRegistrationConfiguration from '~/users/containers/Onboarding/azure/components/AppRegistrationConfiguration.jsx';

export const getNavObjectsMap = (usersStore) => {
  const cloudTypeId = usersStore?.currDispUserCloudAccountType;
  const tabsMap = new Map([
    [
      TABS_IDS.USERS,
      {
        id: 0,
        component: Users,
        name: 'Users',
        title: '',
        icon: ICONS.mainUserRegular.name,
        path: TAB_PATHS.USERS,
        permissionCategories: [OrganizationEntityCategory.UsersManagement],
      },
    ],
    [
      TABS_IDS.ROLES,
      {
        id: 1,
        component: Roles,
        name: 'Roles',
        title: '',
        icon: ICONS.userGear.name,
        path: TAB_PATHS.ROLES,
        permissionCategories: [OrganizationEntityCategory.Roles],
      },
    ],
    [
      TABS_IDS.COST_CENTER,
      {
        id: 2,
        component: CostCenters,
        name: 'Cost Centers',
        title: '',
        icon: ICONS.listTree.name,
        path: TAB_PATHS.COST_CENTERS,
        permissionCategories: [OrganizationEntityCategory.CostCenters],
      },
    ],
    [
      TABS_IDS.CLOUD_ACCOUNTS,
      {
        id: 3,
        component: CloudAccounts,
        name: 'Cloud Accounts',
        title: '',
        icon: ICONS.cloud.name,
        path: TAB_PATHS.CLOUD_ACCOUNTS,
        permissionCategories: [HierarchicalEntityCategory.Accounts],
      },
    ],
    [
      TABS_IDS.CUSTOMERS,
      {
        id: 4,
        component: Customers,
        name: 'Customers',
        title: '',
        icon: ICONS.users.name,
        path: TAB_PATHS.CUSTOMERS,
        permissionCategories: [OrganizationEntityCategory.ResellerCustomers],
      },
    ],
    [
      TABS_IDS.CHANNELS,
      {
        id: 5,
        component: Channels,
        name: 'Channels',
        title: '',
        icon: ICONS.objectIntersect.name,
        path: TAB_PATHS.CHANNELS,
        permissionCategories: [HierarchicalEntityCategory.Accounts],
      },
    ],
    [
      TABS_IDS.LINKED_ACCOUNTS,
      {
        id: 6,
        component: LinkedAccounts,
        name: prepareLinkAccountLabel(cloudTypeId),
        title: '',
        icon: ICONS.linkHorizontal.name,
        path: TAB_PATHS.LINKED_ACCOUNTS,
        permissionCategories: [HierarchicalEntityCategory.Accounts],
      },
    ],
  ]);
  if (cloudTypeId === CLOUD_TYPE_IDS.AZURE) {
    tabsMap.set(TABS_IDS.AZURE_APP_REG, {
      id: 7,
      component: AppRegistrationConfiguration,
      name: 'App Registration',
      title: 'Grant permissions to analyze specific resources needed for our savings recommendations tool',
      icon: 'pencilMechanical',
      path: TAB_PATHS.AZURE_APP_REG,
      permissionCategories: [HierarchicalEntityCategory.Accounts],
    });
  }
  tabsMap.set(TABS_IDS.SETTINGS, {
    id: cloudTypeId === CLOUD_TYPE_IDS.AZURE ? 8 : 7,
    component: Settings,
    name: 'Settings',
    title: '',
    icon: ICONS.gearRegular.name,
    path: TAB_PATHS.SETTINGS,
    permissionCategories: [OrganizationEntityCategory.UsersManagement], // Check if this is correct
  });

  return tabsMap;
};

const Organization = ({ usersStore, ...props }) => {
  const { fetchAccessibleCategories } = usePermissionCategories();
  const { data: permissionCategories, isLoading: isLoadingAccessibleCategories } = fetchAccessibleCategories();

  const location = useLocation();

  const navAccessibleObjectsMap = useMemo(
    () =>
      new Map(
        [...getNavObjectsMap(usersStore).entries()].filter(([, value]) =>
          value?.permissionCategories?.every((category) => permissionCategories?.includes(category)),
        ),
      ),
    [permissionCategories, usersStore.currDispUserCloudAccountType],
  );

  if (usersStore.isLoading || isLoadingAccessibleCategories) {
    return <Spinner />;
  }

  const getArrOfComponents = () =>
    [...navAccessibleObjectsMap.values()].map((tab) => {
      const C = tab.component;
      return (
        <TableHeaderProvider key={tabId}>
          <C {...props} usersStore={usersStore} />
        </TableHeaderProvider>
      );
    });

  const defaultAccountIndex = location.state?.defaultTabId
    ? [...getNavObjectsMap(usersStore).keys()].indexOf(location.state?.defaultTabId)
    : location.defaultAccountIndex || location.state?.defaultAccountIndex;

  const lastPathSegment = location.pathname.split('/').pop();
  const tabId = [...getNavObjectsMap(usersStore).values()].find((o) => o?.path === lastPathSegment)?.id;
  return (
    <Container>
      <PageHeader title={PageNames.ORGANIZATION} />
      <Row>
        <ColoredTabs
          onTabChange={(tabId) => {
            const tab = Array.from(getNavObjectsMap(usersStore).values()).find((t) => t?.id === tabId);
            window.history.pushState({}, '', `/account/${tab.path}`);
          }}
          arrOfNavItems={[...navAccessibleObjectsMap.values()]}
          defaultAccountIndex={defaultAccountIndex ?? tabId}
        >
          {getArrOfComponents()}
        </ColoredTabs>
      </Row>
    </Container>
  );
};

Organization.propTypes = {
  usersStore: PropTypes.object.isRequired,
  username: PropTypes.string.isRequired,
  appStore: PropTypes.object.isRequired,
  invoiceStore: PropTypes.object.isRequired,
  usageStore: PropTypes.object.isRequired,
};

export default observer(Organization);
