import PropTypes from 'prop-types';
import React from 'react';

import styles from './Permission.module.scss';

const PermissionTooltip = ({ roles }) => (
  <div className={styles.permissionTooltip}>
    <div className={styles.tooltipTitle}>This permission was granted via these roles:</div>
    <ul>
      {roles?.map((role) => (
        <li key={role}>{role?.displayName}</li>
      ))}
    </ul>
    <div className={styles.tooltipComment}>Detach these roles to remove this permission</div>
  </div>
);

PermissionTooltip.propTypes = {
  roles: PropTypes.array,
};

export default PermissionTooltip;
