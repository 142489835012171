 
import { RecommendationTypes } from 'recommendations/constants/recommendationsConstants';
import { RdsIdleRecommendation } from 'recommendations/models/recommTypes';
import RdsCostRecommendationBuilder from 'recommendations/models/builders/rdsCostRecommBuilder';

export default class RdsIdleRecommendationBuilder extends RdsCostRecommendationBuilder {
  constructor(rawRecommendation) {
    super(rawRecommendation);
  }

  createRecommendation() {
    this._recommendation = new RdsIdleRecommendation();
  }

  getRecommType() {
    return RecommendationTypes.RDS_IDLE;
  }

  buildRecommTitle() {
    const { recommended_action: action } = this._rawRecommendation;
    this._recommendation.title = `${action || ''} RDS Idle`;
  }

  build() {
    super.build();
    const {
      num_of_connections: numOfConnections,
      potential_savings: potentialSavings,
      days_to_check: daysToCheck,
    } = this._rawRecommendation;
    this._recommendation.numOfConnections = parseInt(numOfConnections, 0);
    this._recommendation.potentialSavings = potentialSavings;
    this._recommendation.daysToCheck = daysToCheck;
  }
}
